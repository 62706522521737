/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import React from 'react';

import { Container, Label, Select, SelectProps } from '..';
import { OptionTypeBase } from './SelectOptionTypes';

interface FormSelectOwnProps {
	name?: string;
	label?: string;
}

export type FormSelectProps = FormSelectOwnProps & Omit<SelectProps<OptionTypeBase>, keyof FormSelectOwnProps>;

export const FormSelect: React.VFC<FormSelectProps> = ({ label, flex, my, mx, mt, mb, ml, mr, ...props }) => {
	return (
		<Container display={'flex'} flexDirection={'column'} my={my} mx={mx} mt={mt} mb={mb} ml={ml} mr={mr} flex={flex}>
			{label ? (
				<Label>
					{label}
					<Select mt={'xSmall'} label={label} {...props} />
				</Label>
			) : (
				<Select mt={'xSmall'} {...props} />
			)}
		</Container>
	);
};
