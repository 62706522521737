/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import AstroidScreen from 'components/List/AstroidScreen/AstroidScreen';
import { IconTarget } from 'components/icons';
import { Container, Text } from 'components';
import { ReactElement } from 'react';

export default function ExploreNotFoundView(): ReactElement {
	return (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100%',
				width: '100%',
				bg: 'neutral700',
				color: 'neutral000',
			}}
		>
			<Container mt={-160}>
				<AstroidScreen
					title={
						<Text variant="xLargeStrong" color="purple300">
							Not found
						</Text>
					}
					icon={<IconTarget variant="xxLarge" color="neutral000" />}
					description={
						<Text
							variant="large"
							color="neutral000"
							sx={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							Could not find the view. Maybe it was deleted.
						</Text>
					}
				/>
			</Container>
		</Container>
	);
}
