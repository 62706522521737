/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import { ReactElement } from 'react';

import EnvironmentVariables from './EnvironmentVariables';
import Placeholders from './Placeholders';

interface VariablesAndPlaceholdersProps {
	selectItem: (variableOrPlaceholder: string) => void;
	width: string | number | undefined;
}

export default function VariablesAndPlaceholders({
	width,
	selectItem,
}: VariablesAndPlaceholdersProps): ReactElement | null {
	const { mode } = useEditorSettings();

	if (mode === undefined) {
		return null;
	}

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: mode === 'templateEditor' ? '4fr 5fr' : '1fr',
				width,
			}}
		>
			{mode === 'templateEditor' && <Placeholders width={width} onPlaceholderSelected={selectItem} />}
			<EnvironmentVariables width={width} onVariableSelected={selectItem} allowAddNew={mode !== 'templateUsage'} />
		</div>
	);
}
