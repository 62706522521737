/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { BehaviorSubject, distinctUntilChanged, from, interval, share, startWith, switchMap } from 'rxjs';
import { isEqual } from 'lodash';

import { ServerInfo } from '../../../services/serverInfoApi'; // export default function useShouldUpdateUI(): boolean {
import { useAsyncState } from '../../../utils/hooks/useAsyncState';
import { useObservable } from '../../../utils/hooks/useObservable';
import { Services } from '../../../services/services';

const serverInfoObservable$ = interval(1000 * 60)
	.pipe(startWith(-1))
	.pipe(switchMap(() => from(Services.serverInfo.fetchServerInfo())))
	.pipe(distinctUntilChanged(isEqual))
	.pipe(
		share({
			connector: () =>
				new BehaviorSubject({
					build: {
						version: '',
						time: '',
					},
				} as ServerInfo | null),
		}),
	);

export function useShouldUpdateUI(): boolean {
	const [serverInfo] = useAsyncState(Services.serverInfo.fetchServerInfo);
	const result = useObservable(() => serverInfoObservable$, []);
	if (
		!serverInfo.value ||
		!serverInfo.value.build ||
		!result.value ||
		!result.value.build ||
		result.value.build.time === '' ||
		result.value.build.version === ''
	) {
		return false;
	}
	return (
		result.value.build.time !== serverInfo.value.build.time ||
		result.value.build.version !== serverInfo.value.build.version
	);
}
