/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { FieldHookConfig, useField } from 'formik';
import React from 'react';

import { Toggle, ToggleProps } from '../Toggle';

export type FormikToggleProps = ToggleProps;

export const FormikToggle = React.forwardRef<HTMLButtonElement, FormikToggleProps>(({ ...props }, ref) => {
	const [field] = useField({ type: 'checkbox', ...props } as FieldHookConfig<string>);
	return <Toggle ref={ref} {...field} {...props} />;
});
FormikToggle.displayName = 'FormikToggle';
