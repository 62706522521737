/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { useEffect, useState } from 'react';

import ExploreTextures from '../TargetBubbleMap/ExploreTextures';

export function useIconTextures(icons: Map<string, string>): ExploreTextures | null {
	const [exploreTextures, setExploreTextures] = useState<ExploreTextures | null>(null);

	useEffect(() => {
		setExploreTextures(null);
		let iconsToBeLoaded = icons.size;
		const exploreTextures = new ExploreTextures(icons, () => {
			iconsToBeLoaded--;
			if (iconsToBeLoaded <= 0) {
				setExploreTextures(exploreTextures);
			}
		});
	}, [icons]);

	return exploreTextures;
}
