/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Container,
	ContainerProps,
	Heading,
	RouterLink,
	RouterPagination,
	Spinner,
	Stack,
	StateBadge,
	Tag,
	Text,
	Tooltip,
} from 'components';
import { ExperimentExecutionSummaryVO, ExperimentExecutionVO } from 'ui-api';
import { useApplicationHeaderHeight } from 'App/ApplicationHeaderHeight';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { AsyncState, useAsyncState } from 'utils/hooks/useAsyncState';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { PageLocation, usePage } from 'utils/hooks/usePage';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { IconDot, IconFilter } from 'components/icons';
import { formatDateWithTime } from 'utils/dateFns';
import { StateSelect } from 'hocs/StateSelect';
import { useUrlState } from 'url/useUrlState';
import { Services } from 'services/services';
import { UserSelect } from 'hocs/UserSelect';
import { useAsyncFn } from 'react-use';
import { useHistory } from 'url/hooks';
import { AxiosError } from 'axios';
import { debounce } from 'lodash';

import { ExperimentExecutionSubHeader } from './header/experimentExecutionSubHeader';

export const ExperimentExecutionWithSideList: React.FC<{
	experimentExecution?: AsyncState<ExperimentExecutionVO | undefined>;
	experimentExecutionSection?: string;
	experimentExecutionId: number;
	experimentKey: string;
	children: ReactNode;
}> = ({ experimentKey, experimentExecutionId, experimentExecution, experimentExecutionSection, children }) => {
	const isLoading = !experimentExecution || String(experimentExecutionId) !== String(experimentExecution.value?.id);

	const hasErrors = Boolean(experimentExecution?.error);
	const notFound = Boolean((experimentExecution?.error as AxiosError)?.response?.status === 404);

	return (
		<Container
			sx={{
				flex: '1 1 auto',
				display: 'grid',
				gridTemplateColumns: '320px 1fr',
				gridTemplateRows: '96px 1fr',
				gridTemplateAreas: '"recent-executions subheader" "recent-executions children"',
				overflow: 'hidden',
			}}
		>
			<RecentExecutionsPageResolver
				experimentKey={experimentKey}
				experimentExecutionId={String(experimentExecutionId)}
			/>
			{hasErrors ? (
				<ErrorScreen notFound={notFound} experimentExecutionId={experimentExecutionId} />
			) : !isLoading ? (
				<>
					<ExperimentExecutionSubHeader
						experimentExecution={experimentExecution.value}
						experimentExecutionSection={experimentExecutionSection}
					/>
					<Stack size={'none'} sx={{ gridArea: 'children', overflowY: 'auto' }}>
						{children}
					</Stack>
				</>
			) : (
				<LoadingScreen />
			)}
		</Container>
	);
};

function LoadingScreen(): ReactElement {
	const applicationHeaderHeight = useApplicationHeaderHeight();
	const height = `calc(100vh - ${applicationHeaderHeight + 80}px)`;

	return (
		<Stack justifyContent="center" alignItems="center" height={height}>
			<Spinner variant="xLarge" color="neutral300" />
		</Stack>
	);
}

function ErrorScreen({
	notFound,
	experimentExecutionId,
}: {
	notFound: boolean;
	experimentExecutionId: number;
}): ReactElement {
	const applicationHeaderHeight = useApplicationHeaderHeight();
	const height = `calc(100vh - ${applicationHeaderHeight + 80}px)`;

	return (
		<Stack justifyContent="center" alignItems="center" height={height}>
			<Text variant="large" color="neutral600">
				{notFound
					? `Experiment run #${experimentExecutionId} was not found.`
					: `Could not load the experiment run #${experimentExecutionId}.`}
			</Text>
		</Stack>
	);
}

function RecentExecutionsPageResolver({
	experimentKey,
	experimentExecutionId,
}: {
	experimentKey: string;
	experimentExecutionId: string;
}): ReactElement {
	const page = usePage('/executions', { page: 0, size: 15 });

	const [{ page: pageFromUrl }, , updateUrlState] = useUrlState([
		{
			pathSegment: '/executions',
			name: 'page',
			defaultValue: undefined,
		},
	]);
	const [experimentExecutionIds] = useAsyncState(() => {
		const newPage = page.withPage(0).withSize(100_000_00);
		return Services.experiments.fetchExperimentRunIds(newPage.criteria, newPage.pageParams, experimentKey);
	}, [page.criteria, experimentKey]);
	useEffect(() => {
		if (!experimentExecutionIds.value) {
			return;
		}

		const indexOfCurrentExecution = experimentExecutionIds.value.indexOf(experimentExecutionId);
		const pageOfCurrentExecution = Math.floor(indexOfCurrentExecution / page.pageParams.size);

		if (pageOfCurrentExecution !== pageFromUrl) {
			updateUrlState({ page: pageOfCurrentExecution });
		}
	}, [experimentExecutionId, experimentExecutionIds.value?.length]);

	if (pageFromUrl === undefined) {
		return (
			<Container sx={{ display: 'flex', gridArea: 'recent-executions', flexDirection: 'column' }}>
				<Container m="small">
					<Heading variant="small">Recent Runs</Heading>
				</Container>

				<Spinner variant="large" color="neutral500" />
			</Container>
		);
	}

	return <RecentExecutions experimentKey={experimentKey} experimentExecutionId={experimentExecutionId} />;
}

const RecentExecutions: React.VFC<{ experimentKey: string; experimentExecutionId: string }> = ({
	experimentKey,
	experimentExecutionId,
}) => {
	const page = usePage('/executions', { page: 0, size: 15 });
	const searchParams = page.toString();
	const [showFilter, setShowFilter] = useState(
		searchParams.indexOf('createdBy=') >= 0 || searchParams.indexOf('state=') >= 0,
	);

	const [experimentExecutions, fetch] = useAsyncState(
		() => Services.experiments.fetchExperimentRuns(page.criteria, page.pageParams, experimentKey),
		[page, experimentKey],
	);
	const [environments] = useAsyncState(
		async () => {
			const environmentIds =
				experimentExecutions.value?.content.filter((run) => run.environmentId).map((run) => run.environmentId) || [];
			return await Services.environments.fetchEnvironmentsById(environmentIds);
		},
		[experimentExecutions],
		new Map(),
	);
	const [stop, handleStopButtonClick] = useAsyncFn((executionId) =>
		Services.experiments.cancelExperimentRun(executionId),
	);

	const debouncedFetch = useMemo(() => debounce(fetch, 100), [fetch]);
	useEventEffect(
		debouncedFetch,
		[
			'experiment.execution.failed',
			'experiment.execution.errored',
			'experiment.execution.canceled',
			'experiment.execution.completed',
			'experiment.execution.deleted',
		],
		debouncedFetch.cancel,
	);

	if (experimentExecutions.error) {
		return <Text>Error loading Executions: {experimentExecutions.error.message}</Text>;
	}
	return (
		<Container display={'flex'} sx={{ gridArea: 'recent-executions', flexDirection: 'column' }}>
			<Stack m={'small'} size={'small'}>
				<Container display={'flex'}>
					<Heading variant={'small'}>Recent Runs</Heading>
					<IconFilter color={'neutral600'} ml={'auto'} onClick={() => setShowFilter((s) => !s)} />
				</Container>
				<ExperimentRunFilters page={page} show={showFilter} />
			</Stack>

			<Stack size={'xxSmall'} sx={{ overflowY: 'auto', flex: '1 1 auto', height: '1px' }}>
				{experimentExecutions.loading && !experimentExecutions.value ? (
					<Stack>
						<Spinner variant="large" color={'neutral500'} />
					</Stack>
				) : experimentExecutions.value ? (
					experimentExecutions.value.content.length > 0 ? (
						experimentExecutions.value.content.map((execution, index) => (
							<ExperimentRunRow
								key={execution.id}
								stopExecution={
									execution.state === 'CREATED' && !stop.loading ? () => handleStopButtonClick(execution.id) : undefined
								}
								first={index === 0}
								last={index + 1 === experimentExecutions?.value?.content.length}
								experimentKey={experimentKey}
								value={execution}
								experimentChanged={
									index !== 0 &&
									execution.experimentVersion !== experimentExecutions?.value?.content?.[index - 1].experimentVersion
								}
								selected={execution.id.toString() === experimentExecutionId}
								environmentName={environments?.value?.get(execution.environmentId)?.name || ''}
							/>
						))
					) : (
						<RecentExecutionBox indicator={<IconDot color={'neutral400'} height={6} width={16} />}>
							<Text muted>No executions found.</Text>
						</RecentExecutionBox>
					)
				) : null}
			</Stack>
			{experimentExecutions.value?.totalPages && (
				<RouterPagination
					my={'small'}
					size={'small'}
					activePage={page.pageParams.page}
					totalPages={experimentExecutions.value?.totalPages}
					to={(i) => page.withPage(i).toString()}
				/>
			)}
		</Container>
	);
};

const ExperimentRunFilters: React.VFC<{ page: PageLocation; show: boolean }> = ({ page, show }) => {
	const history = useHistory();
	if (!show) {
		return <></>;
	}
	return (
		<Stack direction={'horizontal'} size={'xxSmall'}>
			<StateSelect
				variant={'small'}
				value={page.criteria.get('state')}
				name={'state'}
				placeholder={'State'}
				onChange={(state) => history.replace(page.withCriterion('state', state).toString())}
			/>
			<UserSelect
				variant={'small'}
				value={page.criteria.get('createdBy')}
				name={'createdBy'}
				onChange={(createdBy) => history.replace(page.withCriterion('createdBy', createdBy).toString())}
			/>
		</Stack>
	);
};

const ExperimentRunRow: React.VFC<{
	stopExecution?: () => void;
	experimentKey: string;
	value: ExperimentExecutionSummaryVO;
	first: boolean;
	last: boolean;
	selected: boolean;
	experimentChanged: boolean;
	environmentName: string;
}> = ({ experimentKey, value, stopExecution, first, last, selected, experimentChanged, environmentName }) => {
	return (
		<>
			{experimentChanged && (
				<RecentExecutionBox indicator={<IconDot color={'neutral400'} height={6} width={16} />}>
					<Text as={'span'} variant={'small'} color={'neutral600'}>
						Experiment design changed
					</Text>
				</RecentExecutionBox>
			)}
			<RecentExecutionBox
				indicator={
					<StateBadge as="icon" value={value.state} onClick={value.state === 'CREATED' ? stopExecution : undefined} />
				}
				selected={selected}
				first={first}
				last={last}
				hoverable
				pathname={`/experiments/edit/${experimentKey}/executions/${value.id}/attack`}
			>
				<Container display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
					<Container display={'flex'} justifyContent={'center'} alignItems={'center'}>
						<Text as={'span'} variant={'mediumStrong'}>
							#{value.id}
						</Text>
						<Tooltip content={environmentName} onlyShowOnEllipsis>
							<Container>
								<Tag variant={'xxSmall'} ml={'xSmall'}>
									{environmentName}
								</Tag>
							</Container>
						</Tooltip>
					</Container>
					<Text as={'span'} sx={{ fontVariantNumeric: 'tabular-nums' }}>
						{formatDateWithTime(value.created)}
					</Text>
				</Container>
			</RecentExecutionBox>
		</>
	);
};

const RecentExecutionBox: React.FC<
	{
		selected?: boolean;
		hoverable?: boolean;
		first?: boolean;
		last?: boolean;
		indicator: ReactNode;
		pathname?: string;
	} & ContainerProps
> = ({ selected, first, last, hoverable, children, indicator, pathname, ...props }) => {
	const component = (
		<Container
			display={'flex'}
			sx={{
				mx: 'small',
				p: 'small',
				pl: 'xLarge',
				borderRadius: '6px',
				bg: selected ? 'purple100' : undefined,
				position: 'relative',
				':hover': hoverable
					? {
							bg: 'neutral100',
							cursor: 'pointer',
					  }
					: {},
			}}
			{...props}
		>
			{!first ? (
				<Container
					sx={{
						position: 'absolute',
						left: '21px',
						top: '-4px',
						bottom: 'calc(50% + 12px)',
						borderLeft: '2px solid',
						borderLeftColor: 'neutral200',
					}}
				/>
			) : null}
			<Container display={'flex'} sx={{ position: 'absolute', left: '14px', top: 0, bottom: 0, alignItems: 'center' }}>
				{indicator}
			</Container>
			{!last ? (
				<Container
					sx={{
						position: 'absolute',
						left: '21px',
						top: 'calc(50% + 12px)',
						bottom: '-4px',
						borderLeft: '2px solid',
						borderLeftColor: 'neutral200',
					}}
				/>
			) : null}
			{children}
		</Container>
	);

	if (pathname) {
		return (
			<RouterLink
				to={pathname}
				sx={{
					color: 'inherit',
				}}
			>
				{component}
			</RouterLink>
		);
	}

	return component;
};
