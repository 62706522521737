/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { BreadcrumbItem, Breadcrumbs, Button, ButtonIcon, HeaderBar, Stack, Tooltip, userConfirmV2 } from 'components';
import EditableLabel from 'components/EditableLabel/EditableLabel';
import { deleteTemplate } from 'templates/components/formUtils';
import { IconTemplate, IconTrash } from 'components/icons';
import { useFormikContext } from 'formik';
import { useHistory } from 'url/hooks';
import { ReactElement } from 'react';

import { TemplateFormValues } from './types';

export default function TemplateHeader(): ReactElement {
	const { values, errors, isSubmitting, setFieldValue, setFieldTouched, submitForm } =
		useFormikContext<TemplateFormValues>();
	const history = useHistory();

	const hasErrors = Object.keys(errors).length > 0;
	const isNewTemplate = !values.id;

	return (
		<HeaderBar
			theme="template"
			icon={IconTemplate}
			tagline={
				<Breadcrumbs>
					<BreadcrumbItem variant="small" label="Settings" to="/settings" sx={{ color: 'purple200', mr: 'xxxSmall' }} />
					<BreadcrumbItem variant="small" label="Templates" to="/settings/templates" sx={{ color: 'purple200' }} />
				</Breadcrumbs>
			}
			title={
				<EditableLabel
					value={values.templateTitle}
					placeholder="Untitled Template"
					onChange={(value) => {
						setFieldValue('templateTitle', value);
						setFieldTouched('templateTitle', true);
					}}
					theme="light"
				/>
			}
			sx={{ height: '66px' }}
		>
			<Stack direction="horizontal" alignItems="center" size="small">
				<ButtonIcon
					onClick={async () => {
						if (!isNewTemplate) {
							await deleteTemplate(values.id || '', 'UI_EDITOR');
							history.replace('/settings/templates');
							return;
						}

						if (
							(await userConfirmV2({
								title: 'Discard new template',
								message: `Do you really want to discard your new template: ${values.templateTitle || 'Untitled'}?`,
								actions: [
									{ value: 'cancel', label: 'Cancel' },
									{
										value: 'confirm',
										label: 'Discard',
										variant: 'primary',
									},
								],
							})) === 'confirm'
						) {
							history.replace('/settings/templates');
						}
					}}
					tooltip={isNewTemplate ? 'Discard new template' : 'Delete template'}
				>
					<IconTrash color="purple200" />
				</ButtonIcon>

				<Tooltip content={hasErrors ? 'Fix all validation errors to save this template' : ''}>
					<Button
						variant="secondary"
						minWidth="140px"
						onClick={submitForm}
						disabled={hasErrors || isSubmitting}
						data-cy="save-template"
					>
						{isSubmitting ? 'Saving…' : 'Save template'}
					</Button>
				</Tooltip>
			</Stack>
		</HeaderBar>
	);
}
