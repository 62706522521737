/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React from 'react';

type StackDirection = 'horizontal' | 'vertical';

export interface StackProps extends ContainerProps {
	size?: string | number;
	direction?: StackDirection;
	reverse?: boolean;
}

export const Stack = React.forwardRef<HTMLElement, StackProps>(
	({ size = 'small', direction = 'vertical', children, reverse, sx = {}, ...props }, ref) => {
		const ContainerProps =
			direction === 'vertical' ? (reverse ? { mb: size } : { mt: size }) : reverse ? { mr: size } : { ml: size };

		return (
			<Container
				ref={ref}
				display={'flex'}
				{...props}
				sx={{
					...sx,
					// double && intended: results in the components css class being added twice
					// which fixes specificity problems when a child has set its own margin-left or -right.
					'&& > * + *': ContainerProps,
				}}
				flexDirection={
					direction === 'vertical' ? (reverse ? 'column-reverse' : 'column') : reverse ? 'row-reverse' : 'row'
				}
			>
				{children}
			</Container>
		);
	},
);

Stack.displayName = 'Stack';
