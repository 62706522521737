/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { DeleteIcon } from 'pages/templates/FromTemplateModal/TemplateDetails/common';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { createIconFromDataUri } from 'components/icons';
import { TargetTypeDescriptionVO } from 'ui-api';
import { ReactElement } from 'react';
import { Pill } from 'components';

interface TargetProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	targetId: string;
	onClick?: () => void;
	onDelete?: () => void;
}

export function Target({ targetDefinitions, targetId, onClick, onDelete }: TargetProps): ReactElement {
	const resolvedTargetDefinition = (targetDefinitions || []).find(
		(targetDefinition) => targetDefinition.id === targetId,
	);
	const Icon = createIconFromDataUri(resolvedTargetDefinition?.icon);

	return (
		<Pill
			backgroundColorOnHover="neutral300"
			backgroundColor="neutral200"
			color="neutral800"
			onClick={onClick}
			sx={{
				width: 'fit-content',
				height: '28px',
				fontSize: '12px',
				borderRadius: '14px',
				...textEllipsis,
			}}
		>
			{resolvedTargetDefinition?.icon ? <Icon variant="small" mr="xxSmall" /> : <></>}
			{resolvedTargetDefinition?.label.one || targetId}
			{onDelete ? <DeleteIcon color="neutral800" onClick={onDelete} /> : <></>}
		</Pill>
	);
}
