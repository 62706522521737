/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import axios from 'axios';

import { TenantsApi } from './tenantsApi';
import { ListResponse } from '../ui-api';

export class ExportApi {
	tenantApi: TenantsApi;

	constructor(tenantApi: TenantsApi) {
		this.tenantApi = tenantApi;
	}

	async isDbExportAvailable(): Promise<boolean> {
		return Boolean(await this.getDbExportUrl());
	}

	async fetchDatabaseTables(): Promise<ListResponse<string>> {
		return (await axios.get<ListResponse<string>>('/db/tables')).data;
	}

	async getDbExportUrl(): Promise<string | undefined> {
		try {
			const tenantKey = (await this.tenantApi.getCurrentTenantWithAgentKey())?.key;
			if (tenantKey) {
				const url = `/db/export?tenantKey=${tenantKey}`;
				const response = await axios.head(url);
				return response.status === 200 ? url : undefined;
			}
			return undefined;
		} catch (error) {
			return undefined;
		}
	}

	async isMetricExportAvailable(): Promise<boolean> {
		return Boolean(await this.getMetricExportUrl());
	}

	async getMetricExportUrl(): Promise<string | undefined> {
		try {
			const tenantKey = (await this.tenantApi.getCurrentTenantWithAgentKey())?.key;
			if (tenantKey) {
				const url = `/ui/metrics?tenantKey=${tenantKey}`;
				const response = await axios.head(url);
				return response.status === 200 ? url : undefined;
			}
			return undefined;
		} catch (error) {
			return undefined;
		}
	}

	async isAmplitudeExportAvailable(): Promise<boolean> {
		return Boolean(await this.getAmplitudeExportUrl());
	}

	async getAmplitudeExportUrl(): Promise<string | undefined> {
		try {
			const tenantKey = (await this.tenantApi.getCurrentTenantWithAgentKey())?.key;
			if (tenantKey) {
				const url = `/ui/user-analytics/export?tenantKey=${tenantKey}`;
				const response = await axios.head(url);
				return response.status === 200 ? url : undefined;
			}
		} catch (error) {
			return undefined;
		}
	}
}
