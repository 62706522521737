/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps, Text } from 'components';
import React, { useCallback, useRef } from 'react';

import { NumberInputButton } from './NumberInputButton';
import { IconAdd, IconSubtract } from '../icons';

export interface NumberInputProps
	extends Omit<ContainerProps, 'onChange'>,
		Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof ContainerProps> {
	value?: number;
	min?: number;
	max?: number;
	onChange?: (newValue: number) => unknown;
	hasError?: boolean;
	step?: number;
	unit?: string;
	boxShadow?: string;
}

export const NumberInput: React.VFC<NumberInputProps> = ({
	mx,
	my,
	mt,
	mb,
	ml,
	mr,
	boxShadow,
	width,
	min = -Infinity,
	max = Infinity,
	value = min,
	disabled,
	sx,
	onChange,
	hasError,
	variant = 'medium',
	step = 1,
	unit,
	...props
}) => {
	const inputRef = useRef<HTMLInputElement | null>(null);
	const decrease = useCallback(() => {
		if (min === undefined || value - step >= min) {
			onChange?.(value - step);
		} else {
			onChange?.(min);
		}
	}, [onChange, value, min, step]);

	const increase = useCallback(() => {
		if (inputRef?.current?.value && value + step <= max) {
			onChange?.(value + step);
		} else if (max !== undefined) {
			onChange?.(max);
		}
	}, [max, onChange, value, step]);

	const unitWidth = unit ? 20 : 0;

	return (
		<Container
			display={'flex'}
			width={width || '100%'}
			my={my}
			mx={mx}
			mt={mt}
			mb={mb}
			ml={ml}
			mr={mr}
			sx={{
				display: 'inline-flex',
				position: 'relative',
				borderRadius: 4,
				border: '1px solid',
				borderColor: hasError ? 'coral' : 'neutral400',
				overflow: 'hidden',
				':focus-within': { borderColor: hasError ? 'coral' : 'neutral600' },
				':disabled': {
					bg: 'neutral100',
					borderColor: hasError ? 'coral' : 'neutral200',
				},
				boxShadow: boxShadow ? boxShadow : 'unset',
				...sx,
			}}
			tx={'numberInput'}
			variant={variant}
			data-number-input
		>
			<NumberInputButton
				title={`Decrease by ${step}`}
				onClick={decrease}
				disabled={props.type !== 'number' || disabled || min === value}
				bg={'neutral000'}
			>
				<IconSubtract />
			</NumberInputButton>
			<Container
				as="input"
				ref={inputRef}
				flex="1 0 auto"
				value={value}
				type="number"
				py="xSmall"
				color="neutral800"
				variant={variant}
				disabled={disabled}
				onChange={(e) => {
					const parsed = parseInt((e.target as HTMLInputElement).value);

					if (isNaN(parsed)) {
						onChange?.(min);
					} else if (parsed > max) {
						onChange?.(max);
					} else if (parsed < min) {
						onChange?.(min);
					} else {
						onChange?.(parsed);
					}
				}}
				onFocus={(e) => {
					(e.target as HTMLInputElement).select();
				}}
				sx={{
					textAlign: 'center',
					border: 'none',
					':focus': { outline: 'none' },
					'::-webkit-outer-spin-button,::-webkit-inner-spin-button': {
						appearance: 'none',
						margin: 0,
					},
					MozAppearance: 'textfield',
					width: 'calc(100% - 76px)',
					pr: unitWidth,
				}}
				{...props}
			/>
			{unit && (
				<Text
					as={'span'}
					variant={variant}
					sx={{
						display: 'flex',
						alignItems: 'center',
						ml: -unitWidth,
						pr: 'xSmall',
						py: 'xSmall',
					}}
				>
					{unit}
				</Text>
			)}
			<NumberInputButton
				title={`Increase by ${step}`}
				onClick={increase}
				disabled={props.type !== 'number' || disabled || max === value}
				bg={'neutral000'}
			>
				<IconAdd />
			</NumberInputButton>
		</Container>
	);
};
