/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { isNotBlank } from 'utils/string';

export const TemplatePlaceholderRegexHandlebar = /\[\[([\sA-Za-z0-9-_.]+?)\]\]/g;

export function listPlaceholders(value: unknown): string[] {
	// as Safari does not support lookbehind, we need to use a workaround replacing the escaped pattern
	if (!value) {
		return [];
	} else if (typeof value === 'string') {
		return Array.from(
			value
				.replaceAll('\\[[', '\\#WORKAROUND#')
				.replaceAll('\\]]', '\\#WORKAROUND#')
				.matchAll(TemplatePlaceholderRegexHandlebar),
		)
			.map((match) => match[1].trim())
			.filter(isNotBlank);
	} else if (Array.isArray(value)) {
		return value.flatMap((v) => listPlaceholders(v));
	} else if (typeof value === 'object') {
		return Object.values(value).flatMap((v) => listPlaceholders(v));
	} else {
		return listPlaceholders(JSON.stringify(value));
	}
}

export function listPlaceholderKeyValuePairs(
	value: { [index: string]: unknown },
	placeholder: string | undefined,
): Array<[string, string]> {
	const result: Map<string, string> = new Map();
	Object.keys(value).forEach((key) => {
		listPlaceholders(value[key])
			.filter((_placeholder) => _placeholder === placeholder)
			.forEach((_placeholder) => {
				result.set(key, _placeholder);
			});
	});
	return Array.from(result);
}
