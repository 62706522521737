/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { UrlParam } from 'url/useUrlState';
import { TargetId } from 'targets/util';

const pathSegment = '/advice';

export interface UrlState {
	selectedAdviceId: string | null;
	selectedTargetId: TargetId | null;
}

export const selectedAdviceIdParam: UrlParam<string | null> = {
	pathSegment,
	name: 'selectedAdviceId',
	defaultValue: null,
};

export const selectedTargetIdParam: UrlParam<TargetId | null> = {
	pathSegment,
	name: 'selectedTargetId',
	defaultValue: null,
};
