/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

export default `
precision highp float;
precision highp int;

uniform sampler2D textureMap;

varying vec2 vUv;
varying float fTargetSize;
varying float fWorldUnitInScreenSpace;

void main()	{
  if( 2.0 * fWorldUnitInScreenSpace * fTargetSize < 28.0 ) {
    discard;
  }

  vec4 tex = texture2D( textureMap, vUv );
	gl_FragColor = vec4( 1.0, 1.0, 1.0, 1.0 ) * tex;

  gl_FragColor.x = 1.0;
  gl_FragColor.y = 1.0;
  gl_FragColor.z = 1.0;
}
`;
