/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

export default `
precision highp float;
precision highp int;

uniform float progress;
uniform float worldUnitInScreenSpace;
uniform vec3 color;

attribute vec3 toPosition;
attribute float targetSize;

varying vec2 vUv;
varying vec3 vColor;
varying float fTargetSize;
varying float fWorldUnitInScreenSpace;

void main()	{
	vUv = uv;
	fTargetSize = targetSize;
	vColor = color;
	fWorldUnitInScreenSpace = worldUnitInScreenSpace;

	vec3 currentPosition = vec3(
		position.x + (toPosition.x - position.x) * progress,
		position.y + (toPosition.y - position.y) * progress,
		position.z
	);

	gl_Position = projectionMatrix * modelViewMatrix * vec4( currentPosition, 1.0 );
}
`;
