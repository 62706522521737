/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

// eslint-disable-next-line no-restricted-imports
import { Redirect as OriginalRedirect } from 'react-router-dom';
import { ReactElement } from 'react';

import { CommonOpts, LocationWithUrlParams } from './type';
import { HistoryWithUrlParams } from './historyWrap';
import { mergeMatrixParameters } from './utils';
import { stringify } from './stringifier';
import { useHistory } from './hooks';
import { parse } from './parser';

export type To = undefined | string | ((location: LocationWithUrlParams<unknown>) => void);

interface RedirectProps {
	from?: string;
	exact?: boolean;
	to: To;
}

export default function Redirect({ from, to, exact }: RedirectProps): ReactElement {
	const history = useHistory();
	const resolvedTo = resolveTo(history, to);
	return <OriginalRedirect from={from} to={resolvedTo.href} exact={exact} />;
}

interface ResolvedTo {
	href: string;
	isActive: boolean;
}

export function resolveTo(history: HistoryWithUrlParams<unknown>, to: To): ResolvedTo {
	if (!to) {
		return {
			href: '',
			isActive: false,
		};
	}
	if (typeof to === 'string') {
		if (to.startsWith('https://') || to.startsWith('http://')) {
			return {
				href: to,
				isActive: false,
			};
		}

		const parsed = fromHref(to, history.config);

		const href = stringify({
			pathname: parsed.pathname,
			query: {
				...history.location.query,
				...parsed.query,
			},
			matrix: mergeMatrixParameters(history.location.matrix, parsed.matrix),
		}) as string;

		return {
			href,
			isActive: history.location.pathname.startsWith(parsed.pathname),
		};
	} else if (typeof to === 'function') {
		const href = history.createHref(to);
		const parsed = fromHref(href, history.config);
		return {
			href,
			isActive: history.location.pathname.startsWith(parsed.pathname),
		};
	} else {
		throw new Error('to must be a string or a function');
	}
}

function fromHref(href: string, options: CommonOpts): LocationWithUrlParams<unknown> {
	let remainingPath = href;

	const indexHash = remainingPath.indexOf('#');
	let hash = '';
	if (indexHash !== -1) {
		hash = remainingPath.substring(indexHash);
		remainingPath = remainingPath.substring(0, indexHash);
	}

	const indexQuestionMark = remainingPath.indexOf('?');
	let search = '';
	if (indexQuestionMark !== -1) {
		search = remainingPath.substring(indexQuestionMark);
		remainingPath = remainingPath.substring(0, indexQuestionMark);
	}

	const pathname = remainingPath;

	const urlParams = parse(pathname, search, options);

	return {
		...urlParams,
		hash,
		search,
		state: undefined,
	};
}
