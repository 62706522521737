// Generated from QueryLanguageParser.g4 by ANTLR 4.13.0
// jshint ignore: start
import antlr4 from 'antlr4';

// This class defines a complete listener for a parse tree produced by QueryLanguageParser.
export default class QueryLanguageParserListener extends antlr4.tree.ParseTreeListener {

	// Enter a parse tree produced by QueryLanguageParser#topLevelQuery.
	enterTopLevelQuery(ctx) {
	}

	// Exit a parse tree produced by QueryLanguageParser#topLevelQuery.
	exitTopLevelQuery(ctx) {
	}


	// Enter a parse tree produced by QueryLanguageParser#query.
	enterQuery(ctx) {
	}

	// Exit a parse tree produced by QueryLanguageParser#query.
	exitQuery(ctx) {
	}


	// Enter a parse tree produced by QueryLanguageParser#disjQuery.
	enterDisjQuery(ctx) {
	}

	// Exit a parse tree produced by QueryLanguageParser#disjQuery.
	exitDisjQuery(ctx) {
	}


	// Enter a parse tree produced by QueryLanguageParser#conjQuery.
	enterConjQuery(ctx) {
	}

	// Exit a parse tree produced by QueryLanguageParser#conjQuery.
	exitConjQuery(ctx) {
	}


	// Enter a parse tree produced by QueryLanguageParser#modClause.
	enterModClause(ctx) {
	}

	// Exit a parse tree produced by QueryLanguageParser#modClause.
	exitModClause(ctx) {
	}


	// Enter a parse tree produced by QueryLanguageParser#modifier.
	enterModifier(ctx) {
	}

	// Exit a parse tree produced by QueryLanguageParser#modifier.
	exitModifier(ctx) {
	}


	// Enter a parse tree produced by QueryLanguageParser#clause.
	enterClause(ctx) {
	}

	// Exit a parse tree produced by QueryLanguageParser#clause.
	exitClause(ctx) {
	}


	// Enter a parse tree produced by QueryLanguageParser#groupingExpr.
	enterGroupingExpr(ctx) {
	}

	// Exit a parse tree produced by QueryLanguageParser#groupingExpr.
	exitGroupingExpr(ctx) {
	}


	// Enter a parse tree produced by QueryLanguageParser#fieldName.
	enterFieldName(ctx) {
	}

	// Exit a parse tree produced by QueryLanguageParser#fieldName.
	exitFieldName(ctx) {
	}


	// Enter a parse tree produced by QueryLanguageParser#term.
	enterTerm(ctx) {
	}

	// Exit a parse tree produced by QueryLanguageParser#term.
	exitTerm(ctx) {
	}


	// Enter a parse tree produced by QueryLanguageParser#quotedTerm.
	enterQuotedTerm(ctx) {
	}

	// Exit a parse tree produced by QueryLanguageParser#quotedTerm.
	exitQuotedTerm(ctx) {
	}



}