/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Container } from 'components';
import { ReactElement } from 'react';

interface ModalFooterProps {
	children: ReactElement | ReactElement[];
	withShadow?: boolean;
}

export default function ModalFooter({ withShadow, children }: ModalFooterProps): ReactElement {
	return (
		<Container
			className="modalFooter"
			display="flex"
			alignItems="center"
			justifyContent="flex-end"
			py="medium"
			px="xxxLarge"
			mt="auto"
			sx={{
				boxShadow: withShadow ? '0px -1px 14px #0000001a' : 'none',
			}}
		>
			{children}
		</Container>
	);
}
