/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { UrlParam } from 'url/useUrlState';

export interface UrlState {
	newExperimentTags?: string[];
}

export const newExperimentTagsParam: UrlParam<string[] | null> = {
	pathSegment: '/start',
	name: 'newExperimentTags',
	defaultValue: null,
};
