/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React from 'react';

export type WizardProps = ContainerProps;

export const Wizard: React.FC<WizardProps> = ({ children, sx = {}, ...props }) => {
	return (
		<Container
			flex={'1 1 auto'}
			variant={'text.medium'}
			p={0}
			sx={{
				display: 'grid',
				gridTemplateColumns: 'auto 1fr',
				gridTemplateAreas: '"sidebar content"',
				overflow: 'hidden',
				...sx,
			}}
			{...props}
		>
			{children}
		</Container>
	);
};
