/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { ReactElement } from 'react';
import { Stack } from 'components';

import StepConfigurationSidebar from './StepConfigurationSidebar/StepConfigurationSidebar';
import MissingEnvironmentBlurOverlay from './MissingEnvironmentBlurOverlay';
import ActionsSidebar from './ActionsSidebar/ActionsSidebar';
import { EditorSettingsContext } from './useEditorSettings';
import ExperimentSubHeader from './ExperimentSubHeader';
import UnsavedChangesPromt from './UnsavedChangesPromt';
import DragAndDropHandler from './DragAndDropHandler';
import ExperimentHeader from './ExperimentHeader';
import Workspace from './Workspace/Workspace';
import { ActionsContext } from './useActions';

export default function ExperimentEditor(): ReactElement {
	const actions = usePromise(() => Services.actions.fetchActions(), []);

	return (
		<EditorSettingsContext.Provider value={{ mode: 'experiment' }}>
			<ActionsContext.Provider value={{ actions: actions.value || [] }}>
				<UnsavedChangesPromt />
				<Stack size="none">
					<ExperimentHeader section="design" />
					<ExperimentSubHeader />

					{/* Since items can be dragged from the sidebar to the workspace, the context must be mounted here */}
					<DragAndDropHandler>
						<Stack direction="horizontal" size="none">
							<ActionsSidebar />
							<Workspace mode="experiment" />
						</Stack>
					</DragAndDropHandler>

					<MissingEnvironmentBlurOverlay />

					<StepConfigurationSidebar />
				</Stack>
			</ActionsContext.Provider>
		</EditorSettingsContext.Provider>
	);
}
