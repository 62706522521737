/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { FieldHookConfig, useField } from 'formik';
import { Container, Text } from 'components';
import React from 'react';

import { ParsingUnitField, ParsingUnitFieldProps, UnitFieldUnit } from '../UnitField';
import { hasError } from './utils';

const BIT = { value: 'bit', label: 'bit/s' };
const KBIT = { value: 'kbit', label: 'kbit/s' };
const MBIT = { value: 'mbit', label: 'mbit/s' };
const GBIT = { value: 'gbit', label: 'gbit/s' };
const TBIT = { value: 'tbit', label: 'tbit/s' };
const BPS = { value: 'bps', label: 'byte/s' };
const KBPS = { value: 'kbps', label: 'kb/s' };
const MBPS = { value: 'mbps', label: 'mb/s' };
const GBPS = { value: 'gbps', label: 'gb/s' };
const TBPS = { value: 'tbps', label: 'tb/s' };
const BITRATE_UNITS: UnitFieldUnit[] = [BIT, KBIT, MBIT, GBIT, TBIT, BPS, KBPS, MBPS, GBPS, TBPS];

function parseBitrate(value?: string): { unit: UnitFieldUnit; value?: string } {
	if (value) {
		const m = /^([+-]?\d+)([a-zA-Z]{0,4})$/.exec(value);
		if (m) {
			return { value: m[1] ?? '', unit: BITRATE_UNITS.find((u) => u.value === m[2]) ?? KBIT };
		}
	}
	return { value: '', unit: KBIT };
}

export type FormikBitrateFieldProps = Omit<
	ParsingUnitFieldProps,
	'unit' | 'units' | 'onUnitChange' | 'onChange' | 'parse' | 'ref'
>;

export const FormikBitrateField = React.forwardRef<HTMLInputElement, FormikBitrateFieldProps>(
	({ flex, my, mx, mt, mb, ml, mr, width, ...props }, ref) => {
		const [field, meta, { setValue, setTouched }] = useField(props as FieldHookConfig<string>);

		return (
			<Container
				ref={ref}
				display={'flex'}
				flexDirection={'column'}
				my={my}
				mx={mx}
				mt={mt}
				mb={mb}
				ml={ml}
				mr={mr}
				width={width}
				flex={flex}
			>
				<ParsingUnitField
					{...field}
					{...meta}
					units={BITRATE_UNITS}
					onChange={({ value, unit }) => {
						setValue(`${value}${unit.value}`);
						setTouched(true, false);
					}}
					parse={parseBitrate}
					type={'number'}
					hasError={hasError(meta)}
					{...props}
				/>
				{hasError(meta) ? (
					<Text mt={'xxSmall'} variant={'smallStrong'} color={'coral'} data-formik-error>
						{meta.error}
					</Text>
				) : null}
			</Container>
		);
	},
);
FormikBitrateField.displayName = 'FormikBitrateField';
