/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { LineChart, ResponsiveContainer, XAxis } from 'recharts';
import { ReactElement, ReactNode, useMemo } from 'react';
import { Container, Stack } from 'components';
import { theme } from 'styles.v2/theme';
import { range } from 'lodash';

import { ExperimentRunLogsAndMetricsProps } from '../experimentExecutionLogsAndMetrics';
import { formatTime } from '../../../../utils/dateFns';

interface ChartWithTimeAxisProps extends Pick<ExperimentRunLogsAndMetricsProps, 'position' | 'start' | 'duration'> {
	children: ReactNode;
}

export default function ChartWithTimeAxis({
	children,
	position,
	start,
	duration,
}: ChartWithTimeAxisProps): ReactElement {
	const ticks = useMemo(() => {
		const tickCount = Math.min(8, duration / 1000);
		return range(start, start + duration + 1, duration / tickCount);
	}, [start, duration]);

	return (
		<Stack size="none" sx={{ position: 'relative' }}>
			{children}
			<ResponsiveContainer width="100%" height={32}>
				<LineChart margin={{ left: 168, top: 0, right: 0, bottom: 0 }}>
					<XAxis
						{...theme.charts.axes}
						dataKey="ts"
						type="number"
						domain={[start, start + duration]}
						ticks={ticks}
						scale={'time'}
						tickFormatter={(t) => formatTime(new Date(t))}
					/>
				</LineChart>
			</ResponsiveContainer>
			{position && (
				<Container sx={{ position: 'absolute', top: 0, left: 168, right: 0, bottom: 30, pointerEvents: 'none' }}>
					<Container
						sx={{
							position: 'absolute',
							top: 0,
							bottom: 0,
							left: `${Math.max(0, 100 * ((position - start) / duration))}%`,
							width: 2,
							backgroundColor: theme.colors.slate,
						}}
					/>
				</Container>
			)}
		</Stack>
	);
}
