/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React from 'react';

type DividerProps = ContainerProps & {
	variant?: 'left' | 'center' | 'right';
};

export const Divider: React.FC<DividerProps> = ({ children, variant = 'center', sx = {}, ...props }) => {
	const content = '';
	return (
		<Container
			display={'flex'}
			color={'neutral600'}
			alignItems={'center'}
			{...props}
			sx={{
				variant: 'text.smallStrong',
				textTransform: 'uppercase',

				'&::before, &::after': {
					content: `'${content}'`,
					flex: 1,
					borderBottom: '1px solid',
					color: props.color || 'neutral200',
				},
				'&::before': {
					marginRight: children ? 'xxSmall' : undefined,
					display: variant === 'left' ? 'none' : 'block',
				},
				'&::after ': {
					marginLeft: children ? 'small' : undefined,
					display: variant === 'right' ? 'none' : 'block',
				},
				...sx,
			}}
		>
			{children}
		</Container>
	);
};
