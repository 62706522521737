/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { useNowObservable } from 'utils/now';
import { ReactElement } from 'react';

import { sizes, sizeToPixel } from './ButtonRound';

interface ProgressIndicatorProps {
	progress?: number;
	start?: number | undefined;
	end?: number | undefined;

	size?: sizes;
	inversed?: boolean;
}

export default function ProgressIndicator({
	inversed = false,
	size = 'large',
	progress = 0,
	start = 0,
	end = 0,
}: ProgressIndicatorProps): ReactElement | null {
	const now = useNowObservable();

	if (!progress && progress !== 0 && (!start || !end)) {
		return null;
	}

	if (start && end) {
		const progressed = now.valueOf() - start;
		const duration = end - start;
		progress = progressed / duration;
	}
	progress = Math.max(Math.min(progress, 1.0), 0.0);
	if (inversed) {
		progress = 1 - progress;
	}

	const strokeWidth = size === 'large' ? 3 : 2;
	const pixelSize = sizeToPixel[size] - 2 * strokeWidth;

	const viewBox = `0 0 ${pixelSize} ${pixelSize}`;
	const radius = (pixelSize - strokeWidth) / 2;
	const circumference = radius * Math.PI * 2;
	const dash = progress * circumference;

	return (
		<svg
			width={pixelSize}
			height={pixelSize}
			viewBox={viewBox}
			style={{
				position: 'absolute',
				top: strokeWidth,
				bottom: strokeWidth,
				left: strokeWidth,
				right: strokeWidth,
			}}
		>
			<circle
				style={{
					transition: 'all 1s linear',
				}}
				fill="none"
				stroke={'white'}
				cx={pixelSize / 2}
				cy={pixelSize / 2}
				r={radius}
				strokeWidth={strokeWidth}
				transform={`rotate(-90 ${pixelSize / 2} ${pixelSize / 2})`}
				strokeDasharray={`${dash} ${circumference - dash}`}
				strokeLinecap="round"
			/>
		</svg>
	);
}
