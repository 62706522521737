/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Code,
	Combobox,
	Container,
	Label,
	Link,
	ModalContentV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	Stack,
	Text,
	TextField,
	Tooltip,
} from 'components';
import { IconInformationCircle, IconWarning } from 'components/icons';
import { Formik, useFormikContext } from 'formik';
import { getBaseHref } from 'utils/getBaseHref';
import { useTenant } from 'tenancy/useTenant';
import { ReactElement } from 'react';

import ExperimentTags from '../../experimentsV2/ExperimentTags';
import { useTeam } from '../../../services/useTeam';

type TagBadgeModalProps = {
	onClose: () => void;
};

type TagBadgeValues = {
	tags: string[];
	teamId: string;
	codeType: string;
	scale: string;
	caption: string;
};

const getTagBadgeInitialValues = (teamId: string): TagBadgeValues => ({
	tags: [],
	teamId,
	codeType: codeTypeOptions[0].value,
	scale: scaleOptions[0].value,
	caption: 'Create Experiment for Tag',
});

type CodeTypeOptionType = { value: 'HTML' | 'MARKDOWN' | 'IMAGE'; label: string };
const codeTypeOptions: CodeTypeOptionType[] = [
	{ value: 'HTML', label: 'HTML' },
	{ value: 'MARKDOWN', label: 'Markdown' },
	{ value: 'IMAGE', label: 'Image only' },
];

type ScaleOptionType = { value: string; label: string };
const scaleOptions: ScaleOptionType[] = [
	{ value: '1', label: '1x' },
	{ value: '2', label: '2x' },
	{ value: '3', label: '3x' },
];

export default function TagBadgeModal({ onClose }: TagBadgeModalProps): ReactElement {
	const team = useTeam();
	return (
		<ModalOverlay open onClose={onClose}>
			{({ close }) => (
				<ModalV2>
					<ModalHeaderV2 title={'Create a Tag Badge'} onClose={close} />
					<ModalContentV2>
						<Formik initialValues={getTagBadgeInitialValues(team.id)} onSubmit={() => {}}>
							<TagBadgeForm />
						</Formik>
					</ModalContentV2>
				</ModalV2>
			)}
		</ModalOverlay>
	);
}

function TagBadgeForm(): ReactElement {
	const formik = useFormikContext<TagBadgeValues>();
	const { tags, codeType, caption, scale } = formik.values;
	const tag = tags.length > 0 ? tags[0] : undefined;
	const tenant = useTenant();

	const imageUrl = `${getBaseHref()}api/badges/linked-badge.svg?tenantKey=${tenant.key}&scale=${scale}&tag=${tag}&createCaption=${encodeURI(`${caption} ${tag}`)}`;
	const linkUrl = `${getBaseHref()}api/badges/link?tenantKey=${tenant.key}&tag=${tag}`;
	const html = `<a href='${linkUrl}' target='_blank'><img alt='${tag}' src='${imageUrl}'></a>`;
	const markdown = `[![${tag}](${imageUrl})](${linkUrl})`;

	return (
		<Stack size={'medium'}>
			<Text muted>
				You can create a badge for any experiment tag. It will automatically update to reflect the current state. Refer
				to our{' '}
				<Link href={'https://docs.steadybit.com/integrate-with-steadybit/badges#tag-badges'} external>
					documentation
				</Link>{' '}
				for the details.
				<br />
				<br />
				Provide the tag that will be automatically added to new experiments created via the badge and be used to
				identify any existing experiment the badge should refer to.
			</Text>

			<Container
				width={'100%'}
				sx={{
					display: 'grid',
					gridTemplateColumns: '200px 200px',
					justifyItems: 'start',
					columnGap: 'xSmall',
					rowGap: 'medium',
				}}
			>
				<Label sx={{ maxWidth: '100%' }}>
					<Stack size={'small'}>
						Tag
						<ExperimentTags maxTags={1} />
					</Stack>
				</Label>
				<Label sx={{ justifySelf: 'stretch' }}>
					<Container display="flex" alignItems="center" justifyContent="space-between" pr="xxSmall">
						<Container
							sx={{
								display: 'flex',
								alignItems: 'flex-start',
								justifyContent: 'space-between',
								gap: '8px',
							}}
						>
							<Text variant="smallMedium" sx={{ minWidth: 'min-content' }}>
								Call to action
							</Text>

							<Tooltip
								color="light"
								placement="bottom"
								content={
									<Text variant="small" maxWidth="25em">
										The call to action will be shown when no experiment with the tag exists
									</Text>
								}
							>
								<div style={{ lineHeight: '16px' }}>
									<IconInformationCircle variant="small" color="neutral400" mt={2} />
								</div>
							</Tooltip>
						</Container>
					</Container>

					<TextField
						value={caption}
						variant={'small'}
						onChange={(e) => formik.setFieldValue('caption', e.target.value)}
					/>
				</Label>
				<Label>
					Format
					<Combobox<CodeTypeOptionType>
						width={'120px'}
						options={codeTypeOptions}
						value={codeTypeOptions.find((codeTypeOption) => codeTypeOption.value === codeType)}
						onChange={(option) => {
							if (option) {
								formik.setFieldValue('codeType', option.value);
							}
						}}
						name={'codeType'}
						variant={'small'}
					/>
				</Label>
				<Label>
					Scale
					<Combobox<ScaleOptionType>
						width={'80px'}
						options={scaleOptions}
						value={scaleOptions.find((scaleOption) => scaleOption.value == scale)}
						onChange={(option) => {
							if (option) {
								formik.setFieldValue('scale', option.value);
							}
						}}
						name={'scale'}
						variant={'small'}
					/>
				</Label>
			</Container>
			<Stack variant={'xSmall'} sx={{ gridColumn: 'span 2' }}>
				<Label>Result</Label>
				{tag ? (
					<Stack variant={'xSmall'}>
						<Container>
							<Link href={linkUrl} target={'_blank'}>
								<img src={imageUrl} key={`example-badge-${codeType}`} alt={'Experiment Tag Badge'} />
							</Link>
						</Container>
						<Code
							lang={codeType === 'HTML' || codeType === 'MARKDOWN' ? 'markdown' : 'jsx'}
							sx={{ whiteSpace: 'normal' }}
							withCopyToClipboard
						>
							{codeType === 'HTML' ? html : codeType === 'MARKDOWN' ? markdown : imageUrl}
						</Code>
					</Stack>
				) : (
					<Stack
						direction={'horizontal'}
						size={'xxSmall'}
						alignItems={'center'}
						color={formik.getFieldMeta('tags').touched ? 'coral' : 'neutral500'}
					>
						<IconWarning variant={'small'} />
						<Text>Please provide a new tag or choose an existing one</Text>
					</Stack>
				)}
			</Stack>
		</Stack>
	);
}
