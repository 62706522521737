/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import React, { ReactNode } from 'react';

import { Tooltip } from '../Tooltip';
import { IconHelp } from '../icons';

export const Help: React.FC<{
	variant?: string;
	color?: string;
	children: ReactNode;
	onClick?: (event: React.MouseEvent) => void;
}> = ({ children, variant, color, onClick }) => {
	return (
		<Tooltip content={children}>
			<span>
				<IconHelp
					onClick={onClick}
					variant={variant}
					color={color ?? 'neutral100'}
					sx={{ verticalAlign: 'baseline', ...(onClick ? { cursor: 'pointer' } : {}) }}
				/>
			</span>
		</Tooltip>
	);
};
