/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ButtonRunIconStop, Container, RouterLink, ShortenedText, Snackbar, Stack, Text, TimeDiff } from 'components';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { RunningExperimentVO } from 'ui-api';
import { Services } from 'services/services';
import React from 'react';

export const RunningExperiments: React.VFC<{ running: RunningExperimentVO[] }> = ({ running }) => {
	const [users] = useAsyncState(
		() => Services.users.findUsersByUsername(running.map((run) => run.createdBy)),
		[running],
		new Map(),
	);

	const handleExecutionStopClick = async (experimentExecutionId: number): Promise<void> => {
		try {
			await Services.experiments.cancelExperimentRun(experimentExecutionId);
		} catch (err) {
			Snackbar.error('Experiment not canceled: ' + err.toString(), { toastId: 'experiment-cancel' });
		}
	};

	const experimentExecutionIds: number[] = running.map((vo) => vo.executionId).sort();

	return (
		<Stack size="none">
			{experimentExecutionIds.map((experimentExecutionId) => {
				const runningExperiment = running.find((exe) => exe.executionId === experimentExecutionId);
				return (
					<Container
						key={experimentExecutionId}
						mt={'xSmall'}
						width={'100%'}
						sx={{
							display: 'grid',
							gridTemplateColumns: 'auto 1fr auto',
							columnGap: 'xSmall',
							alignItems: 'center',

							borderRadius: '4px',
							px: 'xSmall',
							':hover': {
								bg: 'neutral100',
							},
						}}
					>
						{runningExperiment && runningExperiment._actions.includes('cancel') ? (
							<Container
								color="coral"
								onClick={(e) => {
									handleExecutionStopClick(experimentExecutionId);
									e.preventDefault();
									e.stopPropagation();
								}}
								sx={{ width: '32px', height: '32px', cursor: 'pointer' }}
							>
								<ButtonRunIconStop
									started={runningExperiment.started}
									estimatedEnd={runningExperiment.estimatedEnd}
									size={'large'}
									color={'slate'}
									sx={{
										borderRadius: '16px',
										':hover': {
											bg: 'coralLight',
											color: 'coral',
										},
										':focus': {
											outline: 'none',
											boxShadow: '0 0 0 1px currentColor',
										},
									}}
								/>
							</Container>
						) : null}
						<RouterLink
							to={`/experiments/edit/${runningExperiment?.experimentKey}/executions/${runningExperiment?.executionId}`}
							variant={'secondary'}
							my={'xSmall'}
						>
							<ShortenedText noWrap>
								<Text variant={'mediumStrong'} as={'span'} mr={'xSmall'}>
									{runningExperiment?.experimentKey}
								</Text>
								<Text variant={'medium'} as={'span'} mr="xSmall">
									{runningExperiment?.name}
								</Text>
							</ShortenedText>
							<ShortenedText noWrap sx={{ marginTop: -2 }}>
								<Text variant={'small'} color={'neutral600'} as={'span'} data-private>
									#{runningExperiment?.executionId} by{' '}
									{users.value.get(runningExperiment?.createdBy)?.name ?? runningExperiment?.createdBy}{' '}
								</Text>
							</ShortenedText>
						</RouterLink>

						<Text color={'neutral600'} variant={'small'}>
							{runningExperiment?.estimatedEnd && runningExperiment?.started ? (
								<TimeDiff value={runningExperiment.estimatedEnd} />
							) : (
								'...'
							)}
						</Text>
					</Container>
				);
			})}
		</Stack>
	);
};
