/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Container, ModalOverlay, Stack, Text, Tooltip, UserIcon } from 'components';
import AccountSettings from 'pages/settings/account/accountSettings';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { AnimatePresence, motion } from 'framer-motion';
import { IconLogout } from 'components/icons';
import { Services } from 'services/services';
import { useUser } from 'services/usersApi';
import { ReactElement } from 'react';

interface UserItemProps {
	expanded: boolean;
	onClick: () => void;
}

export default function UserItem({ expanded, onClick }: UserItemProps): ReactElement {
	const user = useUser();

	return (
		<div style={{ display: 'flex', marginLeft: '-5px' }}>
			<Tooltip content="Your account settings">
				<Stack
					direction="horizontal"
					size="xSmall"
					sx={{
						alignItems: 'center',
						p: '4px 8px',

						borderRadius: '4px 0 0 4px',
						overflow: 'hidden',

						cursor: 'pointer',
						'&:hover': {
							backgroundColor: 'purple600',
						},
					}}
					data-cy="icon-user"
					onClick={onClick}
				>
					<UserIcon
						src={user.pictureUrl}
						sx={{
							minHeight: '40px',
							minWidth: '40px',
							width: '40px',
							height: '40px',
						}}
						data-private
					/>
					{expanded && (
						<Stack direction="vertical" size="none">
							<Text variant="smallStrong" color="neutral000" sx={{ ...textEllipsis }} data-private>
								{user.name}
							</Text>
							<Text variant="smallMedium" color="purple200" sx={{ ...textEllipsis }} data-private>
								{user.email}
							</Text>
						</Stack>
					)}
				</Stack>
			</Tooltip>

			{expanded && (
				<AnimatePresence initial>
					<motion.div
						initial={{ width: 0, opacity: 0 }}
						animate={{ width: 'fit-content', opacity: 1 }}
						transition={{ delay: 0.05 }}
					>
						<Tooltip content="Sign out from Steadybit">
							<Container
								sx={{
									display: 'flex',
									alignItems: 'center',

									padding: '12px 12px',

									borderRadius: '0 4px 4px 0',
									cursor: 'pointer',
									'&:hover': {
										backgroundColor: 'purple600',
									},
								}}
								onClick={async () => {
									await Services.auth.logout();
									window.location.assign('/');
								}}
								data-cy="logout"
							>
								<IconLogout color="purple200" />
							</Container>
						</Tooltip>
					</motion.div>
				</AnimatePresence>
			)}
		</div>
	);
}

export function AccountSettingsModal({ onClose }: { onClose: () => void }): ReactElement {
	return (
		<ModalOverlay centerContent open onClose={onClose}>
			{({ close }) => <AccountSettings onClose={close} />}
		</ModalOverlay>
	);
}
