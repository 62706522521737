/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react-markdown/lib/react-markdown';
import React, { useEffect } from 'react';
import { clamp } from 'lodash';

import BlastRadius from './illustration.svg';

type BlastCanvasProps = React.SVGProps<SVGSVGElement> & {
	impacted: number;
	total: number;
};

export default function BlastCanvas({ impacted, total }: BlastCanvasProps): ReactElement {
	useEffect(() => {
		const ufoAttackBackground = document.getElementById('ufoAttackBackground') as HTMLElement | undefined;
		const blastRadiusAffected = document.getElementById('blastRadiusAffected') as HTMLElement | undefined;

		if (ufoAttackBackground) {
			ufoAttackBackground.style.filter = 'blur(0.8px)';
		}

		if (blastRadiusAffected) {
			if (total > 0 && impacted > 0) {
				blastRadiusAffected.style.transform = `scale(${clamp(impacted / total, 0.05, 1)})`;
				blastRadiusAffected.style.transformOrigin = '56px 70px';
			} else {
				blastRadiusAffected.style.transform = 'scale(0)';
				blastRadiusAffected.style.transformOrigin = '56px 70px';
			}
		}
	}, [impacted, total]);

	return <BlastRadius />;
}
