/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ReactElement, ReactNode } from 'react';
import { formatTime } from 'utils/dateFns';
import { Container } from 'components';

interface ExperimentExecutionTimerangeProps {
	start: number;
	end: number | undefined;
	state: string;
	experimentExecutionEnd: number | undefined;
}

export default function ExperimentExecutionTimerange({
	start,
	end,
	state,
	experimentExecutionEnd,
}: ExperimentExecutionTimerangeProps): ReactElement | null {
	if (state === 'SKIPPED' || state === 'PREPARED') {
		return null;
	}

	if (state === 'RUNNING') {
		return <TimeContainer>{formatTime(new Date(start))} - </TimeContainer>;
	}

	if ((state === 'CANCELED' || state === 'FAILED' || state === 'ERRORED') && experimentExecutionEnd) {
		return (
			<TimeContainer>
				{formatTime(new Date(start))} -{' '}
				{formatTime(new Date(end ? Math.min(end, experimentExecutionEnd) : experimentExecutionEnd))}
			</TimeContainer>
		);
	}

	if (end) {
		return (
			<TimeContainer>
				{formatTime(new Date(start))} - {formatTime(new Date(end))}
			</TimeContainer>
		);
	}

	return <TimeContainer>{formatTime(new Date(start))} -</TimeContainer>;
}

interface TimeContainerProps {
	children: ReactNode;
}
function TimeContainer({ children }: TimeContainerProps): ReactElement {
	return (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				width: 'fit-content',
				fontWeight: '600',
				px: '4px',
				py: '4px',
				bg: 'slate',
				fontSize: 12,
				lineHeight: 1,
				color: 'neutral000',
				borderRadius: 4,
			}}
		>
			{children}
		</Container>
	);
}
