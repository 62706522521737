/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { BaseExperimentStepExecutionVOUnion, BaseExperimentStepVOUnion } from 'ui-api';
import { Container, Stack, Text } from 'components';
import { ActionInfoBox } from 'hocs/ActionInfo';
import { IconWarning } from 'components/icons';
import React from 'react';

export const ExperimentStepInfo: React.VFC<{
	step: BaseExperimentStepVOUnion | BaseExperimentStepExecutionVOUnion | null;
	errors?: string[];
}> = ({ errors, step }) => {
	if (!step) {
		return null;
	}
	return step.type === 'wait' ? (
		<Container>
			<Text variant={'smallStrong'} mb={'xxSmall'}>
				{step.customLabel ? step.customLabel : 'Wait'}
			</Text>
			<Stack size={2}>
				{step.customLabel ? <Text variant={'small'}>Type: Wait</Text> : null}
				<Text variant={'small'}>Duration: {step.parameters.duration}</Text>
			</Stack>
		</Container>
	) : step.type === 'action' ? (
		<>
			{errors && errors.length > 0 && (
				<>
					<Container display="flex" alignItems="center" py="xSmall" color="coral">
						<IconWarning variant="small" mr="xxSmall" />
						Errors:
					</Container>
					<Stack pb="small">
						{errors.map((error) => (
							<Text key={error} variant="small" color="coral">
								{error}
							</Text>
						))}
					</Stack>
				</>
			)}
			<ActionInfoBox value={step} />
		</>
	) : null;
};
