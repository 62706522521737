/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { LocationWithUrlParams, MatrixParameters, Parameters } from './type';

/**
 * A mostly manual clone implementation for performance reasons.
 */
export function clone<T>({ query, matrix, ...other }: LocationWithUrlParams<T>): LocationWithUrlParams<T> {
	return {
		...other,
		query: cloneParameters(query),
		matrix: cloneMatrixParameters(matrix),
	};
}

function cloneMatrixParameters(matrixParameters: MatrixParameters): MatrixParameters {
	if (matrixParameters == null) {
		return {};
	}

	const clone: MatrixParameters = {};
	for (const [path, parameters] of Object.entries(matrixParameters)) {
		clone[path] = cloneParameters(parameters);
	}
	return clone;
}

/**
 * shallow clone - we do not support cloning of arbitrary objects within
 * the parameters.
 */
function cloneParameters(parameters: Parameters): Parameters {
	if (parameters == null) {
		return {};
	}
	return { ...parameters };
}
