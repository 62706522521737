/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	Checkbox,
	Label,
	ModalContentV2,
	ModalFooterV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	Snackbar,
	Stack,
	Text,
} from 'components';
import { ReactElement, useState } from 'react';
import { Services } from 'services/services';
import { HubVO } from 'ui-api';

interface DeleteHubModalProps {
	hub: HubVO;
	onClose: () => void;
}

export default function DeleteHubModal({ hub, onClose }: DeleteHubModalProps): ReactElement {
	const [checked, setChecked] = useState(false);

	return (
		<ModalOverlay open centerContent onClose={onClose}>
			<ModalV2 slick withFooter width="90vw" maxWidth="780px">
				<ModalHeaderV2
					title="Are you sure you want to delete this Hub?"
					subTitle="This action can’t be undone. If you’ll need this Hub in the future, you’ll have to add it manually again; I mean, it’s not a lot of work, but still."
					onClose={onClose}
				/>
				<ModalContentV2>
					<Label>
						<Stack direction="horizontal" size="xSmall" alignItems="flex-start" mt="small">
							<Checkbox
								id="disconnect-hub-checkbox"
								checked={checked}
								minHeight={18}
								minWidth={18}
								mt={2}
								onChange={(e) => setChecked(e.target.checked)}
							/>
							<Text variant="medium" color="neutral800" htmlFor="delete-team-checkbox">
								Delete all imported templates from this Hub (this won’t affect experiments created from them)
							</Text>
						</Stack>
					</Label>
				</ModalContentV2>

				<ModalFooterV2>
					<Stack direction="horizontal" size="small" justifyContent="space-between" width="100%">
						<Button variant="secondary" onClick={onClose}>
							Cancel
						</Button>
						<Button
							variant="primary"
							onClick={async () => {
								try {
									await Services.hubsApi.disconnectHub(hub.id, checked);
									Snackbar.dark('Hub deleted.', { toastId: 'hub-deleted' });
								} catch (err) {
									Snackbar.error('Hub not deleted: ' + err.toString(), { toastId: 'hub-deleted' });
								}
								onClose();
							}}
						>
							Delete Hub
						</Button>
					</Stack>
				</ModalFooterV2>
			</ModalV2>
		</ModalOverlay>
	);
}
