/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { UnstyledLink, UnstyledLinkProps } from 'components';
import React from 'react';

export type LinkProps = UnstyledLinkProps;

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
	(
		{
			children,
			variant = 'primary',
			sx = {},
			disabled,

			backgroundColor,
			bg,
			color,
			height,
			minHeight,
			maxHeight,
			width,
			minWidth,
			maxWidth,
			m,
			mb,
			ml,
			mr,
			mt,
			mx,
			my,
			p,
			pb,
			lineHeight,
			pl,
			pr,
			pt,
			px,
			py,
			overflow,
			overflowX,
			overflowY,
			opacity,
			tabIndex,

			...props
		},
		ref,
	) => (
		<UnstyledLink
			ref={ref}
			variant={variant}
			tx={'links'}
			sx={{
				display: 'inline',
				background: 'none',
				border: 'none',
				textAlign: 'inherit',
				padding: 0,
				cursor: disabled ? 'initial' : 'pointer',
				pointerEvents: disabled ? 'none' : 'initial',
				textDecoration: 'none',
				whiteSpace: 'nowrap',
				backgroundColor,
				bg,
				color,
				lineHeight,
				height,
				minHeight,
				maxHeight,
				width: typeof width === 'number' ? `${width}px` : width,
				minWidth,
				maxWidth,
				m,
				mb,
				ml,
				mr,
				mt,
				mx,
				my,
				p,
				pb,
				pl,
				pr,
				pt,
				px,
				py,
				overflow,
				overflowX,
				overflowY,
				opacity,
				tabIndex,
				...sx,
			}}
			disabled={disabled}
			{...props}
		>
			{children}
		</UnstyledLink>
	),
);
Link.displayName = 'Link';
