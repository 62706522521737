/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import React from 'react';

import { Text, TextProps } from './Text';
import { Tooltip } from '../Tooltip';

export type ShortenedTextProps = { tooltip?: React.ReactNode; noWrap?: boolean } & TextProps;

export const ShortenedText = React.forwardRef<HTMLDivElement, ShortenedTextProps>(
	({ children, tooltip, noWrap = false, sx, ...props }, ref) => (
		<Tooltip onlyShowOnEllipsis content={tooltip ?? children}>
			<Text
				ref={ref}
				{...props}
				sx={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: noWrap ? 'nowrap' : undefined,
					...sx,
				}}
			>
				{children}
			</Text>
		</Tooltip>
	),
);
ShortenedText.displayName = 'ShortenedText';
