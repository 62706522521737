/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { IconChevronRight, IconInformation } from 'components/icons';
import { Container, Link, Stack, Text } from 'components';
import { ReactElement, ReactNode } from 'react';

interface InfoBoxProps {
	children: ReactNode;
}

export default function InfoBox({ children }: InfoBoxProps): ReactElement {
	return (
		<Stack
			direction="horizontal"
			size="xSmall"
			sx={{
				borderRadius: 4,
				bg: 'purple200',
				py: 'xSmall',
				px: 'small',
			}}
		>
			<IconInformation color="slate" />

			<Container>{children}</Container>
		</Stack>
	);
}

interface ExternalLinkProps {
	label: string;
	href: string;
}

export function ExternalLink({ label, href }: ExternalLinkProps): ReactElement {
	return (
		<Link href={href} target="_blank" rel="noopener">
			<Stack direction="horizontal" alignItems="center" size="none">
				<Text variant="smallStrong" color="purple800">
					{label}
				</Text>
				<IconChevronRight variant="xSmall" color="purple800" />
			</Stack>
		</Link>
	);
}
