/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ReactElement, ReactNode, useEffect } from 'react';
import { Container, Text, Tooltip } from 'components';

import useKeyboardNavigation from './components/useKeyboardNavigation';
import DropdownContentFrame from './components/DropdownContentFrame';
import ItemList, { MAX_ITEMS } from './components/ItemList';

interface Label<T> {
	id: string;
	label: string;
	data?: T;
}

interface LabelsProps<T> {
	onSelect: (label: Label<T>) => void;
	onLoadMoreClick?: () => void;
	additionalEntry?: ReactNode;
	maxHeight?: string | number;
	type: 'loose' | 'strict';
	withoutTooltip?: boolean;
	width?: number | string;
	queryString?: string;
	selectedId?: string;
	labels: Label<T>[];
	maxItems?: number;
}

export default function Labels<T = unknown>({
	maxItems = MAX_ITEMS,
	queryString = '',
	onLoadMoreClick,
	additionalEntry,
	withoutTooltip,
	maxHeight,
	selectedId,
	onSelect,
	labels,
	width,
	type,
}: LabelsProps<T>): ReactElement | null {
	const fileredLabels = labels.filter(({ label }) => label.toLowerCase().includes(queryString.toLowerCase()));
	const [hoveredIndex, setHoveredIndex] = useKeyboardNavigation(fileredLabels.length, (i) =>
		onSelect(fileredLabels[i]),
	);

	useEffect(() => {
		if (hoveredIndex !== undefined && hoveredIndex >= 0) {
			const element = document.getElementById(`item-${hoveredIndex}`);
			if (element) {
				element.scrollIntoView({ block: 'nearest', inline: 'start' });
			}
		}
	}, [hoveredIndex]);

	if (fileredLabels.length === 0 && !additionalEntry) {
		return null;
	}

	const selectedIndex = fileredLabels.findIndex(({ id }) => id === selectedId);

	return (
		<DropdownContentFrame
			maxHeight={maxHeight}
			maxWidth="100%"
			sx={{
				py: type === 'loose' ? 'xxSmall' : 'none',
			}}
		>
			<>
				<ItemList
					type={type}
					width={width}
					maxItems={maxItems}
					onClick={(i) => onSelect(fileredLabels[i])}
					totalItems={fileredLabels.length}
					onHover={setHoveredIndex}
					highlightedIndices={[hoveredIndex, selectedIndex]}
					onLoadMoreClick={onLoadMoreClick}
				>
					{fileredLabels.slice(0, maxItems).map(({ id, label }) => {
						const content = (
							<Text key={id} color="neutral800" variant="small" sx={{ ...textEllipsis }}>
								{label}
							</Text>
						);
						if (withoutTooltip) {
							return content;
						}
						return (
							<Tooltip
								key={id}
								content={label}
								placement="top-end"
								bindWidth={{
									target: 'reference',
									offset: 20,
								}}
							>
								{content}
							</Tooltip>
						);
					})}
				</ItemList>
				{!!additionalEntry && <Container onMouseEnter={() => setHoveredIndex(-1)}>{additionalEntry}</Container>}
			</>
		</DropdownContentFrame>
	);
}
