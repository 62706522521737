/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { from, Observable } from 'rxjs';
import { DependencyList } from 'react';

import { createResult, createErrorResult, DataStreamResult, isDataStreamResultLike } from './stream/result';
import { useObservable } from './useObservable';

export type Creator<T> = () => T | PromiseLike<T>;

export function usePromise<T>(fn: Creator<DataStreamResult<T>>, dependencies: DependencyList): DataStreamResult<T>;
export function usePromise<T>(fn: Creator<T>, dependencies: DependencyList): DataStreamResult<T>;
export function usePromise<T>(fn: Creator<T>, dependencies: DependencyList): DataStreamResult<T> {
	return useObservable(() => toDataStreamResultObservable(fn()), dependencies);
}

export function toDataStreamResultObservable<T>(
	input: DataStreamResult<T> | PromiseLike<DataStreamResult<T>>,
): Observable<DataStreamResult<T>>;
export function toDataStreamResultObservable<T>(input: T | PromiseLike<T>): Observable<DataStreamResult<T>>;
export function toDataStreamResultObservable<T>(input: T | PromiseLike<T>): Observable<DataStreamResult<T>> {
	const promise = Promise.resolve(input).then(
		(v) => {
			if (isDataStreamResultLike(v)) {
				return v;
			}
			return createResult(v);
		},
		(err) => {
			if (isDataStreamResultLike(err)) {
				return err;
			}
			return createErrorResult(err.message, err.response?.status);
		},
	);
	return from(promise);
}
