/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { DependencyList } from 'react';

import { ReExecute, useObservableWithReExecution } from './useObservableWithReExecution';
import { Creator, toDataStreamResultObservable } from './usePromise';
import { DataStreamResult } from './stream/result';

export function usePromiseWithReExecution<T>(
	fn: Creator<DataStreamResult<T>>,
	dependencies: DependencyList,
): [DataStreamResult<T>, ReExecute];
export function usePromiseWithReExecution<T>(
	fn: Creator<T>,
	dependencies: DependencyList,
): [DataStreamResult<T>, ReExecute];
export function usePromiseWithReExecution<T>(
	fn: Creator<T>,
	dependencies: DependencyList,
): [DataStreamResult<T>, ReExecute] {
	return useObservableWithReExecution(() => toDataStreamResultObservable(fn()), dependencies);
}
