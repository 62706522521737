/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { EnvironmentVariableVO } from 'ui-api';
import { Container, Pill } from 'components';
import { Stack } from 'components/Stack';
import { ReactElement } from 'react';

interface EnvVariablesProps {
	variables: EnvironmentVariableVO[] | string[];
	onVariableClick: (variableKey: string) => void;
	withHelpText?: boolean;
}

export default function EnvVariables({ variables, onVariableClick }: EnvVariablesProps): ReactElement | null {
	if (variables.length === 0) {
		return null;
	}

	return (
		<Container p={'small'}>
			<Stack direction="vertical" size="xSmall" alignItems={'flex-start'}>
				{variables.map((variable) => {
					const variableKey = isEnvironmentVariableVO(variable) ? variable.key : variable;

					return (
						<Pill
							key={variableKey}
							backgroundColor="slateMidLight40p"
							backgroundColorOnHover="slateMidLight"
							color="neutral800"
							onClick={() => onVariableClick(variableKey)}
						>
							{`{{${variableKey}}}`}
						</Pill>
					);
				})}
			</Stack>
		</Container>
	);
}

function isEnvironmentVariableVO(variable: EnvironmentVariableVO | string): variable is EnvironmentVariableVO {
	return (variable as EnvironmentVariableVO).key !== undefined;
}
