/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { IconComponent, IconTags, IconTarget } from 'components/icons';
import { Container, Pill, Stack, Text } from 'components';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import { Tab } from './types';

interface TabSelectorProps {
	setSelectedTab: (tab: Tab) => void;
	numSharedAttributes: number;
	numTargets: number;
	selectedTab: Tab;
}

export function TabSelector({
	numSharedAttributes,
	setSelectedTab,
	selectedTab,
	numTargets,
}: TabSelectorProps): ReactElement {
	return (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',
				bg: 'neutral100',
				borderBottom: `1px solid ${theme.colors.neutral300}`,
			}}
		>
			<Button
				Icon={IconTarget}
				label="Targets"
				number={numTargets}
				active={selectedTab === 'targets'}
				onClick={() => setSelectedTab('targets')}
			/>
			<Button
				Icon={IconTags}
				label="Shared Attributes"
				number={numSharedAttributes}
				active={selectedTab === 'shared_attributes'}
				onClick={() => setSelectedTab('shared_attributes')}
				withoutLeftBorder
			/>
		</Container>
	);
}

interface ButtonProps {
	withoutLeftBorder?: boolean;
	Icon: IconComponent;
	onClick: () => void;
	active: boolean;
	number: number;
	label: string;
}
function Button({ Icon, label, number, active, withoutLeftBorder, onClick }: ButtonProps): ReactElement {
	return (
		<Stack
			size="xxSmall"
			direction="horizontal"
			onClick={onClick}
			sx={{
				alignItems: 'center',
				bg: active ? 'neutral000' : 'neutral100',
				px: 'small',
				py: 'small',
				mb: active ? '-1px' : '1px',
				'&:hover': {
					bg: 'neutral000',
					cursor: 'pointer',
				},

				borderLeft: active && !withoutLeftBorder ? `1px solid ${theme.colors.neutral300}` : 'none',
				borderRight: active ? `1px solid ${theme.colors.neutral300}` : 'none',
				borderBottom: active ? `1px solid ${theme.colors.neutral000}` : 'none',
			}}
		>
			<Icon color={active ? 'neutral800' : 'slate'} />
			<Text variant="mediumStrong" color="neutral800">
				{label}
			</Text>
			<Pill backgroundColor="neutral200" backgroundColorOnHover="neutral400" color="neutral600">
				{number}
			</Pill>
		</Stack>
	);
}
