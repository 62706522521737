/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ButtonIcon, Container, RouterLink, Text, Tooltip } from 'components';
import { localeCompareIgnoreCase } from 'utils/string';
import { IconInformation } from 'components/icons';
import { useUrlState } from 'url/useUrlState';
import { TargetAttributeVO } from 'ui-api';
import { ReactElement } from 'react';

interface TargetAttributeListProps {
	attributesGroupedByKey: { [index: string]: TargetAttributeVO[] };
}

export default function TargetAttributeList({ attributesGroupedByKey }: TargetAttributeListProps): ReactElement {
	const [, getUrlWithState] = useUrlState([
		{
			pathSegment: '/table',
			name: 'targetId',
			defaultValue: undefined,
		},
	]);

	return (
		<>
			{Object.keys(attributesGroupedByKey)
				.sort(localeCompareIgnoreCase)
				.map((key) => (
					<Container
						key={key}
						px="medium"
						py="small"
						display="flex"
						alignItems="flex-start"
						sx={{
							borderBottom: '1px solid',
							borderColor: 'neutral300',
							':last-child': {
								borderBottom: 0,
							},
							gap: 'medium',
						}}
					>
						<Tooltip content={key} onlyShowOnEllipsis>
							<Text
								as="dt"
								variant="smallCode"
								color="neutral800"
								flexShrink={0}
								width="33%"
								minWidth={400}
								sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
							>
								{key}
							</Text>
						</Tooltip>
						<Container as="dd" width="100%">
							<Container as="ul" p={0} sx={{ listStyle: 'none' }}>
								{attributesGroupedByKey[key]
									.slice()
									.sort((a, b) => localeCompareIgnoreCase(a.value, b.value))
									.map((attribute, i) => {
										let srcUrl: string | undefined = undefined;
										if (attribute.srcType) {
											srcUrl = getUrlWithState({
												targetId: {
													type: attribute.srcType,
													name: attribute.srcName,
												},
											});
										}

										return (
											<Text
												as="li"
												key={i}
												variant="smallCode"
												color="neutral700"
												sx={{
													display: 'flex',
													alignItems: 'center',

													wordBreak: 'break-all',
													'& + li': {
														mt: 'small',
													},
												}}
											>
												{attribute.value}{' '}
												{srcUrl && (
													<RouterLink to={srcUrl}>
														<ButtonIcon
															ml="xSmall"
															variant="xSmall"
															tooltip={`Copied from ${attribute.srcType} ${attribute.srcName}`}
														>
															<IconInformation color="neutral500" />
														</ButtonIcon>
													</RouterLink>
												)}
											</Text>
										);
									})}
							</Container>
						</Container>
					</Container>
				))}
		</>
	);
}
