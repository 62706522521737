/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

// This is the only file allowd to import from 'react-router'.

// eslint-disable-next-line no-restricted-imports
import { useHistory as useHistoryInternal, useLocation as useLocationInternal } from 'react-router';

import { HistoryWithUrlParams } from './historyWrap';
import { LocationWithUrlParams } from './type';

/**
 * An alternative to the original `useHistory` hook that
 * support parsed query and matrix parameters.
 */
export function useHistory<T>(): HistoryWithUrlParams<T> {
	return useHistoryInternal<T>() as HistoryWithUrlParams<T>;
}

export function useLocation<T>(): LocationWithUrlParams<T> {
	return useLocationInternal<T>() as LocationWithUrlParams<T>;
}
