/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { PluralLabelVO } from 'ui-api';

export function getLabel(label: PluralLabelVO, count?: number | undefined): string {
	if (count == null) {
		return label.other;
	}
	if (count === 1) {
		return label.one;
	}
	return label.other;
}
