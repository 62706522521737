/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import useObservable from 'react-use/lib/useObservable';
import { map } from 'rxjs/operators';
import { interval } from 'rxjs';
import axios from 'axios';

import { minutes, seconds } from './time';

let serverOffset = 0;

function getNow(): Date {
	return new Date(new Date().valueOf() + serverOffset);
}

const now$ = interval(seconds(1).getMillis()).pipe(map(() => getNow()));

function setServerOffset(serverDate: Date): void {
	const browserDate = new Date();
	browserDate.setMilliseconds(0);
	const offset = serverDate.valueOf() - browserDate.valueOf();

	//ignore offsets that exceeds 5 minutes
	if (Math.abs(offset) < minutes(5).getMillis()) {
		serverOffset = offset;
	}
}

export function useNowObservable(): Date {
	return useObservable(now$, getNow());
}

export const setupAxiosServerDateInterceptor = (): void => {
	axios.interceptors.response.use((response) => {
		if (response?.headers['date']) {
			const date = new Date(response?.headers['date']);
			if (!isNaN(date.getTime())) {
				setServerOffset(date);
			}
		}
		return response;
	});
};
