/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Container } from 'components';
import { FC, ReactNode } from 'react';

interface WizardFooterProps {
	children: ReactNode;
}

export const WizardFooter: FC<WizardFooterProps> = ({ children, ...props }) => {
	return (
		<Container
			sx={{
				display: 'flex',
				justifyContent: 'flex-end',

				mt: '0px',
				mb: '0px',
				px: 'xxLarge',
				py: 'small',

				background: 'neutral000',
				boxShadow: '0px -1px 14px #0000001a',
				zIndex: 1,
			}}
			{...props}
		>
			{children}
		</Container>
	);
};
