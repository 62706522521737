/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { UrlParam } from 'url/useUrlState';

export interface UrlState {
	selectedStepId?: string;
}

export const selectedStepIdParam: UrlParam<string | null> = {
	pathSegment: '/design',
	name: 'selectedStepId',
	defaultValue: null,
};

export const selectedTargetIdParam: UrlParam<string | null> = {
	pathSegment: '/design',
	name: 'targetId',
	defaultValue: null,
};
