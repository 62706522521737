/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { UnstyledLink, UnstyledLinkProps } from 'components';
import React from 'react';

import { Button, ButtonProps } from './Button';

export type LinkButtonProps = ButtonProps & UnstyledLinkProps;

export const LinkButton = React.forwardRef<HTMLButtonElement, LinkButtonProps>(({ children, ...props }, ref) => (
	<Button ref={ref} as={UnstyledLink} {...props}>
		{children}
	</Button>
));
LinkButton.displayName = 'LinkButton';
