/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { IconComponent } from 'components/icons';
import { ReactElement, ReactNode } from 'react';
import { Stack } from 'components/Stack';
import { Text } from 'components/Text';

interface ListHeaderTitleProps {
	IconOverride?: ReactNode;
	Icon?: IconComponent;
	children?: ReactNode;
	title: string;
}

export default function ListHeaderTitle({ Icon, IconOverride, title, children }: ListHeaderTitleProps): ReactElement {
	return (
		<Stack direction="horizontal" size="xSmall" alignItems="center">
			{Icon && <Icon variant="large" color="slate" />}
			{IconOverride}
			<Text tx="heading" variant="large" as="h1">
				{title}
			</Text>
			{children}
		</Stack>
	);
}
