/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { useEventEffect } from 'utils/hooks/useEventEffect';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { PageLocation } from 'utils/hooks/usePage';
import { ExperimentScheduleVO } from 'ui-api';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';
import { debounce } from 'lodash';
import { useMemo } from 'react';

export default function useRefreshingSchedules(page: PageLocation, type: string): ExperimentScheduleVO[] | undefined {
	const team = useTeam();
	const [schedules, fetchSchedules] = useAsyncState(
		() => Services.experiments.fetchSchedules(page.criteria, page.pageParams, team.id, type),
		[],
	);
	const debouncedFetchSchedules = useMemo(
		() =>
			debounce(fetchSchedules, 2000, {
				leading: true,
			}),
		[fetchSchedules, page.criteria, page.pageParams],
	);

	useEventEffect(
		(event) => {
			if (event.type === 'experiment.execution.completed' && event.experimentKey) {
				const keys = schedules.value?.content.map((schedule) => schedule.experimentKey) || [];
				if (keys.includes(event.experimentKey)) {
					debouncedFetchSchedules();
				}
			} else {
				debouncedFetchSchedules();
			}
		},
		['schedule.created', 'schedule.updated', 'schedule.deleted', 'experiment.execution.completed'],
		debouncedFetchSchedules.cancel,
	);

	return schedules.value?.content || undefined;
}
