/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement, Suspense, lazy } from 'react';
import { useHistory } from 'url/hooks';

import Card from './Card';

const Image = lazy(() => import('./images/Image'));

export default function FromScratch(): ReactElement {
	const history = useHistory();

	return (
		<Card
			title="Blank"
			onClick={async () => history.push('/settings/templates/design/<new>')}
			cypressTag="create-template-from-scratch"
		>
			<Suspense fallback={<div />}>
				<Image type="fromScratch" />
			</Suspense>
		</Card>
	);
}
