/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ParsingError } from './types';

export class QueryLanguageErrorListener {
	syntaxError(_1: never, _2: never, line: number, column: number, message: string): void {
		const error: ParsingError = { message, line, column };
		throw error;
	}
	reportAmbiguity(): void {
		// noop
	}
	reportAttemptingFullContext(): void {
		// noop
	}
	reportContextSensitivity(): void {
		// noop
	}
}
