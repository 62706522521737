/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { VisuallyHidden } from '@reach/visually-hidden';
import { AnimatePresence, motion } from 'framer-motion';
import { Container, ContainerProps } from 'components';
import { usePopper } from 'react-popper';
import { theme } from 'styles.v2/theme';
import React, { useMemo } from 'react';
import { useSelect } from 'downshift';
import ReactDOM from 'react-dom';

import { SelectOptionGroup } from './SelectOptionGroup';
import { flattenOptions, isGroup } from './utils';
import { SelectOptions } from './SelectOptions';
import { SelectOption } from './SelectOption';
import { IconArrowDropDown } from '../icons';
import * as Types from './SelectOptionTypes';
import { Spinner } from '../Spinner';
import { Text } from '../Text';

type SelectOwnProps<OptionType extends Types.OptionTypeBase> = {
	options: Types.SelectOptions<OptionType>[];
	id?: string;
	inlineLabel?: boolean;
	label?: string;
	variant?: string;
	value?: Types.SelectOption<OptionType> | null;
	onChange?: (value?: Types.SelectOption<OptionType> | null) => void;
	hasError?: boolean;
	menuWidth?: number | string;
	loading?: boolean;
	placeholder?: string;
	disabled?: boolean;
};

export type SelectProps<OptionType extends Types.OptionTypeBase> = SelectOwnProps<OptionType> &
	Omit<ContainerProps, 'css' | keyof SelectOwnProps<OptionType>>;

/**
 *
 * 🚨 ATTENTION 🚨
 *
 * There is an open ADT for this component: https://www.notion.so/steadybit/Multiple-Inputs-for-similar-Usecases-DropDown-ComboBox-6fa13829ba3b438db6598e902ce62d65
 * It triggers as soon as there are changes to this component.
 * So if you need to make changes, please refactor the stuff first and resolve the ADT.
 *
 * all affected components have this comment.
 *
 * 	-Johannes
 */
export function Select<OptionType extends Types.OptionTypeBase>({
	options,
	value,
	variant = 'medium',
	onChange,
	label,
	disabled,
	width = '100%',
	inlineLabel = false,
	placeholder = 'Please select',
	hasError,
	flex,
	my,
	mx,
	mt,
	mb,
	ml,
	mr,
	sx,
	color,
	loading,
	...props
}: SelectProps<OptionType>): JSX.Element {
	const [referenceElement, setReferenceElement] = React.useState<HTMLElement | null>(null);
	const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null);
	const {
		styles,
		attributes,
		update: updatePopper,
	} = usePopper(referenceElement, popperElement, {
		placement: 'bottom-start',
	});

	const flattenedOptions = useMemo(() => flattenOptions(options), [options]);
	const { isOpen, selectedItem, getToggleButtonProps, getLabelProps, getMenuProps, highlightedIndex, getItemProps } =
		useSelect<Types.SelectOptions<OptionType>>({
			items: flattenedOptions,
			selectedItem: value,
			itemToString: (item) => item?.label ?? '',
			onSelectedItemChange: (item) => {
				if (isGroup(item.selectedItem)) {
					return;
				}

				onChange?.(item.selectedItem);
			},
		});
	React.useEffect(() => {
		if (isOpen) {
			updatePopper?.();
		}
	}, [isOpen, updatePopper]);

	let optionIndex = -1;
	const renderOptions = (options: Types.SelectOptions<OptionType>[]): JSX.Element => {
		return (
			<>
				{options.map((item) => {
					if (isGroup(item)) {
						return (
							<SelectOptionGroup key={item.label} label={item.label}>
								{renderOptions(item.options)}
							</SelectOptionGroup>
						);
					}
					optionIndex++;

					return (
						<SelectOption
							key={`${item.value}${optionIndex}`}
							focused={highlightedIndex === optionIndex}
							{...getItemProps({
								index: optionIndex,
								item,
								selected: selectedItem === item,
								disabled: item.disabled,
							})}
						>
							{item.icon && <item.icon mr={'xSmall'} />}
							{item.label}
						</SelectOption>
					);
				})}
			</>
		);
	};

	return (
		<Container
			sx={{ position: 'relative', ...sx }}
			my={my}
			mx={mx}
			mt={mt}
			mb={mb}
			ml={ml}
			mr={mr}
			flex={flex}
			color={color}
			{...props}
		>
			{label ? (
				<VisuallyHidden>
					<label {...getLabelProps()}>{label}</label>
				</VisuallyHidden>
			) : null}
			<Container
				display={'flex'}
				data-select-button
				as={'button'}
				tx={'select'}
				type={'button'}
				variant={variant}
				width={width}
				color={color}
				sx={{
					textAlign: 'left',
					alignItems: 'center',
					justifyContent: 'flex-start',
					borderColor: hasError ? 'coral' : isOpen ? 'slate' : 'neutral300',
					boxShadow: isOpen ? `inset 0 0 0 1px ${theme.colors.slate}` : 'none',
					cursor: disabled ? 'default' : 'pointer',
				}}
				{...getToggleButtonProps({
					ref: (element) => {
						if (element && !referenceElement) {
							setReferenceElement(element);
						}
					},
				})}
				aria-label={'toggle menu'}
				disabled={disabled}
			>
				<Text
					as={'span'}
					sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
					data-select-selected-item
				>
					{inlineLabel && (
						<Text as={'span'} mr={'xxSmall'} fontSize={'inherit'} data-select-inline-label>
							{label}:
						</Text>
					)}
					{selectedItem ? selectedItem.label : placeholder}
				</Text>
				<AnimatePresence>
					{loading ? (
						<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
							<Spinner variant="small" color="neutral600" sx={{ position: 'absolute', right: 34, top: 12 }} />
						</motion.div>
					) : null}
				</AnimatePresence>
				<IconArrowDropDown color={'neutral600'} ml={variant !== 'chromeless' ? 'auto' : 'initial'} flexShrink={0} />
			</Container>
			{ReactDOM.createPortal(
				<Container
					sx={{
						zIndex: 50,
						':focus': { outline: 'none' },
					}}
					minWidth={referenceElement?.clientWidth}
					maxWidth={referenceElement ? referenceElement?.clientWidth * 5 : undefined}
					style={styles.popper}
					{...attributes.popper}
					{...getMenuProps({
						ref: (element) => {
							if (element && !popperElement) {
								setPopperElement(element);
							}
						},
					})}
				>
					<AnimatePresence>
						{isOpen && <SelectOptions variant={variant}>{renderOptions(options)}</SelectOptions>}
					</AnimatePresence>
				</Container>,
				document.body,
			)}
		</Container>
	);
}
