/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	FormikError,
	ModalContentV2,
	ModalFooterV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	Stack,
	Text,
	TextField,
} from 'components';
import { LandscapeConfig } from 'targets/Explore/types';
import { Formik, useFormikContext } from 'formik';
import { IconSaveDisc } from 'components/icons';
import { useTeam } from 'services/useTeam';
import { LandscapeViewVO } from 'ui-api';
import { ReactElement } from 'react';

interface SaveViewModalProps {
	onSave: (name: string, description: string) => void;
	onClose: () => void;
}

interface InnerForm {
	name: string;
	description: string;
}

export default function SaveViewModal(props: SaveViewModalProps): ReactElement {
	const { onSave, onClose } = props;
	const { values } = useFormikContext<LandscapeConfig>();
	const team = useTeam();
	const readonly = values.teamId !== team.id;

	return (
		// mounting an inner form because we dont want to leave the outer form with validtion errors if any
		<Formik<InnerForm>
			initialValues={{
				name: readonly ? `Copy of ${values.name}` : values.name,
				description: values.description,
			}}
			onSubmit={(values) => onSave(values.name, values.description)}
			validate={(values) => {
				if (values.name === '') {
					return { name: 'Name is required' };
				}
			}}
			validateOnBlur={false}
			validateOnChange={false}
		>
			<Content onClose={() => onClose()} />
		</Formik>
	);
}

interface ContentProps {
	onClose: () => void;
}

function Content({ onClose }: ContentProps): ReactElement {
	const { values, setFieldValue, setTouched, submitForm } = useFormikContext<LandscapeViewVO>();

	return (
		<ModalOverlay open centerContent onClose={onClose}>
			{({ close }) => (
				<ModalV2 withFooter minHeight={250}>
					<ModalHeaderV2 title="Save as new view" onClose={close} />
					<ModalContentV2>
						<Stack>
							<Text color="neutral600">
								Save the view to share it with all your team members. By sharing the view&apos;s deep link, you can also
								make it accessible to people outside your team.
							</Text>

							<Stack size="xSmall">
								<Text variant="smallStrong" color="neutral700">
									Your view name
								</Text>
								<TextField
									value={values.name}
									onChange={(e) => {
										setFieldValue('name', e.target.value);
										setTouched({ name: true });
									}}
									placeholder="Untitled View"
								/>
								{!values.name && <FormikError name="name" />}
							</Stack>

							<Stack size="xSmall">
								<Text variant="smallStrong" color="neutral700">
									Description
								</Text>
								<TextField
									autoFocus
									sx={{ minHeight: 80, maxHeight: 400 }}
									as="textarea"
									value={values.description}
									placeholder="No Description"
									variant="small"
									onChange={(e) => {
										setFieldValue('description', e.target.value);
										setTouched({ description: true });
									}}
									maxLength={20_000}
								/>
							</Stack>
						</Stack>
					</ModalContentV2>
					<ModalFooterV2>
						<Stack size="small" direction="horizontal">
							<Button variant="secondary" onClick={close}>
								Cancel
							</Button>
							<Button variant="primary" onClick={submitForm}>
								<IconSaveDisc mr="xSmall" /> Save
							</Button>
						</Stack>
					</ModalFooterV2>
				</ModalV2>
			)}
		</ModalOverlay>
	);
}
