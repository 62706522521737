/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { IconChevronBeginning, IconChevronEnd, IconChevronLeft, IconChevronRight } from 'components/icons';
import { Container, ContainerProps, Tooltip, Text } from 'components';
import { ReactElement } from 'react';
import { Link } from 'url/Link';

import { usePagination } from './usePagination';
import { Stack } from '../Stack';

interface PaginationComponentProps extends Omit<ContainerProps, 'to' | 'onClick'> {
	onClick?: (pageNo: number) => void;
	to?: (pageNo: number) => string;
	size?: 'small' | 'medium';
	totalPages?: number;
	activePage?: number;
}

export default function PaginationComponent({
	totalPages = 0,
	size = 'medium',
	activePage = 0,
	onClick,
	to,
	...props
}: PaginationComponentProps): ReactElement | null {
	const itemList = usePagination(totalPages, activePage);
	const as = to ? Link : undefined;

	if (totalPages <= 1) {
		return null;
	}

	const canGoLeft = activePage > 0;
	const canGoRight = activePage < totalPages - 1;
	const containsEllipsedPages = !!itemList.find((item) => typeof item !== 'number');
	const showArrowControls = containsEllipsedPages || size === 'small';

	const leftArrowControls = showArrowControls ? (
		<Stack direction="horizontal" justifyContent="center" alignItems="center">
			{canGoLeft ? (
				<>
					<Container as={as} {...(to ? { to: to(0) } : {})} onClick={() => onClick?.(0)}>
						<IconChevronBeginning color="neutral600" sx={iconStyle} />
					</Container>
					<Container
						as={as}
						{...(to ? { to: to(Math.max(0, activePage - 1)) } : {})}
						onClick={() => onClick?.(Math.max(0, activePage - 1))}
					>
						<IconChevronLeft color="neutral800" sx={iconStyle} />
					</Container>
				</>
			) : (
				<>
					<IconChevronBeginning color="neutral400" sx={disabledIconStyle} />
					<IconChevronLeft color="neutral400" sx={disabledIconStyle} />
				</>
			)}
		</Stack>
	) : null;

	const rightArrowControls = showArrowControls ? (
		<Stack direction="horizontal" justifyContent="center" alignItems="center">
			{canGoRight ? (
				<>
					<Container
						as={as}
						{...(to ? { to: to(Math.min(totalPages - 1, activePage + 1)) } : {})}
						onClick={() => onClick?.(Math.min(totalPages - 1, activePage + 1))}
					>
						<IconChevronRight color="neutral800" sx={iconStyle} />
					</Container>
					<Container as={as} {...(to ? { to: to(totalPages - 1) } : {})} onClick={() => onClick?.(totalPages - 1)}>
						<IconChevronEnd color="neutral600" sx={iconStyle} />
					</Container>
				</>
			) : (
				<>
					<IconChevronRight color="neutral400" sx={disabledIconStyle} />
					<IconChevronEnd color="neutral400" sx={disabledIconStyle} />
				</>
			)}
		</Stack>
	) : null;

	const pages = (
		<Stack
			direction="horizontal"
			justifyContent="center"
			alignItems="center"
			flexWrap="wrap"
			mx={size === 'small' ? 'small' : 'xLarge'}
		>
			{itemList.map((item, j) => {
				if (typeof item === 'number') {
					return (
						<Tooltip key={j} content={`Page ${item + 1}`}>
							<Container
								variant={size}
								data-active={activePage === item}
								flex="0 0 auto"
								as={as}
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									width: size === 'small' ? 24 : 42,
									height: size === 'small' ? 24 : 42,
									textDecoration: 'none',
									bg: activePage === item ? 'neutral200' : 'none',
									borderRadius: size === 'small' ? 4 : '50%',
									cursor: 'pointer',
								}}
								{...(to ? { to: to(item) } : {})}
								onClick={() => onClick?.(item)}
							>
								<Text variant="medium" color={activePage === item ? 'neutral800' : 'neutral600'} lineHeight="15px">
									{item + 1}
								</Text>
							</Container>
						</Tooltip>
					);
				}
				return (
					<Container key={j} variant={size} flex="0 0 auto" disabled>
						…
					</Container>
				);
			})}
		</Stack>
	);

	return (
		<Container display="flex" alignItems="center" justifyContent="center" {...props}>
			<>
				{leftArrowControls}
				{size !== 'small' && pages}
				{rightArrowControls}
			</>
		</Container>
	);
}

const iconStyle = {
	minWidth: 24,
	minHeight: 24,

	cursor: 'pointer',
	'&:hover': {
		color: 'neutral800',
	},
};

const disabledIconStyle = {
	minWidth: 24,
	minHeight: 24,
};
