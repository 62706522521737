/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import qs from 'qs';

import { CommonOpts, Parameters, LocationDescriptorObjectWithUrlParams } from './type';

const qsOptions = { delimiter: ';' };

export function stringify<T>(
	{ pathname, query = {}, matrix = {}, hash }: LocationDescriptorObjectWithUrlParams<T>,
	{ useAbsolutePaths }: CommonOpts = {},
): string | undefined {
	if (!pathname || !pathname.startsWith('/') || !matrix) {
		return pathname;
	}

	return (
		pathname
			.split('/')
			.slice(1)
			.reduce(
				(agg, path) => {
					agg.pathSoFarWithoutMatrix = `${agg.pathSoFarWithoutMatrix}/${path}`;
					const pathForMatrixParameterAccess = useAbsolutePaths ? agg.pathSoFarWithoutMatrix : `/${path}`;
					agg.pathSoFar = `${agg.pathSoFar}/${path}${encodeMatrixParameters(matrix[pathForMatrixParameterAccess])}`;
					return agg;
				},
				{
					pathSoFarWithoutMatrix: '',
					pathSoFar: '',
				},
			).pathSoFar +
		encodeQueryParameters(query) +
		(hash ? `#${hash}` : '')
	);
}

function encodeMatrixParameters(parameters?: Parameters): string {
	if (!parameters) {
		return '';
	}
	const stringifiedMatrix = qs.stringify(parameters, qsOptions);
	if (stringifiedMatrix.length === 0) {
		return '';
	}
	return ';' + stringifiedMatrix;
}

export function encodeQueryParameters(parameters: Parameters | URLSearchParams): string {
	if (isURLSearchParams(parameters)) {
		parameters = paramsToObject(parameters);
	}

	const stringifiedMatrix = qs.stringify(parameters);
	if (stringifiedMatrix.length === 0) {
		return '';
	}
	return '?' + stringifiedMatrix;
}

function isURLSearchParams(val: unknown): val is URLSearchParams {
	return typeof URLSearchParams !== 'undefined' && val instanceof URLSearchParams;
}

function paramsToObject(parameters: URLSearchParams): Record<string, string> {
	const result: Record<string, string> = {};
	const entries = parameters.entries();
	for (const [key, value] of entries) {
		result[key] = value;
	}
	return result;
}
