/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Pill, Text, Tooltip, smellsLikeEnvVar, smellsLikeTemplatePlaceholder } from 'components';
import { useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { EnvironmentVariableVO } from 'ui-api';
import { IconClose } from 'components/icons';
import { ReactElement } from 'react';
import { useField } from 'formik';

interface ChunkProps {
	value: string;
}

export default function Chunks({ value = '' }: ChunkProps): ReactElement {
	const { mode } = useEditorSettings();
	const chunks =
		mode === 'experiment'
			? value.split(/(\{\{.*?\}\})/g).filter(Boolean)
			: value
					.split(/(\{\{.*?\}\})/g)
					.map((c) => c.split(/(\[\[.*?\]\])/g))
					.flat()
					.filter(Boolean);

	return (
		<>
			{chunks.map((chunk, index) => (
				<Chunk key={index} chunk={chunk} />
			))}
		</>
	);
}

function Chunk({ chunk }: { chunk: string }): ReactElement {
	const { mode } = useEditorSettings();
	if (mode !== 'experiment') {
		if (smellsLikeEnvVar(chunk)) {
			return (
				<HighlightedChunk background="purple200" color="purple800">
					{chunk}
				</HighlightedChunk>
			);
		}
		if (smellsLikeTemplatePlaceholder(chunk)) {
			return <Placeholder placeholder={chunk} />;
		}
		return <span style={{ fontSize: '15px' }}>{chunk}</span>;
	}

	if (smellsLikeEnvVar(chunk)) {
		return <Variable variableKey={chunk} />;
	}
	return <span style={{ ...textEllipsis }}>{chunk}</span>;
}

function Variable({ variableKey, onDelete }: { variableKey: string; onDelete?: () => void }): ReactElement {
	const [{ value: variables = [] }] = useField<EnvironmentVariableVO[]>({ name: 'variables' });

	const resolvedVariable = variables.find((variable) => `{{${variable.key}}}` === variableKey);
	const isResolved = resolvedVariable && resolvedVariable.value ? true : false;

	return (
		<HighlightedChunk
			background={isResolved ? 'slateMidLight40p' : 'coral200'}
			color={isResolved ? 'neutral800' : 'coral'}
			onDelete={onDelete}
		>
			{resolvedVariable && resolvedVariable.value ? resolvedVariable.value : variableKey}
		</HighlightedChunk>
	);
}

function Placeholder({ placeholder, onDelete }: { placeholder: string; onDelete?: () => void }): ReactElement {
	return (
		<HighlightedChunk background="aqua300" color="aqua800" onDelete={onDelete}>
			{placeholder}
		</HighlightedChunk>
	);
}

function HighlightedChunk({
	children,
	onDelete,
	background,
	color,
}: {
	children: string;
	background: string;
	color: string;
	onDelete?: () => void;
}): ReactElement {
	return (
		<div style={{ width: 'fit-content' }}>
			<Pill
				backgroundColor={background}
				color={color}
				sx={{
					py: '4px',
					width: 'fit-content',
					fontSize: '13px',
					fontWeight: '500',
				}}
			>
				<>
					<Tooltip content={children}>
						<Text
							variant="smallMedium"
							sx={{
								lineHeight: '16px',
							}}
						>
							{children}
						</Text>
					</Tooltip>

					{onDelete && (
						<Tooltip content="Clear variable">
							<IconClose
								variant="xSmall"
								onClick={onDelete}
								sx={{
									ml: 'xxSmall',
									color: 'neutral700',
									cursor: 'pointer',
									':hover': {
										color: 'neutral400',
									},
								}}
							/>
						</Tooltip>
					)}
				</>
			</Pill>
		</div>
	);
}
