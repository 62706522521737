/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { getActionPerKindWithoutAttacks, getAttacksPerType } from 'pages/experiments/components/utils';
import { ActionVO, PluralLabelVO, TableVO, TargetTypeDescriptionVO } from 'ui-api';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import { localeCompareIgnoreCase, toTitleCase } from 'utils/string';
import { useStableInstance } from 'utils/hooks/useStableInstance';
import { useCount } from 'pages/experiments/wizard/common/utils';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { getLabel } from 'i18n/label';
import { Dictionary } from 'lodash';
import { useMemo } from 'react';

export interface ActionCategory {
	targetDefinition?: TargetTypeDescriptionVO;
	subCategories?: ActionSubCategory[];
	actions?: ActionCategoryItem[];
	label: string;
}

interface ActionSubCategory {
	actions: ActionCategoryItem[];
	label: string;
}

export interface ActionCategoryItem {
	action: ActionVO;
	label: string;
}

export default function useActionHierarchy(teamId?: string, environmentId?: string): ActionCategory[] {
	const actionsResult = usePromise(() => Services.actions.fetchActions(), []);
	const allowedActionIdsResult = usePromise<string[]>(
		async () => (teamId ? Services.experiments.fetchActionIdsForTeam(teamId) : Services.actions.fetchActionIds()),
		[teamId],
	);

	const allowedActionIds = useStableInstance(allowedActionIdsResult.value ?? []);
	const actions: ActionVO[] = actionsResult.value || [];

	const targetDefinitions = useTargetDefinitions();
	const actionsPerType = useMemo(() => getAttacksPerType(actions, allowedActionIds) || {}, [actions, allowedActionIds]);
	const actionsPerKind = useActionsPerKind(allowedActionIds, actions);
	const totalCount = useCount(environmentId);

	const categories: ActionCategory[] = [];
	if (actionsPerKind['CHECK']) {
		categories.push(
			createActionCategory('Checks', actionsPerKind['CHECK'], {
				id: 'check',
				version: '1',
				label: {
					one: 'Check',
					other: 'Checks',
				} as PluralLabelVO,
				table: {} as TableVO,
			} as TargetTypeDescriptionVO),
		);
	}
	if (actionsPerKind['LOAD_TEST']) {
		categories.push(
			createActionCategory('Load Tests', actionsPerKind['LOAD_TEST'], {
				id: 'load_test',
				version: '1',
				label: {
					one: 'Load Test',
					other: 'Load Tests',
				} as PluralLabelVO,
				table: {} as TableVO,
			} as TargetTypeDescriptionVO),
		);
	}
	if (actionsPerKind['OTHER']) {
		categories.push(
			createActionCategory('Other', actionsPerKind['OTHER'], {
				id: 'other',
				version: '1',
				label: {
					one: 'Other',
					other: 'Others',
				} as PluralLabelVO,
				table: {} as TableVO,
			} as TargetTypeDescriptionVO),
		);
	}

	if (targetDefinitions.value) {
		const actions = targetDefinitions.value
			.slice()
			.concat([
				{
					id: 'other_attacks',
					version: '0.0.1',
					label: { one: 'Other', other: 'Others' },
					table: { columns: [], orderBy: [] },
				},
			])
			.filter((t) => actionsPerType[t.id]?.length > 0 && (environmentId ? (totalCount.value?.[t.id] ?? 0) > 0 : true))
			.map((t) => createActionCategory(toTitleCase(`${getLabel(t.label, 1)} Attacks`), actionsPerType[t.id], t))
			.sort((a, b) => localeCompareIgnoreCase(a.label, b.label));
		categories.push(...actions);
	}

	return categories;
}

function groupActions(actions: ActionVO[]): [ActionCategoryItem[], ActionSubCategory[]] {
	const actionsWithoutCategory: ActionCategoryItem[] = actions
		.filter((a) => !a.category || a.category === 'other')
		.map((action) => ({ label: action.name, action }))
		.sort((a, b) => localeCompareIgnoreCase(a.label, b.label));

	const actionsByCategory = actions
		.filter((a) => a.category && a.category !== 'other')
		.reduce(
			(acc, action) => {
				const category = action.category || '';
				if (!acc[category]) {
					acc[category] = [];
				}
				acc[category].push(action);
				return acc;
			},
			{} as Record<string, ActionVO[]>,
		);

	const actionSubCategories = Object.entries(actionsByCategory)
		.map(([category, actions]) => {
			return {
				label: toTitleCase(category),
				actions: actions
					.map((action) => ({ label: action.name, action }))
					.sort((a, b) => localeCompareIgnoreCase(a.label, b.label)),
			};
		})
		.sort((a, b) => localeCompareIgnoreCase(a.label, b.label));

	return [actionsWithoutCategory, actionSubCategories];
}

function createActionCategory(
	label: string,
	actions: ActionVO[],
	targetDefinition?: TargetTypeDescriptionVO,
): ActionCategory {
	const actionCategory: ActionCategory = { label, targetDefinition };

	const [actionsWithoutCategory, subCategories] = groupActions(actions);
	if (actionsWithoutCategory.length > 0) {
		actionCategory.actions = actionsWithoutCategory;
	}
	if (subCategories.length > 0) {
		actionCategory.subCategories = subCategories;
	}
	return actionCategory;
}

function useActionsPerKind(allowedActionIds: string[], actions: ActionVO[]): Dictionary<ActionVO[]> {
	const uniqueActionNamesResult = usePromise<{ [index: string]: string }>(
		async () => (allowedActionIds ? Services.actions.getActionNamesWithTargetTypeIfNotUnique(allowedActionIds) : {}),
		[allowedActionIds],
	);
	const uniqueActionNames = uniqueActionNamesResult.value ?? {};

	const stableActionIds = useStableInstance(allowedActionIds);
	const stableActionName = useStableInstance(uniqueActionNames);

	const actionsPerKind = useMemo(
		() => getActionPerKindWithoutAttacks(actions, stableActionIds, stableActionName) || {},
		[actions, stableActionIds, stableActionName],
	);

	return actionsPerKind;
}
