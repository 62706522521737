/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { SelectOptions } from './SelectOptionTypes';
import * as Types from './SelectOptionTypes';

export function isGroup(
	optionOrGroup: Types.SelectOptions<Types.OptionTypeBase> | null | undefined,
): optionOrGroup is Types.SelectOptionGroup<Types.OptionTypeBase> {
	if (!optionOrGroup) return false;
	return (optionOrGroup as Types.SelectOptionGroup<Types.OptionTypeBase>).options !== undefined;
}

export function flattenOptions<OptionType extends Types.OptionTypeBase>(
	options: Types.SelectOptions<OptionType>[],
): OptionType[] {
	return options.reduce<OptionType[]>((result, option) => {
		if (isGroup(option)) {
			result.push(...flattenOptions<OptionType>(option.options));
		} else {
			result.push(option);
		}
		return result;
	}, []);
}

export function filterOptionsRecursive<OptionType extends Types.OptionTypeBase>(
	options: Types.SelectOptions<OptionType>[],
	filterFn: (option: OptionType) => boolean,
): Types.SelectOptions<OptionType>[] {
	return options
		.map((option) => {
			if (isGroup(option)) {
				const filteredOptions = filterOptionsRecursive(option.options, filterFn);
				if (!filteredOptions.length) {
					return null;
				}
				return {
					label: option.label,
					options: filteredOptions,
				};
			} else if (filterFn(option)) {
				return option;
			}
			return null;
		})
		.filter(Boolean) as Types.SelectOptions<OptionType>[];
}

export function find<OptionType extends Types.OptionTypeBase>(
	options: SelectOptions<OptionType>[],
	filter?: (o: OptionType) => boolean,
): OptionType | null {
	if (filter) {
		for (const option of options) {
			if (isGroup(option)) {
				const found = find(option.options, filter);
				if (found) {
					return found;
				}
			} else {
				if (filter(option)) {
					return option;
				}
			}
		}
	}
	return null;
}
