/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { UrlParam } from 'url/useUrlState';
import { DirectionVO } from 'ui-api';

export interface UrlState {
	selectedTemplateId?: string;
	freeTextPhrases: string[];
	targetTypes: string[];
	actions: string[];
	tags: string[];
	page: number;
	size: number;
	direction: DirectionVO;
	sort: string;
}

export const selectedTemplateIdParam: UrlParam<string | undefined> = {
	pathSegment: '/templates',
	name: 'selectedTemplateId',
	defaultValue: undefined,
};

export const sortParam: UrlParam<string> = {
	pathSegment: '/templates',
	name: 'sort',
	defaultValue: 'name',
};

export const directionParam: UrlParam<DirectionVO> = {
	pathSegment: '/templates',
	name: 'direction',
	defaultValue: 'ASC',
};
