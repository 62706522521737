/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

export function getBaseHref(): string {
	return document.getElementsByTagName('base')[0]?.href ?? '';
}

export function getBaseHrefPathname(): string {
	const href = getBaseHref();
	return href ? new URL(href).pathname : '';
}

export function withBaseHref(url: string): string {
	return getBaseHref() + (url[0] === '/' ? url.substring(1) : url);
}
