/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import ActionsSidebar from 'pages/experimentsV2/ActionsSidebar/ActionsSidebar';
import DragAndDropHandler from 'pages/experimentsV2/DragAndDropHandler';
import Workspace from 'pages/experimentsV2/Workspace/Workspace';
import { ReactElement } from 'react';
import { Stack } from 'components';

import StepConfigurationSidebar from './StepConfigurationSidebar/StepConfigurationSidebar';

export default function TemplateExperimentContent(): ReactElement {
	return (
		<>
			{/* Since items can be dragged from the sidebar to the workspace, the context must be mounted here */}
			<DragAndDropHandler>
				<Stack direction="horizontal" size="none">
					<ActionsSidebar />
					<Workspace mode="templateEditor" />
				</Stack>
			</DragAndDropHandler>

			<StepConfigurationSidebar top="175px" />
		</>
	);
}
