/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { RouterLink, Stack, StyleProp } from 'components';
import { ReactElement, ReactNode } from 'react';
import { theme } from 'styles.v2/theme';

interface ViewFrameProps {
	children: ReactNode | ReactNode[];
	onClick?: () => void;
	disabled?: boolean;
	sx?: StyleProp;
	href?: string;
}

export default function ViewFrame({ href, disabled, onClick, sx = {}, children }: ViewFrameProps): ReactElement {
	const content = (
		<Stack
			size="small"
			sx={{
				width: 272,
				height: 340,
				p: 16,
				border: '2px solid ' + theme.colors.neutral200,
				borderRadius: 4,
				overflow: 'hidden',
				'&:hover': {
					border: '2px solid ' + theme.colors.slate,
					boxShadow: theme.shadows.applicationMedium,
					bg: 'purple100',
				},
				...sx,
			}}
		>
			{children}
		</Stack>
	);

	if (!href) {
		return content;
	}

	return (
		<RouterLink
			to={href}
			width={272}
			height={326}
			sx={{
				pointerEvents: disabled ? 'none' : 'auto',
				color: 'neutral800',
				'&:hover': {
					textDecoration: 'none',
				},
			}}
			onClick={onClick}
		>
			{content}
		</RouterLink>
	);
}
