/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	BufferGeometry,
	Float32BufferAttribute,
	Material,
	Mesh,
	OrthographicCamera,
	Scene,
	ShaderMaterial,
	Vector3,
	WebGLRenderer,
} from 'three';
import { getAllGroups, getLayoutedTargets, hasAdvice } from 'targets/Explore/utils';
import { emit, event$ } from 'targets/Explore/ServiceLocator';
import { Subscription, filter } from 'rxjs';

import { getGroupInnerLayer, getGroupLayer, overlayIconLayer, targetIconsLayer, targetLayer } from './layer';
import { addOpacities, addQuadColors, addQuadUVs, addQuadVertices } from '../attributeUtils';
import { LayoutedGroup, LayoutedTarget, isColorLegendHoveredEvent } from '../types';
import innerGroupFS from '../shader/innerGroupFragmentShader';
import innerGroupVS from '../shader/innerGroupVertexShader';
import SelectionController from './SelectionController';
import targetFS from '../shader/targetFragmentShader';
import groupFS from '../shader/groupFragmentShader';
import targetVS from '../shader/targetVertexShader';
import groupVS from '../shader/groupVertexShader';
import AdviceController from './AdviceController';
import ExploreTextures from '../ExploreTextures';
import HoverController from './HoverController';
import IconsController from './IconsController';
import MouseController from './MouseController';
import { Sizing } from '../../types';

const ANIMATION_TIME = 500;

export default class TargetMapController {
	canvas: HTMLCanvasElement;
	renderer: WebGLRenderer;
	scene: Scene;
	camera: OrthographicCamera;
	disposed = false;
	numTargets: number;

	targetMaterial: ShaderMaterial;
	targetGeometry: BufferGeometry;
	targetMesh: Mesh<BufferGeometry, Material>;

	groupMaterials: ShaderMaterial[] = [];

	sizing: Sizing = 'none';
	mouseController: MouseController;

	hoverController: HoverController;
	selectionController: SelectionController;
	iconsController: IconsController;
	adviceController: AdviceController;
	adviceIconsController: IconsController;

	hoveredColorGroupSubscription: Subscription;
	adviceSubscription: Subscription;
	adviceActive = false;

	constructor(canvas: HTMLCanvasElement, numTargets: number, textures: ExploreTextures, adviceActive: boolean) {
		this.adviceActive = adviceActive;
		this.numTargets = numTargets;
		this.canvas = canvas;

		this.scene = new Scene();

		const w = this.canvas.clientWidth;
		const h = this.canvas.clientHeight;
		this.camera = new OrthographicCamera(w / -2, w / 2, h / 2, h / -2, 1, 1000);
		this.camera.position.z = 999;

		this.renderer = new WebGLRenderer({ canvas, antialias: true, preserveDrawingBuffer: true });
		this.renderer.setClearColor(0x424e5c, 1);
		this.renderer.setSize(w, h);
		this.renderer.setPixelRatio(window.devicePixelRatio);

		// targets
		this.targetGeometry = new BufferGeometry();
		const initValues: number[] = [];
		const uvs: number[] = [];
		const initialOpacities: number[] = [];
		for (let i = 0; i < numTargets; i++) {
			addQuadVertices(
				initValues,
				-w / 40 + Math.random() * w * 0.05,
				-h / 40 + Math.random() * h * 0.05,
				targetLayer,
				1.2,
			);
			addQuadUVs(uvs);
			addOpacities(initialOpacities, 1);
		}

		this.targetGeometry.setAttribute('uv', new Float32BufferAttribute(uvs, 2));
		this.targetGeometry.setAttribute('position', new Float32BufferAttribute(initValues, 3));
		this.targetGeometry.setAttribute('toPosition', new Float32BufferAttribute(initValues, 3));
		this.targetGeometry.setAttribute('color', new Float32BufferAttribute(initValues, 3));
		this.targetGeometry.setAttribute('opacity', new Float32BufferAttribute(initialOpacities, 1));

		this.targetMaterial = new ShaderMaterial({
			uniforms: {
				progress: { value: 0.0 },
			},
			vertexShader: targetVS,
			fragmentShader: targetFS,
			transparent: true,
			depthTest: true,
			depthWrite: true,
		});

		this.targetMesh = new Mesh(this.targetGeometry, this.targetMaterial);
		this.targetMesh.renderOrder = targetLayer;
		this.targetMesh.frustumCulled = false;
		this.targetMesh.name = 'targets';
		// targets end

		this.hoverController = new HoverController(textures, () => this.renderer.render(this.scene, this.camera));
		this.selectionController = new SelectionController(textures, () => this.renderer.render(this.scene, this.camera));
		this.iconsController = new IconsController(textures, targetIconsLayer, [0, 0, 0]);
		this.adviceIconsController = new IconsController(textures, overlayIconLayer, [1, 1, 1]);
		this.adviceController = new AdviceController(adviceActive);
		this.mouseController = new MouseController(canvas, this.camera, () => this.render());

		this.hoveredColorGroupSubscription = event$()
			.pipe(filter(isColorLegendHoveredEvent))
			.subscribe((e) => {
				if (e.colorAttributeValues) {
					this.highlightColor(e.colorAttributeValues);
				} else {
					this.resetOpacity();
				}
				this.render();
			});

		this.adviceSubscription = event$().subscribe((e) => {
			if (e.type === 'advice') {
				this.adviceActive = e.active;
				this.adviceController.mesh.visible = e.active;
				this.adviceIconsController.getMeshes().forEach((m) => (m.visible = e.active));
				this.resetOpacity();
				this.render();
			}
		});

		requestAnimationFrame((t) => this.animate(t));
	}

	resize(w: number, h: number): void {
		this.camera.left = -w / 2;
		this.camera.right = w / 2;
		this.camera.top = h / 2;
		this.camera.bottom = -h / 2;
		this.camera.updateProjectionMatrix();
		this.renderer.setSize(w, h);
		this.render();
	}

	layoutedGroups: LayoutedGroup[] = [];
	layoutedTargets: LayoutedTarget[] = [];
	renderGroups(layoutedGroups: LayoutedGroup[]): void {
		this.layoutedGroups = layoutedGroups;
		this.layoutedTargets = getLayoutedTargets(layoutedGroups);

		this.selectionController.hide();
		this.hoverController.hide();

		emit({ type: 'groupsChanged', groups: getAllGroups(layoutedGroups) });

		this.updateTargetAttributes();
		this.resetOpacity();

		// clear scene from group geometries
		this.scene.clear();

		// re-add static meshes
		this.scene.add(this.targetMesh);
		this.iconsController.getMeshes().forEach((m) => this.scene.add(m));
		this.adviceIconsController.getMeshes().forEach((m) => this.scene.add(m));
		this.hoverController.getMeshes().forEach((m) => this.scene.add(m));
		this.selectionController.getMeshes().forEach((m) => this.scene.add(m));
		this.scene.add(this.adviceController.getMesh());

		// clear group resources
		this.groupMaterials.forEach((m) => m.dispose());
		this.groupMaterials = [];

		const data = new Map<number, [number[], number[], number[], number[], number[]]>();
		this.getGroupAttributesPerLevel(data, this.layoutedGroups, 0);

		for (const [depth, [vertices, colors, uvs, innerVertices, advice]] of data.entries()) {
			const geometry = new BufferGeometry();
			geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3));
			geometry.setAttribute('color', new Float32BufferAttribute(colors, 3));
			geometry.setAttribute('uv', new Float32BufferAttribute(uvs, 2));
			geometry.setAttribute('hasAdvice', new Float32BufferAttribute(advice, 1));
			const material = new ShaderMaterial({
				uniforms: {
					progress: { value: 0.0 },
					opacity: { value: 1.0 },
					enableAdviceColors: { value: this.adviceActive ? 1 : 0 },
				},
				vertexShader: groupVS,
				fragmentShader: groupFS,
				transparent: true,
				depthTest: true,
				depthWrite: true,
			});
			this.groupMaterials.push(material);
			const mesh = new Mesh(geometry, material);
			mesh.frustumCulled = false;
			mesh.renderOrder = getGroupLayer(depth);
			mesh.name = 'group';
			this.scene.add(mesh);

			const innerGeometry = new BufferGeometry();
			innerGeometry.setAttribute('position', new Float32BufferAttribute(innerVertices, 3));
			innerGeometry.setAttribute('uv', new Float32BufferAttribute(uvs, 2));
			const innerMaterial = new ShaderMaterial({
				uniforms: {
					color: { value: [66.0 / 255.0, 78.0 / 255.0, 92.0 / 255.0] },
				},
				vertexShader: innerGroupVS,
				fragmentShader: innerGroupFS,
				transparent: true,
				depthTest: true,
				depthWrite: true,
			});
			const gMesh = new Mesh(innerGeometry, innerMaterial);
			gMesh.frustumCulled = false;
			gMesh.renderOrder = getGroupInnerLayer(depth);
			gMesh.name = 'inner group';
			this.scene.add(gMesh);
		}

		this.centerView();

		// reset time to trigger animation
		this.iconsController.getMaterials().forEach((m) => (m.uniforms.progress.value = 0));
		this.adviceIconsController.getMaterials().forEach((m) => (m.uniforms.progress.value = 0));
		this.adviceController.getMaterial().uniforms.progress.value = 0;
		this.targetMaterial.uniforms.progress.value = 0;
		this.startTime = 0;
	}

	updateTargetAttributes(): void {
		const currentToPosition = this.targetGeometry.attributes.toPosition.array;
		const currentPosition: number[] = [];
		for (let i = 0; i < currentToPosition.length; i++) {
			currentPosition[i] = currentToPosition[i];
		}

		this.iconsController.reset();
		this.adviceIconsController.reset();
		this.adviceController.reset();

		const vertices: number[] = [];
		const colors: number[] = [];
		for (let i = 0; i < this.layoutedTargets.length; i++) {
			const { x, y, target } = this.layoutedTargets[i];
			const r = target.size * 1.2;
			addQuadVertices(vertices, x, y, targetLayer, r);
			addQuadColors(colors, target.color);

			const currentX = currentPosition[i * 18] + r;
			const currentY = currentPosition[i * 18 + 1] + r;
			this.iconsController.add(target.type, currentX, currentY, x, y, Math.min(3, target.size));

			this.adviceController.add(
				currentX,
				currentY,
				x,
				y,
				r,
				target.adviceRequireAction,
				target.adviceRequireValidation,
				target.adviceDone,
				!!target.colorByValue,
			);

			if (hasAdvice(target)) {
				this.adviceIconsController.add(
					target.adviceRequireAction > 0
						? '_advice_action_needed'
						: target.adviceRequireValidation > 0
							? '_advice_validation'
							: '_advice_implemented',
					currentX,
					currentY,
					x,
					y,
					Math.min(5, target.size),
				);
			}
		}
		this.adviceIconsController.getMeshes().forEach((m) => (m.visible = this.adviceActive));
		this.iconsController.flush();
		this.adviceIconsController.flush();
		this.adviceController.flush();

		this.targetGeometry.setAttribute('position', new Float32BufferAttribute(currentPosition, 3));
		this.targetGeometry.setAttribute('toPosition', new Float32BufferAttribute(vertices, 3));
		this.targetGeometry.setAttribute('color', new Float32BufferAttribute(colors, 3));
	}

	getGroupAttributesPerLevel(
		data: Map<number, [number[], number[], number[], number[], number[]]>,
		groups: LayoutedGroup[],
		depth: number,
	): void {
		const storedAttributes = data.get(depth) ?? [[], [], [], [], []];
		data.set(depth, storedAttributes);
		const [vertices, colors, uvs, innerVertices, advice] = storedAttributes;

		for (let i = 0; i < groups.length; i++) {
			const { x, y, r, group, layoutedGroups } = groups[i];
			addQuadVertices(vertices, x, y, getGroupLayer(depth), r * 1.2);
			addQuadVertices(innerVertices, x, y, getGroupInnerLayer(depth), r * 1.2 - 0.2);
			addQuadColors(colors, group.color);
			addQuadUVs(uvs);

			if (group.adviceRequireValidation + group.adviceRequireAction + group.adviceDone > 0) {
				advice.push(1, 1, 1, 1, 1, 1);
			} else {
				advice.push(0, 0, 0, 0, 0, 0);
			}

			if (layoutedGroups) {
				this.getGroupAttributesPerLevel(data, layoutedGroups, depth + 1);
			}
		}
	}

	highlightColor(colorAttributeValues: string[]): void {
		const opacities: number[] = [];
		for (let i = 0; i < this.layoutedTargets.length; i++) {
			const target = this.layoutedTargets[i].target;
			const isHighlighted = target.colorByValue && colorAttributeValues.includes(target.colorByValue);
			if (isHighlighted) {
				opacities.push(1, 1, 1, 1, 1, 1);
			} else {
				opacities.push(0.2, 0.2, 0.2, 0.2, 0.2, 0.2);
			}
		}

		this.targetGeometry.setAttribute('opacity', new Float32BufferAttribute(opacities, 1));
		this.groupMaterials.forEach((m) => (m.uniforms.enableAdviceColors.value = this.adviceActive ? 1 : 0));
	}

	resetOpacity(): void {
		const targetOpacities: number[] = [];
		for (let i = 0; i < this.layoutedTargets.length; i++) {
			const target = this.layoutedTargets[i].target;
			const o = this.adviceActive ? (hasAdvice(target) ? 1 : 0.5) : 1;
			targetOpacities.push(o, o, o, o, o, o);
		}
		this.targetGeometry.setAttribute('opacity', new Float32BufferAttribute(targetOpacities, 1));

		this.groupMaterials.forEach((m) => (m.uniforms.enableAdviceColors.value = this.adviceActive ? 1 : 0));
	}

	startTime = 0;
	animate(t: number): void {
		if (!this.startTime) {
			this.startTime = t;
		}
		const dt = t - this.startTime;

		if (this.targetMaterial.uniforms.progress.value < 1) {
			const progress = dt / ANIMATION_TIME;

			const p = Math.min(1.0, progress);
			this.targetMaterial.uniforms.progress.value = p;
			this.iconsController.getMaterials().forEach((m) => (m.uniforms.progress.value = p));
			this.adviceIconsController.getMaterials().forEach((m) => (m.uniforms.progress.value = p));
			this.adviceController.getMaterial().uniforms.progress.value = p;

			const groupOpacity = Math.min(1.0, Math.pow(progress, 3));
			this.groupMaterials.forEach((m) => (m.uniforms.progress.value = groupOpacity));

			this.render();
		}

		if (!this.disposed) {
			requestAnimationFrame((t) => this.animate(t));
		}
	}

	render(): void {
		this.renderer.clear(true);
		this.renderer.render(this.scene, this.camera);

		const width = this.renderer.domElement.width / window.devicePixelRatio;
		const height = this.renderer.domElement.height / window.devicePixelRatio;

		const widthHalf = width / 2;
		const heightHalf = height / 2;

		const worldUnitInScreenSpace = this.camera.zoom;
		this.iconsController.updateScreenSpaceSize(worldUnitInScreenSpace);
		this.adviceIconsController.updateScreenSpaceSize(worldUnitInScreenSpace);
		this.adviceController.updateScreenSpaceSize(worldUnitInScreenSpace);
		this.hoverController.updateScreenSpaceSize(worldUnitInScreenSpace);
		this.selectionController.updateScreenSpaceSize(worldUnitInScreenSpace);

		const groups = getAllGroups(this.layoutedGroups).map((g) => {
			const pos = new Vector3(g.x, g.y + g.r, 0);
			pos.project(this.camera);
			pos.x = pos.x * widthHalf + widthHalf;
			pos.y = -(pos.y * heightHalf) + heightHalf;

			const radiusInScreenSpace = g.r * this.camera.zoom;
			return {
				x: pos.x,
				y: pos.y + radiusInScreenSpace,
				r: radiusInScreenSpace,
				group: g,
			};
		});

		emit({ type: 'groupsPositionChanged', groups, screenWidth: width, screenHeight: height });
	}

	centerView(): void {
		const { w: desiredWidth, h: desiredHeight } = getDimensions(this.layoutedGroups);

		const currentWidth = this.camera.right - this.camera.left;
		const currentHeight = this.camera.top - this.camera.bottom;
		const scalingFactorWidth = desiredWidth / currentWidth;
		const scalingFactorHeight = desiredHeight / currentHeight;

		const maxZoomOut = 1 / (Math.max(scalingFactorWidth, scalingFactorHeight) * 1.1);
		this.camera.zoom = this.mouseController.setMaxZoomOut(maxZoomOut);
		this.camera.updateProjectionMatrix();

		this.camera.position.x = 0;
		this.camera.position.y = 0;
	}

	dispose(): void {
		this.disposed = true;
		this.mouseController.dispose();
		this.hoverController.dispose();
		this.iconsController.dispose();
		this.adviceIconsController.dispose();
		this.selectionController.dispose();
		this.adviceController.dispose();
		this.hoveredColorGroupSubscription.unsubscribe();
		this.adviceSubscription.unsubscribe();
	}
}

function getDimensions(groups: LayoutedGroup[]): { w: number; h: number } {
	let maxX = 0;
	let minX = 0;
	let maxY = 0;
	let minY = 0;

	for (let i = 0; i < groups.length; i++) {
		const g = groups[i];
		minX = Math.min(minX, g.x - g.r);
		maxX = Math.max(maxX, g.x + g.r);
		minY = Math.min(minY, g.y - g.r);
		maxY = Math.max(maxY, g.y + g.r);
	}
	return { w: maxX - minX, h: maxY - minY };
}
