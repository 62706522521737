/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import React from 'react';

import { Container, Label, Select, SelectProps } from '..';
import { OptionTypeBase } from './SelectOptionTypes';

interface FormIdSelectOwnProps {
	name?: string;
	label: string;
	options: { id: string; name: string }[];
	value?: string;
	onChange?: (id?: string) => void;
}

export type FormIdSelectProps = FormIdSelectOwnProps & Omit<SelectProps<OptionTypeBase>, keyof FormIdSelectOwnProps>;

export const FormIdSelect: React.VFC<FormIdSelectProps> = ({
	label,
	options,
	onChange,
	value,
	flex,
	my,
	mx,
	mt,
	mb,
	ml,
	mr,
	...props
}) => {
	const selectOptions = React.useMemo(() => options.map((o) => ({ label: o.name, value: o.id })), [options]);
	const selectValue = React.useMemo(() => selectOptions.find((o) => o.value === value), [selectOptions, value]);
	return (
		<Container display={'flex'} flexDirection={'column'} my={my} mx={mx} mt={mt} mb={mb} ml={ml} mr={mr} flex={flex}>
			<Label>
				{label}
				<Select
					mt={'xSmall'}
					label={label}
					options={selectOptions}
					value={selectValue ?? null}
					onChange={(option) => {
						onChange?.(option?.value);
					}}
					{...props}
				/>
			</Label>
		</Container>
	);
};
