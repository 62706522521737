/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Pill, TableDataCell, Tag } from 'components';
import { ReactElement, useState } from 'react';
import { TeamVO } from 'ui-api';

interface EnvironmentsProps {
	team: TeamVO;
	reduced: boolean;
}

export default function Environments({ team, reduced }: EnvironmentsProps): ReactElement {
	const [showAll, setShowAll] = useState(false);
	const envs = team.allowedEnvironmentNames;
	return (
		<TableDataCell sx={{ flexWrap: 'wrap' }}>
			{reduced ? (
				<Pill color="neutral800" backgroundColor="neutral200">
					{envs.length}
				</Pill>
			) : (
				<>
					{envs.slice(0, showAll ? envs.length : 9).map((name) => (
						<Tag key={name} variant={'xSmall'} mr={'xxSmall'} my={'xxSmall'}>
							{name}
						</Tag>
					))}
					{envs.length > 9 && !showAll && (
						<Tag
							variant={'xSmall'}
							sx={{
								mr: 'xxSmall',
								my: 'xxSmall',
								cursor: 'pointer',
								':hover': {
									backgroundColor: 'neutral300',
								},
							}}
							onClick={() => setShowAll(true)}
						>
							{envs.length - 9 > 99 ? '99+' : `+${envs.length - 9}`}
						</Tag>
					)}
				</>
			)}
		</TableDataCell>
	);
}
