/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import { Container, RouterButton, RouterLink, Stack, Text, Tooltip } from 'components';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { IconAdd, IconBookmark } from 'components/icons';
import { localeCompareIgnoreCase } from 'utils/string';
import { LandscapeViewVO } from 'ui-api';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';
import Li from 'components/List/Li';
import Ul from 'components/List/Ul';
import { ampli } from 'ampli';

interface ExploreViewsContentProps {
	templateViews: LandscapeViewVO[];
	selectedViewId?: string | null;
	views: LandscapeViewVO[];
	onChangeView: () => void;
}

export default function ExploreViewsContent({
	selectedViewId,
	templateViews,
	onChangeView,
	views,
}: ExploreViewsContentProps): ReactElement {
	return (
		<DropdownContentFrame maxWidth={350} maxHeight={600}>
			<Stack
				direction="horizontal"
				sx={{
					alignItems: 'center',
					justifyContent: 'space-between',
					px: 'small',
					pt: 'small',
				}}
			>
				<RouterLink to="/landscape/views" onClick={() => onChangeView()}>
					<Stack
						direction="horizontal"
						size="xSmall"
						sx={{
							alignItems: 'center',
							cursor: 'pointer',
							color: 'neutral800',
							':hover': {
								color: 'slate',
							},
						}}
					>
						<IconBookmark />
						<Text variant="mediumStrong">Saved views</Text>
					</Stack>
				</RouterLink>

				<RouterButton
					variant="primarySmall"
					to="/landscape/explore/<new>"
					onClick={() => {
						ampli.landscapeExplorerNewView({
							landscape_explorer_new_view_context: 'view_switcher',
							url: window.location.href,
						});
						onChangeView();
					}}
					sx={{ borderRadius: 2 }}
					dontResolve
				>
					<IconAdd mr="xSmall" ml="-xSmall" />
					<Text variant="mediumStrong"> New view</Text>
				</RouterButton>
			</Stack>

			<Stack size="none" px="small" pb="small">
				{views.length > 0 && (
					<>
						<Text variant="medium" color="neutral600" pt="small">
							YOUR VIEWS
						</Text>
						<Ul>
							{views
								.sort((v1, v2) => localeCompareIgnoreCase(v1.name, v2.name))
								.map((view) => (
									<Li key={view.id} onClick={() => onChangeView()}>
										<Wrapper href={`/landscape/explore/${view.id}`} active={selectedViewId === view.id}>
											{view.name}
										</Wrapper>
									</Li>
								))}
						</Ul>
					</>
				)}

				{templateViews.length > 0 && (
					<>
						<div style={{ height: '1px', backgroundColor: theme.colors.neutral400, marginTop: 12 }} />
						<Text variant="medium" color="neutral600" pt="small">
							SHARED BY STEADYBIT
						</Text>
						<Ul>
							{templateViews
								.sort((v1, v2) => localeCompareIgnoreCase(v1.name, v2.name))
								.map((view) => (
									<Li key={view.id} onClick={() => onChangeView()}>
										<Wrapper
											href={`/landscape/explore/${encodeURIComponent(view.id)}`}
											active={selectedViewId === view.id}
										>
											{view.name}
										</Wrapper>
									</Li>
								))}
						</Ul>
					</>
				)}
			</Stack>
		</DropdownContentFrame>
	);
}

function Wrapper({ children, active, href }: { active: boolean; children: string; href: string }): ReactElement {
	return (
		<RouterLink to={href} dontResolve>
			<Container
				sx={{
					px: 'xSmall',
					py: 'xSmall',
					borderRadius: '4px',
					bg: 'neutral000',
					color: active ? 'slate' : 'neutral600',
					cursor: 'pointer',
					':hover': {
						bg: 'purple100',
						color: 'slate',
					},
				}}
			>
				<Tooltip content={children}>
					<Text variant="mediumStrong" sx={{ ...textEllipsis }}>
						{children}
					</Text>
				</Tooltip>
			</Container>
		</RouterLink>
	);
}
