/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { PageParams } from 'utils/hooks/usePage';
import { useTenant } from 'tenancy/useTenant';
import { Services } from 'services/services';

import { useAsyncState } from './useAsyncState';

interface UseMaxAgentsReponse {
	maxAgentsReached: boolean;
}

export default function useMaxAgents(): UseMaxAgentsReponse {
	const tenant = useTenant();

	const [agents] = useAsyncState(() => Services.agents.findAgents('', new PageParams(0, 1, [['']])));
	const totalAgents = agents.value?.totalElements || 0;

	// Even works, when maxAgents is undefined
	const maxAgentsReached = tenant.maxAgents <= totalAgents;

	return { maxAgentsReached };
}
