/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import DeploymentReplicaCountMetrics from 'pages/experiments/components/metrics/DeploymentReplicaCountMetrics';
import { HttpResponseTimes } from 'pages/experiments/components/metrics/HttpResponseTimes';
import ExperimentRunCard from 'pages/experiments/components/ExperimentRunCard';
import { PredefinedWidgetVO } from 'ui-api';
import { ReactElement } from 'react';

import { WidgetProps } from '../types';

export const PredefinedWidgetType = 'com.steadybit.widget.predefined';

export default function PredefinedWidget({
	experimentExecution,
	widget,
	position,
	start,
	duration,
	progress,
	stepAction,
	onPositionSelect,
}: WidgetProps): ReactElement {
	const predefinedWidget = widget as PredefinedWidgetVO;

	if (predefinedWidget.predefinedWidgetId === 'com.steadybit.widget.predefined.DeploymentReadinessWidget') {
		return (
			<ExperimentRunCard title={'Deployment Readiness'}>
				<DeploymentReplicaCountMetrics
					experimentExecution={experimentExecution}
					start={start}
					duration={duration}
					position={position}
					progress={progress}
				/>
			</ExperimentRunCard>
		);
	}

	if (predefinedWidget.predefinedWidgetId === 'com.steadybit.widget.predefined.HttpCheck' && stepAction) {
		return (
			<ExperimentRunCard title={'HTTP Responses for ' + stepAction.parameters.url}>
				<HttpResponseTimes
					action={stepAction}
					experimentExecution={experimentExecution}
					start={start}
					duration={duration}
					position={position}
					progress={progress}
					onPositionSelect={(position) => onPositionSelect(position)}
				/>
			</ExperimentRunCard>
		);
	}

	return (
		<ExperimentRunCard title={predefinedWidget.predefinedWidgetId}>
			Unknown Widget Type ${predefinedWidget.predefinedWidgetId}
		</ExperimentRunCard>
	);
}
