/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

type JobRoles = 'DEVELOPER' | 'SRE' | 'PERFORMANCE_QA_ENGINEER' | 'MANAGER' | 'OTHER' | 'PREFER_NOT_TO_SAY';

interface JobRole {
	id: JobRoles;
	name: string;
}

export const jobRoles: JobRole[] = [
	{ id: 'DEVELOPER', name: 'Developer' },
	{ id: 'SRE', name: 'SRE' },
	{ id: 'PERFORMANCE_QA_ENGINEER', name: 'Performance/QA Engineer' },
	{ id: 'MANAGER', name: 'Manager' },
	{ id: 'OTHER', name: 'Other' },
	{ id: 'PREFER_NOT_TO_SAY', name: 'Prefer not to say' },
];
