/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import { Container, Stack, Svg, SvgProps } from 'components';
import DropDown from 'components/Select/Dropdown/Dropdown';
import { useNowObservable } from 'utils/now';
import React, { RefObject } from 'react';
import { motion } from 'framer-motion';

import { IconArrowDropDown, IconArrowDropUp, IconComponent } from '../icons';
import { ContextualMenuButton } from '../ContextualMenu';
import { Button, ButtonProps } from '../Button';
import { TimeDiff } from '../Time';

export interface ButtonRunAction {
	label: string;
	value: string;
	disabled?: boolean;
	icon?: IconComponent;
}

export interface ButtonRunProps extends Omit<ButtonProps, 'css'> {
	actions?: ButtonRunAction[];
	onAction?: (action: ButtonRunAction) => void;
	running?: boolean;
	started?: Date;
	estimatedEnd?: Date;
}

function bg(muted = false, running = false): string {
	if (muted && !running) {
		return 'neutral100';
	} else if (running) {
		return 'linear-gradient(0deg, #F94D4C 0%, #FF6362 100%)';
	}
	return 'neutral000';
}

function borderColor(muted = false, running = false): string {
	if (muted && !running) {
		return 'neutral100';
	}
	if (running) {
		return 'coral';
	}
	return 'neutral300';
}

function separatorColor(muted = false, running = false): string {
	if (running) {
		return 'transparent';
	}
	if (muted) {
		return 'neutral000';
	}

	return 'neutral200';
}

const ButtonRunDropdownTrigger: React.FC<ButtonRunProps> = React.forwardRef(
	({ children, muted, running, variant, ...props }, ref) => (
		<Button
			ref={ref}
			tx={'buttonRun'}
			variant={variant}
			color={'neutral600'}
			bg={bg(muted)}
			width={30}
			sx={{
				variant: 'text.smallStrong',
				border: '1px solid',
				borderColor: borderColor(muted),
				borderLeftColor: separatorColor(muted),
				boxShadow: running ? '0px 1px 2px rgba(21, 31, 45, 0.5), 0px 2px 8px rgba(21, 31, 45, 0.25)' : 'unset',
				px: 0,
				borderRadius: '0 4px 4px 0',
				':hover': {
					bg: 'slate',
					color: 'neutral000',
				},
				':focus': {
					outline: 'none',
					boxShadow: '0 0 0 1px currentColor',
				},
			}}
			{...props}
		>
			{children}
		</Button>
	),
);
ButtonRunDropdownTrigger.displayName = 'ButtonRunDropdownTrigger';

type ButtonRunIconStopProps = Omit<SvgProps, 'ref'> & { started?: Date; estimatedEnd?: Date; size?: string };

export const ButtonRunIconStop: React.VFC<ButtonRunIconStopProps> = ({
	started,
	estimatedEnd,
	size = 'medium',
	...props
}) => {
	const now = useNowObservable();
	let progress = 0;
	if (started && estimatedEnd) {
		const duration = estimatedEnd.valueOf() - started.valueOf();
		const progressed = now.valueOf() - started.valueOf();
		progress = Math.max(Math.min(progressed / duration, 1.0), 0.0);
	}
	return (
		<Svg tx={'runButtonStop'} variant={size} viewBox={'0 0 24 24'} xmlns={'http://www.w3.org/2000/svg'} {...props}>
			<rect x={'9'} y={'9'} width={'6'} height={'6'} rx={1} fill={'currentColor'} />
			<circle opacity={0.3} cx={12} cy={12} r={10} stroke={'currentColor'} fill={'none'} strokeWidth={2} />
			<motion.circle
				cx={12}
				cy={12}
				r={10}
				fill={'none'}
				stroke={'currentColor'}
				strokeWidth={2}
				strokeDasharray={63}
				initial={{ strokeDashoffset: 0 }}
				animate={{ strokeDashoffset: 63 * progress }}
				style={{
					transform: 'rotate(-90deg)',
					transformOrigin: '50% 50%',
				}}
			/>
		</Svg>
	);
};

const ButtonRunIconPlay: React.VFC = () => (
	<Svg width={24} height={24} mr={'xxSmall'} viewBox={'0 0 24 24'} xmlns={'http://www.w3.org/2000/svg'}>
		<path
			fillRule={'evenodd'}
			clipRule={'evenodd'}
			d={
				'M10 14.7545C10.0004 15.2432 10.2733 15.6865 10.698 15.8885C11.1228 16.0905 11.621 16.0138 11.9724 15.6924L16 12.0016L11.9724 8.30848C11.6212 7.98638 11.1226 7.9093 10.6976 8.11141C10.2726 8.31352 9.99976 8.75744 10 9.24643V14.7545Z'
			}
			fill={'currentColor'}
		/>

		<circle cx={12} cy={12} r={10} stroke={'currentColor'} fill={'none'} strokeWidth={2} />
	</Svg>
);

export const ButtonRun = React.forwardRef<HTMLButtonElement, ButtonRunProps>(
	({ running, started, estimatedEnd, muted, variant = 'primary', actions, onAction, ...props }, ref) => {
		const noActions = running || !actions?.length;

		return (
			<Container sx={{ display: 'inline-flex', flexDirection: 'row', flex: '0 0 auto' }}>
				<Button
					ref={ref}
					tx={'buttonRun'}
					variant={variant}
					color={running ? 'neutral000' : 'neutral600'}
					sx={{
						background: bg(muted, running),
						border: '1px solid',
						borderRight: noActions ? '1px solid' : 'none',
						borderRadius: noActions ? '4px' : '4px 0 0 4px',
						boxShadow: running ? '0px 1px 2px rgba(21, 31, 45, 0.5), 0px 2px 8px rgba(21, 31, 45, 0.25)' : 'unset',
						borderColor: borderColor(muted, running),
						justifyContent: 'start',
						minWidth: (variant === 'primarySmall' ? 100 : 124) - (noActions ? 0 : 30),

						':hover': {
							bg: running ? 'coralLight' : 'slate',
							color: running ? 'neutral700' : 'neutral000',
						},
						':focus': {
							outline: 'none',
							boxShadow: '0 0 0 1px currentColor',
						},
					}}
					{...props}
				>
					{running ? <ButtonRunIconStop started={started} estimatedEnd={estimatedEnd} /> : <ButtonRunIconPlay />}
					{running ? (
						estimatedEnd && started ? (
							<TimeDiff value={estimatedEnd} mx={'auto'} />
						) : (
							<Container mx={'auto'}>...</Container>
						)
					) : (
						<Container mx={'auto'}>Run</Container>
					)}
				</Button>

				{!noActions ? (
					<DropDown
						placement={'bottom-end'}
						renderComponent={({ ref, showMenu, setShowMenu }) => (
							<ButtonRunDropdownTrigger
								muted={muted}
								disabled={props.disabled}
								variant={variant}
								onClick={() => setShowMenu(!showMenu)}
								ref={ref as RefObject<HTMLButtonElement>}
							>
								{showMenu ? <IconArrowDropUp variant="small" /> : <IconArrowDropDown variant="small" />}
							</ButtonRunDropdownTrigger>
						)}
					>
						{() => (
							<DropdownContentFrame>
								<Stack size="none">
									{actions?.map((action) => {
										const Icon = action.icon;
										return (
											<ContextualMenuButton
												key={action.value}
												onClick={() => onAction?.(action)}
												disabled={action.disabled}
											>
												{Icon ? <Icon data-tag-icon /> : null}
												{action.label}
											</ContextualMenuButton>
										);
									})}
								</Stack>
							</DropdownContentFrame>
						)}
					</DropDown>
				) : null}
			</Container>
		);
	},
);

ButtonRun.displayName = 'ButtonRun';
