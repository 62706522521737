/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

export default function HorizontalLine(): ReactElement {
	return <div style={{ width: '100%', height: '1px', background: theme.colors.neutral300 }} />;
}
