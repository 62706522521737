/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Button, ModalContentV2, ModalFooterV2, ModalHeaderV2, ModalOverlay, ModalV2, Stack, Text } from 'components';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { ReactElement, useState } from 'react';
import { IconTrash } from 'components/icons';
import { Services } from 'services/services';

interface DeleteViewModalProps {
	onDeleteSuccess: () => void;
	viewId: string;
	viewName: string;
	onClose: () => void;
}

export default function DeleteViewModal({
	viewId,
	viewName,
	onClose,
	onDeleteSuccess,
}: DeleteViewModalProps): ReactElement {
	const [isDeleting, setIsDeleting] = useState(false);

	return (
		<ModalOverlay open centerContent onClose={onClose}>
			{({ close }) => (
				<ModalV2 withFooter minHeight={220} width={600} slick>
					<ModalHeaderV2 title="Delete view" onClose={close} />
					<ModalContentV2>
						<Text as="span" color="neutral600" mr="xxSmall">
							Are you sure you want to delete the view:
						</Text>
						<Text as="span" variant="mediumStrong" color="neutral600">
							{viewName}
						</Text>
						<Text color="neutral600">It won&apos;t be possible retrieve it again after the deletion.</Text>
					</ModalContentV2>
					<ModalFooterV2>
						<Stack size="small" direction="horizontal">
							<Button variant="secondary" onClick={close}>
								Cancel
							</Button>
							<Button
								variant="primary"
								onClick={async () => {
									if (isDeleting) {
										return;
									}

									try {
										setIsDeleting(true);
										await Services.landscapeV2.deleteView(viewId);
										onDeleteSuccess();
									} catch (error) {
										console.error(error);
										setIsDeleting(false);
									}
								}}
							>
								{!isDeleting ? (
									<>
										<IconTrash mr="xSmall" /> Delete view
									</>
								) : (
									<>
										<LoadingIndicator variant="small" sx={{ mr: 'xSmall' }} /> Deleting...
									</>
								)}
							</Button>
						</Stack>
					</ModalFooterV2>
				</ModalV2>
			)}
		</ModalOverlay>
	);
}
