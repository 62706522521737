/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { IconTargetIndicatorAttack, IconTargetType } from 'components/icons';
import { Container, ContainerProps } from 'components';
import { ReactElement } from 'react';

interface AttackedTargetIndicatorProps extends Omit<ContainerProps, 'children>'> {
	targetType: string;
	color?: 'coral' | 'purple700';
}

export default function AttackedTargetIndicator({
	targetType,
	color = 'purple700',
	...props
}: AttackedTargetIndicatorProps): ReactElement {
	return (
		<Container tx={'targetIndicator.icon'} variant={'medium'} sx={{ position: 'relative' }} {...props}>
			<IconTargetIndicatorAttack width={'100%'} height={'100%'} color={color} />
			<IconTargetType
				targetType={targetType}
				width={'100%'}
				height={'100%'}
				sx={{ position: 'absolute', transform: 'scale(.35)', top: 0, left: 0 }}
			/>
		</Container>
	);
}
