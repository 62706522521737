/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ExperimentExecutionPreflightVO } from 'ui-api';
import { Container, Tag, Text } from 'components';
import { formatTime } from 'utils/dateFns';
import { ReactElement } from 'react';

import { getColorForState, getIconForState } from '../utils';

interface LogLinePreflightProps {
	preflight: ExperimentExecutionPreflightVO;
}

export default function LogLinePreflight({ preflight }: LogLinePreflightProps): ReactElement {
	const { name, state, checkedTimestamp } = preflight;

	const successful = state === 'SUCCESSFUL';
	const Icon = getIconForState(state);
	const isBold = !successful;
	const color = successful ? 'neutral700' : getColorForState(state);

	function getState(): string | null {
		switch (state) {
			case 'CREATED':
				return ' created';
			case 'SUCCESSFUL':
				return ' succeeded';
			case 'FAILED':
				return ' failed';
			case 'ERRORED':
				return ' errored';
		}
		return null;
	}

	return (
		<Container display="flex" alignItems="flex-start">
			<Icon variant="small" minWidth={16} maxWidth={16} color={color} mt={2} sx={{ mr: 'xSmall' }} />
			{checkedTimestamp && (
				<Text
					variant={isBold ? 'smallStrong' : 'small'}
					sx={{
						fontFamily: 'code',
						color,
						whiteSpace: 'nowrap',
						mr: 'small',
						minWidth: 'fit-content',
					}}
				>
					{formatTime(new Date(checkedTimestamp))}
				</Text>
			)}
			<Text
				variant={isBold ? 'smallStrong' : 'small'}
				sx={{
					color,
				}}
			>
				<>
					preflight check{preflight.type === 'com.steadybit.experiment.execution.preflight.webhook' && ' webhook'}
					<Tag variant={'xxSmall'} maxWidth={'unset'} mx="xSmall" mt={2}>
						{name}
					</Tag>
					{getState()}
					{preflight.reason ? `: ${preflight.reason}` : null}
				</>
			</Text>
		</Container>
	);
}
