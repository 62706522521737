/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { BufferGeometry, Float32BufferAttribute, Mesh, ShaderMaterial } from 'three';
import { emit, event$ } from 'targets/Explore/ServiceLocator';
import { Subscription, filter } from 'rxjs';

import {
	LayoutedGroup,
	LayoutedTarget,
	ScreenSpaceGroup,
	isGroupsPositionChangedEvent,
	isHoverEntityEvent,
	isLayoutedTarget,
	isMouseMoveEvent,
	isSelectEntity,
} from '../types';
import { getGroupHoverLayer, targetHoverIconsLayer, targetHoverLayer } from './layer';
import { addQuadUVs, addQuadVertices } from '../attributeUtils';
import innerGroupFS from '../shader/innerGroupFragmentShader';
import innerGroupVS from '../shader/innerGroupVertexShader';
import iconFS from '../shader/hoverIconsFragmentShader';
import iconVS from '../shader/hoverIconsVertexShader';
import ExploreTextures from '../ExploreTextures';

export default class HoverController {
	subscription: Subscription;
	groupSubscription: Subscription;
	mouseX = -1;
	mouseY = -1;
	width = 0;
	height = 0;
	groups: ScreenSpaceGroup[] = [];
	clear: () => void;
	renderGroup: (group: LayoutedGroup) => void;
	renderTargets: (targets: LayoutedTarget[]) => void;
	currentHovereredEntity: LayoutedTarget | LayoutedGroup | null = null;

	iconMesh: Mesh<BufferGeometry, ShaderMaterial>;
	mesh: Mesh<BufferGeometry, ShaderMaterial>;
	textures: ExploreTextures;

	constructor(textures: ExploreTextures, render: () => void) {
		this.textures = textures;

		const geometry = new BufferGeometry();
		geometry.setAttribute('position', new Float32BufferAttribute([], 3));

		const uvs: number[] = [];
		addQuadUVs(uvs);
		geometry.setAttribute('uv', new Float32BufferAttribute(uvs, 2));
		const material = new ShaderMaterial({
			uniforms: {
				color: { value: [91.0 / 255.0, 72.0 / 255.0, 202.0 / 255.0] },
			},
			vertexShader: innerGroupVS,
			fragmentShader: innerGroupFS,
			transparent: true,
			depthTest: true,
			depthWrite: true,
		});

		this.mesh = new Mesh(geometry, material);
		this.mesh.frustumCulled = false;
		this.mesh.name = 'hover';

		const iconMaterial = new ShaderMaterial({
			uniforms: {
				progress: { value: 1.0 },
				textureMap: { value: null },
				worldUnitInScreenSpace: { value: 0.0 },
			},
			vertexShader: iconVS,
			fragmentShader: iconFS,
			transparent: true,
			depthTest: true,
			depthWrite: true,
		});
		const iconGeometry = new BufferGeometry();
		iconGeometry.setAttribute('position', new Float32BufferAttribute([], 3));
		iconGeometry.setAttribute('uv', new Float32BufferAttribute(uvs, 2));
		iconGeometry.setAttribute('targetSize', new Float32BufferAttribute([], 1));

		this.iconMesh = new Mesh(iconGeometry, iconMaterial);
		this.iconMesh.frustumCulled = false;
		this.iconMesh.name = 'hover-icon';

		this.renderGroup = ({ x, y, r, group }: LayoutedGroup) => {
			this.show();
			const vertices: number[] = [];
			addQuadVertices(vertices, x, y, getGroupHoverLayer(group.depth), r * 1.2 - 0.2);
			const iconVertices: number[] = [];
			addQuadVertices(iconVertices, x, y, getGroupHoverLayer(group.depth), Math.min(3, r) * 0.5);

			this.mesh.renderOrder = getGroupHoverLayer(group.depth);
			this.iconMesh.renderOrder = getGroupHoverLayer(group.depth);
			geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3));
			iconGeometry.setAttribute('position', new Float32BufferAttribute(iconVertices, 3));
			iconGeometry.setAttribute('targetSize', new Float32BufferAttribute([r, r, r, r, r, r], 1));

			render();
		};

		this.renderTargets = (targets: LayoutedTarget[]) => {
			this.show();
			const iconVertices: number[] = [];
			const vertices: number[] = [];
			const sizes: number[] = [];
			const uvs: number[] = [];

			for (let i = 0; i < targets.length; i++) {
				const { x, y, target } = targets[i];
				const r = target.size;
				addQuadVertices(vertices, x, y, targetHoverLayer, r * 1.205 + 0.02);
				addQuadVertices(iconVertices, x, y, targetHoverIconsLayer, Math.min(3, r) * 0.5);
				addQuadUVs(uvs);
				sizes.push(r, r, r, r, r, r);
			}

			geometry.setAttribute('uv', new Float32BufferAttribute(uvs, 2));
			geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3));
			iconGeometry.setAttribute('uv', new Float32BufferAttribute(uvs, 2));
			iconGeometry.setAttribute('position', new Float32BufferAttribute(iconVertices, 3));
			iconGeometry.setAttribute('targetSize', new Float32BufferAttribute(sizes, 1));

			this.mesh.renderOrder = targetHoverLayer;
			this.iconMesh.renderOrder = targetHoverIconsLayer;

			render();
		};

		this.clear = () => {
			this.hide();
			render();
		};

		this.subscription = event$().subscribe((event) => {
			if (isMouseMoveEvent(event)) {
				this.mouseX = event.x;
				this.mouseY = event.y;
				this.calculate();
			} else if (isHoverEntityEvent(event)) {
				if (isLayoutedTarget(event.entity)) {
					this.setEntity(event.entity);
					const targetsSharingId = getTargetsWithId(
						event.entity.target.id,
						this.groups.map((g) => g.group),
					);
					return this.renderTargets(targetsSharingId);
				}
			} else if (isSelectEntity(event)) {
				this.setEntity(event.entity);
			}
		});
		this.groupSubscription = event$()
			.pipe(filter(isGroupsPositionChangedEvent))
			.subscribe((event) => {
				this.width = event.screenWidth;
				this.height = event.screenHeight;
				this.groups = event.groups;
				this.calculate();
			});
	}

	calculate(): void {
		const { mouseX, mouseY, width, height, groups } = this;
		if (mouseX === -1 || mouseY === -1 || width === 0 || height === 0 || groups.length === 0) {
			this.setEntity(null);
			return this.clear();
		}

		const hoveredGroup = groups
			.filter((group) => {
				const { x, y, r } = group;
				return Math.sqrt(Math.pow(mouseX - x, 2) + Math.pow(mouseY - y, 2)) < r;
			})
			.sort((a, b) => b.group.group.depth - a.group.group.depth)[0];

		if (!hoveredGroup) {
			this.setEntity(null);
			return this.clear();
		}

		if (hoveredGroup.group.layoutedTargets) {
			const pixelsPerWorldUnit = hoveredGroup.r / hoveredGroup.group.r;
			for (let i = 0; i < hoveredGroup.group.layoutedTargets.length; i++) {
				const target = hoveredGroup.group.layoutedTargets[i];
				const xT = target.x;
				const yT = target.y;
				const rT = target.target.size * pixelsPerWorldUnit;
				const x = hoveredGroup.x + (xT - hoveredGroup.group.x) * pixelsPerWorldUnit;
				const y = hoveredGroup.y - (yT - hoveredGroup.group.y) * pixelsPerWorldUnit;

				const isHovered = Math.sqrt(Math.pow(mouseX - x, 2) + Math.pow(mouseY - y, 2)) < rT;
				if (isHovered) {
					this.setEntity(target);

					const targetsSharingId = getTargetsWithId(
						target.target.id,
						groups.map((g) => g.group),
					);
					return this.renderTargets(targetsSharingId);
				}
			}
		}

		this.setEntity(hoveredGroup.group);
		return this.renderGroup(hoveredGroup.group);
	}

	setEntity(entity: LayoutedTarget | LayoutedGroup | null): void {
		if (isLayoutedTarget(entity)) {
			this.iconMesh.material.uniforms.textureMap.value = this.textures.get(entity.target.type);
		} else {
			this.iconMesh.material.uniforms.textureMap.value = null;
		}

		if (this.getId(this.currentHovereredEntity) !== this.getId(entity)) {
			this.currentHovereredEntity = entity;
			emit({
				type: 'hoverEntity',
				entity,
			});
		}
	}

	getId(entity: LayoutedTarget | LayoutedGroup | null): string | null {
		if (!entity) {
			return null;
		}
		return isLayoutedTarget(entity) ? entity.target.id : entity.group.id;
	}

	updateScreenSpaceSize(worldUnitInScreenSpace: number): void {
		this.iconMesh.material.uniforms.worldUnitInScreenSpace.value = worldUnitInScreenSpace;
	}

	getMeshes(): Mesh<BufferGeometry, ShaderMaterial>[] {
		return [this.mesh, this.iconMesh];
	}

	show(): void {
		this.mesh.visible = true;
		this.iconMesh.visible = true;
	}

	hide(): void {
		this.mesh.visible = false;
		this.iconMesh.visible = false;
	}

	dispose(): void {
		this.subscription.unsubscribe();
		this.groupSubscription.unsubscribe();
		this.mesh.geometry.dispose();
		this.mesh.material.dispose();
		this.iconMesh.geometry.dispose();
		this.iconMesh.material.dispose();
	}
}

function getTargetsWithId(id: string, groups: LayoutedGroup[]): LayoutedTarget[] {
	const targets: LayoutedTarget[] = [];
	for (let i = 0; i < groups.length; i++) {
		const group = groups[i];
		if (group.layoutedGroups) {
			targets.push(...getTargetsWithId(id, group.layoutedGroups));
		} else {
			targets.push(...group.layoutedTargets.filter((target) => target.target.id === id));
		}
	}
	return targets;
}
