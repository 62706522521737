/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ampli, EventOptions, IdentifyProperties, LoadOptions, PromiseResult } from 'ampli';
import { BrowserConfig, EnrichmentPlugin, Event } from '@amplitude/analytics-types';
import { ignoreEmail } from 'tracking/ignoredEmailAddresses';
import * as amplitude from '@amplitude/analytics-browser';
import { Types } from '@amplitude/analytics-browser';
import useFeatureFlag from 'services/useFeatureFlag';
import { digestMessage } from 'tracking/hashUtil';
import { CurrentUserVO, TenantVO } from 'ui-api';
import { useTenant } from 'tenancy/useTenant';
import { debounceTime, Subject } from 'rxjs';
import { Services } from 'services/services';
import { useUser } from 'services/usersApi';
import { useTeam } from 'services/useTeam';
import React, { useEffect } from 'react';
import { useAsync } from 'react-use';

// noinspection JSUnusedGlobalSymbols
enum Mode {
	DISABLED = 'DISABLED',
	LOCAL_STORAGE = 'LOCAL_STORAGE',
	VENDOR_STORAGE = 'VENDOR_STORAGE',
}

declare global {
	// noinspection JSUnusedGlobalSymbols
	interface Window {
		INJECTED_AMPLITUDE_MODE?: string;
		INJECTED_AMPLITUDE_API_KEY?: string;
		INJECTED_AMPLITUDE_FILTER_OWN_USERS?: boolean;
	}
}

const isAmplitudeEnabled =
	window.INJECTED_AMPLITUDE_MODE !== undefined && window.INJECTED_AMPLITUDE_MODE !== Mode.DISABLED;

class AddGroupTenantTeamPlugin implements EnrichmentPlugin {
	name = 'add-tenant-team';
	type = 'enrichment' as const;
	config?: BrowserConfig;
	private readonly tenantKey: string;
	private readonly teamId: string;

	constructor(tenantKey: string, teamId: string) {
		this.tenantKey = tenantKey;
		this.teamId = teamId;
	}

	async setup(config: BrowserConfig): Promise<undefined> {
		this.config = config;
		return;
	}

	async execute(event: Event): Promise<Event> {
		event.groups = {
			tenant_key: this.tenantKey,
			team_id: this.teamId,
		};
		return event;
	}
}

const genLogRocketUrl = async (email: string): Promise<string | undefined> => {
	if (!window.INJECTED_LOGROCKET_ID) {
		return;
	}
	const devUrl = 'qykstv/dev-platform-sdxoi';
	const prodUrl = 'qykstv/prod-platform-uk8xc';
	const encodedFilter = encodeURIComponent(
		JSON.stringify([
			{
				type: 'userTrait',
				operator: { name: 'is', type: 'IS', hasStrings: true, autocompleteEnabled: true },
				strings: [`amplitudeUserId:${await digestMessage(email)}`],
			},
		]),
	);
	if (window.location.hostname === 'platform.steadybit.com') {
		return `https://app.logrocket.com/${prodUrl}?filters=${encodedFilter}`;
	} else {
		return `https://app.logrocket.com/${devUrl}?filters=${encodedFilter}`;
	}
};
const ampliLoad = (tenantKey: string | undefined): PromiseResult<void> => {
	let serverUrl = `${window.location.origin}/ampli/2/httpapi`;
	if (window.INJECTED_AMPLITUDE_MODE === Mode.LOCAL_STORAGE) {
		console.log('Using local amplitude event store');
		serverUrl = `${window.location.origin}/ui/amplitude/track?tenantKey=${tenantKey}`;
	}
	return ampli.load({
		client: {
			apiKey: window.INJECTED_AMPLITUDE_API_KEY,
			configuration: {
				defaultTracking: false,
				serverZone: Types.ServerZone.EU,
				serverUrl: serverUrl,
				includeUtm: true,
				includeReferrer: true,
			},
		},
	} as LoadOptions);
};

const createDummyAmpli = (): void => {
	//Dummy Instance

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	ampli.amplitude = amplitude.createInstance();
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	ampli.disabled = true;
};

export const ampliIdentify = async (user: CurrentUserVO, tenant?: TenantVO): Promise<void> => {
	let userid = undefined;
	if (user.email) {
		userid = await digestMessage(user.email);
	} else if (tenant) {
		userid = await digestMessage(tenant.agentKey + user.username);
	}
	await ampli.identify(
		userid,
		{
			job_role: user.jobRole ? user.jobRole.toLowerCase() : undefined,
			intention: user.intention ? user.intention.toLowerCase() : undefined,
			technology_focus: user.usedTechnologies,
			logrocket_url: user.email ? await genLogRocketUrl(user.email) : undefined,

			screen_width: window.screen.width,
			screen_height: window.screen.height,
			screen_resolution: `${window.screen.width}x${window.screen.height}`,
			screen_ratio: window.screen.width / window.screen.height,

			client_width: window.innerWidth,
			client_height: window.innerHeight,
			client_resolution: `${window.innerWidth}x${window.innerHeight}`,
			client_ratio: window.innerWidth / window.innerHeight,

			client_screen_width_ratio: window.innerWidth / window.screen.width,
			client_screen_height_ratio: window.innerHeight / window.screen.height,
		} as IdentifyProperties,
		{} as EventOptions,
	).promise;
};

type ScreenClientDimension = {
	clientWidth: number;
	clientHeight: number;
	screenWidth: number;
	screenHeight: number;
};

export function useAmplitude(): boolean {
	const tenant = useTenant();
	const user = useUser();
	const team = useTeam();
	const isAnalyticsFeatureDisabled = useFeatureFlag('disable_analytics');

	const [dimension$] = React.useState<Subject<ScreenClientDimension>>(new Subject<ScreenClientDimension>());

	const isAnalyticsEnabled = () => {
		return !ignoreEmail(user) && !isAnalyticsFeatureDisabled && isAmplitudeEnabled && tenant.key && team.id;
	};

	useEffect(() => {
		const sub = dimension$.pipe(debounceTime(1000)).subscribe(() => {
			if (isAnalyticsEnabled()) {
				ampliIdentify(user, tenant);
			}
		});
		return () => {
			sub.unsubscribe();
		};
	}, [dimension$, user.name]);
	useEffect(() => {
		const trackDimensions = async (): Promise<void> => {
			dimension$.next({
				screenWidth: window.screen.width,
				screenHeight: window.screen.height,
				clientWidth: window.innerWidth,
				clientHeight: window.innerHeight,
			});
		};
		window.addEventListener('resize', trackDimensions);
		return () => window.removeEventListener('resize', trackDimensions);
	}, []);

	const init = useAsync(async () => {
		if (isAnalyticsEnabled()) {
			await ampliLoad(tenant.key).promise;
			ampli.client.add(new AddGroupTenantTeamPlugin(tenant.key, team.id));
			// noinspection ES6MissingAwait
			ampliIdentify(user, tenant);
			// Send Amplitude DeviceId to platform before(!) sessionId
			const deviceId = ampli.client.getDeviceId();
			if (deviceId) {
				await Services.users.updateUserAmplitudeDeviceId(deviceId);
			}
			// Send Amplitude SessionId to platform
			const sessionId = ampli.client.getSessionId();
			if (sessionId) {
				await Services.users.updateUserAmplitudeSessionId(sessionId);
			}

			return true;
		} else {
			createDummyAmpli();
			return true;
		}
	}, [user, team.id, tenant.key]);
	if (init.error) {
		console.log('Error initializing amplitude', init.error);
		createDummyAmpli();
		return true;
	}
	return init.value || false;
}

export function useAmplitudeInTenantInit(): void {
	const user = useUser();
	useAsync(async () => {
		if (!ignoreEmail(user) && isAmplitudeEnabled && window.INJECTED_AMPLITUDE_MODE !== Mode.LOCAL_STORAGE) {
			await ampliLoad(undefined).promise;
			// noinspection ES6MissingAwait
			ampliIdentify(user, undefined);
		} else {
			createDummyAmpli();
		}
	}, [user]);
}
