/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { getHash } from 'utils/hash';
import { useMemo } from 'react';

/**
 * Returns the same previous object instance as long the hash
 * has not changed.
 */
export function useStableInstance<T>(v: T): T {
	return useMemo(() => v, [v ? getHash(v) : v]);
}
