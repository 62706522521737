/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { DeleteIcon } from 'pages/templates/FromTemplateModal/TemplateDetails/common';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ACTION_STYLES } from 'pages/experimentsV2/utils';
import { createIconFromDataUri } from 'components/icons';
import { ReactElement } from 'react';
import { ActionVO } from 'ui-api';
import { Pill } from 'components';

interface ActionProps {
	actionsData: ActionVO[];
	action: string;
	onClick?: () => void;
	onDelete?: () => void;
}

export default function Action({ action, actionsData, onClick, onDelete }: ActionProps): ReactElement {
	const resolvedAction = actionsData.find((a) => a.id === action);

	const style = { ...ACTION_STYLES[resolvedAction?.kind ? `action_${resolvedAction?.kind}` : 'action'] };
	const Icon = createIconFromDataUri(resolvedAction?.icon);

	return (
		<Pill
			backgroundColor={style.backgroundColor || 'neutral200'}
			color={style.color || 'neutral800'}
			onClick={onClick}
			sx={{
				width: 'fit-content',
				height: '28px',
				fontSize: '12px',
				borderRadius: '4px',
				...textEllipsis,
			}}
		>
			{resolvedAction?.icon ? <Icon variant="small" mr="xxSmall" /> : <></>}
			{resolvedAction?.name || action}
			{onDelete ? <DeleteIcon color="neutral000" onClick={onDelete} /> : <></>}
		</Pill>
	);
}
