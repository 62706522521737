/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	BaseExperimentStepVOUnion,
	OccuranceActionPredicateVO,
	OccuranceStepLabelVO,
	OccuranceStepParameterVO,
	OccuranceTemplateFieldVO,
	OccuranceVO,
} from 'ui-api';

export interface TemplatePlaceholder {
	steps: BaseExperimentStepVOUnion[];
	description: string;
	value: string;
}

export interface EnvironmentVariable {
	steps: BaseExperimentStepVOUnion[];
	value: string;
}

export function isOccuranceTemplateFieldVO(occurance: OccuranceVO): occurance is OccuranceTemplateFieldVO {
	return 'templateField' in occurance;
}

export function isOccuranceActionPredicateVO(occurance: OccuranceVO): occurance is OccuranceActionPredicateVO {
	return 'stepId' in occurance && ('attribute' in occurance || 'usedInKey' in occurance);
}

export function isOccuranceStepLabelVO(occurance: OccuranceVO): occurance is OccuranceStepLabelVO {
	return 'stepId' in occurance && 'label' in occurance;
}

export function isOccuranceStepParameterVO(occurance: OccuranceVO): occurance is OccuranceStepParameterVO {
	return 'stepId' in occurance && 'field' in occurance;
}
