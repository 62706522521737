/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { CustomKeywordFunction, CustomSuggestionFunction } from './Editor';
import { Token } from './TokensProvider';
import { TOKENIZER } from './config';

interface GetSuggestionsForLinePositionArguments {
	line: string;
	cursorPosition: number;
	getKeywords: () => Promise<string[]>;
	getKeywordValues: (keyword: string) => Promise<string[]>;
	getAdditionalKeywords?: CustomKeywordFunction;
	getAdditionalSuggestions?: CustomSuggestionFunction;
}

interface Suggestion {
	label: string;
	text: string;
}

export interface SuggestionsResult {
	suggestions: Suggestion[];
	startIndex: number;
	endIndex: number;
}

const countComparatorsScopes = [
	'OP_EQUAL',
	'OP_NOT_EQUAL',
	'OP_LESS_THAN',
	'OP_LESS_THAN_EQUAL',
	'OP_GREATER_THAN',
	'OP_GREATER_THAN_EQUAL',
];

const countComparators = ['=', '!=', '<', '<=', '>', '>='];

const termComparators = ['=', '!=', '~', '!~', '=*', '!=*', '~*', '!~*'];

// exported for testing
/**
 * Developed by a TDD approach. See suggestions.test.ts for test cases.
 *
 * @param line the whole line(string)
 * @param cursorPosition the cursor position in the line
 * @param getKeywords a function that returns a promise with all keywords
 * @param getKeywordValues a function that returns a promise with all values for a given keyword
 * @returns Suggestions for the current line and cursor position
 */
export async function getSuggestionsForLinePosition({
	line,
	getKeywords: getKeywordsDefault,
	getKeywordValues: getKeywordValuesDefault,
	cursorPosition,
	getAdditionalKeywords,
	getAdditionalSuggestions,
}: GetSuggestionsForLinePositionArguments): Promise<SuggestionsResult> {
	const getKeywordValues = (key: string): Promise<string[]> => {
		const additionalSuggestions = getAdditionalSuggestions ? getAdditionalSuggestions(key) : [];
		if (additionalSuggestions.length > 0) {
			return getKeywordValuesDefault(key).then((values) => [...additionalSuggestions, ...values]);
		}
		return getKeywordValuesDefault(key);
	};
	const getKeywords = (): Promise<string[]> => {
		const additionalKeywords = getAdditionalKeywords ? getAdditionalKeywords() : [];
		if (additionalKeywords.length > 0) {
			return getKeywordsDefault().then((keywords) => [...additionalKeywords, ...keywords]);
		}
		return getKeywordsDefault();
	};

	const getKeywordsWithCount = (): Promise<string[]> => {
		return getKeywords().then((keywords) => ['COUNT', ...keywords]);
	};

	if (line.trim().length === 0) {
		return map(getKeywordsWithCount(), 0, 0);
	}

	const lineTokens = TOKENIZER.tokenize(line).tokens.sort((a, b) => b.startIndex - a.startIndex) as Token[];
	// this actually should never happen since the tokenizer always returns at least one token but hey, better safe than sorry
	if (lineTokens.length === 0) {
		return map(getKeywords(), 0, 0);
	}

	const tokenAtCursor =
		lineTokens.find((t) => cursorPosition - 1 <= t.endIndex && t.startIndex <= cursorPosition - 1) || lineTokens[0];
	const tokenAtCursorIndex = lineTokens.indexOf(tokenAtCursor);
	const { scopes: type, startIndex } = tokenAtCursor;
	const endIndex = Math.min(cursorPosition, tokenAtCursor.endIndex);

	// foo = bar
	const isKeyValueClause = (): boolean =>
		tokenAtCursorIndex === 0 &&
		lineTokens.length >= 3 &&
		(isTerm(lineTokens[0]) || isQuoted(lineTokens[0])) &&
		isComparator(lineTokens[1]) &&
		isTerm(lineTokens[2]);

	const isFullCountClause = (): boolean =>
		lineTokens.length >= 6 &&
		(isTerm(lineTokens[0]) || isQuoted(lineTokens[0])) &&
		countComparatorsScopes.includes(lineTokens[1].scopes) &&
		lineTokens[2].scopes === 'RPAREN' &&
		lineTokens[3].scopes === 'TERM' &&
		lineTokens[4].scopes === 'LPAREN' &&
		lineTokens[5].scopes === 'COUNT' &&
		tokenAtCursorIndex === 0;

	const IsPresentClause = (): boolean =>
		tokenAtCursorIndex === 0 &&
		((lineTokens.length >= 3 &&
			lineTokens[0].scopes === 'PRESENT' &&
			lineTokens[1].scopes === 'IS' &&
			isTerm(lineTokens[2])) ||
			(lineTokens.length >= 4 &&
				lineTokens[0].scopes === 'PRESENT' &&
				lineTokens[1].scopes === 'NOT' &&
				lineTokens[2].scopes === 'IS' &&
				isTerm(lineTokens[3])));

	// special case: cursor is after a count comparator `count(foo.bar) > CURSOR`
	if (
		lineTokens.length >= 5 &&
		countComparatorsScopes.includes(lineTokens[0].scopes) &&
		lineTokens[1].scopes === 'RPAREN' &&
		lineTokens[2].scopes === 'TERM' &&
		lineTokens[3].scopes === 'LPAREN' &&
		lineTokens[4].scopes === 'COUNT' &&
		tokenAtCursorIndex === 0
	) {
		return Promise.resolve({
			suggestions: createSuggestions(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']),
			startIndex: cursorPosition,
			endIndex: cursorPosition,
		});
	} else if (
		// special case: cursor is after a count bracket `count(foo.bar)CURSOR`
		lineTokens.length >= 4 &&
		lineTokens[0].scopes === 'RPAREN' &&
		lineTokens[1].scopes === 'TERM' &&
		lineTokens[2].scopes === 'LPAREN' &&
		lineTokens[3].scopes === 'COUNT' &&
		tokenAtCursorIndex === 0
	) {
		return Promise.resolve({
			suggestions: createSuggestions(countComparators),
			startIndex: cursorPosition,
			endIndex: cursorPosition,
		});
	} else if (
		// special case: cursor is after a count bracket `count(foo.barCURSOR`
		lineTokens.length >= 3 &&
		lineTokens[0].scopes === 'TERM' &&
		lineTokens[1].scopes === 'LPAREN' &&
		lineTokens[2].scopes === 'COUNT' &&
		tokenAtCursorIndex === 0
	) {
		return Promise.resolve({
			suggestions: createSuggestions([')']),
			startIndex: cursorPosition,
			endIndex: cursorPosition,
		});
	}

	// special case: cursor is at whitespace
	if (line[cursorPosition - 1] === ' ' && (!line[cursorPosition] || line[cursorPosition] === ' ')) {
		if (isTerm(tokenAtCursor) || isQuoted(tokenAtCursor) || IsPresentClause()) {
			if (isKeyValueClause() || isFullCountClause() || IsPresentClause()) {
				return Promise.resolve({
					suggestions: createSuggestions(['AND', 'OR']),
					startIndex: cursorPosition,
					endIndex: cursorPosition,
				});
			}
			return Promise.resolve({
				suggestions: createSuggestions(['IS PRESENT', 'IS NOT PRESENT', ...termComparators]),
				startIndex: cursorPosition,
				endIndex: cursorPosition,
			});
		}
		if (
			isComparator(lineTokens[tokenAtCursorIndex]) &&
			(isTerm(lineTokens[tokenAtCursorIndex + 1]) || isQuoted(lineTokens[tokenAtCursorIndex + 1]))
		) {
			const comperatorToken = lineTokens[tokenAtCursorIndex + 0];
			const key = getStringForToken(line, lineTokens[tokenAtCursorIndex + 1]);
			return mapWithQuotes(getKeywordValues(key), comperatorToken.endIndex + 1, comperatorToken.endIndex + 1);
		}
		if (
			isUnknown(tokenAtCursor) &&
			isComparator(lineTokens[tokenAtCursorIndex + 1]) &&
			(isTerm(lineTokens[tokenAtCursorIndex + 2]) || isQuoted(lineTokens[tokenAtCursorIndex + 2]))
		) {
			const key = getStringForToken(line, lineTokens[tokenAtCursorIndex + 2]);
			return mapWithQuotes(getKeywordValues(key), tokenAtCursor.startIndex, tokenAtCursor.startIndex + 1);
		}
		if (
			tokenAtCursorIndex === 0 &&
			lineTokens.length >= 2 &&
			lineTokens[0].scopes === 'LPAREN' &&
			lineTokens[1].scopes === 'COUNT'
		) {
			return map(getKeywords(), cursorPosition, cursorPosition);
		}
		return map(getKeywordsWithCount(), cursorPosition, cursorPosition);
	}

	if (type === 'AND' || type === 'OR') {
		return Promise.resolve({ suggestions: createSuggestions(['AND', 'OR']), startIndex, endIndex });
	}
	if (type === 'NOT') {
		return Promise.resolve({
			// special handling for "!"
			suggestions: startIndex === endIndex ? createSuggestions(termComparators) : createSuggestions(['NOT', '!']),
			startIndex,
			endIndex,
		});
	}
	if (type === 'RPAREN') {
		return Promise.resolve({ suggestions: [], startIndex, endIndex });
	}
	if (type === 'LPAREN') {
		return mapWithParenthesis(getKeywords(), startIndex, endIndex);
	}

	if (isTerm(tokenAtCursor)) {
		if (
			isUnknown(lineTokens[tokenAtCursorIndex + 1]) &&
			isComparator(lineTokens[tokenAtCursorIndex + 2]) &&
			(isTerm(lineTokens[tokenAtCursorIndex + 3]) || isQuoted(lineTokens[tokenAtCursorIndex + 3]))
		) {
			const key = getStringForToken(line, lineTokens[tokenAtCursorIndex + 3]);
			return mapWithQuotes(getKeywordValues(key), lineTokens[tokenAtCursorIndex + 1].startIndex, endIndex);
		}
		if (isUnknown(lineTokens[tokenAtCursorIndex + 1])) {
			return mapWithQuotes(getKeywords(), startIndex - 1, endIndex);
		}
		if (tokenAtCursorIndex === 0 && lineTokens.length >= 2 && isTerm(lineTokens[0]) && isANDorOR(lineTokens[1])) {
			return map(getKeywords(), startIndex, endIndex);
		}
		if (
			tokenAtCursorIndex === 0 &&
			lineTokens.length >= 2 &&
			lineTokens[0].scopes === 'LPAREN' &&
			lineTokens[1].scopes === 'COUNT'
		) {
			return map(getKeywords(), startIndex, endIndex);
		}
		if (tokenAtCursorIndex === 0 && lineTokens.length >= 1 && isTerm(lineTokens[0])) {
			return map(getKeywordsWithCount(), startIndex, endIndex);
		}
		return map(getKeywords(), startIndex, endIndex);
	}

	if (isComparator(tokenAtCursor)) {
		const keyToken = lineTokens[tokenAtCursorIndex + 1];
		if (isTerm(keyToken) || isQuoted(keyToken)) {
			const key = getStringForToken(line, keyToken);
			return mapWithQuotes(getKeywordValues(key), endIndex + 1, endIndex + 1);
		}
	}

	if (isUnknown(tokenAtCursor)) {
		const comperatorToken = lineTokens[tokenAtCursorIndex + 1];
		const keyToken = lineTokens[tokenAtCursorIndex + 2];
		if (isComparator(comperatorToken) && (isTerm(keyToken) || isQuoted(keyToken))) {
			const key = getStringForToken(line, keyToken);
			return getStringForToken(line, tokenAtCursor) === '"'
				? mapWithQuotes(getKeywordValues(key), startIndex, endIndex + 1)
				: map(getKeywordValues(key), comperatorToken.endIndex + 1, comperatorToken.endIndex + 1);
		}
	}

	if (isQuoted(tokenAtCursor)) {
		if (line[cursorPosition - 1] === '"' && line[cursorPosition] !== '"') {
			return { suggestions: [], startIndex: cursorPosition, endIndex: cursorPosition };
		}
		if (
			isComparator(lineTokens[tokenAtCursorIndex + 1]) &&
			(isTerm(lineTokens[tokenAtCursorIndex + 2]) || isQuoted(lineTokens[tokenAtCursorIndex + 2]))
		) {
			const key = getStringForToken(line, lineTokens[tokenAtCursorIndex + 2]);
			return mapWithQuotes(getKeywordValues(key), startIndex, endIndex);
		}
		return map(getKeywords(), startIndex, endIndex);
	}

	// fallback
	return { suggestions: [], startIndex, endIndex };
}

function map(promise: Promise<string[]>, startIndex: number, endIndex: number): Promise<SuggestionsResult> {
	return promise.then((strings) => ({ suggestions: createSuggestions(strings), startIndex, endIndex }));
}

function mapWithQuotes(promise: Promise<string[]>, startIndex: number, endIndex: number): Promise<SuggestionsResult> {
	return promise.then((strings) => ({
		suggestions: strings.map((label) => ({ label, text: `"${label}"` })),
		startIndex,
		endIndex,
	}));
}

function mapWithParenthesis(
	promise: Promise<string[]>,
	startIndex: number,
	endIndex: number,
): Promise<SuggestionsResult> {
	return promise.then((strings) => ({
		suggestions: strings.map((label) => ({ label, text: `(${label} ` })),
		startIndex,
		endIndex,
	}));
}

function getStringForToken(line: string, token: Token): string {
	if (isQuoted(token)) {
		return line.slice(token.startIndex + 1, token.endIndex);
	}
	return line.slice(token.startIndex, token.endIndex + 1);
}

function isTerm(token: Token): boolean {
	return token && token.scopes === 'TERM';
}

function isComparator(token: Token): boolean {
	return (
		token &&
		(token.scopes === 'OP_EQUAL' ||
			token.scopes === 'OP_EQUAL_IGNORE_CASE' ||
			token.scopes === 'OP_NOT_EQUAL' ||
			token.scopes === 'OP_NOT_EQUAL_IGNORE_CASE' ||
			token.scopes === 'OP_TILDE' ||
			token.scopes === 'OP_TILDE_IGNORE_CASE' ||
			token.scopes === 'OP_LESS_THAN' ||
			token.scopes === 'OP_LESS_THAN_EQUAL' ||
			token.scopes === 'OP_GREATER_THAN' ||
			token.scopes === 'OP_GREATER_THAN_EQUAL' ||
			token.scopes === 'OP_NOT_TILDE' ||
			token.scopes === 'OP_NOT_TILDE_IGNORE_CASE')
	);
}

function isQuoted(token: Token): boolean {
	return token && token.scopes === 'QUOTED';
}

function isANDorOR(token: Token): boolean {
	return token && (token.scopes === 'AND' || token.scopes === 'OR');
}

function isUnknown(token: Token): boolean {
	return token && token.scopes === 'UNKNOWN';
}

function createSuggestions(texts: string[]): Suggestion[] {
	return texts.map((text) => ({ text, label: text }));
}
