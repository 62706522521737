/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { UrlParam } from 'url/useUrlState';
import { DirectionVO } from 'ui-api';

export interface UrlState {
	time: string | [number, number] | undefined;
	environmentIds: string[];
	direction: DirectionVO;
	teamIds: string[];
	states: string[];
	page: number;
	size: number;
	sort: string;
}

export const pageParam: UrlParam<number> = {
	pathSegment: '/experimentruns',
	name: 'page',
	defaultValue: 0,
};

export const sortParam: UrlParam<string> = {
	pathSegment: '/experimentruns',
	name: 'sort',
	defaultValue: 'created',
};

export const directionParam: UrlParam<DirectionVO> = {
	pathSegment: '/experimentruns',
	name: 'direction',
	defaultValue: 'DESC',
};

export const environmentIdsParam: UrlParam<string[]> = {
	pathSegment: '/experimentruns',
	name: 'environmentIds',
	defaultValue: [],
};

export const teamIdsParam: UrlParam<string[]> = {
	pathSegment: '/experimentruns',
	name: 'teamIds',
	defaultValue: [],
};

export const statesParam: UrlParam<string[]> = {
	pathSegment: '/experimentruns',
	name: 'states',
	defaultValue: [],
};

export const timeParam: UrlParam<string | [number, number] | undefined> = {
	pathSegment: '/experimentruns',
	name: 'time',
	defaultValue: undefined,
};
