/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Container, StyleProp } from 'components';
import { ReactElement } from 'react';

interface ExperimentExecutionLogLineProps {
	StateComponent: ReactElement;
	TimeComponent: ReactElement;
	children: ReactElement;
	sx?: StyleProp;
	onClick?: () => void;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
}

export default function ExperimentExecutionLogLine({
	StateComponent,
	TimeComponent,
	onMouseEnter,
	onMouseLeave,
	children,
	onClick,
	sx = {},
}: ExperimentExecutionLogLineProps): ReactElement {
	return (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',

				minHeight: '44px',
				maxHeight: '44px',
				p: '8px',
				backgroundColor: 'neutral100',
				borderRadius: '6px',
				...sx,
			}}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<Container display="flex" justifyContent="center" mr="xSmall" minWidth="fit-content">
				{StateComponent}
			</Container>

			<Container display="flex" justifyContent="center" mr="xSmall" minWidth="fit-content">
				{TimeComponent}
			</Container>

			{children}
		</Container>
	);
}
