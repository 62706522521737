/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Banner, BannerAccent, Link } from 'components';
import { SystemStatusMaintenanceVO } from 'ui-api';
import { IconMaintenance } from 'components/icons';
import { formatDateWithTime } from 'utils/dateFns';
import { minutes } from 'utils/time';
import { ReactElement } from 'react';

interface MaintenanceBannerProps {
	maintenance?: SystemStatusMaintenanceVO;
}

export default function MaintenanceBanner({ maintenance }: MaintenanceBannerProps): ReactElement | null {
	if (!maintenance) {
		return null;
	}

	return (
		<Banner
			id="maintenance-banner"
			variant="info"
			Icon={IconMaintenance}
			title={maintenance.title}
			description={
				<span>
					{maintenance.message && maintenance.message != '' ? maintenance.message + ' | ' : ''} Maintenance is starting
					at <BannerAccent>{formatDateWithTime(maintenance.start)}</BannerAccent>
					{maintenance.end != null && (
						<>
							{' '}
							and should be completed at <BannerAccent>{formatDateWithTime(maintenance.end)}</BannerAccent>
						</>
					)}
					.{' '}
					{maintenance.statusPage != null && (
						<>
							Learn more through our{' '}
							<Link href={maintenance.statusPage} external>
								status page
							</Link>
							.
						</>
					)}
				</span>
			}
			snooze={{
				id: 'planned-maintenance',
				end: new Date(maintenance.start.getTime() - minutes(60).getMillis()),
			}}
		/>
	);
}
