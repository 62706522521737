/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Container } from 'components';
import { ReactElement } from 'react';

import Chunks from './Chunks';

interface TextFieldWithVariableHighlightingProps {
	hasErrors?: boolean;
	withUnit?: boolean;
	disabled: boolean;
	value: string;
	onClick: () => void;
}

export default function TextFieldWithVariableHighlighting({
	hasErrors,
	disabled,
	withUnit,
	value,
	onClick,
}: TextFieldWithVariableHighlightingProps): ReactElement {
	return (
		<Container
			disabled={disabled}
			onClick={() => {
				if (!disabled) {
					onClick();
				}
			}}
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: '3px',

				width: '100%',
				height: '40px',

				py: 'xSmall',
				px: 'small',
				backgroundColor: 'neutral000',
				borderRadius: withUnit ? '4px 0 0 4px' : '4px',
				border: '1px solid',
				borderRight: withUnit ? 'none' : '1px solid',
				borderColor: hasErrors ? 'coral' : 'neutral300',
				overflow: 'hidden',

				cursor: disabled ? 'default' : 'text',
			}}
			data-cy="text-field-with-highlighting"
		>
			<Chunks value={value} />
		</Container>
	);
}
