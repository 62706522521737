/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react';

import './styles.css';

interface LaneMarkerProps {
	children: string;
}

export default function LaneMarker({ children }: LaneMarkerProps): ReactElement {
	return (
		<div className="lane-index-marker">
			<span className="lane-index-marker-text">{children}</span>
		</div>
	);
}
