// Generated from QueryLanguageLexer.g4 by ANTLR 4.13.0
// jshint ignore: start
import antlr4 from 'antlr4';


const serializedATN = [4,0,24,198,6,-1,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,
4,7,4,2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,2,9,7,9,2,10,7,10,2,11,7,11,2,12,7,
12,2,13,7,13,2,14,7,14,2,15,7,15,2,16,7,16,2,17,7,17,2,18,7,18,2,19,7,19,
2,20,7,20,2,21,7,21,2,22,7,22,2,23,7,23,2,24,7,24,2,25,7,25,2,26,7,26,2,
27,7,27,2,28,7,28,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,3,0,68,8,0,1,1,1,1,1,1,
1,1,1,1,1,1,3,1,76,8,1,1,2,1,2,1,2,1,2,1,2,1,2,1,2,3,2,85,8,2,1,3,1,3,1,
3,1,3,3,3,91,8,3,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,
3,4,107,8,4,1,5,1,5,1,5,1,5,1,5,1,5,1,5,1,5,1,5,1,5,3,5,119,8,5,1,6,1,6,
1,7,1,7,1,8,1,8,1,8,1,9,1,9,1,9,1,9,1,10,1,10,1,11,1,11,1,11,1,12,1,12,1,
13,1,13,1,13,1,14,1,14,1,14,1,15,1,15,1,15,1,15,1,16,1,16,1,17,1,17,1,17,
1,18,1,18,1,19,1,19,1,19,1,20,1,20,5,20,161,8,20,10,20,12,20,164,9,20,1,
20,1,20,1,21,1,21,5,21,170,8,21,10,21,12,21,173,9,21,1,22,1,22,1,22,1,22,
1,23,1,23,1,24,1,24,1,25,1,25,3,25,185,8,25,1,26,1,26,1,26,1,27,1,27,3,27,
192,8,27,1,28,1,28,1,28,3,28,197,8,28,0,0,29,1,1,3,2,5,3,7,4,9,5,11,6,13,
7,15,8,17,9,19,10,21,11,23,12,25,13,27,14,29,15,31,16,33,17,35,18,37,19,
39,20,41,21,43,22,45,23,47,24,49,0,51,0,53,0,55,0,57,0,1,0,4,4,0,9,10,13,
13,32,32,12288,12288,2,0,34,34,92,92,14,0,9,10,13,13,32,34,40,41,43,43,45,
45,47,47,58,58,60,62,64,64,91,94,123,123,125,126,12288,12288,2,0,43,43,45,
45,207,0,1,1,0,0,0,0,3,1,0,0,0,0,5,1,0,0,0,0,7,1,0,0,0,0,9,1,0,0,0,0,11,
1,0,0,0,0,13,1,0,0,0,0,15,1,0,0,0,0,17,1,0,0,0,0,19,1,0,0,0,0,21,1,0,0,0,
0,23,1,0,0,0,0,25,1,0,0,0,0,27,1,0,0,0,0,29,1,0,0,0,0,31,1,0,0,0,0,33,1,
0,0,0,0,35,1,0,0,0,0,37,1,0,0,0,0,39,1,0,0,0,0,41,1,0,0,0,0,43,1,0,0,0,0,
45,1,0,0,0,0,47,1,0,0,0,1,67,1,0,0,0,3,75,1,0,0,0,5,84,1,0,0,0,7,90,1,0,
0,0,9,106,1,0,0,0,11,118,1,0,0,0,13,120,1,0,0,0,15,122,1,0,0,0,17,124,1,
0,0,0,19,127,1,0,0,0,21,131,1,0,0,0,23,133,1,0,0,0,25,136,1,0,0,0,27,138,
1,0,0,0,29,141,1,0,0,0,31,144,1,0,0,0,33,148,1,0,0,0,35,150,1,0,0,0,37,153,
1,0,0,0,39,155,1,0,0,0,41,158,1,0,0,0,43,167,1,0,0,0,45,174,1,0,0,0,47,178,
1,0,0,0,49,180,1,0,0,0,51,184,1,0,0,0,53,186,1,0,0,0,55,191,1,0,0,0,57,196,
1,0,0,0,59,60,5,65,0,0,60,61,5,78,0,0,61,68,5,68,0,0,62,63,5,97,0,0,63,64,
5,110,0,0,64,68,5,100,0,0,65,66,5,38,0,0,66,68,5,38,0,0,67,59,1,0,0,0,67,
62,1,0,0,0,67,65,1,0,0,0,68,2,1,0,0,0,69,70,5,79,0,0,70,76,5,82,0,0,71,72,
5,111,0,0,72,76,5,114,0,0,73,74,5,124,0,0,74,76,5,124,0,0,75,69,1,0,0,0,
75,71,1,0,0,0,75,73,1,0,0,0,76,4,1,0,0,0,77,78,5,78,0,0,78,79,5,79,0,0,79,
85,5,84,0,0,80,81,5,110,0,0,81,82,5,111,0,0,82,85,5,116,0,0,83,85,5,33,0,
0,84,77,1,0,0,0,84,80,1,0,0,0,84,83,1,0,0,0,85,6,1,0,0,0,86,87,5,73,0,0,
87,91,5,83,0,0,88,89,5,105,0,0,89,91,5,115,0,0,90,86,1,0,0,0,90,88,1,0,0,
0,91,8,1,0,0,0,92,93,5,80,0,0,93,94,5,82,0,0,94,95,5,69,0,0,95,96,5,83,0,
0,96,97,5,69,0,0,97,98,5,78,0,0,98,107,5,84,0,0,99,100,5,112,0,0,100,101,
5,114,0,0,101,102,5,101,0,0,102,103,5,115,0,0,103,104,5,101,0,0,104,105,
5,110,0,0,105,107,5,116,0,0,106,92,1,0,0,0,106,99,1,0,0,0,107,10,1,0,0,0,
108,109,5,67,0,0,109,110,5,79,0,0,110,111,5,85,0,0,111,112,5,78,0,0,112,
119,5,84,0,0,113,114,5,99,0,0,114,115,5,111,0,0,115,116,5,117,0,0,116,117,
5,110,0,0,117,119,5,116,0,0,118,108,1,0,0,0,118,113,1,0,0,0,119,12,1,0,0,
0,120,121,5,40,0,0,121,14,1,0,0,0,122,123,5,41,0,0,123,16,1,0,0,0,124,125,
5,33,0,0,125,126,5,61,0,0,126,18,1,0,0,0,127,128,5,33,0,0,128,129,5,61,0,
0,129,130,5,42,0,0,130,20,1,0,0,0,131,132,5,61,0,0,132,22,1,0,0,0,133,134,
5,61,0,0,134,135,5,42,0,0,135,24,1,0,0,0,136,137,5,126,0,0,137,26,1,0,0,
0,138,139,5,126,0,0,139,140,5,42,0,0,140,28,1,0,0,0,141,142,5,33,0,0,142,
143,5,126,0,0,143,30,1,0,0,0,144,145,5,33,0,0,145,146,5,126,0,0,146,147,
5,42,0,0,147,32,1,0,0,0,148,149,5,62,0,0,149,34,1,0,0,0,150,151,5,62,0,0,
151,152,5,61,0,0,152,36,1,0,0,0,153,154,5,60,0,0,154,38,1,0,0,0,155,156,
5,60,0,0,156,157,5,61,0,0,157,40,1,0,0,0,158,162,5,34,0,0,159,161,3,51,25,
0,160,159,1,0,0,0,161,164,1,0,0,0,162,160,1,0,0,0,162,163,1,0,0,0,163,165,
1,0,0,0,164,162,1,0,0,0,165,166,5,34,0,0,166,42,1,0,0,0,167,171,3,55,27,
0,168,170,3,57,28,0,169,168,1,0,0,0,170,173,1,0,0,0,171,169,1,0,0,0,171,
172,1,0,0,0,172,44,1,0,0,0,173,171,1,0,0,0,174,175,3,49,24,0,175,176,1,0,
0,0,176,177,6,22,0,0,177,46,1,0,0,0,178,179,9,0,0,0,179,48,1,0,0,0,180,181,
7,0,0,0,181,50,1,0,0,0,182,185,8,1,0,0,183,185,3,53,26,0,184,182,1,0,0,0,
184,183,1,0,0,0,185,52,1,0,0,0,186,187,5,92,0,0,187,188,9,0,0,0,188,54,1,
0,0,0,189,192,8,2,0,0,190,192,3,53,26,0,191,189,1,0,0,0,191,190,1,0,0,0,
192,56,1,0,0,0,193,197,3,55,27,0,194,197,3,53,26,0,195,197,7,3,0,0,196,193,
1,0,0,0,196,194,1,0,0,0,196,195,1,0,0,0,197,58,1,0,0,0,12,0,67,75,84,90,
106,118,162,171,184,191,196,1,6,0,0];


const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

const decisionsToDFA = atn.decisionToState.map( (ds, index) => new antlr4.dfa.DFA(ds, index) );

export default class QueryLanguageLexer extends antlr4.Lexer {

    static grammarFileName = "QueryLanguageLexer.g4";
    static channelNames = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];
	static modeNames = [ "DEFAULT_MODE" ];
	static literalNames = [ null, null, null, null, null, null, null, "'('", 
                         "')'", "'!='", "'!=*'", "'='", "'=*'", "'~'", "'~*'", 
                         "'!~'", "'!~*'", "'>'", "'>='", "'<'", "'<='" ];
	static symbolicNames = [ null, "AND", "OR", "NOT", "IS", "PRESENT", "COUNT", 
                          "LPAREN", "RPAREN", "OP_NOT_EQUAL", "OP_NOT_EQUAL_IGNORE_CASE", 
                          "OP_EQUAL", "OP_EQUAL_IGNORE_CASE", "OP_TILDE", 
                          "OP_TILDE_IGNORE_CASE", "OP_NOT_TILDE", "OP_NOT_TILDE_IGNORE_CASE", 
                          "OP_GREATER_THAN", "OP_GREATER_THAN_EQUAL", "OP_LESS_THAN", 
                          "OP_LESS_THAN_EQUAL", "QUOTED", "TERM", "DEFAULT_SKIP", 
                          "UNKNOWN" ];
	static ruleNames = [ "AND", "OR", "NOT", "IS", "PRESENT", "COUNT", "LPAREN", 
                      "RPAREN", "OP_NOT_EQUAL", "OP_NOT_EQUAL_IGNORE_CASE", 
                      "OP_EQUAL", "OP_EQUAL_IGNORE_CASE", "OP_TILDE", "OP_TILDE_IGNORE_CASE", 
                      "OP_NOT_TILDE", "OP_NOT_TILDE_IGNORE_CASE", "OP_GREATER_THAN", 
                      "OP_GREATER_THAN_EQUAL", "OP_LESS_THAN", "OP_LESS_THAN_EQUAL", 
                      "QUOTED", "TERM", "DEFAULT_SKIP", "UNKNOWN", "WHITESPACE", 
                      "QUOTED_CHAR", "ESCAPED_CHAR", "TERM_START_CHAR", 
                      "TERM_CHAR" ];

    constructor(input) {
        super(input)
        this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.atn.PredictionContextCache());
    }
}

QueryLanguageLexer.EOF = antlr4.Token.EOF;
QueryLanguageLexer.AND = 1;
QueryLanguageLexer.OR = 2;
QueryLanguageLexer.NOT = 3;
QueryLanguageLexer.IS = 4;
QueryLanguageLexer.PRESENT = 5;
QueryLanguageLexer.COUNT = 6;
QueryLanguageLexer.LPAREN = 7;
QueryLanguageLexer.RPAREN = 8;
QueryLanguageLexer.OP_NOT_EQUAL = 9;
QueryLanguageLexer.OP_NOT_EQUAL_IGNORE_CASE = 10;
QueryLanguageLexer.OP_EQUAL = 11;
QueryLanguageLexer.OP_EQUAL_IGNORE_CASE = 12;
QueryLanguageLexer.OP_TILDE = 13;
QueryLanguageLexer.OP_TILDE_IGNORE_CASE = 14;
QueryLanguageLexer.OP_NOT_TILDE = 15;
QueryLanguageLexer.OP_NOT_TILDE_IGNORE_CASE = 16;
QueryLanguageLexer.OP_GREATER_THAN = 17;
QueryLanguageLexer.OP_GREATER_THAN_EQUAL = 18;
QueryLanguageLexer.OP_LESS_THAN = 19;
QueryLanguageLexer.OP_LESS_THAN_EQUAL = 20;
QueryLanguageLexer.QUOTED = 21;
QueryLanguageLexer.TERM = 22;
QueryLanguageLexer.DEFAULT_SKIP = 23;
QueryLanguageLexer.UNKNOWN = 24;



