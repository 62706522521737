/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	createIconFromDataUri,
	IconComponent,
	IconDiscoveryKit,
	IconEnlarge,
	IconRefresh,
	IconTarget,
	IconWarning,
} from 'components/icons';
import {
	ButtonIcon,
	Container,
	ContainerProps,
	RouterButtonIcon,
	RouterLink,
	Select,
	Stack,
	Text,
	Tooltip,
} from 'components';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import MaxAgentsMessage from 'components/Message/messages/MaxAgents';
import { OptionTypeBase } from 'components/Select/SelectOptionTypes';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import React, { useEffect, useMemo, useState } from 'react';
import useObservable from 'react-use/lib/useObservable';
import Skeletons from 'components/Skeleton/Skeletons';
import useMaxAgents from 'utils/hooks/useMaxAgents';
import { Services } from 'services/services';
import { theme } from 'styles.v2/theme';
import { ampli } from 'ampli';
import { EMPTY } from 'rxjs';

import { pageParam, queryParam, sortParam, targetIdParam, targetTypeParam } from '../../../targets/TableView/url';
import { useUrlState } from '../../../url/useUrlState';

export const DiscoveryWidget: React.VFC<ContainerProps> = ({ ...props }) => {
	const [environmentId, setEnvironmentId] = useState<string | undefined>(undefined);
	const stats$ = useMemo(
		() => (environmentId ? Services.dashboard.statsObservable(environmentId) : EMPTY),
		[environmentId],
	);

	const { selectedEnvironment, defaultSelectedEnvironment, environmentsOfCurrentTeam, environmentsIsLoading } =
		useEnvironments(environmentId);

	const environmentOptions = useMemo(
		() => environmentsOfCurrentTeam.map((as) => ({ value: as.id, label: as.name })),
		[environmentsOfCurrentTeam],
	);
	const statistics = useObservable(stats$);
	const [simulateRefresh, setSimulateRefresh] = useState(false);
	const targetDefinitions = useTargetDefinitions();

	useEffect(() => {
		if (!environmentId && defaultSelectedEnvironment) {
			setEnvironmentId(defaultSelectedEnvironment.id);
		}
	}, [defaultSelectedEnvironment, environmentId]);

	const { maxAgentsReached } = useMaxAgents();

	useEffect(() => {
		if (environmentId) {
			ampli.dashboardSystemDiscoveryViewed({
				environment_id: environmentId,
			});
		}
	}, [environmentId]);

	const targets = Object.keys(statistics?.targets || {}).map((type) => ({
		type,
		count: statistics?.targets[type] || 0,
	}));

	const isLoading = !statistics;

	return (
		<Container
			{...props}
			px={'medium'}
			py={'small'}
			sx={{
				color: 'neutral800',
				bg: 'neutral000',
				borderRadius: 2,
				boxShadow: 'applicationMedium',
			}}
		>
			<Stack size={0} direction={'horizontal'} justifyContent={'space-between'} alignItems={'center'}>
				<Stack direction="horizontal" alignItems="center" size="xsmall">
					<IconDiscoveryKit mr="xSmall" />
					<Text variant={'mediumStrong'} mr={'xSmall'}>
						System Discovery for Environment:
					</Text>
					{environmentOptions.length > 0 && (
						<Select<OptionTypeBase>
							label="label"
							onChange={(o) => {
								setEnvironmentId(o && o.value ? o.value : undefined);
								if (o && o.value) {
									ampli.dashboardSystemDiscoveryEnvironmentChanged({ environment_id: o.value });
								}
							}}
							options={environmentOptions}
							value={environmentOptions.find((eo) => eo.value === environmentId) ?? environmentOptions[0]}
							variant={'chromelessMediumStrong'}
							loading={environmentsIsLoading}
						/>
					)}
				</Stack>

				<Container display={'flex'}>
					<ButtonIcon
						variant={'chromeless'}
						sx={{ display: 'block', textAlign: 'center' }}
						onClick={() => {
							Services.dashboard.forceStatsUpdate();
							setSimulateRefresh(true);
							setTimeout(() => setSimulateRefresh(false), 1000);
							ampli.dashboardSystemDiscoveryRefreshed({ environment_id: selectedEnvironment?.id });
						}}
						mr={'xxSmall'}
						loading={simulateRefresh}
					>
						<IconRefresh />
					</ButtonIcon>
					{selectedEnvironment ? (
						<RouterButtonIcon
							to={`/landscape/${encodeURIComponent(selectedEnvironment.id)}/table`}
							onClick={() => ampli.dashboardSystemDiscoveryEnlarged({ environment_id: selectedEnvironment.id })}
							variant={'chromeless'}
							sx={{ display: 'block', textAlign: 'center' }}
						>
							<IconEnlarge />
						</RouterButtonIcon>
					) : null}
				</Container>
			</Stack>

			{maxAgentsReached && <MaxAgentsMessage mt={'xSmall'} />}

			{!environmentsIsLoading && !environmentsOfCurrentTeam.length ? (
				<Text mt={'small'} color={'coral'} variant={'small'}>
					Your team has no assigned environments. Please contact your administrator.
				</Text>
			) : (
				<Container
					mt={'small'}
					sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gridGap: '40px', alignItems: 'center' }}
				>
					<TargetCount
						environmentId={environmentId}
						isLoading={isLoading}
						count={targets.reduce((agg, t) => agg + t.count, 0)}
					/>
					{isLoading ? (
						<Stack size={'medium'}>
							<Container
								sx={{ display: 'grid', gridTemplateColumns: 'auto auto', gridGap: '12px', alignItems: 'center' }}
							>
								{Array.from({ length: 6 }).map((_, i) => {
									return <Skeletons key={i} height={19} widths={[120 + (i / 6) * 50]} />;
								})}
							</Container>
						</Stack>
					) : targets.length > 0 ? (
						<Stack size={'medium'}>
							<Container
								sx={{ display: 'grid', gridTemplateColumns: 'auto auto', gridGap: '12px', alignItems: 'center' }}
							>
								{targets
									.sort((t1, t2) => t2.count - t1.count)
									.slice(0, 5)
									.map(({ type, count }) => {
										const definition = (targetDefinitions.value || []).find((td) => td.id === type);

										return (
											<DiscoveryItem
												key={type}
												icon={definition ? createIconFromDataUri(definition.icon) : null}
												count={count}
												targetType={type}
												environmentId={environmentId}
												name={definition ? (count === 1 ? definition.label.one : definition.label.other) : type}
											/>
										);
									})}

								{targets.length > 5 && (
									<RouterLink
										to={environmentId ? `/landscape/${environmentId}/table` : '/landscape/table'}
										sx={{
											whiteSpace: 'nowrap',
											display: 'flex',
											alignItems: 'center',
											gap: '4px',
											':hover': { color: 'slate' },
										}}
										color={'neutral800'}
									>
										<IconTarget mr={'xxSmall'} width={16} height={16} />
										<Text as="span" variant="small" color="neutral600">
											See
										</Text>
										<Text as="span" variant="smallStrong" color="neutral600">
											{targets.length - 5}
										</Text>
										<Text as="span" variant="small" color="neutral600">
											more target {targets.length - 5 == 1 ? 'type' : 'types'}
										</Text>
									</RouterLink>
								)}
							</Container>
						</Stack>
					) : (
						<Stack size="xxSmall" justifyContent={'center'} alignItems={'center'} mt="medium">
							<IconWarning variant="large" mr="xSmall" />
							<Text variant={'large'}>No targets discovered.</Text>
						</Stack>
					)}
				</Container>
			)}
		</Container>
	);
};

interface DiscoveryItemEmptyTargetContentState {
	targetType: string;
}

const DiscoveryItem: React.VFC<{
	icon: IconComponent | null;
	count?: number;
	name: string;
	environmentId?: string;
	targetType: string;
}> = ({ icon, count, name, environmentId, targetType }) => {
	const Icon = icon;
	const [, getUrlWithState] = useUrlState<DiscoveryItemEmptyTargetContentState>([
		targetTypeParam(targetType),
		targetIdParam,
		queryParam,
		pageParam,
		sortParam,
	]);
	return (
		<RouterLink
			to={getUrlWithState(
				{
					targetType,
					targetId: null,
					page: 0,
					sort: [],
					query: '',
				},
				(location) => {
					location.pathname = `/landscape/${environmentId}/table`;
				},
			)}
			sx={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', ':hover': { color: 'slate' } }}
			color={'neutral800'}
		>
			{Icon && <Icon mr={'xSmall'} width={16} height={16} />}
			<Text variant="smallStrong" lineHeight={'150%'} color={'neutral800'}>
				{count ?? 0}
			</Text>
			<Tooltip content={`${count ?? 0} ${name}`} onlyShowOnEllipsis>
				<Text
					ml={4}
					fontSize={13}
					fontWeight={'normal'}
					lineHeight={'150%'}
					color={'neutral600'}
					variant={'medium'}
					sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
				>
					{name}
				</Text>
			</Tooltip>
		</RouterLink>
	);
};

const TargetCount: React.VFC<{ count: number; environmentId?: string; isLoading: boolean }> = ({
	count,
	environmentId,
	isLoading,
}) => {
	if (isLoading) {
		return (
			<Container display="flex" alignItems="center" justifyContent="center" minWidth="113px" minHeight="113px">
				<LoadingIndicator variant="xxLarge" color="slate" />
			</Container>
		);
	}

	const error = count === 0;

	const key = '@keyframes rotate';
	return (
		<Container
			sx={{
				position: 'relative',

				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',

				width: 113,
				height: 113,
			}}
		>
			<RouterLink
				to={`/landscape/${environmentId}/table`}
				variant={'chromeless'}
				sx={{ display: 'block', textAlign: 'center', ':hover': { textDecoration: 'none' }, zIndex: 2 }}
			>
				<Text fontSize={count < 10_000 ? 32 : count < 1000000 ? 24 : 18} fontWeight={600} lineHeight={'36px'}>
					{count}
				</Text>
				<Text
					color={'neutral600'}
					fontSize={10}
					lineHeight={'13px'}
					variant={'small'}
					sx={{ 'a:hover &': { textDecoration: 'underline' } }}
					data-track={'agents-count-clicked'}
				>
					Targets
					<br />
					discovered
				</Text>
			</RouterLink>
			<Container
				sx={{
					position: 'absolute',
					top: 0,

					width: 114,
					height: 114,
					borderRadius: '50%',
					border: '3px dashed ' + (error ? theme.colors.coral : theme.colors.secondary),

					zIndex: 1,
					animation: error ? '' : 'rotate 12s infinite linear',
					[key]: {
						'0%': {
							transform: 'rotate(0deg)',
						},
						'100%': {
							transform: 'rotate(-360deg)',
						},
					},
				}}
			/>
		</Container>
	);
};
