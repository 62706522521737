/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { OptionTypeBase, Select, SelectProps } from 'components';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { PageParams } from 'utils/hooks/usePage';
import { find } from 'components/Select/utils';
import { Services } from 'services/services';
import React, { useMemo } from 'react';

interface UserSelectProps extends Omit<SelectProps<OptionTypeBase>, 'value' | 'onChange' | 'options'> {
	value?: string | null;
	onChange?: (v: string | null) => void;
	includeAny?: boolean;
	name?: string;
}

const OPTION_ANY = { label: 'Any', value: null };

export const UserSelect: React.VFC<UserSelectProps> = ({ value, onChange, includeAny = true, ...props }) => {
	const [options] = useAsyncState(async () => {
		const users = (await Services.users.fetchUsers(new PageParams(0, 1000))).content;
		const options = users.map((u) => ({ label: u.name ?? u.username, value: u.username }));
		return includeAny ? [OPTION_ANY, ...options] : options;
	});
	const selected = useMemo(() => find(options.value ?? [], (o) => o.value === value), [options, value]);
	return (
		<Select
			label={'User'}
			inlineLabel
			options={options.value ?? []}
			loading={options.loading}
			value={selected}
			onChange={(option) => onChange?.(option && 'value' in option ? option.value : null)}
			data-private
			{...props}
		/>
	);
};
