/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { KeyboardEvent } from 'react';

export const suppressSubmitOnEnter = (e: KeyboardEvent): void => {
	if (e.key === 'Enter' && 'textarea' !== (e.target as HTMLInputElement).type) {
		e.preventDefault();
	}
};
