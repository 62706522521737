/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { LandscapeGroup, LandscapeTarget } from '../types';

export interface LayoutedTarget {
	x: number;
	y: number;
	target: LandscapeTarget;
	parent: LayoutedGroup;
}
export function isLayoutedTarget(entity: LayoutedTarget | LayoutedGroup | null): entity is LayoutedTarget {
	return !!entity && (entity as LayoutedTarget).target !== undefined;
}

interface LayoutedGroupBase {
	x: number;
	y: number;
	r: number;
	group: LandscapeGroup;
	parent: LayoutedGroup | null;
}
interface LayoutedGroupWithSubgroups extends LayoutedGroupBase {
	layoutedGroups: LayoutedGroup[];
	layoutedTargets?: never;
}
interface LayoutedGroupWithTargets extends LayoutedGroupBase {
	layoutedGroups?: never;
	layoutedTargets: LayoutedTarget[];
}
export type LayoutedGroup = LayoutedGroupWithSubgroups | LayoutedGroupWithTargets;
export function isLayoutedGroupWithSubgroups(entity?: LayoutedGroup): entity is LayoutedGroupWithSubgroups {
	return !!entity?.layoutedGroups;
}

export interface ScreenSpaceGroup {
	x: number;
	y: number;
	r: number;
	group: LayoutedGroup;
}

interface GroupsChangedEvent {
	type: 'groupsChanged';
	groups: LayoutedGroup[];
}

interface GroupsPositionChangedEvent {
	type: 'groupsPositionChanged';
	groups: ScreenSpaceGroup[];
	screenWidth: number;
	screenHeight: number;
}

interface DragStartEvent {
	type: 'dragStart';
}

interface DragStopEvent {
	type: 'dragStop';
}

interface MouseMoveEvent {
	type: 'mouseMove';
	x: number;
	y: number;
}

export interface HoverEntity {
	type: 'hoverEntity';
	entity: LayoutedTarget | LayoutedGroup | null;
}

export interface SelectEntity {
	type: 'selectEntity';
	entity: LayoutedTarget | LayoutedGroup | null;
}

interface MouseClickEvent {
	type: 'mouseClick';
}

interface AdviceEvent {
	type: 'advice';
	active: boolean;
}

interface ColorLegendHoveredEvent {
	type: 'colorLegendHovered';
	colorAttributeKey?: string;
	colorAttributeValues?: string[];
}

export type ExploreViewEvent =
	| MouseMoveEvent
	| DragStartEvent
	| DragStopEvent
	| GroupsChangedEvent
	| GroupsPositionChangedEvent
	| MouseClickEvent
	| SelectEntity
	| ColorLegendHoveredEvent
	| HoverEntity
	| AdviceEvent;

export function isGroupsChangedEvent(event: ExploreViewEvent): event is GroupsChangedEvent {
	return event.type === 'groupsChanged';
}

export function isGroupsPositionChangedEvent(event: ExploreViewEvent): event is GroupsPositionChangedEvent {
	return event.type === 'groupsPositionChanged';
}

export function isDragStartEvent(event: ExploreViewEvent): event is DragStartEvent {
	return event.type === 'dragStart';
}

export function isDragStopEvent(event: ExploreViewEvent): event is DragStopEvent {
	return event.type === 'dragStop';
}

export function isMouseMoveEvent(event: ExploreViewEvent): event is MouseMoveEvent {
	return event.type === 'mouseMove';
}

export function isHoverEntityEvent(event: ExploreViewEvent): event is HoverEntity {
	return event.type === 'hoverEntity';
}

export function isSelectEntity(event: ExploreViewEvent): event is SelectEntity {
	return event.type === 'selectEntity';
}

export function isMouseClickEvent(event: ExploreViewEvent): event is MouseClickEvent {
	return event.type === 'mouseClick';
}

export function isColorLegendHoveredEvent(event: ExploreViewEvent): event is ColorLegendHoveredEvent {
	return event.type === 'colorLegendHovered';
}
