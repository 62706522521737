/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ComboboxPlain, Container, OptionTypeBase, Skeletons, Stack, Tag, Text } from 'components';
import { ReactElement, useMemo, useState } from 'react';
import { IconSearch, IconTeam } from 'components/icons';
import { TeamSummaryVO } from 'ui-api';
import { useField } from 'formik';
import { keys } from 'lodash';

import { DataStreamResult } from '../../../../utils/hooks/stream/result';

interface SidebarTeamsModuleProps {
	addFakeAdminTeam?: boolean;
	teams: DataStreamResult<TeamSummaryVO[]>;
}

export default function SidebarTeamsModule({ addFakeAdminTeam, teams }: SidebarTeamsModuleProps): ReactElement {
	const [field, , { setValue, setTouched }] = useField<string[]>('teamIds');

	return (
		<SidebarTeamsModuleRenderer
			addFakeAdminTeam={addFakeAdminTeam}
			teams={teams.value ?? []}
			addTeam={(teamId) => {
				setValue([...field.value, teamId]);
				setTouched(true);
			}}
			removeTeam={(teamId) => {
				setValue(field.value.filter((t) => t !== teamId));
				setTouched(true);
			}}
			addedTeamIds={field.value}
			isLoading={teams.loading}
		/>
	);
}

interface SidebarTeamsModuleRendererProps {
	removeTeam: (teamId: string) => void;
	addTeam: (teamId: string) => void;
	addFakeAdminTeam?: boolean;
	teams: TeamSummaryVO[];
	addedTeamIds: string[];
	isLoading?: boolean;
	disabled?: boolean;
}

export function SidebarTeamsModuleRenderer({
	addFakeAdminTeam,
	addedTeamIds,
	removeTeam,
	isLoading,
	disabled,
	addTeam,
	teams,
}: SidebarTeamsModuleRendererProps): ReactElement {
	const [searchterm, setSearchterm] = useState('');

	const adminTeamId = useMemo(() => {
		if (!isLoading && teams) {
			const admTeam = teams.find((t) => t.key === 'ADM');
			if (admTeam) {
				return admTeam.id;
			}
		}
		return undefined;
	}, [teams]);

	const selectedTeams: Record<string, string> = useMemo(() => {
		const result: Record<string, string> = {};
		// The admin team is added in the backend, when saving the environment,
		// so we need to add it here as well on not yet saved environments
		if (adminTeamId && addFakeAdminTeam) {
			result[adminTeamId] = 'Administrators';
		}
		if (!isLoading && teams) {
			teams
				.filter((t) => addedTeamIds.indexOf(t.id) >= 0)
				.forEach((t) => {
					result[t.id] = t.name;
				});
		}
		return result;
	}, [addedTeamIds, teams]);

	const teamsOptions = useMemo(() => {
		if (!isLoading && teams) {
			return teams
				.filter((t) => t.key !== 'ADM')
				.map((t) => ({
					value: t.id,
					label: t.name,
				}))
				.filter((t) => !searchterm || t.label.toLowerCase().includes(searchterm))
				.filter((t) => addedTeamIds.indexOf(t.value) < 0);
		}
	}, [teams, searchterm, addedTeamIds]);

	return (
		<Stack>
			<Stack size="xxSmall">
				<Stack direction="horizontal" size="xxSmall">
					<IconTeam />
					<Text variant="mediumStrong">Add Teams</Text>
				</Stack>
				<Text variant="small" color="neutral800">
					Add the Teams that will be able to do Experiments within the scope of this Environment.
				</Text>
			</Stack>

			<Stack size="xxSmall">
				{isLoading ? (
					<Skeletons height={34} widths={['100%']} />
				) : (
					<ComboboxPlain<OptionTypeBase>
						disabled={disabled}
						options={teamsOptions || []}
						value={null}
						variant={'small'}
						onChange={(selection) => {
							if (selection?.value) {
								addTeam(selection.value);
							}
						}}
						onInputValueChange={(value) => setSearchterm(value || '')}
						placeholder="Select Teams"
						iconLeft={IconSearch}
					/>
				)}
				<Container>
					{keys(selectedTeams).map((teamId) => {
						const disabled = teamId === adminTeamId;
						return (
							<Tag
								key={teamId}
								isRemoveable
								variant="small"
								disabled={disabled}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									removeTeam(teamId);
								}}
								mx={'xxSmall'}
								mb={'xxSmall'}
								sx={
									disabled
										? {
												color: 'neutral500',
												bg: 'neutral100',
												':hover': {
													bg: 'neutral100',
												},
											}
										: {
												color: 'neutral800',
												bg: 'neutral200',
												':hover': {
													bg: 'neutral300',
												},
											}
								}
							>
								{selectedTeams[teamId]}
							</Tag>
						);
					})}
				</Container>
			</Stack>
		</Stack>
	);
}
