/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import {
	Code,
	ModalContentV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	Spinner,
	Stack,
	WithDownloadOptions,
} from 'components';
import { ReactElement } from 'react';

type FileModalProps = {
	title: string;
	content?: string;
	lang?: 'yaml' | 'json';
	withDownload?: WithDownloadOptions;
	onClose: () => void;
};

export default function FileModal({
	title,
	lang = 'yaml',
	content,
	onClose,
	withDownload,
}: FileModalProps): ReactElement {
	return (
		<ModalOverlay open onClose={onClose}>
			{({ close }) => (
				<ModalV2 width="100%" maxWidth="1280px" slick>
					<ModalHeaderV2 title={title} onClose={close} />
					<ModalContentV2>
						{!content ? (
							<Stack mt="large">
								<Spinner variant="xxLarge" color="neutral600" mx="auto" />
							</Stack>
						) : (
							<Code lang={lang} withCopyToClipboard withDownload={withDownload}>
								{content}
							</Code>
						)}
					</ModalContentV2>
				</ModalV2>
			)}
		</ModalOverlay>
	);
}
