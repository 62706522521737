/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	ContainerProps,
	FormikBitrateField,
	FormikCombobox,
	FormikDurationField,
	FormikNumberInput,
	FormikStressngWorkerInput,
	FormikTextArrayField,
	FormikTextField,
	FormikToggle,
	FormikUnitField,
	SettingsGroupItemProps,
} from 'components';
import { FormikKeyValueListInput } from 'components/Formik/FormikKeyValueListInput';
import FormikEnvVarOrUri from 'components/Formik/FormikUriWithEnvVarSupport';
import { FormikFileUpload } from 'components/Formik/FormikFileUpload';
import FormikMultiSelect from 'components/Formik/FormikMultiSelect';
import { FieldVO, OptionVO } from 'ui-api';
import { Separator } from 'hocs/Separator';
import { Header } from 'hocs/Header';
import { useField } from 'formik';
import React from 'react';

import SettingsGroupItemGrid from './SettingsGroup/SettingsGroupItemGrid';
import { FieldVOOutput } from '../hocs/FieldVOOutput';

interface SettingsGroupFieldVOItemProps extends SettingsGroupItemProps {
	field: FieldVO;
	name?: string;
	autoFocus?: boolean;
}

export const SettingsGroupFieldVOItem: React.FC<SettingsGroupFieldVOItemProps> = ({
	field,
	name,
	disabled,
	width,
	autoFocus,
	...props
}) => {
	const [formikField] = useField<string>(name || '');
	if (field.type === 'header') {
		return <Header variant="small" mb={'small'} label={field.label} />;
	} else if (field.type === 'separator') {
		return <Separator />;
	}
	if (field.deprecated && !formikField.value) {
		return null;
	}
	return (
		<SettingsGroupItemGrid
			direction={field.type === 'boolean' ? 'horizontal' : 'vertical'}
			label={field.label}
			description={field.description}
			optional={!field.required}
			deprecated={field.deprecated}
			deprecationMessage={field.deprecationMessage}
			hint={field.hint}
			{...props}
		>
			{field.readonly ? (
				<FieldVOOutput field={field} value={field.defaultValue} />
			) : (
				<FormikFieldVOInput
					name={name ?? field.name}
					type={field.type}
					disabled={disabled || field.disabled}
					options={field.options}
					width={width}
					default={field.defaultValue}
					min={field.min}
					max={field.max}
					acceptedFileTypes={field.acceptedFileTypes}
					autoFocus={autoFocus}
					optional={!field.required}
				/>
			)}
		</SettingsGroupItemGrid>
	);
};

interface FormikFieldVOInputProps extends Pick<ContainerProps, 'width'> {
	name: string;
	type: string;
	disabled?: boolean;
	options?: OptionVO[];
	default?: string;
	min?: number;
	max?: number;
	acceptedFileTypes?: string[];
	autoFocus?: boolean;
	optional?: boolean;
}

const FormikFieldVOInput: React.VFC<FormikFieldVOInputProps> = ({
	name,
	type,
	options,
	disabled,
	width,
	min,
	max,
	acceptedFileTypes,
	autoFocus,
	optional,
}) => {
	const common = {
		name,
		disabled,
		autoFocus,
	};

	if (type === 'boolean') {
		return <FormikToggle {...common} />;
	}

	if (type === 'password') {
		return <FormikTextField type={'password'} {...common} width={width} />;
	}

	if (type === 'file') {
		return <FormikFileUpload {...common} width={width} accept={acceptedFileTypes?.join(',')} />;
	}

	if (type === 'integer') {
		return <FormikNumberInput {...common} width={width} min={min ? min : 0} max={max} />;
	}

	if (type === 'percentage') {
		return <FormikUnitField unit={'%'} {...common} width={width} pattern={'[0-9]*'} />;
	}

	if (type === 'stressng-workers') {
		return <FormikStressngWorkerInput {...common} minHeight={37} width={width} min={min ? min : 0} max={max} />;
	}

	if (type === 'duration') {
		return <FormikDurationField {...common} width={width} />;
	}

	if (type === 'bitrate') {
		return <FormikBitrateField {...common} width={width} />;
	}

	if (type === 'key-value') {
		return <FormikKeyValueListInput {...common} width={width} />;
	}

	if (type === 'textarea') {
		return <FormikTextField {...common} width={width} as={'textarea'} />;
	}

	if (type === 'string[]') {
		if (options?.length) {
			return <FormikMultiSelect options={options} {...common} width={width} />;
		}
		return <FormikTextArrayField {...common} width={width} />;
	}

	if (type === 'url') {
		return <FormikEnvVarOrUri {...common} width={width} />;
	}

	if (options?.length) {
		return <FormikCombobox options={options} {...common} width={width} clearable={!!optional} />;
	} else {
		return <FormikTextField {...common} width={width} />;
	}
};
