/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import TermsOfServicesDialogContent from 'pages/settings/account/TermsOfServicesDialogContent';
import { Container, Stack } from 'components';
import { CurrentUserVO } from 'ui-api';
import { ReactElement } from 'react';
import { Brand } from 'images';

import UserQuestionaireContent from './UserQuestionaireContent';
import BackgroundImage from './BackgroundImage';

interface UserQuestionaireProps {
	user: CurrentUserVO;
	page: number;
}

export default function UserQuestionaire({ user, page }: UserQuestionaireProps): ReactElement {
	return (
		<Container
			sx={{
				position: 'fixed',
				top: 0,
				right: 0,
				left: 0,
				bottom: 0,

				background: 'linear-gradient(45deg, #110657 0%, #1C0B7D 100%)',

				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',

				color: 'neutral800',
			}}
		>
			<BackgroundImage alternativeVersion={user.termsAccepted} />
			<Container
				sx={{
					overflowY: 'auto',
					width: '100%',
					paddingBottom: 'xxLarge',
					zIndex: 2,
				}}
			>
				<Stack mt="xxxxLarge" alignItems="center" size="xxxLarge">
					<Brand color="#ffffff" width={276} />

					{!user.termsAccepted ? (
						<TermsOfServicesDialogContent user={user} />
					) : (
						<Container
							justifyContent="center"
							sx={{
								backgroundColor: 'neutral100',
								borderRadius: '8px',
								padding: '32px 64px',
							}}
						>
							<UserQuestionaireContent user={user} page={page} />
						</Container>
					)}
				</Stack>
			</Container>
		</Container>
	);
}
