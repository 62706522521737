/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import TargetDetails from 'targets/TargetDetails/TargetDetails';
import { IconChevronLeft } from 'components/icons';
import { useUrlState } from 'url/useUrlState';
import { Stack, Text } from 'components';
import { TargetId } from 'targets/util';
import { ReactElement } from 'react';

import { UrlState, selectedTargetIdParam } from '../urlParams';

interface TargetDetailsProps {
	environmentId: string;
	adviceType: string;
	targetId: TargetId;
}

export default function TargetDetailsView({ environmentId, targetId, adviceType }: TargetDetailsProps): ReactElement {
	const [, , updateUrlState] = useUrlState<UrlState>([selectedTargetIdParam]);

	return (
		<Stack overflowY="auto" maxHeight="100%" px="xLarge" pt="xLarge" pb="small">
			<Stack
				direction="horizontal"
				size="xSmall"
				alignItems="center"
				color="neutral600"
				onClick={() => updateUrlState({ selectedTargetId: null }, false)}
				sx={{
					cursor: 'pointer',
					':hover': {
						color: 'neutral700',
					},
				}}
			>
				<IconChevronLeft />
				<Text variant="smallStrong">Go back to Target list</Text>
			</Stack>
			<TargetDetails
				environmentId={environmentId}
				targetId={targetId}
				adviceType={adviceType}
				defaultToAdvicePage
				withAdvice
			/>
		</Stack>
	);
}
