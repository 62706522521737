/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

// eslint-disable-next-line no-restricted-imports
import { Link as LinkInternal, LinkProps as LinkPropsInternal } from 'react-router-dom';
import { ForwardedRef, ReactElement, forwardRef } from 'react';

import { To, resolveTo } from './Redirect';
import { useHistory } from './hooks';

export interface LinkProps<T> extends Omit<LinkPropsInternal<T>, 'to'> {
	dontResolve?: boolean;
	to: To;
}

export const Link = forwardRef(function Link<T>(
	{ to, href, dontResolve, ...otherProps }: LinkProps<T>,
	ref: ForwardedRef<HTMLAnchorElement>,
): ReactElement {
	const history = useHistory();
	let resolvedHref: string = resolveTo(history, to || href).href;
	if (dontResolve) {
		if (typeof to === 'function') {
			resolvedHref = history.createHref(to);
		} else {
			resolvedHref = to || href || '';
		}
	}

	return <LinkInternal {...otherProps} ref={ref} to={resolvedHref} />;
});
