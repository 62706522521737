/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import MultiSelectList from 'components/Select/Dropdown/presets/MultiSelectList';
import DropDownButton from 'components/Select/Dropdown/DropdownButton';
import { ReactElement } from 'react-markdown/lib/react-markdown';

import { UseSelectedLogLevelResult } from './useSelectedLogLevel';

export default function EventLogLevelFilter({
	selectedLogLevel,
	setSelectedLogLevel,
	availableLogLevel,
}: UseSelectedLogLevelResult): ReactElement | null {
	if (availableLogLevel.length === 0) {
		return null;
	}

	return (
		<DropDownButton variant="secondary" value={selectedLogLevel.join(', ') || 'All'} width={200}>
			{() => (
				<DropdownContentFrame maxHeight={200} snapToComponent>
					<MultiSelectList
						width={200}
						selectedItemValues={selectedLogLevel}
						items={availableLogLevel.map((level) => ({ value: level, label: level }))}
						selectItem={(level) => setSelectedLogLevel([...selectedLogLevel, level.value])}
						removeSelectedItem={(level) =>
							setSelectedLogLevel(selectedLogLevel.filter((_level) => _level !== level.value))
						}
					/>
				</DropdownContentFrame>
			)}
		</DropDownButton>
	);
}
