// Generated from QueryLanguageParser.g4 by ANTLR 4.13.0
// jshint ignore: start
import antlr4 from 'antlr4';

// This class defines a complete generic visitor for a parse tree produced by QueryLanguageParser.

export default class QueryLanguageParserVisitor extends antlr4.tree.ParseTreeVisitor {

	// Visit a parse tree produced by QueryLanguageParser#topLevelQuery.
	visitTopLevelQuery(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryLanguageParser#query.
	visitQuery(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryLanguageParser#disjQuery.
	visitDisjQuery(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryLanguageParser#conjQuery.
	visitConjQuery(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryLanguageParser#modClause.
	visitModClause(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryLanguageParser#modifier.
	visitModifier(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryLanguageParser#clause.
	visitClause(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryLanguageParser#groupingExpr.
	visitGroupingExpr(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryLanguageParser#fieldName.
	visitFieldName(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryLanguageParser#term.
	visitTerm(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryLanguageParser#quotedTerm.
	visitQuotedTerm(ctx) {
	  return this.visitChildren(ctx);
	}



}