/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	isPredicateEditableWithTheQueryBuilder,
	isQueryLanguagePredicateVO,
	isTargetAttributeKeyValuePredicateVO,
} from 'components/PredicateEditor/utils';
import VariablesAndPlaceholders from 'pages/experimentsV2/StepConfigurationSidebar/Fields/Controls/VariablesAndPlaceholders';
import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import { EnvironmentVariableVO, QueryLanguagePredicateVO } from 'ui-api';
import DropdownInput from 'components/Select/Dropdown/DropdownInput';
import { toTargetPredicate } from 'queryLanguage/parser/parser';
import Labels from 'components/Select/Dropdown/presets/Labels';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { getCategoryLabel } from 'services/targetsApi';
import { localeCompareIgnoreCase } from 'utils/string';
import { quoteForVariables } from 'utils/envVars';
import { groupBy, isEmpty } from 'lodash';
import React, { useMemo } from 'react';

import { ButtonIcon, Container, smellsLikeTemplatePlaceholder, Stack, Tag, TextField } from '..';
import { OptionTypeBase, SelectOptionGroup } from '../Select/SelectOptionTypes';
import { IconAlertTriangle, IconDelete, IconRoundSpinner } from '../icons';
import DropdownInputFilterable from './DropdownInputFilterable';
import * as Types from '../Select/SelectOptionTypes';
import { find } from '../Select/utils';
import { Divider } from '../Divider';
import { Select } from '../Select';

export interface CompositePredicate {
	operator?: string;
	predicates?: AttributePredicate[];
}

export interface AttributePredicate {
	key: string;
	operator: string;
	values?: string[];
}

export type ValueValidator = (
	value: string,
	options: Types.SelectOptions<OptionTypeBase>[],
) => [boolean, string | undefined] | undefined;

export interface PredicateBuilderProps {
	value?: CompositePredicate;
	attributeKeys?: string[];
	attributeKeysLoading: boolean;
	fetchAttributeValues?: (key: string) => Promise<string[]>;
	operators?: string[];
	onChange?: (value: CompositePredicate | undefined) => void;
	onAttributeKeyChange?: (key: string, change: 'added' | 'removed' | 'replaced') => void;
	valueValidator?: ValueValidator;
	disabled?: boolean;
	variant?: string;
	variables?: EnvironmentVariableVO[];
}

export const PredicateBuilder: React.VFC<PredicateBuilderProps> = ({
	value = {
		predicates: [],
		operator: 'AND',
	},
	onChange,
	onAttributeKeyChange,
	disabled,
	attributeKeys,
	attributeKeysLoading,
	fetchAttributeValues,
	valueValidator,
	operators = ['EQUALS', 'CONTAINS', 'EQUALS_IGNORE_CASE', 'CONTAINS_IGNORE_CASE'],
	variant = 'medium',
	variables = [],
}) => {
	if (value && isQueryLanguagePredicateVO(value) && isPredicateEditableWithTheQueryBuilder(value)) {
		const predicate = toTargetPredicate((value as QueryLanguagePredicateVO).query);
		if (isTargetAttributeKeyValuePredicateVO(predicate)) {
			value = {
				predicates: [predicate],
				operator: 'AND',
			};
		} else {
			value = predicate as CompositePredicate;
		}
	}
	if (value && isTargetAttributeKeyValuePredicateVO(value)) {
		value = {
			predicates: [value],
			operator: 'AND',
		};
	}

	const [newPredicate, setNewPredicate] = React.useState<AttributePredicate>({
		key: '',
		operator: 'EQUALS',
		values: [],
	});
	const predicates = React.useMemo(() => value.predicates ?? [], [value?.predicates]);
	const predicateList = React.useMemo(
		() => [...predicates, ...(disabled ? [] : [newPredicate])],
		[predicates, disabled, newPredicate],
	);

	if (disabled && predicateList.length === 0) {
		return (
			<Tag variant={'small'} bg={'cyanLight'} m={4}>
				any
			</Tag>
		);
	}
	return (
		<Stack size={'small'}>
			{predicateList.map((v, i) => {
				const isNewPredicate = i >= (value.predicates?.length ?? 0);
				const handlers = isNewPredicate
					? {
							onChange: setNewPredicate,
							onDelete: undefined,
							onComplete: (predicate: AttributePredicate) => {
								onAttributeKeyChange?.(predicate.key, 'added');
								onChange?.({ ...value, predicates: [...predicates, predicate] });
								setNewPredicate({
									key: '',
									operator: 'EQUALS',
									values: [],
								});
							},
						}
					: {
							onChange: (predicate: AttributePredicate) => {
								onAttributeKeyChange?.(predicate.key, 'replaced');
								onChange?.({
									...value,
									predicates: replaceAt(predicates, i, predicate),
								});
							},
							onDelete: () => {
								onAttributeKeyChange?.(predicates[i].key, 'removed');
								const newPredicates = removeAt(predicates, i);
								if (newPredicates.length) {
									return onChange?.({
										...value,
										predicates: newPredicates,
									});
								} else {
									return onChange?.(undefined);
								}
							},
							onComplete: undefined,
						};
				return (
					<React.Fragment key={i}>
						{i > 0 ? <Divider variant={'left'}>And</Divider> : null}
						<SinglePredicateBuilder
							value={v}
							disabled={disabled}
							attributeKeys={attributeKeys}
							attributeKeysLoading={attributeKeysLoading}
							fetchAttributeValues={fetchAttributeValues}
							operators={operators}
							id={`predicate-${i}`}
							variant={variant}
							variables={variables}
							valueValidator={valueValidator}
							{...handlers}
						/>
					</React.Fragment>
				);
			})}
		</Stack>
	);
};

function replaceAt<T>(array: T[], index: number, item: T): T[] {
	const newArray = [...array];
	newArray[index] = item;
	return newArray;
}

function removeAt<T>(array: T[], index: number): T[] {
	const newArray = [...array];
	newArray.splice(index, 1);
	return newArray;
}

function getCategory(key: string): string {
	if (key.startsWith('k8s.pod.label')) {
		return 'k8s.pod.label';
	}
	const [category] = key.split('.', 1);
	return category ?? key;
}

type AttributeKeyOption = OptionTypeBase;
type AttributeKeyGroup = SelectOptionGroup<AttributeKeyOption>;
type AttributeValueOption = OptionTypeBase;

function operatorsToOptions(operators: string[]): OptionTypeBase[] {
	return operators.map((operator) => ({
		label: operator.toLowerCase().replace(/_/g, ' '),
		value: operator,
	}));
}

function attributesKeyToOptions(attributeKeys: string[]): AttributeKeyGroup[] {
	const byCategory = Object.entries(groupBy(attributeKeys, getCategory));
	return byCategory.map(([category, keys]) => ({
		label: getCategoryLabel(category),
		options: keys.map((key) => ({
			label: key,
			value: key,
		})),
	}));
}

function attributesToValueOptions(values: string[]): AttributeValueOption[] {
	return values.map((value) => {
		const quoted = quoteForVariables(value);
		return { label: quoted, value: quoted };
	});
}

interface SinglePredicateBuilderProps {
	value: AttributePredicate;
	attributeKeys?: string[];
	attributeKeysLoading: boolean;
	fetchAttributeValues?: (key: string) => Promise<string[]>;
	operators?: string[];
	onChange?: (value: AttributePredicate) => void;
	onComplete?: (value: AttributePredicate) => void;
	onDelete?: () => void;
	disabled?: boolean;
	id?: string;
	variant: string;
	variables: EnvironmentVariableVO[];
	valueValidator?: ValueValidator;
}

const SinglePredicateBuilder: React.VFC<SinglePredicateBuilderProps> = ({
	value,
	attributeKeys = [],
	attributeKeysLoading,
	fetchAttributeValues = () => [],
	operators = [],
	disabled,
	id = 'predicate',
	valueValidator,
	variant,
	onChange,
	onDelete,
	onComplete,
	variables,
}) => {
	const operatorOptions = useMemo(() => operatorsToOptions(operators), [operators]);
	const keyOptions = useMemo(() => attributesKeyToOptions(attributeKeys), [attributeKeys]);
	const selectedKey = useMemo(
		() => find(keyOptions, value.key ? (o) => o.value === value.key : undefined) ?? null,
		[keyOptions, value.key],
	);

	const [valueOptions] = useAsyncState(
		async () => (value.key ? attributesToValueOptions(await fetchAttributeValues(value.key)) : []),
		[fetchAttributeValues, value.key],
	);

	const filledValues = value.values?.filter(Boolean) ?? [];
	const values = React.useMemo(() => {
		const selectedValues = filledValues
			? filledValues.map((value) => valueOptions.value?.find((o) => o.value === value) ?? null)
			: [];

		const allSelectionsAreValid = filledValues?.length === 0 || selectedValues.filter((v) => v != null).length > 0;
		return disabled || !allSelectionsAreValid
			? selectedValues
			: [...selectedValues, { key: '', operator: 'EQUALS', values: [] }];
	}, [valueOptions.value, filledValues.join(','), disabled]);

	const emitChange = (predicate: AttributePredicate): void => {
		onChange?.(predicate);
		if (predicate.key && predicate.operator && !isEmpty(predicate.values)) {
			onComplete?.(predicate);
		}
	};

	const hasValidKey = Boolean(selectedKey || !value.key || smellsLikeTemplatePlaceholder(value.key));

	return (
		<Container
			sx={{
				display: 'grid',
				gridTemplateColumns: '1fr auto 1fr auto',
				alignItems: 'center',
			}}
			tx={'predicateBuilder'}
			variant={variant}
		>
			<DropdownInputFilterable
				small={variant === 'small'}
				value={value.key}
				hasError={!hasValidKey}
				disabled={!!disabled}
				attributeKeys={attributeKeys}
				attributeKeysLoading={attributeKeysLoading}
				onValueChanged={(_v) => emitChange({ ...value, key: _v, values: [] })}
			/>
			<Select
				id={`${id}-operator`}
				options={operatorOptions}
				value={operatorOptions.find((option) => option.value === value.operator)}
				width={'8em'}
				onChange={(option) => emitChange({ ...value, operator: option?.value || '', values: [] })}
				disabled={disabled || (!value.key && isEmpty(value.values))}
				variant={variant}
			/>
			{values.map((_, i) => {
				const isNewElement = i >= (value.values?.length ?? 0);
				return (
					<ValueInput
						key={i}
						id={`${id}-value-${i}`}
						value={value.values?.[i] ?? ''}
						operator={value.operator}
						options={valueOptions?.value ?? []}
						loading={valueOptions.loading}
						disabled={disabled}
						valueValidator={valueValidator}
						isFirst={i === 0}
						variant={variant}
						variables={variables}
						onChange={(changed) =>
							emitChange({
								...value,
								values: replaceAt(value.values ?? [], i, changed ?? ''),
							})
						}
						onDelete={
							!isNewElement
								? () => {
										if (value.values?.length === 1) {
											onDelete?.();
										} else {
											emitChange({ ...value, values: removeAt(value.values ?? [], i) });
										}
									}
								: selectedKey && value.values?.length === 0
									? () => emitChange({ ...value, key: '', operator: 'EQUALS' })
									: undefined
						}
					/>
				);
			})}
		</Container>
	);
};

type ValueInputProps = {
	options: Types.SelectOptions<Types.OptionTypeBase>[];
	variables: EnvironmentVariableVO[];
	disabled?: boolean;
	loading?: boolean;
	isFirst: boolean;
	operator: string;
	variant: string;
	value?: string;
	id: string;
	onChange: (value: string) => void;
	valueValidator?: ValueValidator;
	onDelete?: () => void;
};

const ValueInput: React.VFC<ValueInputProps> = ({
	variables,
	operator,
	isFirst,
	options,
	loading,
	value,
	id,
	valueValidator,
	onDelete,
	onChange,
	...props
}) => {
	const [hasValidValue, validationError] = useMemo(() => {
		if (!value) {
			return [true, undefined];
		}

		if (valueValidator) {
			const validationResult = valueValidator(value, options);
			if (validationResult) {
				return validationResult;
			}
		}

		const foundValue = options.some((o) => localeCompareIgnoreCase(o.label, value) === 0);
		return [foundValue, undefined];
	}, [value, variables, options]);

	return (
		<>
			{!isFirst && (
				<>
					<div />
					<OrDivider />
				</>
			)}
			{operator === 'EQUALS' || operator === 'EQUALS_IGNORE_CASE' ? (
				<>
					<DropdownInput
						small={props.variant === 'small'}
						value={value ?? ''}
						hasError={!hasValidValue}
						disabled={props.disabled}
						onValueChanged={(_v) => onChange(_v)}
						placeholder="Value"
						placement="bottom-end"
						width="100%"
						iconRight={
							validationError && (
								<ButtonIcon
									onClick={() => document.getElementById('environmentVariablesDropDownButton')?.click()}
									tooltip={validationError}
									color={'coral'}
									muted={props.disabled}
									disabled={props.disabled}
									tabIndex={-1}
									data-textfield-icon-right
									variant={'xSmall'}
									style={{
										backgroundColor: 'white',
									}}
								>
									<IconAlertTriangle color="coral" />
								</ButtonIcon>
							)
						}
					>
						{({ selectItem, width }) =>
							loading ? (
								<DropdownContentFrame maxHeight={200}>
									<Container display="flex" alignItems="center" justifyContent="center" height={50} width={width}>
										<IconRoundSpinner variant="large" />
									</Container>
								</DropdownContentFrame>
							) : (
								<DropdownContentFrame maxWidth={380}>
									<Labels
										type="strict"
										onSelect={({ label }) => selectItem(label)}
										labels={options.map((o) => ({
											id: '',
											label: o.label,
										}))}
										maxHeight={400}
										queryString={value ?? ''}
										additionalEntry={
											<Container display={'flex'} flexDirection={'column'} backgroundColor="neutral000">
												<Divider />
												<VariablesAndPlaceholders width={340} selectItem={selectItem} />
											</Container>
										}
									/>
								</DropdownContentFrame>
							)
						}
					</DropdownInput>
				</>
			) : (
				<TextField placeholder={'Value'} value={value} onChange={(e) => onChange(e.target.value)} {...props} />
			)}

			<ButtonIcon
				id={`${id}-delete`}
				flex={'0 0 auto'}
				muted
				disabled={props.disabled || !onDelete}
				onClick={onDelete}
				tooltip={'Delete'}
				color={'neutral600'}
				variant={props.variant}
			>
				<IconDelete />
			</ButtonIcon>
		</>
	);
};

const OrDivider: React.VFC = () => (
	<Divider
		width={'3.5rem'}
		sx={{
			placeSelf: 'stretch end',
			'&::before': {
				borderLeft: '1px solid',
				height: '1rem',
				marginRight: 'xxSmall',
				marginTop: '-1rem',
			},
		}}
	>
		or
	</Divider>
);
