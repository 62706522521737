/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import WaitForTenantPreparation from 'pages/tenantPreparation/waitForTenantPreparationLazyLoader';
import { SnackbarContainer, UserConfirmContainer, UserConfirmContainerV2 } from 'components';
import GameLazyLoader from 'pages/tenantPreparation/gameLazyLoader';
import { UserInputContainer } from 'components/Dialog/UserInput';
import GlobalStyles from 'components/GlobalStyles/GlobalStyles';
import LoginFailure from 'pages/login-failure/loginFailure';
import { IntercomProvider } from 'react-use-intercom';
import { Route, Router, Switch } from 'url/router';
import { ThemeProvider } from '@emotion/react';
import { theme } from 'styles.v2/theme';
import Login from 'pages/login/login';
import { history } from 'url/history';
import { ReactElement } from 'react';
import Init from 'Init';

export default function Foundation(): ReactElement {
	return (
		<ThemeProvider theme={theme}>
			<IntercomProvider
				appId={window.INJECTED_INTERCOM_APP_ID ?? ''}
				shouldInitialize={Boolean(window.INJECTED_INTERCOM_APP_ID)}
			>
				<GlobalStyles />
				<Router history={history}>
					<Switch>
						<Route path={'/login-failure'}>
							<LoginFailure />
						</Route>
						<Route path={'/login'}>
							<Login />
						</Route>
						<Route path={'/preparation'}>
							<WaitForTenantPreparation />
						</Route>
						<Route path={'/game'}>
							<GameLazyLoader />
						</Route>
						<Route>
							<Init />
						</Route>
					</Switch>
				</Router>
				<SnackbarContainer />
				<UserConfirmContainer />
				<UserConfirmContainerV2 />
				<UserInputContainer />
			</IntercomProvider>
		</ThemeProvider>
	);
}
