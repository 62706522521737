/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	ButtonIcon,
	Checkbox,
	Code,
	Container,
	Heading,
	Link,
	Message,
	ModalContentV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	Pagination,
	Snackbar,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	Text,
	userConfirm,
} from 'components';
import { IconDelete, IconInformation, IconTarget, IconWarningCircle } from 'components/icons';
import { usePromiseWithReExecution } from 'utils/hooks/usePromiseWithReExecution';
import ListHeaderSearch from 'components/List/presets/ListHeaderSearch';
import { createElement, ReactElement, useMemo, useState } from 'react';
import EmptyListContent from 'components/List/EmptyListContent';
import TableLoadingRow from 'components/Table/TableLoadingRow';
import { localeCompareIgnoreCase } from 'utils/string';
import invokePromise from 'utils/ignorePromise';
import { TargetTypeSummaryVO } from 'ui-api';
import { Services } from 'services/services';
import { json } from 'utils/mediaTypes';
import { getLabel } from 'i18n/label';
import { debounce } from 'lodash';

import { useEventEffect } from '../../../utils/hooks/useEventEffect';
import { useTrackExtensionsListViewed } from './ExtensionList';
import { usePromise } from '../../../utils/hooks/usePromise';
import Pill from '../../../components/Pill/Pill';
import { getIcon } from '../../../targets/util';
import HelpText from '../components/HelpText';
import { ampli } from '../../../ampli';

export default function TargetTypes(): ReactElement {
	const [targetTypesResult, refetch] = usePromiseWithReExecution(() => Services.targets.fetchTargetTypeSummaries(), []);
	const [showDetails, setShowDetails] = useState<TargetTypeSummaryVO | null>(null);
	const [selectedTargetTypes, setSelectedTargetTypes] = useState<string[]>([]);

	const debouncedFetch = useMemo(() => debounce(refetch, 100), [refetch]);
	useEventEffect(
		debouncedFetch,
		['targetType.created', 'targetType.deleted', 'targetType.updated'],
		() => debouncedFetch.cancel,
	);

	const [query, setQuery] = useState<string>('');
	const pageSize = 10;
	const [page, setPage] = useState(0);
	const sortedItems = targetTypesResult.value?.content ? sort(search(targetTypesResult.value.content, query)) : [];

	const items = sortedItems.slice(page * pageSize, (page + 1) * pageSize);
	const totalElements = sortedItems.length;
	const totalPages = Math.ceil(totalElements / pageSize);

	const unavailableItems = items.filter((a) => !a.reportedByAgents);
	const itemsWithMultipleVersions = items.filter((a) => a.reportedByAgentsInDifferentVersions);
	const itemsWithUnknownVersion = items.filter((a) => a.reportedByAgentsWithUnknownVersion);
	const unavailableItemsDeletable =
		unavailableItems.length > 0 && unavailableItems.find((a) => a._actions.includes('delete'));
	const itemsWithUnknownVersionDeletable =
		itemsWithUnknownVersion.length > 0 && itemsWithUnknownVersion.find((a) => a._actions.includes('delete'));
	const deleteEnabled = unavailableItemsDeletable || itemsWithUnknownVersionDeletable;

	useTrackExtensionsListViewed(
		targetTypesResult.loading ? undefined : 'target_types',
		page,
		totalElements,
		totalPages,
		unavailableItems.map((action) => action.id),
		itemsWithMultipleVersions.map((action) => action.id),
		itemsWithUnknownVersion.map((action) => action.id),
	);

	return (
		<>
			{showDetails && (
				<ModalOverlay open onClose={() => setShowDetails(null)}>
					{({ close }) => (
						<TargetTypeDetails
							summary={showDetails}
							close={close}
							onDeleteClick={() =>
								handleDeleteClick([showDetails], () => {
									setSelectedTargetTypes([]);
								})
							}
						/>
					)}
				</ModalOverlay>
			)}

			<Stack m="xLarge">
				<Stack direction={'horizontal'}>
					<HelpText sx={{ flex: '50%' }}>
						Targets are infrastructure and logical components that could be used for attacks and verifications. Targets
						are defined and discovered through extensions leveraging our{' '}
						<Link
							href="https://github.com/steadybit/discovery-kit/blob/main/docs/discovery-api.md#target-type-description"
							external
						>
							DiscoveryKit
						</Link>
						.
					</HelpText>
					{unavailableItems.length > 0 && (
						<Message variant={'warning'} title={'Missing Agents for Target Types'} flex={'50%'}>
							No agent will report target of these types. <br />
							If you removed the extensions, you can remove the target type from Steadybit by deleting them.
							<br />
							If this is not on purpose, please check the extension and agent logs for errors.
						</Message>
					)}
					{itemsWithMultipleVersions.length > 0 && (
						<Message variant={'warning'} title={'Multiple Versions for Target Types'} flex={'50%'}>
							We found target types with multiple versions. <br />
							This could lead to unexpected behavior.
							<br />
							If this is not on purpose, please check the extension and agent logs for errors.
						</Message>
					)}
					{itemsWithUnknownVersion.length > 0 && (
						<Message variant={'warning'} title={'Unversioned  Target Types'} flex={'50%'}>
							We found unversioned target types. These are potentially unstable. Consider updating your extension to a
							released version. You can delete unversioned target types, agents will then be triggered to retransmit
							their current target type definitions.
						</Message>
					)}
				</Stack>

				<Stack size={'xxSmall'}>
					<Container display="flex" alignItems="center" flexDirection="row-reverse" justifyContent="space-between">
						<ListHeaderSearch
							title="Search target type"
							value={query ?? ''}
							setValue={(v) => {
								setQuery(v);
								setPage(0);
							}}
						/>
						{deleteEnabled ? (
							<Stack direction={'horizontal'} justifyContent={'flex-end'} mb={'xxSmall'}>
								<Button
									disabled={selectedTargetTypes.length === 0}
									variant="chromelessSmall"
									color="neutral600"
									onClick={() =>
										handleDeleteClick(items.filter((t) => selectedTargetTypes.includes(t.id)) ?? [], () => {
											setSelectedTargetTypes([]);
										})
									}
								>
									<IconDelete mr="xSmall" ml="-xSmall" /> Delete Target Types
								</Button>
							</Stack>
						) : null}
					</Container>

					<Table width={'100%'}>
						<TableHead>
							<TableRow>
								<TableHeadCell colSpan={2}></TableHeadCell>
								<TableHeadCell>Name</TableHeadCell>
								<TableHeadCell>Id</TableHeadCell>
								<TableHeadCell>Details</TableHeadCell>
							</TableRow>
						</TableHead>
						{!targetTypesResult.value ? (
							<TableBody>
								<>
									<TableLoadingRow numColumns={7} />
									<TableLoadingRow numColumns={7} />
									<TableLoadingRow numColumns={7} />
								</>
							</TableBody>
						) : (
							<TableBody>
								{items.map((t) => {
									const props: TargetTypeRowProps = {
										summary: t,
										onShowDetailsClick: () => setShowDetails(t),
										selected: selectedTargetTypes.includes(t.id),
									};
									if (t._actions.includes('delete')) {
										props.onSelectClick = () =>
											setSelectedTargetTypes((prev) =>
												prev.includes(t.id) ? prev.filter((id) => id !== t.id) : [...prev, t.id],
											);
									}
									return <TargetTypeRow key={t.id} {...props} />;
								})}
							</TableBody>
						)}
					</Table>

					{targetTypesResult.value && items.length === 0 && (
						<EmptyListContent
							icon={<IconTarget variant="xxLarge" color="purple700" />}
							title={query ? 'No target types matching your filter found' : 'No target types found'}
						/>
					)}
				</Stack>

				<Pagination activePage={page} totalPages={totalPages} onClick={setPage} />
			</Stack>
		</>
	);
}

type TargetTypeRowProps = {
	summary: TargetTypeSummaryVO;
	onShowDetailsClick?: () => void;
	onSelectClick?: () => void;
	selected?: boolean;
};

function TargetTypeRow({ summary, selected, onSelectClick, onShowDetailsClick }: TargetTypeRowProps): ReactElement {
	return (
		<TableRow hoverable onClick={onSelectClick}>
			{onSelectClick ? (
				<TableDataCell width={'30px'}>
					<Checkbox checked={selected} onChange={onSelectClick} onClick={(e) => e.stopPropagation()} />
				</TableDataCell>
			) : null}
			<TableDataCell
				colSpan={onSelectClick ? 1 : 2}
				maxWidth={'170px'}
				minWidth={'0px'}
				width={
					!summary.reportedByAgents ||
					summary.reportedByAgentsWithUnknownVersion ||
					summary.reportedByAgentsInDifferentVersions
						? '170px'
						: '0px'
				}
			>
				<Stack size={'xSmall'} my={'xxSmall'}>
					{!summary.reportedByAgents && (
						<Pill
							backgroundColor={'feedbackWarningLightPill'}
							color={'feedbackWarningDark'}
							sx={{
								height: 32,
								borderRadius: 20,
							}}
						>
							<IconWarningCircle variant={'small'} mr={'xxSmall'} />
							<Text variant="smallStrong" ml="xxSmall" mr="xxSmall">
								Missing Agent
							</Text>
						</Pill>
					)}
					{summary.reportedByAgentsWithUnknownVersion && (
						<Pill
							backgroundColor={'feedbackWarningLightPill'}
							color={'feedbackWarningDark'}
							sx={{
								height: 32,
								borderRadius: 20,
							}}
						>
							<IconWarningCircle variant={'small'} mr={'xxSmall'} />
							<Text variant="smallStrong" ml="xxSmall" mr="xxSmall">
								Unversioned
							</Text>
						</Pill>
					)}
					{summary.reportedByAgentsInDifferentVersions && (
						<Pill
							backgroundColor={'feedbackWarningLightPill'}
							color={'feedbackWarningDark'}
							sx={{
								ml: 'xxSmall',
								height: 32,
								borderRadius: 20,
							}}
						>
							<IconWarningCircle variant={'small'} mr={'xxSmall'} />
							<Text variant="smallStrong" ml="xxSmall" mr="xxSmall">
								Multiple Versions
							</Text>
						</Pill>
					)}
				</Stack>
			</TableDataCell>
			<TableDataCell>
				{createElement(getIcon(summary), { mr: 'small' })}
				{getLabel(summary.label, 1)}
			</TableDataCell>
			<TableDataCell>
				{summary.id}
				{summary.version != 'unknown' && ` @ ${summary.version}`}
			</TableDataCell>
			<TableDataCell>
				<ButtonIcon onClick={onShowDetailsClick} variant={'small'} tooltip={'Show Details'}>
					<IconInformation size={'small'} />
				</ButtonIcon>
			</TableDataCell>
		</TableRow>
	);
}

const handleDeleteClick = (summaries: TargetTypeSummaryVO[], onDeleted: () => void): void => {
	const count = summaries.length;
	invokePromise(async () => {
		if (
			(await userConfirm({
				width: '550px',
				title: 'Delete Target Types',
				message: (
					<>
						<Stack>
							<Text>
								Do you really want to delete the following target type{count > 1 ? 's' : ''}?
								<ul>
									{summaries.map((s) => (
										<li key={s.id}>
											<strong>{getLabel(s.label, 1)}</strong>
											<br />
											{s.id}
										</li>
									))}
								</ul>
							</Text>
						</Stack>
					</>
				),
				actions: [
					{ value: 'cancel', label: 'Cancel' },
					{
						value: 'confirm',
						label: `Delete ${count == 1 ? 'Target Type' : `${count} Target Types`}`,
						variant: 'primary',
					},
				],
			})) === 'confirm'
		) {
			try {
				await Promise.all(summaries.map((s) => Services.targets.deleteTargetTypeDescription(s.id)));
				ampli.extensionTypeDeleted({
					url: window.location.href,
					extension_type: 'target_types',
					extension_type_ids: summaries.map((s) => s.id),
				});
				Snackbar.dark('Target Type deleted.', { toastId: 'target-type-deleted' });
				onDeleted();
			} catch (err) {
				Snackbar.error('Target Type not deleted: ' + err.toString(), { toastId: 'target-type-deleted' });
			}
		}
	});
};

interface TargetTypeDetailsProps {
	summary: TargetTypeSummaryVO;
	onDeleteClick: () => void;
}

function TargetTypeDetails({
	summary,
	close,
	onDeleteClick,
}: TargetTypeDetailsProps & { close: () => void }): ReactElement {
	const infos = usePromise(() => Services.targets.fetchTargetDefinitionOnAgentInfo(summary.id), [summary.id]);
	const definition = usePromise(() => Services.targets.getTargetDefinition(summary.id), [summary.id]);
	return (
		<ModalV2>
			<ModalHeaderV2 title={`"${getLabel(summary.label, 1)}" Version Info`} onClose={close} />
			<ModalContentV2>
				<Stack>
					{summary.reportedByAgents ? (
						<>
							<HelpText>
								These are the agents the target type is currently registered on. The version may differ between the
								agents.
							</HelpText>
							<Table width="100%">
								<TableHead>
									<TableRow>
										<TableHeadCell>Agent Hostname</TableHeadCell>
										<TableHeadCell>Target Type Version</TableHeadCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{infos.loading && (
										<>
											<TableLoadingRow numColumns={2} />
										</>
									)}
									{infos.value?.content
										?.slice()
										.sort((a, b) => localeCompareIgnoreCase(a.agent, b.agent))
										.map((a) => (
											<TableRow hoverable key={a.agent}>
												<TableDataCell>{a.agent}</TableDataCell>
												<TableDataCell>{a.versions.join(', ')}</TableDataCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
							{summary.reportedByAgentsWithUnknownVersion && (
								<Message variant={'warning'} title={'Unversioned Target Type'} flex={'50%'}>
									<p>
										The target type is potentially unstable cause it is reported with an unknown version. Consider
										updating your extension to a released version. You can delete the unversioned target type, agents
										will then be triggered to retransmit their current target type definitions.
									</p>
									{onDeleteClick && (
										<Button
											variant={'primary'}
											onClick={() => {
												close();
												onDeleteClick();
											}}
											disabled={!summary._actions.includes('delete')}
										>
											<IconDelete mr={'xSmall'} /> Delete Target Type
										</Button>
									)}
								</Message>
							)}
						</>
					) : (
						<Message variant={'danger'} title={'Missing Agents for Target Type'} flex={'50%'}>
							<p>
								The target type extension is not reported by any of the registered agents. <br />
								No agent will report target of this type. <br />
								If you removed the extension you can remove the target type from Steadybit by deleting it. <br />
								If this is not on purpose, please check the extension and agent logs for errors.
							</p>
							<Button
								variant={'primary'}
								onClick={() => {
									close();
									onDeleteClick();
								}}
								disabled={!summary._actions.includes('delete')}
							>
								<IconDelete mr={'xSmall'} /> Delete Target Type
							</Button>
						</Message>
					)}
					{definition.value && (
						<>
							<Heading>Details</Heading>
							<Code
								lang="json"
								withCopyToClipboard
								withDownload={{
									fileName: `${definition.value?.id}.json`,
									mediaType: json,
								}}
							>
								{JSON.stringify(definition.value, undefined, 2)}
							</Code>
						</>
					)}
				</Stack>
			</ModalContentV2>
		</ModalV2>
	);
}

// exported for testing
export function sort(items: TargetTypeSummaryVO[]): TargetTypeSummaryVO[] {
	// first, sort by reportedByAgents
	// second, sort by reportedByAgentsWithUnknownVersion
	// third, sort by name

	return items.slice().sort((a, b) => {
		if (a.reportedByAgents && !b.reportedByAgents) {
			return 1;
		} else if (!a.reportedByAgents && b.reportedByAgents) {
			return -1;
		} else if (a.reportedByAgentsWithUnknownVersion && !b.reportedByAgentsWithUnknownVersion) {
			return -1;
		} else if (!a.reportedByAgentsWithUnknownVersion && b.reportedByAgentsWithUnknownVersion) {
			return 1;
		} else {
			return localeCompareIgnoreCase(a.label.one, b.label.one);
		}
	});
}

function search(items: TargetTypeSummaryVO[], query: string): TargetTypeSummaryVO[] {
	query = query.trim().toLowerCase();
	return items.filter((item) => {
		return item.id.toLowerCase().includes(query) || item.label.one.toLowerCase().includes(query);
	});
}
