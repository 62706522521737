/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { getHash } from './hash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SignatureFunction<T> = (...args: any[]) => Promise<T>;
type CachedFunction<T> = SignatureFunction<T> & { invalidateCaches: (...args: string[]) => void };

/*
	The API of this is pretty simple:

	Imagine you have a function you want to cache:

	async function expensiveFetch(p1: string, p2: string): Promise<Result> {
		return await axios.get<Result>("/api/expensive", { params: { p1, p2 } }).data;
	}

	To cache it, just wrap it inside cached:

	const cachedFetch = cached(expensiveFetch);
	cachedFetch("foo", "bar"); // will call expensiveFetch and cache the result.

	You can also invalidate the cache:
	cachedFetch.invalidateCaches();
	cachedFetch.invalidateCaches("foo", "bar"); // invalidate only the cache for these parameters

	You can also set a timeout for the cache:
	const cachedFetch = cached(expensiveFetch, 1000); // cache for 1 second, then invalidate
*/
export default function cached<T>(func: SignatureFunction<T>, timeout?: number): CachedFunction<T> {
	const cache = new Map<string, Promise<unknown>>();
	const timeoutHandles: Map<string, NodeJS.Timeout> = new Map();

	const cachedFunction: CachedFunction<T> = (...args: string[]) => {
		const key = createCacheKey(args);
		if (cache.has(key)) {
			return cache.get(key) as Promise<T>;
		}

		const result: Promise<T> = func(...args);
		cache.set(key, result);

		if (timeout && !timeoutHandles.has(key)) {
			timeoutHandles.set(
				key,
				setTimeout(() => {
					cache.delete(key);
					clearTimeoutHandle(key, timeoutHandles);
				}, timeout),
			);
		}

		return result;
	};

	cachedFunction.invalidateCaches = (...args: string[]) => {
		if (args && args.length > 0) {
			const key = createCacheKey(args);
			clearTimeoutHandle(key, timeoutHandles);
			cache.delete(key);
		} else {
			clearTimeoutHandles(timeoutHandles);
			cache.clear();
		}
	};

	return cachedFunction;
}

function clearTimeoutHandles(handles: Map<string, NodeJS.Timeout>): void {
	for (const id of handles.keys()) {
		clearTimeoutHandle(id, handles);
	}
}

function clearTimeoutHandle(id: string, handles: Map<string, NodeJS.Timeout>): void {
	const handle = handles.get(id);
	if (handle) {
		clearTimeout(handle);
		handles.delete(id);
	}
}

function createCacheKey(args: string[]): string {
	return getHash(args);
}
