/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { useTenant } from 'tenancy/useTenant';
import { EnvironmentSummaryVO } from 'ui-api';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';
import { useMemo } from 'react';

import { usePromise } from './usePromise';

export interface UseEnvironmentsResult {
	environments: EnvironmentSummaryVO[];
	environmentsIsLoading: boolean;
	globalEnvironment: EnvironmentSummaryVO | undefined;
	isGlobalSelected: boolean;
	onlyGlobalEnvironmentAvailableForCurrentTeam: boolean;
	selectedEnvironment: EnvironmentSummaryVO | undefined;
	defaultSelectedEnvironment: EnvironmentSummaryVO | undefined | null;
	environmentsOfCurrentTeam: EnvironmentSummaryVO[];
	isAdmin: boolean;
}

export function useEnvironments(selectedEnvironmentId?: string): UseEnvironmentsResult {
	const currentTenant = useTenant();
	const currentTeam = useTeam();

	const isAdmin = currentTenant.user.role === 'ADMIN';

	const environmentsAsyncState = usePromise(async () => (await Services.environments.fetchEnvironments()).content, []);

	const environments = environmentsAsyncState.value ?? [];

	const selectedEnvironment = useMemo(
		() => environments.find((environment) => environment.id === selectedEnvironmentId),
		[environments, selectedEnvironmentId],
	);
	const globalEnvironment = useMemo(() => environments.find((environment) => environment.global), [environments]);
	const isGlobalSelected = useMemo(
		(): boolean => globalEnvironment?.id === selectedEnvironmentId,
		[globalEnvironment, selectedEnvironmentId],
	);

	const environmentsOfCurrentTeam = useMemo(
		() => environments.filter((environment) => !!environment.teams.find((ts) => ts.id === currentTeam.id)),
		[environments, currentTeam.id],
	);
	const onlyGlobalEnvironmentAvailableForCurrentTeam = useMemo(
		(): boolean => environmentsOfCurrentTeam.length === 1 && environmentsOfCurrentTeam[0].global,
		[environmentsOfCurrentTeam],
	);

	const defaultSelectedEnvironment = !environmentsAsyncState.value ? undefined : environmentsOfCurrentTeam[0] || null;

	return {
		environments,
		environmentsIsLoading: environmentsAsyncState.loading,
		globalEnvironment,
		isGlobalSelected,
		onlyGlobalEnvironmentAvailableForCurrentTeam,
		selectedEnvironment,
		environmentsOfCurrentTeam,
		isAdmin,
		defaultSelectedEnvironment,
	};
}
