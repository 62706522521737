/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	Container,
	ModalContentV2,
	ModalFooterV2,
	ModalHeaderV2,
	ModalV2,
	Snackbar,
	Spinner,
	Stack,
	Text,
} from 'components';
import { ActionPermissions } from 'pages/settings/teams/components/actionPermissions';
import { TeamLogoColors } from 'components/icons/TeamLogoIcon';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { Form, Formik, FormikHelpers } from 'formik';
import { matchPath, Prompt } from 'url/router';
import { ValidationError } from 'utils/error';
import { Services } from 'services/services';
import { useHistory } from 'url/hooks';
import { ensure } from 'utils/ensure';
import { TeamVO } from 'ui-api';
import React from 'react';

import { EnvironmentPermissions } from './components/environmentPermissions';
import TeamInformation from './components/teamInformation';
import { TeamFormValues } from './components/teamTypes';
import { TeamMembers } from './components/teamMembers';

const EditTeam: React.VFC<{ id: string; close: () => void }> = ({ id, close }) => {
	const history = useHistory();
	const [submitError, setSubmitError] = React.useState<Error | null>();
	const [team] = useAsyncState(() => Services.teams.fetchTeam(id), [id]);

	const handleSubmit = async ({ ...values }: TeamFormValues, form: FormikHelpers<TeamFormValues>): Promise<void> => {
		try {
			setSubmitError(null);
			if (team.value) {
				if (canEditTeam(team.value)) {
					await Services.teams.updateTeam(id, { version: ensure(team.value).version, ...values });
				}
				if (team.value._actions.includes('manage-members')) {
					await Services.teams.addOrUpdateTeamMembers(id, { members: values.members });
					const deletedMembers = initial.members.filter(
						(existingMember) => !values.members.find((newMember) => newMember.username === existingMember.username),
					);
					if (deletedMembers.length > 0) {
						await Services.teams.removeTeamMembers(id, { members: deletedMembers });
					}
				}
			}
			Snackbar.dark('Team saved.', { toastId: 'team-saved' });
			history.replace('/settings/teams');
		} catch (error) {
			if (error instanceof ValidationError) {
				error.violations.forEach((violation) => form.setFieldError(violation.field, violation.message));
			} else {
				setSubmitError(error);
			}
		}
	};

	const initial: TeamFormValues = team.value ?? {
		key: '',
		name: '',
		logoId: '1',
		logoColor: TeamLogoColors[0],
		description: '',
		allowedActions: [],
		allowedEnvironments: [],
		members: [],
	};

	const teamCanBeEdit = canEditTeam(team.value);
	const isAdminTeam = team.value?.key === 'ADM';

	return (
		<Formik initialValues={initial} enableReinitialize onSubmit={handleSubmit}>
			{({ isSubmitting, dirty }) => {
				return (
					<Form noValidate>
						<ModalV2 withFooter width="90vw">
							<ModalHeaderV2 title={`Edit Team ${team.value?.name || ''}`} onClose={close} withShadow />
							<ModalContentV2>
								<Prompt
									when={dirty && !isSubmitting}
									message={(location) => {
										return matchPath(location.pathname, { path: history.location.pathname })
											? true
											: 'You have unsaved changes, do you really want to quit?';
									}}
								/>
								{team.loading && (
									<Stack>
										<Spinner variant="large" color={'neutral500'} mr={'auto'} />
									</Stack>
								)}
								{!team.loading && team.value && (
									<Stack size={'xLarge'} py="large">
										<TeamInformation keyDisabled disabled={!teamCanBeEdit} isAdminTeam={isAdminTeam} />
										<Container>
											<Text variant={'mediumStrong'} mb={'xSmall'}>
												Members
											</Text>
											<TeamMembers teamId={team.value.id} disabled={!team.value._actions.includes('manage-members')} />
										</Container>

										<EnvironmentPermissions
											disabled={!teamCanBeEdit}
											disabledExplanation={
												isAdminTeam ? (
													<>
														You can&apos;t change environments for the Administrator team, as it always has access to
														all environments.
													</>
												) : undefined
											}
										/>
										<ActionPermissions
											readonly={!teamCanBeEdit}
											readonlyExplanation={
												isAdminTeam ? (
													<>
														You can&apos;t change allowed actions for the Administrator team, as it always has access to
														all actions.
													</>
												) : undefined
											}
										/>
									</Stack>
								)}
							</ModalContentV2>
							<ModalFooterV2 withShadow>
								<>
									{team.error && <Container width="100%">{team.error.toString()}</Container>}
									{submitError && (
										<Container width="100%">
											<Text variant="mediumStrong" color="coral">
												{submitError.toString()}
											</Text>
										</Container>
									)}
									<Button id={'save'} type={'submit'} disabled={!dirty} loading={isSubmitting}>
										Save
									</Button>
								</>
							</ModalFooterV2>
						</ModalV2>
					</Form>
				);
			}}
		</Formik>
	);
};

export default EditTeam;

function canEditTeam(team: TeamVO | undefined): boolean {
	return !!team && team._actions.includes('edit');
}
