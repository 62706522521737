/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { FieldConfig, FieldHookConfig, useField } from 'formik';
import { Text, ContainerProps, Container } from 'components';
import React from 'react';

import { FileUpload, FileUploadProps } from '../FileUpload/FileUpload';
import { hasError } from './utils';

type FormikFileUploadProps = FieldConfig & ContainerProps & Omit<FileUploadProps, keyof FieldConfig>;

export const FormikFileUpload: React.VFC<FormikFileUploadProps> = ({ width, ...props }) => {
	const [field, meta, { setValue, setTouched }] = useField({ ...props, type: 'file' } as FieldHookConfig<
		string | undefined
	>);

	return (
		<Container display={'flex'} flexDirection={'column'} width={width}>
			<FileUpload
				{...field}
				onChange={(value: string | undefined) => {
					setValue(value);
					setTouched(true, false);
				}}
				{...props}
			/>
			{hasError(meta) ? (
				<Text mt={'xxSmall'} variant={'smallStrong'} color={'coral'} data-formik-error>
					{meta.error}
				</Text>
			) : null}
		</Container>
	);
};
FormikFileUpload.displayName = 'FormikFileUpload';
