/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { IconWarningOpen } from 'components/icons';
import { Container, Text } from 'components';
import { ReactElement } from 'react';

interface ErrorProps {
	error: string;
}

export default function Error({ error }: ErrorProps): ReactElement {
	return (
		<Container display="flex" alignItems="center">
			<IconWarningOpen color="coral" mr="xSmall" />
			<Text variant="medium" color="coral">
				{error}
			</Text>
		</Container>
	);
}
