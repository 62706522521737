/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { DidNotSavePrompt } from 'pages/experiments/wizard/components/DidNotSavePrompt';
import { saveTemplate } from 'templates/components/formUtils';
import { useFormikContext } from 'formik';
import { matchPath } from 'url/router';
import { ReactElement } from 'react';

import { TemplateFormValues } from './types';

export default function UnsavedChangesPromt(): ReactElement {
	const { values, touched } = useFormikContext<TemplateFormValues>();
	const { id } = values;

	const dirty = Object.keys(touched).length > 0;

	return (
		<DidNotSavePrompt
			when={dirty}
			entityTitle="template"
			block={({ pathname }) => {
				if (pathname.startsWith('/settings/templates/design')) {
					return false;
				}

				return (
					!matchPath(pathname, { path: `/settings/templates/design/${id ?? '<new>'}` }) &&
					!matchPath(pathname, { path: '/settings/templates' })
				);
			}}
			onSaveCallback={async () => {
				await saveTemplate(values);
			}}
		/>
	);
}
