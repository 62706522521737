/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { useAsyncFn } from 'react-use';
import React from 'react';

export type AsyncState<T> = {
	loading: boolean;
	error?: Error | undefined;
	value: T;
};

type PromiseFn<T> = () => Promise<T | undefined>;

export function useAsyncState<T>(fn: PromiseFn<T>): [AsyncState<T | undefined>, () => void];
export function useAsyncState<T>(
	fn: PromiseFn<T | undefined>,
	deps: React.DependencyList,
): [AsyncState<T | undefined>, () => void];
/**
 * @deprecated useAsyncState with a default value / initial value has undefined runtime behavior.
 * The type definition given here is not correct. Relying on this will result in a runtime error
 * under certain conditions.
 *
 * Use usePromise or usePromiseWithReExecution instead.
 */
export function useAsyncState<T>(fn: PromiseFn<T>, deps: React.DependencyList, initial: T): [AsyncState<T>, () => void];
export function useAsyncState<T>(
	fn: PromiseFn<T>,
	deps: React.DependencyList = [],
	initial?: T,
): [AsyncState<T>, () => void] {
	const [state, callback] = useAsyncFn(fn, deps, { loading: true, value: initial });

	React.useEffect(() => {
		callback();
	}, [callback]);

	return [state as AsyncState<T>, callback];
}
