/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { CurrentUserVO } from 'ui-api';

const steadybitEmailPattern = /^[^@]+@(steadybit|chaosmesh)\.(com|io)$/i;
const cypressEmailPattern = /^steadybitsignupcypress/i;

export function isSteadybitOrPartnerUser(user: CurrentUserVO): boolean {
	return user.email?.match(steadybitEmailPattern) != null || user.name?.match(cypressEmailPattern) != null;
}

export function ignoreEmail(user: CurrentUserVO): boolean {
	return !!window.INJECTED_AMPLITUDE_FILTER_OWN_USERS && isSteadybitOrPartnerUser(user);
}
