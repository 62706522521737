/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { UnstyledLink, UnstyledLinkProps } from 'components';
import React from 'react';

export interface ContextualMenuLinkProps extends UnstyledLinkProps {
	disabled?: boolean;
}

export const ContextualMenuLink: React.FC<ContextualMenuLinkProps> = ({ children, disabled, ...props }) => {
	return (
		<UnstyledLink
			{...props}
			color={disabled ? 'neutral400' : 'neutral800'}
			px={12}
			py={10}
			sx={{
				variant: 'text.medium',
				display: 'inline-flex',
				alignItems: 'center',
				minWidth: '158px',
				pointerEvents: disabled ? 'none' : 'initial',
				textDecoration: 'none',

				':hover': {
					textDecoration: 'none',
					bg: 'purple100',
					color: 'slate',
					cursor: disabled ? 'default' : 'pointer',
				},

				svg: {
					width: '1em',
					height: '1em',
					mr: 'xxSmall',
				},
			}}
		>
			{children}
		</UnstyledLink>
	);
};
