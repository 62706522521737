/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components/Container';
import { forwardRef, ReactElement } from 'react';

type LiProps = ContainerProps;

const Li = forwardRef<HTMLElement, LiProps>(({ children, sx = {}, ...props }, ref): ReactElement => {
	return (
		<Container as="li" sx={sx} {...props} ref={ref}>
			{children}
		</Container>
	);
});
Li.displayName = 'Li';

export default Li;
