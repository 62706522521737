/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { IconArrowDropDown, IconArrowDropUp } from 'components/icons';
import { renderChildren } from 'components/Select/Dropdown/types';
import DropDown from 'components/Select/Dropdown/Dropdown';
import { ReactElement, RefObject } from 'react';
import { Pill, StyleProp } from 'components';

interface DropDownPillProps<T> {
	content: ReactElement;
	children: renderChildren<T>;
	backgroundColor?: string;
	color?: string;
	sx?: StyleProp;
	disabled?: boolean;
	onOpen?: () => void;
	onClose?: () => void;
	id?: string;
}

export default function DropDownPill<T>({
	id,
	sx,
	backgroundColor,
	color,
	content,
	children,
	onOpen,
	onClose,
	disabled,
}: DropDownPillProps<T>): ReactElement {
	return (
		<DropDown
			onOpen={onOpen}
			onClose={onClose}
			renderComponent={({ setShowMenu, showMenu, ref }) => {
				return (
					<Pill
						id={id}
						ref={ref as RefObject<HTMLDivElement>}
						backgroundColor={backgroundColor ?? 'neutral200'}
						backgroundColorOnHover={backgroundColor ?? 'neutral300'}
						color={color ?? 'neutral600'}
						sx={{
							borderRadius: 20,
							...sx,
						}}
						onClick={() => {
							if (!disabled) {
								setShowMenu(!showMenu);
							}
						}}
					>
						{content}
						{showMenu ? (
							<IconArrowDropUp ml="xSmall" width={16} minWidth={16} />
						) : (
							<IconArrowDropDown ml="xSmall" width={16} minWidth={16} />
						)}
					</Pill>
				);
			}}
		>
			{children}
		</DropDown>
	);
}
