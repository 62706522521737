/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import {
	CompositeTargetPredicateVO,
	QueryLanguagePredicateVO,
	TargetAttributeKeyValuePredicateVO,
	TargetPredicateVO,
} from 'ui-api';
import { toTargetPredicate } from 'queryLanguage/parser/parser';

export function isPredicateEditableWithTheQueryBuilder(predicate?: TargetPredicateVO): boolean {
	if (predicate == null) {
		return true;
	}
	if (isTargetAttributeKeyValuePredicateVO(predicate)) {
		return (
			predicate.operator === 'EQUALS' ||
			predicate.operator === 'CONTAINS' ||
			predicate.operator === 'EQUALS_IGNORE_CASE' ||
			predicate.operator === 'CONTAINS_IGNORE_CASE'
		);
	}
	if (isQueryLanguagePredicateVO(predicate)) {
		return isPredicateEditableWithTheQueryBuilder(toTargetPredicate(predicate.query));
	}
	const compositePredicate = predicate as CompositeTargetPredicateVO;
	if (compositePredicate.operator !== 'AND' || compositePredicate.predicates == null) {
		return false;
	}

	return (
		compositePredicate.predicates.find((predicate) => !isTargetAttributeKeyValuePredicateVO(predicate)) == null &&
		compositePredicate.predicates.find((predicate) => {
			if (isTargetAttributeKeyValuePredicateVO(predicate)) {
				const tp = predicate as TargetAttributeKeyValuePredicateVO;
				return (
					tp.operator !== 'EQUALS' &&
					tp.operator !== 'CONTAINS' &&
					tp.operator !== 'EQUALS_IGNORE_CASE' &&
					tp.operator !== 'CONTAINS_IGNORE_CASE'
				);
			}
			return false;
		}) == null
	);
}

export function isQueryLanguagePredicateVO(predicate: TargetPredicateVO): predicate is QueryLanguagePredicateVO {
	return (predicate as QueryLanguagePredicateVO).query !== undefined;
}

export function isTargetAttributeKeyValuePredicateVO(
	predicate: TargetPredicateVO,
): predicate is TargetAttributeKeyValuePredicateVO {
	const kvPredicate = predicate as TargetAttributeKeyValuePredicateVO;
	return (
		typeof kvPredicate.key === 'string' &&
		typeof kvPredicate.operator === 'string' &&
		kvPredicate.values instanceof Array
	);
}
