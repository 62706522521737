/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { LandscapeTarget } from 'targets/Explore/types';

export function getSteadybitLabelOrDefault(target: LandscapeTarget, def: string): string {
	const steadybitLabel = target.attributes.find((a) => a.key === 'steadybit.label')?.value;
	return steadybitLabel ?? def;
}
