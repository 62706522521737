/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ReactElement, useEffect, useState } from 'react';
import { Container, Text, Tooltip } from 'components';
import { useFormikContext } from 'formik';
import { filter } from 'rxjs';

import {
	LayoutedGroup,
	isDragStartEvent,
	isDragStopEvent,
	isGroupsChangedEvent,
	isGroupsPositionChangedEvent,
} from './types';
import { LandscapeConfig, LandscapeGroup } from '../types';
import { event$ } from '../ServiceLocator';
import { hasAdvice } from '../utils';

export default function GroupLabels(): ReactElement | null {
	const [render, setRender] = useState(true);
	const [screenSpaceGroups, setScreenSpaceGroups] = useState<LayoutedGroup[]>([]);
	const [renderedIds, setRenderedIds] = useState<Set<string> | null>(null);

	useEffect(() => {
		const subscription = event$().subscribe((event) => {
			if (isDragStartEvent(event)) {
				setRender(false);
			} else if (isDragStopEvent(event)) {
				setRender(true);
			} else if (isGroupsChangedEvent(event)) {
				setScreenSpaceGroups(event.groups);
			}
		});
		return () => {
			subscription.unsubscribe();
		};
	}, [event$]);

	useEffect(() => {
		const subscription = event$()
			.pipe(filter(isGroupsPositionChangedEvent))
			.subscribe((event) => {
				const rendered = new Set<string>();
				for (let i = 0; i < event.groups.length; i++) {
					const { x, y, r, group } = event.groups[i];
					const width = r * 2;

					if (width >= 80) {
						rendered.add(group.group.id);
					}

					const domElement = document.getElementById(group.group.id);
					if (domElement) {
						domElement.style.minWidth = `${width}px`;
						domElement.style.maxWidth = `${width}px`;

						if (width < 80) {
							domElement.style.display = 'none';
						} else {
							domElement.style.display = 'flex';
							domElement.style.transform = `translate3D(${x - width / 2}px, ${y - r - 12}px, 0)`;
						}
					}
				}

				if (!renderedIds || Array.from(rendered).join(',') !== Array.from(renderedIds).join(',')) {
					setRenderedIds(rendered);
				}
			});
		return () => {
			subscription.unsubscribe();
		};
	}, [renderedIds, event$]);

	return (
		<>
			{screenSpaceGroups
				.filter((g) => !renderedIds || renderedIds.has(g.group.id))
				.map(({ group }) => {
					const identifier = group.id;
					return (
						<Container
							id={identifier}
							key={identifier}
							sx={{
								position: 'fixed',

								display: 'none',
								alignItems: 'center',
								justifyContent: 'center',

								overflow: 'hidden',
								height: 25,
								pointerEvents: 'none',
							}}
						>
							{render && <GroupLabel group={group} withShadow withDarkenOnMissingAdvice />}
						</Container>
					);
				})}
		</>
	);
}

interface GroupLabelProps {
	withDarkenOnMissingAdvice?: boolean;
	group: LandscapeGroup;
	withShadow?: boolean;
}

export function GroupLabel({ group, withDarkenOnMissingAdvice, withShadow }: GroupLabelProps): ReactElement {
	const { values } = useFormikContext<LandscapeConfig>();
	const label = `${group.label} (${group.groups ? group.groups.length : group.totalUniqueTargets})`;

	return (
		<Tooltip content={label}>
			<Container
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					gap: '6px',

					bg:
						values.showAdvice && !hasAdvice(group) && withDarkenOnMissingAdvice
							? 'neutral600'
							: `rgb(${group.color[0]}, ${group.color[1]}, ${group.color[2]})`,
					borderRadius: 14,
					px: 10,
					py: 1,
					cursor: 'default',
					fontSize: 14,
					pointerEvents: 'all',
					boxShadow: withShadow ? '0px 2px 4px #151f2d80' : undefined,
				}}
			>
				<Text sx={{ ...textEllipsis }}>{label}</Text>
			</Container>
		</Tooltip>
	);
}
