/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ButtonActivity } from 'components/ButtonActivity';
import useObservable from 'react-use/lib/useObservable';
import { ReactElement, useMemo } from 'react';
import { Services } from 'services/services';
import { useNowObservable } from 'utils/now';
import { Stack, Text } from 'components';

interface ActivityWidgetProps {
	expanded: boolean;
	onClick: () => void;
}

export default function ActivityWidget({ expanded, onClick }: ActivityWidgetProps): ReactElement {
	const now = useNowObservable();
	const runningExperiments = useObservable(Services.experiments.running$, []);
	const killswitchActive = useObservable(Services.killswitch.killswitchActive$);

	const startedExperimentsSecondsAgo = useMemo(() => {
		return (
			runningExperiments.filter(
				(runningExperiment) => runningExperiment.started && now.getTime() - runningExperiment.started.getTime() < 5000,
			).length > 0
		);
	}, [runningExperiments, now]);

	return (
		<>
			<Stack
				direction="horizontal"
				size="xSmall"
				sx={{
					alignItems: 'center',
					padding: '4px 4px',

					borderRadius: '4px',
					overflow: 'hidden',

					cursor: 'pointer',
					'&:hover': {
						backgroundColor: 'purple600',
					},
				}}
				onClick={onClick}
			>
				<div style={{ position: 'relative' }}>
					<ButtonActivity
						ripple={startedExperimentsSecondsAgo}
						blocked={killswitchActive}
						count={runningExperiments.length}
					/>
				</div>
				<Text
					as="span"
					variant="smallStrong"
					color={expanded ? 'neutral000' : 'transparent'}
					sx={{
						transition: 'color 0.2s',
						whiteSpace: 'nowrap',
					}}
				>
					Current activities
				</Text>
			</Stack>
		</>
	);
}
