/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { useAsyncState } from 'utils/hooks/useAsyncState';
import { find } from 'components/Select/utils';
import { Services } from 'services/services';
import React, { useMemo } from 'react';
import { Select } from 'components';

interface TeamSelectProps {
	value?: string | null;
	onChange?: (v: string | null) => void;
	name?: string;
	includeAny?: boolean;
}

const OPTION_ANY = { label: 'Any', value: null };

export const TeamSelect: React.VFC<TeamSelectProps> = ({ onChange, value, includeAny = true, ...props }) => {
	const [options] = useAsyncState(async () => {
		const users = await Services.teams.fetchTeamsFirst100();
		const options = users.content.map((t) => ({ label: `${t.name} (${t.key})`, value: t.id }));
		return includeAny ? [OPTION_ANY, ...options] : options;
	});
	const selected = useMemo(() => find(options.value ?? [], (o) => o.value === value), [options, value]);

	return (
		<Select
			label={'Team'}
			inlineLabel
			value={selected}
			options={options.value ?? []}
			loading={options.loading}
			onChange={(option) => onChange?.(option && 'value' in option ? option.value : null)}
			{...props}
		/>
	);
};
