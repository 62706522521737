/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ProgressButton, Text } from 'components';
import { IconCancel } from 'components/icons';
import React from 'react';

export const KillSwitchUnblock: React.VFC<{ handleDisengageClick: () => Promise<void> }> = ({
	handleDisengageClick,
}) => {
	return (
		<Container
			display={'flex'}
			py={'xSmall'}
			flexDirection={'column'}
			justifyContent={'center'}
			alignItems={'center'}
			color={'neutral600'}
			mx={'xLarge'}
		>
			<IconCancel variant={'xxLarge'} />
			<Text variant={'mediumStrong'} textAlign={'center'} mt={'small'}>
				Running Experiments is temporarily deactivated.
			</Text>
			<Text variant={'medium'} textAlign={'center'} mt={'small'} mb={'large'}>
				All running experiments have been stopped and running experiments is temporarily disallowed for all users.
			</Text>
			<ProgressButton variant="secondary" onClick={handleDisengageClick}>
				Allow Experiment Runs
			</ProgressButton>
		</Container>
	);
};
