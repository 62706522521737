/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from 'url/Link';
import React from 'react';

import { Link, LinkProps } from './Link';

export type RouterLinkProps = LinkProps & ReactRouterLinkProps<never>;

export const RouterLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
	({ children, variant = 'primary', ...props }, ref) => (
		<Link ref={ref} as={ReactRouterLink} variant={variant} {...props}>
			{children}
		</Link>
	),
);
RouterLink.displayName = 'RouterLink';
