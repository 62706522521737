/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ReactElement, ReactNode } from 'react';
import { Stack, Text } from 'components';

interface KeyChildrenProps {
	k: string;
	children: ReactNode;
}

export default function KeyChildren({ k, children }: KeyChildrenProps): ReactElement | ReactNode {
	if (!k) {
		return children;
	}

	return (
		<Stack size="xxSmall">
			<Text variant="small" color="neutral600">
				{k}:
			</Text>
			{children}
		</Stack>
	);
}
