/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { FieldHookConfig, useField } from 'formik';
import React from 'react';

import { FormIdSelect, FormIdSelectProps } from '../Select';

type FormikIdSelectOwnProps = {
	name: string;
};

export type FormikIdSelectProps = FormikIdSelectOwnProps &
	Omit<FormIdSelectProps, 'error' | 'touched' | keyof FormikIdSelectOwnProps>;

export const FormikIdSelect = React.forwardRef<HTMLInputElement, FormikIdSelectProps>((props, ref) => {
	const [field, meta, { setValue }] = useField({ type: 'select', ...props } as FieldHookConfig<string | undefined>);

	const handleChange = React.useCallback((id?: string) => setValue(id), [setValue]);

	return <FormIdSelect ref={ref} {...field} {...meta} onChange={handleChange} {...props} />;
});
FormikIdSelect.displayName = 'FormikIdSelect';
