/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

export default function Split(): ReactElement {
	return (
		<div
			style={{
				height: '1px',
				width: '100%',
				backgroundColor: theme.colors.neutral200,
			}}
		/>
	);
}
