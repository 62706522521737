/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Text, TextProps } from 'components';
import React from 'react';

export type DialogContentProps = TextProps;

export const DialogContent: React.FC<DialogContentProps> = ({ children, ...props }) => {
	return (
		<Text
			variant={'medium'}
			flex={'1 1 auto'}
			color={'neutral600'}
			px={'medium'}
			mt={'xSmall'}
			overflowY="auto"
			{...props}
		>
			{children}
		</Text>
	);
};
