/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { CSSProperties } from 'react';

const snippet: CSSProperties = {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
};

export default snippet;
