/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React from 'react';

export type TableRowProps = ContainerProps &
	Omit<React.HTMLAttributes<HTMLTableRowElement>, keyof ContainerProps> & {
		hoverable?: boolean;
	};

export const SX_HOVERABLE = {
	'& a': { color: 'neutral800' },
	'& a svg': { color: 'neutral400' },
	':hover': {
		backgroundColor: 'neutral100',
		'& a': { textDecoration: 'underline', color: 'slate' },
		'& a svg': { color: 'slate' },
	},
};
export const TableRow = React.forwardRef<HTMLTableRowElement, TableRowProps>(
	({ hoverable, sx = {}, ...props }, ref) => {
		const sxHover = hoverable ? SX_HOVERABLE : {};
		return <Container ref={ref} as="tr" sx={{ ...sxHover, ...sx }} {...props} />;
	},
);
TableRow.displayName = 'TableRow';
