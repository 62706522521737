/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { ButtonIcon, Container, ContainerProps, Heading, Text } from 'components';
import React, { ReactNode } from 'react';

import { IconClose, IconComponent } from '../icons';

type Theme = 'light' | 'dark' | 'template';
export interface HeaderBarProps extends Omit<ContainerProps, 'css' | 'title'> {
	icon?: IconComponent | React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
	theme?: Theme;
	onClose?: () => void;
	tagline?: ReactNode;
	center?: ReactNode;
	title: ReactNode;
}

export const HeaderBar: React.FC<HeaderBarProps> = ({
	theme = 'dark',
	children,
	center,
	icon,
	title,
	tagline,
	onClose,
	sx = {},
	...props
}) => {
	const Icon = icon;

	return (
		<Container
			bg={getBackground(theme)}
			px="medium"
			sx={{
				height: 80,
				borderBottom: '1px solid',
				borderColor: 'neutral200',
				display: 'grid',
				gridTemplateColumns: '1fr auto 1fr',
				...sx,
			}}
			{...props}
		>
			<Container display={'flex'} alignItems="center" justifyContent="flex-start">
				{Icon ? (
					<Container
						display={'flex'}
						alignItems="center"
						justifyContent="center"
						mr="small"
						bg={theme === 'template' ? 'neutral000' : 'neutral600'}
						color={theme === 'template' ? 'slate' : 'neutral000'}
						flexShrink={0}
						sx={{
							width: 42,
							height: 42,
							borderRadius: 8,
						}}
					>
						<Icon variant="large" />
					</Container>
				) : null}
				<Container flex="1 1 auto" mr="small">
					{typeof tagline === 'string' ? (
						<Text
							variant="small"
							color="neutral300"
							sx={{
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
							}}
						>
							{tagline}
						</Text>
					) : (
						tagline ?? null
					)}
					<Heading
						variant={tagline ? 'small' : 'medium'}
						color={getColor(theme)}
						sx={{
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
						}}
					>
						{title}
					</Heading>
				</Container>
			</Container>
			<Container display={'flex'} alignItems="center" justifyContent="center">
				{center && <Container flex={'1 0 auto'}>{center}</Container>}
			</Container>
			<Container display={'flex'} alignItems="center" justifyContent="flex-end">
				<Container
					sx={{
						display: 'grid',
						gridAutoFlow: 'column',
						gridGap: ['xSmall', 'small'],
						alignItems: 'center',
					}}
				>
					{children}
				</Container>
				{onClose ? (
					<ButtonIcon
						ml="medium"
						flex="0 0 auto"
						variant="large"
						onClick={onClose}
						tooltip="Close"
						sx={{
							color: 'neutral400',
							':hover': {
								color: 'neutral000',
								bg: 'transparent',
							},
						}}
					>
						<IconClose />
					</ButtonIcon>
				) : null}
			</Container>
		</Container>
	);
};

function getBackground(theme: Theme): string {
	switch (theme) {
		case 'light':
			return 'neutral000';
		case 'dark':
			return 'neutral700';
		case 'template':
			return 'slate';
	}
}

function getColor(theme: Theme): string {
	switch (theme) {
		case 'light':
			return 'neutral800';
		case 'dark':
			return 'neutral000';
		case 'template':
			return 'neutral000';
	}
}
