/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Container, Text } from 'components';
import { formatTime } from 'utils/dateFns';
import { ReactElement } from 'react';
import { millis } from 'utils/time';
import { toLower } from 'lodash';

import { getColorForState, getIconForState, isCanceled, isSucceeded } from '../utils';

interface LogLineWaitingProps {
	started: Date;
	duration: number;
	state: string;
}

export default function LogLineWaiting({ started, duration, state }: LogLineWaitingProps): ReactElement {
	const succeeded = isSucceeded(state);
	const canceled = isCanceled(state);
	const Icon = getIconForState(state);
	const isBold = !succeeded && !canceled;
	const color = succeeded || canceled ? 'neutral700' : getColorForState(state);

	return (
		<Container display="flex" alignItems="center">
			<Icon variant="small" minWidth={16} maxWidth={16} color={color} />

			<Text
				variant={isBold ? 'smallStrong' : 'small'}
				sx={{
					fontFamily: 'code',
					color,
					whiteSpace: 'nowrap',
					mr: 'small',
					ml: 'xSmall',
					minWidth: '150px',
				}}
			>
				{`${formatTime(new Date(started))} - ${duration ? formatTime(new Date(started.getTime() + duration)) : ''}`}
			</Text>

			<Text variant={isBold ? 'smallStrong' : 'small'} color={color}>
				waiting {millis(duration).getDuration()} {toLower(state)}
			</Text>
		</Container>
	);
}
