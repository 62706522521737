/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React from 'react';

export type TableProps = ContainerProps &
	Omit<React.TableHTMLAttributes<HTMLTableElement>, keyof ContainerProps> & {
		fixed?: boolean;
	};

export const Table = React.forwardRef<HTMLTableElement, TableProps>(
	({ fixed, variant = 'default', sx = {}, ...props }, ref) => (
		<Container
			ref={ref}
			as="table"
			tx="table"
			variant={variant}
			{...props}
			sx={{
				borderCollapse: 'collapse',
				tableLayout: fixed ? 'fixed' : 'auto',
				...sx,
			}}
		/>
	),
);
Table.displayName = 'Table';
