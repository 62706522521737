/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import React from 'react';

import { Text, TextProps } from '../Text';

export type ContextualMenuTextProps = TextProps;

export const ContextualMenuText: React.FC<ContextualMenuTextProps> = ({ children, sx = {}, ...props }) => {
	return (
		<Text
			variant={'medium'}
			color={'neutral800'}
			px={12}
			py={'xSmall'}
			sx={{
				minWidth: '158px',
				svg: {
					width: '1em',
					height: '1em',
					mr: 'xxSmall',
				},
				...sx,
			}}
			{...props}
		>
			{children}
		</Text>
	);
};
