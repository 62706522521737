/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import { useFormikContext } from 'formik';
import React from 'react';

export type FormProps = Omit<ContainerProps, keyof React.FormHTMLAttributes<HTMLFormElement>> &
	React.FormHTMLAttributes<HTMLFormElement>;

//has the same behaviour as formik's Form but has support for rebass sx' propery
export const Form = React.forwardRef<HTMLFormElement, FormProps>(({ action = '#', children, ...props }, ref) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const UntypedBox: React.FC<any> = Container;
	const { handleReset, handleSubmit } = useFormikContext();
	return (
		<UntypedBox as={'form'} onSubmit={handleSubmit} ref={ref} onReset={handleReset} action={action} {...props}>
			{children}
		</UntypedBox>
	);
});
Form.displayName = 'Form';
