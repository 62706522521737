/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { CSSProperties, forwardRef, ReactElement } from 'react';

interface FocussableWrapperProps {
	children: ReactElement;
	sx?: CSSProperties;
	disabled: boolean;
	onFocus: () => void;
	onBlur: () => void;
}

const FocussableWrapper = forwardRef<HTMLDivElement, FocussableWrapperProps>(
	({ children, disabled, sx, onFocus, onBlur }, ref) => {
		return (
			<div
				ref={ref}
				onFocus={() => {
					if (!disabled) {
						onFocus();
					}
				}}
				onBlur={() => {
					if (!disabled) {
						onBlur();
					}
				}}
				style={{ ...sx, width: '100%' }}
			>
				{children}
			</div>
		);
	},
);

FocussableWrapper.displayName = 'FocussableWrapper';
export default FocussableWrapper;
