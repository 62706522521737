/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import VariablesAndPlaceholders from 'pages/experimentsV2/StepConfigurationSidebar/Fields/Controls/VariablesAndPlaceholders';
import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import GroupedLabels from 'components/Select/Dropdown/presets/GroupedLabels';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import DropdownInput from 'components/Select/Dropdown/DropdownInput';
import { Group } from 'components/Select/Dropdown/presets/types';
import { Divider, LoadingIndicator, Stack } from 'components';
import { getCategoryLabel } from 'services/targetsApi';
import { groupBy } from 'lodash';

interface DropdownInputFilterableProps {
	small: boolean;
	disabled: boolean;
	value: string;
	hasError: boolean;
	attributeKeys: string[];
	attributeKeysLoading: boolean;
	onValueChanged: (value: string) => void;
}

export default function DropdownInputFilterable({
	small,
	disabled,
	hasError,
	attributeKeys,
	attributeKeysLoading,
	onValueChanged,
	value,
}: DropdownInputFilterableProps): ReactElement {
	const groups = useMemo(() => getGroups(attributeKeys), [attributeKeys]);

	// This logic avoids that one clicks on a prefilled input field and is facing an already filtered list
	const [queryHasChanged, setQueryHasChanged] = useState(false);
	const [queryString, setQueryString] = useState('');
	useEffect(() => {
		if (queryHasChanged) {
			setQueryString(value);
		}
		if (value !== queryString && !queryHasChanged) {
			setQueryHasChanged(true);
		}
	}, [value]);

	return (
		<DropdownInput
			small={small}
			width="100%"
			value={value}
			hasError={hasError}
			placeholder="Target Attribute"
			placement="bottom-start"
			disabled={disabled}
			onValueChanged={onValueChanged}
			popupUpdateSignal={attributeKeysLoading}
		>
			{({ selectItem }) => {
				return (
					<>
						{attributeKeysLoading ? (
							<DropdownContentFrame maxHeight={240}>
								<Stack width={340}>
									<LoadingIndicator variant="medium" color="slate" sx={{ ml: 'xSmall', my: 'xxSmall' }} />
								</Stack>
							</DropdownContentFrame>
						) : (
							<DropdownContentFrame maxHeight={240}>
								<GroupedLabels
									onSelect={({ label }) => selectItem(label)}
									groups={groups}
									queryString={queryString}
									width={340}
								/>
								<Divider />
								<VariablesAndPlaceholders width={340} selectItem={selectItem} />
							</DropdownContentFrame>
						)}
					</>
				);
			}}
		</DropdownInput>
	);
}

function getGroups(attributeKeys: string[]): Group[] {
	const byCategory = Object.entries(groupBy(attributeKeys, getCategory));
	return byCategory.map(([category, keys]) => ({
		label: getCategoryLabel(category),
		options: keys.map((key) => ({
			label: key,
			value: key,
		})),
	}));
}

function getCategory(key: string): string {
	if (key.startsWith('k8s.pod.label')) {
		return 'k8s.pod.label';
	}
	const [category] = key.split('.', 1);
	return category ?? key;
}
