/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { UrlParam } from 'url/useUrlState';

import { SelectedView } from './types';

export interface UrlState {
	selectedStepId?: string;
	selectedView: SelectedView;
}

export const selectedViewParam: UrlParam<SelectedView> = {
	pathSegment: '/design',
	name: 'selectedView',
	defaultValue: 'editor',
};
