/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Button, Container, StyleProp } from 'components';
import { IconComponent } from 'components/icons';
import { ReactElement } from 'react';

interface Option {
	label?: string;
	active?: boolean;
	disabled?: boolean;
	Icon?: IconComponent;
	onClick: (option: Option) => void;
}

interface ButtonGroupProps {
	options: Option[];
	disabled?: boolean;
	sx?: StyleProp;
}

export default function ButtonGroup({ options, disabled, sx = {} }: ButtonGroupProps): ReactElement {
	return (
		<Container display="flex" flexWrap="wrap">
			{options.map((option, index) => {
				const { label, Icon, disabled: optionDisabled, active, onClick } = option;
				return (
					<Button
						key={index}
						variant={active ? 'primarySmall' : 'secondarySmall'}
						sx={{
							borderRadius: index === 0 ? '4px 0 0 4px' : index === options.length - 1 ? '0 4px 4px 0' : 0,
							...sx,
						}}
						onClick={() => onClick(option)}
						disabled={disabled || optionDisabled}
					>
						{Icon && <Icon />}
						{label}
					</Button>
				);
			})}
		</Container>
	);
}
