/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Headway } from 'components/Changelog/Headway';
import { IconNotification } from 'components/icons';
import { Stack, StackProps } from 'components';
import { ReactElement } from 'react';
import { ampli } from 'ampli';

import NavigationItem from './NavigationItem';

interface UserItemProps {
	expanded: boolean;
}

export default function ChangelogItem({ expanded }: UserItemProps): ReactElement | null {
	if (!window.INJECTED_HEADWAY_ACCOUNT) {
		return null;
	}

	return (
		<Headway
			key="static"
			component={Wrapper}
			account={window.INJECTED_HEADWAY_ACCOUNT}
			trigger="#changelog-trigger"
			widgetPosition="top-right"
			onShowWidget={() => {
				ampli.changelogViewed();
			}}
		>
			<NavigationItem icon={IconNotification} label="Changelog" expanded={expanded} />
		</Headway>
	);
}

function Wrapper(props: StackProps): ReactElement {
	return (
		<Stack
			{...props}
			sx={{
				position: 'relative',
				cursor: 'pointer',

				// reset the Headway style prop
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				'.HW_absolute.HW_visible': {
					position: 'absolute !important',
					top: '10px',
					left: '22px',
					marginTop: '0',
					width: '10px',
					height: '10px',
				},

				'.HW_badge': {
					top: '0px !important',
					left: '0px !important',
				},

				'.HW_frame': {
					marginTop: '10px !important',
				},
			}}
			style={{
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				'.HW_widget_component_headwayWidget': {
					display: 'flex !important',
				},

				'.HW_frame': {
					marginTop: '10px !important',
				},
			}}
		/>
	);
}
