/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { EnvironmentSummaryVO } from 'ui-api';
import { Container } from 'components';
import { ReactElement } from 'react';
import { Formik } from 'formik';

import SidebarPredicateModule from './SidebarPredicateModule';
import { EnvironmentConfig } from './types';
import ResultView from './ResultView';
import Sidebar from './Sidebar';

interface ReadOnlyEnvironmentDetailsProps {
	environment: EnvironmentSummaryVO;
}

export default function ReadOnlyEnvironmentDetails({ environment }: ReadOnlyEnvironmentDetailsProps): ReactElement {
	return (
		<Formik<EnvironmentConfig>
			initialValues={{
				name: environment.name,
				predicate: environment.predicate,
				teamIds: environment.teams.map((team) => team.id),
			}}
			onSubmit={() => {}}
		>
			<Container sx={{ display: 'flex', height: '100%' }}>
				<Sidebar size="large" p="large">
					<SidebarPredicateModule readonly isGlobalEnvironment={environment.global} />
				</Sidebar>
				<ResultView />
			</Container>
		</Formik>
	);
}
