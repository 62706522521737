/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { pageParam, queryParam, sortParam, targetIdParam, targetTypeParam } from 'targets/TableView/url';
import { Container, Link, Stack, Text, TextField, Tooltip } from 'components';
import { useAttributeDefinitions } from 'attributes/useAttributeDefinitions';
import { ActionVO, ExperimentStepActionVO, TargetPredicateVO } from 'ui-api';
import { TargetId, emptyTargetDefinition, toTargetId } from 'targets/util';
import TargetDetailsModal from 'targets/TargetDetails/TargetDetailsModal';
import { useBlastRadiusCount } from 'pages/experiments/components/utils';
import { EmptyListContentProps } from 'targets/TableView/TargetsTable';
import AttackedTargetIndicator from 'targets/AttackedTargetIndicator';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import EmptyListContent from 'components/List/EmptyListContent';
import { TargetViewTable } from 'hocs/targets/TargetViewTable';
import { IconInformation, IconSearch } from 'components/icons';
import { formatPercentage } from 'utils/percentageFn';
import useFeatureFlag from 'services/useFeatureFlag';
import { Flex } from '@steadybit/ui-components-lib';
import { useUrlState } from 'url/useUrlState';
import { toTitleCase } from 'utils/string';
import { useFormikContext } from 'formik';
import { getLabel } from 'i18n/label';
import { ampli } from 'ampli';
import { get } from 'lodash';

import { selectedTargetIdParam } from '../urlParams';
import { ExperimentFormValues } from '../types';

interface ActionTargetTableProps {
	actionStep: ExperimentStepActionVO;
	action: ActionVO;
	stepPath: string;
}

export default function ActionTargetTable(props: ActionTargetTableProps): ReactElement | null {
	const { action } = props;
	const targetType = action.target?.type;
	if (!targetType) {
		return null;
	}

	return <ActionTargetTableContent targetType={targetType} {...props} />;
}

interface UrlState {
	targetId: TargetId;
}

interface ActionTargetTableContentProps extends ActionTargetTableProps {
	targetType: string;
}

function ActionTargetTableContent({
	actionStep,
	targetType,
	stepPath,
}: ActionTargetTableContentProps): ReactElement | null {
	const [searchQuery, setSearchQuery] = useState('');
	useEffect(() => {
		if (!actionStep.blastRadius?.predicate) {
			setSearchQuery('');
		}
	}, [actionStep.blastRadius?.predicate]);

	const formik = useFormikContext<ExperimentFormValues>();
	const { environmentId } = formik.values;

	const attributeDefinitions = useAttributeDefinitions();
	const targetDefinitions = useTargetDefinitions();
	const targetDefinition =
		(targetDefinitions.value || []).find((target) => target.id === targetType) || emptyTargetDefinition(targetType);

	const [{ targetId }, getUrlWithState, updateUrlWithState] = useUrlState<UrlState>([selectedTargetIdParam]);

	const variables = useFormikContext<ExperimentFormValues>().values.variables;
	const blastRadiusCount = useBlastRadiusCount(actionStep.blastRadius || {}, environmentId, variables);
	const blastRadiusTargetLabel = useMemo((): string => {
		if (targetDefinition) {
			return toTitleCase(getLabel(targetDefinition.label));
		}

		return toTitleCase(targetType) + 's';
	}, [targetType, targetDefinition]);

	if (!targetDefinition || !actionStep.blastRadius || !attributeDefinitions.value) {
		return null;
	}

	const totalTargets = blastRadiusCount.total || 0;
	const impactedTargets = blastRadiusCount.impacted || actionStep.blastRadius.maximum || 0;

	const targetSelectonReworkEnabled = useFeatureFlag('targetSelectonReworkEnabled');

	const predicateError = get(formik.errors, `${stepPath}.blastRadius.predicate`);
	return (
		<Stack
			size="none"
			sx={{
				width: '100%',
				pb: 'medium',
				px: 'small',
				overflowY: 'auto',
				boxShadow: 'inset 2px 0px 7px rgba(0, 0, 0, 0.1)',
			}}
		>
			<>
				{targetId != null && (
					<TargetDetailsModal
						onClose={() => updateUrlWithState({ targetId: undefined })}
						environmentId={environmentId}
						targetId={targetId}
						withAdvice={false}
					/>
				)}

				<Container
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					height={100}
					width="100%"
					pl={targetSelectonReworkEnabled ? 0 : 300}
				>
					{targetSelectonReworkEnabled && (
						<Flex direction="horizontal" spacing="xxSmall">
							<Tooltip
								content={
									<>
										Targets will be randomly selected from the list below for
										<br />
										inclusion in this action to match the configured blast radius.
									</>
								}
							>
								<div>
									<IconInformation />
								</div>
							</Tooltip>
							<Flex direction="horizontal" align="center" spacing="xxSmall" wrap>
								<Text variant="medium" color="neutral800" sx={{ whiteSpace: 'nowrap' }}>
									Attacked {blastRadiusTargetLabel}:
								</Text>
								<Text variant="mediumStrong" color="neutral800" sx={{ whiteSpace: 'nowrap' }}>
									{impactedTargets}{' '}
									{totalTargets === 0 || impactedTargets === 0
										? ''
										: `(${formatPercentage(impactedTargets / totalTargets)})`}
								</Text>
								<Text variant="medium" color="neutral800" sx={{ whiteSpace: 'nowrap' }}>
									out of {totalTargets} selected by query
								</Text>
							</Flex>
						</Flex>
					)}
					<TextField
						iconLeft={IconSearch}
						placeholder="Search"
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
						wrapperSx={{
							width: targetSelectonReworkEnabled ? 300 : '100%',
						}}
					/>
				</Container>
				<TargetViewTable
					key={predicateError}
					EmptyResult={(props) => <EmptyTargetListContent {...props} predicate={actionStep.blastRadius?.predicate} />}
					getTargetDetailHref={(target) => getUrlWithState({ targetId: toTargetId(target) })}
					targetDefinition={targetDefinition}
					attributeDefinitions={attributeDefinitions.value}
					query={searchQuery}
					environmentId={environmentId}
					predicate={
						actionStep.blastRadius.predicate
							? actionStep.blastRadius.predicate
							: {
									type: 'predicate',
								}
					}
					borderless
				/>
			</>
		</Stack>
	);
}

interface EmptyTargetListContentState {
	targetType: string;
}

interface EmptyTargetListContentProps extends EmptyListContentProps {
	predicate: TargetPredicateVO | undefined;
}

function EmptyTargetListContent({ query, predicate, targetDefinition }: EmptyTargetListContentProps): ReactElement {
	const formik = useFormikContext<ExperimentFormValues>();
	const environmentId = formik.values.environmentId;
	const [{ targetType }, getUrlWithState] = useUrlState<EmptyTargetListContentState>([
		targetTypeParam(targetDefinition.id),
		targetIdParam,
		queryParam,
		pageParam,
		sortParam,
	]);

	const isPredicateDefined = !!predicate;

	return (
		<EmptyListContent
			icon={<AttackedTargetIndicator targetType={targetDefinition.id} variant="xxLarge" color="purple700" />}
			title={
				query
					? `No ${getLabel(targetDefinition.label, 0)} matching your filter found`
					: isPredicateDefined
						? `No ${getLabel(targetDefinition.label, 0)} matching your target selection found`
						: 'No target selected. Add a valid target selection on the left.'
			}
			description={
				<Stack direction="horizontal" size="xSmall">
					<Text variant="medium">Need help finding the right one?</Text>
					<Link
						target="_blank"
						rel="noreferrer"
						href={getUrlWithState(
							{
								targetType,
								targetId: null,
								page: 0,
								sort: [],
								query: '',
							},
							(location) => {
								location.pathname = `/landscape/${environmentId}/table`;
							},
						)}
						onClick={() => {
							ampli.experimentTargetsAllViewed({
								target_type: targetType,
							});
						}}
					>
						<Text variant="medium">Check out all discovered {getLabel(targetDefinition.label, 0)}</Text>
					</Link>
				</Stack>
			}
		/>
	);
}
