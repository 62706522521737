/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Button, ButtonProps } from 'components';
import { ReactElement } from 'react';

export function NumberInputButton({ children, ...props }: ButtonProps): ReactElement {
	return (
		<Button
			variant="secondary"
			bg="neutral200"
			color="neutral600"
			p={0}
			width={38}
			flex="0 0 auto"
			sx={{
				':focus': { outline: 'none', color: 'neutral800' },
				borderRadius: 0,
				margin: -1,
			}}
			{...props}
		>
			{children}
		</Button>
	);
}
