/*
 * Copyright 2019 steadybit GmbH. All rights reserved.
 */

export const ensure = <T>(v?: T | null): T => {
	if (typeof v !== 'undefined' && v !== null) {
		return v;
	}
	throw new ReferenceError('value is undefined');
};
