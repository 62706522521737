/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { DidNotSavePrompt } from 'pages/experiments/wizard/components/DidNotSavePrompt';
import { useFormikContext } from 'formik';
import { matchPath } from 'url/router';
import { ReactElement } from 'react';

import { saveExperiment } from './ExperimentEditorFormHandler';
import { ExperimentFormValues } from './types';

export default function UnsavedChangesPromt(): ReactElement {
	const formik = useFormikContext<ExperimentFormValues>();
	const { environmentId, experimentKey } = formik.values;
	const isNewExperiment = !experimentKey;
	const dirty = formik.dirty;

	return (
		<DidNotSavePrompt
			entityTitle="experiment"
			when={dirty || isNewExperiment}
			block={({ pathname, state }) => {
				if (
					(state === 'redirect_after_initial_save' && isNewExperiment) ||
					state === 'redirect_after_delete' ||
					state === 'redirect_after_duplicate'
				) {
					return false;
				}
				if (!environmentId) {
					return false;
				}
				return !matchPath(pathname, { path: `/experiments/edit/${experimentKey ?? '<new>'}` });
			}}
			onSaveCallback={async () => {
				return await saveExperiment(formik.values);
			}}
		/>
	);
}
