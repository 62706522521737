/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { isSteadybitOrPartnerUser } from 'tracking/ignoredEmailAddresses';
import { useIntercom as useIntercomExternal } from 'react-use-intercom';
import { CurrentUserVO, TenantVO } from 'ui-api';
import { useTenant } from 'tenancy/useTenant';
import { useUser } from 'services/usersApi';
import { useHistory } from 'url/hooks';
import { useEffect } from 'react';

import './intercom_style.css';

declare global {
	interface Window {
		INJECTED_INTERCOM_APP_ID?: string;
	}
}

export default function useIntercom(): void {
	const { boot } = useIntercomExternal();
	const tenant = useTenant();
	const user = useUser();

	const { location } = useHistory();
	useEffect(() => {
		if (['/onboarding'].includes(location.pathname) || location.pathname.startsWith('/settings/teams/create/')) {
			document.body.classList.add('intercom-onboarding-raised');
		} else {
			document.body.classList.remove('intercom-onboarding-raised');
		}
	}, [location.pathname]);

	useEffect(() => {
		if (isIntercomAvailable(tenant, user)) {
			boot({
				name: user.name,
				userId: user.username,
				userHash: user.intercomDigest,
				email: user.email,
				company: { companyId: tenant.key || 'unknown', name: tenant.name },
			});
		}
	}, [user, tenant, boot]);
}

// Since useIntercom does not expose isBooted or isInitialized,
// we need to check if the intercom is available manually
export function isIntercomAvailable(tenant: TenantVO, user: CurrentUserVO): boolean {
	return Boolean(window.INJECTED_INTERCOM_APP_ID) && !isSteadybitOrPartnerUser(user);
}
