/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import { IconChevronDown, IconChevronUp, IconClose, IconSaveFile } from 'components/icons';
import { Box, Colors, Flex, Grid, Spacings, Text } from '@steadybit/ui-components-lib';
import DropdownInput from 'components/Select/Dropdown/DropdownInput';
import Skeletons from 'components/Skeleton/Skeletons';
import { EnvironmentSummaryVO, TeamVO } from 'ui-api';
import { withBaseHref } from 'utils/getBaseHref';
import { ReactElement, useState } from 'react';
import { ButtonIcon, Link } from 'components';
import { useUrlState } from 'url/useUrlState';
import { useTenant } from 'tenancy/useTenant';
import { startCase, toLower } from 'lodash';
import { theme } from 'styles.v2/theme';
import { days } from 'utils/time';

import {
	directionParam,
	environmentIdsParam,
	pageParam,
	sortParam,
	statesParam,
	teamIdsParam,
	timeParam,
	UrlState,
} from './urlParams';
import Times, { getTimeLabel } from './Times';
import Environments from './Environments';
import States from './States';
import Teams from './Teams';

interface FilterBarProps {
	environments: EnvironmentSummaryVO[];
	loading: boolean;
	teams: TeamVO[];
}

export default function FilterBar({ loading, environments, teams }: FilterBarProps): ReactElement {
	const [{ environmentIds, time, teamIds, states }, , updateUrlState] = useUrlState<UrlState>([
		environmentIdsParam,
		teamIdsParam,
		statesParam,
		timeParam,
		pageParam,
	]);

	return (
		<Flex spacing="small">
			<Text type="mediumStrong">Filter by :</Text>
			<Grid cols="1fr 1fr 1fr 1fr 40px" spacing="medium" align="end" style={{ width: '100%' }}>
				<DropDown
					placeholder="Type to search"
					loading={loading}
					name="Team"
					label={teamIds
						.map((teamId) => teams.find((team) => team.id === teamId))
						.filter((team) => team !== undefined)
						.sort((a, b) => a.name.localeCompare(b.name))
						.map((team) => team.name)
						.concat(teamIds.filter((teamId) => teams.find((team) => team.id === teamId) === undefined))
						.join(', ')}
					clearAll={() => updateUrlState({ teamIds: [] })}
				>
					{({ value, width }) => (
						<DropDownListWrapper width={width}>
							<Teams
								selectedTeamIds={teamIds}
								teams={teams}
								value={value}
								width={width}
								toggleId={(_teamId) => {
									updateUrlState({
										teamIds:
											teamIds.indexOf(_teamId) !== -1 ? teamIds.filter((id) => id !== _teamId) : [...teamIds, _teamId],
									});
								}}
								selectIds={(_teamIds) => {
									updateUrlState({
										teamIds: Array.from(new Set([...teamIds, ..._teamIds])),
									});
								}}
								deselectIds={(_teamIds) => {
									updateUrlState({
										teamIds: teamIds.filter((id) => !_teamIds.includes(id)),
									});
								}}
							/>
						</DropDownListWrapper>
					)}
				</DropDown>

				<DropDown placeholder="Select a data range" name="Time range" loading={loading} label={getTimeLabel(time)}>
					{({ width, selectItem }) => (
						<Times
							selectedTime={time}
							width={width}
							selectItem={(time) => {
								updateUrlState({ time });
								if (!Array.isArray(time)) {
									selectItem('time');
								}
							}}
						/>
					)}
				</DropDown>

				<DropDown
					placeholder="Type to search"
					name="Environment"
					loading={loading}
					label={environmentIds
						.map((envId) => environments.find((env) => env.id === envId))
						.filter((env) => env !== undefined)
						.sort((a, b) => a.name.localeCompare(b.name))
						.map((e) => e.name)
						.concat(environmentIds.filter((envId) => environments.find((env) => env.id === envId) === undefined))
						.join(', ')}
					clearAll={() => updateUrlState({ environmentIds: null })}
				>
					{({ value, width }) => (
						<DropDownListWrapper width={width}>
							<Environments
								environments={environments}
								value={value}
								selectedEnvironmentIds={environmentIds}
								width={width}
								toggleId={(_envId) => {
									updateUrlState({
										environmentIds:
											environmentIds.indexOf(_envId) !== -1
												? environmentIds.filter((id) => id !== _envId)
												: [...environmentIds, _envId],
									});
								}}
								selectIds={(_envIds) => {
									updateUrlState({
										environmentIds: _envIds,
									});
								}}
								deselectIds={(_envIds) => {
									updateUrlState({
										environmentIds: environmentIds.filter((id) => !_envIds.includes(id)),
									});
								}}
							/>
						</DropDownListWrapper>
					)}
				</DropDown>

				<DropDown
					placeholder="Type to search"
					loading={loading}
					name="State"
					label={states
						.map((s) => startCase(toLower(s)))
						.sort((a, b) => a.localeCompare(b))
						.join(', ')}
					clearAll={() => updateUrlState({ states: null })}
				>
					{({ value, width }) => (
						<DropDownListWrapper width={width}>
							<States
								states={['CANCELED', 'COMPLETED', 'CREATED', 'ERRORED', 'FAILED', 'PREPARED', 'RUNNING']}
								value={value}
								selectedStates={states}
								width={width}
								selectItem={(_state) => {
									updateUrlState({
										states: states.indexOf(_state) !== -1 ? states.filter((id) => id !== _state) : [...states, _state],
									});
								}}
								selectAll={(_states) => {
									updateUrlState({
										states: Array.from(new Set([...states, ..._states])),
									});
								}}
							/>
						</DropDownListWrapper>
					)}
				</DropDown>

				<DownloadAsCSVButton />
			</Grid>
		</Flex>
	);
}

interface DropDownProps {
	placeholder: string;
	loading: boolean;
	label: string;
	name: string;
	children: (p: {
		width: string | number | undefined;
		value: string;
		selectItem: (value: string) => void;
	}) => ReactElement;
	clearAll?: () => void;
}

function DropDown({ name, label, placeholder, loading, clearAll, children }: DropDownProps): ReactElement {
	const [groupFilter, setGroupFilter] = useState<string | null>(null);
	const [isOpen, setIsOpen] = useState(false);
	const labelIsSet = !!label;

	const Chevron = isOpen ? IconChevronUp : IconChevronDown;

	let content;
	if (loading) {
		content = (
			<Box
				align="center"
				style={{
					width: 'calc(100% - 17px)',
					height: '37px',
					px: 'xSmall',
					border: '1px solid ' + Colors.neutral300,
					borderRadius: 'xxSmall',
				}}
			>
				<Skeletons height={24} widths={[100]} />
			</Box>
		);
	} else {
		content = (
			<DropdownInput
				placeholder={placeholder}
				placement="bottom-start"
				width="100%"
				value={isOpen ? groupFilter || '' : label || ''}
				onValueChanged={(v) => setGroupFilter(v || null)}
				onOpen={() => {
					setGroupFilter(null);
					setIsOpen(true);
				}}
				onClose={() => setIsOpen(false)}
				sx={{
					bg: labelIsSet && !isOpen ? 'purple100' : 'neutral000',
					borderColor: labelIsSet ? 'purple300' : 'neutral300',
					color: labelIsSet ? theme.colors.slate + ' !important' : 'neutral800',
					fontWeight: labelIsSet ? '500' : 'normal',
					paddingRight: '26px',
				}}
				iconRight={
					label && !isOpen && clearAll ? (
						<IconClose
							variant="xSmall"
							onClick={() => {
								clearAll();
								setGroupFilter(null);
							}}
							sx={{
								mt: '6px',
								mr: '6px',
								bg: labelIsSet ? 'purple100' : 'neutral000',
								cursor: 'pointer',
								color: 'neutral800',
								'&:hover': {
									color: 'neutral800',
								},
							}}
						/>
					) : (
						<Chevron variant="xSmall" mr="xSmall" mt="xxSmall" />
					)
				}
			>
				{({ width, selectItem }) => (
					<DropdownContentFrame maxHeight={600}>
						{children({ value: groupFilter || '', width, selectItem })}
					</DropdownContentFrame>
				)}
			</DropdownInput>
		);
	}

	return (
		<Flex spacing="xxSmall" style={{ width: '100%' }}>
			<Text type="small" style={{ color: Colors.neutral600 }}>
				{name}
			</Text>
			{content}
		</Flex>
	);
}

function DropDownListWrapper({
	width,
	children,
}: {
	width: string | number | undefined;
	children: ReactElement;
}): ReactElement {
	return (
		<Flex
			style={{
				minWidth: width,
				py: 'xSmall',
			}}
		>
			<Box
				style={{
					width: 'calc(100% - 32px)',
					marginLeft: Spacings.small,
					marginRight: Spacings.small,
				}}
			>
				{children}
			</Box>
		</Flex>
	);
}

function DownloadAsCSVButton(): ReactElement {
	const tenant = useTenant();
	const [{ environmentIds, time, teamIds, states }] = useUrlState<UrlState>([
		environmentIdsParam,
		directionParam,
		teamIdsParam,
		statesParam,
		timeParam,
		pageParam,
		sortParam,
	]);

	const params = new URLSearchParams();
	if (teamIds.length > 0) {
		params.append('teamIds', teamIds.join(','));
	}
	if (environmentIds.length > 0) {
		params.append('environmentIds', environmentIds.join(','));
	}
	if (states.length > 0) {
		params.append('states', states.join(','));
	}

	if (time) {
		let createdFrom: number | null = null;
		let createdTo: number | null = null;
		createdFrom = new Date().getTime();
		createdTo = new Date().getTime();
		if (Array.isArray(time)) {
			createdFrom = time[0];
			createdTo = time[1];
		} else if (time === 'lastDay') {
			createdFrom = createdTo - days(1).getMillis();
		} else if (time === 'lastWeek') {
			createdFrom = createdTo - days(7).getMillis();
		} else if (time === 'lastMonth') {
			createdFrom = createdTo - days(31).getMillis();
		}
		params.append('createdFrom', String(createdFrom));
		params.append('createdTo', String(createdTo));
	}

	params.append('tenantKey', tenant.key);

	return (
		<Link href={withBaseHref(`/ui/reports/experiments/runs?${params.toString()}`)}>
			<ButtonIcon
				variant="small"
				tooltip="Download as CSV"
				sx={{ borderColor: 'neutral300', height: '40px', width: '40px' }}
			>
				<IconSaveFile />
			</ButtonIcon>
		</Link>
	);
}
