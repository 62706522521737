/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ReactElement, ReactNode } from 'react';
import { Container } from 'components';

interface FullscreenModalProps {
	children: ReactNode | ReactNode[];
}

export default function FullscreenModal({ children }: FullscreenModalProps): ReactElement {
	return (
		<Container
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '100vw',
				maxWidth: '100vw',
				height: '100vh',
				maxHeight: '100vh',
				bg: 'neutral000',
				boxShadow: 'applicationLarge',
				borderRadius: 4,
			}}
		>
			{children}
		</Container>
	);
}
