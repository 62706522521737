/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { isIntercomAvailable } from 'tracking/intercom';
import { useIntercom } from 'react-use-intercom';
import { useTenant } from 'tenancy/useTenant';
import { Link, Text } from 'components';
import { ReactElement } from 'react';

import { useUser } from '../../../services/usersApi';
import { Message } from '../Message';

interface LimitReachedMessageProps {
	title: string;
	message: string;
}

export default function LimitReachedMessage({ title, message, ...props }: LimitReachedMessageProps): ReactElement {
	const tenant = useTenant();
	const user = useUser();
	const intercomAvailable = isIntercomAvailable(tenant, user);
	const { show } = useIntercom();
	const linkTalkToUsProps = intercomAvailable
		? { onClick: show }
		: { href: 'https://steadybit.com/contact', external: true };

	return (
		<Message variant={'warning'} title={title} {...props}>
			<Text>
				{message}
				<br />
				You want more? <Link {...linkTalkToUsProps}>Talk to us!</Link>
			</Text>
		</Message>
	);
}
