/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Container, Skeletons, TableDataCell, TableRow } from 'components';
import { ReactElement } from 'react';

const placeholderWidths = [200, 67, 30, 120, 160];

interface TableLoadingRowProps {
	variant?: 'default' | 'small';
	numColumns: number;
}

export default function TableLoadingRow({ variant, numColumns }: TableLoadingRowProps): ReactElement {
	return (
		<TableRow>
			{Array(numColumns)
				.fill(null)
				.map((_, i) => (
					<TableDataCell
						colSpan={1}
						key={i}
						sx={
							variant === 'small'
								? {
										minHeight: '26px !important',
								  }
								: undefined
						}
					>
						<Container py={variant === 'small' ? 'none' : 'small'}>
							<Skeletons height={16} widths={[placeholderWidths[i % 5]]} />
						</Container>
					</TableDataCell>
				))}
		</TableRow>
	);
}
