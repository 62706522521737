/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	HeaderBar,
	Heading,
	RouterButtonProps,
	Snackbar,
	Tooltip,
	Wizard,
	WizardContent,
	WizardFooter,
	WizardSidebar,
	WizardSidebarStep,
} from 'components';
import { ActionPermissions } from 'pages/settings/teams/components/actionPermissions';
import { Form, Formik, FormikHelpers, FormikProps, useFormikContext } from 'formik';
import { IconArrowLeft, IconArrowRight, IconTeam } from 'components/icons';
import { Prompt, Redirect, Route, Switch, matchPath } from 'url/router';
import MaxTeamsMessage from 'components/Message/messages/MaxTeams';
import FullscreenModal from 'components/Modal/v2/FullscreenModal';
import { TeamLogoColors } from 'components/icons/TeamLogoIcon';
import WizardWrapper from 'components/Wizard/WizardWrapper';
import useMaxTeams from 'utils/hooks/useMaxTeams';
import { ValidationError } from 'utils/error';
import { Services } from 'services/services';
import { useHistory } from 'url/hooks';
import { History } from 'history';
import React from 'react';

import { EnvironmentPermissions } from './components/environmentPermissions';
import TeamInformation from './components/teamInformation';
import { TeamFormValues } from './components/teamTypes';
import { TeamMembers } from './components/teamMembers';

const InitialTeamFormValues: TeamFormValues = {
	name: '',
	key: '',
	logoId: '1',
	logoColor: TeamLogoColors[0],
	description: '',
	allowedActions: [],
	allowedEnvironments: [],
	members: [],
};

const wizardBaseRoute = '/settings/teams/create';
const wizardRoutes = [
	wizardBaseRoute + '/information',
	wizardBaseRoute + '/environments',
	wizardBaseRoute + '/attacks',
	wizardBaseRoute + '/members',
];
const wizardFields: Array<Array<keyof TeamFormValues>> = [
	['name', 'key', 'description'], //
	['allowedEnvironments'], //
	['allowedActions'], //
	['members'], //
];

const navigate = async (
	from: number,
	to: number,
	formik: FormikProps<TeamFormValues>,
	history: History,
	e?: React.MouseEvent<HTMLButtonElement>,
): Promise<void> => {
	if (e) {
		e.preventDefault();
		e.stopPropagation();
	}

	wizardFields[from].forEach((fieldName) => formik.setFieldTouched(fieldName));
	try {
		await Services.teams.validateTeam(formik.values);
		history.push(wizardRoutes[to]);
	} catch (error) {
		if (error instanceof ValidationError) {
			error.violations.forEach((violation) => formik.setFieldError(violation.field, violation.message));
		}
	}
};

const WizardNavigationButton: React.FC<
	{ tooltip?: string; from: number; to: number } & Omit<RouterButtonProps, 'to'>
> = ({ children, tooltip, from, to, ...props }) => {
	const formik = useFormikContext<TeamFormValues>();
	const history = useHistory();
	return (
		<Tooltip content={tooltip}>
			<Button
				onClick={(e) => navigate(from, to, formik, history, e)}
				{...props}
				data-cy={to > from ? 'wizard-next' : 'wizard-prev'}
			>
				{children}
			</Button>
		</Tooltip>
	);
};

const CreateTeamWizard: React.VFC = () => {
	const history = useHistory();
	const handleSubmit = async ({ ...values }: TeamFormValues, form: FormikHelpers<TeamFormValues>): Promise<void> => {
		try {
			const { id } = await Services.teams.createTeam(values);
			await Services.teams.addOrUpdateTeamMembers(id, { members: values.members });
			Snackbar.dark('Team saved.', { toastId: 'team-saved' });
			history.replace('/settings/teams');
		} catch (error) {
			if (error instanceof ValidationError) {
				error.violations.forEach((violation) => form.setFieldError(violation.field, violation.message));
			} else {
				Snackbar.error('Error while saving Team.', { toastId: 'team-error' });
			}
		}
	};

	const height = 'calc(100vh - 80px)';
	const { maxTeamsReached } = useMaxTeams();

	return (
		<Formik initialValues={InitialTeamFormValues} enableReinitialize onSubmit={handleSubmit}>
			{(formik: FormikProps<TeamFormValues>) => {
				const step1Valid = !!formik.values.name && !!formik.values.key;

				return (
					<>
						<Prompt
							when={formik.dirty && !formik.isSubmitting}
							message={({ pathname }) => {
								const allowTransition =
									pathname.startsWith(wizardBaseRoute) || matchPath(pathname, { path: history.location.pathname });
								if (allowTransition) {
									return true;
								}
								return 'You have unsaved changes, do you really want to quit?';
							}}
						/>
						<Form noValidate>
							<FullscreenModal>
								<HeaderBar
									icon={IconTeam}
									title="Create a new Team"
									tagline={formik.values.name || 'Team name'}
									onClose={() => {
										history.push('/settings/teams');
									}}
								/>
								<Wizard>
									<WizardSidebar
										activeIndex={wizardRoutes.indexOf(history.location.pathname)}
										onStepSelect={(i, activeIndex) => {
											navigate(activeIndex, i, formik, history);
										}}
									>
										<WizardSidebarStep name="Team Information">
											Teams allow you to control permissions and limit allowed targets and actions.
										</WizardSidebarStep>
										<WizardSidebarStep name="Environment Permissions">
											Limit the set of targets that this team can use in an Experiment or the Explorer.
										</WizardSidebarStep>
										<WizardSidebarStep name="Action Permissions">
											Define which action you want to allow for this team.
										</WizardSidebarStep>
										<WizardSidebarStep name="Team Members">Add existing members to this new team.</WizardSidebarStep>
									</WizardSidebar>
									<Switch>
										<Redirect from={wizardBaseRoute} exact to={wizardRoutes[0]} />
										<Route path={wizardRoutes[0]}>
											<WizardWrapper height={height}>
												<WizardContent>
													<Heading variant="large" my={'large'}>
														1. Team Information
													</Heading>
													<TeamInformation />
												</WizardContent>

												<WizardFooter>
													<WizardNavigationButton
														from={0}
														to={1}
														disabled={!step1Valid}
														tooltip={step1Valid ? undefined : 'Please enter a team name and key.'}
													>
														Environment Permissions
														<IconArrowRight ml={'small'} />
													</WizardNavigationButton>
												</WizardFooter>
											</WizardWrapper>
										</Route>
										<Route path={wizardRoutes[1]}>
											<WizardWrapper height={height}>
												<WizardContent>
													<Heading variant="large" my={'large'}>
														2. Environment Permissions
													</Heading>
													<EnvironmentPermissions addEnvironmentEnabled />
												</WizardContent>

												<WizardFooter>
													<WizardNavigationButton variant="secondary" from={1} to={0} mr={'auto'}>
														<IconArrowLeft mr={'smalll'} />
														Back
													</WizardNavigationButton>
													<WizardNavigationButton from={1} to={2}>
														Action Permissions
														<IconArrowRight ml={'small'} />
													</WizardNavigationButton>
												</WizardFooter>
											</WizardWrapper>
										</Route>
										<Route path={wizardRoutes[2]}>
											<WizardWrapper height={height}>
												<WizardContent>
													<Heading variant="large" my={'large'}>
														3. Action Permissions
													</Heading>
													<ActionPermissions />
												</WizardContent>

												<WizardFooter>
													<WizardNavigationButton variant="secondary" from={2} to={1} mr={'auto'}>
														<IconArrowLeft mr={'smalll'} />
														Back
													</WizardNavigationButton>
													<WizardNavigationButton from={2} to={3}>
														Team Members
														<IconArrowRight ml={'small'} />
													</WizardNavigationButton>
												</WizardFooter>
											</WizardWrapper>
										</Route>
										<Route path={wizardRoutes[3]}>
											<WizardWrapper height={height}>
												<WizardContent>
													<Heading variant="large" my={'large'}>
														4. Team Members
													</Heading>
													<TeamMembers teamId="members" />

													{maxTeamsReached && <MaxTeamsMessage mt={'small'} />}
												</WizardContent>

												<WizardFooter>
													<WizardNavigationButton variant="secondary" from={3} to={2} mr={'auto'}>
														<IconArrowLeft mr={'smalll'} />
														Back
													</WizardNavigationButton>

													<Button
														data-cy={'wizard-finish'}
														id={'save'}
														type={'submit'}
														disabled={!formik.dirty || maxTeamsReached}
														loading={formik.isSubmitting}
													>
														Create Team
													</Button>
												</WizardFooter>
											</WizardWrapper>
										</Route>
									</Switch>
								</Wizard>
							</FullscreenModal>
						</Form>
					</>
				);
			}}
		</Formik>
	);
};

export default CreateTeamWizard;
