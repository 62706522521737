/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { FormikErrors, FormikValues, FormikHelpers } from 'formik';
import { forEach, isArray, isPlainObject } from 'lodash';
import { isMatch } from 'matcher';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function flattenKeys(obj: Record<string, any> | Array<any>): string[] {
	const result: string[] = [];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const flatten = (collection: Record<string, any> | Array<any>, prefix = '', suffix = ''): void => {
		forEach(collection, (value, key) => {
			const path = `${prefix}${key}${suffix}`;
			if (isArray(value)) {
				flatten(value, `${path}[`, ']');
			} else if (isPlainObject(value)) {
				flatten(value, `${path}.`);
			} else {
				result.push(path);
			}
		});
	};
	flatten(obj);
	return result;
}

export function hasFormikError<T>(errors: FormikErrors<T>, fieldPatterns: string[]): boolean {
	const errorFields = flattenKeys(errors);
	return errorFields.some((errorField) => containsField(fieldPatterns, errorField));
}

function containsField(fieldPatterns: string[], fieldName: string): boolean {
	return fieldPatterns.some((fieldPattern) => isMatch(fieldName, fieldPattern));
}

export function touchFormikFields<T>(
	formik: FormikHelpers<T>,
	obj: FormikErrors<T> | FormikValues,
	fieldPatternFilter?: string[],
): void {
	flattenKeys(obj).forEach((field) => {
		if (typeof fieldPatternFilter === 'undefined' || containsField(fieldPatternFilter, field)) {
			formik.setFieldTouched(field, true, false);
		}
	});
}
