/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import FileModal from 'components/Modal/predefined/fileModal';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { json } from 'utils/mediaTypes';
import { ReactElement } from 'react';
import { TemplateVO } from 'ui-api';

type TemplateFileModalProps = {
	template: TemplateVO;
	onClose: () => void;
};

export default function TemplateFileModal({ template, onClose }: TemplateFileModalProps): ReactElement {
	const downloadContentResult = usePromise(
		() => Services.templatesApi.getTemplatesAsApiFormat(template.id),
		[template.id],
	);

	return (
		<FileModal
			title={template.templateTitle}
			content={downloadContentResult.value ? JSON.stringify(downloadContentResult.value, null, 2) : undefined}
			lang="json"
			onClose={onClose}
			withDownload={{
				mediaType: json,
				fileName: `template-${template.templateTitle}.json`,
			}}
		/>
	);
}
