/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import { Container, FormCheckboxGroup, Stack, Text } from 'components';
import DropdownInput from 'components/Select/Dropdown/DropdownInput';
import { ReactElement, useMemo } from 'react';
import { useField } from 'formik';
import { OptionVO } from 'ui-api';

import { FormComboContainerProps } from '../Select';
import { hasError } from './utils';

type FormikComboboxOwnProps = {
	name: string;
	maxLength?: number;
	options: OptionVO[];
};

type FormikMultiSelectProps = FormikComboboxOwnProps &
	Omit<FormComboContainerProps, 'error' | 'touched' | 'value' | 'onChange' | keyof FormikComboboxOwnProps>;

export default function FormikMultiSelect({
	flex,
	my,
	mx,
	mt,
	mb,
	ml,
	mr,
	width,
	name,
	options,
	disabled,
	...props
}: FormikMultiSelectProps): ReactElement {
	const [{ value }, meta, { setValue }] = useField<string[]>(name);

	const selectedOptions = useMemo(
		() =>
			(value || []).map((v: string) => options.find((o) => o.value === v) ?? { value: v, label: v, hasError: true }),
		[value, options],
	);

	return (
		<Container
			display={'flex'}
			flexDirection={'column'}
			my={my}
			mx={mx}
			mt={mt}
			mb={mb}
			ml={ml}
			mr={mr}
			width={width}
			flex={flex}
		>
			<DropdownInput value={value ? value?.join(', ') : ''} hasError={hasError(meta)} disabled={disabled} width="100%">
				{({ width }) => (
					<DropdownContentFrame>
						<Stack size={'xSmall'} p="small" width={width}>
							<FormCheckboxGroup
								value={selectedOptions}
								onChange={(v) => setValue(v.map((o) => o.value))}
								options={options}
								{...props}
							/>
						</Stack>
					</DropdownContentFrame>
				)}
			</DropdownInput>

			{hasError(meta) && (
				<Text mt={'xxSmall'} variant={'smallStrong'} color={'coral'} data-formik-error>
					{meta.error}
				</Text>
			)}
		</Container>
	);
}
