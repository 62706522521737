/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import { formatDifference } from 'utils/dateFns';
import { useNowObservable } from 'utils/now';
import React from 'react';

export const TimeDiff: React.VFC<{ value: Date } & Omit<ContainerProps, 'css' | 'value'>> = ({
	value,
	sx = {},
	...props
}) => {
	const now = useNowObservable();
	if (!value) {
		return null;
	}
	return (
		<Container sx={{ fontVariantNumeric: 'tabular-nums', ...sx }} {...props}>
			{formatDifference(now, value)}
		</Container>
	);
};
