/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import AstroidScreen from 'components/List/AstroidScreen/AstroidScreen';
import { useTargetCounts } from 'targets/useTargetCounts';
import { Container, RouterLink, Text } from 'components';
import { IconTarget } from 'components/icons';
import { useTenant } from 'tenancy/useTenant';
import { useFormikContext } from 'formik';
import { setParamValue } from 'url/utils';
import { useHistory } from 'url/hooks';
import { ReactElement } from 'react';
import { ampli } from 'ampli';

import { coloringParam, environmentParam, filterQueryParam, groupingParam, sizingParam } from '../urlParams';
import { LandscapeConfig } from '../types';

export default function ExploreEmptyView(): ReactElement {
	const { createHref } = useHistory();
	const tenant = useTenant();
	const isAdmin = tenant.user?.role === 'ADMIN';

	const formik = useFormikContext<LandscapeConfig>();
	const { filterQuery, groupBy, colorBy, sizeBy, environmentId } = formik.values;

	const counts = useTargetCounts({ environmentId, predicate: undefined });
	const environmentHasTargets = !counts.value ? true : counts.value.total > 0 ? true : false;

	return (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100%',
				width: '100%',
				bg: 'neutral700',
				color: 'neutral000',
			}}
		>
			<Container mt={-160}>
				<AstroidScreen
					title={
						<Text variant="xLargeStrong" color="purple300">
							{!environmentHasTargets
								? 'No targets found in this environment'
								: 'No targets found matching your filter'}
						</Text>
					}
					icon={<IconTarget variant="xxLarge" color="neutral000" />}
					description={
						environmentHasTargets ? (
							<Text
								variant="large"
								color="neutral000"
								sx={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								Please adjust the filter on the left or
								<RouterLink
									to={createHref((location) => {
										setParamValue(location, environmentParam, '');
										setParamValue(location, groupingParam, []);
										setParamValue(location, sizingParam, '');
										setParamValue(location, coloringParam, '');
										setParamValue(location, filterQueryParam, '');
									})}
									onClick={() => {
										ampli.landscapeExplorerResetted({
											environment_id: formik.values.environmentId,
											url: window.location.href,
											landscape_explorer_colored_by: colorBy,
											landscape_explorer_filter_query: filterQuery,
											landscape_explorer_grouped_by: groupBy,
											landscape_explorer_sized_by: sizeBy,
										});
									}}
									sx={{
										color: 'purple300',
										mx: 'xxSmall',
									}}
								>
									reset the view
								</RouterLink>
								to start from scratch.
							</Text>
						) : isAdmin ? (
							<Text
								variant="large"
								color="neutral000"
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									flexWrap: 'wrap',
								}}
							>
								The environment doesn&apos;t contain any targets. Please update
								<RouterLink
									to={createHref((location) => {
										location.pathname = `/settings/environments/${encodeURIComponent(formik.values.environmentId)}`;
									})}
									color="purple300"
									mx="xxSmall"
								>
									the environment
								</RouterLink>
								or
								<RouterLink
									to={createHref((location) => {
										location.pathname = '/settings/agents/setup';
									})}
									color="purple300"
									ml="xxSmall"
								>
									add more agents
								</RouterLink>
								.
							</Text>
						) : (
							<Text variant="large" color="neutral000" textAlign="center">
								The environment doesn&apos;t contain any targets. Please choose another one or reach out to your
								administrator.
							</Text>
						)
					}
				/>
			</Container>
		</Container>
	);
}
