/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { BasicButton, BasicButtonProps } from 'components';
import React from 'react';

export type ContextualMenuButtonProps = BasicButtonProps;

export const ContextualMenuButton: React.VFC<ContextualMenuButtonProps> = ({ children, disabled, ...props }) => {
	return (
		<BasicButton
			{...props}
			px={12}
			py={10}
			tx={'text'}
			variant={'medium'}
			sx={{
				color: disabled ? 'neutral400' : 'neutral800',
				display: 'inline-flex',
				alignItems: 'center',
				minWidth: '158px',
				pointerEvents: disabled ? 'none' : 'initial',
				textDecoration: 'none',
				bg: 'neutral000',
				borderRadius: 0,
				outline: 'none',
				cursor: 'pointer',
				':hover': {
					textDecoration: 'none',
					bg: 'purple100',
					color: 'slate',
				},

				':active': {
					outline: 0,
				},

				svg: {
					width: '1em',
					height: '1em',
					mr: 'xxSmall',
				},
				...props.sx,
			}}
		>
			{children}
		</BasicButton>
	);
};
