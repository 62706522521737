/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, Divider, Stack, Text, TextField, userConfirmV2 } from 'components';
import PlaceholderMarker from 'pages/templates/components/PlaceholderMarker';
import EnvVariables from 'components/EnvVariables/EnvVariables';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ReactElement, useEffect, useState } from 'react';
import { EnvironmentVariableVO } from 'ui-api';
import { IconAdd } from 'components/icons';
import { useField } from 'formik';

interface EnvironmentVariablesProps {
	width: string | number | undefined;
	allowAddNew?: boolean;
	onVariableSelected: (variable: string) => void;
}

export default function EnvironmentVariables({
	allowAddNew = true,
	width,
	onVariableSelected,
}: EnvironmentVariablesProps): ReactElement | null {
	const [{ value: variables }] = useField<EnvironmentVariableVO[] | string[]>('variables');

	return (
		<Stack size="none">
			<div style={{ width, flexGrow: 1 }}>
				<EnvVariables variables={variables} onVariableClick={(key) => onVariableSelected(`{{${key}}}`)} />
			</div>
			<Divider />

			{allowAddNew && (
				<div style={{ display: 'flex' }}>
					<Button
						variant="chromelessSmall"
						onClick={async () => {
							await userConfirmV2({
								title: 'Add Environment Variable',
								message: ({ setDisabled }) => <AddEnvVarContent setDisabled={setDisabled} />,
								actions: [
									{
										value: 'confirm',
										label: 'Add',
										variant: 'primary',
										dataCy: 'create-env-var',
										action: async () => {
											// eslint-disable-next-line @typescript-eslint/ban-ts-comment
											// @ts-ignore
											const newEnvVar = document.getElementById('new-env-var-value')?.value;
											if (newEnvVar) {
												onVariableSelected(`{{${newEnvVar}}}`);
											}
										},
									},
								],
								secondaryActions: [{ value: 'cancel', label: 'Cancel' }],
								width: '700px',
							});
						}}
						data-cy="add-env-var"
					>
						<IconAdd variant="small" mr="xxSmall" />
						<span style={{ ...textEllipsis }}>Add environment variable</span>
					</Button>
				</div>
			)}
		</Stack>
	);
}

function AddEnvVarContent({ setDisabled }: { setDisabled: (disabled: boolean) => void }): ReactElement {
	const [value, setValue] = useState<string>('');
	useEffect(() => {
		setDisabled(!value);
	}, [value]);

	return (
		<Stack size="xxSmall">
			<Text as="span" variant="mediumStrong" color="neutral800">
				Name
			</Text>
			<Stack direction="horizontal" size="none">
				<PlaceholderMarker marker="{{" left />
				<TextField
					id="new-env-var-value"
					value={value}
					onChange={(e) => setValue(e.target.value)}
					placeholder="ENVIRONMENT VARIABLE"
					sx={{
						borderRadius: 0,
						borderLeft: 'none',
						borderRight: 'none',
					}}
				/>
				<PlaceholderMarker marker="}}" />
			</Stack>
		</Stack>
	);
}
