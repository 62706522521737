/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Subject } from 'rxjs';

import { ExploreViewEvent } from './TargetBubbleMap/types';

let eventStream: Subject<ExploreViewEvent> = new Subject();
export function provideEvent$(_eventStream: Subject<ExploreViewEvent>): void {
	eventStream = _eventStream;
}
export function event$(): Subject<ExploreViewEvent> {
	return eventStream;
}
export function emit(_e: ExploreViewEvent): void {
	eventStream.next(_e);
}
