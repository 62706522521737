/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ExperimentLaneVO, OccuranceVO, TemplateVO, TrackingCreationMethodVO } from 'ui-api';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import { LoadingIndicator, Stack, Text } from 'components';
import { usePromise } from 'utils/hooks/usePromise';
import { suppressSubmitOnEnter } from 'utils/form';
import { ReactElement, useState } from 'react';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';
import { useHistory } from 'url/hooks';
import { Form, Formik } from 'formik';
import { cloneDeep } from 'lodash';

import { createExperimentRequestFromTemplate } from './utils';
import PlaceholderSyncJob from './PlaceholderSyncJob';
import ValidationHandler from './ValidationHandler';
import MetadataSyncJob from './MetadataSyncJob';
import { ampli } from '../../../ampli';

export type UseTemplateFormData = TemplateVO & {
	placeholdersMap: Map<string, OccuranceVO[]>;
	placeholderValuesMap: Map<string, string>;
	variablesMap: Map<string, OccuranceVO[]>;
	variableValuesMap: Map<string, string>;
	newExperimentTags?: string[];
	environmentId: string;
	variables: string[];
	__originalLanes: ExperimentLaneVO[];
	__originalExperimentName: string;
	__originalHypothesis: string;
};

interface UseTemplateFormLoadingHandlerProps {
	experimentCreationMethod: TrackingCreationMethodVO;
	newExperimentTags?: string[];
	template?: TemplateVO;
	templateId: string;
	children: ReactElement;
}

export default function UseTemplateFormLoadingHandler({
	experimentCreationMethod,
	newExperimentTags,
	templateId,
	template,
	children,
}: UseTemplateFormLoadingHandlerProps): ReactElement {
	const templateResult = usePromise(
		() => (template ? Promise.resolve(template) : Services.templatesApi.getTemplate(templateId)),
		[templateId],
	);

	const { environmentsOfCurrentTeam: environments } = useEnvironments();
	if (environments.length === 0 || !templateResult.value) {
		return (
			<Stack
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<LoadingIndicator variant="xxLarge" color="slate" />
				<Text variant="largeStrong" color="neutral600">
					Preparing template...
				</Text>
			</Stack>
		);
	}

	return (
		<UseTemplateForm
			experimentCreationMethod={experimentCreationMethod}
			newExperimentTags={newExperimentTags}
			environmentId={environments[0]?.id}
			template={templateResult.value}
		>
			{children}
		</UseTemplateForm>
	);
}

interface UseTemplateFormProps {
	experimentCreationMethod: TrackingCreationMethodVO;
	newExperimentTags?: string[];
	environmentId: string;
	template: TemplateVO;
	children: ReactElement;
}

function UseTemplateForm({
	experimentCreationMethod,
	newExperimentTags,
	environmentId,
	template,
	children,
}: UseTemplateFormProps): ReactElement {
	const history = useHistory();
	const team = useTeam();

	const [__originalLanes] = useState(() => cloneDeep(template.lanes));
	const [__originalExperimentName] = useState(() => template.experimentName);
	const [__originalHypothesis] = useState(() => template.hypothesis);

	return (
		<Formik<UseTemplateFormData>
			initialValues={{
				...template,
				placeholderValuesMap: new Map(),
				variableValuesMap: new Map(),
				placeholdersMap: new Map(),
				variablesMap: new Map(),
				newExperimentTags: newExperimentTags,
				variables: [],
				environmentId,
				__originalLanes,
				__originalExperimentName,
				__originalHypothesis,
			}}
			// see <ValidationHandler /> for docs
			validateOnChange={false}
			validateOnBlur={false}
			onSubmit={(values) => {
				ampli.experimentTemplateUsedWizardStepCompleted({
					experiment_template_name: values.templateTitle,
					experiment_template_step: values.placeholders.length + 1,
					experiment_template_last_step: true,
					experiment_template_step_placeholder: values.placeholders[values.placeholders.length - 1]?.name,
					experiment_template_total_steps: values.placeholders.length + 1,
				});
				history.push('/experiments/edit/<new>/design', {
					preparedFormData: {
						actions: ['save', 'run'],
						initialValues: createExperimentRequestFromTemplate(
							values,
							team.id,
							experimentCreationMethod,
							newExperimentTags,
						),
						initialErrors: {},
					},
				});
			}}
		>
			<Form key={template.id} onKeyDown={suppressSubmitOnEnter} noValidate>
				<MetadataSyncJob />
				<PlaceholderSyncJob />
				<ValidationHandler />
				{children}
			</Form>
		</Formik>
	);
}
