/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { BasicButton, BasicButtonProps } from 'components';
import React from 'react';

import { ButtonIcon } from '../ButtonIcon';
import { IconComponent } from '../icons';

export interface ButtonLinkProps extends BasicButtonProps {
	icon?: IconComponent;
	muted?: boolean;
}

export const ButtonLink = React.forwardRef<HTMLButtonElement, ButtonLinkProps>(
	({ children, icon, disabled, muted, sx, ...props }, ref) => {
		const Icon = icon;
		return (
			<BasicButton
				ref={ref}
				sx={{
					display: 'inline-flex',
					alignItems: 'center',
					justifyContent: 'center',
					color: disabled || muted ? 'neutral400' : 'neutral600',
					background: 'transparent',
					cursor: disabled ? 'initial' : 'pointer',
					pointerEvents: disabled ? 'none' : 'initial',

					':hover:not(:disabled)': {
						'[data-button-link-icon]': {
							color: 'slateDark',
							bg: 'slateLight',
						},
					},

					':focus': {
						outline: 'none',
						color: 'neutral800',

						'[data-button-link-icon]': {
							color: 'neutral800',
							bg: 'neutral200',
						},
					},

					...sx,
				}}
				disabled={disabled}
				variant={'text.mediumStrong'}
				type={'button'}
				{...props}
			>
				{Icon ? (
					<ButtonIcon
						muted={muted}
						disabled={disabled}
						data-button-link-icon={true}
						as="span"
						tabIndex={-1}
						variant="small"
						mr="xxSmall"
						sx={{ ':hover': {}, ':focus': {} }}
					>
						<Icon />
					</ButtonIcon>
				) : null}
				{children}
			</BasicButton>
		);
	},
);
ButtonLink.displayName = 'ButtonLink';
