/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { IncidentSeverityVO, SystemStatusIncidentVO } from 'ui-api';
import { IconComponent, IconMaintenance } from 'components/icons';
import { Banner, BannerVariant, Link } from 'components';
import { ReactElement } from 'react';
import { hours } from 'utils/time';

interface IncidentBannerProps {
	incident?: SystemStatusIncidentVO;
}

const severityToVariantMapping: Record<IncidentSeverityVO, BannerVariant> = {
	UNDER_MAINTENANCE: 'info',
	DEGRADED_PERFORMANCE: 'warning',
	PARTIAL_OUTAGE: 'warning',
	MAJOR_OUTAGE: 'danger',
};

const severityToIconMapping: Record<IncidentSeverityVO, IconComponent | undefined> = {
	UNDER_MAINTENANCE: IconMaintenance,
	DEGRADED_PERFORMANCE: undefined,
	PARTIAL_OUTAGE: undefined,
	MAJOR_OUTAGE: undefined,
};

export default function IncidentBanner({ incident }: IncidentBannerProps): ReactElement | null {
	if (!incident) {
		return null;
	}

	return (
		<Banner
			id="incident-banner"
			variant={severityToVariantMapping[incident.severity]}
			Icon={severityToIconMapping[incident.severity]}
			title={incident.title}
			description={
				<span>
					{incident.message && incident.message != '' ? incident.message + ' | ' : ''}
					{incident.statusPage != null && (
						<>
							Learn more through our{' '}
							<Link href={incident.statusPage} external>
								status page
							</Link>
							.
						</>
					)}
				</span>
			}
			snooze={{
				id: 'active-incident',
				end: new Date(Date.now() + hours(8).getMillis()),
			}}
		/>
	);
}
