/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ReactElement, ReactNode } from 'react';
import { Container, Stack } from 'components';

import stones1PngContent from './images/stones_1.png';
import stones2PngContent from './images/stones_2.png';

interface AstroidScreenProps {
	description: string | ReactNode;
	children?: ReactNode;
	title: ReactNode;
	icon: ReactNode;
}

export default function AstroidScreen({ title, icon, description, children }: AstroidScreenProps): ReactElement {
	return (
		<Stack
			sx={{
				position: 'relative',
				size: 'large',
				alignItems: 'center',
				pt: 'xxxLarge',
			}}
		>
			<Container sx={{ position: 'absolute', top: 40, left: 0 }}>
				<img src={stones1PngContent} />
			</Container>
			<Container sx={{ position: 'absolute', bottom: -60, right: -60 }}>
				<img src={stones2PngContent} />
			</Container>

			{icon}
			<Stack direction="vertical" size="small" alignItems="center" width={700}>
				{title}
				{description}
			</Stack>
			{children}
		</Stack>
	);
}
