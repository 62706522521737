/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import React from 'react';

export const KillSwitchTooltip: React.VFC = () => (
	<>
		Running experiments is temporarily disallowed.
		<br />
		Allow them on the right to run this experiment
	</>
);
