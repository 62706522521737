/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, Container, FormikTextField, ModalContentV2, ModalHeaderV2, ModalV2, Stack } from 'components';
import SettingsGroupFieldVO from 'components/SettingsGroupFieldVO';
import { FieldVO, MetricQueryVO } from 'ui-api';
import { IconSaveDisc } from 'components/icons';
import { ReactElement, useState } from 'react';
import { Services } from 'services/services';
import { Form, Formik } from 'formik';
import { debounce } from 'lodash';

interface FormProps {
	query: MetricQueryVO;
}

interface MetricQueryEditorProps {
	metricQueryParameterDefinitions: FieldVO[];
	saveQuery: (query: MetricQueryVO) => void;
	query: MetricQueryVO;
	close: () => void;
}

export default function MetricQueryEditor({
	metricQueryParameterDefinitions,
	query,
	saveQuery,
	close,
}: MetricQueryEditorProps): ReactElement {
	const debouncedValidate = debounce(Services.experiments.validateMetricQuery, 500, { leading: true });
	const [initialValues] = useState({ query });

	return (
		<Formik<FormProps>
			initialValues={initialValues}
			onSubmit={(values: FormProps) => saveQuery(values.query)}
			validate={async (values: FormProps) => {
				const violations = await debouncedValidate(values.query);
				if (violations.length === 0) {
					return {};
				}
				const errors: Record<string, string> = {};
				for (let i = 0; i < violations.length; i++) {
					const { field, message } = violations[i];
					errors[field] = message;
				}
				return { query: errors };
			}}
			validateOnChange
			validateOnBlur={false}
		>
			{({ isSubmitting, isValid, submitForm }) => (
				<Form>
					<ModalV2 width={860}>
						<ModalHeaderV2 title="Define Metric Query" onClose={close} />
						<ModalContentV2>
							<Stack
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										submitForm();
									}
								}}
							>
								<FormikTextField
									variant="small"
									name="query.label"
									label={'Label'}
									placeholder="Descriptive label to identify this query"
									tabIndex={0}
								/>

								<SettingsGroupFieldVO
									fields={metricQueryParameterDefinitions}
									showDescriptionAsTooltip
									name="query.parameters"
									variant="small"
									sx={{ border: 0 }}
								/>

								<Container display="flex" justifyContent="flex-end" pt="small">
									<Button variant="secondary" onClick={close} mr="small">
										Cancel
									</Button>
									<Button id="save" onClick={submitForm} loading={isSubmitting} disabled={!isValid}>
										<IconSaveDisc variant="small" mr="xSmall" />
										Save Metric
									</Button>
								</Container>
							</Stack>
						</ModalContentV2>
					</ModalV2>
				</Form>
			)}
		</Formik>
	);
}
