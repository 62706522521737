/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

export default `
precision highp float;
precision highp int;

uniform float worldUnitInScreenSpace;
uniform float withBorder;
uniform float progress;

attribute float targetSize;
attribute float adviceMode;
attribute vec3 toPosition;

varying float fWorldUnitInScreenSpace;
varying float fTargetSize;
varying float fWithBorder;
varying float fAdviceMode;
varying vec2 vUv;

void main()	{
	vUv = uv;
	fAdviceMode = adviceMode;
	fTargetSize = targetSize;
	fWithBorder = withBorder;
	fWorldUnitInScreenSpace = worldUnitInScreenSpace;

	vec3 currentPosition = vec3(
		position.x + (toPosition.x - position.x) * progress,
		position.y + (toPosition.y - position.y) * progress,
		position.z
	);

	gl_Position = projectionMatrix * modelViewMatrix * vec4( currentPosition, 1.0 );
}
`;
