/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Select } from 'components';
import React from 'react';

const roleOwnerOption = { label: 'Owner', value: 'OWNER' };
const roleMemberOption = { label: 'Member', value: 'MEMBER' };
const roleOptions = [roleOwnerOption, roleMemberOption];

export const TeamRoleSelect: React.VFC<{ value?: string; onChange: (value: string) => void }> = ({
	value,
	onChange,
}) => {
	return (
		<Select
			variant={'chromeless'}
			value={roleOptions.find((o) => o.value === value)}
			width={150}
			options={roleOptions}
			onChange={(option) => {
				if (option && 'value' in option && option.value !== value) {
					onChange(option.value);
				}
			}}
		/>
	);
};
