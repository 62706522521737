/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { TeamLogoIcon } from 'components/icons/TeamLogoIcon';
import { Box, Colors } from '@steadybit/ui-components-lib';
import { ReactElement, ReactNode } from 'react';
import { Tooltip } from 'components';

interface TeamIconProps {
	variant?: 'small' | 'default';
	userIsMember: boolean;
	tooltip?: ReactNode;
	logoId: string;
	color: string;
}

export default function TeamIcon({
	variant = 'default',
	userIsMember,
	tooltip,
	logoId,
	color,
}: TeamIconProps): ReactElement {
	const width = variant === 'small' ? '24px' : '40px';
	const teamColor = Colors[color as keyof typeof Colors] || color || Colors.neutral400;

	const content = (
		<Box
			align="center"
			justify="center"
			style={{
				minWidth: width,
				maxWidth: width,
				minHeight: width,
				maxHeight: width,
				backgroundImage: userIsMember
					? undefined
					: `repeating-linear-gradient(120deg, ${teamColor}, ${teamColor} 4px, #ffffff44 4px, #fffff445 8px)`,
				backgroundColor: teamColor,
				borderRadius: variant === 'small' ? 'xxSmall' : 'xSmall',
			}}
		>
			<TeamLogoIcon variant={variant === 'small' ? 'small' : 'large'} color="neutral000" logoId={logoId} />
		</Box>
	);

	if (tooltip) {
		return <Tooltip content={tooltip}>{content}</Tooltip>;
	}
	return content;
}
