/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { stringify as locationStringify } from './stringifier';
import { getParamValue, setParamValue } from './utils';
import { useHistory, useLocation } from './hooks';
import { LocationWithUrlParams } from './type';
import { clone } from './clone';

export interface UrlParam<T> {
	pathSegment?: string;
	name: string;
	defaultValue: T;
}

export type GetUrlWithState = (newValues: Record<string, unknown>, locationMutator?: LocationMutator) => string;
type UpdateUrlWithState = (newValues: Record<string, unknown>, replace?: boolean) => void;
type LocationMutator = (location: LocationWithUrlParams<unknown>) => void;

const noopMutator: LocationMutator = (): void => {};

export function useUrlState(
	params: UrlParam<unknown>[],
): [Record<string, unknown>, GetUrlWithState, UpdateUrlWithState];
export function useUrlState<T>(params: UrlParam<unknown>[]): [T, GetUrlWithState, UpdateUrlWithState];
export function useUrlState(
	params: UrlParam<unknown>[],
): [Record<string, unknown>, GetUrlWithState, UpdateUrlWithState] {
	const location = useLocation();
	const history = useHistory();

	const values = params.map((param) => [param.name, getValue(location, param)]);

	return [
		Object.fromEntries(values),
		(newValues: Record<string, unknown>, locationMutator: LocationMutator = noopMutator) =>
			getUrlWithState(location, params, newValues, locationMutator),
		(newValues: Record<string, unknown>, replace = true) => {
			const url = getUrlWithState(location, params, newValues, noopMutator);
			if (replace) {
				history.replace(url);
			} else {
				history.push(url);
			}
		},
	];
}

// exported for testing
export function getValue<T>(location: LocationWithUrlParams<unknown>, param: UrlParam<T>): T {
	return getParamValue(location, param);
}

// exported for testing
export function getUrlWithState(
	location: LocationWithUrlParams<unknown>,
	params: UrlParam<unknown>[],
	newValues: Record<string, unknown>,
	locationMutator?: LocationMutator,
): string {
	const locationToMutate = clone(location);
	(locationMutator ?? noopMutator)(locationToMutate);

	for (const [name, value] of Object.entries(newValues)) {
		const param = params.find((param) => param.name === name);
		if (!param) {
			throw new Error(`Unknown parameter ${name}. Cannot set this value.`);
		}

		setParamValue(locationToMutate, param, value);
	}

	return locationStringify(locationToMutate) as string;
}
