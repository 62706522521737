/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Button, Container, Stack, Text } from 'components';
import { theme } from 'styles.v2/theme';
import React, { useMemo } from 'react';
import { Brand } from 'images';

import { IconWarningOpen } from '../../components/icons';
import { Services } from '../../services/services';

const DefaultError: React.VFC<{ error: string | null }> = ({ error }) => {
	const logoutUrl = useMemo(() => {
		if (window.location.hostname === 'platform.dev.steadybit.com') {
			return 'https://login.dev.steadybit.com/oidc/logout';
		} else if (window.location.hostname === 'platform.steadybit.com') {
			return 'https://login.steadybit.com/oidc/logout';
		} else if (window.location.hostname === 'local.platform.dev.steadybit.com') {
			return 'https://login.dev.steadybit.com/oidc/logout';
		} else {
			return 'https://' + window.location.hostname + '/oidc/logout';
		}
	}, []);

	const onLogoutClick = async (): Promise<void> => {
		console.log('error', error);
		if (error && error.indexOf('Google SSO is required for this domain') >= 0) {
			window.location.assign(logoutUrl);
		} else {
			await Services.auth.logout();
			window.location.assign('/');
		}
	};

	return (
		<Container
			sx={{
				position: 'absolute',
				top: 0,
				right: 0,
				left: 0,
				bottom: 0,

				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',

				backgroundColor: 'neutral100',
			}}
		>
			<Stack
				size="medium"
				sx={{
					alignItems: 'center',
					justifyContent: 'center',
					gap: '100px',
					mt: '-160px',
				}}
			>
				<Brand width="168px" height="38px" color="#0F054C" />

				<Stack
					size="medium"
					sx={{
						alignItems: 'center',
						padding: '56px 72px',
						backgroundColor: 'neutral000',
						borderRadius: '8px',
						boxShadow: theme.shadows.applicationLarge,
					}}
				>
					<Stack size="xSmall" direction="horizontal" alignItems="center">
						<IconWarningOpen color="coral" />
						<Text color="coral">{error}.</Text>
					</Stack>

					<Button
						variant="primaryLarge"
						type="submit"
						width="fit-content"
						height="42px"
						onClick={() => (window.location.href = window.location.origin)}
					>
						Go back to Steadybit
					</Button>

					<Stack size="xxSmall" direction="horizontal" alignItems="center">
						<Text>Troubles with login?</Text>
						<Text
							onClick={onLogoutClick}
							color="slate"
							variant="mediumStrong"
							data-cy="logout"
							sx={{
								cursor: 'pointer',
								':hover': {
									textDecoration: 'underline',
								},
							}}
						>
							Logout and change account
						</Text>
					</Stack>
				</Stack>
			</Stack>
		</Container>
	);
};

export default DefaultError;
