/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react-markdown/lib/react-markdown';
import Dropdown from 'components/Select/Dropdown/Dropdown';
import { FieldHookConfig, useField } from 'formik';
import { Container, Text } from 'components';
import { toString } from 'utils/numberFn';
import { useMemo } from 'react';

import { UnitField, UnitFieldProps, UnitFieldUnit } from '../UnitField';
import { hasError, smellsLikeEnvVar } from './utils';
import Variables from './Variables';

export type FormikUnitFieldProps = Omit<UnitFieldProps, 'unit' | 'css' | 'error' | 'touched' | 'units' | 'default'> & {
	unit: string | UnitFieldUnit;
	default?: number;
	pattern?: string;
};

export function FormikUnitField({
	unit,
	flex,
	my,
	mx,
	mt,
	mb,
	ml,
	mr,
	width,
	default: defaultVal,
	...props
}: FormikUnitFieldProps): ReactElement {
	const [field, meta, helper] = useField(props as FieldHookConfig<string>);
	const innerUnit = useMemo(() => (typeof unit === 'string' ? { value: unit, label: unit } : unit), [unit]);

	return (
		<Dropdown<string>
			onValueChanged={(value: string) => helper.setValue(smellsLikeEnvVar(value) ? value : toString(value))}
			renderComponent={({ setShowMenu, ref, onValueChanged }) => {
				return (
					<Container
						display={'flex'}
						flexDirection={'column'}
						ref={ref}
						my={my}
						mx={mx}
						mt={mt}
						mb={mb}
						ml={ml}
						mr={mr}
						width={width}
						flex={flex}
					>
						<UnitField
							{...field}
							{...meta}
							{...props}
							value={smellsLikeEnvVar(field.value) ? field.value : toString(field.value)}
							defaultText={toString(defaultVal)}
							unit={innerUnit}
							onFocus={() => setShowMenu(true)}
							onChange={(e) => {
								onValueChanged?.(e.target.value);
							}}
							hasError={hasError(meta)}
						/>
						{hasError(meta) ? (
							<Text mt={'xxSmall'} variant={'smallStrong'} color={'coral'} data-formik-error>
								{meta.error}
							</Text>
						) : null}
					</Container>
				);
			}}
		>
			{({ width, selectItem }) => <Variables width={width} onSelect={(v) => selectItem(v)} />}
		</Dropdown>
	);
}
