/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ContainerProps } from 'components';
import { ReactElement } from 'react';

import LimitReachedMessage from './LimitReachedMessage';

export default function MaxTeamsMessage(props: ContainerProps): ReactElement {
	return (
		<LimitReachedMessage
			title={'Team limit reached'}
			message={'You have reached the maximum number of teams.'}
			{...props}
		/>
	);
}
