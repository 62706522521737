/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import EditableLabel from 'components/EditableLabel/EditableLabel';
import { Container, Stack, TutorialTooltip } from 'components';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';
import { useField } from 'formik';

interface SidebarNameModuleProps {
	disabled?: boolean;
}

export default function SidebarNameModule({ disabled = false }: SidebarNameModuleProps): ReactElement {
	const [field, , { setValue, setTouched }] = useField<string>('name');

	return (
		<Stack direction="horizontal" alignItems="center" width="100%">
			<Container sx={{ borderBottom: disabled ? undefined : '2px solid ' + theme.colors.neutral300, width: '100%' }}>
				<EditableLabel
					value={field.value}
					onChange={(_name) => {
						setValue(_name);
						setTouched(true);
					}}
					disabled={disabled}
				/>
			</Container>
			<TutorialTooltip placement="bottom" showAlways light hideIcon hideSkip id="please_name_your_environment">
				Name your Environment in order to create one
			</TutorialTooltip>
		</Stack>
	);
}

interface SidebarNameModuleRendererProps {
	name: string;
	setName: (name: string) => void;
	disabled?: boolean;
}

export function SidebarNameModuleRenderer({
	name,
	setName,
	disabled = false,
}: SidebarNameModuleRendererProps): ReactElement {
	return (
		<Container sx={{ borderBottom: disabled ? undefined : '2px solid ' + theme.colors.neutral300 }}>
			<EditableLabel value={name} onChange={setName} disabled={disabled} />
		</Container>
	);
}
