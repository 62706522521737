/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Icon, getBackgroundStyle, getScaleFaktor } from 'pages/templates/FromTemplateModal/TemplateDetails/common';
import { ActionVO, TemplatePreviewLaneVO, TemplatePreviewStepVO } from 'ui-api';
import { ActionIcon } from 'hocs/ActionIcon';
import { IconLock } from 'components/icons';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

interface ExperimentPreviewProps {
	lanes: TemplatePreviewLaneVO[];
	actions: ActionVO[];
	disabled: boolean;
}

export default function ExperimentPreview({ lanes, actions, disabled }: ExperimentPreviewProps): ReactElement {
	const scale = getScaleFaktor(lanes, 220, 1, 2);

	return (
		<div
			style={{
				position: 'relative',

				minHeight: '104px',
				maxHeight: '104px',
				width: '218px',
				padding: '8px',

				borderRadius: '4px',
				border: '1px solid ' + theme.colors.neutral300,
				backgroundColor: theme.colors.neutral100,
				overflow: 'hidden',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '2px',
					overflow: 'hidden',
				}}
			>
				{lanes.slice(0, 5).map((lane, i) => (
					<Lane key={i} lane={lane} scale={scale} actions={actions} />
				))}
				{lanes.length > 5 && (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							height: '16px',
							borderRadius: '2px',
							backgroundColor: theme.colors.experimentAttack,
							width: '100px',
							padding: '0 4px',
						}}
					>
						<span
							style={{
								fontSize: '12px',
								color: theme.colors.neutral000,
							}}
						>
							{`+${lanes.length - 5} more`}
						</span>
					</div>
				)}
			</div>

			{disabled && (
				<IconLock
					variant="large"
					color="slate"
					sx={{
						position: 'absolute',
						top: '6px',
						right: '6px',
					}}
				/>
			)}
		</div>
	);
}

function Lane({
	lane,
	actions,
	scale,
}: {
	lane: TemplatePreviewLaneVO;
	scale: number;
	actions: ActionVO[];
}): ReactElement {
	return (
		<div
			style={{
				display: 'flex',
				gap: '2px',
			}}
		>
			{lane.steps.map((step, i) => {
				const resolvedAction = actions.find((action) => action.id === step.actionId);
				return <Step key={i} step={step} scale={scale} action={resolvedAction} />;
			})}
		</div>
	);
}

function Step({
	step,
	action,
	scale,
}: {
	step: TemplatePreviewStepVO;
	scale: number;
	action: ActionVO | undefined;
}): ReactElement {
	const stepWidth = step.durationInSeconds * scale - 8;
	const type = action?.kind || step.actionId;

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				height: '16px',
				padding: '0 4px',

				borderRadius: '2px',
				background: getBackgroundStyle(type),
				minWidth: `${stepWidth}px`,
				maxWidth: `${stepWidth}px`,
				overflow: 'hidden',
			}}
		>
			{action ? (
				<ActionIcon
					id={action.id}
					color="neutral000"
					style={{
						minWidth: '10px',
						maxWidth: '10px',
						minHeight: '10px',
						maxHeight: '10px',
					}}
				/>
			) : (
				<Icon
					type={type}
					style={{
						minWidth: '10px',
						maxWidth: '10px',
						minHeight: '10px',
						maxHeight: '10px',
						color: 'neutral000',
					}}
				/>
			)}
		</div>
	);
}
