/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React from 'react';

export type ContextualMenuProps = ContainerProps;

export const ContextualMenu = React.forwardRef<HTMLDivElement, ContextualMenuProps>(
	({ children, sx = {}, ...props }, ref) => (
		<Container
			display={'flex'}
			ref={ref}
			flexDirection={'column'}
			bg={'neutral000'}
			py={'small'}
			sx={{
				boxShadow: 'applicationMedium',
				border: '1px solid',
				borderColor: 'neutral200',
				borderRadius: 4,

				'&:empty': {
					display: 'none',
				},
				...sx,
			}}
			{...props}
		>
			{children}
		</Container>
	),
);
ContextualMenu.displayName = 'ContextualMenu';
