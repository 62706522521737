/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

export default `
precision highp float;
precision highp int;

uniform sampler2D textureMap;

varying vec2 vUv;
varying vec3 vColor;
varying float fTargetSize;
varying float fWorldUnitInScreenSpace;

void main()	{
  if( 2.0 * fWorldUnitInScreenSpace * fTargetSize < 28.0 ) {
    discard;
  }

  vec4 tex = texture2D( textureMap, vUv );
  tex.rgb = vColor;
	gl_FragColor = tex;
}
`;
