/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement, Suspense, lazy } from 'react';
import { usePromise } from 'utils/hooks/usePromise';
import { PageParams } from 'utils/hooks/usePage';
import { Services } from 'services/services';
import { Tooltip } from 'components';

import Card from './Card';

const Image = lazy(() => import('./images/Image'));

export default function FromImport({ onClick }: { onClick: () => void }): ReactElement {
	const hubsResult = usePromise(() => {
		return Services.hubApi.getHubs(new PageParams(0, 1));
	}, []);

	const hubsAvailable = hubsResult.value?.totalElements ? hubsResult.value?.totalElements > 0 : false;

	return (
		<Tooltip
			content={
				hubsResult.loading || hubsAvailable
					? ''
					: 'To import Templates from an external Hub, you need to connect one first'
			}
		>
			<div style={{ position: 'relative' }}>
				<Card
					title="Import"
					description="From a connected Hub"
					onClick={() => {
						if (hubsAvailable) {
							onClick();
						}
					}}
				>
					<Suspense fallback={<div />}>
						<Image type="fromImport" />
					</Suspense>
				</Card>

				{!hubsAvailable && (
					<div
						style={{
							position: 'absolute',
							top: '0',
							bottom: '0',
							left: '0',
							right: '0',
							cursor: 'not-allowed',
							pointerEvents: 'none',
							backgroundColor: 'rgba(255, 255, 255, 0.5)',
						}}
					/>
				)}
			</div>
		</Tooltip>
	);
}
