/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Container, Text } from 'components';
import { twoDigits } from 'utils/dateFns';
import { theme } from 'styles.v2/theme';
import { useMemo } from 'react';

import useWorkspaceSettings from '../useWorkspaceSettings';

function formatSeconds(onlySeconds: number): string {
	const hours = Math.floor(onlySeconds / 3600);
	const minutes = Math.floor((onlySeconds % 3600) / 60);
	const seconds = Math.floor(onlySeconds % 60);

	return [hours, minutes, seconds].map(twoDigits).join(':');
}

export default function Timeline(): React.ReactElement {
	const { workspaceWidth, pxPerSecond } = useWorkspaceSettings();

	const widthPerLabel = 100;
	const labels = useMemo(() => {
		const numLabels = Math.ceil(workspaceWidth / widthPerLabel);
		return Array.from(new Array(numLabels)).map((_, index) => {
			return formatSeconds(index * (widthPerLabel / pxPerSecond));
		});
	}, [workspaceWidth, pxPerSecond]);

	return (
		<Container
			sx={{
				position: 'absolute',
				top: 0,
				width: `${workspaceWidth}px`,
				height: 32,
				zIndex: 1,

				backgroundColor: 'neutral000',
				overflow: 'hidden',
				borderBottom: '1px solid ' + theme.colors.neutral300,
			}}
		>
			<Container
				sx={{
					position: 'absolute',
					top: 0,
					bottom: 0,
					left: '24px',
					right: 0,
					display: 'flex',
					alignItems: 'center',
				}}
			>
				{labels.map((label: string) => (
					<Text
						key={label}
						variant="xSmall"
						sx={{
							minWidth: widthPerLabel,
							maxWidth: widthPerLabel,
							height: 32,
							textAlign: 'center',
							transform: 'translateX(-50%)',
							color: 'neutral500',
							pt: 10,

							':before': {
								content: '""',
								position: 'absolute',
								display: 'block',
								top: 0,
								left: '50%',
								width: 1,
								height: 6,
								backgroundColor: 'neutral300',
							},
						}}
					>
						{label}
					</Text>
				))}
			</Container>
		</Container>
	);
}
