/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { IconArrowDropDown, IconArrowDropUp } from 'components/icons';
import { ReactElement, useState } from 'react';
import { Stack, Text } from 'components';
import { theme } from 'styles.v2/theme';

import ActionSubGroups from './ActionSubGroups';
import { ActionCategory } from './types';
import Actions from './Actions';

interface ActionGroupProps {
	initalExpanded?: boolean;
	group: ActionCategory;
}

export default function ActionGroup({ initalExpanded = false, group }: ActionGroupProps): ReactElement {
	const [expanded, setExpanded] = useState(initalExpanded);

	return (
		<div style={{ borderBottom: '1px solid ' + theme.colors.neutral200 }}>
			<Stack
				direction="horizontal"
				alignItems="center"
				sx={{
					justifyContent: 'space-between',
					px: 'small',
					py: 'small',
					cursor: 'pointer',

					'&:hover': {
						color: 'slate',
					},
				}}
				onClick={() => setExpanded(!expanded)}
			>
				<Text variant="smallStrong">{group.label}</Text>
				{expanded ? <IconArrowDropUp color="neutral600" /> : <IconArrowDropDown color="neutral600" />}
			</Stack>
			{expanded && (
				<Stack size="none" px="small" pb="small">
					<ActionSubGroups subGroups={group.subCategories} />
					<Actions actions={group.actions} />
				</Stack>
			)}
		</div>
	);
}
