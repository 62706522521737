/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import FileModal from 'components/Modal/predefined/fileModal';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { Services } from 'services/services';
import { json } from 'utils/mediaTypes';
import { ReactElement } from 'react';

type ExperimentFileModalProps = {
	title: string;
	experimentKey: string;
	onClose: () => void;
};

export default function ExperimentFileModal({ title, experimentKey, onClose }: ExperimentFileModalProps): ReactElement {
	const [fileContent] = useAsyncState(
		() => Services.experiments.fetchExperimentInApiFormat(experimentKey),
		[experimentKey],
	);

	return (
		<FileModal
			title={title}
			content={JSON.stringify(fileContent.value, null, 2)}
			lang="json"
			onClose={onClose}
			withDownload={{
				mediaType: json,
				fileName: 'experiment.json',
			}}
		/>
	);
}
