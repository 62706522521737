/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { AnimatePresence, motion } from 'framer-motion';
import { Container } from 'components';
import { ReactElement } from 'react';

import { IconExperiment, IconExperimentBlocked, IconRipple, IconRoundSpinnerWhite } from '../icons';
import { NumberIndicator } from '../NumberIndicator';
import { ButtonProps } from '../Button';

export interface ButtonActivityProps extends Omit<ButtonProps, 'css' | 'children'> {
	blocked?: boolean;
	error?: boolean;
	count?: number;
	ripple?: boolean;
}

function bg(blocked = false, running = false): string {
	if (blocked) {
		return 'neutral800';
	}
	if (running) {
		return 'slateMid';
	}
	return 'neutral600';
}

function color(blocked = false): string {
	if (blocked) {
		return 'neutral500';
	}
	return 'neutral000';
}

export function ButtonActivity({ count, error, blocked, ripple }: ButtonActivityProps): ReactElement {
	const running = Number(count ? count : 0) > 0;
	const animate = {
		initial: { opacity: 1, y: 0 },
		exit: { opacity: 0, y: 0 },
		animate: { scale: 2.1, opacity: 0, y: 0 },
		transition: { duration: 0.7 },
	};
	return (
		<Container
			display={'flex'}
			justifyContent={'center'}
			alignItems={'center'}
			minWidth={40}
			minHeight={40}
			maxWidth={40}
			maxHeight={40}
			color={color(blocked)}
			bg={bg(blocked, running)}
			sx={{
				borderRadius: 9999,
				':hover': { backgroundColor: running ? 'purple500' : 'neutral500' },
			}}
		>
			{blocked && (
				<IconExperimentBlocked
					sx={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: 40,
						height: 40,
					}}
				/>
			)}
			{running && (
				<IconRoundSpinnerWhite
					sx={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: 40,
						height: 40,
						zIndex: 2,
					}}
				/>
			)}
			<AnimatePresence>
				{ripple ? (
					<motion.div
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							zIndex: 1,
							willChange: 'opacity, transform, transition',
						}}
						{...animate}
					>
						<IconRipple
							sx={{
								width: 40,
								height: 40,
								zIndex: 1,
							}}
						></IconRipple>
					</motion.div>
				) : null}
			</AnimatePresence>
			{!blocked && running && count !== undefined && (
				<NumberIndicator
					variant={'small'}
					bg={'neutral000'}
					color={'neutral800'}
					sx={{
						position: 'absolute',
						left: '70.07%',
						right: '-10.07%',
						top: '-7.43%',
						bottom: '67.43%',
					}}
				>
					{count}
				</NumberIndicator>
			)}
			{!blocked && error && !running && (
				<NumberIndicator
					variant={'small'}
					bg={'coral'}
					color={'neutral000'}
					sx={{
						position: 'absolute',
						left: '70.07%',
						right: '-10.07%',
						top: '-7.43%',
						bottom: '67.43%',
					}}
				>
					!
				</NumberIndicator>
			)}
			<IconExperiment
				sx={{
					zIndex: 2,
				}}
			></IconExperiment>
		</Container>
	);
}
