/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {useCallback, useEffect, useState} from 'react';

export const useTabActive = (): number => {
	const [activeTabTimeStamp, setActiveTabTimeStamp] = useState(Date.now());

	const handleVisibilityChange = useCallback(() => {
		if (document.visibilityState === 'visible') {
			setActiveTabTimeStamp(Date.now());
		}
	}, []);

	useEffect(() => {
		document.addEventListener('visibilitychange', handleVisibilityChange);
		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, []);

	return activeTabTimeStamp;
};
