/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ExperimentSummaryVO, SteadybitEventVO } from 'ui-api';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import cached from 'utils/cached';

import { ExperimentApi } from './experimentApi';
import { TemplatesApi } from './templatesApi';
import { EventsApi } from './eventsApi';
import { HubsApi } from './hubsApi';

const relevantRefreshEvents = ['team.updated', 'team.deleted', 'team.created'];

export interface Permissions {
	experiments: ExperimentsPermissions;
	templates: TemplatesPermissions;
	hubs: HubsPermissions;
}

interface ExperimentsPermissions {
	canCreate: boolean;
}

interface TemplatesPermissions {
	canCreate: boolean;
	canUse: boolean;
}

interface HubsPermissions {
	canCreate: boolean;
}

interface ExperimentPermissions {
	canDeleteSchedule: boolean;
	canSchedule: boolean;
	canDelete: boolean;
	canEdit: boolean;
	canRun: boolean;
}

export class PermissionsApi {
	getExperimentsPermissions = cached(this.getExperimentsPermissionsInternal.bind(this));
	getTemplatesPermissions = cached(this.getTemplatesPermissionsInternal.bind(this));
	getHubsPermissions = cached(this.getHubsPermissionsInternal.bind(this));
	events$: BehaviorSubject<SteadybitEventVO | null>;
	experimentsApi: ExperimentApi;
	templatesApi: TemplatesApi;
	hubApi: HubsApi;

	constructor(events: EventsApi, experimentApi: ExperimentApi, templatesApi: TemplatesApi, hubApi: HubsApi) {
		this.experimentsApi = experimentApi;
		this.templatesApi = templatesApi;
		this.hubApi = hubApi;
		this.events$ = new BehaviorSubject<SteadybitEventVO | null>(null);

		events.events$.pipe(filter((event) => relevantRefreshEvents.includes(event.type))).subscribe((event) => {
			this.getExperimentsPermissions.invalidateCaches();
			this.events$.next(event);
		});
	}

	getGlobalPermissions$(teamId: string): Observable<Permissions> {
		return this.events$.pipe(switchMap(() => from(this.getGlobalPermissions(teamId))));
	}

	getExperimentPermissions(experiment: ExperimentSummaryVO): ExperimentPermissions {
		return {
			canDeleteSchedule: experiment._actions.includes('delete-schedule'),
			canSchedule: experiment._actions.includes('schedule'),
			canDelete: experiment._actions.includes('delete'),
			canEdit: experiment._actions.includes('edit'),
			canRun: experiment._actions.includes('run'),
		};
	}

	private async getGlobalPermissions(teamId: string): Promise<Permissions> {
		const experiments = await this.getExperimentsPermissions(teamId);
		const templates = await this.getTemplatesPermissions(teamId);
		const hubs = await this.getHubsPermissions();
		return {
			experiments,
			templates,
			hubs,
		};
	}

	private async getExperimentsPermissionsInternal(teamId: string): Promise<ExperimentsPermissions> {
		const actions = await this.experimentsApi.getPermissions(teamId);
		return {
			canCreate: actions.includes('create'),
		};
	}

	private async getTemplatesPermissionsInternal(teamId: string): Promise<TemplatesPermissions> {
		const actions = await this.templatesApi.getPermissions(teamId);
		return {
			canCreate: actions.includes('create'),
			canUse: actions.includes('use'),
		};
	}

	private async getHubsPermissionsInternal(): Promise<HubsPermissions> {
		const actions = await this.hubApi.getPermissions();
		return {
			canCreate: actions.includes('create'),
		};
	}

	defaultPermissions: Permissions = {
		experiments: {
			canCreate: false,
		},
		templates: {
			canCreate: false,
			canUse: false,
		},
		hubs: {
			canCreate: false,
		},
	};
}
