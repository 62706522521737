/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { Container, StyleProp } from 'components';
import { ExperimentLaneVO } from 'ui-api';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import { WorkspaceLaneMaker } from '../DragAndDropHandler';
import { useEditorSettings } from '../useEditorSettings';
import LaneMarker from './LaneMarker';
import GhostStep from './GhostStep';
import Steps from './Steps';
import './styles.css';

interface LaneProps {
	stepIdToError: Map<string, string[]>;
	lane: ExperimentLaneVO;
	index: number;
}

export default function Lane({ lane, index, stepIdToError }: LaneProps): ReactElement {
	const { mode } = useEditorSettings();
	return (
		<Droppable droppableId={WorkspaceLaneMaker + lane.id} direction="horizontal">
			{(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => {
				return (
					<Container
						id="lane"
						className="lane"
						ref={dropProvided.innerRef}
						sx={{
							...LANE_STYLE,
							minWidth: mode === 'experiment' ? 'calc(100vw - 276px - 77px)' : 'calc(100vw - 276px - 77px - 77px)',
							backgroundColor: dropSnapshot.isDraggingOver ? theme.colors.neutral200 : theme.colors.neutral100,
						}}
						{...dropProvided.droppableProps}
					>
						{index !== undefined && <LaneMarker>{String(index + 1)}</LaneMarker>}
						{dropSnapshot.isDraggingOver && lane.steps.length === 0 && <GhostStep />}
						<Steps steps={lane.steps} stepIdToError={stepIdToError} path={`lanes[${index}]`} />
					</Container>
				);
			}}
		</Droppable>
	);
}

export const LANE_STYLE: StyleProp = {
	display: 'flex',
	alignItems: 'center',
	paddingRight: '560px',
	maxWidth: '100000px',
	borderTop: '1px solid ' + theme.colors.neutral300,
	borderBottom: '1px solid transparent',
	overflowX: 'clip',
};
