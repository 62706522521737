/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import { IconEnvironment, IconEnvironmentGlobal, IconFunction } from 'components/icons';
import DropdownPill from 'components/Select/Dropdown/DropdownPill';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import { Spinner, Stack, TutorialTooltip } from 'components';
import React, { useEffect, useMemo, useState } from 'react';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { FormikErrors, useFormikContext } from 'formik';
import { Services } from 'services/services';
import { useUpdateEffect } from 'react-use';
import { TUTORIALS } from 'tutorials';

import ExperimentEnvironmentVariables from './experimentEnvironmentVariables';
import ExperimentEnvironmentDropDown from './ExperimentEnvironmentDropDown';
import { ExperimentFormValues } from '../../experiment';
import { hasFormikError } from '../../wizard/utils';

export const ExperimentSubHeaderEnvironments: React.VFC<{
	errors: FormikErrors<ExperimentFormValues>;
	disabled: boolean;
}> = ({ errors, disabled }) => {
	const formik = useFormikContext<ExperimentFormValues>();
	const { teamId, environmentId, variables } = formik.values;
	const { setFieldValue, setFieldTouched } = formik;
	const {
		environments,
		isGlobalSelected: globalChecked,
		selectedEnvironment,
		environmentsIsLoading,
	} = useEnvironments(environmentId);
	const [team] = useAsyncState(async () => (teamId ? Services.teams.fetchTeam(teamId) : undefined), [teamId]);
	const [, setEnvironmentName] = useState<string | undefined>(undefined);

	useUpdateEffect(
		//when teamId changes reset environmentId
		() => {
			setFieldValue('environmentId', '');
		},
		[teamId, setFieldValue],
	);

	useUpdateEffect(
		//when environmentId changes, update environment name
		() => {
			if (!environmentsIsLoading) {
				const environmentString = environments
					.filter((environment) => environmentId === environment.id)
					.map((a) => a.name)
					.join(', '); //legacy -> actual only one environment is selectable
				setEnvironmentName(environmentString || 'Select Environment');
			}
		},
		[environmentId, environments, setEnvironmentName, environmentsIsLoading],
	);

	useEffect(
		//when environmentId changes reset variables
		() => {
			(async () => {
				const variables = environmentId ? await Services.environments.fetchEnvironmentVariables(environmentId) : [];
				if (!formik.touched.variables && formik.values.variables) {
					setFieldValue('variables', [
						...formik.values.variables.filter((v) => !variables.some((_v) => _v.key === v.key)),
						...variables.map((v) => ({
							...v,
							value: formik.values.variables.find((_v) => _v.key === v.key)?.value || v.value || '',
						})),
					]);
				} else {
					setFieldValue('variables', variables);
					setFieldTouched('variables', true);
				}
			})();
		},
		[environmentId, setFieldValue],
	);

	const hasEnvironmentError = useMemo(() => hasFormikError(errors, ['environmentId']), [errors]);
	const hasVariablesError = useMemo(
		() => !hasEnvironmentError && hasFormikError(errors, ['variables']),
		[errors, hasEnvironmentError],
	);

	const environmentsColor = hasEnvironmentError ? 'coralDark' : 'neutral700';
	const environmentsBackground = hasEnvironmentError ? 'coral200' : 'neutral200';
	const variablesColor = hasVariablesError
		? 'coralDark'
		: hasEnvironmentError
		? 'neutral400'
		: variables.length > 0
		? 'purple800'
		: 'neutral700';
	const variablesBackground = hasVariablesError
		? 'coral200'
		: hasEnvironmentError
		? 'neutral200'
		: variables.length > 0
		? 'slateMidLight'
		: 'neutral200';

	return (
		<Stack flexShrink={0} direction={'horizontal'}>
			<Stack direction="horizontal" size="none">
				<DropdownPill
					backgroundColor={environmentsBackground}
					color={environmentsColor}
					disabled={disabled}
					value={selectedEnvironment?.name || ''}
					Icon={globalChecked ? IconEnvironmentGlobal : IconEnvironment}
				>
					{({ selectItem }) => (
						<DropdownContentFrame maxHeight={500} sx={{ borderRadius: '4px' }}>
							{environmentsIsLoading || team.loading ? (
								<Spinner variant={'small'} />
							) : (
								<ExperimentEnvironmentDropDown
									mt="xSmall"
									environments={environments}
									fieldName="environmentId"
									team={team.value}
									onlyPermittedByTeam
									loading={environmentsIsLoading}
									selectItem={selectItem}
								/>
							)}
						</DropdownContentFrame>
					)}
				</DropdownPill>
				{!environmentId && (
					<TutorialTooltip
						light
						hideIcon
						hideSkip
						showAlways
						placement="right"
						{...TUTORIALS.experiments.noEnvironmentSelected}
					/>
				)}
			</Stack>

			<DropdownPill
				id="environmentVariablesDropDownButton"
				backgroundColor={variablesBackground}
				color={variablesColor}
				disabled={disabled || hasEnvironmentError}
				Icon={IconFunction}
				value={variables.length === 0 ? 'No Environment variables' : `${variables.length} Environment variables`}
			>
				{() => (
					<DropdownContentFrame sx={{ borderRadius: '4px' }}>
						<ExperimentEnvironmentVariables />
					</DropdownContentFrame>
				)}
			</DropdownPill>
		</Stack>
	);
};
