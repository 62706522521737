/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Container, FormCheckbox, FormikCheckbox, Stack, Text } from 'components';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import React, { useEffect, useState } from 'react';
import { Services } from 'services/services';
import { useFormikContext } from 'formik';
import flatMap from 'lodash/flatMap';
import { capitalize } from 'lodash';

import { WebhookFormValues } from './webhookForm';

interface WebhookEventsInputProps {
	value?: string[];
	onChange?: (v: string[]) => void;
	name?: string;
	disabled?: boolean;
	required?: boolean;
	type: string;
}

const EVENT_LABELS: { [id: string]: string } = {
	'experiment.execution.created': 'Execution created',
	'experiment.execution.preflight': 'Execution preflight checks',
	'experiment.execution.failed': 'Execution failed',
	'experiment.execution.errored': 'Execution errored',
	'experiment.execution.canceled': 'Execution canceled',
	'experiment.execution.step-started': 'Execution step started',
	'experiment.execution.step-failed': 'Execution step failed',
	'experiment.execution.step-errored': 'Execution step errored',
	'experiment.execution.step-canceled': 'Execution step canceled',
	'experiment.execution.step-completed': 'Execution step completed',
	'experiment.execution.completed': 'Execution completed',
	'experiment.execution.deleted': 'Execution deleted',
	'killswitch.engaged': 'Engaged emergency stop / kill switch',
	'killswitch.disengaged': 'Disengaged emergency stop / kill switch',
};

export const WebhookEventsInput: React.VFC<WebhookEventsInputProps> = ({ type, disabled, onChange, value = [] }) => {
	const formik = useFormikContext<WebhookFormValues>();
	const [eventGroups] = useAsyncState(() => Services.webhooks.fetchAvailableEvents(type), [], []);
	const allSelected = Boolean(value?.includes('*'));
	const [events, setEvents] = useState<string[]>([]);

	useEffect(() => {
		const flattenedEvents = flatMap(eventGroups.value, (group) => group.events);
		setEvents(flattenedEvents);
		if (flattenedEvents.length === 1 && allSelected) {
			formik.setFieldValue('events', flattenedEvents);
		}
	}, [eventGroups.value]);

	const handleAllChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		onChange?.(event.target.checked ? ['*'] : []);
	};

	const handleEventChanged =
		(eventType: string) =>
		(event: React.ChangeEvent<HTMLInputElement>): void => {
			if (allSelected) {
				onChange?.(events.filter((e) => e !== eventType));
			} else {
				onChange?.(event.target.checked ? [...value, eventType] : value.filter((e) => e !== eventType));
			}
		};

	return (
		<Stack size={'xSmall'}>
			<Text variant={'mediumStrong'}>Events</Text>

			{events.length > 1 && (
				<FormCheckbox label={'All events'} checked={allSelected} onChange={handleAllChanged} disabled={disabled} />
			)}

			{eventGroups.value.map(({ category, events }) => (
				<Container
					key={category}
					size={'medium'}
					sx={{
						display: 'grid',
						gridTemplateColumns: '1fr 1fr',
						rowGap: 'xSmall',
						marginBottom: 'xSmall',
					}}
				>
					<Text>{capitalize(category)}</Text>
					<Container />
					{events.map((event) => (
						<FormikCheckbox
							key={event}
							name={event}
							label={EVENT_LABELS[event] ?? event}
							checked={allSelected || value?.includes(event)}
							onChange={handleEventChanged(event)}
							disabled={disabled}
						/>
					))}
				</Container>
			))}
		</Stack>
	);
};
