/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

// eslint-disable-next-line no-restricted-imports
import { NavLink as NavLinkInternal, NavLinkProps as NavLinkPropsInternal } from 'react-router-dom';
import { ForwardedRef, ReactElement, forwardRef } from 'react';

import { To, resolveTo } from './Redirect';
import { useHistory } from './hooks';

export interface NavLinkProps<T> extends Omit<NavLinkPropsInternal<T>, 'to'> {
	to: To;
}

export const NavLink = forwardRef(function NavLink<T>(
	{ to, href, ...otherProps }: NavLinkProps<T>,
	ref: ForwardedRef<HTMLAnchorElement>,
): ReactElement {
	const history = useHistory();
	const resolvedTo = resolveTo(history, to || href);
	return <NavLinkInternal {...otherProps} ref={ref} to={resolvedTo.href} isActive={() => resolvedTo.isActive} />;
});
