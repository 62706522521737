/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { FieldHookConfig, useField, useFormikContext } from 'formik';
import { ExperimentFormValues } from 'pages/experiments/experiment';
import EnvVariables from 'components/EnvVariables/EnvVariables';
import React, { ReactElement, useRef, useState } from 'react';
import { Container, ContainerProps, Text } from 'components';
import { AnimatePresence } from 'framer-motion';
import { usePopper } from 'react-popper';
import { useCombobox } from 'downshift';
import ReactDOM from 'react-dom';

import { containsUnknownVariable } from '../../utils/envVars';
import { IconAlertTriangle } from '../icons';
import { TextField } from '../TextField';
import { hasError } from './utils';

interface FormikUriWithEnvVarSupportProps extends ContainerProps {
	name: string;
}

export default function FormikUriWithEnvVarSupport({ width, name }: FormikUriWithEnvVarSupportProps): ReactElement {
	const [field, meta, { setValue, setTouched }] = useField({ type: 'select', name } as FieldHookConfig<string>);

	const variables = useFormikContext<ExperimentFormValues>().values.variables;

	const hasValidValue = !field.value || !containsUnknownVariable(field.value, variables);

	return (
		<Container display={'flex'} flexDirection={'column'} width={width}>
			<SelectComponent
				initialValue={field.value}
				setValue={(v) => {
					setValue(v);
					setTouched(true);
				}}
				hasValidValue={hasValidValue}
				isErroneous={hasError(meta)}
				variables={variables.map((variable) => variable.key)}
			/>
			{hasError(meta) ? (
				<Text mt={'xxSmall'} variant={'smallStrong'} color={'coral'} data-formik-error>
					{meta.error}
				</Text>
			) : null}
		</Container>
	);
}

interface SelectComponentProps {
	setValue: (value: string, shouldValidate?: boolean | undefined) => void;
	initialValue: string;
	variables: string[];
	isErroneous: boolean;
	hasValidValue: boolean;
}

function SelectComponent({
	initialValue,
	variables,
	isErroneous,
	hasValidValue,
	setValue,
}: SelectComponentProps): ReactElement {
	const { isOpen, openMenu, closeMenu, getMenuProps, getInputProps, getComboboxProps, setInputValue } = useCombobox({
		initialInputValue: initialValue,
		items: variables,
		onInputValueChange: ({ inputValue }) => {
			setValue(inputValue || '', true);
		},
	});

	const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const {
		styles,
		attributes,
		update: updatePopper,
	} = usePopper(inputRef.current, popperElement, {
		placement: 'bottom-start',
	});

	React.useEffect(() => {
		if (isOpen) {
			updatePopper?.();
		}
	}, [isOpen, updatePopper]);

	return (
		<Container tx={'combobox'} sx={{ position: 'relative' }} data-combobox>
			<div {...getComboboxProps()}>
				<TextField
					placeholder={''}
					hasError={isErroneous || !hasValidValue}
					iconRight={!hasValidValue && IconAlertTriangle}
					iconRightColor={'coral'}
					iconRightTooltip={'Undefined variable!'}
					onIconRightClick={() => {
						document.getElementById('environmentVariablesDropDownButton')?.click();
					}}
					{...getInputProps({
						ref: inputRef,
						onFocus: () => {
							if (!isOpen) {
								openMenu();
							}
						},
					})}
				/>
			</div>
			{ReactDOM.createPortal(
				<Container
					sx={{ zIndex: 30, position: 'absolute', top: '100%', left: 0 }}
					width={inputRef?.current?.clientWidth}
					style={styles.popper}
					{...attributes.popper}
					{...getMenuProps({
						ref: (element) => {
							if (element && !popperElement) {
								setPopperElement(element);
							}
						},
					})}
				>
					<AnimatePresence>
						{isOpen && (
							<Container
								display={'flex'}
								flexDirection={'column'}
								backgroundColor="neutral000"
								sx={{
									boxShadow: 'applicationMedium',
								}}
							>
								<EnvVariables
									variables={variables.map((v) => ({ key: v, value: v }))}
									onVariableClick={(key) => {
										setInputValue(`{{${key}}}`);
										closeMenu();
									}}
									withHelpText
								/>
							</Container>
						)}
					</AnimatePresence>
				</Container>,
				document.body,
			)}
		</Container>
	);
}
