/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ModalOverlay, ModalV2 } from 'components';
import { ReactElement } from 'react';

import TargetDetails, { TargetDetailsProps } from './TargetDetails';

interface TargetDetailsModalProps extends TargetDetailsProps {
	onClose: () => void;
}

export default function TargetDetailsModal({ onClose, ...props }: TargetDetailsModalProps): ReactElement {
	return (
		<ModalOverlay open onClose={onClose}>
			{({ close }) => (
				<ModalV2 width="auto" frameless>
					<TargetDetails {...props} width="90vw" maxWidth="1440px" minHeight={320} onClose={close} />
				</ModalV2>
			)}
		</ModalOverlay>
	);
}
