/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Colors, Flex, Text } from '@steadybit/ui-components-lib';
import { startCase, toLower } from 'lodash';
import { Checkbox } from 'components';
import { ReactElement } from 'react';

import DropDownItemWrapper from './DropDownItemWrapper';
import NotFoundResult from './NotFoundResult';

interface StatesProps {
	width: string | number | undefined;
	selectedStates: string[];
	states: string[];
	value: string;
	selectAll: (envIds: string[]) => void;
	selectItem: (envId: string) => void;
}

export default function States({
	selectedStates,
	states,
	width,
	value,
	selectItem,
	selectAll,
}: StatesProps): ReactElement {
	const filteredStates = states.filter((state) => {
		if (!value) {
			return true;
		}
		const matcher = value.toLowerCase();
		return state.toLowerCase().includes(matcher);
	});

	if (filteredStates.length === 0) {
		return <NotFoundResult title="No states found" width={width} />;
	}

	return (
		<>
			{filteredStates.length > 0 && (
				<>
					<Flex
						direction="horizontal"
						align="center"
						justify="end"
						spacing="xSmall"
						style={{ width: '-webkit-fill-available', py: 'xSmall', px: 'xSmall' }}
					>
						<Text
							type="smallStrong"
							style={{ color: Colors.slate, onHover: { cursor: 'pointer', textDecoration: 'underline' } }}
							onClick={() => selectAll(filteredStates)}
						>
							Select All
						</Text>
					</Flex>
					{filteredStates.map((state) => {
						return (
							<StateItem
								key={state}
								selected={selectedStates.indexOf(state) !== -1}
								state={state}
								onSelect={selectItem}
							/>
						);
					})}
				</>
			)}
		</>
	);
}

interface StateItemProps {
	selected: boolean;
	state: string;
	onSelect: (state: string) => void;
}
function StateItem({ selected, state, onSelect }: StateItemProps): ReactElement {
	return (
		<DropDownItemWrapper onClick={() => onSelect(state)}>
			<Checkbox checked={selected} onChange={() => {}} />
			<Text
				type={selected ? 'smallStrong' : 'smallMedium'}
				style={{ color: selected ? Colors.slate : Colors.neutral600 }}
			>
				{startCase(toLower(state))}
			</Text>
		</DropDownItemWrapper>
	);
}
