/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	dateAsUserTimezone,
	dateInTimeZone,
	formatDateWithTime,
	formatDateWithTimeInTimeZone,
	getDateAsLocale,
	getDayPeriod,
	Timezone,
	timeZones,
} from 'utils/dateFns';
import {
	Button,
	Combobox,
	ModalContentV2,
	ModalFooterV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	Select,
	Snackbar,
	Stack,
	Text,
	Toggle,
} from 'components';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { IconLightbulb, IconSaveDisc, IconTrash } from 'components/icons';
import { CreateExperimentScheduleVO, ExperimentScheduleVO } from 'ui-api';
import { ReactElement, ReactNode, useMemo, useState } from 'react';
import { usePromise } from 'utils/hooks/usePromise';
import Calendar from 'components/Calendar/Calendar';
import { Services } from 'services/services';
import { localeCompare } from 'utils/string';
import { useUser } from 'services/usersApi';
import { theme } from 'styles.v2/theme';
import cronstrue from 'cronstrue';

import DeleteScheduleExperimentModal from './DeleteScheduleExperimentModal';

interface ScheduleExperimentModalProps {
	schedule?: ExperimentScheduleVO;
	experimentKey?: string;
	onClose: () => void;
	disabled: boolean;
	title?: string;
}

type SelectOptionType = { value: string; label: string };
type DayPeriod = 'AM' | 'PM';

export default function ScheduleExperimentModal({
	title = 'Decide When to Run This Experiment',
	experimentKey,
	schedule,
	onClose,
	disabled,
}: ScheduleExperimentModalProps): ReactElement {
	const onceOption = { value: 'once', label: 'Only once' };
	const cronOption = { value: 'cron', label: 'Recurrently' };

	const user = useUser();
	const browserDefaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const usersTimeZone = timeZones.find((t) => t.timeZone === (user.timeZone || browserDefaultTimezone)) || timeZones[0];
	const [scheduleIsActivated, setScheduleIsActivated] = useState<boolean>(schedule?.enabled ?? true);
	const [allowParallel, setAllowParallel] = useState<boolean>(schedule?.allowParallel ?? true);
	const [scheduleIdToDelete, setScheduleIdToDelete] = useState<string | undefined>(undefined);
	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [cron, setCron] = useState<string>(schedule?.cron ?? '');
	const [timeZone, setTimeZone] = useState<Timezone>(
		timeZones.find((t) => t.timeZone === (schedule?.timezone || usersTimeZone?.timeZone)) || timeZones[0],
	);
	const [pickedDate, setPickedDate] = useState<Date>(() => {
		if (schedule?.startAt) {
			return dateAsUserTimezone(schedule.startAt);
		}

		const now = new Date();
		now.setMinutes(now.getMinutes() + 60);
		return now;
	});

	const [dayPeriod, setDayPeriod] = useState<DayPeriod>(getDayPeriod(pickedDate));

	const [selectedOption, setSelectedOption] = useState<SelectOptionType>(schedule?.cron ? cronOption : onceOption);

	const readableCron: string | undefined = useMemo(() => {
		try {
			return cronstrue.toString(cron);
		} catch (e) {
			return undefined;
		}
	}, [cron]);

	const readableCronUserTimeZone: string | undefined = useMemo(() => {
		try {
			const dateUserTimezone = dateInTimeZone(new Date(), usersTimeZone.timeZone);
			const dateScheduleTimeZone = dateInTimeZone(new Date(), timeZone.timeZone);
			const diffInMillis = dateUserTimezone.getTime() - dateScheduleTimeZone.getTime();
			const tzOffset = diffInMillis / 1000 / 3600;
			return cronstrue.toString(cron, { tzOffset });
		} catch (e) {
			return undefined;
		}
	}, [cron, timeZone, usersTimeZone]);

	const result = usePromise(() => {
		if (!readableCron) {
			return Promise.resolve(undefined);
		}
		return Services.experiments.validateCronExpression(cron, timeZone?.timeZone);
	}, [cron, timeZone?.timeZone]);
	const cronNextRun = result.value;
	const isValidDateSelected = selectedOption.value === 'once' || (selectedOption.value === 'cron' && !result.error);

	if (scheduleIdToDelete) {
		return <DeleteScheduleExperimentModal id={scheduleIdToDelete} onClose={onClose} />;
	}

	return (
		<ModalOverlay open centerContent onClose={onClose}>
			{({ close }) => (
				<ModalV2 slick withFooter width={750}>
					<ModalHeaderV2 title={title} onClose={close} />
					<ModalContentV2>
						<Stack size={'medium'} mb={'medium'}>
							<ScheduleContentBlock
								title={'Activate Schedule'}
								description={'Should this schedule be active or paused.'}
								setting={
									<Toggle
										type="radio"
										checked={scheduleIsActivated}
										onChange={async (e) => {
											setScheduleIsActivated(e.target.checked);
										}}
										disabled={disabled}
									/>
								}
							/>

							<ScheduleContentBlock
								title={'Run this Experiment'}
								description={
									'Should the schedule run the experiment only once or recurrently, based on a crontab expression?'
								}
								setting={
									<Select<SelectOptionType>
										width={150}
										options={[onceOption, cronOption]}
										value={selectedOption}
										disabled={disabled}
										onChange={(value) => value && setSelectedOption(value)}
										sx={{
											flex: '1 0 auto',
											borderRadius: '2px',
											border: '1px solid var(neutral300, #CCD4DD)',
											background: 'linear-gradient(180deg, neutral000 0%, neutral100 100%)',
										}}
									/>
								}
							>
								{selectedOption.value === 'once' ? (
									<ScheduleOnce
										pickedDate={pickedDate}
										setPickedDate={setPickedDate}
										dayPeriod={dayPeriod}
										setDayPeriod={setDayPeriod}
										disabled={disabled}
									/>
								) : (
									<ScheduleCronTab cron={cron} setCron={setCron} cronValid={isValidDateSelected} disabled={disabled} />
								)}
							</ScheduleContentBlock>

							{selectedOption.value === 'cron' && (
								<Stack direction={'horizontal'}>
									<ScheduleTimezoneBlock
										title={'Schedule Time zone'}
										timezone={
											<TimezoneCombobox
												value={timeZone}
												setValue={(timeZone) => {
													const match = timeZones.find((t) => timeZone === t.timeZone);
													setTimeZone(match || timeZones[0]);
												}}
											/>
										}
										userReadableTimezone={isValidDateSelected ? readableCron : undefined}
										nextRun={
											isValidDateSelected && cronNextRun
												? formatDateWithTimeInTimeZone(cronNextRun, timeZone.timeZone)
												: undefined
										}
									/>

									<ScheduleTimezoneBlock
										title={'Schedule Converted to Your Time Zone'}
										timezone={
											<Text variant="largeStrong" color="neutral700" sx={{ lineHeight: '42px' }}>
												{usersTimeZone.timeZone.replace('_', ' ')}
											</Text>
										}
										userReadableTimezone={isValidDateSelected ? readableCronUserTimeZone : undefined}
										nextRun={isValidDateSelected && cronNextRun ? formatDateWithTime(cronNextRun) : undefined}
									/>
								</Stack>
							)}
							{selectedOption.value === 'cron' && (
								<Stack
									direction="horizontal"
									size="xSmall"
									sx={{
										p: 'small',
										borderRadius: 8,
										alignItems: 'flex-start',
										border: '1px solid ' + theme.colors.neutral300,
									}}
								>
									<IconLightbulb variant="large" color="neutral600" />
									<Stack size="xSmall">
										<Text variant="largeStrong" color="neutral600">
											Crontab examples:
										</Text>
										<CronTabExample syntax="0 15 10 ? * *" description="= 10:15am every day" />
										<CronTabExample
											syntax="0 15 10 * * ? 2025"
											description="= 10:15am every day during the year 2025"
										/>
										<CronTabExample
											syntax="0 15 10 ? * MON-FRI"
											description="= 10:15am every Monday, Tuesday, Wednesday, Thursday and Friday"
										/>
									</Stack>
								</Stack>
							)}

							<ScheduleContentBlock
								title={'Run Experiments in Parallel'}
								description={
									'At the configured time, should the experiment run even when another experiment is already running?'
								}
								setting={
									<Toggle
										type="radio"
										checked={allowParallel}
										onChange={async (e) => {
											setAllowParallel(e.target.checked);
										}}
										disabled={disabled}
									/>
								}
							/>
						</Stack>
					</ModalContentV2>

					<ModalFooterV2>
						<Stack size="small" direction="horizontal" width={schedule ? '100%' : 'auto'}>
							{schedule && (
								<Button
									variant="chromelessSmall"
									color="neutral600"
									mr="auto"
									onClick={async () => {
										setScheduleIdToDelete(schedule?.id ?? '');
									}}
									disabled={disabled}
								>
									<IconTrash mr="xxSmall" />
									Delete Schedule
								</Button>
							)}
							<Button variant="secondary" onClick={close}>
								Cancel
							</Button>
							<Button
								variant="primary"
								disabled={!isValidDateSelected || isSaving || disabled}
								onClick={async () => {
									setIsSaving(true);

									try {
										if (schedule) {
											const scheduleToSave: CreateExperimentScheduleVO = {
												...schedule,
												allowParallel,
												enabled: scheduleIsActivated,
											};
											delete scheduleToSave.startAt;
											delete scheduleToSave.cron;
											delete scheduleToSave.timezone;
											if (selectedOption.value === 'once') {
												scheduleToSave.startAt = userDateAsBrowserDate(pickedDate);
											} else {
												scheduleToSave.timezone = timeZone?.timeZone;
												scheduleToSave.cron = cron;
											}
											await Services.experiments.updateExperimentSchedule(scheduleToSave);
										} else {
											const scheduleToSave: CreateExperimentScheduleVO = {
												experimentKey: experimentKey || '',
												enabled: scheduleIsActivated,
												allowParallel,
											};
											if (selectedOption.value === 'once') {
												scheduleToSave.startAt = userDateAsBrowserDate(pickedDate);
											} else {
												scheduleToSave.cron = cron;
												scheduleToSave.timezone = timeZone?.timeZone;
											}
											await Services.experiments.scheduleExperiment(scheduleToSave);
										}
										close();
									} catch (e) {
										Snackbar.error('Failed to schedule experiment');
										setIsSaving(false);
									}
								}}
							>
								{isSaving ? (
									<LoadingIndicator color="neutral000" sx={{ mr: 'xSmall' }} />
								) : (
									<IconSaveDisc mr="xSmall" />
								)}
								Save Schedule
							</Button>
						</Stack>
					</ModalFooterV2>
				</ModalV2>
			)}
		</ModalOverlay>
	);
}

function ScheduleOnce({
	pickedDate,
	setPickedDate,
	dayPeriod,
	setDayPeriod,
	disabled,
}: {
	pickedDate: Date;
	setPickedDate: (date: Date) => void;
	dayPeriod: DayPeriod;
	setDayPeriod: (dayPeriod: DayPeriod) => void;
	disabled: boolean;
}): ReactElement {
	const userTimezoneNow = dateAsUserTimezone(new Date());
	const isPickedDateValid = pickedDate.getTime() > userTimezoneNow.getTime();
	return (
		<Stack size="small" alignItems="center" mt="large" mb="medium">
			<Stack
				sx={{
					py: 'small',
					px: 'medium',
					borderRadius: 8,
					border: '1px solid ' + theme.colors.neutral300,
					alignItems: 'center',
					background: 'neutral000',
				}}
			>
				<Calendar
					defaultActiveStartDate={pickedDate}
					defaultValue={pickedDate}
					value={pickedDate}
					tileDisabled={() => disabled}
					onChange={(e) => {
						if (e instanceof Date) {
							setPickedDate(e);
							setDayPeriod('AM');
						}
					}}
				/>
				<Stack size="xxSmall" alignItems="center">
					<Text variant="small" color="neutral600" fontWeight={600}>
						Pick a time:
					</Text>

					<Stack direction="horizontal" size="xSmall" alignItems="center">
						<Numbers
							numberOfItems={12}
							startAt={1}
							value={String(
								pickedDate.getHours() > 12
									? pickedDate.getHours() - 12
									: pickedDate.getHours() === 0
										? 12
										: pickedDate.getHours(),
							).padStart(2, '0')}
							setValue={(hours) => {
								const date = new Date(pickedDate);
								// special case, 12AM
								if (hours === '12' && dayPeriod === 'AM') {
									date.setHours(0);
								} else {
									date.setHours(Number(hours) + (dayPeriod === 'PM' ? 12 : 0));
								}
								setPickedDate(date);
							}}
							disabled={disabled}
						/>
						<Text variant="small" color="neutral600" fontWeight={600}>
							:
						</Text>
						<Numbers
							numberOfItems={60}
							value={String(pickedDate.getMinutes()).padStart(2, '0')}
							setValue={(minutes) => {
								const date = new Date(pickedDate);
								date.setMinutes(Number(minutes));
								setPickedDate(date);
							}}
							disabled={disabled}
						/>
						<Text variant="small" color="neutral600" fontWeight={600}>
							:
						</Text>
						<Numbers
							numberOfItems={60}
							value={String(pickedDate.getSeconds()).padStart(2, '0')}
							setValue={(seconds) => {
								const date = new Date(pickedDate);
								date.setSeconds(Number(seconds));
								setPickedDate(date);
							}}
							disabled={disabled}
						/>
						<Text variant="small" color="neutral600" fontWeight={600}>
							:
						</Text>
						<Select<SelectOptionType>
							variant="small"
							options={[
								{
									value: 'AM',
									label: 'AM',
								},
								{
									value: 'PM',
									label: 'PM',
								},
							]}
							value={{
								value: dayPeriod,
								label: dayPeriod,
							}}
							onChange={(value) => {
								const newDayPeriod = value?.value === 'PM' ? 'PM' : 'AM';
								if (dayPeriod === newDayPeriod) {
									return;
								}
								setDayPeriod(newDayPeriod);
								const dateCopy = new Date(pickedDate);
								dateCopy.setHours(Number(dateCopy.getHours()) + (newDayPeriod === 'PM' ? 12 : -12));
								setPickedDate(dateCopy);
							}}
							disabled={disabled}
						/>
					</Stack>
				</Stack>
			</Stack>

			{!isPickedDateValid ? (
				<Stack direction="horizontal" size="small" alignItems="center">
					<Text color="neutral600">You need to select a date in the future</Text>
				</Stack>
			) : (
				<Stack direction="horizontal" size="small" alignItems="center"></Stack>
			)}
		</Stack>
	);
}

function ScheduleCronTab({
	cron,
	setCron,
	cronValid,
	disabled,
}: {
	cron: string;
	setCron: (cron: string) => void;
	cronValid: boolean | undefined;
	disabled: boolean;
}): ReactElement {
	const [cronParts, setCronParts] = useState<string[]>(cron.split(' '));

	const widths: number[] = [0, 1, 2, 3, 4, 5, 6].map((i) => {
		const part = cronParts[i] || '';
		return Math.min(80, Math.max(44, 16 + 14 * part.length));
	});

	return (
		<Stack size="small" alignItems="center">
			<Stack alignItems="center">
				<Stack
					size="none"
					alignItems="center"
					sx={{
						bg: 'neutral100',
						borderRadius: 8,
						width: 444,
					}}
				>
					<Stack direction="horizontal" size="xSmall" alignItems="center">
						{[0, 1, 2, 3, 4, 5, 6].map((i) => {
							const part = cronParts[i] || '';

							return (
								<input
									key={i}
									id={`cron-part-${i}`}
									type="text"
									value={part}
									disabled={disabled}
									onChange={(e) => {
										let newValue = e.target.value;
										if (newValue.endsWith(' ')) {
											const nextInput = document.getElementById(`cron-part-${i + 1}`);
											if (nextInput) {
												nextInput.focus();
											}
										} else if (!newValue) {
											const nextInput = document.getElementById(`cron-part-${i - 1}`);
											if (nextInput) {
												nextInput.focus();
											}
										}

										newValue = newValue.trim();

										const newCronParts = [...cronParts];
										newCronParts[i] = newValue;
										setCronParts(newCronParts);
										setCron(newCronParts.join(' '));
									}}
									onKeyDown={(e) => {
										const caretPosition = e.currentTarget.selectionStart;
										if (caretPosition != null && e.key === 'ArrowLeft') {
											const cursorPositionAfterKey = caretPosition - 1;
											if (cursorPositionAfterKey === -1) {
												const prevInput = document.getElementById(`cron-part-${i - 1}`) as HTMLInputElement | null;
												if (prevInput) {
													prevInput.focus();
													prevInput.setSelectionRange(prevInput.value.length, prevInput.value.length);
												}
											}
										} else if (caretPosition != null && e.key === 'ArrowRight') {
											const cursorPositionAfterKey = caretPosition + 1;
											if (cursorPositionAfterKey > part.length) {
												const nextInput = document.getElementById(`cron-part-${i + 1}`) as HTMLInputElement | null;
												if (nextInput) {
													nextInput.focus();
													nextInput.setSelectionRange(0, 0);
												}
											}
										}

										if (e.key === 'Backspace') {
											const prevInput = document.getElementById(`cron-part-${i - 1}`);
											if (!part && prevInput) {
												prevInput.focus();
											}
										}
									}}
									onPaste={(e) => {
										try {
											const newParts = e.clipboardData.getData('text/plain').split(' ');
											const newCronParts = [...cronParts];
											newCronParts.splice(i, newParts.length, ...newParts);
											setCronParts(newCronParts);
											setCron(newCronParts.join(' '));

											e.preventDefault();
										} catch {
											// ignore
										}
									}}
									style={{
										maxWidth: widths[i],
										fontFamily: 'monospace !important',
										backgroundColor: 'transparent',
										border: '1px solid ' + (part ? theme.colors.slate : theme.colors.neutral300),
										color: theme.colors.neutral800,
										textAlign: 'center',
										fontSize: '24px',
										fontWeight: 'regular',
										paddingLeft: '0px',
										paddingRight: '0px',
										paddingTop: '12px',
										paddingBottom: '12px',
										borderRadius: '4px',
										outline: 'none',
									}}
								/>
							);
						})}
					</Stack>

					<Stack direction="horizontal" size="xSmall" alignItems="flex-start">
						<Text variant="smallCode" as="span" color="neutral600" textAlign="center" width={widths[0] + 2}>
							second
						</Text>
						<Text variant="smallCode" as="span" color="neutral600" textAlign="center" width={widths[1] + 2}>
							minute
						</Text>
						<Text variant="smallCode" as="span" color="neutral600" textAlign="center" width={widths[2] + 2}>
							hour
						</Text>
						<Text variant="small" as="span" color="neutral600" textAlign="center" width={widths[3] + 2}>
							<Text variant="small" color="neutral600">
								day
							</Text>
							<Text variant="small" color="neutral600">
								(month)
							</Text>
						</Text>
						<Text variant="smallCode" as="span" color="neutral600" textAlign="center" width={widths[4] + 2}>
							month
						</Text>
						<Text variant="small" as="span" color="neutral600" textAlign="center" width={widths[5] + 2}>
							<Text variant="small" color="neutral600">
								day
							</Text>
							<Text variant="small" color="neutral600">
								(week)
							</Text>
						</Text>
						<Text variant="smallCode" as="span" color="neutral600" textAlign="center" width={widths[6] + 2}>
							year
						</Text>
					</Stack>
				</Stack>
				<Text variant="mediumCode" textAlign="center" color="neutral600">
					{cronValid === true || (cronValid === false && cron === '') ? (
						<>&nbsp;</>
					) : cronValid === undefined ? (
						'validating...'
					) : (
						'Invalid CronTab expression'
					)}
				</Text>
			</Stack>
		</Stack>
	);
}

interface CronTabExampleProps {
	syntax: string;
	description: string;
}

function CronTabExample({ syntax, description }: CronTabExampleProps): ReactElement {
	return (
		<Stack direction="horizontal" size="xSmall" alignItems="center">
			<Text
				variant="smallCode"
				sx={{
					color: 'neutral600',
					bg: 'neutral100',
					px: 'xSmall',
					py: 'xxSmall',
					borderRadius: 4,
				}}
			>
				{syntax}
			</Text>
			<Text variant="smallCode" color="neutral600">
				{description}
			</Text>
		</Stack>
	);
}

function userDateAsBrowserDate(userDate: Date): Date {
	const dateNow = new Date();
	const now = getDateAsLocale(dateNow);
	const userNow = dateAsUserTimezone(dateNow);
	const diffInMillis = now.getTime() - userNow.getTime();
	return new Date(userDate.getTime() + diffInMillis);
}

interface NumberSelectorProps {
	numberOfItems: number;
	value: string;
	setValue: (value: string) => void;
	startAt?: number;
	disabled: boolean;
}

function Numbers({ startAt = 0, numberOfItems, value, setValue, disabled }: NumberSelectorProps): ReactElement {
	const options = useMemo(() => {
		const options: SelectOptionType[] = [];
		for (let i = 0; i < numberOfItems; i++) {
			options.push({
				value: String(startAt + i),
				label: String(startAt + i).padStart(2, '0'),
			});
		}
		return options;
	}, [startAt, numberOfItems]);

	return (
		<Select<SelectOptionType>
			variant="small"
			options={options}
			value={{
				value,
				label: value,
			}}
			onChange={(value) => setValue(value?.value || '')}
			disabled={disabled}
		/>
	);
}

function TimezoneCombobox({ value, setValue }: { value: Timezone; setValue: (value: string) => void }): ReactElement {
	const timezonesOption = useMemo(
		() =>
			timeZones
				.slice()
				.sort((t1, t2) => localeCompare(t1.timeZone, t2.timeZone))
				.map(({ timeZone, offset }) => ({
					label: `${timeZone.replace('_', ' ')} (UTC ${offset})`,
					value: timeZone,
				})),
		[timeZones],
	);
	return (
		<Combobox
			width={'255px'}
			label="Time Zone"
			name="timeZone"
			autoComplete="on"
			placeholder="Time Zone"
			onChange={(_timeZone) => {
				if (_timeZone) {
					setValue(_timeZone.value);
				}
			}}
			value={
				value ? { label: `${value.timeZone.replace('_', ' ')} (UTC ${value.offset})`, value: value.timeZone } : null
			}
			options={timezonesOption}
			sx={{
				borderColor: 'neutral300',
				boxShadow: 'none',
			}}
		/>
	);
}

function ScheduleContentBlock({
	title,
	description,
	setting,
	children,
}: {
	title: string;
	description: string;
	setting: ReactNode;
	children?: ReactNode;
}): ReactElement {
	return (
		<Stack
			size="xSmall"
			sx={{
				alignItems: 'stretch',
				p: 'small',
				background: 'neutral100',
				borderRadius: '8px',
			}}
		>
			<Stack
				size={'medium'}
				direction={'horizontal'}
				sx={{
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<Stack size={'xxSmall'}>
					<Text variant="mediumStrong" color="neutral800">
						{title}
					</Text>
					<Text variant="small" color="neutral600">
						{description}
					</Text>
				</Stack>
				{setting}
			</Stack>
			{children}
		</Stack>
	);
}

function ScheduleTimezoneBlock({
	title,
	timezone,
	userReadableTimezone,
	nextRun,
}: {
	title: string;
	timezone: ReactNode;
	userReadableTimezone: string | undefined;
	nextRun?: string;
}): ReactElement {
	return (
		<Stack
			size="xSmall"
			sx={{
				alignItems: 'stretch',
				p: 'small',
				background: 'neutral100',
				borderRadius: '8px',
				flex: '1',
			}}
		>
			<Text variant="medium" as="span" color="neutral600">
				{title}
			</Text>
			{timezone}
			<Text variant="medium" as="span" color="neutral800">
				{userReadableTimezone ? userReadableTimezone : <>&nbsp;</>}
			</Text>
			<Text variant="medium" as="span" color="neutral800">
				{nextRun ? (
					<>
						<Text variant="medium" fontWeight={'bold'} as="span" color="neutral800">
							Next run
						</Text>
						&nbsp;{nextRun}
					</>
				) : (
					<>&nbsp;</>
				)}
			</Text>
		</Stack>
	);
}
