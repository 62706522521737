/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { Pill, Tooltip } from 'components';
import { ReactElement } from 'react';

import { DeleteIcon } from '../FromTemplateModal/TemplateDetails/common';

interface TagsProps {
	small?: boolean;
	tags: string[];
	onDelete?: (tag: string) => void;
	onClick?: (tag: string) => void;
}

export default function Tags({ tags, small, onClick, onDelete }: TagsProps): ReactElement {
	return (
		<div
			style={{
				display: 'flex',
				flexWrap: 'wrap',
				gap: '4px',
			}}
		>
			{tags.map((tag) => (
				<Tag
					key={tag}
					small={small}
					onClick={onClick ? () => onClick(tag) : undefined}
					onDelete={onDelete ? () => onDelete(tag) : undefined}
				>
					{tag}
				</Tag>
			))}
		</div>
	);
}

interface TagProps {
	children: string;
	small?: boolean;
	onClick?: () => void;
	onDelete?: () => void;
}

export function Tag({ small, children, onClick, onDelete }: TagProps): ReactElement {
	const content = clampTag(children);
	const pill = (
		<Pill
			backgroundColorOnHover="purple200"
			backgroundColor="purple100"
			color="slate"
			sx={{
				width: 'fit-content',
				borderRadius: '4px',
				fontSize: small ? '10px' : '12px',
				p: small ? '3px 6px' : '8px 8px',
				...textEllipsis,
			}}
			onClick={onClick}
		>
			{content}
			{onDelete ? <DeleteIcon color="slate" onClick={onDelete} /> : <></>}
		</Pill>
	);
	if (content.indexOf('…') !== -1) {
		return (
			<Tooltip content={children}>
				<div>{pill}</div>
			</Tooltip>
		);
	}
	return pill;
}

function clampTag(tag: string): string {
	if (tag.length > 10) {
		return tag.slice(0, 10) + '…';
	}
	return tag;
}
