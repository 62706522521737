/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import DropdownButton from 'components/Select/Dropdown/DropdownButton';
import Labels from 'components/Select/Dropdown/presets/Labels';
import { ReactElement, ReactNode } from 'react';
import { UnitFieldUnit } from 'components';

export function ControlWrapper({ children }: { children: ReactNode }): ReactElement {
	return (
		<div
			style={{
				display: 'flex',
				width: '100%',
				alignItems: 'center',
			}}
		>
			{children}
		</div>
	);
}

interface OptionsProps {
	onChange: (v: string) => void;
	options: UnitFieldUnit[];
	width?: string | number;
	disabled?: boolean;
	value: string;
}

export function Options({ value, options, onChange, disabled, width }: OptionsProps): ReactElement {
	return (
		<DropdownButton
			width={width}
			variant="secondary"
			value={options.find((o) => o.value === value)?.label ?? value}
			onValueChanged={onChange}
			disabled={disabled}
			sx={{
				borderRadius: '0 4px 4px 0',
				minWidth: 'fit-content',
			}}
		>
			{({ value, selectItem, width }) => (
				<DropDownContent value={value} selectItem={selectItem} options={options} width={width} />
			)}
		</DropdownButton>
	);
}

interface DropDownContentProps {
	selectItem: (value: string) => void;
	width: string | number | undefined;
	options: UnitFieldUnit[];
	value?: string;
}

function DropDownContent({ value, options, selectItem, width }: DropDownContentProps): ReactElement {
	return (
		<DropdownContentFrame>
			<Labels<string>
				width={width}
				type="strict"
				maxHeight={300}
				onSelect={({ id }) => selectItem(id)}
				labels={options.map(({ label, value }) => ({
					id: value,
					label,
				}))}
				selectedId={value}
			/>
		</DropdownContentFrame>
	);
}
