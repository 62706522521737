/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	GetTargetAttributeKeysRequestVO,
	GetTargetAttributeKeysResponseVO,
	GetTargetAttributeValuesRequestVO,
	GetTargetAttributeValuesResponseVO,
} from 'ui-api';
import { seconds } from 'utils/time';
import cached from 'utils/cached';
import axios from 'axios';

export class EditorApi {
	fetchTargetAttributeKeys = cached(this.fetchTargetAttributeKeysInternal.bind(this), seconds(15).getMillis());
	fetchTargetAttributeValues = cached(this.fetchTargetAttributeValuesInternal.bind(this), seconds(15).getMillis());

	private async fetchTargetAttributeKeysInternal(request: GetTargetAttributeKeysRequestVO): Promise<string[]> {
		return (await axios.post<GetTargetAttributeKeysResponseVO>('/ui/targets/attribute_keys', request)).data.content;
	}

	private async fetchTargetAttributeValuesInternal(request: GetTargetAttributeValuesRequestVO): Promise<string[]> {
		return (await axios.post<GetTargetAttributeValuesResponseVO>('/ui/targets/attribute_values', request)).data.content;
	}
}
