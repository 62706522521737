/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ContainerProps, Stack, Text } from 'components';
import { ReactElement, ReactNode } from 'react';

interface WithDescriptionProps extends ContainerProps {
	description: string | ReactNode;
	children: ReactNode | ReactNode[];
}

export default function WithDescription({ description, children, ...props }: WithDescriptionProps): ReactElement {
	return (
		<Stack size="xSmall" {...props}>
			<Text variant="smallStrong" color="neutral600">
				{description}
			</Text>
			{children}
		</Stack>
	);
}
