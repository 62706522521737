/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ActionKindVO, ExperimentStepRadiusVO, QuantityRestrictionVO } from 'ui-api';
import { FormikError, hasError, Text, Tooltip, UnitField } from 'components';
import { Box, Colors, Flex, Spacings } from '@steadybit/ui-components-lib';
import { useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import { ChangeEvent, ReactElement, useCallback, useMemo } from 'react';
import { useTargetDefinition } from 'targets/useTargetDefinition';
import { listPlaceholders } from 'templates/utils';
import { toInt, toString } from 'utils/numberFn';
import Slider from 'components/Slider2/Slider';
import { IconTarget } from 'components/icons';
import { useUpdateEffect } from 'react-use';
import { toTitleCase } from 'utils/string';
import { useFormikContext } from 'formik';
import { getLabel } from 'i18n/label';

import { ExperimentFormValues } from '../../experiment';
import { useBlastRadiusCount } from '../utils';
import BlastCanvas from './BlastCanvas';

const BLAST_UNIT_PERCENTAGE = { label: '%', value: 'percentage' };
const BLAST_UNIT_MAXIMUM = { label: '#', value: 'maximum' };
const BLAST_UNITS = [BLAST_UNIT_PERCENTAGE, BLAST_UNIT_MAXIMUM];

export default function StepTargetsBlastRadius({
	quantityRestriction,
	environmentId,
	blastRadius,
	targetType,
	actionKind,
	disabled,
	stepPath,
}: {
	quantityRestriction?: QuantityRestrictionVO;
	blastRadius: ExperimentStepRadiusVO;
	actionKind?: ActionKindVO;
	stepPath: string | null;
	environmentId: string;
	disabled?: boolean;
	targetType: string;
}): ReactElement {
	const formik = useFormikContext<ExperimentFormValues>();
	const { setFieldValue } = formik;
	const mode = useMemo(
		() =>
			Number.isInteger(blastRadius.percentage)
				? 'percentage'
				: Number.isInteger(blastRadius.maximum)
					? 'maximum'
					: 'percentage',
		[blastRadius.percentage, blastRadius.maximum],
	);

	const targetDefinition = useTargetDefinition(targetType);
	const variables = useFormikContext<ExperimentFormValues>().values.variables;
	const blastRadiusCount = useBlastRadiusCount(blastRadius, environmentId, variables);
	const blastRadiusTargetLabel = useMemo((): string => {
		if (targetDefinition.value) {
			return toTitleCase(getLabel(targetDefinition.value.label));
		}

		return toTitleCase(targetType) + 's';
	}, [targetType, targetDefinition]);

	useUpdateEffect(
		//when maximum blastradius exceeds the selection reduce it
		() => {
			if (blastRadius?.maximum && blastRadiusCount.total && blastRadius.maximum > blastRadiusCount.total) {
				setFieldValue(`${stepPath}.blastRadius.maximum`, blastRadiusCount.total);
			}
		},
		[blastRadius?.maximum, blastRadiusCount.total, setFieldValue, stepPath],
	);

	const isLoading = blastRadiusCount.loading || Object.keys(blastRadius).length === 0;

	const { mode: editorMode } = useEditorSettings();
	const cannotDeterminCount =
		editorMode === 'templateEditor' && blastRadius.predicate && listPlaceholders(blastRadius.predicate).length > 0;

	const renderBlastRadiusSlider =
		!isLoading &&
		!(
			(!cannotDeterminCount && (blastRadiusCount.total === 0 || blastRadiusCount.total === 1)) ||
			quantityRestriction == 'EXACTLY_ONE' ||
			quantityRestriction == 'ALL'
		);

	const hasValidPredicate = !!blastRadius.predicate;

	return (
		<Box style={{ px: 'small', py: 'medium', backgroundColor: Colors.neutral000 }}>
			<Flex
				spacing="small"
				style={{
					position: 'relative',
					p: 'small',
					borderRadius: 'small',
					backgroundColor: Colors.neutral100,
				}}
			>
				<Flex spacing="none">
					<Text variant="xSmallStrong" color="neutral800">
						{actionKind === 'ATTACK' || actionKind == null ? 'Attacked ' : null} {blastRadiusTargetLabel}
					</Text>

					<Flex direction="horizontal" align="center">
						<IconTarget variant="large" color={hasValidPredicate ? 'slate' : 'coral'} />
						<Text variant="xxLargeStrong" color={hasValidPredicate ? 'neutral800' : 'coral'}>
							{cannotDeterminCount ||
							blastRadiusCount.impacted === undefined ||
							isNaN(blastRadiusCount.impacted) ||
							isLoading
								? '--'
								: blastRadiusCount.impacted}
						</Text>
					</Flex>
				</Flex>

				{!isLoading && (
					<>
						{!hasValidPredicate && <BlastRadiusPreview />}

						{renderBlastRadiusSlider && !cannotDeterminCount && hasValidPredicate && (
							<Flex spacing="none" style={{ width: '100%' }}>
								<Text variant="xSmallStrong" color="neutral800" mb="-xxSmall">
									Limit Blast Radius
								</Text>
								<EditBlastRadius
									totalTargetCount={blastRadiusCount.total}
									blastRadius={blastRadius}
									disabled={disabled}
									stepPath={stepPath}
									mode={mode}
								/>
							</Flex>
						)}

						{/* The blast radius animation is absolut positioned because it's right in the middle of everything */}
						{(!hasValidPredicate || (renderBlastRadiusSlider && !cannotDeterminCount)) && (
							<div
								style={{
									position: 'absolute',
									top: '8px',
									right: '16px',
									height: 'auto',
									width: '124px',
								}}
							>
								<BlastCanvas
									total={blastRadiusCount.total || 0}
									impacted={!hasValidPredicate ? 0 : blastRadiusCount.impacted || blastRadius.maximum || 0}
								/>
							</div>
						)}

						<FormikError name={`${stepPath}.blastRadius.percentage`} />
						<FormikError name={`${stepPath}.blastRadius.maximum`} />
					</>
				)}
			</Flex>
		</Box>
	);
}

interface EditBlastRadiusProps {
	blastRadius: ExperimentStepRadiusVO;
	mode: 'percentage' | 'maximum';
	totalTargetCount?: number;
	stepPath: string | null;
	disabled?: boolean;
}
function EditBlastRadius({
	totalTargetCount,
	blastRadius,
	disabled,
	stepPath,
	mode,
}: EditBlastRadiusProps): ReactElement {
	const formik = useFormikContext<ExperimentFormValues>();

	const handleUnitChange = useCallback(
		(unit: { value: string }): void => {
			const newBlastRadius: ExperimentStepRadiusVO = {};
			newBlastRadius.targetType = blastRadius.targetType;
			newBlastRadius.predicate = blastRadius.predicate;
			if (unit.value === 'percentage') {
				newBlastRadius.percentage = 100;
			} else if (unit.value === 'maximum') {
				newBlastRadius.maximum = 1;
			}
			formik.setFieldValue(`${stepPath}.blastRadius`, newBlastRadius);
		},
		[formik, stepPath],
	);
	const handleValueChange = useCallback(
		(event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>): void => {
			if (mode === 'percentage') {
				formik.setFieldValue(`${stepPath}.blastRadius.percentage`, toInt(event.target.value));
				formik.setFieldTouched(`${stepPath}.blastRadius.percentage`, true, false);
			} else {
				formik.setFieldValue(`${stepPath}.blastRadius.maximum`, toInt(event.target.value));
				formik.setFieldTouched(`${stepPath}.blastRadius.maximum`, true, false);
			}
		},
		[formik, mode, stepPath],
	);

	const value: number = (mode === 'percentage' ? blastRadius.percentage : blastRadius.maximum) || 0;

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row-reverse',
				alignItems: 'center',
				width: '100%',
				gap: Spacings.small,
			}}
		>
			<UnitField
				width={mode === 'percentage' ? '75px' : '100px'}
				value={toString(mode === 'percentage' ? blastRadius.percentage : blastRadius.maximum)}
				unit={mode === 'percentage' ? BLAST_UNIT_PERCENTAGE : BLAST_UNIT_MAXIMUM}
				units={BLAST_UNITS}
				min={0}
				max={mode === 'percentage' ? 100 : totalTargetCount}
				onUnitChange={handleUnitChange}
				onChange={handleValueChange}
				disabled={disabled}
				type={'number'}
				hasError={
					mode === 'percentage'
						? hasError(formik.getFieldMeta(`${stepPath}.blastRadius.percentage`))
						: hasError(formik.getFieldMeta(`${stepPath}.blastRadius.maximum`))
				}
				sx={{
					borderRadius: '4px 0px 0px 4px',
					':disabled': {
						backgroundColor: Colors.neutral000,
						color: Colors.neutral500,
					},
				}}
			/>

			<Slider
				value={value}
				min={0}
				max={mode === 'percentage' ? 100 : totalTargetCount}
				disabled={disabled}
				onChange={(value) => {
					const rounded = Math.round(value);
					if (mode === 'percentage') {
						formik.setFieldValue(`${stepPath}.blastRadius.percentage`, rounded);
						formik.setFieldTouched(`${stepPath}.blastRadius.percentage`, true, false);
					} else {
						formik.setFieldValue(`${stepPath}.blastRadius.maximum`, rounded);
						formik.setFieldTouched(`${stepPath}.blastRadius.maximum`, true, false);
					}
				}}
			/>
		</div>
	);
}

function BlastRadiusPreview(): ReactElement {
	return (
		<Tooltip content="Please select targets before changing the blast radius.">
			<Flex spacing="none" style={{ width: '100%' }}>
				<Text variant="xSmallStrong" color="neutral800" mb="-xxSmall">
					Limit Blast Radius
				</Text>
				<EditBlastRadius
					mode="percentage"
					blastRadius={{
						percentage: 100,
					}}
					stepPath={''}
					totalTargetCount={0}
					disabled
				/>
			</Flex>
		</Tooltip>
	);
}
