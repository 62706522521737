/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ContainerProps } from 'components';
import React from 'react';

import PaginationComponent from './PaginationComponent';

type PaginationOwnProps = {
	size?: 'small' | 'medium';
	totalPages?: number;
	activePage?: number;
	onClick?: (pageNo: number) => void;
};

export type PaginationProps = PaginationOwnProps & Omit<ContainerProps, keyof PaginationOwnProps | 'to'>;

export const Pagination: React.VFC<PaginationProps> = (props) => {
	return <PaginationComponent {...props} />;
};
