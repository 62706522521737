/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	FormikError,
	Link,
	LoadingIndicator,
	ModalContentV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	Snackbar,
	Stack,
	Text,
	TextField,
	Tooltip,
} from 'components';
import { Form, Formik, FormikErrors, useField } from 'formik';
import { usePromise } from 'utils/hooks/usePromise';
import { CreateHubRequest, HubVO } from 'ui-api';
import { ReactElement, useState } from 'react';
import { Services } from 'services/services';
import { theme } from 'styles.v2/theme';
import { set } from 'lodash';

import ImportTemplatesModal from './ImportTemplatesModal';
import TestConnectionButton from './TestConnectionButton';

interface ConnectHubModalProps {
	onClose: () => void;
}

async function handleValidate(values: CreateHubRequest): Promise<FormikErrors<CreateHubRequest>> {
	const errors = {};
	const violations = await Services.hubsApi.validate({
		...values,
		repositoryUrl: values.repositoryUrl,
		hubLink: values.hubLink,
	});
	violations.forEach(({ field, message }) => {
		set(errors, field, message);
	});
	return errors;
}

export default function ConnectHubModal({ onClose }: ConnectHubModalProps): ReactElement {
	const [connectedHub, setConnectedHub] = useState<HubVO | null>(null);
	const [isImportingTemplates, setIsImportingTemplates] = useState(false);

	if (connectedHub && isImportingTemplates) {
		return (
			<ImportTemplatesModal
				title="Import Templates from Hub"
				description="Import all the templates from your hub now, or decide to do this later."
				cancelText="Import Templates later"
				hub={connectedHub}
				pathname="/<new>"
				onClose={onClose}
				onGoBackOnError={() => {
					setIsImportingTemplates(false);
				}}
			/>
		);
	}

	if (connectedHub) {
		return (
			<EditHubModal
				hubId={connectedHub.id}
				readonly={false}
				onHubUpdated={() => setIsImportingTemplates(true)}
				onClose={onClose}
			/>
		);
	}

	return (
		<ModalOverlay open centerContent onClose={onClose}>
			<ModalV2 slick width="90vw" maxWidth="780px">
				<ModalHeaderV2
					title="Add new Hub"
					subTitle={
						<Stack>
							<Text>
								To add a hub, please provide an HTTP link to its JSON index. One example is our own public{' '}
								<Link href="https://hub.steadybit.com" external>
									Reliability Hub
								</Link>
								, served via its{' '}
								<Link href="https://raw.githubusercontent.com/steadybit/reliability-hub-db/main/index.json" external>
									index.json
								</Link>
								. Learn more about the conventions of a connected hub{' '}
								<Link href="https://docs.steadybit.com/integrate-with-steadybit/hubs" external>
									in our docs
								</Link>
								.
							</Text>
						</Stack>
					}
					onClose={onClose}
				/>
				<ModalContentV2>
					<Formik<CreateHubRequest>
						initialValues={
							{
								repositoryUrl: '',
								hubLink: '',
								hubName: '',
							} as CreateHubRequest
						}
						validateOnBlur={false}
						enableReinitialize
						validate={handleValidate}
						onSubmit={async (values) => {
							try {
								const _connectedHub = await Services.hubsApi.connectHub({
									...values,
									repositoryUrl: values.repositoryUrl,
									hubLink: values.hubLink,
								});

								setConnectedHub(_connectedHub);
								setIsImportingTemplates(true);
							} catch (error) {
								Snackbar.error('Hub cannot be connected: ' + error.toString(), {
									toastId: 'connect-hub-error',
								});
							}
						}}
					>
						{({ touched, errors, submitForm, isSubmitting }) => {
							return (
								<Form noValidate>
									<Stack>
										<ConnectHubContent readonly={false} />
										<Stack direction="horizontal" size="large" justifyContent="space-between" alignItems="flex-end">
											<TestConnectionButton />

											<Stack direction="horizontal" size="large" justifyContent="flex-end">
												<Button variant="secondarySmall" onClick={onClose}>
													Cancel
												</Button>
												<Button
													variant="primarySmall"
													disabled={isSubmitting || (Object.keys(errors).length > 0 && Object.keys(touched).length > 0)}
													onClick={submitForm}
												>
													{isSubmitting ? 'Saving...' : 'Add new Hub'}
												</Button>
											</Stack>
										</Stack>
									</Stack>
								</Form>
							);
						}}
					</Formik>
				</ModalContentV2>
			</ModalV2>
		</ModalOverlay>
	);
}

interface EditHubModalProps {
	readonly: boolean;
	hubId: string;
	onHubUpdated: () => void;
	onClose: () => void;
}

export function EditHubModal({ hubId, readonly, onClose, onHubUpdated }: EditHubModalProps): ReactElement {
	return (
		<ModalOverlay open centerContent onClose={onClose}>
			<ModalV2 slick width="90vw" maxWidth="780px">
				<ModalHeaderV2 title="Edit Hub info" onClose={onClose} />
				<ModalContentV2>
					<EditHubModalContent hubId={hubId} readonly={readonly} onClose={onClose} onHubUpdated={onHubUpdated} />
				</ModalContentV2>
			</ModalV2>
		</ModalOverlay>
	);
}

function EditHubModalContent({ hubId, readonly, onClose, onHubUpdated }: EditHubModalProps): ReactElement {
	const hubResult = usePromise(() => Services.hubsApi.getHub(hubId), [hubId]);
	if (hubResult.loading) {
		return <LoadingHubContent onClose={onClose} />;
	}

	if (hubResult.error) {
		return <ErrorHubContent error={hubResult.error.message} onClose={onClose} />;
	}

	const hub = hubResult.value;
	return (
		<Formik<CreateHubRequest>
			initialValues={hub as CreateHubRequest}
			validateOnBlur={false}
			enableReinitialize
			validate={handleValidate}
			onSubmit={async (values) => {
				try {
					await Services.hubsApi.updateHub(hub.id, {
						...values,
						version: hub.version,
						repositoryUrl: values.repositoryUrl,
						hubLink: values.hubLink,
					});
					onHubUpdated();
				} catch (error) {
					Snackbar.error('Hub cannot be connected: ' + error.toString(), {
						toastId: 'connect-hub-error',
					});
				}
			}}
		>
			{({ touched, errors, submitForm, isSubmitting }) => {
				return (
					<Form noValidate>
						<Stack>
							<ConnectHubContent readonly={readonly} />

							<Stack direction="horizontal" size="large" justifyContent="space-between" alignItems="flex-end">
								<TestConnectionButton />

								<Stack direction="horizontal" size="large" justifyContent="flex-end">
									<Button variant="secondarySmall" onClick={onClose}>
										Cancel
									</Button>
									<Tooltip content={readonly ? 'This hub connection cannot be edited' : undefined}>
										<Button
											variant="primarySmall"
											disabled={
												readonly || isSubmitting || (Object.keys(errors).length > 0 && Object.keys(touched).length > 0)
											}
											onClick={submitForm}
										>
											{isSubmitting ? 'Saving...' : 'Save changes'}
										</Button>
									</Tooltip>
								</Stack>
							</Stack>
						</Stack>
					</Form>
				);
			}}
		</Formik>
	);
}

function ConnectHubContent({ readonly }: { readonly: boolean }): ReactElement {
	const [repositoryUrlField, , { setValue: setRepositoryUrl }] = useField<string>('repositoryUrl');
	const [hubNameField, , { setValue: setHubName }] = useField<string>('hubName');
	const [hubLinkField, , { setValue: setHubLink }] = useField<string>('hubLink');

	return (
		<Stack
			size="medium"
			sx={{
				marginTop: '32px',
				padding: '16px',
				border: '1px solid ' + theme.colors.neutral300,
				borderRadius: '4px',
			}}
		>
			<Stack size="xSmall">
				<Text variant="smallStrong" color="neutral600">
					Repository link
				</Text>

				<TextField
					type="text"
					value={repositoryUrlField.value}
					placeholder="https://github.com/steadybit/reliability-hub-db"
					disabled={readonly}
					onChange={(e) => {
						setRepositoryUrl(e.target.value);
					}}
				/>
				<FormikError name="repositoryUrl" />
			</Stack>
			<Stack size="xSmall">
				<Text variant="smallStrong" color="neutral600">
					Hub name
				</Text>
				<TextField
					type="text"
					value={hubNameField.value}
					placeholder="My Reliability Hub"
					disabled={readonly}
					onChange={(e) => {
						setHubName(e.target.value);
					}}
					height="40px"
				/>
				<FormikError name="hubName" />
			</Stack>
			<Stack size="xSmall">
				<Text variant="smallStrong" color="neutral600">
					Hub link (optional)
				</Text>

				<TextField
					type="text"
					value={hubLinkField.value}
					placeholder="https://my.reliability.hub"
					disabled={readonly}
					onChange={(e) => {
						setHubLink(e.target.value);
					}}
				/>
				<FormikError name="hubLink" />
			</Stack>
		</Stack>
	);
}

function LoadingHubContent({ onClose }: { onClose: () => void }): ReactElement {
	return (
		<Stack size="large" justifyContent="space-between">
			<Stack size="medium" height="200px" alignItems="center" justifyContent="center">
				<LoadingIndicator variant="large" />
				<Text variant="medium" color="neutral600">
					Loading Hub info…
				</Text>
			</Stack>
			<Stack direction="horizontal" size="large">
				<Button variant="secondarySmall" onClick={onClose}>
					Cancel
				</Button>
			</Stack>
		</Stack>
	);
}

function ErrorHubContent({ error, onClose }: { error: string; onClose: () => void }): ReactElement {
	return (
		<Stack size="large" justifyContent="space-between">
			<Stack size="medium" height="200px" alignItems="center" justifyContent="center">
				<LoadingIndicator variant="large" />
				<Text variant="medium" color="neutral600">
					Loading Hub info…
				</Text>
			</Stack>
			<Stack direction="horizontal" size="large">
				<Button variant="secondarySmall" onClick={onClose}>
					Cancel
				</Button>
			</Stack>
		</Stack>
	);
}
