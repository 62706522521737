/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import ReactSlider from 'react-slider';
import { ReactElement } from 'react';

import './slider-style.css';

interface SliderProps {
	disabled?: boolean;
	value: number;
	min?: number;
	max?: number;
	onChange: (value: number) => void;
}

export default function Slider({ value, min, max, disabled, onChange }: SliderProps): ReactElement {
	return (
		<ReactSlider
			value={value}
			min={min}
			max={max}
			disabled={disabled}
			onChange={onChange}
			className="slider"
			trackClassName="slider-track"
			thumbClassName="slider-thumb"
		/>
	);
}
