/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ActionVO,
	ExperimentStepActionVO,
	ExperimentStepRadiusVO,
	TargetPredicateTemplateVO,
	TargetPredicateVO,
} from 'ui-api';
import { isPredicateTemplateApplicable } from 'components/PredicateEditor/PredicateEditor';
import { toTargetPredicate } from 'queryLanguage/parser/parser';
import { isParsingError } from 'queryLanguage/parser/types';
import { usePromise } from 'utils/hooks/usePromise';
import { useField, useFormikContext } from 'formik';
import { Services } from 'services/services';
import { useEffect } from 'react';
import { ampli } from 'ampli';

import { ExperimentFormValues } from '../types';
import useActions from '../useActions';

export default function ActionInitialiser({ step, path }: { step: ExperimentStepActionVO; path: string }): null {
	const { values } = useFormikContext<ExperimentFormValues>();
	const [, , { setValue, setTouched }] = useField<ExperimentStepActionVO>(path);
	const { environmentId, experimentKey } = values;

	const actions = useActions();
	const action = actions.find((a) => a.id === step.actionId);
	const targetType = action ? action.target.type : undefined;

	const keys = usePromise(
		() => Services.editorApi.fetchTargetAttributeKeys({ targetType, environmentId }),
		[targetType, environmentId],
	);
	useEffect(() => {
		if (action && targetType && keys.value) {
			const blastRadius = initBlastRadius(action, keys.value, experimentKey, environmentId);
			setValue({ ...step, blastRadius });
			setTouched(true);
		}
	}, [targetType, keys]);

	return null;
}

function initBlastRadius(
	action: ActionVO,
	attributeKeys: string[],
	experimentKey: string | undefined,
	environmentId: string | undefined,
): ExperimentStepRadiusVO {
	const initialTemplate = getInititalTargetTemplatePredicate(action, attributeKeys);
	if (initialTemplate) {
		ampli.experimentStepTargetSelectionTemplateApplied({
			experiment_key: experimentKey,
			action: action.id,
			environment_id: environmentId,
			experiment_step_target_template_method: 'default',
			experiment_step_target_template_name: initialTemplate.template.name,
		});
	}
	const radius = {
		targetType: action.target.type,
		predicate: initialTemplate?.predicate,
		percentage: undefined,
		maximum: undefined,
	};
	if (action.quantityRestriction === 'NONE') {
		return {
			...radius,
			percentage: 100,
		};
	}
	return radius;
}

function getInititalTargetTemplatePredicate(
	action: ActionVO,
	attributeKeys: string[],
): { predicate: TargetPredicateVO; template: TargetPredicateTemplateVO } | undefined {
	const preselectableTemplates = action.targetPredicateTemplates.filter((tpt) => tpt.preselect);
	for (let i = 0; i < preselectableTemplates.length; i++) {
		const predicateTemplate = preselectableTemplates[i];
		const parseResult = toTargetPredicate(predicateTemplate.template);
		if (!isParsingError(parseResult) && isPredicateTemplateApplicable(parseResult, attributeKeys)) {
			return { predicate: parseResult, template: predicateTemplate };
		}
	}
}
