/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { BasicButton, BasicButtonProps, Container } from 'components';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import React, { ReactNode, useCallback } from 'react';
import { theme } from 'styles.v2/theme';

import { Spinner } from '../Spinner';
import { Tooltip } from '../Tooltip';

export interface ButtonIconProps extends BasicButtonProps {
	muted?: boolean;
	loading?: boolean;
	tooltip?: string;
}

export const ButtonIcon = React.forwardRef<HTMLButtonElement, ButtonIconProps>(
	({ children, tooltip, loading, disabled, sx = {}, variant = 'medium', ...props }, ref) => {
		const wrapIfLoading = useCallback(
			(children: ReactNode) => {
				if (loading) {
					return (
						<Container as="span" sx={{ visibility: 'hidden' }}>
							{children}
						</Container>
					);
				}
				return children;
			},
			[loading],
		);

		const button = (
			<BasicButton
				type="button"
				ref={ref}
				tx="buttonIcon"
				disabled={disabled}
				sx={{
					position: 'relative',
					variant: 'text.medium',
					display: 'inline-flex',
					alignItems: 'center',
					justifyContent: 'center',

					color: props.muted ? 'neutral400' : disabled ? 'neutral300' : 'neutral600',
					bg: 'transparent',
					borderRadius: '4px',
					cursor: disabled || loading ? 'initial' : 'pointer',

					opacity: disabled && props.muted ? 0.35 : 1,
					pointerEvents: disabled || loading ? 'none' : 'initial',
					border: '1px solid transparent',

					':hover': {
						textDecoration: 'none',
						color: 'purple700',
						bg: 'linear-gradient(180deg, ' + theme.colors.neutral000 + ' 0%, ' + theme.colors.neutral100 + ' 100%)',
						border: '1px solid ' + theme.colors.neutral300,
					},

					'&[data-active=true]': {
						outline: 'none',
						color: 'neutral700',
						bg: 'neutral200',
					},

					...sx,
				}}
				variant={variant}
				{...props}
			>
				{wrapIfLoading(children)}
				{loading && (
					<Spinner
						sx={{
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					/>
				)}
			</BasicButton>
		);

		return tooltip ? <Tooltip content={tooltip}>{button}</Tooltip> : button;
	},
);
ButtonIcon.displayName = 'ButtonIcon';

export interface ProgressIconButtonProps extends Omit<ButtonIconProps, 'loading'> {
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
}

export const ProgressButtonIcon = React.forwardRef<HTMLButtonElement, ProgressIconButtonProps>(
	({ onClick, children, ...props }, ref) => {
		const [promise, setPromise] = React.useState<Promise<void>>(Promise.resolve());
		const [state] = useAsyncState(() => promise, [promise]);

		return (
			<ButtonIcon ref={ref} {...props} onClick={(e) => setPromise(onClick(e))} loading={state.loading}>
				{children}
			</ButtonIcon>
		);
	},
);
ProgressButtonIcon.displayName = 'ProgressButtonIcon';
