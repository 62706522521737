/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	CompositeTargetPredicateVO,
	EnvironmentVariableVO,
	TargetAttributeKeyValuePredicateVO,
	TargetPredicateTemplateVO,
	TargetPredicateVO,
	TemplatePlaceholderVO,
} from 'ui-api';
import { isCompositeTargetPredicateVO } from 'queryLanguage/parser/visitor';
import { useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import { Button, Container, Stack, Text, Tooltip } from 'components';
import { toPredicateString } from 'queryLanguage/parser/serializer';
import React, { ReactElement, useCallback, useState } from 'react';
import { toTargetPredicate } from 'queryLanguage/parser/parser';
import { isParsingError } from 'queryLanguage/parser/types';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { theme } from 'styles.v2/theme';
import { useField } from 'formik';

import { isPredicateEditableWithTheQueryBuilder, isTargetAttributeKeyValuePredicateVO } from './utils';
import { PredicateBuilder, ValueValidator } from '../PredicateBuilder';
import Editor from '../Editor/Editor';

interface PredicateEditorProps {
	predicate: TargetPredicateVO | undefined;
	setPredicate: (predicate: TargetPredicateVO | undefined) => void;
	disabled?: boolean;
	targetType?: string;
	environmentId?: string;
	targetPredicateTemplates?: TargetPredicateTemplateVO[];
	onTargetPredicateTemplateApplied?: (predicate: TargetPredicateTemplateVO) => void;
	onAttributeKeyChange?: (key: string, change: 'added' | 'removed' | 'replaced') => void;
	valueValidator?: ValueValidator;
	initialView?: 'queryBuilder' | 'editor';
	error?: React.ReactNode;
}

function filterPredicateTemplates(
	templates: TargetPredicateTemplateVO[],
	attributeKeys: string[],
): TargetPredicateTemplateVO[] {
	return templates.filter((template) => {
		const parseResult = toTargetPredicate(template.template);
		return !isParsingError(parseResult) && isPredicateTemplateApplicable(parseResult, attributeKeys);
	});
}

export function isPredicateTemplateApplicable(predicate: TargetPredicateVO, attributeKeys: string[]): boolean {
	if (isCompositeTargetPredicateVO(predicate)) {
		const composite = predicate as CompositeTargetPredicateVO;
		return composite.predicates.every((predicate: TargetPredicateVO) =>
			isPredicateTemplateApplicable(predicate, attributeKeys),
		);
	}
	if (isTargetAttributeKeyValuePredicateVO(predicate)) {
		const keyValuePredicate = predicate as TargetAttributeKeyValuePredicateVO;
		return attributeKeys.includes(keyValuePredicate.key);
	}
	return true;
}

export default function PredicateEditor({
	predicate,
	setPredicate,
	disabled,
	targetType,
	environmentId,
	targetPredicateTemplates,
	onTargetPredicateTemplateApplied,
	onAttributeKeyChange,
	valueValidator,
	initialView,
	error,
}: PredicateEditorProps): ReactElement {
	const isEditableWithTheQueryBuilder = isPredicateEditableWithTheQueryBuilder(predicate);
	const predicateString = toPredicateString(predicate);
	const keys = usePromise(
		() => Services.editorApi.fetchTargetAttributeKeys({ environmentId, targetType }),
		[targetType, environmentId],
	);
	const [mode, setMode] = useState<'queryBuilder' | 'editor'>(
		initialView ? initialView : isEditableWithTheQueryBuilder ? 'queryBuilder' : 'editor',
	);
	const { mode: editorMode } = useEditorSettings();

	const [forceUpdateSignal, setForceUpdateSignal] = useState(0);

	const filteredTemplates = filterPredicateTemplates(targetPredicateTemplates || [], keys.value || []);

	const fetchAttributeValues = useCallback(
		(key: string) => Services.editorApi.fetchTargetAttributeValues({ key, targetType, environmentId }),
		[targetType, environmentId],
	);

	const [{ value: placeholders = [] }] = useField<TemplatePlaceholderVO[]>('placeholders');
	const [{ value: variables = [] }] = useField<EnvironmentVariableVO[]>('variables');

	const [tempFilterQuery, setTempFilterQuery] = useState(predicateString);

	const showApplyButton = predicateString !== tempFilterQuery;

	return (
		<Stack size="none">
			{filteredTemplates.length > 0 && (
				<Stack direction={'horizontal'} mb={'small'}>
					<Text variant={'small'} muted sx={{ lineHeight: 'unset' }}>
						Selection Templates:
					</Text>
					{filteredTemplates.map((tpt) => (
						<Tooltip content={tpt.description} key={tpt.name} placement={'bottom'}>
							<Text
								key={tpt.name}
								variant="smallStrong"
								color="slate"
								sx={{
									fontSize: 1,
									cursor: 'pointer',
									'&:hover': {
										textDecoration: 'underline',
									},
								}}
								data-track={'target-predicate-template'}
								onClick={() => {
									const parseResult = toTargetPredicate(tpt.template);
									if (!isParsingError(parseResult)) {
										setPredicate(parseResult);
										setForceUpdateSignal(forceUpdateSignal + 1);
										onTargetPredicateTemplateApplied?.(tpt);
									}
								}}
							>
								{tpt.name}
							</Text>
						</Tooltip>
					))}
				</Stack>
			)}
			<Container display="flex" alignItems="center" justifyContent="space-between">
				<Stack direction="horizontal" size="none">
					<Button
						variant={mode === 'queryBuilder' ? 'primarySmall' : 'secondarySmall'}
						onClick={() => setMode('queryBuilder')}
						sx={{ borderRadius: '4px 0 0 0', borderBottom: 0 }}
					>
						Query UI
					</Button>
					<Button
						variant={mode === 'editor' ? 'primarySmall' : 'secondarySmall'}
						onClick={() => setMode('editor')}
						sx={{ borderRadius: '0 4px 0 0', borderBottom: 0 }}
					>
						Query Language
					</Button>
				</Stack>
			</Container>

			<Container
				sx={{
					border: '1px solid ' + theme.colors.neutral200,
					borderRadius: '0 0 4px 4px',
					bg: 'neutral000',
				}}
			>
				{mode === 'queryBuilder' ? (
					<Container p="medium">
						{isEditableWithTheQueryBuilder ? (
							<>
								<PredicateBuilder
									disabled={disabled}
									variant="small"
									value={predicate}
									onChange={(predicate) => {
										setPredicate(predicate);
										setTempFilterQuery(toPredicateString(predicate));
									}}
									onAttributeKeyChange={onAttributeKeyChange}
									attributeKeys={keys.value}
									attributeKeysLoading={keys.loading}
									fetchAttributeValues={fetchAttributeValues}
									variables={variables}
									valueValidator={valueValidator}
								/>
								{error ? <Container pt={'small'}>{error}</Container> : null}
							</>
						) : (
							<Stack size="xSmall" alignItems="center">
								<Text variant="mediumStrong" color="neutral800">
									¯\_(ツ)_/¯
								</Text>
								<Text variant="medium" color="neutral600">
									The query is too complex for the Query UI
								</Text>
							</Stack>
						)}
					</Container>
				) : (
					<Container>
						<Editor
							forceUpdateSignal={forceUpdateSignal}
							disabled={disabled}
							initialValue={predicateString}
							onValidCodeChanged={(codeString) => {
								setTempFilterQuery(codeString);
							}}
							targetType={targetType}
							environmentId={environmentId}
							getAdditionalSuggestions={() => variables.map(({ key }) => `{{${key}}}`)}
							getAdditionalKeywords={() =>
								editorMode === 'templateEditor' ? placeholders.map(({ key }) => `[[${key}]]`) : []
							}
							allowFullQueryPlaceholder={editorMode === 'templateEditor'}
							onApply={() => {
								if (showApplyButton) {
									setPredicate({ query: tempFilterQuery });
								}
							}}
						/>
						<Container
							display="flex"
							flexDirection="row-reverse"
							alignItems="center"
							justifyContent="space-between"
							width="100%"
						>
							{showApplyButton && (
								<Tooltip content={getApplyShortcutTooltip()}>
									<Button
										variant="primarySmall"
										onClick={() => {
											setPredicate({ query: tempFilterQuery });
										}}
										m="xxSmall"
									>
										Apply
									</Button>
								</Tooltip>
							)}

							{error ? (
								<Text variant="smallStrong" color="coral" m="xxSmall">
									{error}
								</Text>
							) : null}
						</Container>
					</Container>
				)}
			</Container>
		</Stack>
	);
}

export function getApplyShortcutTooltip(): string {
	if (window.navigator.userAgent.indexOf('Mac') !== -1) {
		return '⌘ + ↩';
	}
	return 'Ctrl + Enter';
}
