/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { useTargetDefinition } from 'targets/useTargetDefinition';
import { ReactElement } from 'react-markdown/lib/react-markdown';

import { createIconFromDataUri, IconTarget, IconComponentProps } from './icons';

interface IconTargetTypeProps extends IconComponentProps {
	targetType: string;
}

export function IconTargetType({ targetType, ...props }: IconTargetTypeProps): ReactElement {
	const targetDefinition = useTargetDefinition(targetType);
	if (targetDefinition.value) {
		const Icon = createIconFromDataUri(targetDefinition.value.icon);
		return <Icon {...props} />;
	}
	return <IconTarget {...props} />;
}
