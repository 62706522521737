/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import MultiSelectDropdown from 'components/Select/Dropdown/MultiSelectDropdown';
import MultiSelectList from 'components/Select/Dropdown/presets/MultiSelectList';
import { Li, Skeletons, Stack, Text, Ul } from 'components';
import { ReactElement, useEffect, useState } from 'react';
import { localeCompareIgnoreCase } from 'utils/string';
import { useUrlState } from 'url/useUrlState';
import { AdviceListItemVO } from 'ui-api';
import { theme } from 'styles.v2/theme';

import { UrlState, selectedAdviceIdParam } from '../urlParams';
import AdviceListItem from './AdviceListItem';
import { ampli } from '../../../ampli';

interface AdviceListProps {
	adviceList: AdviceListItemVO[];
	selectedAdviceId: string | null;
}

export default function AdviceList({ adviceList, selectedAdviceId }: AdviceListProps): ReactElement {
	const [, , updateUrlState] = useUrlState<UrlState>([selectedAdviceIdParam]);

	// if nothing is selected, select the first advice
	useEffect(() => {
		if (selectedAdviceId || adviceList.length === 0) {
			return;
		}

		updateUrlState({ selectedAdviceId: adviceList.sort((a, b) => a.label.localeCompare(b.label))[0].type });
	}, [selectedAdviceId, adviceList]);
	const [searchTermTags, setSearchTermTags] = useState<string>('');
	const [selectedTags, setSelectedTags] = useState<string[]>([]);

	useEffect(() => {
		ampli.explorerAdviceListViewed({
			advice_ids_listed: adviceList.map((advice) => advice.type),
			advice_id_shown: selectedAdviceId ?? undefined,
			filter_tag: selectedTags,
			advice_context: 'explorer_advice',
		});
	}, [adviceList, selectedAdviceId, selectedTags]);

	const tags = adviceList
		.reduce((acc, advice) => {
			(advice.tags || []).forEach((tag) => {
				if (acc.indexOf(tag) === -1) {
					acc.push(tag);
				}
			});
			return acc;
		}, [] as string[])
		.sort(localeCompareIgnoreCase)
		.filter((tag) => tag.toLowerCase().includes(searchTermTags.toLowerCase()))
		.map((tag) => ({ value: tag, label: tag }));

	let filteredAdviceList = adviceList.sort((a, b) => a.label.localeCompare(b.label));
	if (selectedTags.length > 0) {
		filteredAdviceList = filteredAdviceList.filter((advice) =>
			selectedTags.find((tag) => advice.tags.indexOf(tag) !== -1),
		);
	}

	return (
		<Stack
			size="none"
			sx={{
				borderRight: '1px solid ' + theme.colors.neutral300,
				overflowY: 'auto',
			}}
		>
			{tags.length > 0 && (
				<Stack bg="neutral100" p="small">
					<Text variant="smallStrong">Filter by tags:</Text>
					<MultiSelectDropdown
						variant="light"
						value={searchTermTags}
						selectedValues={selectedTags.map((tag) => ({ value: tag, label: tag }))}
						onRemoveSelectedValue={({ value }) => setSelectedTags(selectedTags.filter((t) => t !== value))}
						onValueChanged={setSearchTermTags}
						placeholder="Value"
						width="100%"
					>
						{({ width }) => (
							<DropdownContentFrame maxHeight={300} snapToComponent>
								<MultiSelectList
									width={width}
									selectedItemValues={selectedTags}
									items={tags}
									selectItem={({ value }) => setSelectedTags([...selectedTags, value])}
									removeSelectedItem={({ value }) => setSelectedTags(selectedTags.filter((t) => t !== value))}
								/>
							</DropdownContentFrame>
						)}
					</MultiSelectDropdown>
				</Stack>
			)}

			<Ul
				sx={{
					p: 'small',

					height: '100%',
				}}
			>
				{filteredAdviceList.map((advice) => {
					const isSelected = selectedAdviceId === advice.type;
					return <AdviceListItem key={advice.type} advice={advice} isSelected={isSelected} />;
				})}
			</Ul>
		</Stack>
	);
}

export function LoadingList(): ReactElement {
	return (
		<Ul
			sx={{
				p: 'small',
				borderRight: '1px solid ' + theme.colors.neutral300,
				height: '100%',
				overflowY: 'auto',
			}}
		>
			<Li mb="small">
				<Skeletons height={56} widths={[390]} pb="xxSmall" mt={1} />
			</Li>
			<Li mb="small">
				<Skeletons height={56} widths={[390]} pb="xxSmall" mt={1} />
			</Li>
			<Li mb="small">
				<Skeletons height={56} widths={[390]} pb="xxSmall" mt={1} />
			</Li>
		</Ul>
	);
}
