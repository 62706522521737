/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { PageParams } from 'utils/hooks/usePage';
import { useTenant } from 'tenancy/useTenant';
import { Services } from 'services/services';

import { useAsyncState } from './useAsyncState';

interface UseMaxTeamsReponse {
	maxTeamsReached: boolean;
}

export default function useMaxTeams(): UseMaxTeamsReponse {
	const tenant = useTenant();
	const [teams] = useAsyncState(() => Services.teams.fetchTeams(new PageParams(0, 1, [['name']])));
	const totalTeams = teams.value?.totalElements || 0;
	const maxTeamsReached = tenant.maxTeams <= totalTeams;

	return { maxTeamsReached };
}
