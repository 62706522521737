/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { merge } from 'lodash';

import { parse as jsUrlParse, stringify as jsUrlStringify } from './jsurl2';
import { LocationWithUrlParams, MatrixParameters } from './type';
import { UrlParam } from './useUrlState';

export function getParamValue<T>(location: LocationWithUrlParams<unknown>, param: UrlParam<T>): T {
	let value: string | undefined = undefined;

	if (param.pathSegment) {
		value = location.matrix[param.pathSegment]?.[param.name];
	} else {
		value = location.query[param.name];
	}

	if (value != null) {
		return jsUrlParse(value);
	} else {
		return param.defaultValue;
	}
}

export function setParamValue<T>(
	location: LocationWithUrlParams<unknown>,
	param: Omit<UrlParam<T>, 'defaultValue'>,
	value: T,
): void {
	if (value == null) {
		if (param.pathSegment) {
			delete location.matrix[param.pathSegment]?.[param.name];
		} else {
			delete location.query[param.name];
		}
	} else if (param.pathSegment) {
		location.matrix[param.pathSegment] = location.matrix[param.pathSegment] || {};
		location.matrix[param.pathSegment][param.name] = jsUrlStringify(value);
	} else {
		location.query[param.name] = jsUrlStringify(value);
	}
}

export function mergeMatrixParameters(a: MatrixParameters, b: MatrixParameters): MatrixParameters {
	return merge({}, a, b);
}
