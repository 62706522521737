/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { MutableRefObject, ReactElement, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { IconRoundSpinnerWhite, IconTarget, IconWarningCircleOpen } from 'components/icons';
import { Button, Container, Stack, Tooltip } from 'components';
import { useFormikContext } from 'formik';

import SelectedEntitySidebar from './SelectedEntitySidebar/SelectedEntitySidebar';
import useTargetDuplicationHint from '../ConfigSidebar/useTargetDuplicationHint';
import { LandscapeConfig, LandscapeGroup, NumberOfItems } from '../types';
import EntitySelectionReEmitter from './EntitySelectionReEmitter';
import EntitySelectionHandler from './EntitySelectionHandler';
import HoveredEntityTooltip from './HoveredEntityTooltip';
import Controller from './controller/Controller';
import ExploreTextures from './ExploreTextures';
import GroupLabels from './GroupLabels';

interface TargetBubbleMapProps {
	canvasRef: MutableRefObject<HTMLCanvasElement | null>;
	numberOfItems: NumberOfItems;
	icons: Map<string, string>;
	textures: ExploreTextures;
	groups: LandscapeGroup[];
	isCalculating: boolean;
}

export default function TargetBubbleMap({
	numberOfItems,
	isCalculating,
	canvasRef,
	textures,
	groups,
	icons,
}: TargetBubbleMapProps): ReactElement {
	const containerRef = useRef<HTMLDivElement>(null);
	const [mapController, setMapController] = useState<Controller | null>(null);
	const [isLayouting, setIsLayouting] = useState(true);
	const formik = useFormikContext<LandscapeConfig>();

	useTargetDuplicationHint(groups, formik.values.colorMapping);

	const numberOfVisibleTargets = numberOfItems.targets;

	useEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) {
			setMapController(null);
			return;
		}

		const controller = new Controller(canvas, numberOfVisibleTargets, textures, formik.values.showAdvice);
		setMapController(controller);

		return () => {
			controller.dispose();
			setMapController(null);
		};
	}, [canvasRef.current, numberOfVisibleTargets, Array.from(icons.keys()).join(',')]);

	useLayoutEffect(() => {
		const container = containerRef.current;
		if (!container || !mapController) {
			return;
		}

		const resize = new ResizeObserver(() => mapController.resize(container.clientWidth, container.clientHeight));
		resize.observe(container);

		return () => {
			resize.unobserve(container);
		};
	}, [containerRef.current, mapController]);

	useEffect(() => {
		if (!isLayouting) {
			setIsLayouting(true);
		}
		if (!mapController || mapController.disposed) {
			return;
		}
		if (!groups || groups.length === 0) {
			return;
		}

		const worker = new Worker(new URL('../util.worker.ts', import.meta.url), {
			type: 'module',
		});
		worker.postMessage({ type: 'layoutGroups', groups });
		worker.addEventListener('message', (event: MessageEvent): void => {
			setIsLayouting(false);
			mapController.renderGroups(event.data.layoutedGroups);
		});

		return (): void => {
			worker.terminate();
		};
	}, [mapController, groups]);

	return (
		<Container
			sx={{
				position: 'relative',
				height: '100%',
				width: '100%',
				bg: 'neutral700',
				overflow: 'hidden',
			}}
			ref={containerRef}
		>
			<canvas
				id="canvas"
				ref={canvasRef}
				style={{
					display: 'block',
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
				}}
			/>
			<HoveredEntityTooltip icons={icons} />
			<Container
				sx={{
					position: 'relative',
					height: '100%',
					width: '100%',
					pointerEvents: 'none',
					// we need that because the group labels are fixed positioned
					clipPath: 'inset(0 0 0 0)',
				}}
			>
				<GroupLabels />
			</Container>
			<EntitySelectionHandler />
			<EntitySelectionReEmitter groups={groups} />
			<SelectedEntitySidebar />
			<Stack
				direction="horizontal"
				size="xSmall"
				sx={{
					position: 'absolute',
					top: 8,
					left: 8,
				}}
			>
				{mapController && (
					<Tooltip content="center view">
						<Button
							variant="secondary"
							width={36}
							height={36}
							onClick={() => {
								mapController.centerView();
								mapController.render();
							}}
						>
							<IconTarget minWidth={24} minHeight={24} />
						</Button>
					</Tooltip>
				)}
				<HiddenTargetsNotifiication />

				{(isCalculating || isLayouting) && (
					<Container
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: 30,
							height: 36,
						}}
					>
						<IconRoundSpinnerWhite color="neutral000" />
					</Container>
				)}
			</Stack>
		</Container>
	);
}

function HiddenTargetsNotifiication(): ReactElement | null {
	const formik = useFormikContext<LandscapeConfig>();

	const showNotification = formik.values.groupConfigs.some((groupConfig) => !groupConfig?.showUnknown);
	if (!showNotification) {
		return null;
	}

	return (
		<Tooltip
			content={
				<Stack size="none" alignItems="center">
					<span>
						Some targets might be missing because there are active group configs hiding targets with unknown property
						values.
					</span>
				</Stack>
			}
		>
			<Button variant="secondary" width={36} height={36} onClick={() => {}}>
				<IconWarningCircleOpen minWidth={24} minHeight={24} />
			</Button>
		</Tooltip>
	);
}
