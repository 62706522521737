/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { BaseExperimentStepVOUnion, ExperimentLaneVO } from 'ui-api';
import { theme } from 'styles.v2/theme';
import { CSSProperties } from 'react';
import { v4 as uuidv4 } from 'uuid';

import ActionBackgroundGraph from './Workspace/action-background-graph.svg';

export function removeStep(lanes: ExperimentLaneVO[], stepId: string): ExperimentLaneVO[] {
	return lanes.map((lane) => {
		return {
			...lane,
			steps: lane.steps.filter((step) => step.id !== stepId),
		};
	});
}

export function findStep(lanes: ExperimentLaneVO[], stepId: string): [BaseExperimentStepVOUnion | undefined, string] {
	for (let iL = 0; iL < lanes.length; iL++) {
		const lane = lanes[iL];
		for (let iS = 0; iS < lane.steps.length; iS++) {
			const step = lane.steps[iS];
			if (step.id === stepId) {
				return [step, `lanes[${iL}].steps[${iS}]`];
			}
		}
	}
	return [undefined, ''];
}

export function filterEmpty(lanes: ExperimentLaneVO[]): ExperimentLaneVO[] {
	return lanes.filter((lane) => lane.steps.length > 0);
}

export function duplicateStep(lanes: ExperimentLaneVO[], step: BaseExperimentStepVOUnion): ExperimentLaneVO[] | void {
	const { id } = step;

	const laneIndexOfThisStep = lanes.findIndex((lane) => lane.steps.some((step) => step.id === id));
	if (laneIndexOfThisStep === undefined || laneIndexOfThisStep === -1) {
		return;
	}

	const indexOfThisStep = lanes[laneIndexOfThisStep].steps.findIndex((step) => step.id === id);
	if (indexOfThisStep === undefined || indexOfThisStep === -1) {
		return;
	}

	const newStep = { ...step, id: uuidv4() };
	const newLanes = [...lanes];
	newLanes[laneIndexOfThisStep].steps.splice(indexOfThisStep + 1, 0, newStep);

	return newLanes;
}

export function getErrorMessages(errors?: string | { [index: string]: unknown }): string[] {
	if (!errors) {
		return [];
	}

	if (typeof errors === 'string') {
		return [errors];
	}

	const messages: string[] = Object.keys(errors || {})
		.map((key) => {
			const value = errors?.[key];
			if (typeof value === 'string') {
				return [key + ': ' + value];
			}
			if (typeof value === 'object') {
				return getErrorMessages(value as { [index: string]: unknown });
			}
			return [''];
		})
		.filter(Boolean)
		.flat();
	return messages;
}

export const ACTION_STYLES: Record<string, CSSProperties> = {
	wait: {
		border: `1px solid ${theme.colors.experimentWait}`,
		backgroundImage: `repeating-linear-gradient(120deg, ${theme.colors.experimentWait}, ${theme.colors.experimentWait} 4px, #aebad1 4px, #aebad1 8px)`,
		backgroundColor: theme.colors.experimentWait,
	},
	action: {
		color: theme.colors.neutral000,
		backgroundColor: theme.colors.experimentOther,
		border: `1px solid ${theme.colors.experimentOther}`,
	},
	action_ATTACK: {
		color: theme.colors.neutral000,
		backgroundColor: theme.colors.experimentAttack,
		border: `1px solid ${theme.colors.experimentAttack}`,
	},
	action_CHECK: {
		color: theme.colors.neutral000,
		backgroundColor: theme.colors.experimentCheck,
		border: `1px solid ${theme.colors.experimentCheck}`,
	},
	action_OTHER: {
		color: theme.colors.neutral000,
		backgroundColor: theme.colors.experimentOther,
		border: `1px solid ${theme.colors.experimentOther}`,
	},
	action_LOAD_TEST: {
		color: theme.colors.purple900,
		backgroundColor: theme.colors.experimentLoadTest,
		border: `1px solid ${theme.colors.experimentLoadTest}`,
		backgroundOrigin: 'border-box',
		backgroundImage: `url(${ActionBackgroundGraph})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'right bottom',
	},
	action_invalid: { color: theme.colors.neutral600, backgroundColor: theme.colors.experimentOtherInvalid },
	action_ATTACK_invalid: { color: theme.colors.neutral600, backgroundColor: theme.colors.experimentAttackInvalid },
	action_CHECK_invalid: { color: theme.colors.neutral600, backgroundColor: theme.colors.experimentCheckInvalid },
	action_OTHER_invalid: { color: theme.colors.neutral600, backgroundColor: theme.colors.experimentOtherInvalid },
	action_LOAD_TEST_invalid: {
		backgroundColor: theme.colors.experimentLoadTestInvalid,
	},
};
