/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Tooltip } from 'components/Tooltip';
import { IconHelp } from 'components/icons';
import { Stack, Text } from 'components';
import { ReactElement } from 'react';

import { THEME } from '../config';

export default function ExplanationIcon(): ReactElement {
	return (
		<Tooltip
			color="light"
			content={
				<Stack size="xxSmall" m="xSmall">
					<Stack direction="horizontal" size="xxSmall">
						<Text variant="smallStrong" color="neutral700">
							Example:
						</Text>

						<Stack direction="horizontal" size="none">
							<Text variant="small" color={THEME.colors.blue}>
								k8s.cluster.name
							</Text>
							<Text variant="small" color={THEME.colors.purple}>
								=
							</Text>
							<Text variant="small" color={THEME.colors.lightBlue} mr="xxSmall">
								{'"prod-eu-central-1"'}
							</Text>
							<Text variant="small" color={THEME.colors.green} mr="xxSmall">
								AND
							</Text>
							<Text variant="small" color={THEME.colors.blue}>
								k8s.namespace
							</Text>
							<Text variant="small" color={THEME.colors.purple}>
								=
							</Text>
							<Text variant="small" color={THEME.colors.lightBlue}>
								{'"checkout"'}
							</Text>
						</Stack>
					</Stack>
					<Stack direction="horizontal" size="xxSmall">
						<Text variant="smallStrong" color="neutral700">
							Ctrl+Space
						</Text>
						<Text variant="small" color="neutral600">
							- Open suggestions.
						</Text>
					</Stack>
				</Stack>
			}
		>
			<div>
				<IconHelp
					sx={{
						position: 'absolute',
						top: 14,
						left: 10,
						zIndex: 1,
						color: 'neutral600',
					}}
				/>
			</div>
		</Tooltip>
	);
}
