/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { LandscapeConfig, LandscapeTarget } from 'targets/Explore/types';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import { CollapsibleV2, Stack, Text, TextField } from 'components';
import { AdviceStatusVO, TargetTypeDescriptionVO } from 'ui-api';
import { IconSearch, IconTargetType } from 'components/icons';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ReactElement, useMemo, useState } from 'react';
import { localeCompareIgnoreCase } from 'utils/string';
import { hasAdvice } from 'targets/Explore/utils';
import { useFormikContext } from 'formik';
import { theme } from 'styles.v2/theme';
import { debounce } from 'lodash';

import AdviceBadges, { GeneralAdvice } from '../../../../components/advice/AdviceBadges';
import { LayoutedTarget } from '../types';
import TargetTable from './TargetTable';

interface TargetsProps {
	selectedTargetId?: string;
	targets: LayoutedTarget[];
	expanded: boolean;
}

export default function Targets({ selectedTargetId, expanded, targets }: TargetsProps): ReactElement {
	const [activeBadges, setActiveBadges] = useState<Array<AdviceStatusVO>>([]);

	const [searchQuery, setSearchQuery] = useState<string>('');
	const [searchFilterQuery, setFilterSearchQueryInternal] = useState<string>('');
	const setFilterSearchQuery = debounce(setFilterSearchQueryInternal, 500);

	const { values } = useFormikContext<LandscapeConfig>();
	const showAdvice = values.showAdvice;

	const targetDefinitionsResult = useTargetDefinitions();
	const targetDefinitions = targetDefinitionsResult.value || [];

	// filter
	let filteredTargets = targets.slice() || [];

	const filteredTargetsBeforeAdvices = useMemo(() => {
		if (searchFilterQuery) {
			return filteredTargets.filter((t) => targetMatchesQuery(t.target, searchFilterQuery, targetDefinitions));
		}
		return filteredTargets;
	}, [searchFilterQuery, targetDefinitions, filteredTargets]);
	filteredTargets = filteredTargetsBeforeAdvices;

	if (activeBadges.length > 0) {
		filteredTargets = filteredTargets.filter((t) => {
			if (!hasAdvice(t.target)) {
				return false;
			}
			if (activeBadges.indexOf('ACTION_NEEDED') >= 0 && t.target.adviceRequireAction > 0) {
				return true;
			}
			if (activeBadges.indexOf('VALIDATION_NEEDED') >= 0 && t.target.adviceRequireValidation > 0) {
				return true;
			}
			if (activeBadges.indexOf('IMPLEMENTED') >= 0 && t.target.adviceDone > 0) {
				return true;
			}
		});
	}

	const categorisedTargets = useMemo(() => {
		const targetsByType: Map<string, LayoutedTarget[]> = filteredTargets.reduce((map, target) => {
			const _targets = map.get(target.target.type) || [];
			_targets.push(target);
			map.set(target.target.type, _targets);
			return map;
		}, new Map<string, LayoutedTarget[]>());

		const sortedTargetsByType = Array.from(targetsByType.entries()).sort((a, b) => {
			const labelA: string = targetDefinitions.find((t) => t.id === a[0])?.label.one || '';
			const labelB: string = targetDefinitions.find((t) => t.id === b[0])?.label.one || '';
			return localeCompareIgnoreCase(labelA, labelB);
		});

		return sortedTargetsByType;
	}, [showAdvice, filteredTargets]);
	const adviceCounts = getAdviceCounts(filteredTargetsBeforeAdvices);

	return (
		<Stack>
			<Stack
				direction="horizontal"
				size="none"
				justifyContent="space-between"
				flexWrap="wrap"
				width="100%"
				sx={{
					gap: '12px',
				}}
			>
				<TextField
					iconLeft={IconSearch}
					placeholder="Search Targets"
					value={searchQuery}
					type="text"
					name="name"
					onChange={(e) => {
						setSearchQuery(e.target.value);
						setFilterSearchQuery(e.target.value);
					}}
					wrapperSx={{ height: 32, maxWidth: expanded ? '428px' : '100%' }}
				/>

				{showAdvice && adviceCounts.total > 0 && (
					<Stack direction="horizontal" alignItems="center">
						<Text variant="smallStrong">Filter Targets by:</Text>
						<AdviceBadges
							{...adviceCounts}
							onBadgeClick={(type) => {
								if (activeBadges.indexOf(type) === -1) {
									setActiveBadges([...activeBadges, type]);
								} else {
									setActiveBadges(activeBadges.filter((t) => t !== type));
								}
							}}
							activeBadges={activeBadges}
						/>
					</Stack>
				)}
			</Stack>
			{categorisedTargets.map(([targetType, _targets]) => {
				const targetDefinition = targetDefinitions.find((t) => t.id === targetType);
				if (!targetDefinition) {
					return null;
				}
				const groupHasAdvice = _targets.some((t) => hasAdvice(t.target));
				const groupHasSelectedTarget = _targets.some((t) => t.target.id === selectedTargetId);

				return (
					<CollapsibleV2
						key={targetType + '-' + groupHasSelectedTarget}
						title={
							<Stack direction="horizontal" size="xSmall" alignItems="center" color="neutral700">
								<IconTargetType targetType={targetDefinition.id} minWidth={16} />
								<Text variant="mediumStrong" sx={{ ...textEllipsis }}>
									{targetDefinition.label.other} (
									{
										_targets.reduce((acc, t) => {
											acc.add(t.target.id);
											return acc;
										}, new Set<string>()).size
									}
									)
								</Text>
							</Stack>
						}
						actions={<>{showAdvice && groupHasAdvice && <GeneralAdvice />}</>}
						backgroundColor={theme.colors.neutral000}
						initialExpanded={groupHasSelectedTarget || categorisedTargets.length === 1}
					>
						<TargetTable
							targetDefinitions={targetDefinitions}
							selectedTargetId={selectedTargetId}
							showAdvices={showAdvice}
							targetType={targetType}
							expanded={expanded}
							targets={_targets}
							key={targetType}
						/>
					</CollapsibleV2>
				);
			})}
		</Stack>
	);
}

function targetMatchesQuery(
	target: LandscapeTarget,
	query: string,
	targetDefinitions: TargetTypeDescriptionVO[],
): boolean {
	const targetDefinition = targetDefinitions.find((t) => t.id === target.type);
	if (!targetDefinition) {
		return target.name.toLowerCase().includes(query.toLowerCase());
	}

	const renderedAttributes = new Set(targetDefinition.table.columns.map((c) => c.attribute));
	const attributeValues = target.attributes.filter((a) => renderedAttributes.has(a.key));
	return attributeValues.some((a) => a.value.toLowerCase().includes(query.toLowerCase()));
}

type Counts = {
	adviceRequireAction: number;
	adviceRequireValidation: number;
	adviceDone: number;
	total: number;
};
function getAdviceCounts(filteredTargetsBeforeAdvices: LayoutedTarget[]): Counts {
	const counts: Counts = {
		adviceRequireAction: 0,
		adviceRequireValidation: 0,
		adviceDone: 0,
		total: 0,
	};

	for (const { target } of filteredTargetsBeforeAdvices) {
		counts.adviceRequireAction += target.adviceRequireAction;
		counts.adviceRequireValidation += target.adviceRequireValidation;
		counts.adviceDone += target.adviceDone;
		counts.total += target.adviceRequireAction + target.adviceRequireValidation + target.adviceDone;
	}

	return counts;
}
