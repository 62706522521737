/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { forwardRef } from 'react';

import { Combobox, ComboContainerProps, Container, Label } from '..';
import { OptionTypeBase } from './SelectOptionTypes';

interface FormComboboxOwnProps {
	name?: string;
	label?: string;
}

export type FormComboContainerProps = FormComboboxOwnProps &
	Omit<ComboContainerProps<OptionTypeBase>, keyof FormComboboxOwnProps>;

export const FormCombobox = forwardRef<HTMLDivElement, FormComboContainerProps>(function FormCombobox(
	{ label, flex, my, mx, mt, mb, ml, mr, ...props }: FormComboContainerProps,
	ref,
) {
	return (
		<Container display={'flex'} flexDirection={'column'} my={my} mx={mx} mt={mt} mb={mb} ml={ml} mr={mr} flex={flex}>
			{label ? (
				<Label>
					{label}
					<Combobox mt={'xSmall'} label={label} {...props} ref={ref} />
				</Label>
			) : (
				<Combobox mt={'xSmall'} {...props} ref={ref} />
			)}
		</Container>
	);
});
