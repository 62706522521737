/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { createRoot } from 'react-dom/client';
import '@fontsource/fira-code/400.css';
import '@fontsource/fira-code/700.css';
import { init } from 'tracking/sentry';
import 'inter-ui/inter.css';

import Foundation from './Foundation';

init();
const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(<Foundation />);
