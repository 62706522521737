/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	Heading,
	ModalContentV2,
	ModalFooterV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	Stack,
	Text,
	Toggle,
} from 'components';
import { LandscapeViewMappedGroupingVO } from 'ui-api';
import { ReactElement, useState } from 'react';
import { useFormikContext } from 'formik';
import { theme } from 'styles.v2/theme';

import ModalMappingsSection, { addUntitledTitles, newMapping, removeEmptyMappings } from './ModalMappingsSection';
import { GroupConfig, LandscapeConfig } from '../types';
import { getGroupingLabelFromUrl } from '../utils';

interface ConfigureGroupingModalProps {
	attributeKey: string;
	readOnly?: boolean;
	index: number;
	onSave: (groupConfigs: Array<GroupConfig | null>) => void;
	onClose: () => void;
}

export default function ConfigureGroupingModal({
	attributeKey,
	readOnly,
	index,
	onClose,
	onSave,
}: ConfigureGroupingModalProps): ReactElement {
	const formik = useFormikContext<LandscapeConfig>();
	const { groupConfigs } = formik.values;

	const [showUnknown, setShowUnknown] = useState(groupConfigs[index]?.showUnknown ?? true);
	const [mergeUnmappedToUnknown, setMergeUnmappedToUnknown] = useState(
		groupConfigs[index]?.mergeUnmappedToUnknown ?? true,
	);

	const [mappings, setMappings] = useState<LandscapeViewMappedGroupingVO[]>(
		groupConfigs[index]?.mappings ? [...groupConfigs[index].mappings] : [newMapping()],
	);
	const attributeLabel = getGroupingLabelFromUrl(attributeKey);

	return (
		<ModalOverlay open centerContent onClose={onClose}>
			{({ close }) => (
				<ModalV2 slick withFooter width="800px">
					<ModalHeaderV2
						title={
							<Stack direction="horizontal" size="xSmall" alignItems="center">
								<Heading variant="large" color="neutral800">
									Configure
								</Heading>
								<Heading variant="large" color="slate">
									{attributeLabel}
								</Heading>
							</Stack>
						}
						subTitle="You can configure your view even more by creating custom buckets and assign ad hoc values for each bucket."
						onClose={close}
					/>
					<ModalContentV2>
						<Stack size="medium" mt="medium">
							<div style={{ height: 1, background: theme.colors.neutral300 }} />
							<Stack direction="horizontal" size="xSmall" alignItems="center" justifyContent="space-between">
								<Text variant="smallStrong" color="neutral600">
									{'Assign unmapped values to "unknown"'}
								</Text>
								<Toggle
									checked={mergeUnmappedToUnknown}
									onChange={(e) => setMergeUnmappedToUnknown(e.target.checked)}
									disabled={readOnly}
								/>
							</Stack>
							<Stack direction="horizontal" size="xSmall" alignItems="center" justifyContent="space-between">
								<Text variant="smallStrong" color="neutral600">
									{'Show "unknown" group'}
								</Text>
								<Toggle checked={showUnknown} onChange={(e) => setShowUnknown(e.target.checked)} disabled={readOnly} />
							</Stack>
							<div style={{ height: 1, background: theme.colors.neutral300 }} />
						</Stack>

						<ModalMappingsSection
							attributeKey={attributeKey}
							mappings={mappings}
							readOnly={readOnly}
							setMappings={setMappings}
						/>
					</ModalContentV2>
					<ModalFooterV2>
						<Stack direction="horizontal" alignItems="center">
							<Button variant="secondary" onClick={close}>
								Cancel
							</Button>
							<Button
								onClick={() => {
									const newGroupConfigs = [...groupConfigs];
									const cleanedMappings = addUntitledTitles(removeEmptyMappings(mappings));
									if (cleanedMappings.length === 0 && showUnknown && mergeUnmappedToUnknown) {
										delete newGroupConfigs[index];
									} else {
										newGroupConfigs[index] = {
											showUnknown,
											mergeUnmappedToUnknown,
											mappings: cleanedMappings,
										};
									}
									onSave(newGroupConfigs);

									close();
								}}
								disabled={readOnly}
							>
								Save configuration
							</Button>
						</Stack>
					</ModalFooterV2>
				</ModalV2>
			)}
		</ModalOverlay>
	);
}
