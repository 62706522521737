/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { useAsyncState } from 'utils/hooks/useAsyncState';
import { toDatetimeUTCValue } from 'utils/dateFns';
import { Services } from 'services/services';
import { ReactElement } from 'react';
import { Text } from 'components';

export default function ServerInfoComponent({ color }: { color?: string }): ReactElement | null {
	const [serverInfo] = useAsyncState(Services.serverInfo.fetchServerInfo);

	if (!serverInfo.value || !serverInfo.value.build) {
		return null;
	}

	return (
		<Text variant="small" muted fontFamily="code" color={color}>
			Version:
			<br />
			{serverInfo.value.build.version}
			<br />
			{toDatetimeUTCValue(new Date(serverInfo.value.build.time))}
		</Text>
	);
}
