/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { useSystemStatus } from 'App/components/SystemStatus/useSystemStatus';
import { Snackbar, userConfirm } from 'components';
import { useCallback } from 'react';

type ExperimentExecutionSystemStatusCheckResult = 'execute' | 'abort-execution';

type ExperimentExecutionSystemStatusCheck = () => Promise<ExperimentExecutionSystemStatusCheckResult>;

const toastId = 'experiment-execution-system-status-check';

export function useExperimentExecutionStatus(): string | undefined {
	const systemStatus = useSystemStatus();
	const ongoingIncident = systemStatus.experimentExecutionTriggerBehavior === 'NO_EXECUTION_DUE_TO_INCIDENT';

	if (!ongoingIncident) {
		return;
	}

	return systemStatus.incident?.severity === 'UNDER_MAINTENANCE'
		? 'Experiment cannot be started due to ongoing maintenance'
		: 'Experiment cannot be started due to ongoing incident';
}

export function useExperimentExecutionSystemStatusCheck(): ExperimentExecutionSystemStatusCheck {
	const systemStatus = useSystemStatus();
	return useCallback(check, [systemStatus]);

	async function check(): Promise<ExperimentExecutionSystemStatusCheckResult> {
		switch (systemStatus.experimentExecutionTriggerBehavior) {
			case 'NO_EXECUTION_DUE_TO_INCIDENT':
				if (systemStatus.incident?.severity === 'UNDER_MAINTENANCE') {
					Snackbar.error('Experiment cannot be started due to ongoing maintenance.', { toastId });
				} else {
					Snackbar.error('Experiment cannot be started due to ongoing incident.', { toastId });
				}
				return 'abort-execution';
			case 'EXECUTE_AFTER_CONFIRMATION_DUE_TO_INCIDENT':
				return askForConfirmationDueToIncident();
			case 'EXECUTE_AFTER_CONFIRMATION_DUE_TO_PENDING_MAINTENANCE':
				return askForConfirmationDuePendingMaintenance();
			default:
				return 'execute';
		}
	}
}

async function askForConfirmationDueToIncident(): Promise<ExperimentExecutionSystemStatusCheckResult> {
	const result = await userConfirm({
		title: 'Ongoing Incident',
		message:
			'There is an ongoing incident impacting the Steadybit platform. Are you sure you want to start the experiment?',
		actions: [
			{ value: 'cancel', label: 'Cancel', variant: 'primary' },
			{ value: 'confirm', label: 'Start Experiment' },
		],
		dataCy: 'running-parallel',
	});

	if (result === 'confirm') {
		return 'execute';
	} else {
		return 'abort-execution';
	}
}

async function askForConfirmationDuePendingMaintenance(): Promise<ExperimentExecutionSystemStatusCheckResult> {
	const result = await userConfirm({
		title: 'Planned Maintenance',
		message: 'Maintenance is scheduled to start soon. Are you sure you want to start the experiment?',
		actions: [
			{ value: 'cancel', label: 'Cancel', variant: 'primary' },
			{ value: 'confirm', label: 'Start Experiment' },
		],
		dataCy: 'running-parallel',
	});

	if (result === 'confirm') {
		return 'execute';
	} else {
		return 'abort-execution';
	}
}
