/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ReactElement, ReactNode } from 'react';
import { Stack } from 'components';

interface SidebarProps {
	children: ReactNode | ReactNode[];
	size: 'small' | 'large';
	scrollable?: boolean;
	p?: string;
}

export default function Sidebar({ size, p, scrollable, children }: SidebarProps): ReactElement {
	return (
		<Stack
			size="large"
			sx={{
				minWidth: size === 'large' ? '512px' : '394px',
				maxWidth: size === 'large' ? '512px' : '394px',
				background: 'neutral000',
				p,
				overflowY: scrollable ? 'auto' : 'hidden',
			}}
		>
			{children}
		</Stack>
	);
}
