/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ReactElement, ReactNode } from 'react';
import { Stack } from 'components/Stack';

interface ListHeaderProps {
	description?: ReactNode;
	right?: ReactNode;
	left: ReactNode;
}

export default function ListHeader({ left, right, description }: ListHeaderProps): ReactElement {
	if (description) {
		return (
			<Stack>
				<Stack direction="horizontal" alignItems="center">
					{left}
				</Stack>
				{description}

				<Stack direction="horizontal" alignItems="center" justifyContent="flex-end">
					{right}
				</Stack>
			</Stack>
		);
	}

	return (
		<Stack direction="horizontal" alignItems="flex-end" justifyContent="space-between">
			{left}
			{right}
		</Stack>
	);
}
