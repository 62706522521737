/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react-markdown/lib/react-markdown';
import SplashLogo from 'components/Branding/SplashLogo';
import { Container } from 'components';
import React from 'react';

export default function SplashScreen({ children }: { children?: React.ReactNode }): ReactElement {
	return (
		<Container
			display={'flex'}
			sx={{
				position: 'absolute',
				top: 0,
				right: 0,
				left: 0,
				bottom: 0,
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				bg: 'neutral000',
				textAlign: 'center',
				color: 'coral',
			}}
		>
			<Container width={'33%'}>
				<SplashLogo />
			</Container>
			{children}
		</Container>
	);
}
