/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Link as RouterLink, LinkProps as RouterLinkProps } from 'url/Link';
import React from 'react';

import { TableSort, TableSortProps } from './TableSort';
import { ButtonProps } from '../Button';

export type TableSortLinkProps = Omit<TableSortProps, keyof ButtonProps> &
	RouterLinkProps<never> & { variant?: string };

export const TableSortLink: React.FC<TableSortLinkProps> = ({ children, variant, ...props }) => {
	return (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		<TableSort variant={variant} as={RouterLink} {...(props as any)}>
			{children}
		</TableSort>
	);
};
