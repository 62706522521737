/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { FieldHookConfig, useField } from 'formik';
import { useEnsuredForwardedRef } from 'react-use';
import { EnvironmentVariableVO } from 'ui-api';
import { Container, Text } from 'components';
import React, { useCallback } from 'react';

import { TextField, TextFieldProps } from '../TextField';
import { IconClose } from '../icons';
import { Label } from '../Label';

type FormikTextFieldOwnProps = {
	name: string;
	variables?: EnvironmentVariableVO[];
	required?: boolean;
};

export type FormikFieldProps = FormikTextFieldOwnProps &
	Omit<FormTextFieldProps, 'error' | 'touched' | keyof FormikTextFieldOwnProps>;

export const FormikTextField = React.forwardRef<HTMLDivElement, FormikFieldProps>((props, ref) => {
	const [field, meta] = useField({ type: 'text', ...props } as FieldHookConfig<string>);
	return <FormTextField ref={ref} {...field} {...meta} {...props} />;
});
FormikTextField.displayName = 'FormikTextField';

export const FormikTextArrayField = React.forwardRef<HTMLDivElement, FormikFieldProps>((props, ref) => {
	const [field, meta, { setValue }] = useField({ type: 'text', ...props } as FieldHookConfig<string>);
	return (
		<FormTextField
			ref={ref}
			{...field}
			{...meta}
			{...props}
			onChange={(e) => {
				const value = e.target.value;
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				setValue(value ? value.split(',') : []);
			}}
		/>
	);
});
FormikTextArrayField.displayName = 'FormikTextArrayField';

interface FormTextFieldOwnProps {
	name?: string;
	label?: string;
	labelVariant?: string;
	error?: string;
	touched?: boolean;
	clearable?: boolean;
	variables?: EnvironmentVariableVO[];
}

type FormTextFieldProps = FormTextFieldOwnProps & Omit<TextFieldProps, 'hasError' | keyof FormTextFieldOwnProps>;

const FormTextField = React.forwardRef<HTMLDivElement, FormTextFieldProps>(
	({ label, labelVariant, error, touched, flex, my, mx, mt, mb, ml, mr, width, clearable, ...props }, ref) => {
		const innerRef = useEnsuredForwardedRef(ref as React.MutableRefObject<HTMLInputElement>);
		const hasError = Boolean(touched && error);

		const setValueOnRef = useCallback(
			(value: string): void => {
				const valueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;
				if (valueSetter) {
					valueSetter.call(innerRef.current, value);
					innerRef.current.dispatchEvent(new Event('input', { bubbles: true }));
				}
			},
			[innerRef],
		);

		let clearableProps = {};
		if (clearable) {
			const handleClearClick = (): void => {
				if (!props.disabled && innerRef.current?.value) {
					setValueOnRef(props.defaultText ?? '');
				}
			};

			clearableProps = {
				iconRight: IconClose,
				onIconRightClick: handleClearClick,
			};
		}

		const textFieldEl = <TextField mt={'xSmall'} ref={innerRef} hasError={hasError} {...props} {...clearableProps} />;

		return (
			<Container
				display={'flex'}
				flexDirection={'column'}
				my={my}
				mx={mx}
				mt={mt}
				mb={mb}
				ml={ml}
				mr={mr}
				width={width}
				flex={flex}
			>
				{label ? (
					<Label variant={labelVariant ? labelVariant : 'mediumStrong'}>
						{label}
						{textFieldEl}
					</Label>
				) : (
					textFieldEl
				)}
				{hasError ? (
					<Text mt={'xxSmall'} variant={'smallStrong'} color={'coral'} data-formik-error>
						{error}
					</Text>
				) : null}
			</Container>
		);
	},
);
FormTextField.displayName = 'FormTextField';
