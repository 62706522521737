/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Texture } from 'three';

export default class ExploreTextures {
	textures = new Map<string, Texture>();

	constructor(icons: Map<string, string>, callback: () => void) {
		const addTexture = (key: string, iconPath: string): void => {
			const canvas = document.createElement('canvas');
			canvas.width = 256;
			canvas.height = 256;

			const img = document.createElement('img');
			img.setAttribute('src', iconPath);

			img.onload = () => {
				const ctx = canvas.getContext('2d');
				if (!ctx) {
					return;
				}

				img.width = img.width === 0 || img.width > 128 ? 256 : img.width;
				img.height = img.height === 0 || img.height > 128 ? 256 : img.height;

				if (key.startsWith('_advice_')) {
					img.width = 256;
					img.height = 256;
					ctx.scale(0.75, 0.75);
					ctx.drawImage(img, 50, 50);
				} else {
					ctx.scale(256 / img.width, 256 / img.height);
					ctx.drawImage(img, 0, 0);
				}

				const texture = new Texture(canvas);
				texture.needsUpdate = true;
				this.textures.set(key, texture);
				callback();
			};
		};
		for (const [targetType, iconPath] of icons) {
			addTexture(targetType, iconPath);
		}
		addTexture(
			'_advice_action_needed',
			// eslint-disable-next-line quotes
			"data:image/svg+xml,%3csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M22.4%2c7.6c0%2c0.1%2c0%2c0.3-0.1%2c0.4l-4.6%2c8.4c-0.1%2c0.2-0.5%2c0.1-0.5-0.1v-5.7c0-0.1-0.1-0.3-0.3-0.3h-2.1 c-0.1%2c0-0.2%2c0-0.4-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1%2c0-0.3%2c0.1-0.4l4.6-8.4c0.1-0.2%2c0.5-0.1%2c0.5%2c0.1v5.7 c0%2c0.1%2c0.1%2c0.3%2c0.3%2c0.3h2.1c0.1%2c0%2c0.2%2c0%2c0.4%2c0.1c0.1%2c0.1%2c0.2%2c0.2%2c0.3%2c0.3C22.4%2c7.3%2c22.4%2c7.4%2c22.4%2c7.6z M13.4%2c1.8 c0%2c0-0.7-0.1-1.3-0.3c-0.3-0.1-0.6-0.1-0.9-0.2c-0.1%2c0-0.2%2c0-0.3-0.1c0%2c0-0.1%2c0-0.1%2c0c0%2c0-0.1%2c0-0.1%2c0c0%2c0-0.1%2c0-0.1%2c0l0%2c0h0 c0%2c0-0.2%2c0-0.3%2c0L2.9%2c3c-0.4%2c0.1-0.7%2c0.3-1%2c0.6C1.7%2c3.9%2c1.6%2c4.3%2c1.6%2c4.7v8.5c0%2c2.5%2c1%2c4.9%2c2.7%2c6.6c0.9%2c0.9%2c2.5%2c1.8%2c3.7%2c2.4 c0.6%2c0.3%2c1.2%2c0.6%2c1.6%2c0.8c0.2%2c0.1%2c0.4%2c0.2%2c0.5%2c0.2c0.1%2c0%2c0.1%2c0%2c0.1%2c0.1l0%2c0l0%2c0l0.5%2c0.2l0.5-0.2l0%2c0l0%2c0l0%2c0l0.1%2c0 c0.1%2c0%2c0.2-0.1%2c0.4-0.2c0.3-0.1%2c0.8-0.3%2c1.3-0.6c1-0.5%2c2.3-1.1%2c3.3-1.9c0.6-0.4%2c0.7-1.2%2c0.3-1.8c-0.4-0.6-1.2-0.7-1.8-0.3 c-0.8%2c0.6-1.9%2c1.2-2.9%2c1.6c-0.5%2c0.2-0.9%2c0.4-1.2%2c0.5l-0.1-0.1c-0.4-0.2-0.9-0.4-1.5-0.7c-1.2-0.6-2.4-1.3-3-1.9 c-1.2-1.3-1.9-3-1.9-4.8V5.4l6.5-1.5l0.1%2c0c0.2%2c0%2c0.5%2c0.1%2c0.8%2c0.2c0.3%2c0.1%2c0.6%2c0.1%2c0.9%2c0.2l0.3%2c0.1l0.1%2c0l0%2c0c0%2c0%2c0%2c0%2c0%2c0 c0.7%2c0.2%2c1.4-0.3%2c1.5-1C14.6%2c2.7%2c14.1%2c2%2c13.4%2c1.8z' fill='currentColor'/%3e%3c/svg%3e",
		);
		addTexture(
			'_advice_validation',
			// eslint-disable-next-line quotes
			"data:image/svg+xml,%3csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='currentColor' d='M12.8%2c1.1c0%2c0-0.7-0.1-1.3-0.3c-0.3-0.1-0.6-0.1-0.9-0.2c-0.1%2c0-0.2%2c0-0.3-0.1c0%2c0-0.1%2c0-0.1%2c0c0%2c0-0.1%2c0-0.1%2c0 c0%2c0-0.1%2c0-0.1%2c0l0%2c0h0c0%2c0-0.2%2c0-0.3%2c0L1.9%2c2.3c-0.4%2c0.1-0.7%2c0.3-1%2c0.6C0.7%2c3.3%2c0.5%2c3.6%2c0.5%2c4v8.8c0%2c2.6%2c1%2c5%2c2.8%2c6.9 c0.9%2c1%2c2.6%2c1.9%2c3.8%2c2.5c0.6%2c0.3%2c1.2%2c0.6%2c1.7%2c0.8c0.2%2c0.1%2c0.4%2c0.2%2c0.5%2c0.2c0.1%2c0%2c0.1%2c0%2c0.1%2c0.1l0%2c0l0%2c0l0.5%2c0.2l0.5-0.2l0%2c0l0%2c0l0%2c0 l0.1%2c0c0.1%2c0%2c0.2-0.1%2c0.4-0.2c0.3-0.1%2c0.8-0.3%2c1.3-0.6c1-0.5%2c2.4-1.2%2c3.4-1.9c0.6-0.4%2c0.7-1.3%2c0.3-1.9c-0.4-0.6-1.3-0.7-1.9-0.3 c-0.8%2c0.6-2%2c1.2-3%2c1.7c-0.5%2c0.2-0.9%2c0.4-1.2%2c0.6l-0.1-0.1c-0.4-0.2-1-0.4-1.6-0.7c-1.3-0.6-2.5-1.4-3.1-2c-1.3-1.3-2-3.1-2-5V4.7 l6.7-1.5l0.1%2c0c0.2%2c0%2c0.6%2c0.1%2c0.9%2c0.2c0.3%2c0.1%2c0.6%2c0.1%2c0.9%2c0.2l0.3%2c0.1l0.1%2c0h0c0%2c0%2c0%2c0%2c0%2c0c0.7%2c0.2%2c1.4-0.3%2c1.6-1 C14%2c1.9%2c13.5%2c1.2%2c12.8%2c1.1z M12.2%2c12.3c0.9%2c0%2c1.6-0.7%2c1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9%2c0-1.6%2c0.7-1.6%2c1.6 C10.5%2c11.6%2c11.3%2c12.3%2c12.2%2c12.3z M17%2c12.3c0.9%2c0%2c1.6-0.7%2c1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9%2c0-1.6%2c0.7-1.6%2c1.6 C15.4%2c11.6%2c16.1%2c12.3%2c17%2c12.3z M23.5%2c10.7c0%2c0.9-0.7%2c1.6-1.6%2c1.6c-0.9%2c0-1.6-0.7-1.6-1.6c0-0.9%2c0.7-1.6%2c1.6-1.6 C22.8%2c9.1%2c23.5%2c9.8%2c23.5%2c10.7z'/%3e%3c/svg%3e",
		);
		addTexture(
			'_advice_implemented',
			// eslint-disable-next-line quotes
			"data:image/svg+xml,%3csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='currentColor' d='M16.4%2c18.7c0.4%2c0.6%2c0.3%2c1.4-0.3%2c1.9c-1%2c0.8-2.4%2c1.4-3.4%2c1.9c-0.5%2c0.2-1%2c0.5-1.3%2c0.6c-0.2%2c0.1-0.3%2c0.1-0.4%2c0.2l-0.1%2c0l0%2c0 l0%2c0l0%2c0l-0.5%2c0.2l-0.5-0.2l0%2c0l0%2c0c0%2c0-0.1%2c0-0.1-0.1c-0.1-0.1-0.3-0.1-0.5-0.2c-0.4-0.2-1-0.5-1.7-0.8c-1.2-0.6-2.9-1.5-3.8-2.5 C1.9%2c17.9%2c1%2c15.4%2c1%2c12.9V4c0-0.4%2c0.1-0.8%2c0.4-1.1c0.2-0.3%2c0.6-0.5%2c1-0.6L10%2c0.5c0.2%2c0%2c0.3%2c0%2c0.3%2c0h0c0%2c0%2c0.1%2c0%2c0.1%2c0 c0%2c0%2c0.1%2c0%2c0.1%2c0c0%2c0%2c0.1%2c0%2c0.1%2c0c0.1%2c0%2c0.2%2c0%2c0.3%2c0.1c0.3%2c0%2c0.6%2c0.1%2c0.9%2c0.2c0.6%2c0.1%2c1.3%2c0.3%2c1.3%2c0.3c0.7%2c0.2%2c1.2%2c0.9%2c1%2c1.6 c-0.2%2c0.7-0.9%2c1.2-1.6%2c1c0%2c0%2c0%2c0%2c0%2c0l-0.1%2c0l-0.3-0.1c-0.2-0.1-0.6-0.1-0.9-0.2c-0.3-0.1-0.6-0.1-0.9-0.2l-0.1%2c0L3.6%2c4.7v8.1 c0%2c1.9%2c0.7%2c3.7%2c2%2c5c0.6%2c0.6%2c1.8%2c1.3%2c3.1%2c2c0.6%2c0.3%2c1.2%2c0.5%2c1.6%2c0.7l0.1%2c0.1c0.3-0.1%2c0.7-0.3%2c1.2-0.6c1-0.5%2c2.1-1.1%2c3-1.7 C15.2%2c18%2c16%2c18.1%2c16.4%2c18.7z M22.6%2c4.1c-0.5-0.5-1.3-0.4-1.8%2c0.1L15%2c10.7L12.6%2c8c-0.5-0.5-1.3-0.6-1.8-0.1c-0.5%2c0.5-0.6%2c1.3-0.1%2c1.8 l3.4%2c3.8c0.2%2c0.3%2c0.6%2c0.4%2c1%2c0.4c0.4%2c0%2c0.7-0.2%2c1-0.4l6.7-7.5C23.2%2c5.4%2c23.1%2c4.6%2c22.6%2c4.1z'/%3e%3c/svg%3e",
		);
	}

	get(targetType: string): Texture | undefined {
		return this.textures.get(targetType);
	}
}
