/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import ExperimentPreview from 'pages/templates/components/ExperimentPreview';
import { useStableErrorMap } from 'pages/experimentsV2/useFieldErrors';
import { DataStreamResult } from 'utils/hooks/stream/result';
import Markdown from 'components/Markdown/Markdown';
import { ActionVO, OccuranceVO } from 'ui-api';
import { useFormikContext } from 'formik';
import { Stack, Text } from 'components';
import { ReactElement } from 'react';

import { isOccuranceActionPredicateVO, isOccuranceStepLabelVO, isOccuranceStepParameterVO } from '../types';
import { UseTemplateFormData } from '../UseTemplateForm';
import OccuranceValue from './OccuranceValue';

interface TemplatePlaceholderProps {
	actionsResult: DataStreamResult<ActionVO[]>;
	placeholder: Placeholder;
}

export interface Placeholder {
	description: string;
	value: string;
	name: string;
	key: string;
}

export default function TemplatePlaceholder({ placeholder, actionsResult }: TemplatePlaceholderProps): ReactElement {
	const { values, setFieldValue } = useFormikContext<UseTemplateFormData>();

	const selectedPlaceholder = values.placeholdersMap.get(placeholder.key);

	const selectedStepIds: Set<string> = new Set();
	if (selectedPlaceholder) {
		collectStepIds(selectedPlaceholder, selectedStepIds);
	}

	const stepIdToError = useStableErrorMap();

	return (
		<Stack size="large" pb="xLarge">
			<Stack size="small">
				<Markdown content={placeholder.description} />
				<OccuranceValue
					value={placeholder.value}
					setValue={(_value) => {
						const clone = new Map(values.placeholderValuesMap);
						clone.set(placeholder.key, _value);
						setFieldValue('placeholderValuesMap', clone);
					}}
					occurances={values.placeholdersMap.get(placeholder.key) || []}
				/>
			</Stack>
			{selectedStepIds.size > 0 && (
				<Stack size="xSmall">
					<Text variant="small" color="neutral800">
						<Text variant="smallStrong" color="neutral800" as={'span'}>
							{placeholder.name}
						</Text>
						&nbsp;is used in the following steps:
					</Text>

					<ExperimentPreview
						key={placeholder.key}
						actions={actionsResult.value || []}
						selectedStepIds={selectedStepIds}
						stepIdToError={stepIdToError}
						lanes={values.lanes}
					/>
				</Stack>
			)}
		</Stack>
	);
}

function collectStepIds(occurances: OccuranceVO[], ids: Set<string>): void {
	occurances.forEach((occurance) => {
		if (isOccuranceActionPredicateVO(occurance)) {
			ids.add(occurance.stepId);
		} else if (isOccuranceStepLabelVO(occurance)) {
			ids.add(occurance.stepId);
		} else if (isOccuranceStepParameterVO(occurance)) {
			ids.add(occurance.stepId);
		}
	});
}
