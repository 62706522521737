/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { BaseExperimentStepExecutionVOUnion, BaseExperimentStepVOUnion } from 'ui-api';
import { Container, Stack, Text } from 'components';
import { ActionInfoBox } from 'hocs/ActionInfo';
import { IconWarning } from 'components/icons';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import { useEditorSettings } from '../useEditorSettings';

interface StepTooltipContentProps {
	step: BaseExperimentStepVOUnion | BaseExperimentStepExecutionVOUnion;
	errors: string[];
}

export default function StepTooltipContent({ step, errors }: StepTooltipContentProps): ReactElement {
	const { mode } = useEditorSettings();

	if (step.type === 'wait') {
		return (
			<>
				<Text variant="smallStrong">{step.customLabel ? step.customLabel : 'Wait'}</Text>
				<Stack size="none" mt="xxSmall">
					{step.customLabel ? <Text variant="small">Type: Wait</Text> : null}
					<Text variant="small">Duration: {step.parameters.duration}</Text>
				</Stack>
			</>
		);
	}

	const errorColor = mode === 'templateUsage' ? theme.colors.experimentWarning : theme.colors.coral;

	return (
		<div
			style={{
				border: errors.length > 0 ? '2px solid ' + errorColor : undefined,
				margin: '-5px -8px',
				padding: '8px',
				borderRadius: '4px',
			}}
		>
			{errors.length > 0 && (
				<>
					<Container display="flex" alignItems="center" py="xSmall" color={errorColor}>
						<IconWarning variant="small" mr="xxSmall" />
						<Text variant="smallStrong">Errors:</Text>
					</Container>
					<Stack size="xxSmall" pb="xSmall">
						{errors.map((error) => (
							<Text key={error} variant="small" color={errorColor}>
								{error}
							</Text>
						))}
					</Stack>
				</>
			)}
			<ActionInfoBox value={step} />
		</div>
	);
}
