/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { createIconFromDataUri } from 'components/icons';
import { Pill, Stack, Text } from 'components';
import { ReactElement } from 'react';

interface TargetTypeProps {
	type: string;
	icon: string | undefined;
}

export default function TargetTypeBadge({ type, icon }: TargetTypeProps): ReactElement {
	const Icon = icon ? createIconFromDataUri(icon) : undefined;

	return (
		<Stack direction="horizontal" size="xSmall" alignItems="center">
			<Text variant="xSmallStrong" minWidth={24}>
				Type:
			</Text>
			<Pill color="neutral800" backgroundColor="neutral200">
				<>
					{Icon && <Icon width={12} height={12} minWidth={12} minHeight={12} mr="xxSmall" />}
					<Text variant="small" sx={{ ...textEllipsis }}>
						{type}
					</Text>
				</>
			</Pill>
		</Stack>
	);
}
