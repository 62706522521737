/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import { ContextualMenuButton, ContextualMenuText, Stack, Text } from 'components';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { Services } from 'services/services';
import { ReactElement } from 'react';
import { TenantVO } from 'ui-api';

export function TenantMenu({ onSelect }: { onSelect: (tenant: TenantVO) => void }): ReactElement {
	const [tenants] = useAsyncState(() => Services.tenants.fetchTenants(), [], []);

	if (tenants.loading) {
		return (
			<DropdownContentFrame>
				<ContextualMenuText>
					<Text muted>loading...</Text>
				</ContextualMenuText>
			</DropdownContentFrame>
		);
	}

	if (tenants.error) {
		return (
			<DropdownContentFrame>
				<ContextualMenuText variant={'error'}>{tenants.error + ''}</ContextualMenuText>
			</DropdownContentFrame>
		);
	}

	return (
		<DropdownContentFrame>
			<Stack size="none">
				{tenants.value.map((tenant) => (
					<ContextualMenuButton key={tenant.key} onClick={() => onSelect(tenant)}>
						{tenant.name}
					</ContextualMenuButton>
				))}
			</Stack>
		</DropdownContentFrame>
	);
}
