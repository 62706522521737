/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	ButtonGroup,
	Container,
	Divider,
	Heading,
	NumberInput,
	Stack,
	StyleProp,
	Text,
	Tooltip,
} from 'components';
import { default as DropDownButton, default as DropdownButton } from 'components/Select/Dropdown/DropdownButton';
import { ComparableValueVOUnion, ConditionTypeVO, MetricCheckVO, MetricQueryVO, MetricValueVO } from 'ui-api';
import { IconAdd, IconDelete, IconMetricCheck } from 'components/icons';
import { ExperimentFormValues } from 'pages/experiments/experiment';
import Labels from 'components/Select/Dropdown/presets/Labels';
import { Fragment, ReactElement } from 'react';
import { useFormikContext } from 'formik';
import { theme } from 'styles.v2/theme';

import EmptyStateWrapper from './EmptyStateWrapper';

interface MetricChecksProps {
	checks: MetricCheckVO[];
	queries: MetricQueryVO[];
	addNewCheck: () => void;
	saveCheck: (check: MetricCheckVO) => void;
	deleteCheck: (check: MetricCheckVO) => void;
	stepPath: string;
	disabled: boolean;
}

const dropDownButtonStyle: StyleProp = {
	p: 'xSmall',
	border: '1px solid ' + theme.colors.neutral400,
};

export default function MetricChecks({
	checks,
	queries,
	addNewCheck,
	stepPath,
	saveCheck,
	deleteCheck,
	disabled,
}: MetricChecksProps): ReactElement | null {
	const formik = useFormikContext<ExperimentFormValues>();
	const { setFieldValue } = formik;

	if (queries.length === 0) {
		return null;
	}

	if (checks.length === 0) {
		return (
			<EmptyStateWrapper
				title="Metric Checks"
				description="Metric checks help define pre- and post-conditions, and invariants that need to be maintained throughout an experiment. You may use metric checks for threshold comparisons or something as advanced as Prometheus-like alert rules."
				icon={<IconMetricCheck minWidth={40} minHeight={40} color="slate" />}
			>
				<Button onClick={addNewCheck} disabled={disabled}>
					<IconAdd mr="xSmall" />
					Add Check
				</Button>
			</EmptyStateWrapper>
		);
	}

	return (
		<Stack size="medium">
			<Heading variant="small" mb="small">
				Step fails when…
			</Heading>

			{checks.map((check, i) => {
				const { id, condition, a, b } = check;

				return (
					<Fragment key={id}>
						<Stack direction="horizontal" size="small">
							<Container
								sx={{
									display: 'grid',
									gridTemplateColumns: '160px 1fr 1fr 1fr 1fr 1fr 1fr',
									gap: 'small',
									alignItems: 'center',
									width: '100%',
								}}
							>
								<Text variant="smallMedium">Metric</Text>

								<GridValue size="medium">
									<DropdownButton
										variant="chromeless"
										sx={dropDownButtonStyle}
										value={queries.filter((q) => q.id === a.metric.steadybit_metric_query_id)[0]?.label || ''}
										disabled={disabled}
										width="100%"
										onValueChanged={(id) => {
											setFieldValue(
												`${stepPath}.metricChecks[${i}]`,
												{
													...check,
													a: {
														...a,
														metric: {
															...a.metric,
															steadybit_metric_query_id: id,
														},
													},
												},
												false,
											);
										}}
									>
										{({ selectItem, width }) => (
											<Labels type="strict" onSelect={({ id }) => selectItem(id)} labels={queries} width={width} />
										)}
									</DropdownButton>
								</GridValue>

								<Text variant="smallMedium">Condition</Text>
								<GridValue size="medium">
									<Condition
										condition={condition}
										onChange={(_condition) =>
											saveCheck({
												...check,
												condition: _condition,
											})
										}
										disabled={disabled}
									/>
								</GridValue>

								{b && condition !== 'DATA_SERIES_PRESENCE' && (
									<>
										<ButtonGroup
											options={[
												{
													label: 'Value',
													onClick: () => {
														if (valueIsMetric(b)) {
															saveCheck({
																...check,
																b: { type: 'scalar', value: 0 },
															});
														}
													},
													active: !valueIsMetric(b),
												},
												{
													label: 'Metric',
													onClick: () => {
														if (!valueIsMetric(b)) {
															saveCheck({
																...check,
																b: {
																	type: 'metric',
																	metric: {
																		steadybit_metric_query_id: queries[0].id,
																	},
																},
															});
														}
													},
													active: valueIsMetric(b),
													disabled: queries.length <= 1,
												},
											]}
										/>
										{valueIsMetric(b) ? (
											<GridValue size="medium">
												<DropdownButton
													variant="chromeless"
													sx={dropDownButtonStyle}
													value={queries.filter((q) => q.id === b.metric.steadybit_metric_query_id)[0]?.label || ''}
													disabled={disabled}
													width="100%"
													onValueChanged={(id) => {
														setFieldValue(
															`${stepPath}.metricChecks[${i}]`,
															{
																...check,
																b: {
																	...b,
																	metric: {
																		...b.metric,
																		steadybit_metric_query_id: id,
																	},
																},
															},
															false,
														);
													}}
												>
													{({ selectItem, width }) => (
														<Labels
															type="strict"
															onSelect={({ id }) => selectItem(id)}
															labels={queries}
															width={width}
														/>
													)}
												</DropdownButton>
											</GridValue>
										) : (
											<GridValue size="medium">
												<NumberInput
													placeholder="Value"
													type={'number'}
													value={b.value}
													onChange={(value) =>
														saveCheck({
															...check,
															b: {
																...b,
																value,
															},
														})
													}
													max={Infinity}
													disabled={disabled}
												/>
											</GridValue>
										)}
									</>
								)}
							</Container>
							<DeleteIcon onClick={() => deleteCheck(check)} disabled={disabled} />
						</Stack>
						<Divider key={`${id}_divider`} variant="left" my="xSmall">
							OR
						</Divider>
					</Fragment>
				);
			})}

			<Container ml="auto">
				<Button disabled={disabled} variant="chromelessSmall" color="slate" mr={-16} onClick={addNewCheck}>
					<IconAdd mr="xxSmall" /> Add new Check
				</Button>
			</Container>
		</Stack>
	);
}

function valueIsMetric(value: ComparableValueVOUnion): value is MetricValueVO {
	return value.type === 'metric';
}

interface GridValueProps {
	children: ReactElement;
	size: 'large' | 'medium';
}

function GridValue({ children, size }: GridValueProps): ReactElement {
	const span = size === 'large' ? 6 : 4;
	return (
		<Container
			sx={{
				gridColumnStart: 8 - span,
				gridColumnEnd: `span ${span}`,
			}}
		>
			{children}
		</Container>
	);
}

interface ConditionProps {
	condition: ConditionTypeVO;
	onChange: (condition: ConditionTypeVO) => void;
	disabled: boolean;
}

function Condition({ condition, onChange, disabled }: ConditionProps): ReactElement {
	return (
		<DropDownButton
			variant="chromeless"
			sx={dropDownButtonStyle}
			value={getConditionLabel(condition)}
			onValueChanged={(c: string) => onChange(c as ConditionTypeVO)}
			disabled={disabled}
			width="100%"
		>
			{({ selectItem }) => (
				<Labels
					type="strict"
					onSelect={({ id }) => selectItem(id)}
					labels={[
						{ id: 'LT', label: '<' },
						{ id: 'LTE', label: '<=' },
						{ id: 'EQ', label: '=' },
						{ id: 'NEQ', label: '!=' },
						{ id: 'GTE', label: '>=' },
						{ id: 'GT', label: '>' },
						{ id: 'DATA_SERIES_PRESENCE', label: 'is present' },
					]}
					width={140}
				/>
			)}
		</DropDownButton>
	);
}

type ConditionLabel = '<' | '<=' | '>' | '>=' | '=' | '!=' | 'is present';

function getConditionLabel(condition: ConditionTypeVO): ConditionLabel {
	if (condition === 'LTE') {
		return '<=';
	} else if (condition === 'LT') {
		return '<';
	} else if (condition === 'GTE') {
		return '>=';
	} else if (condition === 'GT') {
		return '>';
	} else if (condition === 'EQ') {
		return '=';
	} else if (condition === 'NEQ') {
		return '!=';
	}
	return 'is present';
}

interface DeleteIconProps {
	onClick: () => void;
	disabled: boolean;
}

function DeleteIcon({ onClick, disabled }: DeleteIconProps): ReactElement {
	return (
		<Tooltip content="Delete Check">
			<IconDelete
				disabled={disabled}
				onClick={onClick}
				sx={{
					minWidth: 24,
					mt: 'xSmall',
					cursor: 'pointer',
					color: 'neutral600',
					'&:hover': {
						color: 'neutral800',
					},
				}}
			/>
		</Tooltip>
	);
}
