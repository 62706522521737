/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React from 'react';

type TabOwnProps = {
	value?: string;
	onChange?: (value: string) => void;
};

export const TabContext = React.createContext<TabOwnProps>({
	value: undefined,
	onChange: undefined,
});

type TabsProps = TabOwnProps & Omit<ContainerProps, 'css' | keyof TabOwnProps>;

export const Tabs: React.FC<TabsProps> = ({ children, sx = {}, value, onChange, ...props }) => (
	<Container
		{...props}
		sx={{
			border: '1px solid',
			borderColor: 'neutral300',
			borderRadius: '2px',
			...sx,
		}}
	>
		<TabContext.Provider value={{ value, onChange }}>{children}</TabContext.Provider>
	</Container>
);
