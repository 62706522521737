/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React from 'react';

export type TableHeadProps = ContainerProps & Omit<React.HTMLAttributes<HTMLTableSectionElement>, keyof ContainerProps>;

export const TableHead = React.forwardRef<HTMLTableSectionElement, TableHeadProps>((props, ref) => (
	<Container ref={ref} as="thead" {...props} />
));
TableHead.displayName = 'TableHead';
