/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { createIconFromDataUri, IconTarget } from 'components/icons';
import { TargetTypeDescriptionVO } from 'ui-api';
import { Pill, Text, Tooltip } from 'components';
import { ReactElement } from 'react';

interface TargetsProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	targets: string[];
}

export default function Targets({ targets, targetDefinitions }: TargetsProps): ReactElement | null {
	if (targets.length === 0) {
		return null;
	}

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: '47px calc(100% - 47px)',
			}}
		>
			<Text variant="xSmallStrong" color="neutral600" mt="4px">
				Targets:
			</Text>

			<div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
				{targets.map((target, i) => {
					const resolvedTargetDefinition = targetDefinitions.find((targetDefinition) => targetDefinition.id === target);
					const Icon = createIconFromDataUri(resolvedTargetDefinition?.icon);

					return (
						<Tooltip key={i} content={resolvedTargetDefinition?.label.one || ''}>
							<div>
								<Pill
									backgroundColor="neutral200"
									backgroundColorOnHover="neutral300"
									color="neutral700"
									sx={{
										height: '24px',
										width: '24px',
										borderRadius: '50%',
										p: '0',
									}}
									onClick={() => {}}
								>
									{resolvedTargetDefinition?.icon ? <Icon variant="small" /> : <IconTarget variant="small" />}
								</Pill>
							</div>
						</Tooltip>
					);
				})}
			</div>
		</div>
	);
}
