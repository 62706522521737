/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Select } from 'components';
import React from 'react';

const roleAdminOption = { label: 'Admin', value: 'ADMIN' };
const roleUserOption = { label: 'User', value: 'USER' };
const roleOptions = [roleAdminOption, roleUserOption];

export const RoleSelect: React.VFC<{ value?: string; onChange: (value: string) => void; width?: string }> = ({
	value,
	onChange,
	width = '100px',
}) => {
	return (
		<Select
			variant={'chromeless'}
			width={width}
			value={roleOptions.find((o) => o.value === value)}
			options={roleOptions}
			onChange={(option) => {
				if (option && 'value' in option && option.value !== value) {
					onChange(option.value);
				}
			}}
		/>
	);
};
