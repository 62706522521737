/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useStableInstance } from 'utils/hooks/useStableInstance';
import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { get } from 'lodash';

import { ExperimentFormValues } from './types';
import { getErrorMessages } from './utils';

export default function useFieldErrors(field: string): string[] {
	const [, { error }] = useField(field);
	return getErrorMessages(error);
}

/**
 * Validation errors are returned only on an index based object e.g. lanes[0].steps[0].param = "not valid".
 * When we now drag in a new step, the indices of the steps change, but the error object is still the same until validation is done again.
 * As a result, steps have the wrong error messages.
 * Therefore, this hook returns a stable map of errors which resolve a stepId to the error messages in the error object.
 */
export function useStableErrorMap(): Map<string, string[]> {
	const [map, setMap] = useState(() => new Map<string, string[]>());
	const { values, errors } = useFormikContext<ExperimentFormValues>();

	const hash = useStableInstance(errors);
	useEffect(() => {
		const newMap = new Map<string, string[]>();
		if (!errors.lanes) {
			setMap(newMap);
			return;
		}
		const lanes = values.lanes;

		for (let iL = 0; iL < lanes.length; iL++) {
			const lane = lanes[iL];
			for (let iS = 0; iS < lane.steps.length; iS++) {
				const step = lane.steps[iS];
				const stepErrors = get(errors, `lanes[${iL}].steps[${iS}]`);
				if (stepErrors) {
					newMap.set(step.id, getErrorMessages(stepErrors));
				}
			}
		}

		setMap(newMap);
	}, [hash]);

	return map;
}
