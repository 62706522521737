/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ExperimentPlayerStepId, ExperimentPlayerTimeStamp } from 'components/ExperimentPlayer/types';
import { ExperimentExecutionVO } from 'ui-api';
import { Container } from 'components';
import React, { useMemo } from 'react';

import ExperimentExecutionLog from './experimentExecutionLog';
import ExperimentRunCard from './ExperimentRunCard';
import ExecutionMetrics from './ExecutionMetrics';
import Widgets from './widgets/Widgets';

export type ExperimentRunLogsAndMetricsProps = {
	experimentExecution: ExperimentExecutionVO;
	start: number;
	duration: number;
	progress?: number;
	position: ExperimentPlayerTimeStamp | null;
	onPositionSelect: (position: ExperimentPlayerTimeStamp | null) => void;
	selectedLogLevel?: string[];
	setAvailableLogLevel?: (availableLogLevel: string[]) => void;
	setSelectedStepId: (selectedStepId: ExperimentPlayerStepId | null) => void;
	selectedStepId: ExperimentPlayerStepId | null;
};

export const ExperimentExecutionLogsAndMetrics: React.VFC<
	ExperimentRunLogsAndMetricsProps & {
		hoveredStepId: string | null;
		setHoveredStepId: (id: string | null) => void;
	}
> = (props) => {
	const { experimentExecution, selectedStepId, setSelectedStepId, hoveredStepId, setHoveredStepId } = props;
	const metricQueries = useMemo(() => {
		const queries = [];
		for (let iL = 0; iL < experimentExecution.lanes.length; iL++) {
			const { steps } = experimentExecution.lanes[iL];
			for (let iS = 0; iS < steps.length; iS++) {
				const { metricQueries } = steps[iS];
				if (metricQueries && metricQueries.length > 0) {
					queries.push(...metricQueries);
				}
			}
		}
		return queries;
	}, [experimentExecution.id]);

	return (
		<Container
			sx={{
				display: 'grid',
				gridTemplateColumns: '1fr 1fr',
				alignContent: 'start',
				gridGap: 'small',
				p: 'small',
				bg: 'neutral100',
				flex: '1 1 auto',
			}}
		>
			<ExperimentRunCard title={'Run Status'}>
				<ExperimentExecutionLog
					experimentExecution={experimentExecution}
					selectedStepId={selectedStepId}
					setSelectedStepId={setSelectedStepId}
					hoveredStepId={hoveredStepId}
					setHoveredStepId={setHoveredStepId}
				/>
			</ExperimentRunCard>
			<Widgets {...props} />
			{metricQueries.length > 0 && (
				<Container sx={{ gridColumn: 'span 2' }}>
					<ExecutionMetrics metricQueries={metricQueries} experimentExecution={experimentExecution} />
				</Container>
			)}
		</Container>
	);
};
