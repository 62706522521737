/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

export function set(tenant: string, key: string, value: string): void {
	localStorage.setItem(`${tenant}-${key}`, value);
}

export function setGlobal(key: string, value: string): void {
	localStorage.setItem(key, value);
}

export function get(tenant: string, key: string): string | null {
	const value = localStorage.getItem(`${tenant}-${key}`);
	return value ? value : getGlobal(key);
}

export function getGlobal(key: string): string | null {
	return localStorage.getItem(key);
}

export function remove(tenant: string, key: string): void {
	localStorage.removeItem(`${tenant}-${key}`);
	// remove old items as well
	removeGlobal(key);
}

export function removeGlobal(key: string): void {
	localStorage.removeItem(key);
}
