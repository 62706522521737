/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import React, { useMemo } from 'react';

import { NumberInput, NumberInputProps } from '../NumberInput';
import { Select } from '../Select';
import { Stack } from '../Stack';

const OPTION_ALL_CORES = { label: 'All Cores', value: 'per_core' };
const OPTION_NUMBER_OF_CORES = { label: 'Cores', value: 'number' };
const OPTIONS = [OPTION_ALL_CORES, OPTION_NUMBER_OF_CORES];

export const StressngWorkerInput: React.VFC<NumberInputProps> = ({
	value,
	onChange,
	width,
	height,
	minWidth,
	minHeight,
	...props
}) => {
	const optionValue = useMemo(() => ((value ?? 0) === 0 ? OPTION_ALL_CORES : OPTION_NUMBER_OF_CORES), [value]);
	const showNumberInput = optionValue === OPTION_NUMBER_OF_CORES;
	return (
		<Stack direction={'horizontal'} size={'xxSmall'} sx={{ width, height, minWidth, minHeight }}>
			{showNumberInput ? (
				<NumberInput value={value} onChange={onChange} {...props} sx={{ borderRadius: '4px 0 0 4px' }} min={1} />
			) : null}
			<Select
				sx={{
					flex: '1 0 auto',
					'[data-select-button]': {
						height: '100%',
					},
				}}
				options={OPTIONS}
				value={optionValue}
				onChange={(v) => onChange?.(v === OPTION_NUMBER_OF_CORES ? 1 : 0)}
			/>
		</Stack>
	);
};
