/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { IconAgent, IconWarningCircleOpen } from 'components/icons';
import { Container, RouterButton, Text } from 'components';
import { StyleProp } from 'components/StyleProp';
import { useTenant } from 'tenancy/useTenant';
import { setParamValue } from 'url/utils';
import { Stack } from 'components/Stack';
import { useHistory } from 'url/hooks';
import { ReactElement } from 'react';
import { ampli } from 'ampli';

import image from './background.png';

interface AgentInstallBannerProps {
	sx?: StyleProp;
}

export default function AgentInstallBanner({ sx = {} }: AgentInstallBannerProps): ReactElement {
	const { createHref } = useHistory();
	const tenant = useTenant();
	const isAdmin = tenant.user?.role === 'ADMIN';

	return (
		<Container
			sx={{
				position: 'relative',
				...sx,

				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',

				borderRadius: 6,
				background: 'linear-gradient(180deg, #5B48CA 0%, #3B2F83 100%)',
				px: 'medium',
				py: 'small',
				overflow: 'hidden',
			}}
		>
			<img
				src={image}
				loading="lazy"
				style={{
					position: 'absolute',
					top: 0,
					left: -90,
					width: '726px',
					height: '114px',
				}}
			/>

			<Stack direction="horizontal" alignItems="flex-start" sx={{ zIndex: 1 }}>
				<Container
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						p: 'xxSmall',
						borderRadius: '50%',
						background: 'white',
					}}
				>
					<IconWarningCircleOpen variant="large" color="slate" />
				</Container>

				<Stack size="xxSmall" maxWidth={isAdmin ? 326 : 540} color="white">
					<Text variant="smallStrong" fontWeight={800}>
						There are no Agents / Extensions installed.
					</Text>
					<Text variant="small">
						Steadybit&apos;s features, like running an Experiment, are only available with at least one Agent and one
						Extension. {isAdmin ? 'Please resume to the onboarding.' : 'Please reach out to your admin to get started.'}
					</Text>
				</Stack>

				{isAdmin && (
					<RouterButton
						variant="secondary"
						to={createHref((location) => {
							location.pathname = '/onboarding';
							setParamValue(
								location,
								{
									pathSegment: '/onboarding',
									name: 'page',
								},
								1,
							);
						})}
						onClick={() => {
							ampli.agentSetupResumed();
						}}
						mt="auto"
					>
						Resume Agent Setup <IconAgent ml="xSmall" />
					</RouterButton>
				)}
			</Stack>
		</Container>
	);
}
