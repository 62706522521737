/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, Container, Link, Message, Text } from 'components';
import { ExperimentLaneVO, ExperimentStepActionVO } from 'ui-api';
import { IconDelete } from 'components/icons';
import { useUrlState } from 'url/useUrlState';
import { ReactElement } from 'react';
import { useField } from 'formik';

import { UrlState, selectedStepIdParam } from '../urlParams';
import useActions from '../useActions';

interface OutdatedNotificationProps {
	actionStep: ExperimentStepActionVO;
}

export default function OutdatedNotification({ actionStep }: OutdatedNotificationProps): ReactElement {
	const [, , updateUrl] = useUrlState<UrlState>([selectedStepIdParam]);
	const [lanesField, , { setValue, setTouched }] = useField<ExperimentLaneVO[]>({ name: 'lanes' });

	const actions = useActions();
	const hasToUpdateExtension = actions.some((a) => {
		//We renamed the ids in the extension. So if the new id is not found but an old one,
		//we show a message to update
		const normalized = a.id.replace('com.github.steadybit', 'com.steadybit');
		return normalized === actionStep.actionId;
	});

	return (
		<Container m="medium">
			<Message variant="danger" title="UnknownAction">
				{hasToUpdateExtension ? (
					<Text>The action is outdated. Please update the extension.</Text>
				) : (
					<Text>You are using an action which is currently not available. Please contact your administrator.</Text>
				)}
				<Link href={`https://hub.steadybit.com/action/${actionStep.actionId}`} target="_blank">
					Visit the Reliability Hub to learn how to install the action.
				</Link>
				<Text variant="xSmall" mb="small">
					<code>{actionStep.actionId}</code>
				</Text>
				<Button
					variant="primarySmall"
					onClick={() => {
						const lanesWithoutStep = lanesField.value.map((lane) => {
							return { ...lane, steps: lane.steps.filter((step) => step.id !== actionStep.id) };
						});
						setValue(lanesWithoutStep);
						setTouched(true);
						updateUrl({ selectedStepId: undefined });
					}}
				>
					<IconDelete mr="xSmall" /> Delete This Step
				</Button>
			</Message>
		</Container>
	);
}
