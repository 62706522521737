/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react';
import { Text } from 'components';

interface EntityLabelProps {
	label: string;
	isSelected?: boolean;
}

export default function EntityLabel({ label, isSelected }: EntityLabelProps): ReactElement {
	return (
		<Text
			variant="smallStrong"
			sx={{
				color: isSelected ? 'slate' : 'neutral800',
				wordWrap: 'break-word',
			}}
		>
			{label}
		</Text>
	);
}
