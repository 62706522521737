/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React from 'react';

export type TableBodyProps = ContainerProps & Omit<React.HTMLAttributes<HTMLTableSectionElement>, keyof ContainerProps>;

export const TableBody = React.forwardRef<HTMLTableSectionElement, TableBodyProps>((props, ref) => (
	<Container ref={ref} as="tbody" {...props} />
));
TableBody.displayName = 'TableBody';
