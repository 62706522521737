/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

const animate = {
	initial: { opacity: 0, skewY: 0.9 },
	animate: { opacity: 1, skewY: 0 },
	exit: { opacity: 0, skewY: 0 },
	transition: { duration: 0.15 },
};

interface SelectOptionsProps extends ContainerProps {
	vertical?: boolean;
	level?: number;
}

export const SelectOptions: React.FunctionComponent<SelectOptionsProps> = ({
	children,
	vertical = false,
	variant = 'medium',
	level = 0,
}) => {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (vertical) {
			ref?.current?.scrollTo(0, 0);
		}
	}, [ref, level, vertical]); // `level` must be in deps list, to trigger scrolling when it changes

	return (
		<motion.div {...animate} style={{ willChange: 'opacity, transform' }}>
			<Container
				ref={ref}
				bg="neutral000"
				py="xSmall"
				px="none"
				m="none"
				maxHeight={250}
				sx={{
					position: 'relative',
					overflowY: 'auto',
					overflowX: 'hidden',
					boxShadow: 'applicationMedium',
					borderRadius: 4,
				}}
				tx={'selectOptions'}
				data-select-options
				variant={variant}
			>
				<motion.div
					transition={{ bounce: 0, ease: 'easeOut', duration: vertical ? 0.35 : 0 }}
					initial={{ x: 0 }}
					animate={{ x: `${level * -100}%` }}
				>
					{children}
				</motion.div>
			</Container>
		</motion.div>
	);
};
