/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import ExperimentExecutionTimerange from 'pages/experiments/components/ExperimentExecutionTimerange';
import { Container, ContainerProps, Stack, StyleProp, Text, Tooltip } from 'components';
import { horizontalLine, verticalLines } from 'styles.v2/experimentLineHelpers';
import React, { forwardRef, useMemo } from 'react';
import { theme } from 'styles.v2/theme';
import { range } from 'lodash';

import { MotionBox } from '../../motion';

interface PlayerStepProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'css'> {
	initSx: StyleProp;
	effectiveSx: StyleProp;
	textSx: StyleProp;
	width: React.CSSProperties['width'];
	left: React.CSSProperties['left'];
	initWidth: React.CSSProperties['width'];
	zIndex: React.CSSProperties['zIndex'];
	highlighted: boolean;
	state: string;
	start: number;
	experimentExecutionEnd: number | undefined;
	end: number;
	muted?: boolean;
}

export const PlayerStep = forwardRef<HTMLElement, PlayerStepProps>(
	(
		{
			children,
			width,
			left,
			initSx,
			effectiveSx,
			textSx,
			initWidth,
			zIndex,
			highlighted,
			muted,
			state,
			onClick,
			experimentExecutionEnd,
			onMouseEnter,
			onMouseLeave,
			onMouseMove,
			start,
			end,
			...props
		},
		ref,
	) => {
		const highlightedStyle = highlighted
			? { filter: 'brightness(0.8)' }
			: muted
			? {
					opacity: 0.5,
			  }
			: {};

		return (
			<MotionBox
				animate={{ width, left }}
				initial={false}
				minWidth={12}
				sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					zIndex,
					willChange: 'left, width',
				}}
				whileHover={{ filter: 'brightness(0.8)', cursor: 'pointer' }}
			>
				<Container
					display={'flex'}
					ref={ref}
					sx={{
						height: 56,
						borderRadius: 5,
						overflow: 'hidden',
						position: 'relative',
						border: getStepBorder(state),
						...highlightedStyle,
					}}
					onMouseMove={onMouseMove}
					onClick={onClick}
					{...props}
				>
					<Tooltip
						content={
							<Stack direction="horizontal" size="xSmall">
								<ExperimentExecutionTimerange
									start={start}
									end={end}
									state={state}
									experimentExecutionEnd={experimentExecutionEnd}
								/>
								<Text variant="small">Preparation ({children})</Text>
							</Stack>
						}
					>
						<Container
							sx={{
								position: 'relative',
								transition: 'width 100ms ease-out',
								willChange: 'width',
								width: initWidth,
								...initSx,
							}}
						>
							<Container
								sx={{
									position: 'absolute',
									left: 0,
									right: 0,
									top: 0,
									bottom: 0,
									overflow: 'hidden',
									transition: 'width 100ms ease-out',
									borderLeft: '2px dashed ' + theme.colors.neutral500,
									borderTop: '2px dashed ' + theme.colors.neutral500,
									borderBottom: '2px dashed ' + theme.colors.neutral500,
								}}
							>
								<Text
									variant="smallStrong"
									sx={{
										verticalAlign: 'middle',
										lineHeight: '49px',
										paddingLeft: 7,
										whiteSpace: 'nowrap',
										color: 'neutral600',
									}}
								>
									Preparation ({children})
								</Text>
							</Container>
						</Container>
					</Tooltip>

					<Container
						sx={{
							position: 'relative',
							flex: '1 1 auto',
							transition: 'width 100ms ease-out',
							willChange: 'width',
							...effectiveSx,
						}}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
					>
						{!!children && (
							<Text
								variant="smallStrong"
								sx={{
									color: 'neutral000',
									position: 'absolute',
									top: 0,
									left: 0,
									right: 0,
									bottom: 0,
									verticalAlign: 'middle',
									lineHeight: '54px',
									paddingLeft: 8,
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									...textSx,
								}}
							>
								{children}
							</Text>
						)}
					</Container>
				</Container>
			</MotionBox>
		);
	},
);
PlayerStep.displayName = 'PlayerStep';

interface PlayerLaneProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'css'> {
	prefixContent: React.ReactNode;
}

export const PlayerLane = forwardRef<HTMLElement, PlayerLaneProps>(({ children, prefixContent, ...props }, ref) => (
	<Container display={'flex'} ref={ref} height={64} {...props}>
		<Container display={'flex'} flex="0 0 auto" width={16} alignItems="center" justifyContent="center">
			<Text fontFamily="code" variant="xSmallStrong" color="neutral400" textAlign="center" sx={{ userSelect: 'none' }}>
				{prefixContent}
			</Text>
		</Container>
		<Container height={56} mx="xxSmall" flex="1 0 auto" my={4} sx={{ position: 'relative' }}>
			{children}
		</Container>
	</Container>
));
PlayerLane.displayName = 'PlayerLane';

export const PlayerLaneWrapper = forwardRef<HTMLElement, ContainerProps>(({ children, ...props }, ref) => (
	<Container ref={ref} sx={{ position: 'relative', zIndex: 5, paddingTop: 24 }} {...props}>
		{children}
	</Container>
));
PlayerLaneWrapper.displayName = 'PlayerLaneWrapper';

interface PlayerProgressProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'css'> {
	position: React.CSSProperties['left'];
}

export const PlayerProgress = forwardRef<HTMLElement, PlayerProgressProps>(({ position }, ref) => (
	<Container
		ml={16}
		sx={{ position: 'absolute', left: 0, right: 0, bottom: 0, top: 0, pointerEvents: 'none', zIndex: 6 }}
	>
		<MotionBox
			animate={{ left: position }}
			transition={{ type: 'spring', damping: 30, stiffness: 200 }}
			ref={ref}
			sx={{
				position: 'absolute',
				top: 0,
				bottom: 0,
				right: 0,
				left: 0,
				borderLeft: '2px dashed',
				borderLeftColor: 'slate',
				willChange: 'left',

				bg: 'neutral000',
				opacity: '50%',
			}}
		/>
	</Container>
));
PlayerProgress.displayName = 'PlayerProgress';

interface CursorInnerProps extends ContainerProps {
	labelOffset?: number;
}

const CursorInner: React.FC<CursorInnerProps> = ({ children, labelOffset = 0, ...props }) => (
	<Container
		{...props}
		bg="inherit"
		px="xxSmall"
		py={1}
		height={18}
		sx={{ position: 'absolute', top: 3, left: '50%', borderRadius: 4, display: 'flex', alignItems: 'center' }}
		style={{ transform: `translateX(calc(-50% + ${labelOffset}px))` }}
	>
		<Text
			sx={{
				fontFamily: 'code',
				fontSize: 12,
				lineHeight: 0.8,
				color: 'neutral000',
				whiteSpace: 'nowrap',
			}}
		>
			{children}
		</Text>
	</Container>
);

function calculateLabelOffset(position: string | number, totalWidth: number, labelWidth = 72): number {
	// Who knows what parseFloat might bring us…
	try {
		const pos = (typeof position === 'string' ? parseFloat(position) : position) / 100;
		const posPx = pos * totalWidth;
		const halfLabel = labelWidth / 2;
		const rightEdge = totalWidth - halfLabel;
		const labelOffset =
			posPx < halfLabel ? halfLabel - posPx : posPx > rightEdge ? -halfLabel + (1 - pos) * totalWidth : 0;

		return isNaN(labelOffset) ? 0 : labelOffset;
	} catch (err) {
		return 0;
	}
}

interface PlayerCursorProps extends ContainerProps {
	position: React.CSSProperties['left'];
	totalWidth: number;
}

export const PlayerCursor = forwardRef<HTMLElement, PlayerCursorProps>(
	({ position, totalWidth, variant = 'slate', children }, ref) => {
		const labelOffset = calculateLabelOffset(position || 0, totalWidth);

		return (
			<Container
				tx="experimentPlayer.cursor"
				variant={variant}
				ref={ref}
				width={2}
				style={{ left: position }}
				sx={{
					position: 'absolute',
					top: 0,
					bottom: 0,
				}}
			>
				<CursorInner labelOffset={labelOffset} variant={variant}>
					{children}
				</CursorInner>
			</Container>
		);
	},
);
PlayerCursor.displayName = 'PlayerCursor';

interface PlayerCursorAnimatedProps extends ContainerProps {
	position: string | number;
	totalWidth: number;
}

export const PlayerCursorAnimated = forwardRef<HTMLElement, PlayerCursorAnimatedProps>(
	({ position, totalWidth, variant = 'slate', children }, ref) => {
		const labelOffset = calculateLabelOffset(position, totalWidth);

		return (
			<MotionBox
				initial={false}
				animate={{ left: position }}
				transition={{ type: 'spring', damping: 30, stiffness: 200 }}
				ref={ref}
				tx="experimentPlayer.cursor"
				variant={variant}
				width={2}
				sx={{
					position: 'absolute',
					top: 0,
					bottom: 0,
					willChange: 'left',
				}}
			>
				<CursorInner labelOffset={labelOffset} variant={variant}>
					{children}
				</CursorInner>
			</MotionBox>
		);
	},
);
PlayerCursorAnimated.displayName = 'PlayerCursorAnimated';

export const PlayerCursorWrapper = forwardRef<HTMLElement, Omit<React.ComponentPropsWithoutRef<'div'>, 'css'>>(
	({ children, ...props }, ref) => (
		<Container
			ref={ref}
			ml={16}
			sx={{ position: 'absolute', left: 0, right: 0, bottom: 0, top: 0, pointerEvents: 'none', zIndex: 6 }}
			{...props}
		>
			{children}
		</Container>
	),
);
PlayerCursorWrapper.displayName = 'PlayerCursorWrapper';

interface PlayerTimeScaleProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'css'> {
	duration: number;
}

function timescale(duration: number): [ticks: string[], tickWidth: number] {
	const durationSeconds = duration / 1000;
	const tickCount = Math.min(8, durationSeconds);
	const ticks = range(0, durationSeconds + 1, durationSeconds / tickCount).map((tick) => tick.toFixed() + 's');
	const tickWidth = 100 / tickCount;
	return [ticks, tickWidth];
}

export const PlayerTimeScale = forwardRef<HTMLElement, PlayerTimeScaleProps>(({ duration }, ref) => {
	const [ticks, tickWidth] = useMemo(() => timescale(duration), [duration]);
	return (
		<Container
			display={'flex'}
			ref={ref}
			ml={16}
			height={24}
			justifyContent="flex-start"
			alignItems="flex-start"
			sx={{ position: 'relative', zIndex: 1 }}
		>
			{ticks.map((time) => (
				<Container
					key={time}
					height="100%"
					flex={`0 0 ${tickWidth}%`}
					sx={{
						userSelect: 'none',
						backgroundImage: `linear-gradient(0deg, transparent 4px, ${theme.colors.neutral050} 4px, ${theme.colors.neutral050} 23px, ${theme.colors.neutral200} 23px), linear-gradient(90deg, ${theme.colors.neutral300} 1px, ${theme.colors.neutral050} 1px)`,
						backgroundPosition: '-1px 0',

						':first-of-type [data-time-scale]': {
							transform: 'translateX(0%)',
							pl: 'xxSmall',
						},

						':last-of-type': {
							position: 'absolute',
							right: 0,
							top: 0,
							background: 'none',

							'[data-time-scale]': {
								transform: 'translateX(0%)',
								pr: 'xxSmall',
							},
						},
					}}
				>
					<Text
						variant="xSmall"
						fontFamily="code"
						px="xSmall"
						sx={{ display: 'inline-block', transform: 'translateX(-50%)' }}
						data-time-scale
						color="neutral400"
					>
						{time}
					</Text>
				</Container>
			))}
		</Container>
	);
});
PlayerTimeScale.displayName = 'PlayerTimeScale';

export const PlayerBackground = forwardRef<HTMLElement, ContainerProps>(({ children, ...props }, ref) => (
	<Container
		ref={ref}
		sx={{
			overflow: 'hidden',
			position: 'relative',
			backgroundRepeat: 'repeat-y',
			backgroundSize: '100% 64px',
			backgroundPosition: '0 24px',
			backgroundImage: `${verticalLines(theme.colors.neutral200, 'transparent', 64)}, ${horizontalLine(
				theme.colors.neutral200,
				theme.colors.neutral050,
			)}`,
		}}
		{...props}
	>
		{children}
	</Container>
));
PlayerBackground.displayName = 'PlayerBackground';

function getStepBorder(state: string): string {
	if (state === 'ERRORED') {
		return '3px solid ' + theme.colors.coral;
	}
	if (state === 'FAILED') {
		return '3px solid ' + theme.colors.experimentWarning;
	}
	return '1px solid ' + theme.colors.neutral100;
}
