/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Code } from 'components';
import React from 'react';

export interface GetSetupArg {
	agentKey: string | null;
	ingressUri: string;
	clusterName?: string;
	containerRuntime?: string;
	makeCopyButtonPrimary?: boolean;
	onCopy?: () => void;
}

export const getAgentLinuxSetup = (arg: GetSetupArg): string =>
	`wget https://get.steadybit.com/agent-linux.sh
chmod a+x agent-linux.sh
./agent-linux.sh --platform-url ${arg.ingressUri} --key ${arg.agentKey ?? '********'}`;

export const getAgentDockerSetup = (arg: GetSetupArg): string =>
	`wget https://get.steadybit.com/agent.sh
chmod a+x agent.sh
./agent.sh --platform-url ${arg.ingressUri} --key ${arg.agentKey ?? '********'}`;

export const getAgentK8sHelmSetupText = (arg: GetSetupArg): string => {
	//IF YOU CHANGE SOMETHING, UPDATE ALSO getAgentK8sHelmSetup WHICH IS USED FOR COPYING THE SAME CONTENT TO THE CLIPBOARD!
	return `helm repo add steadybit https://steadybit.github.io/helm-charts
helm repo update
helm install steadybit-agent --namespace steadybit-agent \\
  --create-namespace \\
  --set agent.key=${arg.agentKey ?? '********'} \\
  --set global.clusterName=${arg.clusterName ? '"' + arg.clusterName + '"' : 'my-cluster-name'} \\${
		arg.containerRuntime
			? `
  --set extension-container.container.runtime=${arg.containerRuntime} \\`
			: ''
	}
  --set agent.registerUrl=${arg.ingressUri} \\
  steadybit/steadybit-agent`;
};

export const getAgentK8sHelmSetup = (arg: GetSetupArg): React.ReactNode => (
	//IF YOU CHANGE SOMETHING, UPDATE ALSO getAgentK8sHelmSetupText WHICH IS USED FOR COPYING THE SAME CONTENT TO THE CLIPBOARD!
	<>
		helm repo add steadybit https://steadybit.github.io/helm-charts
		<br />
		helm repo update
		<br />
		helm install steadybit-agent --namespace steadybit-agent \<br />
		{'  '}--create-namespace \<br />
		{'  '}--set agent.key={arg.agentKey ?? '********'} \<br />
		{'  '}--set global.clusterName=&quot;<Code highlight>{arg.clusterName ? arg.clusterName : 'my-cluster-name'}</Code>
		&quot; \
		<br />
		{arg.containerRuntime ? (
			<>
				{'  '}--set extension-container.container.runtime={arg.containerRuntime} \<br />
			</>
		) : null}
		{'  '}--set agent.registerUrl={arg.ingressUri} \<br />
		{'  '}steadybit/steadybit-agent
	</>
);
