/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useEnvironments } from 'utils/hooks/useEnvironments';
import { useFormikContext } from 'formik';
import { Stack, Text } from 'components';
import { ReactElement } from 'react';

import EnvironmentDropdown from './EnvironmentDropdown';
import { UseTemplateFormData } from './UseTemplateForm';

export default function EnvironmentSelection(): ReactElement {
	const formik = useFormikContext<UseTemplateFormData>();
	const { environmentId } = formik.values;

	const environments = useEnvironments();

	return (
		<Stack
			size="xSmall"
			sx={{
				borderRadius: '4px',
			}}
		>
			<Text variant="mediumStrong" color="neutral800">
				In which environment will you run the experiment?
			</Text>
			<Text variant="medium" color="neutral600">
				The environment determines which targets are available for the experiment. Any environment variables used in the
				experiment will resolve to the values defined in the chosen environment.
			</Text>
			<EnvironmentDropdown
				environments={environments}
				selectedEnvironmentId={environmentId || environments.globalEnvironment?.id || ''}
				selectEnvironmentId={(id) => {
					formik.setFieldValue('environmentId', id);
					formik.setFieldValue('variableValuesMap', new Map());
				}}
			/>
		</Stack>
	);
}
