/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Container, Stack, Text } from 'components';
import React, { lazy, Suspense } from 'react';
import { theme } from 'styles.v2/theme';
import { Brand } from 'images';

import { Services } from '../../services/services';

const VerifyEmailErrorIcon = lazy(() => import('./VerifyEmailErrorIcon'));

const VerifyEmailError: React.VFC = () => {
	return (
		<Container
			sx={{
				position: 'absolute',
				top: 0,
				right: 0,
				left: 0,
				bottom: 0,

				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',

				backgroundColor: 'neutral100',
			}}
		>
			<Stack
				size="medium"
				sx={{
					alignItems: 'center',
					justifyContent: 'center',
					gap: '50px',
					mt: '-70px',
				}}
			>
				<Brand width="168px" height="38px" color="#0F054C" />
				<Stack size="medium" alignItems="center">
					<Stack
						size="xSmall"
						sx={{
							alignItems: 'center',
							padding: '56px 72px',
							backgroundColor: 'neutral000',
							borderRadius: '8px',
							boxShadow: theme.shadows.applicationLarge,
							maxWidth: '590px',
						}}
					>
						<Text variant="xLargeStrong" color="neutral800">
							Verify your e-mail address
						</Text>

						<Suspense fallback={<div style={{ width: '211px', height: '204px' }} />}>
							<VerifyEmailErrorIcon />
						</Suspense>

						<Text variant="medium" color="neutral700" textAlign="center">
							To start using Steadybit, we need to verify your e-mail.
							<br />
							We’ve already sent out the verification link. Please check it and confirm it’s really you.
						</Text>
					</Stack>

					<Stack size="xxSmall" direction="horizontal" alignItems="center">
						<Text variant="small" color="neutral700">
							You didn’t receive any e-mail? Check your spam filter or try
						</Text>
						<Text
							variant="smallStrong"
							color="slate"
							onClick={async () => {
								await Services.auth.logout();
								window.location.assign('/');
							}}
							data-cy="logout"
							sx={{
								cursor: 'pointer',
								':hover': {
									textDecoration: 'underline',
								},
							}}
						>
							another e-mail address.
						</Text>
					</Stack>
				</Stack>
			</Stack>
		</Container>
	);
};

export default VerifyEmailError;
