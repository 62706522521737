/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	IconBadge,
	IconCalendarClock,
	IconCalendarPaused,
	IconCalendarRecurrent,
	IconDuplicate,
	IconLogfile,
	IconTemplate,
} from 'components/icons';
import {
	duplicateExperimentConfirm,
	DuplicateExperimentResult,
} from 'pages/experiments/components/DuplicateExperimentConfirm';
import { ExperimentSubHeaderEnvironments } from 'pages/experiments/components/header/experimentSubHeaderEnvironments';
import { ExperimentSubHeaderHypthesis } from 'pages/experiments/components/header/experimentSubHeaderHypthesis';
import EditExperimentScheduleModal from 'pages/experiments/components/Schedule/EditExperimentScheduleModal';
import ScheduleExperimentModal from 'pages/experiments/components/Schedule/ScheduleExperimentModal';
import { Button, ButtonIcon, ButtonSplit, Stack, Subnav, Tooltip, userConfirm } from 'components';
import ExperimentBadgeModal from 'pages/experiments/components/header/ExperimentBadgeModal';
import ExperimentFileModal from 'pages/experiments/components/header/ExperimentFileModal';
import useRefreshingSchedule from 'pages/experiments/hooks/useRefreshingSchedule';
import { prepareCopyExperimentForm } from 'pages/experiments/experiment';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import useGlobalPermissions from 'services/useGlobalPermissions';
import { Services } from 'services/services';
import { useFormikContext } from 'formik';
import { theme } from 'styles.v2/theme';
import { useHistory } from 'url/hooks';
import { useState } from 'react';

import { saveExperiment } from './ExperimentEditorFormHandler';
import { ExperimentFormValues } from './types';
import ExperimentTags from './ExperimentTags';

export default function ExperimentSubHeader(): ReactElement {
	const { values, touched, errors, submitForm, isSubmitting } = useFormikContext<ExperimentFormValues>();
	const { experimentKey, name } = values;

	const isNewExperiment = !experimentKey || experimentKey === '<new>';
	const actions = values.actions ?? [];
	const dirty = Object.keys(touched).length > 0;

	const isEditPermitted = actions.includes('save');
	const disabled = !isEditPermitted;

	const history = useHistory();
	const [showDownloadModal, setShowDownloadModal] = useState(false);
	const [showScheduleModal, setShowScheduleModal] = useState(false);
	const [showBadgeModal, setShowBadgeModal] = useState(false);

	const permissions = useGlobalPermissions();
	const canCreateTemplates = permissions.templates.canCreate;

	const schedule = useRefreshingSchedule(experimentKey);

	const canSaveAsTemplate = canCreateTemplates && isEditPermitted && !isNewExperiment;
	const canSaveExperiment = canSaveAsTemplate || (isEditPermitted && (isNewExperiment || dirty));

	return (
		<Subnav
			px="medium"
			maxWidth="100%"
			sx={{ boxShadow: 'applicationSmall', zIndex: 3 }}
			stackProps={{ justifyContent: 'space-between' }}
		>
			{showDownloadModal && (
				<ExperimentFileModal
					title={`experiment-${experimentKey}`}
					experimentKey={experimentKey}
					onClose={() => setShowDownloadModal(false)}
				/>
			)}
			{showBadgeModal && (
				<ExperimentBadgeModal experimentKey={experimentKey} onClose={() => setShowBadgeModal(false)} />
			)}
			{showScheduleModal &&
				(schedule.value ? (
					<EditExperimentScheduleModal
						schedule={schedule.value}
						onClose={() => setShowScheduleModal(false)}
						disabled={disabled}
					/>
				) : (
					<ScheduleExperimentModal
						experimentKey={experimentKey}
						onClose={() => setShowScheduleModal(false)}
						disabled={disabled}
					/>
				))}

			<Stack
				direction="horizontal"
				justifyContent="flex-start"
				alignItems="center"
				flexGrow={1}
				minWidth="fit-content"
				overflowX="hidden"
			>
				<ExperimentSubHeaderEnvironments errors={errors} disabled={disabled} />
				<Divider />
				<ExperimentSubHeaderHypthesis errors={errors} disabled={disabled} />
				<Divider />
				<ExperimentTags />
			</Stack>

			<Stack direction="horizontal" justifyContent="flex-end" alignItems="center" overflowX="hidden" minWidth="160px">
				{!isNewExperiment && (
					<Stack direction="horizontal" size="none" minWidth="fit-content">
						<Tooltip content="Duplicate Experiment">
							<ButtonIcon onClick={onDuplicate} minWidth={40}>
								<IconDuplicate color="neutral700" />
							</ButtonIcon>
						</Tooltip>
						<Tooltip content="View Experiment as json">
							<ButtonIcon onClick={onDownload} minWidth={40}>
								<IconLogfile color="neutral700" />
							</ButtonIcon>
						</Tooltip>
						<Tooltip content="Get your Experiment Badge">
							<ButtonIcon onClick={onBadge} minWidth={40}>
								<IconBadge color="neutral700" />
							</ButtonIcon>
						</Tooltip>
						<Tooltip content={isEditPermitted ? 'Schedule experiment execution' : 'View experiment schedule'}>
							<ButtonIcon onClick={onSchedule}>
								{schedule.value && !schedule.value.enabled ? (
									<IconCalendarPaused color="slate" />
								) : schedule.value?.cron ? (
									<IconCalendarRecurrent color={schedule.value ? 'slate' : 'neutral700'} />
								) : (
									<IconCalendarClock color={schedule.value ? 'slate' : 'neutral700'} />
								)}
							</ButtonIcon>
						</Tooltip>
					</Stack>
				)}
				{canSaveAsTemplate ? (
					<>
						<Divider />
						<SaveButton />
					</>
				) : canSaveExperiment ? (
					<Button variant="secondarySmall" onClick={submitForm} loading={isSubmitting} data-cy="save-experiment-button">
						Save
					</Button>
				) : null}
			</Stack>
		</Subnav>
	);

	async function onDuplicate(): Promise<void> {
		const executeAction = await ensureSaved({ saveLabel: 'Save & Duplicate' });
		if (executeAction) {
			const original = await Services.experiments.fetchExperiment(experimentKey);
			const duplicateResult: DuplicateExperimentResult = await duplicateExperimentConfirm(original);
			const copyedExperimentFormData = await prepareCopyExperimentForm(
				experimentKey,
				duplicateResult,
				'UI_COPIED_EDITOR',
			);
			const saved = await Services.experiments.createOrUpdateExperiment(copyedExperimentFormData.initialValues);

			history.push(`/experiments/edit/${saved.experimentKey}/design`);
		}
	}

	async function ensureSaved({ saveLabel }: { saveLabel: string }): Promise<boolean> {
		if (dirty || isNewExperiment) {
			if (
				(await userConfirm({
					title: 'Unsaved Changes',
					message: `You have unsaved changes. Do you want to save ${experimentKey} ${name ?? ''}?`,
					actions: [
						{ value: 'cancel', label: 'Cancel' },
						{
							value: 'save&run',
							label: saveLabel,
							variant: 'primary',
						},
					],
				})) === 'save&run'
			) {
				const result = await saveExperiment(values);
				if (result == null) {
					return false;
				}
				return true;
			} else {
				return false;
			}
		}
		return true;
	}

	async function onDownload(): Promise<void> {
		const executeAction = await ensureSaved({ saveLabel: 'Save & Download' });
		if (executeAction) {
			setShowDownloadModal(true);
		}
	}

	async function onBadge(): Promise<void> {
		const executeAction = await ensureSaved({ saveLabel: 'Save & Create Badge' });
		if (executeAction) {
			setShowBadgeModal(true);
		}
	}

	async function onSchedule(): Promise<void> {
		const executeAction = await ensureSaved({ saveLabel: 'Save & Schedule' });
		if (executeAction) {
			setShowScheduleModal(true);
		}
	}
}

function Divider(): ReactElement {
	return <div style={{ width: 1, height: 20, backgroundColor: theme.colors.neutral300 }} />;
}

function SaveButton(): ReactElement | null {
	const history = useHistory();
	const { values, submitForm, isSubmitting } = useFormikContext<ExperimentFormValues>();

	return (
		<>
			<ButtonSplit
				variant="secondarySmall"
				actions={[
					{
						Icon: IconTemplate,
						value: 'Save as Template',
						label: 'Save as Template',
					},
				]}
				onClick={submitForm}
				onAction={() => {
					history.push(`/settings/templates/design/fromExperiment/${values.experimentKey}`);
				}}
				loading={isSubmitting}
			>
				Save
			</ButtonSplit>
		</>
	);
}
