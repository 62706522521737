/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { useEffect, useState } from 'react';

import { LayoutedGroup, LayoutedTarget, isHoverEntityEvent, isMouseClickEvent } from './types';
import { emit, event$ } from '../ServiceLocator';

export default function EntitySelectionHandler(): null {
	const [hoveredEntity, setHoveredEntity] = useState<LayoutedGroup | LayoutedTarget | null>(null);

	useEffect(() => {
		let h: LayoutedTarget | LayoutedGroup | null;
		const subscription = event$().subscribe((event) => {
			if (isHoverEntityEvent(event)) {
				h = event.entity;
				setHoveredEntity(event.entity);
			} else if (isMouseClickEvent(event)) {
				emit({ type: 'selectEntity', entity: h });
			}
		});
		return () => {
			subscription.unsubscribe();
		};
	}, [event$]);

	useEffect(() => {
		if (hoveredEntity) {
			document.body.style.cursor = 'pointer';
		} else {
			document.body.style.cursor = 'default';
		}

		return () => {
			document.body.style.cursor = 'default';
		};
	}, [hoveredEntity]);

	return null;
}
