/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { createIconFromDataUri, IconActionKit } from 'components/icons';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { BasicStyleProperties } from 'components/types';
import { Services } from 'services/services';
import React from 'react';

interface ActionIconProps extends BasicStyleProperties {
	id: string;
	variant?: string;
}

export const ActionIcon: React.VFC<ActionIconProps> = ({ id, variant = 'medium', ...props }) => {
	const [action] = useAsyncState(() => Services.actions.findAction(id), [id]);
	if (action.value?.icon) {
		const Icon = createIconFromDataUri(action.value.icon);
		return <Icon variant={variant} {...props} />;
	}

	return <IconActionKit id={id} variant={variant} {...props} />;
};
