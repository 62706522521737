/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ReactElement, ReactNode } from 'react';
import { Stack } from 'components';

interface NavItemsProps {
	children: ReactNode;
}

export default function NavItems({ children }: NavItemsProps): ReactElement {
	return (
		<Stack size="xxxSmall" as="ul" m="0" p="0" sx={{ listStyle: 'none' }}>
			{children}
		</Stack>
	);
}
