/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { FieldHookConfig, useField } from 'formik';
import React from 'react';

import { RadioButton, RadioButtonProps } from '../RadioButton';
import { hasError } from './utils';

export type FormikRadioButtonProps = Omit<RadioButtonProps, 'error' | 'touched'>;

export const FormikRadioButton = React.forwardRef<HTMLButtonElement, FormikRadioButtonProps>((props, ref) => {
	const [field, meta] = useField({ type: 'radio', ...props } as FieldHookConfig<string>);
	return <RadioButton ref={ref} hasError={hasError(meta)} {...field} {...meta} {...props} />;
});
FormikRadioButton.displayName = 'FormikRadioButton';
