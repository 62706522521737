/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { useEffect, useState } from 'react';
import { Location, Action } from 'history';
import { useHistory } from 'url/hooks';

export type CustomPromptBlockPredicate = (location: Location, action: Action) => boolean;

type useCustomPromptReturn = { showPrompt: boolean; resolve: (confirmed: boolean) => void };

type CustomPromptState =
	| {
			status: 'blocked' | 'confirmed';
			location: Location;
			action: Action;
	  }
	| {
			status: null;
	  };

export function useCustomPrompt(when = false, block?: CustomPromptBlockPredicate): useCustomPromptReturn {
	const [state, setState] = useState<CustomPromptState>({ status: null });
	const history = useHistory();

	useEffect(() => {
		if (!when) {
			return;
		}

		if (state.status === 'confirmed') {
			switch (state.action) {
				case 'PUSH':
					history.push(state.location);
					setState({ status: null });
					break;
				case 'POP':
					history.goBack();
					break;
				case 'REPLACE':
					history.replace(state.location);
					setState({ status: null });
					break;
			}
			return;
		}

		if (state.status === null) {
			const unblock = history.block((location, action) => {
				const blocked = block?.(location, action) || !block;

				if (blocked) {
					setState({ status: 'blocked', action, location });
				}

				// Undocumented: we use return false/true to block/not-block without message
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				return !blocked as any;
			});

			return () => {
				unblock();
			};
		}
	}, [when, block, history, state]);

	if (state.status === 'blocked') {
		return {
			showPrompt: true,
			resolve: (confirm: boolean) => setState({ ...state, status: confirm ? 'confirmed' : null }),
		};
	} else {
		return {
			showPrompt: false,
			resolve: () => {},
		};
	}
}
