/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { DependencyList, useMemo, useState } from 'react';

import { useObservable, Creator } from './useObservable';
import { DataStreamResult } from './stream/result';

export type ReExecute = () => void;

export function useObservableWithReExecution<T>(
	fn: Creator<DataStreamResult<T>>,
	dependencies: DependencyList,
): [DataStreamResult<T>, ReExecute];
export function useObservableWithReExecution<T>(
	fn: Creator<T>,
	dependencies: DependencyList,
): [DataStreamResult<T>, ReExecute];
export function useObservableWithReExecution<T>(
	fn: Creator<T>,
	dependencies: DependencyList,
): [DataStreamResult<T>, ReExecute] {
	const [reExecutionTrigger, setReExecutionTrigger] = useState<number>(0);
	const value = useObservable(fn, [reExecutionTrigger, ...dependencies]);
	const reExecute = useMemo(() => () => setReExecutionTrigger((v) => v + 1), dependencies);
	return [value, reExecute];
}
