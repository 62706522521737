/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { isLandscapeGroupWithTargets } from 'targets/Explore/types';
import { Container, Stack, Text, Tooltip } from 'components';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { IconGroup, IconTarget } from 'components/icons';
import { ReactElement, useMemo } from 'react';

import { LayoutedGroup, LayoutedTarget, isLayoutedTarget } from '../types';
import { getSteadybitLabelOrDefault } from './utils';

interface BreadcrumbsProps {
	expanded: boolean;
	entity: LayoutedGroup | LayoutedTarget | null;
	onBreadcrumbClicked: (entity: LayoutedGroup | LayoutedTarget) => void;
}

export default function Breadcrumbs({ entity, onBreadcrumbClicked, expanded }: BreadcrumbsProps): ReactElement | null {
	const breadcrumbs = useMemo(() => {
		const breadcrumbs = [];
		let cursor: LayoutedGroup | LayoutedTarget | null = entity;
		let index = 0;
		while (cursor) {
			const isTarget = isLayoutedTarget(cursor);
			const label = isLayoutedTarget(cursor)
				? getSteadybitLabelOrDefault(cursor.target, cursor.target.name)
				: cursor.group.label +
					(isLandscapeGroupWithTargets(cursor.group)
						? ` (${cursor.group.totalUniqueTargets})`
						: ` (${cursor.layoutedGroups?.length})`);

			const cache = cursor;
			if (index > 0) {
				breadcrumbs.push(
					<Container key={`seperator-${index}`} color="neutral300" px="xSmall">
						/
					</Container>,
				);
				breadcrumbs.push(
					<Breadcrumb
						key={`crumb-${index}`}
						type={isTarget ? 'target' : 'group'}
						label={label}
						onClick={() => onBreadcrumbClicked(cache)}
						withText={expanded}
					/>,
				);
			} else {
				breadcrumbs.push(
					<Breadcrumb key={`crumb-${index}`} type={isTarget ? 'target' : 'group'} label={label} highlighted withText />,
				);
			}
			cursor = isTarget ? cursor.parent : cursor.parent;
			index++;
		}
		return breadcrumbs.reverse();
	}, [entity, expanded]);

	return (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',
				flexWrap: 'wrap',
				gap: '4px',
				px: 'small',
				py: 'small',
			}}
		>
			{breadcrumbs}
		</Container>
	);
}

interface BreadcrumbProps {
	type: 'group' | 'target';
	highlighted?: boolean;
	onClick?: () => void;
	withText?: boolean;
	label: string;
}

function Breadcrumb({ type, label, onClick, withText, highlighted = false }: BreadcrumbProps): ReactElement {
	const crumb = (
		<Stack
			direction="horizontal"
			size="xxSmall"
			onClick={onClick}
			sx={{
				alignItems: 'center',
				p: 'xSmall',
				bg: highlighted ? 'purple100' : 'neutral100',
				color: highlighted ? 'slate' : 'neutral800',
				borderRadius: '4px',

				...(onClick
					? {
							cursor: 'pointer',
							'&:hover': {
								color: 'slate',
								bg: 'purple100',
							},
						}
					: {}),
			}}
		>
			{type === 'group' ? <IconGroup variant="small" minWidth={16} /> : <IconTarget variant="small" minWidth={16} />}
			{withText && (
				<Text variant="smallStrong" sx={{ ...textEllipsis }}>
					{label}
				</Text>
			)}
		</Stack>
	);

	if (!withText) {
		return <Tooltip content={label}>{crumb}</Tooltip>;
	}

	return crumb;
}
