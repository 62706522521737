/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { theme } from 'styles.v2/theme';

import { TokensProvider } from './TokensProvider';

export const LANGUAGE = 'steadybitQueryLanguage';
export const TOKENIZER = new TokensProvider();

export const THEME = {
	name: 'steadybitLightTheme',
	colors: {
		green: '#7b9826',
		normalText: '#2c2421',
		purple: '#c33ef4',
		lightBlue: '#3d97b8',
		blue: '#407ee7',
		default: +theme.colors.neutral500,
	},
};
