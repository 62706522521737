/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Container, Stack, Text } from 'components';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

interface CardProps {
	children: ReactElement;
	description?: string;
	cypressTag?: string;
	title: string;
	onClick?: () => void;
}

export default function Card({ title, description, children, cypressTag, onClick }: CardProps): ReactElement {
	return (
		<Container
			data-cy={cypressTag}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'space-between',

				width: '216px',
				height: '258px',
				py: 'medium',

				borderRadius: '8px',
				border: '2px solid ' + theme.colors.neutral200,
				color: 'neutral700',

				...(onClick
					? {
							'&:hover': {
								backgroundColor: 'purple100',
								border: '2px solid ' + theme.colors.slate,
								color: 'slate',
								cursor: 'pointer',
							},
					  }
					: {}),
			}}
			onClick={onClick}
		>
			{children}

			<Stack size="xxSmall" justifyContent="center" height="42px">
				<Text variant="mediumStrong" textAlign="center">
					{title}
				</Text>
				{description && (
					<Text variant="small" textAlign="center">
						{description}
					</Text>
				)}
			</Stack>
		</Container>
	);
}
