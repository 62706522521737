/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { IconAdviceActionNeeded, IconAdviceImplemented, IconAdviceValidation, IconInformation } from 'components/icons';
import { Container, Stack, Text, Toggle, Tooltip } from 'components';
import { ReactElement, ReactNode } from 'react';

interface ShowAdviceToggleProps {
	onChange: (checked: boolean) => void;
	theme: 'light' | 'dark';
	withLegend?: boolean;
	readOnly?: boolean;
	sqashed?: boolean;
	checked: boolean;
}

export default function ShowAdviceToggle({
	withLegend,
	theme,
	checked,
	sqashed,
	onChange,
	readOnly,
}: ShowAdviceToggleProps): ReactElement {
	return (
		<Stack
			size="xSmall"
			sx={{
				py: 'small',
				px: sqashed ? '6px' : 'small',
				bg: theme === 'light' ? 'neutral000' : 'neutral100',
				borderRadius: '8px',
			}}
		>
			<Tooltip content={sqashed ? 'Show advice' : undefined}>
				<Stack
					direction="horizontal"
					sx={{
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					{!sqashed && (
						<Stack direction="horizontal" alignItems="center" size="xSmall">
							<Text variant="smallStrong" color="neutral800">
								Show advice
							</Text>
							<Tooltip
								content="Steadybit advice help you discover which chaos engineering experiments to run next and how to set them up."
								bindWidth={{
									target: 'reference',
									offset: 350,
								}}
							>
								<div>
									<IconInformation variant="small" color="neutral600" />
								</div>
							</Tooltip>
						</Stack>
					)}

					<Toggle checked={checked} disabled={readOnly} onChange={(e) => onChange(e.target.checked)} />
				</Stack>
			</Tooltip>
			{withLegend && checked && <AdviceLegend />}
		</Stack>
	);
}

function AdviceLegend(): ReactElement {
	return (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: '12px',
			}}
		>
			<Text variant="xSmall" color="neutral600">
				Advice types:
			</Text>

			<IconWrapper color="coral" tooltip="Some actions are needed">
				<IconAdviceActionNeeded color="neutral000" width={14} height={14} />
			</IconWrapper>

			<IconWrapper color="experimentWarning" tooltip="Some validation is needed">
				<IconAdviceValidation color="neutral000" width={14} height={14} />
			</IconWrapper>

			<IconWrapper color="feedbackSuccess" tooltip="All advice correctly implemented">
				<IconAdviceImplemented color="neutral000" width={14} height={14} />
			</IconWrapper>
		</Container>
	);
}

function IconWrapper({
	color,
	tooltip,
	children,
}: {
	tooltip: string;
	color: string;
	children: ReactNode;
}): ReactElement {
	return (
		<Tooltip content={tooltip}>
			<Container
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: color,
					borderRadius: '50%',
					padding: '4px',
				}}
			>
				{children}
			</Container>
		</Tooltip>
	);
}
