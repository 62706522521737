/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Banner, Stack, Text } from 'components';
import { ReactElement } from 'react';
import { days } from 'utils/time';

export default function SafariBrowserWarning(): ReactElement | null {
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	if (!isSafari) {
		return null;
	}

	return (
		<Banner
			id="steadybit-safari-warning"
			variant="info"
			title="Limited Support for Safari"
			description={
				<Stack size="none">
					<Text as="span" variant="small">
						Steadybit isn&apos;t fully optimized for Safari. In case you experience issues, please try Chrome, Edge, or
						Firefox.
					</Text>
				</Stack>
			}
			snooze={{
				id: 'safari-warning',
				end: new Date(Date.now() + days(7).getMillis()),
			}}
			sx={{
				py: '6px',
			}}
		/>
	);
}
