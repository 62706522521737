/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react';
import { Text } from 'components';

export default function ListSelectButton({ label, onClick }: { label: string; onClick: () => void }): ReactElement {
	return (
		<Text
			sx={{
				color: 'slate',
				cursor: 'pointer',
				':hover': {
					textDecoration: 'underline',
				},
			}}
			onClick={onClick}
		>
			{label}
		</Text>
	);
}
