/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import React, { DependencyList, useEffect } from 'react';
import { Services } from 'services/services';
import { SteadybitEventVO } from 'ui-api';
import { filter } from 'rxjs/operators';

export const useEventEffect = (
	cb: (event: SteadybitEventVO) => void,
	typeFilter: string[] = [],
	cancelCb: () => void = () => {},
	dependencies: DependencyList = [],
): void => {
	const typeFileterRef = React.useRef(typeFilter);

	useEffect(() => {
		const subscription = Services.events.events$
			.pipe(
				filter((event) => {
					const currentTypeFilter = typeFileterRef.current;
					if (Array.isArray(currentTypeFilter) && currentTypeFilter.length > 0) {
						return currentTypeFilter.includes(event.type);
					}
					return true;
				}),
			)
			.subscribe((e) => cb(e));

		return () => {
			cancelCb();
			subscription.unsubscribe();
		};
	}, dependencies);
};
