/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { getBaseHrefPathname } from 'utils/getBaseHref';
import invokePromise from 'utils/ignorePromise';
import { createBrowserHistory } from 'history';
import { userConfirm } from 'components';

import { wrap } from './historyWrap';

export const history = wrap(
	createBrowserHistory({
		basename: getBaseHrefPathname(),
		getUserConfirmation,
	}),
	{
		useAbsolutePaths: false,
	},
);

function getUserConfirmation(message: string, callback: (ok: boolean) => void): void {
	invokePromise(async () => {
		callback((await userConfirm(message)) === 'confirm');
	});
}
