/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ReactElement, ReactNode } from 'react';
import { Text } from 'components';

interface GroupTextProps {
	children: ReactNode;
	id: string;
}

export default function GroupText({ children, id }: GroupTextProps): ReactElement {
	return (
		<Text
			variant="small"
			sx={{
				textTransform: 'uppercase',
				color: 'neutral600',
				...textEllipsis,
			}}
			m="0"
			mb="xSmall"
			as="h2"
			id={id}
		>
			{children}
		</Text>
	);
}
