/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { IconWarningOpen } from 'components/icons';
import { Stack, Text } from 'components';
import { ReactElement } from 'react';

interface ErrorMessageProps {
	children: string[] | string | undefined;
	warning?: boolean;
}

export default function ErrorMessage({ warning, children }: ErrorMessageProps): ReactElement | null {
	if (!children) {
		return null;
	}

	const color = !warning ? 'coral' : 'experimentWarning';
	return (
		<Stack direction="horizontal" size="xxSmall" alignItems="center">
			<IconWarningOpen variant="xSmall" color={color} />
			<Text variant="xSmall" color={color}>
				{Array.isArray(children) ? children.join('. ') : children}
			</Text>
		</Stack>
	);
}
