/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import * as StyledSystem from 'styled-system';
import React from 'react';

export type TableHeadCellProps = ContainerProps &
	Omit<React.TdHTMLAttributes<HTMLTableDataCellElement>, keyof ContainerProps> &
	StyledSystem.JustifyContentProps;

export const TableHeadCell = React.forwardRef<HTMLTableDataCellElement, TableHeadCellProps>(
	({ children, justifyContent, sx = {}, ...props }, ref) => (
		<Container ref={ref} as="th" {...props}>
			{children ? (
				<Container display={'flex'} alignItems={'center'} sx={{ justifyContent, ...sx }}>
					{children}
				</Container>
			) : null}
		</Container>
	),
);
TableHeadCell.displayName = 'TableHeadCell';
