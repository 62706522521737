/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { UrlParam } from 'url/useUrlState';

export const targetTypeParam: (initialTargetType?: string) => UrlParam<string | undefined> = (initialTargetType) => ({
	pathSegment: '/table',
	name: 'targetType',
	defaultValue: initialTargetType,
});

export const targetIdParam: UrlParam<string | undefined> = {
	pathSegment: '/table',
	name: 'targetId',
	defaultValue: undefined,
};

export const queryParam: UrlParam<string | undefined> = {
	pathSegment: '/table',
	name: 'query',
	defaultValue: '',
};

export const pageParam: UrlParam<number | undefined> = {
	pathSegment: '/table',
	name: 'page',
	defaultValue: 0,
};

export const sortParam = {
	pathSegment: '/table',
	name: 'sort',
	defaultValue: undefined,
};

export const adviceParam: UrlParam<boolean | undefined> = {
	pathSegment: '/table',
	name: 'showAdvice',
	defaultValue: false,
};
