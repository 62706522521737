/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { AnimatePresence, motion } from 'framer-motion';
import { Container, ContainerProps } from 'components';
import React, { Children, ReactElement } from 'react';

export interface SettingsGroupProps extends ContainerProps {
	hidden?: boolean;
	tree?: boolean;
	hasError?: boolean;
}

export const SettingsGroup: React.FC<SettingsGroupProps> = ({
	children,
	tree = false,
	hidden,
	sx = {},
	hasError,
	variant = 'medium',
	...props
}) => {
	const childCount = Children.count(children);

	return (
		<AnimatePresence initial={false}>
			{!hidden && (
				<motion.div
					style={hidden === undefined ? {} : { overflow: 'hidden' }}
					initial={{ height: 0 }}
					animate={{ height: 'auto' }}
					exit={{ height: 0 }}
				>
					<Container
						ml={tree ? 'small' : 'none'}
						tx={'settingsGroup'}
						variant={variant}
						sx={{
							display: hidden ? 'none' : 'block',
							border: '1px solid',
							'[data-settings-group-header] [data-settings-group]': {
								border: 'none',
								marginTop: 'xSmall',
							},
							borderColor: hasError ? 'coral' : 'neutral300',
							borderRadius: '2px',
							...sx,
						}}
						{...props}
						data-settings-group
					>
						{React.Children.map(children, (child, index) => {
							if (child === null) {
								return null;
							}
							return React.cloneElement(child as ReactElement, {
								tree: tree && index < childCount ? true : undefined,
							});
						})}
					</Container>
				</motion.div>
			)}
		</AnimatePresence>
	);
};
