/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { getGlobal, setGlobal, removeGlobal } from 'utils/localStorage';
import { useTenant } from 'tenancy/useTenant';
import { useTeam } from 'services/useTeam';

interface Result {
	lastVisitedViewId: string | null;
	setLastViewedId: (viewId: string) => void;
	deleteLastViewedId: () => void;
}

export function useLastVisitedView(): Result {
	const tenant = useTenant();
	const team = useTeam();
	const key = `${tenant.key}-${team.id}-selectedViewId`;
	const lastVisitedViewId = getGlobal(key);
	return {
		lastVisitedViewId,
		setLastViewedId: (viewId: string) => setGlobal(key, viewId),
		deleteLastViewedId: () => removeGlobal(key),
	};
}
