/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { createResult, DataStreamResult } from 'utils/hooks/stream/result';
import { TargetTypeDescriptionVO } from 'ui-api';

import { useTargetDefinitions } from './useTargetDefinitions';

export function useTargetDefinition(
	id: string | null | undefined,
): DataStreamResult<TargetTypeDescriptionVO | undefined> {
	const result = useTargetDefinitions();

	if (!result.value) {
		return result;
	}

	return createResult(result.value.find((target) => target.id === id));
}
