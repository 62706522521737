/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { FieldHookConfig, useField } from 'formik';
import React from 'react';

import { Radio, RadioProps } from '../Radio';
import { hasError } from './utils';

type FormikRadioProps = Omit<RadioProps, 'error' | 'touched'>;

export const FormikRadio = React.forwardRef<HTMLDivElement, FormikRadioProps>((props, ref) => {
	const [field, meta] = useField({ type: 'radio', ...props } as FieldHookConfig<string>);
	return <Radio ref={ref} hasError={hasError(meta)} {...field} {...meta} {...props} />;
});
FormikRadio.displayName = 'FormikRadio';
