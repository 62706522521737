/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { TemplatePlaceholderVO } from 'ui-api';
import { Container, Pill } from 'components';
import { Stack } from 'components/Stack';
import { ReactElement } from 'react';

interface TemplatePlaceholderProps {
	placeholders: TemplatePlaceholderVO[];
	withHelpText?: boolean;
	onClick: (placeholder: TemplatePlaceholderVO) => void;
}

export default function TemplatePlaceholder({ placeholders, onClick }: TemplatePlaceholderProps): ReactElement | null {
	if (placeholders.length === 0) {
		return null;
	}

	return (
		<Container p={'small'}>
			<Stack direction="vertical" size="xSmall" alignItems={'flex-start'}>
				{placeholders.map((placeholder) => (
					<Pill
						key={placeholder.key}
						backgroundColor="aqua300"
						backgroundColorOnHover="aqua300"
						color="aqua800"
						onClick={() => onClick(placeholder)}
					>
						{`[[${placeholder.key}]]`}
					</Pill>
				))}
			</Stack>
		</Container>
	);
}
