/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import DropdownInput from 'components/Select/Dropdown/DropdownInput';
import Labels from 'components/Select/Dropdown/presets/Labels';
import { ReactElement, ReactNode, useState } from 'react';
import { Skeletons, Stack, Text } from 'components';
import { IconClose } from 'components/icons';
import { theme } from 'styles.v2/theme';

import WithDescription from './WithDescription';

interface DropDownProps {
	onItemClick: (key: string | null) => void;
	heading?: string | ReactNode;
	placeholder?: string;
	readonly?: boolean;
	framed?: boolean;
	items: string[];
	label: string;
}

export default function DropDown({
	readonly = false,
	placeholder,
	heading,
	framed,
	label,
	items,
	onItemClick,
}: DropDownProps): ReactElement {
	const [groupFilter, setGroupFilter] = useState<string | null>(null);

	const labelIsSet = !!label;

	const dropDown = (
		<DropdownInput
			disabled={readonly}
			placement="bottom-start"
			placeholder={placeholder}
			width="335px"
			value={groupFilter !== null ? groupFilter : label}
			onValueChanged={(v) => setGroupFilter(v || null)}
			onOpen={() => !groupFilter && setGroupFilter('')}
			onClose={() => {
				!groupFilter && setGroupFilter(null);
			}}
			sx={{
				bg: labelIsSet ? 'purple100' : 'neutral000',
				borderColor: labelIsSet ? 'purple300' : 'neutral300',
				color: labelIsSet ? theme.colors.slate + ' !important' : 'neutral800',
				fontWeight: labelIsSet ? '500' : 'normal',
			}}
			iconRight={
				!readonly && label ? (
					<IconClose
						variant="xSmall"
						onClick={() => {
							onItemClick(null);
							setGroupFilter(null);
						}}
						sx={{
							mt: 6,
							mr: framed ? 6 : 18,
							bg: labelIsSet ? 'purple100' : 'neutral000',
							cursor: 'pointer',
							color: 'neutral800',
							'&:hover': {
								color: 'neutral800',
							},
						}}
					/>
				) : null
			}
		>
			{({ selectItem, width }) => (
				<Content
					onClick={(key) => {
						onItemClick(key);
						selectItem('');
					}}
					width={width}
					items={items}
					groupFilter={groupFilter}
					selectedId={labelIsSet ? label : ''}
				/>
			)}
		</DropdownInput>
	);

	if (heading) {
		return (
			<WithDescription
				description={heading}
				sx={
					framed
						? {
								borderRadius: '8px',
								px: 'xSmall',
								py: 'small',
								bg: 'neutral100',
						  }
						: {}
				}
			>
				{dropDown}
			</WithDescription>
		);
	}
	return dropDown;
}

interface ContentProps {
	onClick: (key: string | null) => void;
	width: string | number | undefined;
	groupFilter?: string | null;
	selectedId: string;
	items: string[];
}

function Content({ selectedId, onClick, groupFilter, items, width }: ContentProps): ReactElement {
	const filteredList = items.filter((key) =>
		groupFilter ? key.toLowerCase().includes(groupFilter.toLowerCase()) : true,
	);
	const [maxItems, setMaxItems] = useState(50);

	return (
		<Labels<string>
			width={width}
			type="loose"
			maxHeight={400}
			maxItems={maxItems}
			onLoadMoreClick={() => setMaxItems(maxItems + 50)}
			onSelect={({ id }) => onClick(id)}
			labels={filteredList.map((key) => ({
				id: key,
				label: key,
			}))}
			selectedId={selectedId}
		/>
	);
}
interface LoadingDropDownProps {
	label: string;
}

export function LoadingDropDown({ label }: LoadingDropDownProps): ReactElement {
	return (
		<Stack size="xSmall">
			<Text variant="smallStrong" color="neutral600">
				{label}
			</Text>
			<Skeletons height={39} widths={['100%']} />
		</Stack>
	);
}
