/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { ExperimentArtifactVO, GetExperimentArtifactsResponse } from 'ui-api';
import axios from 'axios';

export class ExecutionArtifactsApi {
	async findArtifacts(executionId: number): Promise<ExperimentArtifactVO[]> {
		return (await axios.get<GetExperimentArtifactsResponse>(`/ui/artifacts/${executionId}`)).data.content;
	}

	async findArtifactFile(
		executionId: number,
		actionExecutionId: string,
		artifactId: string,
		file: string,
	): Promise<string> {
		const params = new URLSearchParams({ file });
		return (
			await axios.get<string>(`/ui/artifacts/${executionId}/${actionExecutionId}/${artifactId}`, {
				params,
				responseType: 'text',
			})
		).data;
	}
}
