/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Container, smellsLikeTemplatePlaceholder, StyleProp } from 'components';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { toTargetPredicate } from 'queryLanguage/parser/parser';
import { isParsingError } from 'queryLanguage/parser/types';
import * as monaco from 'monaco-editor-core';
import { theme } from 'styles.v2/theme';
import { debounce } from 'lodash';

import ExplanationIcon from './components/ExplanationIcon';
import { LANGUAGE, THEME, TOKENIZER } from './config';
import Placeholder from './components/Placeholder';
import useSuggestions from './useSuggestions';
import './style.css';

monaco.languages.register({ id: LANGUAGE });
monaco.languages.setTokensProvider(LANGUAGE, TOKENIZER);
monaco.editor.defineTheme(THEME.name, {
	base: 'vs',
	rules: [
		{ token: 'IS', foreground: THEME.colors.green },
		{ token: 'AND', foreground: THEME.colors.green },
		{ token: 'OR', foreground: THEME.colors.green },
		{ token: 'NOT', foreground: THEME.colors.green },
		{ token: 'PRESENT', foreground: THEME.colors.green },
		{ token: 'COUNT', foreground: THEME.colors.green },
		{ token: 'LPAREN', foreground: THEME.colors.normalText },
		{ token: 'RPAREN', foreground: THEME.colors.normalText },
		{ token: 'OP_NOT_EQUAL', foreground: THEME.colors.purple },
		{ token: 'OP_NOT_EQUAL_IGNORE_CASE', foreground: THEME.colors.purple },
		{ token: 'OP_EQUAL', foreground: THEME.colors.purple },
		{ token: 'OP_EQUAL_IGNORE_CASE', foreground: THEME.colors.purple },
		{ token: 'OP_TILDE', foreground: THEME.colors.purple },
		{ token: 'OP_TILDE_IGNORE_CASE', foreground: THEME.colors.purple },
		{ token: 'OP_NOT_TILDE', foreground: THEME.colors.purple },
		{ token: 'OP_NOT_TILDE_IGNORE_CASE', foreground: THEME.colors.purple },
		{ token: 'QUOTED', foreground: THEME.colors.lightBlue },
		{ token: 'TERM', foreground: THEME.colors.blue },
		{ token: 'DEFAULT_SKIP', foreground: theme.colors.coral500 },
		{ token: 'UNKNOWN', foreground: THEME.colors.normalText },
	],
	inherit: false,
	colors: {
		'editor.background': theme.colors.neutral100,
	},
});

interface ValidationResult {
	text: string;
	markers: monaco.editor.IMarkerData[];
}

function createValidationResult(text: string, markers: monaco.editor.IMarkerData[] = []): ValidationResult {
	return { text, markers };
}

const validate = (
	textToValidate: string,
	setValidationResult: (r: ValidationResult) => void,
	allowFullQueryPlaceholder: boolean,
): void => {
	if (textToValidate.length === 0) {
		return setValidationResult(createValidationResult(textToValidate));
	}
	if (
		allowFullQueryPlaceholder &&
		(smellsLikeTemplatePlaceholder(textToValidate) || smellsLikeTemplatePlaceholder(`"${textToValidate}"`))
	) {
		return setValidationResult(createValidationResult(textToValidate));
	}
	const parseResult = toTargetPredicate(textToValidate);
	if (!isParsingError(parseResult)) {
		return setValidationResult(createValidationResult(textToValidate));
	}
	setValidationResult(
		createValidationResult(textToValidate, [
			{
				severity: monaco.MarkerSeverity.Error,
				message: parseResult.message,
				startLineNumber: parseResult.line,
				startColumn: parseResult.column + 1,
				endLineNumber: parseResult.line,
				endColumn: parseResult.column + 4,
			},
		]),
	);
};

export type CustomKeywordFunction = () => string[];
export type CustomSuggestionFunction = (key: string) => string[];

interface EditorProps {
	getAdditionalSuggestions?: CustomSuggestionFunction;
	getAdditionalKeywords?: CustomKeywordFunction;
	onValidCodeChanged?: (text: string) => void;
	highlightTokensContainingString?: string;
	allowFullQueryPlaceholder?: boolean;
	forceUpdateSignal?: string | number;
	debounceInputTime?: number;
	environmentId?: string;
	initialValue?: string;
	onApply?: () => void;
	targetType?: string;
	maxHeight?: number;
	disabled?: boolean;
	sx?: StyleProp;
}

export default function getAdditionalSuggestions({
	allowFullQueryPlaceholder = false,
	highlightTokensContainingString,
	getAdditionalSuggestions,
	debounceInputTime = 200,
	getAdditionalKeywords,
	onValidCodeChanged,
	forceUpdateSignal,
	initialValue = '',
	environmentId,
	targetType,
	maxHeight,
	disabled,
	onApply,
	sx,
}: EditorProps): ReactElement {
	const ref = useRef<HTMLDivElement>(null);
	const [editor, setEditor] = useState<monaco.editor.IStandaloneCodeEditor | null>(null);
	const [validationResult, setValidationResult] = useState<ValidationResult>(createValidationResult(initialValue));
	const [editorHeight, setEditorHeight] = useState(0);

	const debouncedValidate = useMemo(
		() =>
			debounce(
				(t, setValidationResult) => validate(t, setValidationResult, allowFullQueryPlaceholder),
				debounceInputTime,
				{
					leading: false,
				},
			),
		[debounceInputTime],
	);

	useEffect(() => {
		if (editor) {
			editor.setValue(initialValue);
			editor.setPosition({ lineNumber: Number.MAX_VALUE, column: Number.MAX_VALUE });
		}
	}, [forceUpdateSignal]);

	useEffect(() => {
		if (!ref.current) {
			return;
		}
		const editor = monaco.editor.create(ref.current, {
			theme: THEME.name,
			language: LANGUAGE,
			quickSuggestionsDelay: 0,
			wordBasedSuggestions: true,
			suggestOnTriggerCharacters: true,
			readOnly: disabled,
			padding: {
				top: 14,
			},
			quickSuggestions: {
				strings: true,
				other: true,
				comments: true,
			},
			wordWrap: 'on',
			automaticLayout: true,
			minimap: { enabled: false },
			renderControlCharacters: false,
			lineNumbers: 'off',
			scrollBeyondLastLine: false,
			value: initialValue,
			renderLineHighlight: 'none',
			overviewRulerBorder: false,
			hideCursorInOverviewRuler: true,
			fixedOverflowWidgets: true,
		});
		const focusDisposable = editor.onDidFocusEditorText(() => {
			editor.trigger('', 'editor.action.triggerSuggest', {});
		});
		validate(initialValue, setValidationResult, allowFullQueryPlaceholder);
		setEditor(editor);
		return () => {
			editor.dispose();
			focusDisposable.dispose();
			setEditor(null);
		};
	}, [ref]);

	useEffect(() => {
		if (!editor) {
			return;
		}
		editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter, function () {
			onApply?.();
		});
		editor.addCommand(monaco.KeyMod.WinCtrl | monaco.KeyCode.Enter, function () {
			onApply?.();
		});
	}, [editor, onApply]);

	useEffect(() => {
		if (!editor || !ref.current) {
			return;
		}
		function checkHeight(element: HTMLElement): void {
			const numLines = element.childNodes.length;
			const height = numLines * 18 + 30;
			if (editorHeight !== height) {
				setEditorHeight(height);
			}
		}
		const observer = new MutationObserver((entries) => {
			checkHeight(entries[0].target as HTMLElement);
		});
		const linesWrapper = ref.current.getElementsByClassName('view-lines')[0] as HTMLElement;
		observer.observe(linesWrapper, {
			childList: true,
		});
		return () => {
			observer.disconnect();
		};
	}, [editor, ref.current, validationResult.text]);

	useEffect(() => {
		if (!editor || !ref.current) {
			return;
		}

		// only callback valid code
		if (onValidCodeChanged && validationResult.markers.length === 0 && editor.getValue() !== initialValue) {
			onValidCodeChanged(validationResult.text);
		}

		const onDidChangeModelContentDisposable = editor.onDidChangeModelContent(() => {
			const text = editor.getValue();
			if (text !== validationResult.text) {
				debouncedValidate(editor.getValue(), setValidationResult);
				editor.trigger('', 'editor.action.triggerSuggest', {});
			}
		});
		const model = editor.getModel();
		if (model) {
			monaco.editor.setModelMarkers(model, 'owner', validationResult.markers);

			if (highlightTokensContainingString) {
				const matches: monaco.editor.FindMatch[] = model.findMatches(
					highlightTokensContainingString,
					false,
					true,
					false,
					null,
					true,
				);
				matches.forEach((match: monaco.editor.FindMatch): void => {
					model.deltaDecorations(
						[],
						[
							{
								range: match.range,
								options: {
									isWholeLine: false,
									inlineClassName: 'monaco-editor-matchDecoration-highlighted',
								},
							},
						],
					);
				});
			}
		}
		return () => {
			onDidChangeModelContentDisposable.dispose();
		};
	}, [editor, validationResult.text]);

	useSuggestions({ editor, targetType, environmentId: environmentId, getAdditionalSuggestions, getAdditionalKeywords });

	return (
		<Container
			ref={ref}
			sx={{
				position: 'relative',
				minHeight: 46,
				maxHeight,
				height: editorHeight,
				paddingLeft: 12,
				backgroundColor: 'neutral100',
				width: '100%',
				...sx,
			}}
		>
			{!disabled && <ExplanationIcon />}
			<Placeholder editor={editor} />
		</Container>
	);
}
