/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import React, { useEffect, useMemo, useState } from 'react';
import { FormikErrors, useFormikContext } from 'formik';
import { ShortenedText, TextField } from 'components';
import { IconDescription } from 'components/icons';

import { ExperimentFormValues } from '../../experiment';
import { hasFormikError } from '../../wizard/utils';
import DropDownPill from '../DropDownPill';

export const ExperimentSubHeaderHypthesis: React.VFC<{
	errors: FormikErrors<ExperimentFormValues>;
	disabled: boolean;
}> = ({ errors, disabled }) => {
	const formik = useFormikContext<ExperimentFormValues>();
	const { setFieldValue, setFieldTouched } = formik;
	const { hypothesis } = formik.values;

	const [hypothesisEdit, setHypothesisEdit] = useState<string | undefined>(undefined);
	const [inititalHypothesis, setInitialHypothesis] = useState<string | undefined>(hypothesis);
	useEffect(() => setInitialHypothesis(hypothesis), [hypothesis]);
	const hasSettingsError = useMemo(() => hasFormikError(errors, ['hypothesis']), [errors]);
	const color = hasSettingsError ? 'coralDark' : 'neutral700';

	return (
		<DropDownPill
			content={
				<>
					<IconDescription
						sx={{
							minHeight: '16px',
							minWidth: '16px',
							maxHeight: '16px',
							maxWidth: '16px',
							color,
							mr: 'xSmall',
						}}
					/>
					<ShortenedText variant={'small'} sx={{ color: { color }, whiteSpace: 'nowrap' }}>
						{hypothesis || 'No Hypothesis'}
					</ShortenedText>
				</>
			}
			sx={{ maxWidth: 400, overflow: 'hidden', textOverflow: 'ellipsis' }}
			onOpen={() => setHypothesisEdit(hypothesis)}
			onClose={() => {
				setFieldValue('hypothesis', hypothesisEdit, false);
				setFieldTouched('hypothesis', true);
				setHypothesisEdit(undefined);
			}}
		>
			{() => (
				<TextField
					sx={{ minHeight: 150, minWidth: 400 }}
					color={'neutral700'}
					as="textarea"
					value={hypothesisEdit}
					placeholder="What outcome do you expect from this experiment?"
					variant={'small'}
					pl={'xSmall'}
					onChange={(e) => setHypothesisEdit(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === 'Escape' || e.key === 'Esc') {
							setHypothesisEdit(inititalHypothesis);
							e.target.dispatchEvent(new Event('dropdown_close', { bubbles: true }));
							e.stopPropagation();
							e.preventDefault();
						}
					}}
					maxLength={20_000}
					readOnly={disabled}
				/>
			)}
		</DropDownPill>
	);
};
