/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ExperimentStepExecutionActionTargetVO, ExperimentStepExecutionActionVO } from 'ui-api';
import { IconChevronDown, IconChevronRight } from 'components/icons';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { Fragment, ReactElement, useState } from 'react';
import { Container, Stack, Text } from 'components';
import { formatTime } from 'utils/dateFns';
import { theme } from 'styles.v2/theme';

import { getColorForState, getIconForState, isCanceled, isCreated, isPrepared, isSkipped, isSucceeded } from '../utils';
import { TargetExecution, isExperimentStepExecutionActionVO } from '../experimentExecutionLog';

interface LogLineProps {
	targetExecution: ExperimentStepExecutionActionTargetVO;
	step: ExperimentStepExecutionActionVO;
	experimentExecutionId: number;
	backgroundColor: string;
}

export default function LogLine({
	experimentExecutionId,
	targetExecution,
	backgroundColor,
	step,
}: LogLineProps): ReactElement {
	const [expanded, setExpanded] = useState(false);
	const { state, started, ended } = targetExecution;

	const succeeded = isSucceeded(state);
	const canceled = isCanceled(state);
	const prepared = isPrepared(state);
	const created = isCreated(state);
	const skipped = isSkipped(state);
	const Icon = getIconForState(state, true);
	const isBold = !succeeded && !canceled;
	const color = succeeded || canceled ? 'neutral700' : getColorForState(state);

	return (
		<Stack
			size="xSmall"
			sx={{
				backgroundColor: expanded ? 'neutral050' : backgroundColor,
				border: expanded ? '2px solid ' + theme.colors.slate : '2px solid transparent',
				borderRadius: expanded ? '8px' : '4px',
			}}
		>
			<Stack
				direction="horizontal"
				alignItems="center"
				size="xSmall"
				px="xSmall"
				py="xxSmall"
				onClick={() => setExpanded(!expanded)}
				sx={{
					cursor: 'pointer',
					':hover .chevron': {
						color: 'neutral600',
					},
				}}
			>
				<Stack direction="horizontal" alignItems="center" size="xSmall" minWidth="fit-content">
					<Container
						sx={{
							width: '16px',
							mt: '-1px',
							color: 'neutral800',
						}}
						className={'chevron'}
					>
						{targetExecution.agentHostname ? (
							expanded ? (
								<IconChevronDown variant="xSmall" />
							) : (
								<IconChevronRight variant="small" />
							)
						) : null}
					</Container>
					<Icon variant="small" color={color} minWidth={16} mr="xxSmall" />
					{(started || ended) && !skipped && (
						<Text
							variant={isBold ? 'smallMedium' : 'small'}
							sx={{
								fontFamily: 'code',
								color: skipped ? 'neutral500' : color,
								whiteSpace: 'nowrap',
								minWidth: 'fit-content',
								mr: 'xxSmall',
							}}
						>
							{`${started ? formatTime(started) : ''} - ${ended ? formatTime(ended) : ''}`}
						</Text>
					)}
					<Container display="flex" alignItems="center" flexWrap="wrap" overflow="hidden">
						{isExperimentStepExecutionActionVO(step) && (
							<Text variant={isBold ? 'smallMedium' : 'small'} color={color}>
								<TargetExecution
									step={step as ExperimentStepExecutionActionVO}
									experimentExecutionId={experimentExecutionId}
									targetExecution={targetExecution}
								/>
								{prepared ? ' prepared' : created ? ' created' : skipped ? ' skipped' : ''}
							</Text>
						)}
					</Container>
				</Stack>
				{!expanded && <FirstReasonText targetExecution={targetExecution} />}
			</Stack>
			{expanded && targetExecution.agentHostname && <LogLineDetails targetExecution={targetExecution} />}
		</Stack>
	);
}

interface LogLineTooltipProps {
	targetExecution: ExperimentStepExecutionActionTargetVO;
}

function FirstReasonText({ targetExecution }: LogLineTooltipProps): ReactElement | null {
	if (!targetExecution.reason) {
		return null;
	}
	const firstLine = targetExecution.reason.split('\n')[0];
	return (
		<Text variant="small" as="span" fontFamily="monospace" sx={{ ...textEllipsis }}>
			{firstLine}
		</Text>
	);
}

interface LogLineTooltipProps {
	targetExecution: ExperimentStepExecutionActionTargetVO;
}

function LogLineDetails({ targetExecution }: LogLineTooltipProps): ReactElement {
	const { agentHostname, reasonDetails, instance } = targetExecution;

	return (
		<Container
			sx={{
				display: 'grid',
				gridTemplateColumns: '56px 1fr',
				gridColumn: '3 / span 4',

				pb: 'xSmall',
				pr: 'small',
				pl: '62px',
				gap: '6px',
				color: 'neutral700',
				overflow: 'hidden',
			}}
		>
			{targetExecution.reason && (
				<div
					style={{
						gridColumn: '1 / span 2',
					}}
				>
					{targetExecution.reason.includes('\n') ? (
						targetExecution.reason.split('\n').map((split, i) => (
							<Text key={i} variant="small" fontFamily="monospace">
								{split}
							</Text>
						))
					) : (
						<Text variant="small" fontFamily="monospace">
							{targetExecution.reason}
						</Text>
					)}
				</div>
			)}

			<Text variant={'smallStrong'}>Agent:</Text>
			<Text variant={'small'} sx={{ wordBreak: 'break-word' }}>
				{agentHostname}
			</Text>
			{reasonDetails && (
				<>
					<Text variant={'smallStrong'}>Details:</Text>
					<Text variant={'small'} sx={{ wordBreak: 'break-word' }}>
						{reasonDetails.includes('\n')
							? reasonDetails.split('\n').map((split, i) => (
									<Fragment key={i}>
										{split}
										<br />
									</Fragment>
								))
							: reasonDetails}
					</Text>
				</>
			)}
			{instance && (
				<>
					<Text variant={'smallStrong'}>Source:</Text>
					<Text variant={'small'} sx={{ wordBreak: 'break-word' }}>
						{instance}
					</Text>
				</>
			)}
		</Container>
	);
}
