/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { DashboardStatisticsVO, OnboardingVO } from 'ui-api';
import { merge, Observable, Subject, timer } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import axios from 'axios';

export class DashboardApi {
	private forceStatsUpdateSubject: Subject<string>;

	constructor() {
		this.forceStatsUpdateSubject = new Subject();
	}

	statsObservable(environmentId: string): Observable<DashboardStatisticsVO> {
		return merge(timer(0, 10_000), this.forceStatsUpdateSubject.asObservable()).pipe(
			exhaustMap(async () => {
				return await this.fetchStatistics(environmentId);
			}),
		);
	}

	forceStatsUpdate(): void {
		this.forceStatsUpdateSubject.next('forced update');
	}

	async fetchStatistics(environmentId?: string): Promise<DashboardStatisticsVO> {
		const params = new URLSearchParams();
		if (environmentId) {
			params.append('environmentId', environmentId);
		}
		return (await axios.get<DashboardStatisticsVO>('/ui/dashboard/stats', { params })).data;
	}

	async fetchOnboardingStatus(): Promise<OnboardingVO> {
		return (await axios.get<OnboardingVO>('/ui/dashboard/onboarding')).data;
	}

	async setOnboardingStatusComplete(ignoreMissingAttacks = false): Promise<void> {
		await axios.post('/ui/dashboard/onboarding/complete', undefined, { params: { ignoreMissingAttacks } });
	}
}
