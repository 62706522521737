/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, ModalContentV2, ModalHeaderV2, ModalOverlay, ModalV2, Stack, Text } from 'components';
import { ActionVO, TargetTypeDescriptionVO, TemplateVO } from 'ui-api';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { usePromise } from 'utils/hooks/usePromise';
import { IconArrowLeft } from 'components/icons';
import { Services } from 'services/services';
import { ReactElement } from 'react';

import TemplateDetails from './TemplateDetails/TemplateDetails';

interface TemplateDetailsModalProps {
	targetDefinitionsResult: DataStreamResult<TargetTypeDescriptionVO[]>;
	actionsResult: DataStreamResult<ActionVO[]>;
	templatePreviewId: string;
	onEditClick?: (template: TemplateVO) => void;
	onUseClick?: (template: TemplateVO) => void;
	onGoBack: () => void;
	onClose: () => void;
}

export default function TemplateDetailsModal({
	targetDefinitionsResult,
	templatePreviewId,
	actionsResult,
	onEditClick,
	onUseClick,
	onGoBack,
	onClose,
}: TemplateDetailsModalProps): ReactElement {
	const templateResult = usePromise(() => Services.templatesApi.getTemplate(templatePreviewId), [templatePreviewId]);

	return (
		<ModalOverlay open onClose={onClose}>
			<ModalV2 width="90vw" maxWidth="1650px" minHeight="calc(100vh - 4rem)">
				<ModalHeaderV2 title="" onClose={onGoBack} />
				<ModalContentV2>
					<Stack size="large">
						<Button variant="chromeless" onClick={onGoBack} width="fit-content" color="neutral600">
							<IconArrowLeft variant="medium" mr="xSmall" />
							<Text variant="smallStrong">Back</Text>
						</Button>
						{templateResult.value && targetDefinitionsResult.value && actionsResult.value ? (
							<TemplateDetails
								targetDefinitions={targetDefinitionsResult.value}
								template={templateResult.value}
								actions={actionsResult.value}
								onEditClick={onEditClick}
								onUseClick={onUseClick}
							/>
						) : (
							<LoadingTemplateDetails />
						)}
					</Stack>
				</ModalContentV2>
			</ModalV2>
		</ModalOverlay>
	);
}

function LoadingTemplateDetails(): ReactElement {
	return <></>;
}
