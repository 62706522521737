/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ViolationVO } from 'ui-api';
import axios from 'axios';

import { setGlobalAvailability } from './platformAvailability';

type Problem = {
	instance?: string;
	type: string;
	parameters: Record<string, unknown>;
	detail?: string;
	status?: number;
	title?: string;
};

export class SteadybitError extends Error {
	instance?: string;
	type: string;
	parameters: Record<string, unknown>;
	detail?: string;
	status?: number;
	title?: string;

	constructor(p: Problem) {
		super(p.detail);
		this.instance = p.instance;
		this.type = p.type;
		this.parameters = p.parameters || {};
		this.detail = p.detail;
		this.status = p.status;
		this.title = p.title;
	}

	toString(): string {
		return this.title || 'Unexpected Error';
	}
}

class ServiceUnavailable extends SteadybitError {
	toString(): string {
		return this.title || 'Service Unavailable';
	}
}

type ProblemWithViolations = Problem & { violations: ViolationVO[] };

export class ValidationError extends SteadybitError {
	violations: ViolationVO[];

	constructor(p: ProblemWithViolations) {
		super(p);
		this.violations = p.violations;
	}

	toString(): string {
		return this.title || 'Validation Error';
	}
}

class InsufficientPermissionsError extends SteadybitError {
	toString(): string {
		return this.detail || 'Insufficient Permissions';
	}
}

export const setupAxiosErrorInterceptor = (): void => {
	axios.interceptors.response.use(
		(response) => response,
		async (error) => {
			const contentType = error.response?.headers['content-type'];
			const problem = error.response?.data;
			// spring 6 currently does return application/json for problems, see https://github.com/spring-projects/spring-framework/issues/29588
			// so we check if this looks like application/problem+json
			const isProblem =
				problem &&
				(contentType === 'application/problem+json' ||
					(contentType === 'application/json' && 'status' in problem && 'type' in problem));
			if (isProblem) {
				if (problem.status === 503) {
					setGlobalAvailability(false);
					throw new ServiceUnavailable(problem);
				}
				if (problem.type === 'https://steadybit.com/problems/validation-exception') {
					throw new ValidationError(problem);
				}
				if (problem.type === 'https://steadybit.com/problems/insufficient-permissions-exception') {
					throw new InsufficientPermissionsError(problem);
				}
				throw new SteadybitError(problem);
			}
			throw error;
		},
	);
};
