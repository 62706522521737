/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

export default `
precision highp float;
precision highp int;

uniform float worldUnitInScreenSpace;

attribute float targetSize;

varying vec2 vUv;
varying float fTargetSize;
varying float fWorldUnitInScreenSpace;

void main()	{
	vUv = uv;
	fTargetSize = targetSize;
	fWorldUnitInScreenSpace = worldUnitInScreenSpace;

	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
`;
