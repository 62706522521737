/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ReactElement, ReactNode } from 'react';
import { RouterButton } from 'components';
import { setParamValue } from 'url/utils';
import { useHistory } from 'url/hooks';

interface InstallAgentsButtonPros {
	children: ReactNode;
	onClick?: () => void;
}

export default function InstallAgentsButton({ onClick, children }: InstallAgentsButtonPros): ReactElement {
	const { createHref } = useHistory();

	return (
		<RouterButton
			color="neutral000"
			to={createHref((location) => {
				location.pathname = '/onboarding';
				setParamValue(
					location,
					{
						pathSegment: '/onboarding',
						name: 'page',
					},
					1,
				);
			})}
			onClick={onClick}
		>
			{children}
		</RouterButton>
	);
}
