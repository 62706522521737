/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React from 'react';

import { IconComponent } from '../icons';
import { Stack } from '../Stack';
import { Text } from '../Text';

export type DashboardWidgetProps = ContainerProps & {
	icon: IconComponent;
	title: React.ReactNode;
	actionsLeft?: React.ReactNode;
	actionsRight?: React.ReactNode;
};

export const DashboardWidget: React.FC<DashboardWidgetProps> = ({
	icon,
	title,
	actionsLeft,
	actionsRight,
	children,
	sx = {},
	...props
}) => {
	const Icon = icon;

	return (
		<Container
			bg="neutral000"
			sx={{
				display: 'grid',
				gridTemplateRows: 'auto 1fr',
				borderColor: 'neutral300',
				borderRadius: '2px',
				overflow: 'hidden',
				boxShadow: 'applicationMedium',
				...sx,
			}}
			{...props}
		>
			<Stack size={0} direction={'horizontal'} justifyContent={'space-between'}>
				<Container
					display={'flex'}
					bg={'neutral000'}
					pt={'small'}
					px={'medium'}
					sx={{ alignItems: 'center', justifyContent: 'flex-start' }}
				>
					<Icon mr={'xSmall'} size={20} />
					<Text variant={'mediumStrong'} color={'neutral800'} mr={'xSmall'}>
						{title}
					</Text>
					{actionsLeft}
				</Container>
				<Container
					display={'flex'}
					bg={'neutral000'}
					pt={'small'}
					px={'medium'}
					sx={{ alignItems: 'center', justifyContent: 'flex-end' }}
				>
					{actionsRight}
				</Container>
			</Stack>

			<Container p={'medium'}>{children}</Container>
		</Container>
	);
};
