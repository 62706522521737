/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { CopyToClipboard as ReactCopyToClipboard } from 'react-copy-to-clipboard';
import Highlight, { defaultProps, Language } from 'prism-react-renderer';
import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import { IconDuplicate, IconSaveFile } from 'components/icons';
import { download, DownloadArgs } from 'utils/download';
import theme from 'prism-react-renderer/themes/github';
import { ButtonIcon } from 'components/ButtonIcon';
import { Text, TextProps } from 'components/Text';
import { Container } from 'components/Container';
import { Snackbar } from 'components/Snackbar';
import { Stack } from 'components/Stack';
import { omit } from 'lodash';

export type WithDownloadOptions = Omit<DownloadArgs, 'fileContent'>;

type CodeProps = TextProps & {
	getLineStyle?: (tokens: string[]) => CSSProperties;
	getTokenStyle?: (token: string) => CSSProperties;
	getContentToCopyOrDownload?: () => string;
	withDownload?: WithDownloadOptions;
	withCopyToClipboard?: boolean;
	children: string | ReactNode;
	highlight?: boolean;
	inline?: boolean;
	lang?: Language;
};

export const Code: React.FC<CodeProps> = ({
	getContentToCopyOrDownload,
	withCopyToClipboard,
	highlight = false,
	inline = false,
	getTokenStyle,
	withDownload,
	getLineStyle,
	children,
	lang,
	...props
}) => {
	if (inline) {
		const actions =
			withCopyToClipboard && getContentToCopyOrDownload
				? {
						onClick: () => {
							navigator.clipboard.writeText(getContentToCopyOrDownload()).then(() => {
								return Snackbar.dark(
									<>
										<Text variant={'medium'} sx={{ display: 'inline' }}>
											Value copied to your clipboard!
										</Text>
									</>,
									{ toastId: 'value-copied' },
								);
							});
						},
				  }
				: {};
		return (
			<Text
				as="span"
				variant="small"
				fontFamily="monospace"
				bg="neutral100"
				color="slate"
				px="xxSmall"
				{...actions}
				{...props}
			>
				{children}
			</Text>
		);
	}

	if (highlight) {
		return (
			<Text
				as={'span'}
				fontFamily={'monospace'}
				bg={'purple200'}
				color={'purple800'}
				sx={{ borderRadius: '4px', paddingX: 'xSmall' }}
				{...props}
			>
				{children}
			</Text>
		);
	}

	if ((lang || withCopyToClipboard || withDownload) && typeof children !== 'string') {
		throw new Error('Syntax highlighting, copy to clipboard and downloads only work when children is of type string.');
	}

	const actions = (
		<Actions
			withCopyToClipboard={withCopyToClipboard}
			withDownload={withDownload}
			getContent={() => (getContentToCopyOrDownload ? getContentToCopyOrDownload() : (children as string))}
		/>
	);

	if (lang) {
		return (
			<Highlight {...defaultProps} code={children as string} language={lang} theme={theme}>
				{({ className, style, tokens, getLineProps, getTokenProps }) => {
					return (
						<Container
							className={className}
							sx={{
								position: 'relative',
								bg: 'neutral100',
								style: omit(style, 'background'),
								...props.sx,
							}}
						>
							{actions}

							<Text
								fontFamily={'monospace'}
								color={'neutral800'}
								{...props}
								sx={{ whiteSpace: 'pre', overflowX: 'auto', p: 'small' }}
							>
								{tokens.map((line, i) => {
									const lineProps = getLineProps({ line, key: i });
									const lineStyle = getLineStyle ? getLineStyle(line.map((token) => token.content)) : {};
									return (
										// eslint-disable-next-line react/jsx-key
										<div {...lineProps} style={{ ...lineProps.style, ...lineStyle }}>
											{line.map((token, key) => {
												const tokenProps = getTokenProps({ token, key });
												const tokenStyle = getTokenStyle ? getTokenStyle(token.content) : {};
												return (
													// eslint-disable-next-line react/jsx-key
													<span {...tokenProps} style={{ ...tokenProps.style, ...tokenStyle }} />
												);
											})}
										</div>
									);
								})}
							</Text>
						</Container>
					);
				}}
			</Highlight>
		);
	}

	return (
		<Text
			fontFamily={'monospace'}
			bg={'neutral100'}
			color={'neutral800'}
			p={'small'}
			{...props}
			sx={{ whiteSpace: 'pre', ...props.sx, position: 'relative' }}
		>
			{actions}
			{children}
		</Text>
	);
};

interface ActionsProps {
	withDownload?: WithDownloadOptions;
	withCopyToClipboard?: boolean;
	getContent: () => string;
}

function Actions({ withCopyToClipboard, withDownload, getContent }: ActionsProps): ReactElement | null {
	if (!withCopyToClipboard && !withDownload) {
		return null;
	}

	const content = getContent();

	return (
		<Stack direction="horizontal" size="xxSmall" sx={{ position: 'absolute', top: 12, right: 12 }}>
			{withDownload != null && (
				<ButtonIcon
					variant="small"
					tooltip="Download"
					onClick={() =>
						download({
							...withDownload,
							fileContent: content,
						})
					}
					sx={{ background: 'neutral100' }}
				>
					<IconSaveFile />
				</ButtonIcon>
			)}

			{withCopyToClipboard && (
				<ReactCopyToClipboard text={content} onCopy={() => Snackbar.dark('Copied!')}>
					<ButtonIcon variant="small" tooltip="Copy to clipboard" sx={{ background: 'neutral100' }}>
						<IconDuplicate />
					</ButtonIcon>
				</ReactCopyToClipboard>
			)}
		</Stack>
	);
}
