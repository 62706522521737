/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Container, Text } from 'components';
import { ReactElement } from 'react';

interface LoadMoreIndicatorProps {
	loadMore: () => void;
	label?: string;
}

export default function LoadMoreIndicator({ loadMore, label = 'Load more' }: LoadMoreIndicatorProps): ReactElement {
	return (
		<Container py="xSmall" onClick={loadMore}>
			<Text
				variant="smallStrong"
				sx={{
					color: 'slate',
					cursor: 'pointer',
					':hover': {
						color: 'purple800',
					},
				}}
			>
				{label}
			</Text>
		</Container>
	);
}
