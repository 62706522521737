/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { BaseExperimentStepVOUnion, ExperimentLaneVO, ExperimentStepActionVO, ExperimentStepWaitVO } from 'ui-api';
import { ReactElement, useEffect, useState } from 'react';
import { useUrlState } from 'url/useUrlState';
import { Container } from 'components';
import { useField } from 'formik';

import { duplicateStep, filterEmpty, findStep, removeStep } from '../utils';
import { UrlState, selectedStepIdParam } from '../urlParams';
import Action from './Action';
import Wait from './Wait';

export default function StepConfigurationSidebar(): ReactElement | null {
	const [{ selectedStepId }] = useUrlState<UrlState>([selectedStepIdParam]);
	const [lanesField] = useField<ExperimentLaneVO[]>({ name: 'lanes' });
	const { value: lanes } = lanesField;

	if (!selectedStepId) {
		return null;
	}

	const [step, stepPath] = findStep(lanes, selectedStepId);
	if (!step) {
		return null;
	}

	return <StepConfigurationSidebarContent step={step} path={stepPath} />;
}

interface StepConfigurationSidebarProps {
	step: BaseExperimentStepVOUnion;
	path: string;
}

function StepConfigurationSidebarContent({ step, path }: StepConfigurationSidebarProps): ReactElement | null {
	const [expanded, setExpanded] = useState<boolean>(false);
	const [{ selectedStepId }, , updateUrlState] = useUrlState<UrlState>([selectedStepIdParam]);
	const [lanesField, , { setValue: setLanes, setTouched }] = useField<ExperimentLaneVO[]>({ name: 'lanes' });
	const { value: lanes } = lanesField;

	const onDelete: () => void = () => {
		setLanes(filterEmpty(removeStep(lanes, step.id)));
		setTouched(true);
		updateUrlState({ selectedStepId: undefined });
	};

	const onDuplicate: () => void = () => {
		const newLanes = duplicateStep(lanes, step);
		if (newLanes) {
			setLanes(newLanes);
			setTouched(true);
		}
	};

	const onClose: () => void = () => updateUrlState({ selectedStepId: undefined });

	useEffect(() => {
		const onKeyDown = (e: KeyboardEvent): void => {
			if (e.key === 'Delete') {
				const isBody = e.target === document.body;
				if (isBody) {
					onDelete();
				}
			}
		};
		window.addEventListener('keydown', onKeyDown);
		return () => {
			window.removeEventListener('keydown', onKeyDown);
		};
	}, [step.id]);

	return (
		<Container
			key={selectedStepId}
			sx={{
				position: 'absolute',
				top: '127px',
				bottom: 12,
				right: 0,

				width: expanded ? '1300px' : '544px',
				transition: 'width 0.2s ease-out',

				backgroundColor: 'neutral000',

				boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
				overflowY: 'hidden',

				// to allow the header shadow to be visible on this sidebar
				zIndex: 2,
			}}
		>
			{step.type === 'wait' ? (
				<Wait
					waitStep={step as ExperimentStepWaitVO}
					stepPath={path}
					onDuplicate={onDuplicate}
					onDelete={onDelete}
					onClose={onClose}
				/>
			) : (
				<Action
					actionStep={step as ExperimentStepActionVO}
					setExpanded={setExpanded}
					expanded={expanded}
					stepPath={path}
					onDuplicate={onDuplicate}
					onDelete={onDelete}
					onClose={onClose}
				/>
			)}
		</Container>
	);
}
