/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

export default `
precision highp float;
precision highp int;

varying float fWorldUnitInScreenSpace;
varying float fTargetSize;
varying float fWithBorder;
varying float fAdviceMode;
varying vec2 vUv;

float circle(in vec2 uv, in float _radius, in bool _hasBorder) {
  float manipulator = 1.2;
  if (_hasBorder) {
    manipulator = max(1.1, 1.2 - 0.1 * (fTargetSize - 1.0));
  }

  float scale = manipulator * (1.0 / _radius);
  float dist = length(vUv - vec2(0.5, 0.5)) * scale;
  float delta = fwidth(dist);
  float alpha = smoothstep(0.5 - delta, 0.5, dist);

  return 1.0 - alpha;
}

vec4 getColor(in float adviceMode, in float alpha) {
  if (adviceMode > 2.0) {
    return vec4(249.0 / 255.0, 77.0 / 255.0, 76.0 / 255.0, alpha);
  }
  if (adviceMode > 1.0) {
    return vec4(247.0 / 255.0, 144.0 / 255.0, 9.0 / 255.0, alpha);
  }
  if (adviceMode > 0.0) {
    return vec4(46.0 / 255.0, 204.0 / 255.0, 113.0 / 255.0, alpha);
  }
  return vec4(103.0 / 255.0, 117.0 / 255.0, 134.0 / 255.0, alpha);
}

void main()	{
  bool hasBorder = fWithBorder == 1.0;
  float alpha = circle(vUv, 1.0, hasBorder);
  vec4 colorCircle = vec4(66.0 / 255.0, 78.0 / 255.0, 92.0 / 255.0, alpha);

  if (hasBorder) {
    float dist = length(vUv - vec2(0.5, 0.5));
    if (dist < 1.0) {
      vec4 colorCircleInner = getColor(fAdviceMode, 1.0);
      float alphaCircle = circle(vUv, 0.8 + min(0.125, 0.1 * (fTargetSize - 1.0)), hasBorder);
      colorCircle = mix(colorCircle, colorCircleInner, alphaCircle);
    }
  } else {
    colorCircle = getColor(fAdviceMode, alpha);
  }
  

  gl_FragColor =  colorCircle;
}
`;
