/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	CreateHubRequest,
	HubConnectionCheckResponse,
	HubVO,
	PageImpl,
	ReliabilityHubExtensionVO,
	UpdateHubRequest,
	ViolationVO,
} from 'ui-api';
import { PageParams } from 'utils/hooks/usePage';
import axios from 'axios';

declare global {
	interface Window {
		reliabilityHubExtensions?: ReliabilityHubExtensionVO[];
		INJECTED_RELIABILITY_HUB_URL?: string;
	}
}

export class HubsApi {
	async getExtensions(): Promise<ReliabilityHubExtensionVO[]> {
		if (window.INJECTED_RELIABILITY_HUB_URL) {
			return (await axios.get<ReliabilityHubExtensionVO[]>('/ui/hubs/extensions')).data || [];
		}

		return Promise.resolve(window.reliabilityHubExtensions || []);
	}

	async getHubs(page: PageParams): Promise<PageImpl<HubVO>> {
		return (await axios.get<PageImpl<HubVO>>('/ui/hubs', { params: page.toUrlSearchParams() })).data;
	}

	async getHub(id: string): Promise<HubVO> {
		return (await axios.get<HubVO>('/ui/hubs/' + id)).data;
	}

	async validate(req: CreateHubRequest): Promise<ViolationVO[]> {
		return (await axios.post<ViolationVO[]>('/ui/hubs/validate', req)).data;
	}

	async testConnection(repositoryUrl: string): Promise<HubConnectionCheckResponse> {
		return (await axios.post<HubConnectionCheckResponse>('/ui/hubs/connection-check', { repositoryUrl })).data;
	}

	async connectHub(req: CreateHubRequest): Promise<HubVO> {
		return (await axios.post<HubVO>('/ui/hubs', req)).data;
	}

	async updateHub(id: string, req: UpdateHubRequest): Promise<HubVO> {
		return (await axios.post<HubVO>('/ui/hubs/' + id, req)).data;
	}

	async disconnectHub(id: string, deleteImportedTemplates: boolean): Promise<void> {
		return (
			await axios.delete<void>('/ui/hubs/' + id, {
				params: {
					deleteImportedTemplates,
				},
			})
		).data;
	}

	async resyncHub(id: string): Promise<HubVO> {
		return (await axios.post<HubVO>('/ui/hubs/' + id + '/resync')).data;
	}

	async getPermissions(): Promise<string[]> {
		return (await axios.get('/ui/hubs/permissions')).data;
	}
}
