/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ReactElement, useEffect, useState } from 'react';
import * as monaco from 'monaco-editor-core';
import { Text } from 'components';

interface PlaceholderProps {
	editor: monaco.editor.IStandaloneCodeEditor | null;
}

export default function Placeholder({ editor }: PlaceholderProps): ReactElement | null {
	const [showPlaceholder, setShowPlaceholder] = useState(false);
	useEffect(() => {
		if (!editor) {
			return;
		}

		setShowPlaceholder(editor.getValue().length === 0);
		const disposableEvent = editor.onDidChangeModelContent(() => {
			setShowPlaceholder(editor.getValue().length === 0);
		});
		return () => {
			disposableEvent.dispose();
		};
	}, [editor]);

	if (!editor || !showPlaceholder) {
		return null;
	}

	return (
		<Text
			variant="small"
			sx={{
				position: 'absolute',
				top: 14,
				left: 40,
				zIndex: 1,
				color: 'neutral500',
				fontStyle: 'italic',
				pointerEvents: 'none',
				...textEllipsis,
			}}
		>
			Enter your Query
		</Text>
	);
}
