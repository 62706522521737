/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { TableDataCell, TableRow, Error } from 'components';
import { ReactElement } from 'react';

interface TableErrorRowProps {
	error: string;
}

export const TableErrorRow = ({ error }: TableErrorRowProps): ReactElement => {
	return (
		<TableRow>
			<TableDataCell colSpan={42}>
				<Error error={error} />
			</TableDataCell>
		</TableRow>
	);
};
