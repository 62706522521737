/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { IconChevronDown, IconChevronUp } from 'components/icons';
import React, { ReactElement, ReactNode, useState } from 'react';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { AnimatePresence, motion } from 'framer-motion';
import { Container, Stack, Text } from 'components';
import { theme } from 'styles.v2/theme';

interface Collapsible_V2_Props {
	children: ReactNode | ReactNode[];
	backgroundColorExpanded?: string;
	borderColorExpanded?: string;
	initialExpanded?: boolean;
	backgroundColor?: string;
	contentPadding?: boolean;
	title: React.ReactNode;
	borderColor?: string;
	borderless?: boolean;
	actions?: ReactNode;
	titleColor?: string;
	onCollapseChange?: (isCollapsed: boolean) => void;
}

export default function Collapsible({
	backgroundColorExpanded = theme.colors.neutral050,
	backgroundColor = theme.colors.neutral100,
	borderColorExpanded = theme.colors.slate,
	borderColor = theme.colors.neutral300,
	contentPadding = true,
	borderless = false,
	initialExpanded,
	actions = null,
	titleColor,
	children,
	title,
	onCollapseChange,
}: Collapsible_V2_Props): ReactElement {
	const [isCollapsed, setIsCollapsed] = useState(!initialExpanded);
	const changeCollapse = (isCollapsed: boolean): void => {
		setIsCollapsed(isCollapsed);
		onCollapseChange?.(isCollapsed);
	};

	return (
		<Container
			sx={{
				border: borderless ? '' : '1px solid ' + (isCollapsed ? borderColor : borderColorExpanded),
				outline: borderless ? '' : '1px solid ' + (isCollapsed ? 'transparent' : borderColorExpanded),
				bg: isCollapsed ? backgroundColor : backgroundColorExpanded,
				borderRadius: 8,
				mx: '1px',

				'&:hover': {
					border: borderless ? 'none' : '1px solid ' + borderColorExpanded,
				},
			}}
		>
			<Container
				onClick={() => changeCollapse(!isCollapsed)}
				sx={{
					p: 'small',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',

					'&:hover': {
						cursor: 'pointer',
					},
				}}
			>
				<Text
					variant="mediumStrong"
					sx={{
						...textEllipsis,
						mr: 'medium',
						color: titleColor || (isCollapsed ? theme.colors.neutral800 : theme.colors.slate),
					}}
				>
					{title}
				</Text>
				<Stack direction="horizontal" alignItems="center">
					{actions}
					{isCollapsed ? <IconChevronDown minWidth={20} /> : <IconChevronUp minWidth={20} />}
				</Stack>
			</Container>
			<AnimatePresence initial={false}>
				<motion.div {...(isCollapsed ? animateOut : animateIn)}>
					{!isCollapsed && (
						<Container px={contentPadding ? 'medium' : '0px'} pb="medium">
							{children}
						</Container>
					)}
				</motion.div>
			</AnimatePresence>
		</Container>
	);
}

const animateIn = {
	initial: { height: 0, overflow: 'hidden' },
	animate: {
		height: 'auto',
		transitionEnd: {
			overflow: 'visible',
		},
	},
};

const animateOut = {
	initial: { height: 'auto', overflow: 'visible' },
	animate: { height: 0, overflow: 'hidden' },
};
