/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { lazy, ReactElement, Suspense } from 'react';

const GameContainerLoaded = lazy(() => import('./GameContainer'));

export default function GameLazyLoader(): ReactElement | null {
	return (
		<Suspense fallback={<></>}>
			<GameContainerLoaded gameOnly={true} isTenantAvailable={true} />
		</Suspense>
	);
}
