/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Container, RouterLink, StateBadge, Text, Tooltip } from 'components';
import { LastExperimentExecutionVO } from 'ui-api';
import { formatDateWithTime } from 'utils/dateFns';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import { getColorForState } from './components/utils';

interface ExperimentRunTrendProps {
	lastRuns: LastExperimentExecutionVO[] | undefined;
	experimentKey: string;
}

export default function ExperimentRunTrend({ lastRuns = [], experimentKey }: ExperimentRunTrendProps): ReactElement {
	const missingExperimentExecutions: string[] = [];
	for (let i = 0; i < 10 - lastRuns.length; i++) {
		missingExperimentExecutions.push('');
	}

	return (
		<Container
			sx={{
				display: 'flex',
				flexDirection: 'row-reverse',
				alignItems: 'flex-end',
				gap: '2px',
				width: '140px',
			}}
		>
			{lastRuns.map((lastRun) => {
				return (
					<Tooltip
						color="light"
						key={lastRun.experimentExecutionId}
						content={
							<>
								<Container display={'flex'} flexDirection={'column'}>
									#{lastRun.experimentExecutionId}
									<Container maxWidth={150}>
										<StateBadge as="state" value={lastRun.state} />
									</Container>
									<Text variant={'small'} sx={{ color: 'neutral600', fontVariantNumeric: 'tabular-nums' }}>
										Started: {`${formatDateWithTime(lastRun.created)} `}
									</Text>
									{lastRun.ended && (
										<Text variant={'small'} sx={{ color: 'neutral600', fontVariantNumeric: 'tabular-nums' }}>
											Ended: {`${formatDateWithTime(new Date(lastRun.ended))} `}
										</Text>
									)}
								</Container>
							</>
						}
					>
						<RouterLink to={`/experiments/edit/${experimentKey}/executions/${lastRun.experimentExecutionId}`}>
							<Container
								sx={{
									width: '8px',
									height: '16px',
									bg:
										lastRun.state === 'CANCELED'
											? 'neutral300'
											: getColorForState(lastRun.state, theme.colors.feedbackSuccess),
									borderRadius: '1px',

									'&:hover': {
										cursor: 'pointer',
										border: '1px solid ' + theme.colors.neutral000,
										outline: '1px solid ' + theme.colors.slate,
									},
								}}
							/>
						</RouterLink>
					</Tooltip>
				);
			})}
			{missingExperimentExecutions.map((_, index) => (
				<Container
					key={index}
					sx={{
						width: '8px',
						height: '16px',
						borderRadius: '1px',
						border: '1px dashed ' + theme.colors.neutral300,
					}}
				/>
			))}
		</Container>
	);
}
