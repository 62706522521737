/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { IconWarning } from 'components/icons';
import { Container, Text } from 'components';
import { ReactElement } from 'react';

interface FailedBadgeProps {
	numberFailed: number;
}

export default function FailedBadge({ numberFailed }: FailedBadgeProps): ReactElement | null {
	if (numberFailed === 0) {
		return null;
	}

	return (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',
				borderRadius: 4,
				backgroundColor: 'experimentWarning',
				padding: '4px 8px',
			}}
		>
			<IconWarning variant="small" color="white" mr="xSmall" />
			<Text variant="smallStrong" color="white">
				Failed ({numberFailed})
			</Text>
		</Container>
	);
}
