/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { AsyncState, useAsyncState } from 'utils/hooks/useAsyncState';
import { ReactElement, ReactNode, useEffect } from 'react';
import { ExperimentExecutionVO } from 'ui-api';
import { Services } from 'services/services';

interface ExperimentExecutionLoaderProps {
	experimentExecutionId: string;
	children: ({
		experimentExecution,
	}: {
		experimentExecution: AsyncState<ExperimentExecutionVO | undefined>;
	}) => ReactNode;
}

export default function ExperimentExecutionLoader({
	experimentExecutionId,
	children,
}: ExperimentExecutionLoaderProps): ReactElement {
	const experimentExecution = useExperimentRun(experimentExecutionId);
	return <>{children({ experimentExecution: experimentExecution })}</>;
}

function useExperimentRun(experimentExecutionId: string): AsyncState<ExperimentExecutionVO | undefined> {
	const [experimentExecution, fetch] = useAsyncState(
		async () => Services.experiments.fetchExperimentRun(Number(experimentExecutionId)),
		[experimentExecutionId],
	);

	useEffect(() => {
		if (
			experimentExecution.loading ||
			!experimentExecutionId ||
			!runningOrEndedInLast10seconds(experimentExecution.value)
		) {
			return;
		}
		const timeout = setTimeout(() => {
			fetch();
		}, 1000);
		return () => clearTimeout(timeout);
	}, [experimentExecution.value]);

	return experimentExecution;
}

function runningOrEndedInLast10seconds(execution: ExperimentExecutionVO | undefined): boolean {
	if (!execution) {
		return true;
	}
	if (!execution.ended) {
		return true;
	}
	if (execution.ended.getTime() + 10_000 > Date.now()) {
		return true;
	}
	return false;
}
