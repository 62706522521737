/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useField } from 'formik';

export default function useIsExperimentDisabled(): boolean {
	const [{ value: actions }] = useField<string[]>({ name: 'actions' });
	return !actions?.includes('save');
}
