/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ButtonIcon,
	Combobox,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	TableSortLink,
	UserIcon,
} from 'components';
import TableLoadingRow from 'components/Table/TableLoadingRow';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { Order, usePage } from 'utils/hooks/usePage';
import { IconDelete } from 'components/icons';
import { Services } from 'services/services';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { UserVO } from 'ui-api';

import { TeamRoleSelect } from './teamRoleSelect';
import { TeamFormValues } from './teamTypes';

const SORT_NAME_ASC: Order[] = [['name', 'asc', 'ignoreCase']];
const SORT_NAME_DESC: Order[] = [['name', 'desc', 'ignoreCase']];
const SORT_EMAIL_ASC: Order[] = [['email', 'asc']];
const SORT_EMAIL_DESC: Order[] = [['email', 'desc']];

export const TeamMembers: React.VFC<{ teamId: string; disabled?: boolean }> = ({ teamId, disabled = false }) => {
	type UserOptionType = { value: string; label: string };
	const formik = useFormikContext<TeamFormValues>();

	const page = usePage(`/${teamId}`, { size: 1000, sort: SORT_NAME_ASC });
	const [users] = useAsyncState(() => Services.users.fetchUsers(page.pageParams), [page]);

	const isLoading = users.loading;

	function handleAddMember(value: string): void {
		if (!formik.values.members.find((m) => m.username === value)) {
			formik.setFieldValue('members', [...formik.values.members, { username: value, role: 'MEMBER' }]);
		}
	}

	const handleRoleChange = (user: UserVO, role: string): void => {
		const indexOfUser = formik.values.members.findIndex((m) => m.username === user.username);
		if (indexOfUser >= 0) {
			formik.setFieldValue(`members[${indexOfUser}].role`, role);
		}
	};

	const handleRemoveMember = (user: UserVO) => () => {
		const newMembers = formik.values.members.filter((m) => m.username !== user.username);
		formik.setFieldValue('members', newMembers);
	};

	const [value] = useState<UserOptionType | null | undefined>(null);

	const resolvedMembers: UserVO[] = [];
	const resolvedUsers: UserVO[] = [];
	if (users.value) {
		for (const user of users.value.content) {
			if (formik.values.members.find((m) => m.username === user.username)) {
				resolvedMembers.push(user);
			} else {
				resolvedUsers.push(user);
			}
		}
	}

	return (
		<Stack>
			{!disabled && (
				<Combobox<UserOptionType>
					data-private
					options={resolvedUsers.map((u) => ({
						value: u.username,
						label: u.name + (u.email ? ` (${u.email})` : ''),
					}))}
					value={value}
					onChange={(option) => {
						if (option) {
							handleAddMember(option.value);
						}
					}}
					placeholder="Add existing members."
					name={'membername'}
				/>
			)}
			<Table width={'100%'} data-cy="team-table">
				<TableHead>
					<TableRow>
						<TableHeadCell>
							<TableSortLink
								sort={page.getDirection(SORT_NAME_ASC, SORT_NAME_DESC)}
								to={page.toggleSort(SORT_NAME_ASC, SORT_NAME_DESC).toString()}
							>
								Name
							</TableSortLink>
						</TableHeadCell>
						<TableHeadCell>
							<TableSortLink
								sort={page.getDirection(SORT_EMAIL_ASC, SORT_EMAIL_DESC)}
								to={page.toggleSort(SORT_EMAIL_ASC, SORT_EMAIL_DESC).toString()}
							>
								E-Mail
							</TableSortLink>
						</TableHeadCell>
						<TableHeadCell>Role</TableHeadCell>
						<TableHeadCell />
					</TableRow>
				</TableHead>
				<TableBody>
					{isLoading ? (
						<>
							<TableLoadingRow numColumns={3} />
							<TableLoadingRow numColumns={3} />
							<TableLoadingRow numColumns={3} />
						</>
					) : (
						resolvedMembers.map((user) => (
							<MemberRow
								key={user.username}
								user={user}
								role={formik.values.members.find((m) => m.username === user.username)?.role ?? undefined}
								disabled={disabled}
								onRoleChange={(role) => handleRoleChange(user, role)}
								onRemove={handleRemoveMember(user)}
							/>
						))
					)}
				</TableBody>
			</Table>
		</Stack>
	);
};

interface MemberRowProps {
	user: UserVO;
	role?: string;
	disabled?: boolean;
	onRoleChange: (r: string) => void;
	onRemove: () => void;
}

const MemberRow: React.VFC<MemberRowProps> = ({ user, role, disabled, onRoleChange, onRemove }) => {
	return (
		<TableRow hoverable={true}>
			<TableDataCell data-private>
				<UserIcon
					variant="medium"
					src={user.pictureUrl}
					mr={5}
					sx={{
						minWidth: 24,
						minHeight: 24,
						maxWidth: 24,
						maxHeight: 24,
					}}
				/>
				{user.name}
			</TableDataCell>
			<TableDataCell data-private>{user?.email ?? ''}</TableDataCell>
			<TableDataCell>{!disabled ? <TeamRoleSelect value={role} onChange={onRoleChange} /> : role}</TableDataCell>
			<TableDataCell justifyContent={'flex-end'}>
				{!disabled ? (
					<ButtonIcon muted onClick={onRemove}>
						<IconDelete color="neutral600" />
					</ButtonIcon>
				) : null}
			</TableDataCell>
		</TableRow>
	);
};
