/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	Container,
	FormikCombobox,
	ModalV2,
	ModalContentV2,
	ModalFooterV2,
	ModalHeaderV2,
	Snackbar,
	Spinner,
	Stack,
	Text,
} from 'components';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { ChangeAgentLogLevelRequest } from 'ui-api';
import { Services } from 'services/services';
import React, { useState } from 'react';
import { Form, Formik } from 'formik';

const initialValues: ChangeAgentLogLevelRequest = {
	loggerName: 'com.steadybit',
	level: 'INFO',
};

const ChangeAgentLogLevel: React.VFC<{ agentId?: string; close: () => void }> = ({ agentId, close }) => {
	const [agent, fetch] = useAsyncState(
		async () => (agentId ? Services.agents.fetchAgent(agentId) : undefined),
		[agentId],
	);
	const [submitError, setSubmitError] = React.useState<Error | null>();

	const [options, setOptions] = useState([
		{ label: 'com.steadybit', value: 'com.steadybit' },
		{ label: 'com.steadybit.communication', value: 'com.steadybit.communication' },
		{ label: 'com.steadybit.actions', value: 'com.steadybit.actions' },
		{ label: 'com.steadybit.attacks', value: 'com.steadybit.attacks' },
		{ label: 'com.steadybit.discovery', value: 'com.steadybit.discovery' },
	]);

	useEventEffect(
		React.useCallback(
			(event) => {
				if (agentId === event.agentId) {
					fetch();
				}
			},
			[fetch, agentId],
		),
	);

	const handleSubmit = async (values: ChangeAgentLogLevelRequest): Promise<void> => {
		try {
			if (agentId) {
				await Services.agents.setLogLevel(agentId, values);
			} else {
				await Services.agents.setLogLevelAll(values);
			}
			Snackbar.dark('Log Level Change requested.', { toastId: 'level-saved' });
			close();
		} catch (error) {
			setSubmitError(error);
		}
	};

	return (
		<Formik initialValues={initialValues} onSubmit={handleSubmit}>
			{({ setFieldValue, submitForm, isSubmitting }) => (
				<Form noValidate>
					<ModalV2 withFooter slick>
						<ModalHeaderV2 title={`Change Agent Log Level ${agentId ? agent.value?.hostname : ''}`} onClose={close} />
						<ModalContentV2>
							{agentId && agent.loading ? (
								<Stack>
									<Spinner variant={'xxLarge'} color={'neutral200'} />
								</Stack>
							) : (
								<Stack size={'large'}>
									{agent.value?.logLevel ? (
										<Container>
											<Text variant={'mediumStrong'}>Current Settings</Text>
											{agent.value?.logLevel?.split('\n').map((value, index) => (
												<React.Fragment key={index}>
													{value}
													<br />
												</React.Fragment>
											))}
										</Container>
									) : null}

									<Text muted>
										Log-Level-Changes are requested from the agent and processed asynchronous. Every Change will be
										automatically reverted after 10 minutes.
									</Text>

									<Container
										sx={{
											display: 'grid',
											gridTemplateColumns: '2fr 1fr',
											gap: '12px',
											marginBottom: 'large',
										}}
									>
										<FormikCombobox
											label={'Logger'}
											name={'loggerName'}
											creatable={(value) => `Add custom logger "${value}"`}
											onCreate={(value) => {
												setOptions([...options, { label: value, value: value }]);
												setFieldValue('loggerName', value);
											}}
											options={options}
										/>
										<FormikCombobox
											label={'Level'}
											name={'level'}
											options={[
												{ label: 'INFO', value: 'INFO' },
												{ label: 'DEBUG', value: 'DEBUG' },
												{ label: 'TRACE', value: 'TRACE' },
											]}
										/>
									</Container>
								</Stack>
							)}
							{agent.error && <div>Error loading Agent: {agent.error.message}</div>}
						</ModalContentV2>
						<ModalFooterV2>
							<>
								{submitError ? <Container flex={'1 0 auto'}> {submitError.toString()}</Container> : null}
								<Button
									mr={'small'}
									variant={'secondary'}
									onClick={() => {
										setFieldValue('loggerName', 'com.steadybit');
										setFieldValue('level', '');
										submitForm();
									}}
								>
									Request Log-Level Reset
								</Button>
								<Button id={'save'} type={'submit'} loading={agent.loading || isSubmitting}>
									Request Log-Level Change
								</Button>
							</>
						</ModalFooterV2>
					</ModalV2>
				</Form>
			)}
		</Formik>
	);
};

export default ChangeAgentLogLevel;
