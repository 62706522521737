/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { BaseExperimentStepVOUnion } from 'ui-api';
import { ReactElement } from 'react';

import Step from './Step';

interface StepsProps {
	stepIdToError: Map<string, string[]>;
	steps: BaseExperimentStepVOUnion[];
	path: string;
}

export default function Steps({ steps, path, stepIdToError }: StepsProps): ReactElement {
	return (
		<>
			{steps.map((step, i) => {
				return <Step key={step.id} stepIdToError={stepIdToError} path={`${path}.steps[${i}]`} step={step} index={i} />;
			})}
		</>
	);
}
