/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ReactElement, ReactNode } from 'react';
import { StyleProp, Text } from 'components';

interface HelpTextProps {
	children: ReactNode;
	sx?: StyleProp;
}

export default function HelpText({ children, sx }: HelpTextProps): ReactElement {
	return (
		<Text muted maxWidth={747} color="neutral600" sx={sx}>
			{children}
		</Text>
	);
}
