/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import { IconAdd, IconInformation, IconZoomMinus } from 'components/icons';
import DropdownButton from 'components/Select/Dropdown/DropdownButton';
import { convertDurationToSeconds, toDuration } from 'utils/duration';
import Labels from 'components/Select/Dropdown/presets/Labels';
import { ReactElement, useEffect, useState } from 'react';
import { Button, Stack, Text, Tooltip } from 'components';
import { roundToTwoDecimals } from 'utils/numberFn';
import { ExperimentLaneVO } from 'ui-api';
import { seconds } from 'utils/time';
import { useField } from 'formik';

import { useEditorSettings } from '../useEditorSettings';

interface ZoomControlsProps {
	pxPerSecond: number;
	setPxPerSecond: (pxPerSecond: number) => void;
}

export default function ZoomControls({ pxPerSecond, setPxPerSecond }: ZoomControlsProps): ReactElement {
	const { mode } = useEditorSettings();
	const [lanesField] = useField<ExperimentLaneVO[]>({ name: 'lanes' });
	const { value: lanes } = lanesField;
	const [maxWidthIsRestricted, setMaxWidthIsRestricted] = useState<boolean>(false);

	// The following effect is a safeguard to prevent the a too big canvas causing the browser performance to degrade.
	const maxLaneDuration = getMaxDuration(lanes);
	useEffect(() => {
		let newMaxWidthIsRestricted = false;
		const resultingWidth = maxLaneDuration * pxPerSecond;
		if (resultingWidth >= 100_000) {
			newMaxWidthIsRestricted = true;
		}
		if (resultingWidth > 100_000) {
			const neededZoom = 100_000 / maxLaneDuration;
			if (neededZoom !== pxPerSecond) {
				setPxPerSecond(neededZoom);
			}
		} else if (!maxWidthIsRestricted && !newMaxWidthIsRestricted && pxPerSecond < 0.5) {
			setPxPerSecond(0.5);
		}

		if (newMaxWidthIsRestricted !== maxWidthIsRestricted) {
			setMaxWidthIsRestricted(newMaxWidthIsRestricted);
		}
	}, [pxPerSecond, maxLaneDuration, maxWidthIsRestricted]);

	return (
		<Stack
			direction="horizontal"
			size="none"
			sx={{
				position: 'fixed',
				left: mode === 'experiment' ? '374px' : '454px',
				bottom: '18px',
			}}
		>
			<Button
				variant="secondarySmall"
				onClick={() => setPxPerSecond(Math.max(0.5, Math.floor(pxPerSecond - 1)))}
				disabled={pxPerSecond <= 0.5}
				sx={{
					borderRadius: '4px 0 0 4px',
					padding: '16px',
				}}
			>
				<IconZoomMinus mx="-xSmall" />
			</Button>

			<DropdownButton
				variant="chromeless"
				value={Math.round(10 * pxPerSecond) + '%'}
				sx={{
					minWidth: '75px',
					height: '34px',
					borderRadius: 0,
					borderRight: 'none',
					borderLeft: 'none',
					borderTop: '1px solid',
					borderBottom: '1px solid',
					borderColor: 'neutral300',
					padding: '0 6px',
					backgroundColor: 'neutral000',
				}}
				icon={
					maxWidthIsRestricted ? (
						<Tooltip
							content={
								<Text variant="smallStrong" maxWidth={400}>
									The experiment duration is too long ({toDuration(seconds(maxLaneDuration).getMillis())}) , so the
									maximum zoom level is limited to {Math.round(10 * pxPerSecond)}%.
								</Text>
							}
						>
							<IconInformation variant="small" color="neutral800" />
						</Tooltip>
					) : undefined
				}
			>
				{({ selectItem }) => (
					<DropDownContent
						width="100px"
						selectItem={(id) => {
							selectItem(id);

							if (id === 'zoom-to-fit') {
								if (lanes.length === 0) {
									return;
								}
								let workspaceWidth = document.getElementById('experiment.explorer.wrapper')?.getBoundingClientRect()
									.width;
								if (workspaceWidth === undefined) {
									return;
								}

								workspaceWidth -= 24; // lanes start with this indicator we have to take it into account

								const maxDuration = getMaxDuration(lanes);
								const zoomToFit = workspaceWidth / (maxDuration * pxPerSecond);

								const newPxPerSecond = roundToTwoDecimals(pxPerSecond * zoomToFit);
								setPxPerSecond(Math.max(0.5, Math.min(20, newPxPerSecond)));
							} else {
								setPxPerSecond(Number(id));
							}
						}}
					/>
				)}
			</DropdownButton>
			<Button
				variant="secondarySmall"
				onClick={() => setPxPerSecond(Math.min(20, Math.floor(Math.round(pxPerSecond) + 1)))}
				disabled={pxPerSecond >= 20}
				sx={{
					borderRadius: '0 4px 4px 0',
					padding: '16px',
				}}
			>
				<IconAdd mx="-xSmall" />
			</Button>
		</Stack>
	);
}
interface DropDownContentProps {
	selectItem: (value: string) => void;
	width: string | number | undefined;
}

function DropDownContent({ selectItem, width }: DropDownContentProps): ReactElement {
	return (
		<DropdownContentFrame>
			<Labels<string>
				type="strict"
				onSelect={({ id }) => selectItem(id)}
				labels={[
					{
						id: '20',
						label: '200%',
					},
					{
						id: '15',
						label: '150%',
					},
					{
						id: '12.5',
						label: '125%',
					},
					{
						id: '10',
						label: '100%',
					},
					{
						id: '7.5',
						label: '75%',
					},
					{
						id: '5',
						label: '50%',
					},
					{
						id: '1',
						label: '10%',
					},
					{
						id: '0.5',
						label: '5%',
					},
					{
						id: 'zoom-to-fit',
						label: 'Zoom to fit',
					},
				]}
				width={width}
				withoutTooltip
			/>
		</DropdownContentFrame>
	);
}

export function getMaxDuration(lane: ExperimentLaneVO[] | ExperimentLaneVO): number {
	if (lane instanceof Array) {
		return lane.reduce((max, lane) => Math.max(max, getMaxDuration(lane)), 0);
	}
	return lane.steps.reduce((prev, step) => {
		return prev + (convertDurationToSeconds(step.parameters.duration) || 10);
	}, 0);
}
