/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ReactElement, useState } from 'react';

import { PREVIEW_DIMENSIONS, useShareClipboard, ViewFrameButton } from './View';
import { Container, Stack, Text, Tooltip } from '../../components';
import textEllipsis from '../../utils/styleSnippets/textEllipsis';
import { IconComponent, IconShare } from '../../components/icons';
import { useHistory } from '../../url/hooks';
import ViewFrame from './ViewFrame';

interface TemplateViewFrameProps {
	templateId: string;
	title: string;
	description: string;
	icon: IconComponent;
}

export default function TemplateViewFrame({
	templateId,
	title,
	description,
	icon,
}: TemplateViewFrameProps): ReactElement {
	const Icon = icon;
	const { createHref } = useHistory();
	const [disableParent, setDisableParent] = useState(false);
	const [, setLinkToCopy] = useShareClipboard();
	return (
		<ViewFrame disabled={disableParent} href={`/landscape/explore/${encodeURIComponent(templateId)}`}>
			<Container
				display="flex"
				alignItems="center"
				justifyContent="center"
				width={PREVIEW_DIMENSIONS.width}
				minWidth={PREVIEW_DIMENSIONS.width}
				height={PREVIEW_DIMENSIONS.height}
				minHeight={PREVIEW_DIMENSIONS.height}
				bg="neutral700"
			>
				<Icon variant="xxxLarge" color="neutral100" />
			</Container>

			<Stack size="xSmall" alignItems="flex-start" justifyContent="space-between" height="100%">
				<Stack size="xxSmall" width="100%">
					<Tooltip content={title}>
						<Text variant="mediumStrong" color="neutral800" sx={{ ...textEllipsis }}>
							{title}
						</Text>
					</Tooltip>
					<Tooltip content={description}>
						<Text
							variant="small"
							color="neutral600"
							sx={{
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								wordBreak: 'normal',
								display: '-webkit-box !important',
								WebkitLineClamp: '3',
								WebkitBoxOrient: 'vertical',
								whiteSpace: 'break-spaces',
							}}
						>
							{description}
						</Text>
					</Tooltip>
				</Stack>

				<Stack direction="horizontal" justifyContent="flex-end" width="100%">
					<ViewFrameButton
						onClick={() =>
							setLinkToCopy(
								location.origin +
									createHref((location) => {
										location.pathname = `/landscape/explore/${encodeURIComponent(templateId)}`;
									}),
							)
						}
						setDisableParent={setDisableParent}
					>
						<IconShare mr="xxSmall" />
						<Text variant="mediumStrong">Share</Text>
					</ViewFrameButton>
				</Stack>
			</Stack>
		</ViewFrame>
	);
}
