/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { useField } from 'formik';
import React from 'react';

import { Text, TextProps } from '../Text';
import { hasError } from './utils';

type FormikErrorOwnProps = {
	name: string;
};

export type FormikErrorProps = FormikErrorOwnProps & Omit<TextProps, keyof FormikErrorOwnProps>;

export const FormikError: React.VFC<FormikErrorProps> = ({ name, ...props }) => {
	const [, meta] = useField(name);

	return hasError(meta) ? (
		<Text mt={'xxSmall'} variant={'smallStrong'} color={'coral'} {...props}>
			{meta.error}
		</Text>
	) : null;
};

FormikError.displayName = 'FormikError';
