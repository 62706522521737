/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { DataStreamResult } from 'utils/hooks/stream/result';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { TargetPredicateVO } from 'ui-api';

interface UseTargetCountsOptions {
	environmentId?: string;
	predicate?: TargetPredicateVO;
}

export function useTargetCounts({ environmentId, predicate }: UseTargetCountsOptions = {}): DataStreamResult<
	Record<string, number>
> {
	return usePromise(async () => Services.targets.countTargets(environmentId, predicate), [environmentId, predicate]);
}
