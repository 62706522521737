/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Container } from 'components';
import React from 'react';

export const Separator: React.VFC = ({ ...props }) => {
	return (
		<Container
			sx={{
				borderBottom: '1px solid',
				borderColor: 'neutral200',
			}}
			mt={'medium'}
			mb={'small'}
			{...props}
		/>
	);
};
