/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

export function addQuadVertices(vertices: number[], x: number, y: number, z: number, radius: number): void {
	vertices.push(
		x - radius,
		y - radius,
		z,
		x + radius,
		y - radius,
		z,
		x - radius,
		y + radius,
		z,
		x + radius,
		y - radius,
		z,
		x + radius,
		y + radius,
		z,
		x - radius,
		y + radius,
		z,
	);
}

export function addQuadColors(colors: number[], rgb: [number, number, number]): void {
	let [r, g, b] = rgb;
	r /= 255;
	g /= 255;
	b /= 255;
	colors.push(r, g, b, r, g, b, r, g, b, r, g, b, r, g, b, r, g, b);
}

export function addQuadUVs(uvs: number[]): void {
	uvs.push(0, 0, 1, 0, 0, 1, 1, 0, 1, 1, 0, 1);
}

export function addOpacities(opacities: number[], opacity: number): void {
	opacities.push(opacity, opacity, opacity, opacity, opacity, opacity);
}
