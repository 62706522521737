/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import {
	BasicStyleProperties,
	InteractionTypes,
	resolveStyleProp,
	resolveSx,
	resolveTxAndVariant,
} from 'components/types';
import { ContainerProps } from 'components/Container';
import { StyleProp } from 'components/StyleProp';
import React, { forwardRef } from 'react';
import { Box } from 'theme-ui';

export interface SvgProps extends ContainerProps {
	viewBox?: string;
	xmlns?: string;
	x?: number;
	y?: number;
}

export interface SvgObjectProps extends BasicStyleProperties, InteractionTypes<HTMLElement> {
	x?: number;
	x1?: number;
	x2?: number;
	y?: number;
	y1?: number;
	y2?: number;
	cx?: number;
	cy?: number;
	d?: string;
	r?: number;
	stroke?: string;
	strokeLinecap?: string;
	strokeLinejoin?: string;
	strokeWidth?: number | string;
	fill?: string;
	filter?: string;

	flexDirection?: 'row' | 'column';
	justifyContent?: string;
	transform?: string;
	alignItems?: string;
	children?: React.ReactNode;
	className?: string;
}

export const Svg = forwardRef<SVGElement, SvgProps>(({ children, sx = {}, ...props }, ref) => (
	<Box
		as="svg"
		{...props}
		sx={{
			...extractStyle(props),
			...sx,
		}}
		variant={resolveTxAndVariant(props.tx, props.variant)}
		ref={ref}
	>
		{children}
	</Box>
));
Svg.displayName = 'Svg';
export const SvgGroup = forwardRef<SVGElement, SvgObjectProps>((props, ref) => (
	<Box as="g" {...props} ref={ref} sx={extractStyleForSvgObject(props)} />
));
SvgGroup.displayName = 'SvgGroup';
export const SvgCircle = forwardRef<SVGElement, SvgObjectProps>((props, ref) => (
	<Box as="circle" {...props} ref={ref} sx={extractStyleForSvgObject(props)} />
));
SvgCircle.displayName = 'SvgCirvle';
export const SvgPath = forwardRef<SVGElement, SvgObjectProps>((props, ref) => (
	<Box as="path" {...props} ref={ref} sx={extractStyleForSvgObject(props)} />
));
SvgPath.displayName = 'SvgPath';
export const SvgLine = forwardRef<SVGElement, SvgObjectProps>((props, ref) => (
	<Box as="line" {...props} ref={ref} sx={extractStyleForSvgObject(props)} />
));
SvgLine.displayName = 'SvgLine';
export const SvgRect = forwardRef<SVGElement, SvgObjectProps>((props, ref) => (
	<Box as="rect" {...props} ref={ref} sx={extractStyleForSvgObject(props)} />
));
SvgRect.displayName = 'SvgRect';

function extractStyleForSvgObject(props: SvgObjectProps): StyleProp {
	const {
		flexDirection,
		justifyContent,
		alignItems,
		backgroundColor,
		bg,
		color,
		height,
		minHeight,
		maxHeight,
		width,
		minWidth,
		maxWidth,
		m,
		mb,
		ml,
		mr,
		mt,
		mx,
		my,
		p,
		pb,
		pl,
		pr,
		pt,
		px,
		py,
		overflow,
		overflowX,
		overflowY,
		opacity,
		tabIndex,
		sx = {},
	} = props;
	return {
		flexDirection,
		justifyContent,
		alignItems,
		backgroundColor,
		bg,
		color,
		height: resolveStyleProp(height),
		minHeight: resolveStyleProp(minHeight),
		maxHeight: resolveStyleProp(maxHeight),
		width: resolveStyleProp(width),
		minWidth: resolveStyleProp(minWidth),
		maxWidth: resolveStyleProp(maxWidth),
		m: resolveStyleProp(m),
		mb: resolveStyleProp(mb),
		ml: resolveStyleProp(ml),
		mr: resolveStyleProp(mr),
		mt: resolveStyleProp(mt),
		mx: resolveStyleProp(mx),
		my: resolveStyleProp(my),
		p: resolveStyleProp(p),
		pb: resolveStyleProp(pb),
		pl: resolveStyleProp(pl),
		pr: resolveStyleProp(pr),
		pt: resolveStyleProp(pt),
		px: resolveStyleProp(px),
		py: resolveStyleProp(py),
		opacity: resolveStyleProp(opacity),
		overflow,
		overflowX,
		overflowY,
		tabIndex,
		...resolveSx(sx),
	};
}

function extractStyle(props: SvgProps): StyleProp {
	const {
		flexDirection,
		justifyContent,
		alignItems,
		backgroundColor,
		bg,
		color,
		height,
		minHeight,
		maxHeight,
		width,
		minWidth,
		maxWidth,
		m,
		mb,
		ml,
		mr,
		mt,
		mx,
		my,
		p,
		pb,
		pl,
		pr,
		pt,
		px,
		py,
		overflow,
		overflowX,
		overflowY,
		opacity,
		tabIndex,
		sx = {},
	} = props;
	return {
		flexDirection,
		justifyContent,
		alignItems,
		backgroundColor,
		bg,
		color,
		height: resolveStyleProp(height),
		minHeight: resolveStyleProp(minHeight),
		maxHeight: resolveStyleProp(maxHeight),
		width: resolveStyleProp(width),
		minWidth: resolveStyleProp(minWidth),
		maxWidth: resolveStyleProp(maxWidth),
		m: resolveStyleProp(m),
		mb: resolveStyleProp(mb),
		ml: resolveStyleProp(ml),
		mr: resolveStyleProp(mr),
		mt: resolveStyleProp(mt),
		mx: resolveStyleProp(mx),
		my: resolveStyleProp(my),
		p: resolveStyleProp(p),
		pb: resolveStyleProp(pb),
		pl: resolveStyleProp(pl),
		pr: resolveStyleProp(pr),
		pt: resolveStyleProp(pt),
		px: resolveStyleProp(px),
		py: resolveStyleProp(py),
		opacity: resolveStyleProp(opacity),
		overflow,
		overflowX,
		overflowY,
		tabIndex,
		...resolveSx(sx),
	};
}
