/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { useStableInstance } from 'utils/hooks/useStableInstance';
import { AdviceListItemVO, TargetPredicateVO } from 'ui-api';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { useState } from 'react';

export default function useAdviceList(predicate: TargetPredicateVO): DataStreamResult<AdviceListItemVO[]> {
	const [reloadCounter, setReloadCounter] = useState(0);

	const hash = useStableInstance(predicate);
	useEventEffect(
		() => {
			setReloadCounter(reloadCounter + 1);
		},
		['advice-updated'],
		() => {},
		[reloadCounter],
	);

	return usePromise(() => Services.adviceApi.getAdviceListItems(predicate), [hash, reloadCounter]);
}
