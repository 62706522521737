/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { LoadingIndicator, Stack, Text } from 'components';
import { ReactNode, useEffect, useState } from 'react';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';

interface HubSynchronizationProps {
	hubId: string;
	children: (p: [syncState: SyncState, refresh: () => void]) => ReactNode;
}

interface SyncState {
	completed: boolean;
	error?: string;
}

export default function HubSynchronization({ hubId, children }: HubSynchronizationProps): ReactNode {
	const [loadCounter, setLoadCounter] = useState(0);
	const hubResult = usePromise(
		() => (loadCounter === 0 ? Services.hubsApi.resyncHub(hubId) : Services.hubsApi.getHub(hubId)),
		[loadCounter],
	);
	const lastSync = hubResult.value?.lastSync;

	useEffect(() => {
		if (lastSync) {
			return;
		}
		const timeout: number = window.setTimeout(() => setLoadCounter(loadCounter + 1), 2_000);
		return () => {
			window.clearTimeout(timeout);
		};
	}, [loadCounter, lastSync]);

	const syncState: SyncState = {
		completed: !!lastSync,
		error: hubResult.value ? hubResult.value?.syncError : hubResult.error?.message,
	};
	return children([syncState, loadCounter === 0 ? () => {} : () => setLoadCounter(0)]);
}

export function WaitingContent(): ReactNode {
	return (
		<Stack size="large" alignItems="center" py="xLarge">
			<Stack size="xSmall" alignItems="center" maxWidth="300px">
				<Text variant="mediumStrong" color="neutral700">
					Just a moment...
				</Text>
				<Text variant="small" color="neutral700" textAlign="center">
					Fetching templates from the selected Hub.
					<br />
					It can take a while, be patient.
				</Text>
			</Stack>
			<LoadingIndicator color="neutral500" />
		</Stack>
	);
}
