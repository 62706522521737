/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps, RouterButton, Stack, Text, Tooltip } from 'components';
import { IncidentBadgeWidget } from 'pages/dashboard/components/IncidentBadgeWidget';
import useGlobalPermissions from 'services/useGlobalPermissions';
import React, { ReactElement, ReactNode } from 'react';
import { IconExperiment } from 'components/icons';
import { useUser } from 'services/usersApi';

import ExtensionsWidget from './ExtensionsWidget';
import { ampli } from '../../../ampli';
import ImageUfo from './svg/ufo.svg';
import CreateBox from './CreateBox';

export const GetStartedWidget: React.VFC<ContainerProps> = (props) => {
	const user = useUser();
	const permissions = useGlobalPermissions();

	return (
		<Container {...props} sx={{ bg: 'neutral200', borderRadius: 2, position: 'relative' }}>
			<Container sx={{ position: 'absolute', bottom: -6, right: 0 }}>
				<ImageUfo />
			</Container>
			<Stack size={0} direction={'vertical'} ml={49} mr={18} mt={'xLarge'}>
				<Stack size={'medium'} direction={'horizontal'} justifyContent={'flex-start'}>
					<Text variant={'small'} color="neutral700" fontSize={'13px'} fontWeight={500} lineHeight={'150%'}>
						Get Started With Steadybit
					</Text>
				</Stack>

				<Stack size={'medium'} direction={'horizontal'} justifyContent={'flex-start'}>
					<Text variant={'smallStrong'} color="neutral700" fontSize={'32px'} lineHeight={'130%'}>
						Start Experimenting Right Away <br />
						and Become More Resilient.
					</Text>
				</Stack>

				<Stack size={'medium'} direction={'horizontal'}>
					<CreateBox
						icon={IconExperiment}
						header={
							<span>
								Hey <span data-private>{user.name}</span>,
							</span>
						}
						text={`Steadybit already discovered your system. Let's create our first Chaos Engineering experiment to verify how your system reacts!${''}`}
						height={220}
					>
						<Tooltip
							content={
								!permissions.experiments.canCreate
									? 'You need to be a member of the Team to create new experiments.'
									: undefined
							}
						>
							<RouterButton
								onClick={() => ampli.dashboardHeroTriggered({ dashboard_hero_action: 'create_experiment' })}
								disabled={!permissions.experiments.canCreate}
								to="/experiments/start"
								variant={'primary'}
								width={170}
							>
								Create Experiment
							</RouterButton>
						</Tooltip>
					</CreateBox>
					<Container sx={{ zIndex: 1 }}>
						<AutoCarousel>
							<IncidentBadgeWidget />
							<ExtensionsWidget />
						</AutoCarousel>
					</Container>
				</Stack>
			</Stack>
		</Container>
	);
};

interface AutoCarouselProps {
	children: ReactNode;
}

function AutoCarousel({ children }: AutoCarouselProps): ReactElement {
	return (
		<Container
			sx={{
				mt: 32,
				width: 470,
				overflow: 'hidden',
				borderRadius: 4,
			}}
		>
			<Container
				sx={{
					display: 'flex',
					width: 'fit-content',

					animation: 'slide-it 10s linear infinite',
					'@keyframes slide-it': {
						'0%': {
							marginLeft: '0px',
						},
						'40%': {
							marginLeft: '0px',
						},
						'50%': {
							marginLeft: '-470px',
						},
						'90%': {
							marginLeft: '-470px',
						},
						'100%': {
							marginLeft: '0px',
						},
					},
				}}
			>
				{children}
			</Container>
		</Container>
	);
}
