/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, OptionTypeBase, Text } from 'components';
import { FieldHookConfig, useField } from 'formik';
import React from 'react';

import { FormSelect, FormSelectProps } from '../Select';
import { find } from '../Select/utils';
import { hasError } from './utils';

type FormikSelectOwnProps = {
	name: string;
};

export type FormikSelectProps = FormikSelectOwnProps &
	Omit<FormSelectProps, 'error' | 'touched' | 'value' | keyof FormikSelectOwnProps>;

export const FormikSelect = React.forwardRef<HTMLInputElement, FormikSelectProps>(
	({ flex, my, mx, mt, mb, ml, mr, options, onChange, ...props }, ref) => {
		const [field, meta, { setValue }] = useField({ type: 'select', ...props } as FieldHookConfig<
			string | null | undefined
		>);

		const handleChange = React.useCallback((o: OptionTypeBase | null | undefined) => setValue(o?.value), [setValue]);
		const selectedValue = React.useMemo(
			() => find(options, field.value ? (o) => o.value === field.value : undefined) ?? null,
			[field.value, options],
		);
		const hasValidValue = selectedValue || !field.value;

		return (
			<Container display={'flex'} flexDirection={'column'} my={my} mx={mx} mt={mt} mb={mb} ml={ml} mr={mr} flex={flex}>
				<FormSelect
					ref={ref}
					{...field}
					{...meta}
					options={options}
					value={{
						label: (hasValidValue ? selectedValue?.label : field.value) ?? '',
						value: (hasValidValue ? selectedValue?.label : field.value) ?? '',
					}}
					hasError={!hasValidValue || hasError(meta)}
					onChange={onChange || handleChange}
					{...props}
				/>
				{hasError(meta) ? (
					<Text mt={'xxSmall'} variant={'smallStrong'} color={'coral'} data-formik-error>
						{meta.error}
					</Text>
				) : null}
			</Container>
		);
	},
);
FormikSelect.displayName = 'FormikSelect';
