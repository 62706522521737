/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { FieldHookConfig, useField, useFormikContext } from 'formik';
import { Container, Text } from 'components';
import React from 'react';

import { StressngWorkerInput } from '../StressngWorkerInput';
import { NumberInputProps } from '../NumberInput';
import { hasError } from './utils';

export type FormikStressngWorkerInputProps = Omit<NumberInputProps, 'css' | 'error' | 'touched'>;

export const FormikStressngWorkerInput = React.forwardRef<HTMLInputElement, FormikStressngWorkerInputProps>(
	({ flex, my, mx, mt, mb, ml, mr, ...props }, ref) => {
		const formik = useFormikContext();
		const [field, meta] = useField({ type: 'number', ...props } as FieldHookConfig<number>);
		return (
			<Container display={'flex'} flexDirection={'column'} my={my} mx={mx} mt={mt} mb={mb} ml={ml} mr={mr} flex={flex}>
				<StressngWorkerInput
					ref={ref}
					hasError={hasError(meta)}
					{...field}
					onChange={(value: number) => {
						formik.setFieldTouched(field.name, true, false);
						formik.setFieldValue(field.name, value);
					}}
					{...meta}
					{...props}
				/>
				{hasError(meta) ? (
					<Text mt={'xxSmall'} variant={'smallStrong'} color={'coral'} data-formik-error>
						{meta.error}
					</Text>
				) : null}
			</Container>
		);
	},
);
FormikStressngWorkerInput.displayName = 'FormikStressngWorkerInput';
