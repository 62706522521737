/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import {
	CompositeTargetPredicateVO,
	NegationTargetPredicateVO,
	PresenceOperator,
	QueryLanguagePredicateVO,
	TargetAttributeKeyCountPredicateVO,
	TargetAttributeKeyPredicateVO,
	TargetAttributeKeyPresencePredicateVO,
	TargetAttributeKeyValuePredicateVO,
	TargetPredicateVO,
	ValueCountOperator,
} from 'ui-api';

function shouldWrapInQuotes(value: string): boolean {
	return [':', '/', '$', '_'].some((char) => value.includes(char)) && value[value.length - 1] !== '"';
}
function getQuotedKeyIfNeeded(key: string): string {
	if (shouldWrapInQuotes(key)) {
		return `"${key}"`;
	}
	return key;
}

export function toPredicateString(predicate: TargetPredicateVO | undefined): string {
	if (!predicate) {
		return '';
	}
	if (isNegationTargetPredicateVO(predicate)) {
		return `NOT ${toPredicateString(predicate.not)}`;
	}
	if (isCompositeTargetPredicateVO(predicate)) {
		if (predicate.predicates.length === 0) {
			return '';
		}
		if (predicate.predicates.length === 1) {
			return `${predicate.predicates.map(toPredicateString)}`;
		}
		return `(${predicate.predicates.map(toPredicateString).join(` ${getOperatorString(predicate.operator)} `)})`;
	}
	if (isTargetAttributeKeyValuePredicateVO(predicate)) {
		const p = predicate as TargetAttributeKeyValuePredicateVO;
		if (p.values.length === 0) {
			return '';
		}
		if (p.values.length === 1) {
			return `${getQuotedKeyIfNeeded(p.key)}${getOperatorString(p.operator)}"${p.values[0]}"`;
		} else {
			return `(${p.values
				.map((v) => `${getQuotedKeyIfNeeded(p.key)}${getOperatorString(p.operator)}"${v}"`)
				.join(' OR ')})`;
		}
	}
	if (isQueryLanguagePredicateVO(predicate)) {
		const p = predicate as QueryLanguagePredicateVO;
		return p.query;
	}
	if (isTargetAttributeKeyPresencePredicateVO(predicate)) {
		return `${getQuotedKeyIfNeeded(predicate.key)} ${getPresenceOperatorString(predicate.presenceOperator)}`;
	}
	if (isTargetAttributeKeyCountPredicateVO(predicate)) {
		return `COUNT(${getQuotedKeyIfNeeded(predicate.key)}) ${getValueCountOperatorString(
			predicate.valueCountOperator,
		)} ${getQuotedKeyIfNeeded(predicate.value)}`;
	}
	if (isTargetAttributeKeyPredicateVO(predicate)) {
		return '';
	}
	return '';
}

function isCompositeTargetPredicateVO(predicate: TargetPredicateVO): predicate is CompositeTargetPredicateVO {
	const compositeTargetPredicateVO = predicate as CompositeTargetPredicateVO;
	return compositeTargetPredicateVO.operator !== undefined && compositeTargetPredicateVO.predicates !== undefined;
}

function isTargetAttributeKeyValuePredicateVO(
	predicate: TargetPredicateVO,
): predicate is TargetAttributeKeyValuePredicateVO {
	const targetAttributeKeyValuePredicateVO = predicate as TargetAttributeKeyValuePredicateVO;
	return (
		targetAttributeKeyValuePredicateVO.key !== undefined &&
		targetAttributeKeyValuePredicateVO.operator !== undefined &&
		targetAttributeKeyValuePredicateVO.values !== undefined
	);
}

function isQueryLanguagePredicateVO(predicate: TargetPredicateVO): predicate is QueryLanguagePredicateVO {
	const queryLanguagePredicateVO = predicate as QueryLanguagePredicateVO;
	return queryLanguagePredicateVO.query !== undefined;
}

function isTargetAttributeKeyPredicateVO(predicate: TargetPredicateVO): predicate is TargetAttributeKeyPredicateVO {
	const targetAttributeKeyPredicateVO = predicate as TargetAttributeKeyPredicateVO;
	return targetAttributeKeyPredicateVO.key !== undefined && targetAttributeKeyPredicateVO.operator !== undefined;
}

function isNegationTargetPredicateVO(predicate: TargetPredicateVO): predicate is NegationTargetPredicateVO {
	const negationTargetPredicateVO = predicate as NegationTargetPredicateVO;
	return negationTargetPredicateVO.not !== undefined;
}

function isTargetAttributeKeyPresencePredicateVO(
	predicate: TargetPredicateVO,
): predicate is TargetAttributeKeyPresencePredicateVO {
	const negationTargetPredicateVO = predicate as TargetAttributeKeyPresencePredicateVO;
	return negationTargetPredicateVO.presenceOperator !== undefined;
}

function isTargetAttributeKeyCountPredicateVO(
	predicate: TargetPredicateVO,
): predicate is TargetAttributeKeyCountPredicateVO {
	const negationTargetPredicateVO = predicate as TargetAttributeKeyCountPredicateVO;
	return negationTargetPredicateVO.valueCountOperator !== undefined;
}

function getOperatorString(operator: string): string {
	switch (operator) {
		case 'EQUALS':
			return '=';
		case 'EQUALS_IGNORE_CASE':
			return '=*';
		case 'AND':
			return 'AND';
		case 'OR':
			return 'OR';
		case 'CONTAINS':
			return '~';
		case 'CONTAINS_IGNORE_CASE':
			return '~*';
		case 'NOT_CONTAINS':
			return '!~';
		case 'NOT_EQUALS':
			return '!=';
		case 'NOT_CONTAINS_IGNORE_CASE':
			return '!~*';
		case 'NOT_EQUALS_IGNORE_CASE':
			return '!=*';
	}
	return '';
}

function getPresenceOperatorString(operator: PresenceOperator): string {
	switch (operator) {
		case 'PRESENT':
			return 'IS PRESENT';
		case 'NOT_PRESENT':
			return 'IS NOT PRESENT';
	}
	return '';
}

function getValueCountOperatorString(operator: ValueCountOperator): string {
	switch (operator) {
		case 'EQUAL':
			return '=';
		case 'NOT_EQUAL':
			return '!=';
		case 'GREATER_THAN':
			return '>';
		case 'GREATER_THAN_OR_EQUAL':
			return '>=';
		case 'LESS_THAN':
			return '<';
		case 'LESS_THAN_OR_EQUAL':
			return '<=';
	}
	return '';
}
