/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { createErrorResult, createResult, DataStreamResult, loadingResult } from 'utils/hooks/stream/result';
import { useEffect, useMemo, useState } from 'react';
import { usePromise } from 'utils/hooks/usePromise';
import { TeamSummaryVO, TenantVO } from 'ui-api';
import { useUrlState } from 'url/useUrlState';
import { get, set } from 'utils/localStorage';
import { Services } from 'services/services';
import { useHistory } from 'url/hooks';
import { Subject } from 'rxjs';

import { teamKeyParam } from '../../tenancy/urlParams';

const localStorageKey = 'team-key';

const reloadStream = new Subject<boolean>();

export function forceReloadTeams(): void {
	reloadStream.next(true);
}

export function useActiveTeam(tenant: TenantVO): DataStreamResult<TeamSummaryVO> {
	const history = useHistory();

	const [reloadMarker, setReloadMarker] = useState(0);
	useEffect(() => {
		const subscription = reloadStream.subscribe(() => setReloadMarker(reloadMarker + 1));
		return () => {
			subscription.unsubscribe();
		};
	}, []);

	// get all available teams
	const teamsResult = usePromise(() => Services.teams.getAllTeams(), [tenant.key, reloadMarker]);

	// optional URL stored team key
	const [{ team: teamKey }, getUrl] = useUrlState([teamKeyParam]);

	// optional local storage team key
	const keyInLocalStorage = get(tenant.key, localStorageKey);
	let resolvedTeam = teamsResult.value?.find((t) => t.key === (teamKey ?? keyInLocalStorage));
	if (resolvedTeam == null && teamsResult.value && teamsResult.value?.length > 0) {
		// if no team for the given teamKey can be found, use the first team the user is in (if any) or the first team
		const firstMyTeam = teamsResult.value.filter((t) => t.userIsTeamMember)[0];
		resolvedTeam = firstMyTeam ?? teamsResult.value[0];
	}

	const teamKeyFromOldPath = getTeamFromOldPath(history.location.pathname);
	useEffect(() => {
		if (teamKeyFromOldPath) {
			const urlWithTeamAsQueryParam = getUrl({ team: teamKeyFromOldPath.team }, (_location) => {
				_location.pathname = teamKeyFromOldPath.path;
			});
			history.replace(urlWithTeamAsQueryParam);
		} else if (resolvedTeam && teamKey !== resolvedTeam.key) {
			history.replace(getUrl({ team: resolvedTeam.key }));
		}
	}, [teamKey, teamKeyFromOldPath, resolvedTeam]);

	useEffect(() => {
		if (resolvedTeam) {
			Services.teams.setTeam(tenant.key, resolvedTeam.key);
			set(tenant.key, localStorageKey, resolvedTeam.key);
		}
	}, [tenant.key, resolvedTeam?.key]);

	// We require useMemo because several parts of the UI rely on identity comparison of the returned result.
	return useMemo(() => {
		if (resolvedTeam) {
			return createResult(resolvedTeam);
		} else if (teamsResult.loading) {
			return loadingResult;
		} else if (teamsResult.error) {
			return { loading: false, error: teamsResult.error, value: undefined };
		} else {
			return createErrorResult('Failed to find a team', 404);
		}
	}, [resolvedTeam, teamsResult]);
}

type OldPath = {
	team: string;
	path: string;
};

// exported for testing
export function getTeamFromOldPath(path: string): OldPath | undefined {
	const parts = path.split('/').filter(Boolean);
	if (parts.length < 2) {
		return;
	}

	if (parts[0] !== 'dashboard' && parts[0] !== 'landscape' && parts[0] !== 'experiments' && parts[0] !== 'policies') {
		return;
	}
	if (parts[0] === 'dashboard') {
		/*
			/dashboard/:team/
		*/
		return returnIfUpperCase(parts[1], '/dashboard');
	}
	if (parts[0] === 'landscape') {
		/*
			/landscape/:team
			/landscape/:team/
			/landscape/:team/:environmentId/map
			/landscape/:team/:environmentId/map/:cluster/:namespace?/:deploymentId?/:podId?
			/landscape/:team/:environmentId/table
			/landscape/services/:team/
			/landscape/services/:team/:environmentId/map/:cluster/:namespace?/:deploymentId?/:podId?
			/landscape/services/:team/:environmentId/policy
			/landscape/services/:team/:environmentId/table
			/landscape/services/:team/:environmentId/table/addPolicies
			/landscape/services/:team/:environmentId/table/addPolicies/new
			/landscape/services/:team/:environmentId/table/new
		*/
		if (parts[1] === 'policies') {
			return returnIfUpperCase(parts[2], '/landscape/policies/' + parts.slice(3).join('/'));
		}
		return returnIfUpperCase(parts[1], '/landscape/' + parts.slice(2).join('/'));
	}
	if (parts[0] === 'policies') {
		/*
			/policies/:team/
			/policies/:team/:environmentId/map/:cluster/:namespace?/:deploymentId?/:podId?
			/policies/:team/:environmentId/policy
			/policies/:team/:environmentId/table
			/policies/:team/:environmentId/table/addPolicies
			/policies/:team/:environmentId/table/addPolicies/new
			/policies/:team/:environmentId/table/new
		*/
		return returnIfUpperCase(parts[1], '/landscape/policies/' + parts.slice(2).join('/'));
	}
	if (parts[0] === 'experiments') {
		/*
			/experiments/:team/
			/experiments/:team/edit/:experimentKey
			/experiments/:team/edit/:experimentKey/:section/:experimentExecutionId(\\d+)?/:experimentExecutionSection?
			/experiments/:team/edit/<new>
			/experiments/:team/edit/<new>/:section
			/experiments/:team/start/:template?
		*/
		return returnIfUpperCase(parts[1], '/experiments/' + parts.slice(2).join('/'));
	}
}

function returnIfUpperCase(team: string, path: string): OldPath | undefined {
	return team && team === team.toUpperCase() ? { team, path } : undefined;
}
