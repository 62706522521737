/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, ModalOverlay } from 'components';
import { CustomPromptBlockPredicate, useCustomPrompt } from 'utils/hooks/useCustomPrompt';
import React from 'react';

export const DidNotSavePrompt: React.VFC<{
	block: CustomPromptBlockPredicate;
	entityTitle: string;
	when: boolean;
	onSaveCallback: () => unknown | null;
}> = ({ when, entityTitle, block, onSaveCallback }) => {
	const { showPrompt, resolve } = useCustomPrompt(when, block);

	return (
		<ModalOverlay open={showPrompt} onClose={() => resolve(false)}>
			<Dialog>
				<DialogHeader title={'Are you sure?'} onClose={() => resolve(false)} variant={'medium'} />
				<DialogContent>Do you want to keep changes in this {entityTitle}?</DialogContent>
				<DialogFooter>
					<Button variant={'secondary'} onClick={() => resolve(true)}>
						Discard changes
					</Button>
					<Button ml={'auto'} variant={'secondary'} onClick={() => resolve(false)}>
						Cancel
					</Button>
					<Button
						ml={'xSmall'}
						variant={'primary'}
						onClick={async () => {
							const result = await onSaveCallback();
							if (result == null) {
								resolve(false);
							} else {
								resolve(true);
							}
						}}
					>
						Save
					</Button>
				</DialogFooter>
			</Dialog>
		</ModalOverlay>
	);
};
