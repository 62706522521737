/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { useEventEffect } from 'utils/hooks/useEventEffect';
import { useState } from 'react';

export function useViewsUpdateSignal(): number {
	const [reloadCounter, setReloadCounter] = useState(0);

	useEventEffect(
		() => {
			setReloadCounter(reloadCounter + 1);
		},
		['landscape-view-created', 'landscape-view-deleted', 'landscape-view-updated'],
		() => {},
		[reloadCounter],
	);

	return reloadCounter;
}
