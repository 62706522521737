/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Text, TextProps } from 'components';
import React from 'react';

type LabelProps = TextProps;

export const Label: React.FC<LabelProps> = ({ children, ...props }) => {
	return (
		<Text as={'label'} variant={'mediumStrong'} {...props}>
			{children}
		</Text>
	);
};
