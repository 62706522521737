/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { UrlParam } from 'url/useUrlState';

export const tenantKeyParam: UrlParam<string | undefined> = {
	name: 'tenant',
	defaultValue: undefined,
};

export const teamKeyParam: UrlParam<string | undefined> = {
	name: 'team',
	defaultValue: undefined,
};
