/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { useHistory } from 'url/hooks';
import { stringify } from 'url/jsurl2';

type TenantSwitcher = (tenantKey: string) => void;

export function useTenantSwitcher(): TenantSwitcher {
	const history = useHistory();

	return (tenantKey) => {
		const href = history.createHref({
			pathname: '/',
			query: {
				tenant: stringify(tenantKey),
			},
		});
		window.location.assign(href);
	};
}
