/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Container, ModalContentV2, ModalHeaderV2, ModalOverlay, ModalV2, Stack, Text } from 'components';
import { ExperimentExecutionPreflightVO } from 'ui-api';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import { ActionIcon } from '../../../../hocs/ActionIcon';
import LogLinePreflight from './LogLinePreflight';
import ErroredBadge from './ErroredBadge';
import FailedBadge from './FailedBadge';

interface ExperimentExecutionLogPreflightsModalProps {
	preflights: ExperimentExecutionPreflightVO[];
	onClose: () => void;
}

export default function ExperimentExecutionLogPreflightsModal({
	preflights,
	onClose,
}: ExperimentExecutionLogPreflightsModalProps): ReactElement {
	const colors = {
		backgroundColor: '#bec6da',
		backgroundImage: 'repeating-linear-gradient(120deg,transparent,transparent 4px,#aebad1 4px,#aebad1 8px)',
		color: 'neutral700',
	};

	const numberErrored = preflights.filter((preflight) => preflight.state === 'ERRORED').length;
	const numberFailed = preflights.filter((preflight) => preflight.state === 'FAILED').length;
	return (
		<ModalOverlay open onClose={onClose}>
			{({ close }) => (
				<ModalV2
					frameless
					width="70vw"
					sx={{
						maxWidth: 1400,
						borderRadius: 4,
						overflow: 'hidden',
					}}
				>
					<ModalHeaderV2 title="" onClose={close} />
					<ModalContentV2>
						<Container>
							<Container
								sx={{
									pt: '36px',
									pb: '16px',
									px: '32px',
									backgroundColor: 'neutral200',
									borderBottom: '1px solid ' + theme.colors.neutral300,
								}}
							>
								<Container display="flex" justifyContent="space-between" alignItems="center" mb="small">
									<Stack
										direction="horizontal"
										alignItems="center"
										size="xSmall"
										sx={{
											width: 'fit-content',
											p: 8,
											borderRadius: 4,
											backgroundColor: 'white',
										}}
									>
										<Container
											sx={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												width: 32,
												height: 32,
												backgroundColor: colors.backgroundColor,
												borderRadius: 4,
											}}
										>
											<ActionIcon id={'TODO'} color={colors.color} />
										</Container>

										<Stack size="none">
											<Text variant="xSmall" color="neutral700">
												Preflight Checks
											</Text>
										</Stack>
									</Stack>

									<Stack direction="horizontal">
										{numberErrored > 0 ? (
											<ErroredBadge numberErrored={numberFailed} />
										) : numberFailed > 0 ? (
											<FailedBadge numberFailed={numberFailed} />
										) : null}
									</Stack>
								</Container>
							</Container>

							<Container px={32} py={16}>
								<Stack size="large">
									<Stack size="xxSmall">
										{preflights.map((preflight) => (
											<LogLinePreflight key={preflight.id} preflight={preflight} />
										))}
									</Stack>
								</Stack>
							</Container>
						</Container>
					</ModalContentV2>
				</ModalV2>
			)}
		</ModalOverlay>
	);
}
