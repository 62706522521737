/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Link, LinkProps } from 'url/Link';
import React from 'react';

import { ButtonIcon, ButtonIconProps } from './ButtonIcon';

export type RouterButtonIconProps = ButtonIconProps & LinkProps<never>;

export const RouterButtonIcon = React.forwardRef<HTMLButtonElement, RouterButtonIconProps>(
	({ children, ...props }, ref) => (
		<ButtonIcon ref={ref} as={Link} {...props}>
			{children}
		</ButtonIcon>
	),
);
RouterButtonIcon.displayName = 'RouterButtonIcon';
