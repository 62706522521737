/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	IconCancel,
	IconCheck,
	IconWarning,
	IconWarningCircle,
	IconWarningCircleOpen,
	IconWarningOpen,
} from 'components/icons';
import { ActionKindVO, ActionVO, EnvironmentVariableVO, ExperimentStepRadiusVO } from 'ui-api';
import { createResult, DataStreamResult, loadingResult } from 'utils/hooks/stream/result';
import { debounce, Dictionary, groupBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { LoadingIndicator } from 'components';
import { Services } from 'services/services';
import { theme } from 'styles.v2/theme';

export const useBlastRadiusCount = (
	blastRadius: ExperimentStepRadiusVO,
	environmentId: string,
	variables: EnvironmentVariableVO[],
): { total?: number; impacted?: number; loading: boolean } => {
	const [totalTargetCountForBlastRadius, setTotalTargetCountForBlastRadius] =
		useState<DataStreamResult<number>>(loadingResult);
	const [debouncedCount] = useState(() =>
		debounce(
			async (environmentId, blastRadius, variables) => {
				return Services.targets.countTargetsForBlastRadius(environmentId, blastRadius, variables);
			},
			500,
			{ leading: true },
		),
	);
	useEffect(() => {
		debouncedCount(environmentId, blastRadius, variables).then((result) =>
			setTotalTargetCountForBlastRadius(createResult(result)),
		);
	}, [environmentId, blastRadius, variables]);

	const countImpacted = useMemo(() => {
		if (totalTargetCountForBlastRadius.value === 0) {
			return 0;
		}
		if (Number.isInteger(blastRadius.percentage)) {
			return Math.round(((blastRadius.percentage ?? 0) / 100) * (totalTargetCountForBlastRadius.value || 0));
		}
		if (Number.isInteger(blastRadius.maximum)) {
			return blastRadius.maximum;
		}
		return totalTargetCountForBlastRadius.value;
	}, [blastRadius.percentage, blastRadius.maximum, totalTargetCountForBlastRadius]);

	return {
		total: totalTargetCountForBlastRadius.value,
		impacted: countImpacted,
		loading: totalTargetCountForBlastRadius.loading,
	};
};

export const getAttacksPerType = (
	actions: ActionVO[] | undefined,
	allowedActionIds: string[],
	keyForAttacksWithoutTargetType = 'other_attacks',
): Dictionary<ActionVO[]> | undefined => {
	if (actions && allowedActionIds) {
		return groupBy(
			actions.filter((a) => allowedActionIds && allowedActionIds.includes(a.id) && a.kind === 'ATTACK'),
			(attack) => attack.target.type ?? keyForAttacksWithoutTargetType,
		);
	}
};

export const getActionPerKindWithoutAttacks = (
	actions: ActionVO[] | undefined,
	allowedActionIds: string[],
	uniqueActionNames: { [p: string]: string },
): Dictionary<ActionVO[]> | undefined => {
	if (actions && allowedActionIds) {
		return groupBy(
			actions
				.filter((a) => allowedActionIds && allowedActionIds.includes(a.id))
				.filter((a) => a.kind !== 'ATTACK')
				.map((a) => ({ ...a, name: uniqueActionNames[a.id] || a.name })),
			(attack) => attack.kind,
		);
	}
};

type Colors = {
	backgroundColor: string;
	color: string;
};

export const getColors = (kind: ActionKindVO): Colors => {
	switch (kind) {
		case 'ATTACK':
			return { backgroundColor: 'experimentAttack', color: 'neutral000' };
		case 'CHECK':
			return { backgroundColor: 'experimentCheck', color: 'neutral000' };
		case 'LOAD_TEST':
			return { backgroundColor: 'experimentLoadTest', color: 'purple900' };
		case 'OTHER':
			return { backgroundColor: 'experimentOther', color: 'neutral000' };
	}
};

export function getBGColorForState(state: string): string {
	switch (state) {
		case 'CANCELED':
			return theme.colors.neutral150;
		case 'FAILED':
			return '#FEF5D9';
		case 'ERRORED':
			return theme.colors.coral100;
		case 'RUNNING':
		case 'PREPARED':
		case 'CREATED':
			return theme.colors.purple100;
		default:
			return theme.colors.success050;
	}
}

export function getColorForState(state: string, fallback: string = theme.colors.successDark): string {
	switch (state) {
		case 'CANCELED':
			return theme.colors.neutral600;
		case 'FAILED':
			return theme.colors.experimentWarning;
		case 'ERRORED':
			return theme.colors.coral;
		case 'RUNNING':
		case 'PREPARED':
		case 'CREATED':
			return theme.colors.purple700;
		case 'SKIPPED':
			return theme.colors.neutral400;
		default:
			return fallback;
	}
}

export function getDarkerColorForState(state: string): string {
	switch (state) {
		case 'FAILED':
			return '#B54708';
		case 'ERRORED':
			return theme.colors.coral700;
		default:
			return getColorForState(state);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getIconForState(state: string, light = false) {
	switch (state) {
		case 'CANCELED':
		case 'SKIPPED':
			return IconCancel;
		case 'FAILED':
			return light ? IconWarningOpen : IconWarning;
		case 'ERRORED':
			return light ? IconWarningCircleOpen : IconWarningCircle;
		case 'CREATED':
		case 'PREPARED':
		case 'RUNNING':
			return LoadingIndicator;
		default:
			return IconCheck;
	}
}

export function isErrored(state: string): boolean {
	return state === 'ERRORED';
}

export function isCanceled(state: string): boolean {
	return state === 'CANCELED';
}

export function isFailed(state: string): boolean {
	return state === 'FAILED';
}

export function isRunning(state: string): boolean {
	return state === 'RUNNING';
}

export function isNotEnded(state: string): boolean {
	return isRunning(state) || isPrepared(state) || isCreated(state);
}

export function isPrepared(state: string): boolean {
	return state === 'PREPARED';
}

export function isCreated(state: string): boolean {
	return state === 'CREATED';
}

export function isSkipped(state: string): boolean {
	return state === 'SKIPPED';
}

export function isCompleted(state: string): boolean {
	return state === 'COMPLETED';
}

export function isSucceeded(state: string): boolean {
	return !isErrored(state) && !isCanceled(state) && !isFailed(state) && !isRunning(state);
}

export function mapExperimentExecutionState(state: string): 'failed' | 'aborted' | 'succeeded' | 'errored' | undefined {
	if (isFailed(state)) {
		return 'failed';
	} else if (isCanceled(state)) {
		return 'aborted';
	} else if (isCompleted(state)) {
		return 'succeeded';
	} else if (isErrored(state)) {
		return 'errored';
	}
	return undefined;
}
