/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	Container,
	DialogContent,
	DialogHeader,
	ModalOverlay,
	ModalV2,
	RouterButton,
	Snackbar,
	Stack,
	Text,
} from 'components';
import { useTargetSelectionForExperimentTracking } from 'targets/Explore/hooks/useLandscapeExploreTracking';
import { IconClipboard, IconExperiment, IconFix } from 'components/icons';
import { ExperimentFormValues } from 'pages/experiments/experiment';
import { ExperimentStepRadiusVO, ExperimentVO } from 'ui-api';
import ReactCopyToClipboard from 'react-copy-to-clipboard';
import { FormikErrors, useFormikContext } from 'formik';
import { LandscapeConfig } from 'targets/Explore/types';
import Editor from 'components/Editor/Editor';
import { theme } from 'styles.v2/theme';
import { useHistory } from 'url/hooks';
import { ReactElement } from 'react';
import { ampli } from 'ampli';

interface UseAsExperimentModalProps {
	onClose: () => void;
	filterQuery: string;
	query: string;
}

export function UseAsExperimentModal({ filterQuery, query, onClose }: UseAsExperimentModalProps): ReactElement {
	const formik = useFormikContext<LandscapeConfig>();
	const { environmentId } = formik.values;

	useTargetSelectionForExperimentTracking(query, environmentId);

	if (filterQuery) {
		query = query ? `(${filterQuery}) AND (${query})` : filterQuery;
	}

	const { createHref } = useHistory<{
		preparedFormData?: PreparedFormData;
		initialValues?: ExperimentFormValues;
		blastRadius?: ExperimentStepRadiusVO;
	}>();

	return (
		<ModalOverlay open centerContent onClose={onClose}>
			{({ close }) => (
				<ModalV2>
					<DialogHeader title="Use Target Selection in an Experiment" onClose={close}></DialogHeader>
					<DialogContent overflowY="auto">
						<Stack mt="small">
							<Container
								sx={{
									bg: 'neutral150',
									borderRadius: 8,
									p: 'small',
								}}
							>
								<Stack direction="horizontal" alignItems="flex-start">
									<IconFix minWidth={24} minHeight={24} color="neutral800" />
									<Stack>
										<Stack size="xxSmall">
											<Text variant="mediumStrong" color="neutral800">
												How it works
											</Text>
											<Text variant="small">
												Leverage the current configuration you have created in the landscape and apply it to a new
												Experiment. You can effectively reuse your configurations from the landscape and eliminate the
												manual process of identifying targets in the Experiment Designer.
											</Text>
										</Stack>
										<Stack size="none">
											<Text variant="smallStrong" color="neutral800">
												The steps:
											</Text>
											<Text as="span" variant="small">
												1. Create a new
												<Text as="span" variant="smallStrong" ml="xxSmall">
													Experiment
												</Text>
											</Text>

											<Text as="span" variant="small">
												2.
												<Text as="span" variant="smallStrong" mx="xxSmall">
													Select an attack
												</Text>
												(or any action) to start the editing
											</Text>

											<Text as="span" variant="small">
												3.
												<Text as="span" variant="smallStrong" mx="xxSmall">
													Copy-Paste
												</Text>
												the following query in the Target Selection
											</Text>
										</Stack>
									</Stack>
								</Stack>
							</Container>
							<Stack>
								<Text variant="largeStrong" color="neutral600">
									Target Selection From Current View
								</Text>

								<Stack size="none" alignItems="flex-end">
									<ReactCopyToClipboard
										text={query}
										onCopy={() => {
											Snackbar.dark(
												<>
													<Text variant={'mediumStrong'} sx={{ display: 'inline', mr: 'xxSmall' }}>
														Query copied to your clipboard!
													</Text>
													<Text variant={'medium'} sx={{ display: 'inline' }}>
														Now, create an experiment and use it for the target-selection in an action.
													</Text>
												</>,
												{ toastId: 'value-copied' },
											);
											ampli.landscapeExplorerTargetCopiedForExperiment({
												environment_id: environmentId,
												target_query: query,
											});
										}}
									>
										<Button variant="chromelessSmall">
											<IconClipboard variant="small" mr="xxSmall" />
											Copy Query
										</Button>
									</ReactCopyToClipboard>
									<Container
										sx={{
											border: '1px dashed ' + theme.colors.neutral300,
											width: '100%',
										}}
									>
										<Editor disabled initialValue={query} />
									</Container>
								</Stack>

								<Container>
									<RouterButton
										to={createHref((location) => {
											location.pathname = '/experiments/edit/<new>/fromlandscape/' + environmentId;
										})}
										target="_blank"
									>
										<IconExperiment mr="xSmall" />
										Create new Experiment
									</RouterButton>
								</Container>
							</Stack>
						</Stack>
					</DialogContent>
				</ModalV2>
			)}
		</ModalOverlay>
	);
}

export type ExperimentFormActions = 'save';
type PreparedFormData = {
	initialValues: ExperimentFormValues;
	initialErrors: FormikErrors<ExperimentFormValues>;
	actions: ExperimentFormActions[];
	experiment?: ExperimentVO;
};
