/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import Dropdown from 'components/Select/Dropdown/Dropdown';
import { Container, Li, Ul } from 'components';
import { ReactElement, Ref } from 'react';

import TextFieldWithVariableHighlighting from './TextFieldWithVariableHighlighting';
import VariablesAndPlaceholders from './VariablesAndPlaceholders';
import FocussableWrapper from './FocussableWrapper';

interface OptionType {
	label: string;
	value: string;
}

interface StringOptionsProps {
	options: OptionType[];
	hasErrors?: boolean;
	disabled: boolean;
	value: string;
	setValue: (value: string) => void;
}

export default function StringOptions({
	value,
	options,
	hasErrors,
	disabled,
	setValue,
}: StringOptionsProps): ReactElement {
	const resolvedOption = options.find((option) => option.value === value);
	return (
		<Dropdown<string>
			placement="bottom-start"
			value={resolvedOption?.label || value}
			onValueChanged={setValue}
			renderComponent={({ setShowMenu, value, ref }) => {
				return (
					<FocussableWrapper
						ref={ref as Ref<HTMLDivElement>}
						onFocus={() => setShowMenu(true)}
						onBlur={() => setShowMenu(false)}
						disabled={disabled}
					>
						<TextFieldWithVariableHighlighting
							value={String(value)}
							hasErrors={hasErrors}
							disabled={disabled}
							onClick={() => setShowMenu(true)}
						/>
					</FocussableWrapper>
				);
			}}
		>
			{({ value, selectItem, width }) => (
				<DropDownContent value={value} selectItem={selectItem} width={width} options={options} />
			)}
		</Dropdown>
	);
}

interface DropDownContentProps {
	selectItem: (value: string) => void;
	width?: number | string;
	options: OptionType[];
	value?: string;
}

function DropDownContent({ value: currentValue, selectItem, options, width }: DropDownContentProps): ReactElement {
	return (
		<DropdownContentFrame>
			<Container
				width={width}
				maxHeight={200}
				sx={{
					backgroundColor: 'neutral000',
				}}
			>
				<Ul>
					{options.map(({ label, value }) => (
						<Li
							key={value}
							sx={{
								px: 'small',
								py: 'xSmall',

								cursor: 'pointer',
								backgroundColor: value === currentValue ? 'neutral100' : 'neutral000',
								fontWeight: value === currentValue ? '500' : '400',
								':hover': {
									fontWeight: '500',
									backgroundColor: 'neutral100',
								},
							}}
							onClick={() => selectItem(value)}
						>
							{label}
						</Li>
					))}
				</Ul>

				<VariablesAndPlaceholders width={width} selectItem={selectItem} />
			</Container>
		</DropdownContentFrame>
	);
}
