/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react';
import { Stack } from 'components';

import ActionSubGroup from './ActionSubGroup';
import { ActionSubCategory } from './types';

interface ActionSubGroupsProps {
	subGroups?: ActionSubCategory[];
}

export default function ActionSubGroups({ subGroups }: ActionSubGroupsProps): ReactElement | null {
	if (!subGroups) {
		return null;
	}

	return (
		<Stack size="xxSmall">
			{subGroups.map((subGroup) => (
				<ActionSubGroup key={subGroup.label} subGroup={subGroup} />
			))}
		</Stack>
	);
}
