/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ButtonIcon,
	Container,
	RouterButton,
	RouterPagination,
	Snackbar,
	Spinner,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	TableSortLink,
	Tag,
	Text,
	Tooltip,
	userConfirm,
	UserIcon,
} from 'components';
import { Order, PageLocation, PageParams, usePage } from 'utils/hooks/usePage';
import { IconAdd, IconDelete, IconUser, IconWarning } from 'components/icons';
import ListHeaderSearch from 'components/List/presets/ListHeaderSearch';
import ListHeaderTitle from 'components/List/presets/ListHeaderTitle';
import React, { ReactNode, useEffect, useMemo } from 'react';
import ListHeader from 'components/List/presets/ListHeader';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { Services } from 'services/services';
import debounce from 'debounce-promise';
import { useDebounce } from 'react-use';
import { useHistory } from 'url/hooks';
import { ensure } from 'utils/ensure';
import { UserVO } from 'ui-api';

import ContentWrapper from '../components/ContentWrapper';
import invokePromise from '../../../utils/ignorePromise';
import { useTenant } from '../../../tenancy/useTenant';
import { RoleSelect } from './components/roleSelect';
import { useUser } from '../../../services/usersApi';
import HelpText from '../components/HelpText';
import { ampli } from '../../../ampli';

const Users: React.VFC = () => {
	const currentTenant = useTenant();
	const currentUserVO = useUser();
	const page = usePage('/users', { size: 15, sort: [['name', 'asc', 'ignoreCase']] });
	const debounced = useMemo(
		() => debounce((query, params) => Services.users.fetchUsersWithQuery(query, params), 200, { leading: true }),
		[],
	);
	const [users, fetch] = useAsyncState(
		() => ensure(debounced(page.criteria.get('query') ?? '', page.pageParams)),
		[page],
	);
	useEventEffect(
		(event) => {
			if (event.type === 'user.membershipGranted') {
				fetch();
			} else if (users.value?.content.some((user) => user.username === event.username)) {
				fetch();
			}
		},
		[],
		() => {},
		[users.value],
	);

	const handleRoleChange = (user: UserVO) => (role: string) => {
		invokePromise(async () => {
			try {
				await Services.users.updateUserRole(user.username, { role });
				Snackbar.dark(`Switched role to ${role}`, {
					toastId: 'user-change-role',
				});
			} catch (err) {
				Snackbar.error('Role not updated: ' + err.toString(), {
					toastId: 'user-change-role',
				});
			}
		});
	};

	const handleRemove = (user: UserVO) => () => {
		invokePromise(async () => {
			if (
				(await userConfirm({
					title: 'Delete User',
					message: <DeleteUserAreYouSure user={user} />,
					actions: [
						{ value: 'cancel', label: 'Cancel' },
						{
							value: 'confirm',
							label: (
								<span data-cy="confirmRemoveUser">
									Remove <span data-private>{user.name}</span>
								</span>
							),
							variant: 'primary',
						},
					],
				})) === 'confirm'
			) {
				try {
					await Services.users.removeUserRole(user.username);
					Snackbar.dark(
						<span>
							Removed <span data-private>{user.name}</span>
						</span>,
						{ toastId: 'user-remove' },
					);
				} catch (err) {
					Snackbar.error('User not removed: ' + err.toString(), {
						toastId: 'user-remove',
					});
				}
			}
		});
	};

	useEffect(() => {
		ampli.userListViewed({ url: window.location.href });
	}, []);

	return (
		<ContentWrapper>
			<ListHeader
				left={<ListHeaderTitle title="Users" Icon={IconUser} />}
				description={
					<HelpText>
						There are properly more people getting benefit from Steadybit. So,&nbsp;
						{users.value?._actions.includes('invite') ? 'invite more users to the platform to ' : undefined}roll out
						Chaos Engineering into your organization!
					</HelpText>
				}
				right={
					<Stack direction="horizontal" alignItems="center">
						<UserFilters page={page} />
						{currentTenant.user.role === 'ADMIN' && (
							<Tooltip
								disabled={users.value?._actions.includes('invite')}
								bindWidth={{
									target: 'reference',
									offset: 210,
								}}
								content={
									'Inviting new users is disabled as your platform syncs with an external authentication provider'
								}
							>
								<RouterButton
									color={'primaryLarge'}
									to={'/settings/users/invite'}
									data-cy="invite-user"
									disabled={!users.value?._actions.includes('invite')}
								>
									<IconAdd mr="xSmall" ml="-xxSmall" />
									<Text variant="mediumStrong" sx={{ whiteSpace: 'nowrap' }}>
										Invite Users
									</Text>
								</RouterButton>
							</Tooltip>
						)}
					</Stack>
				}
			/>

			<Stack>
				{users.error && <Text>Error loading Users: {users.error.message}</Text>}
				{users.loading && !users.value ? <Spinner variant="large" color={'neutral500'} mr={'auto'} /> : null}
				{users.value && (
					<Stack direction={'vertical'} size={'large'}>
						<UserTable page={page}>
							{users.value.content.map((user) => (
								<UserRow
									key={user.username}
									value={user}
									disabled={!users.value?._actions.includes('manage-members')}
									onRoleChange={handleRoleChange(user)}
									onRemove={handleRemove(user)}
									allowRemove={user.username !== currentUserVO?.username}
								/>
							))}
							{!users.value.content.length && (
								<TableRow>
									<TableDataCell colSpan={4}>
										<Text muted>No Users found.</Text>
									</TableDataCell>
								</TableRow>
							)}
						</UserTable>
						<RouterPagination
							activePage={page.pageParams.page}
							totalPages={users.value?.totalPages}
							to={(i) => page.withPage(i).toString()}
						/>
					</Stack>
				)}
			</Stack>
		</ContentWrapper>
	);
};

const DeleteUserAreYouSure: React.VFC<{ user: UserVO }> = ({ user }) => {
	const [tokens] = useAsyncState(() => {
		const urlSearchParams = new URLSearchParams();
		urlSearchParams.set('createdBy', user.username);
		const pageParams = new PageParams(0, 1, [['name', 'asc']]);
		return Services.accessTokens.findAccessTokens(urlSearchParams, pageParams);
	}, [user]);
	return (
		<>
			{!tokens.loading && tokens.value && (
				<>
					<Text>
						Do you really want to remove <span data-private>{user.name}</span>?
					</Text>
					{tokens.value?.content.length > 0 && (
						<Stack direction={'horizontal'} mt={'small'}>
							<Text>
								The user has created{' '}
								<Text as="span" variant="mediumStrong" color="neutral800">
									{tokens.value.totalElements} access tokens
								</Text>{' '}
								which will also be deleted.
							</Text>
							<Container>
								<IconWarning sx={{ color: 'experimentWarning' }} />
							</Container>
						</Stack>
					)}
				</>
			)}
		</>
	);
};

const UserFilters: React.VFC<{ page: PageLocation }> = ({ page }) => {
	const history = useHistory();
	const [query, setQuery] = React.useState<string | null>(page.criteria.get('query'));
	useDebounce(
		() => {
			if (query) {
				ampli.userListFiltered({ filter_text: query });
			}
			// reset pagination information when filtering
			history.replace(page.withCriterion('query', query).withPage(0).toString());
		},
		350,
		[query],
	);

	return (
		<ListHeaderSearch
			data-cy="searchUser"
			data-private
			title="Search User"
			value={query ?? ''}
			setValue={(v) => setQuery(v)}
		/>
	);
};

const SORT_NAME_ASC: Order[] = [['name', 'asc', 'ignoreCase']];
const SORT_NAME_DESC: Order[] = [['name', 'desc', 'ignoreCase']];
const SORT_EMAIL_ASC: Order[] = [['email', 'asc']];
const SORT_EMAIL_DESC: Order[] = [['email', 'desc']];

const UserTable: React.FC<{ page: PageLocation; children: ReactNode }> = ({ page, children }) => {
	return (
		<Table width={'100%'} data-cy="users-table">
			<TableHead>
				<TableRow>
					<TableHeadCell>
						<TableSortLink
							sort={page.getDirection(SORT_NAME_ASC, SORT_NAME_DESC)}
							to={page.toggleSort(SORT_NAME_ASC, SORT_NAME_DESC).toString()}
							onClick={() => {
								ampli.userListSorted({ sorted_by: 'Name' });
							}}
						>
							Name
						</TableSortLink>
					</TableHeadCell>
					<TableHeadCell>
						<TableSortLink
							sort={page.getDirection(SORT_EMAIL_ASC, SORT_EMAIL_DESC)}
							to={page.toggleSort(SORT_EMAIL_ASC, SORT_EMAIL_DESC).toString()}
							onClick={() => {
								ampli.userListSorted({ sorted_by: 'Email' });
							}}
						>
							Email
						</TableSortLink>
					</TableHeadCell>
					<TableHeadCell>Role</TableHeadCell>
					<TableHeadCell>Teams</TableHeadCell>
					<TableHeadCell width={50} />
				</TableRow>
			</TableHead>
			<TableBody>{children}</TableBody>
		</Table>
	);
};

interface UserRowProps {
	value: UserVO;
	disabled?: boolean;
	onRoleChange: (role: string) => void;
	onRemove: () => void;
	allowRemove: boolean;
}

const UserRow: React.VFC<UserRowProps> = ({ value, disabled, onRoleChange, onRemove, allowRemove }) => {
	return (
		<TableRow hoverable={true} height={54}>
			<TableDataCell data-private>
				<UserIcon variant="medium" src={value.pictureUrl} mr={5} />
				{value.name}
			</TableDataCell>
			<TableDataCell data-private>{value.email ?? ''}</TableDataCell>
			<TableDataCell>
				{!disabled ? <RoleSelect value={value.role} onChange={onRoleChange} /> : value.role}
			</TableDataCell>
			<TableDataCell sx={{ flexWrap: 'wrap', gap: '6px' }}>
				{value.teams.map((team) => (
					<Tag key={team} variant="xSmall">
						{team}
					</Tag>
				))}
			</TableDataCell>
			<TableDataCell justifyContent={'flex-end'}>
				{!disabled && allowRemove ? (
					<ButtonIcon onClick={onRemove} tooltip={'Remove User'} data-cy={'removeUser_' + value.name}>
						<IconDelete />
					</ButtonIcon>
				) : null}
			</TableDataCell>
		</TableRow>
	);
};

export default Users;
