/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import React, { useMemo } from 'react';

import { Container, ContainerProps, StyleProp, Text } from '..';
import { IconComponent, IconRemove } from '../icons';

export interface TagProps extends ContainerProps {
	icon?: IconComponent;
	isRemoveable?: boolean;
	textSx?: StyleProp;
}

const removeableStyles: StyleProp = {
	cursor: 'pointer',
	color: 'neutral000',
	bg: 'slate',
	border: 'none',

	':hover': {
		bg: 'slateDark',
	},
};

const disabledStyles: StyleProp = {
	cursor: 'default',
};

export const Tag: React.FC<TagProps> = ({
	children,
	icon,
	isRemoveable,
	maxWidth = '14em', // em, relative to the tags font-size
	variant = 'medium',
	textSx,
	sx = {},
	disabled,
	...props
}) => {
	const Icon = icon;

	const styles = useMemo<StyleProp>(
		() => ({
			display: 'inline-flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			borderRadius: 4,
			whiteSpace: 'nowrap',
			color: 'neutral800',
			bg: 'neutral200',
			...(isRemoveable ? removeableStyles : {}),
			...(disabled ? disabledStyles : {}),
		}),
		[isRemoveable, disabled],
	);

	return (
		<Container
			tx="tags"
			as={isRemoveable ? 'button' : 'div'}
			variant={variant}
			disabled={disabled}
			{...props}
			sx={{
				...styles,
				...sx,
			}}
		>
			{variant !== 'xSmall' && Icon ? (
				<Container flex="0 0 auto" display="flex" alignItems="center">
					<Icon data-tag-icon />
				</Container>
			) : null}

			<Text
				flex="1 1 auto"
				maxWidth={maxWidth}
				variant={'inherit'}
				sx={{ overflow: 'hidden', textOverflow: 'ellipsis', ...textSx }}
			>
				{children}
			</Text>
			{isRemoveable && !disabled && <IconRemove data-tag-icon-remove size="small" />}
		</Container>
	);
};
