/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ExperimentStepTargetQueryKeyAddedProperties,
	ExperimentStepTargetQueryKeyRemovedProperties,
	ExperimentStepTargetQueryKeyReplacedProperties,
	ampli,
} from 'ampli';
import StepTargetsBlastRadiusLegacy from 'pages/experiments/components/experimentStepEditAction/StepTargetsBlastRadiusLegacy';
import StepTargetsBlastRadius from 'pages/experiments/components/experimentStepEditAction/StepTargetsBlastRadius';
import { ActionVO, ExperimentStepActionVO, ExperimentStepRadiusVO, TargetPredicateTemplateVO } from 'ui-api';
import { IconChevronDoubleLeft, IconChevronDoubleRight, IconWarning } from 'components/icons';
import PredicateEditorLegacy from 'components/PredicateEditor/PredicateEditorLegacy';
import { isQueryLanguagePredicateVO } from 'components/PredicateEditor/utils';
import PredicateEditor from 'components/PredicateEditor/PredicateEditor';
import { Container, Stack, Text, ValueValidator } from 'components';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import Collapsible from 'components/Collapsible.v2/Collapsible.v2';
import { useCount } from 'pages/experiments/wizard/common/utils';
import useFeatureFlag from 'services/useFeatureFlag';
import { listPlaceholders } from 'templates/utils';
import { useFormikContext } from 'formik';
import { getLabel } from 'i18n/label';
import { ReactElement } from 'react';

import useIsExperimentDisabled from '../useIsExperimentDisabled';
import { useEditorSettings } from '../useEditorSettings';
import { ExperimentFormValues } from '../types';
import useFieldErrors from '../useFieldErrors';
import Split from './Split';

interface ActionTargetSelectionProps {
	setExpanded: (expanded: boolean) => void;
	actionStep: ExperimentStepActionVO;
	valueValidator?: ValueValidator;
	expanded: boolean;
	action: ActionVO;
	stepPath: string;
}

export default function ActionTargetSelection(props: ActionTargetSelectionProps): ReactElement | null {
	const { action, actionStep } = props;
	const targetType = action.target?.type;

	if (!targetType || !actionStep.blastRadius) {
		return null;
	}

	return <ActionTargetSelectionContent blastRadius={actionStep.blastRadius} targetType={targetType} {...props} />;
}

interface ActionTargetSelectionContentProps extends ActionTargetSelectionProps {
	blastRadius: ExperimentStepRadiusVO;
	targetType: string;
}

function ActionTargetSelectionContent({
	valueValidator,
	setExpanded,
	blastRadius,
	actionStep,
	targetType,
	expanded,
	stepPath,
	action,
}: ActionTargetSelectionContentProps): ReactElement | null {
	const disabled = useIsExperimentDisabled();
	const formik = useFormikContext<ExperimentFormValues>();
	const { environmentId, experimentKey } = formik.values;

	const targetDefinitions = useTargetDefinitions();
	const targetDefinition = (targetDefinitions.value || []).find((target) => target.id === targetType);
	const totalTargetCountsInEnvironment = useCount(environmentId);

	const stepErrors = useFieldErrors(`${stepPath}.blastRadius.predicate`);

	const { mode: editorMode } = useEditorSettings();
	const cannotDeterminCount =
		editorMode === 'templateEditor' && blastRadius.predicate && listPlaceholders(blastRadius.predicate).length > 0;

	const targetSelectonReworkEnabled = useFeatureFlag('targetSelectonReworkEnabled');

	return (
		<Stack size="none" bg={targetSelectonReworkEnabled ? 'neutral100' : 'neutral000'}>
			{targetSelectonReworkEnabled ? (
				<StepTargetsBlastRadius
					targetType={targetType}
					blastRadius={blastRadius}
					environmentId={environmentId}
					stepPath={stepPath}
					disabled={disabled}
					actionKind={action.kind}
					quantityRestriction={action.quantityRestriction}
				/>
			) : (
				<StepTargetsBlastRadiusLegacy
					targetType={targetType}
					blastRadius={blastRadius}
					environmentId={environmentId}
					stepPath={stepPath}
					disabled={disabled}
					actionKind={action.kind}
					quantityRestriction={action.quantityRestriction}
					expanded={expanded}
					setExpanded={setExpanded}
				/>
			)}
			<Collapsible
				title="Target Selection"
				backgroundColorExpanded={targetSelectonReworkEnabled ? 'neutral100' : 'neutral000'}
				backgroundColor={targetSelectonReworkEnabled ? 'neutral100' : 'neutral000'}
				titleColor="neutral800"
				initialExpanded
				borderless
			>
				<>
					{totalTargetCountsInEnvironment.value?.[targetType] === 0 && targetDefinition && (
						<Stack direction="horizontal" size="xSmall" pb="small">
							<IconWarning color="coral" />
							<Text color="neutral600">
								No {getLabel(targetDefinition.label, 0)} can be found in the selected environment.
							</Text>
						</Stack>
					)}
					<Container width="100%">
						{targetSelectonReworkEnabled ? (
							<PredicateEditor
								additionalActions={
									<Text
										variant="mediumStrong"
										sx={{
											color: cannotDeterminCount ? 'neutral400' : 'primary',
											cursor: cannotDeterminCount ? 'default' : 'pointer',
										}}
										onClick={() => {
											if (cannotDeterminCount) {
												return;
											}
											setExpanded(!expanded);
											ampli.experimentTargetsAttackedViewed({
												attack_query_defined: !!blastRadius.predicate,
												target_type: targetType,
											});
										}}
									>
										{expanded ? 'Hide' : 'Show'} Targets
										{expanded ? <IconChevronDoubleLeft ml="xSmall" /> : <IconChevronDoubleRight ml="xSmall" />}
									</Text>
								}
								disabled={disabled}
								predicate={blastRadius.predicate}
								setPredicate={(value) => {
									// if the predicate is a query language predicate and has no query, we need to set it to a predicate so it will fetch an empty result
									if (value && isQueryLanguagePredicateVO(value) && !value.query) {
										formik.setFieldValue(`${stepPath}.blastRadius.predicate`, {
											type: 'predicate',
										});
										formik.setFieldTouched(`${stepPath}.blastRadius.predicate`, true);
									} else {
										formik.setFieldValue(`${stepPath}.blastRadius.predicate`, value);
										formik.setFieldTouched(`${stepPath}.blastRadius.predicate`, true);
									}
								}}
								targetType={targetType}
								environmentId={environmentId}
								targetPredicateTemplates={action.targetPredicateTemplates || []}
								valueValidator={valueValidator}
								onTargetPredicateTemplateApplied={(template: TargetPredicateTemplateVO) =>
									ampli.experimentStepTargetSelectionTemplateApplied({
										action: actionStep.actionId,
										environment_id: environmentId,
										experiment_step_target_template_name: template.name,
										experiment_step_target_template_method: 'ui',
										experiment_key: experimentKey,
									})
								}
								onAttributeKeyChange={(key: string, change: 'added' | 'removed' | 'replaced') => {
									const targetQueryKeyChangeProps:
										| ExperimentStepTargetQueryKeyAddedProperties
										| ExperimentStepTargetQueryKeyRemovedProperties
										| ExperimentStepTargetQueryKeyReplacedProperties = {
										action: actionStep.actionId,
										environment_id: environmentId,
										experiment_key: experimentKey,
										experiment_step_target_key_changed: key,
									};
									if (change === 'added') {
										ampli.experimentStepTargetQueryKeyAdded({ ...targetQueryKeyChangeProps });
									} else if (change === 'removed') {
										ampli.experimentStepTargetQueryKeyRemoved({ ...targetQueryKeyChangeProps });
									} else if (change === 'replaced') {
										ampli.experimentStepTargetQueryKeyReplaced({ ...targetQueryKeyChangeProps });
									}
								}}
							/>
						) : (
							<PredicateEditorLegacy
								disabled={disabled}
								predicate={blastRadius.predicate}
								setPredicate={(value) => {
									// if the predicate is a query language predicate and has no query, we need to set it to a predicate so it will fetch an empty result
									if (value && isQueryLanguagePredicateVO(value) && !value.query) {
										formik.setFieldValue(`${stepPath}.blastRadius.predicate`, {
											type: 'predicate',
										});
										formik.setFieldTouched(`${stepPath}.blastRadius.predicate`, true);
									} else {
										formik.setFieldValue(`${stepPath}.blastRadius.predicate`, value);
										formik.setFieldTouched(`${stepPath}.blastRadius.predicate`, true);
									}
								}}
								targetType={targetType}
								environmentId={environmentId}
								targetPredicateTemplates={action.targetPredicateTemplates || []}
								valueValidator={valueValidator}
								onTargetPredicateTemplateApplied={(template: TargetPredicateTemplateVO) =>
									ampli.experimentStepTargetSelectionTemplateApplied({
										action: actionStep.actionId,
										environment_id: environmentId,
										experiment_step_target_template_name: template.name,
										experiment_step_target_template_method: 'ui',
										experiment_key: experimentKey,
									})
								}
								onAttributeKeyChange={(key: string, change: 'added' | 'removed' | 'replaced') => {
									const targetQueryKeyChangeProps:
										| ExperimentStepTargetQueryKeyAddedProperties
										| ExperimentStepTargetQueryKeyRemovedProperties
										| ExperimentStepTargetQueryKeyReplacedProperties = {
										action: actionStep.actionId,
										environment_id: environmentId,
										experiment_key: experimentKey,
										experiment_step_target_key_changed: key,
									};
									if (change === 'added') {
										ampli.experimentStepTargetQueryKeyAdded({ ...targetQueryKeyChangeProps });
									} else if (change === 'removed') {
										ampli.experimentStepTargetQueryKeyRemoved({ ...targetQueryKeyChangeProps });
									} else if (change === 'replaced') {
										ampli.experimentStepTargetQueryKeyReplaced({ ...targetQueryKeyChangeProps });
									}
								}}
							/>
						)}
					</Container>
					<>
						{stepErrors.map((_error, index) => (
							<Text key={index} variant="smallStrong" color="coral" data-formik-error>
								{_error}
							</Text>
						))}
					</>
				</>
			</Collapsible>

			<Split />
		</Stack>
	);
}
