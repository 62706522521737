/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { EnvironmentInClusterResponse, LandscapeViewVO, TargetPredicateVO } from 'ui-api';
import { Landscape, LandscapeTargetBase } from 'targets/Explore/types';
import axios from 'axios';

import { TargetsApi } from './targetsApi';

export class LandscapeV2Api {
	targetsApi: TargetsApi;

	constructor(targetsApi: TargetsApi) {
		this.targetsApi = targetsApi;
	}

	async getEnvironmentInClusterByPredicate(predicate: TargetPredicateVO): Promise<EnvironmentInClusterResponse> {
		return (await axios.post<EnvironmentInClusterResponse>('/ui/explore/cluster/environment/predicate', predicate))
			.data;
	}

	async getLandscape(predicate: TargetPredicateVO, environmentId: string, addAdviceData = false): Promise<Landscape> {
		const [landscapeTargetsResult, iconsResult] = await Promise.all([
			axios.post<LandscapeTargetBase[]>('/ui/explore/targets/stream', {
				predicate,
				environmentId,
			}),
			this.targetsApi.getTargetDefinitions(),
		]);

		const landscapeTargets = landscapeTargetsResult.data;
		const icons = iconsResult;

		const targets: LandscapeTargetBase[] = landscapeTargets.map((target) => {
			const landscapeTarget: LandscapeTargetBase = {
				type: target.type,
				name: target.name,
				attributes: target.attributes,
				adviceDone: 0,
				adviceRequireAction: 0,
				adviceRequireValidation: 0,
			};

			if (addAdviceData) {
				let adviceRequireAction = 0;
				let adviceRequireValidation = 0;
				let adviceDone = 0;

				for (let i = 0; i < landscapeTarget.attributes.length; i++) {
					const { key } = landscapeTarget.attributes[i];
					if (key === 'advice.status.action-needed') {
						adviceRequireAction++;
					} else if (key === 'advice.status.validation-needed') {
						adviceRequireValidation++;
					} else if (key === 'advice.status.implemented') {
						adviceDone++;
					}
				}

				landscapeTarget.adviceRequireAction = adviceRequireAction;
				landscapeTarget.adviceRequireValidation = adviceRequireValidation;
				landscapeTarget.adviceDone = adviceDone;
			}

			return landscapeTarget;
		});

		return {
			targets,
			icons: icons.map(({ id, icon }) => ({ type: id, icon: icon ?? '' })),
		};
	}

	async getViews(teamId: string): Promise<LandscapeViewVO[]> {
		const params = new URLSearchParams();
		params.append('teamId', teamId);
		return (await axios.get<LandscapeViewVO[]>('/ui/explore/views', { params })).data;
	}

	async getTemplateViews(): Promise<LandscapeViewVO[]> {
		return (await axios.get<LandscapeViewVO[]>('/ui/explore/view/templates')).data;
	}

	async getView(viewId: string | undefined, teamId: string, environmentId: string): Promise<LandscapeViewVO> {
		const params = new URLSearchParams();
		params.append('teamId', teamId);
		params.append('environmentId', environmentId);
		if (viewId) {
			params.append('viewId', viewId);
			return (await axios.get<LandscapeViewVO>('/ui/explore/view', { params })).data;
		} else {
			return (await axios.get<LandscapeViewVO>('/ui/explore/view/new', { params })).data;
		}
	}

	async createView(view: LandscapeViewVO): Promise<LandscapeViewVO> {
		return (await axios.put<LandscapeViewVO>('/ui/explore/view', view)).data;
	}

	async updateView(view: LandscapeViewVO): Promise<LandscapeViewVO> {
		return (await axios.post<LandscapeViewVO>('/ui/explore/view', view)).data;
	}

	async deleteView(viewId: string): Promise<LandscapeViewVO> {
		const params = new URLSearchParams();
		params.append('viewId', viewId);
		return (await axios.delete<LandscapeViewVO>('/ui/explore/view', { params })).data;
	}

	async test(environmentId: string): Promise<number> {
		const params = new URLSearchParams();
		params.append('environmentId', environmentId);
		return (
			await axios.get('/ui/explore/map/test', {
				params,
			})
		).data;
	}
}
