/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { IconEdit } from 'components/icons';
import { Stack, Text } from 'components';
import { ReactElement } from 'react';

interface DropDownLabelProps {
	label: string;
	canEdit: boolean;
	configLabel: string;
	onConfigureClick?: () => void;
}

export default function DropDownLabel({
	label,
	canEdit,
	configLabel,
	onConfigureClick,
}: DropDownLabelProps): ReactElement {
	return (
		<Stack direction="horizontal" justifyContent="space-between" width="100%">
			<Text variant="smallStrong">{label}</Text>
			{onConfigureClick && (
				<Text
					variant="small"
					onClick={onConfigureClick}
					sx={{
						color: 'slate',
						cursor: 'pointer',
						':hover': {
							textDecoration: 'underline',
						},
					}}
				>
					{!canEdit ? (
						configLabel
					) : (
						<>
							<IconEdit variant="small" /> {configLabel}
						</>
					)}
				</Text>
			)}
		</Stack>
	);
}
