/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { selectedAdviceIdParam, selectedTargetIdParam } from 'pages/advice/urlParams';
import { Redirect, Route, Switch } from 'url/router';
import { setParamValue } from 'url/utils';
import { useHistory } from 'url/hooks';
import { ReactElement } from 'react';

export default function PermalinkRedirects(): ReactElement {
	const { createHref } = useHistory();

	return (
		<Switch>
			<Route path="/permalink/advice/:base64Payload">
				{({ match }) => {
					if (!match) {
						return <Redirect to="/" />;
					}

					const base64Payload = match.params.base64Payload;
					const payload = JSON.parse(atob(base64Payload));
					const { environmentId, adviceId, targetType, targetName } = payload;

					return (
						<Redirect
							to={createHref((location) => {
								location.pathname = `/landscape/${environmentId}/advice`;

								setParamValue(location, selectedAdviceIdParam, adviceId);
								setParamValue(location, selectedTargetIdParam, {
									type: targetType,
									name: targetName,
								});
							})}
						/>
					);
				}}
			</Route>

			<Route path="*">
				<Redirect to="/" />
			</Route>
		</Switch>
	);
}
