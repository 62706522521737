/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { GetTargetsPageResponse, TargetTypeDescriptionVO } from 'ui-api';
import ListHeaderSearch from 'components/List/presets/ListHeaderSearch';
import ListHeaderTitle from 'components/List/presets/ListHeaderTitle';
import AttackedTargetIndicator from 'targets/AttackedTargetIndicator';
import { DataStreamResult } from 'utils/hooks/stream/result';
import ListHeader from 'components/List/presets/ListHeader';
import { NumberIndicator } from 'components';
import { toTitleCase } from 'utils/string';
import { getLabel } from 'i18n/label';
import { ReactElement } from 'react';

interface TargetsTableHeaderProps {
	targetDefinition: DataStreamResult<TargetTypeDescriptionVO | undefined>;
	fetchResult: DataStreamResult<GetTargetsPageResponse>;
	query: string;
	onChangeQuery: (query: string) => void;
}

export default function TargetsTableHeader({
	targetDefinition,
	fetchResult,
	query,
	onChangeQuery,
}: TargetsTableHeaderProps): ReactElement | null {
	if (!targetDefinition.value) {
		return null;
	}

	return (
		<ListHeader
			left={
				<ListHeaderTitle
					title={toTitleCase(getLabel(targetDefinition.value.label, fetchResult.value?.content.length))}
					IconOverride={<AttackedTargetIndicator targetType={targetDefinition.value.id} />}
				>
					{fetchResult.value && (
						<NumberIndicator variant="small" flexShrink={0}>
							{fetchResult.value.totalElements}
						</NumberIndicator>
					)}
				</ListHeaderTitle>
			}
			right={
				<ListHeaderSearch
					title={`Search ${getLabel(targetDefinition.value.label)}`}
					value={query}
					setValue={(v) => onChangeQuery(v)}
				/>
			}
		/>
	);
}
