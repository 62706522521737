/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, OptionTypeBase, Text } from 'components';
import { FieldHookConfig, useField } from 'formik';
import React from 'react';

import { FormCombobox, FormComboContainerProps } from '../Select';
import { find } from '../Select/utils';
import { hasError } from './utils';

type FormikComboboxOwnProps = {
	name: string;
	maxLength?: number;
	clearable?: boolean;
};

export type FormikComboContainerProps = FormikComboboxOwnProps &
	Omit<FormComboContainerProps, 'error' | 'touched' | 'value' | 'onChange' | keyof FormikComboboxOwnProps>;

export const FormikCombobox = React.forwardRef<HTMLDivElement, FormikComboContainerProps>(
	({ flex, my, mx, mt, mb, ml, mr, width, options, clearable, ...props }, ref) => {
		const [field, meta, { setValue }] = useField({ type: 'select', ...props } as FieldHookConfig<
			string | null | undefined
		>);

		const handleChange = React.useCallback(
			(o: OptionTypeBase | null | undefined) => {
				if (o) {
					setValue(o.value);
				} else {
					setValue(undefined);
				}
			},
			[setValue],
		);
		const selectedValue: OptionTypeBase | undefined = React.useMemo(
			() => find(options, field.value ? (o) => o.value === field.value : undefined) ?? undefined,
			[field.value, options],
		);
		const hasValidValue = selectedValue || !field.value;
		return (
			<Container
				display={'flex'}
				flexDirection={'column'}
				my={my}
				mx={mx}
				mt={mt}
				mb={mb}
				ml={ml}
				mr={mr}
				width={width}
				flex={flex}
			>
				<FormCombobox
					ref={ref}
					{...field}
					{...meta}
					options={options}
					value={{
						label: (hasValidValue ? selectedValue?.label : field.value) ?? '',
						value: (hasValidValue ? selectedValue?.value : field.value) ?? '',
					}}
					hasError={!hasValidValue || hasError(meta)}
					onChange={handleChange}
					clearable={clearable}
					{...props}
				/>
				{hasError(meta) ? (
					<Text mt={'xxSmall'} variant={'smallStrong'} color={'coral'} data-formik-error>
						{meta.error}
					</Text>
				) : null}
			</Container>
		);
	},
);
FormikCombobox.displayName = 'FormikCombobox';
