/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { UnitFieldUnit } from 'components';

const MILLIS = { label: 'Millisec.', value: 'ms' };
const SECONDS = { label: 'Seconds', value: 's' };
const MINUTES = { label: 'Minutes', value: 'm' };
const HOURS = { label: 'Hours', value: 'h' };
export const DURATION_UNITS: UnitFieldUnit[] = [MILLIS, SECONDS, MINUTES];

export function parseDuration(
	value?: string,
	fallback: UnitFieldUnit = SECONDS,
): { unit: UnitFieldUnit; value: number } {
	if (value) {
		const m = /^([+-]?\d+)([a-zA-Z]{0,2})$/.exec(value);
		if (m) {
			const i = Number.parseInt(m[1]);
			return {
				value: Number.isSafeInteger(i) ? i : 0,
				unit: DURATION_UNITS.find((u) => u.value === m[2]) ?? fallback,
			};
		}
	}
	return { value: 0, unit: DURATION_UNITS.find((u) => u.value === value) ?? fallback };
}

export function convertDurationToSeconds(s?: string): number | null {
	if (s) {
		const { value = 0, unit } = parseDuration(s);
		const v = Number(value);
		switch (unit) {
			case MILLIS:
				return v / 1000;
			case SECONDS:
				return v;
			case MINUTES:
				return v * 60;
			case HOURS:
				return v * 60 * 60;
			default:
				return 0;
		}
	}
	return null;
}

export function toDuration(millis: number): string {
	if (millis < 1000) {
		return `${millis}ms`;
	}
	if (millis < 60_000) {
		return `${Math.round(millis / 1000)}s`;
	}
	if (millis < 3_600_000) {
		const minutes = Math.round(millis / 60_000);
		const remainingSeconds = Math.round((millis % 60_000) / 1000);
		return `${minutes}m${remainingSeconds > 0 ? remainingSeconds + 's' : ''}`;
	}
	const hours = Math.round(millis / 3_600_000);
	const remainingMinutes = Math.round((millis % 3_600_000) / 60_000);
	const remainingSeconds = Math.round((millis % 60_000) / 1000);

	return `${hours}h${remainingMinutes > 0 ? remainingMinutes + 'm' : ''}${
		remainingSeconds > 0 ? remainingSeconds + 's' : ''
	}`;
}
