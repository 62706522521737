/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import Dropdown from 'components/Select/Dropdown/Dropdown';
import { CSSProperties, ReactElement, Ref } from 'react';
import { TextField } from 'components';

import TextFieldWithVariableHighlighting from './TextFieldWithVariableHighlighting';
import VariablesAndPlaceholders from './VariablesAndPlaceholders';
import FocussableWrapper from './FocussableWrapper';

interface TextProps {
	placeholder?: string;
	hasErrors?: boolean;
	disabled: boolean;
	value: string;
	setValue: (value: string) => void;
}

export default function Text({ value = '', setValue, ...props }: TextProps): ReactElement {
	return (
		<Input
			value={value}
			onChange={(value) => {
				setValue(value);
			}}
			{...props}
		/>
	);
}

interface InputProps {
	onChange: (value: string) => void;
	onFocus?: () => void;
	onBlur?: () => void;
	placeholder?: string;
	hasErrors?: boolean;
	sx?: CSSProperties;
	disabled: boolean;
	value: string;
}

export function Input({
	placeholder,
	hasErrors,
	disabled,
	value,
	sx,
	onChange,
	onFocus,
	onBlur,
}: InputProps): ReactElement {
	return (
		<Dropdown<string>
			placement="bottom-start"
			value={value}
			onValueChanged={onChange}
			renderComponent={({ showMenu, setShowMenu, value, ref, onValueChanged }) => {
				let component;
				if (!showMenu) {
					component = (
						<TextFieldWithVariableHighlighting
							value={String(value)}
							hasErrors={hasErrors}
							disabled={disabled}
							onClick={() => setShowMenu(true)}
						/>
					);
				} else {
					component = (
						<TextField
							value={value}
							type="text"
							onChange={(e) => onValueChanged?.(e.target.value)}
							placeholder={placeholder}
							hasError={hasErrors}
							disabled={disabled}
							autoFocus
							height="40px"
						/>
					);
				}

				return (
					<FocussableWrapper
						ref={ref as Ref<HTMLDivElement>}
						onFocus={() => {
							setShowMenu(true);
							onFocus?.();
						}}
						onBlur={() => {
							setShowMenu(false);
							onBlur?.();
						}}
						sx={sx}
						disabled={disabled}
					>
						{component}
					</FocussableWrapper>
				);
			}}
		>
			{({ selectItem, width }) => {
				return (
					<DropdownContentFrame>
						<VariablesAndPlaceholders width={width} selectItem={selectItem} />
					</DropdownContentFrame>
				);
			}}
		</Dropdown>
	);
}
