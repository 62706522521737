/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React from 'react';

export interface SettingsGroupItemProps extends ContainerProps {
	tree?: boolean;
	showDescriptionAsTooltip?: boolean;
}

const treeBeforeAfterStyles = {
	':not(:last-of-type)': {
		':before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: 8,
			left: -32,
			bottom: -37,
			width: 2,
			backgroundColor: 'neutral300',
		},
	},
	':after': {
		content: '""',
		display: 'block',
		position: 'absolute',
		left: -32,
		top: 36,
		width: 16,
		height: 2,
		backgroundColor: 'neutral300',
	},
};

export const SettingsGroupItem: React.FC<SettingsGroupItemProps> = ({ tree, children, sx = {}, ...props }) => {
	const treeStyles = tree ? treeBeforeAfterStyles : {};

	return (
		<Container
			py={'small'}
			px={'medium'}
			sx={{
				position: 'relative',
				...treeStyles,
				'[data-settings-group]': {
					border: 'none',
				},
				'[data-settings-group-item]': {
					px: 'none',
					py: 'medium',
					mx: 'medium',
				},
				'& + [data-settings-group-footer=right]': {
					borderLeft: '1px solid',
					borderColor: 'neutral200',
				},

				...sx,
			}}
			{...props}
			data-settings-group-item
		>
			{children}
		</Container>
	);
};
