/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useContext, createContext } from 'react';

type SettingsType = {
	workspaceWidth: number;
	pxPerSecond: number;
};

export const WorkspaceSettings = createContext<SettingsType>({
	workspaceWidth: 0,
	pxPerSecond: 10,
});

const useWorkspaceSettings = (): SettingsType => {
	return useContext(WorkspaceSettings);
};
export default useWorkspaceSettings;
