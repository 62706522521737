/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { IconEnvironment, IconEnvironmentGlobal } from 'components/icons';
import StepIndicators from 'components/StepIndicator/StepIndicator';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ReactElement, useEffect, useState } from 'react';
import { usePromise } from 'utils/hooks/usePromise';
import { Pill, Stack, Text } from 'components';
import { Services } from 'services/services';
import { useFormikContext } from 'formik';
import { theme } from 'styles.v2/theme';

import { UseTemplateFormData } from '../UseTemplateForm';
import { Placeholder } from './TemplatePlaceholder';
import { ampli } from '../../../../ampli';
import Footer from './Footer';
import Pages from './Pages';

export default function NavigationWithContent(): ReactElement {
	const [page, setPage] = useState(0);
	const { values } = useFormikContext<UseTemplateFormData>();
	const placeholders: Placeholder[] = values.placeholders.map(({ key, name, description }) => {
		const value = values.placeholderValuesMap.get(key) || '';
		return {
			description,
			value,
			name,
			key,
		};
	});
	const totalPages = placeholders.length + 1;

	useEffect(() => {
		ampli.experimentTemplateUsedWizardStepStarted({
			experiment_template_name: values.templateTitle,
			experiment_template_step: page + 1,
			experiment_template_last_step: page + 1 === totalPages,
			experiment_template_step_placeholder: page >= 1 ? placeholders[page - 1]?.name : undefined,
			experiment_template_total_steps: totalPages,
		});
		if (page > 0) {
			const completedPage = page - 1;
			ampli.experimentTemplateUsedWizardStepCompleted({
				experiment_template_name: values.templateTitle,
				experiment_template_step: completedPage + 1,
				experiment_template_last_step: completedPage === totalPages,
				experiment_template_step_placeholder: completedPage >= 1 ? placeholders[completedPage - 1]?.name : undefined,
				experiment_template_total_steps: totalPages,
			});
		}
	}, [page, totalPages]);

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: '278px 1fr',

				border: '1px solid ' + theme.colors.neutral300,
				marginBottom: '32px',
			}}
		>
			<div
				style={{
					padding: '24px 16px 32px',
					backgroundColor: theme.colors.neutral100,
				}}
			>
				<StepIndicators
					currentPage={page}
					steps={[
						{
							label: 'Select Environment',
							description: (currentPage: number, stepIndex: number) => {
								if (currentPage > stepIndex) {
									return <Environment id={values.environmentId} />;
								}
								return '';
							},
							onClick: () => setPage(0),
						},
						...placeholders.map(({ key, name, value }, index) => {
							return {
								label: name || key,
								description: () =>
									value ? (
										<Text variant="smallCode" color="neutral700" pl={7}>
											{value}
										</Text>
									) : (
										''
									),
								onClick: () => setPage(1 + index),
							};
						}),
					]}
				/>
			</div>
			<Stack size="none" sx={{ flexDirection: 'column-reverse' }}>
				<Footer page={page} pages={1 + placeholders.length} setPage={setPage} />
				<Stack sx={{ flexGrow: 1, p: 'medium', minHeight: '500px' }}>
					<Pages page={page} placeholder={placeholders[page - 1]} />
				</Stack>
			</Stack>
		</div>
	);
}

function Environment({ id }: { id: string }): ReactElement {
	const envResult = usePromise(() => Services.environments.fetchEnvironment(id), [id]);

	return (
		<Pill
			backgroundColorOnHover="neutral300"
			backgroundColor="neutral200"
			color="neutral800"
			sx={{
				height: '28px',
				fontSize: '12px',
				borderRadius: '14px',
				pr: 'small',
				overflowX: 'hidden',
				...textEllipsis,
			}}
		>
			{envResult.value?.global ? (
				<IconEnvironmentGlobal variant="small" mr="xxSmall" />
			) : (
				<IconEnvironment variant="small" mr="xxSmall" />
			)}
			<span style={{ ...textEllipsis }}>{envResult.value?.name || ''}</span>
		</Pill>
	);
}
