/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, CopyToClipboard, Heading, Link, Stack, Text, TextField } from 'components';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { IconHide, IconShow } from 'components/icons';
import { Services } from 'services/services';
import { useToggle } from 'react-use';
import React from 'react';

const PlatformSetup: React.VFC = () => {
	const [tenant] = useAsyncState(() => Services.tenants.getCurrentTenantWithAgentKey());
	const agentKey = tenant.value?.agentKey ?? '<agent-key>';
	const [showAgentKey, toggleShowAgentKey] = useToggle(false);

	return (
		<Container maxWidth={1024} mx="auto">
			<Stack size="large" mt="xLarge">
				<Heading variant={'large'}>Set Up Platform</Heading>
				<Stack direction={'horizontal'} alignItems="flex-start">
					<Container flex="1 1 auto">
						<Heading variant={'medium'}>Getting Started</Heading>
						<Text mt={'medium'}>
							You&apos;ve subscribed to the on-prem variant of steadybit. First you need to install the platform to your
							environment. After installing the platform we provide you with the steps to install the agents.
						</Text>
						<Text mt={'medium'}>
							There are multiple ways to install the platform, please consult the docs for the way it suits you most:
							<ul>
								<li>
									<Link
										href={process.env.REACT_APP_DOCS_URL + '/install-configure/40-install-platform/20-k8s'}
										target="_blank"
										rel="noopener"
									>
										Kubernetes (using Helm or kubectl)
									</Link>
								</li>
								<li>
									<Link
										href={process.env.REACT_APP_DOCS_URL + '/install-configure/40-install-platform/10-docker'}
										target="_blank"
										rel="noopener"
									>
										Docker
									</Link>
								</li>
							</ul>
						</Text>
					</Container>
					{!tenant.loading ? (
						<Container sx={{ backgroundColor: 'neutral200' }} p={'medium'} flex="0 0 auto">
							<Stack size={'medium'}>
								<Stack size="xSmall">
									<Heading variant={'small'}>Agent Key</Heading>
									<TextField
										name={'agentKey'}
										value={showAgentKey ? agentKey : '********'}
										readOnly
										iconRight={showAgentKey ? IconHide : IconShow}
										onIconRightClick={toggleShowAgentKey}
									/>
									<CopyToClipboard text={agentKey} />
								</Stack>
							</Stack>
						</Container>
					) : null}
				</Stack>
			</Stack>
		</Container>
	);
};

export default PlatformSetup;
