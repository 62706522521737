/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Parameters, LocationWithUrlParams } from 'url/type';
import { getParamValue, setParamValue } from 'url/utils';
import { stringify } from 'url/stringifier';
import { useLocation } from 'url/hooks';
import { parse } from 'url/jsurl2';
import { clone } from 'url/clone';
import { isEqual } from 'lodash';
import { useMemo } from 'react';

export type Direction = 'asc' | 'desc';
export type Order = [string] | [string, Direction] | [string, Direction, 'ignoreCase'];

interface PageParamsDefaults {
	page?: number;
	size?: number;
	sort?: Order[];
}

export type Criteria = URLSearchParams;

export const usePage = (
	pathSegment: string,
	defaults?: PageParamsDefaults,
	dependencies: unknown[] = [],
): PageLocation => {
	const location = useLocation();

	return useMemo(() => {
		return PageLocation.of(
			pathSegment,
			location,
			toCriteria(pathSegment, location),
			PageParams.fromLocation(pathSegment, location, defaults),
		);
	}, [location, pathSegment, ...dependencies]);
};

function toCriteria(pathSegment: string, location: LocationWithUrlParams<unknown>): URLSearchParams {
	const urlSearchParams = new URLSearchParams();
	const params = location.matrix[pathSegment];
	if (params) {
		for (const [key, value] of Object.entries(params)) {
			if (key !== 'page' && key !== 'size' && key !== 'sort') {
				urlSearchParams.append(key, parse(value));
			}
		}
	}
	return urlSearchParams;
}

export class PageLocation {
	readonly pathSegment: string;
	readonly location: LocationWithUrlParams<unknown>;
	readonly pageParams: PageParams;
	readonly criteria: Criteria;

	private constructor(
		pathSegment: string,
		location: LocationWithUrlParams<unknown>,
		criteria: Criteria,
		pageRequest: PageParams,
	) {
		this.pathSegment = pathSegment;
		this.location = location;
		this.criteria = criteria;
		this.pageParams = pageRequest;
	}

	static of(
		pathSegment: string,
		location: LocationWithUrlParams<unknown>,
		criteria: Criteria = new URLSearchParams(),
		params: PageParams,
	): PageLocation {
		return new PageLocation(pathSegment, location, criteria, params);
	}

	withPage(page: number): PageLocation {
		return new PageLocation(this.pathSegment, this.location, this.criteria, this.pageParams.withPage(page));
	}

	withSize(size: number): PageLocation {
		return new PageLocation(this.pathSegment, this.location, this.criteria, this.pageParams.withSize(size));
	}

	toggleSort(...sorts: Order[][]): PageLocation {
		return new PageLocation(this.pathSegment, this.location, this.criteria, this.pageParams.toggleSort(...sorts));
	}

	withCriterion(name: string, value?: string | string[] | null): PageLocation {
		const criteria = new URLSearchParams(this.criteria);
		if (value == null) {
			criteria.delete(name);
		} else if (Array.isArray(value)) {
			criteria.delete(name);
			value.forEach((v) => criteria.append(name, v));
		} else {
			criteria.set(name, value);
		}
		return new PageLocation(this.pathSegment, this.location, criteria, this.pageParams);
	}

	hasSort(sort: Order[]): boolean {
		return this.pageParams.hasSort(sort);
	}

	getDirection(asc: Order[], desc: Order[]): Direction | undefined {
		return this.pageParams.getDirection(asc, desc);
	}

	toString(alternativePathname?: string, additionalQuery?: Parameters): string {
		const location = clone(this.location);

		if (alternativePathname != null) {
			location.pathname = alternativePathname;
		}

		if (additionalQuery != null) {
			location.query = {
				...location.query,
				...additionalQuery,
			};
		}

		// clear previously existing criteria
		location.matrix[this.pathSegment] = {};

		for (const [name, value] of this.criteria.entries()) {
			setParamValue(
				location,
				{
					pathSegment: this.pathSegment,
					name,
				},
				value,
			);
		}

		setParamValue(
			location,
			{
				pathSegment: this.pathSegment,
				name: 'page',
			},
			this.pageParams.page,
		);

		setParamValue(
			location,
			{
				pathSegment: this.pathSegment,
				name: 'size',
			},
			this.pageParams.size,
		);

		setParamValue(
			location,
			{
				pathSegment: this.pathSegment,
				name: 'sort',
			},
			this.pageParams.sort,
		);

		return stringify(location) ?? '';
	}
}

export class PageParams {
	readonly page: number;
	readonly size: number;
	readonly sort: Order[];

	constructor(page = 0, size = 20, sort: Order[] = []) {
		this.page = page;
		this.size = size;
		this.sort = sort;
	}

	static of(pageRequest: {
		number: number;
		size: number;
		order: { property: string; direction: 'ASC' | 'DESC' }[];
	}): PageParams {
		const sort: Order[] = pageRequest.order.map((o) => [o.property, 'ASC' === o.direction ? 'asc' : 'desc']);
		return new PageParams(pageRequest.number ?? 0, pageRequest.size ?? 20, sort);
	}

	withSort(sort?: Order[]): PageParams {
		return new PageParams(0, this.size, sort);
	}

	withSize(size: number): PageParams {
		return new PageParams(this.page, size, this.sort);
	}

	withPage(page: number): PageParams {
		return new PageParams(page, this.size, this.sort);
	}

	toggleSort(...sorts: Order[][]): PageParams {
		return this.withSort(toggleSort(this.sort, ...sorts));
	}

	hasSort(sort: Order[]): boolean {
		return isEqual(this.sort, sort);
	}

	getDirection(asc: Order[], desc: Order[]): Direction | undefined {
		return getDirection(this.sort, asc, desc);
	}

	static fromLocation(
		pathSegment: string,
		location: LocationWithUrlParams<unknown>,
		defaults?: PageParamsDefaults,
	): PageParams {
		const page = getParamValue(location, {
			pathSegment,
			name: 'page',
			defaultValue: defaults?.page ?? 0,
		});

		const size = getParamValue(location, {
			pathSegment,
			name: 'size',
			defaultValue: defaults?.size ?? 20,
		});

		const sort = getParamValue(location, {
			pathSegment,
			name: 'sort',
			defaultValue: [] as Order[],
		});

		return new PageParams(page, size, sort.length > 0 ? sort : defaults?.sort);
	}

	toUrlSearchParams(init?: string[][] | Record<string, string> | string | URLSearchParams): URLSearchParams {
		return this.appendTo(new URLSearchParams(init));
	}

	appendTo(params: URLSearchParams): URLSearchParams {
		params.append('page', String(this.page));
		params.append('size', String(this.size));
		for (const order of this.sort) {
			if (typeof order === 'string') {
				params.append('sort', order);
			} else {
				params.append('sort', order.join(','));
			}
		}
		return params;
	}
}

export function toggleSort(active: Order[], ...sorts: Order[][]): Order[] {
	const index = sorts.findIndex((s) => isEqual(active, s));
	if (index >= 0 && index < sorts.length - 1) {
		return sorts[index + 1];
	} else {
		return sorts[0];
	}
}

export function getDirection(active: Order[], asc: Order[], desc: Order[]): Direction | undefined {
	if (isEqual(active, asc)) {
		return 'asc';
	} else if (isEqual(active, desc)) {
		return 'desc';
	} else {
		return undefined;
	}
}
