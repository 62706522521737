/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { customPropertyName } from 'components/Banner/BannerAccent';
import React, { ReactNode } from 'react';
import { Stack } from 'components/Stack';
import { theme } from 'styles.v2/theme';

import { bannerVariantThemes, BannerVariant } from '../Banner/Banner';
import { Container } from '../Container';
import { Text } from '../Text';

export interface MessageProps {
	variant?: BannerVariant;
	title?: ReactNode;
	children?: ReactNode;
	flex?: string;
}

export const Message: React.FC<MessageProps> = ({ variant = 'info', title, children, flex }) => {
	const variantTheme = bannerVariantThemes[variant];

	return (
		<Container
			as="section"
			flex={flex}
			sx={{
				padding: 'small',
				backgroundColor: variantTheme.backgroundColor,
				borderLeftWidth: 4,
				borderLeftStyle: 'solid',
				borderRadius: 3,
				borderColor: variantTheme.accent,
				color: theme.colors.neutral800,
				fontSize: '1',
				lineHeight: 'bodySmall',
				position: 'relative',

				a: {
					textDecoration: 'underline',
				},
			}}
		>
			<Stack size="xSmall" style={{ [customPropertyName]: variantTheme.accent } as React.CSSProperties}>
				{title != null && (
					<Text as="span" variant="mediumStrong" color={variantTheme.accent}>
						{title}
					</Text>
				)}
				{children}
			</Stack>
		</Container>
	);
};
