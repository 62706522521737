/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react';
import { Stack } from 'components';

interface ContentWrapperProps {
	children: React.ReactNode;
}

export default function ContentWrapper({ children }: ContentWrapperProps): ReactElement {
	return (
		<Stack minHeight="100%" size="small" pb="large" pt="xLarge" overflowY="auto">
			{children}
		</Stack>
	);
}
