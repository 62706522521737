/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import { Container, ContextualMenuText, FormCheckbox, Stack } from 'components';
import DropDownButton from 'components/Select/Dropdown/DropdownButton';
import { ExecutionLog, RawExecutionLog } from 'hocs/executionLog';
import { AsyncState } from 'utils/hooks/useAsyncState';
import { flatMapSteps } from 'services/experimentApi';
import { flatMap, orderBy, uniqBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { ExperimentExecutionVO } from 'ui-api';

import useTrackingExperimentExecutionViewed from './hooks/useTrackingExperimentExecutionViewed';

export const ExperimentExecutionAgentLogs: React.VFC<AsyncState<ExperimentExecutionVO | undefined>> = ({ value }) => {
	const logOptions = useMemo(() => {
		if (!value) {
			return [];
		}
		const options = flatMapSteps(value.lanes, (step) => {
			switch (step.type) {
				case 'action':
					return flatMap(step.targetExecutions, (targetExecution) => ({
						value: targetExecution.agentId,
						label: targetExecution.agentHostname,
					}));
				default:
					return [];
			}
		});

		return orderBy(uniqBy(options, 'value'), ['label'], ['asc']);
	}, [value]);

	useTrackingExperimentExecutionViewed(value, 'agent_log');
	const [logSelection, setLogSelection] = useState(logOptions.map((o) => o.value));
	return (
		<>
			<Stack
				direction={'horizontal'}
				justifyContent="space-between"
				size={'small'}
				pr={'medium'}
				py={'xSmall'}
				sx={{ boxShadow: 'applicationSmall', zIndex: 11, bg: 'neutral000' }}
			>
				{value && logOptions.length > 0 ? (
					<SelectAgentLogDropdown
						executionId={value.id}
						options={logOptions}
						value={logSelection}
						onChange={(option) => setLogSelection(option || null)}
					/>
				) : null}
			</Stack>
			{value ? (
				<ExecutionLog
					executionId={value.id}
					type={'AGENT'}
					id={logSelection}
					agentNames={logOptions}
					liveUpdate={!value.ended}
				/>
			) : null}
		</>
	);
};

type SelectAgentLogDropdownProps = {
	options: { label: string; value: string }[];
	executionId: number;
	value: string[];
	onChange: (v: string[]) => void;
};
const SelectAgentLogDropdown: React.VFC<SelectAgentLogDropdownProps> = ({ options, value, onChange, executionId }) => {
	return (
		<DropDownButton variant="secondary" value={`Showing ${value.length} of ${options.length} agent logs`}>
			{() => (
				<DropdownContentFrame>
					{options.map((option) => (
						<ContextualMenuText key={option.value}>
							<Container display={'flex'}>
								<FormCheckbox
									mr={'medium'}
									label={option.label}
									checked={value.includes(option.value)}
									onChange={(e) =>
										onChange(e.target.checked ? [...value, option.value] : value.filter((v) => v !== option.value))
									}
								/>
								<RawExecutionLog
									sx={{ ml: 'auto', fontSize: 1 }}
									executionId={executionId}
									id={option.value}
									type={'AGENT'}
								/>
							</Container>
						</ContextualMenuText>
					))}
				</DropdownContentFrame>
			)}
		</DropDownButton>
	);
};
