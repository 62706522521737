/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import React, { useMemo } from 'react';

import { UnitField, UnitFieldProps, UnitFieldUnit } from './UnitField';

const NANOS = { label: 'ns', value: 'ns' };
const MILLIS = { label: 'ms', value: 'ms' };
const SECONDS = { label: 's', value: 's' };
const MINUTES = { label: 'm', value: 'm' };
const HOURS = { label: 'h', value: 'h' };
const DAYS = { label: 'd', value: 'd' };
const DURATION_UNITS: UnitFieldUnit[] = [NANOS, MILLIS, SECONDS, MINUTES, HOURS, DAYS];

type ParsedValue = { unit: UnitFieldUnit; value?: string };

export type ParsingUnitFieldProps = Omit<UnitFieldProps, 'css' | 'unit' | 'onUnitChange' | 'onChange'> & {
	parse: (value?: string) => ParsedValue;
	onChange?: (v: ParsedValue) => void;
	unitSelectorDisabled?: boolean;
};

export const ParsingUnitField = React.forwardRef<HTMLInputElement, ParsingUnitFieldProps>(
	({ value, parse, onChange, ...props }, ref) => {
		const parsedValue = useMemo(() => parse(String(value)), [value, parse]);

		return (
			<UnitField
				ref={ref}
				value={parsedValue.value}
				unit={parsedValue.unit}
				units={DURATION_UNITS}
				onUnitChange={(unit) => onChange?.({ ...parsedValue, unit })}
				onChange={(e) => onChange?.({ ...parsedValue, value: e.target.value })}
				{...props}
			/>
		);
	},
);
ParsingUnitField.displayName = 'ParsingUnitField';
