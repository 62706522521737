/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ButtonIcon, RouterPagination, Stack, TableDataCell, TableErrorRow, TableRow, Text } from 'components';
import React, { ReactElement, useCallback, useState } from 'react';
import TableLoadingRow from 'components/Table/TableLoadingRow';
import { IconEdit, IconTrash } from 'components/icons';
import { AsyncState } from 'utils/hooks/useAsyncState';
import { GetTeamsPageResponse, TeamVO } from 'ui-api';
import { PageLocation } from 'utils/hooks/usePage';
import invokePromise from 'utils/ignorePromise';
import { useHistory } from 'url/hooks';

import { handleDeleteTeamClick } from './components/actions';
import Environments from './components/table/Environments';
import TeamTable from './components/table/TeamTable';
import TeamName from './components/table/TeamName';
import Members from './components/table/Members';

interface TeamContentProps {
	teams: AsyncState<GetTeamsPageResponse | undefined>;
	page: PageLocation;
	width: number;
}

export default function TeamContent({ width, teams, page }: TeamContentProps): ReactElement {
	const history = useHistory();
	const switchToArchived = useCallback(() => history.push('/settings/teams/archived'), [history]);

	const reduced = width < 1000;

	if (teams.error) {
		return (
			<TeamTable page={page}>
				<TableErrorRow error={`Error loading teams: ${teams.error?.message}`} />
			</TeamTable>
		);
	}

	if (teams.loading || !teams.value) {
		return (
			<TeamTable page={page}>
				<TableLoadingRow numColumns={4} />
				<TableLoadingRow numColumns={4} />
				<TableLoadingRow numColumns={4} />
			</TeamTable>
		);
	}

	return (
		<Stack direction={'vertical'} size={'large'}>
			<TeamTable page={page}>
				{teams.value.content.map((team) => (
					<TeamRow key={team.id} team={team} switchToArchived={switchToArchived} reduced={reduced} />
				))}
				{!teams.value.content.length && (
					<TableRow>
						<TableDataCell colSpan={5}>
							<Text muted>No Teams found.</Text>
						</TableDataCell>
					</TableRow>
				)}
			</TeamTable>
			<RouterPagination
				activePage={page.pageParams.page}
				totalPages={teams.value?.totalPages}
				to={(i) => page.withPage(i).toString()}
			/>
		</Stack>
	);
}

const TeamRow: React.FC<{ team: TeamVO; switchToArchived: () => void; reduced: boolean }> = ({ team, reduced }) => {
	const owners = team.members.filter((member) => member.role === 'OWNER');
	const members = team.members.filter((member) => member.role === 'MEMBER');
	const allowEdit = team._actions.includes('edit') || team._actions.includes('manage-members');
	const allowDelete = team._actions.includes('delete');
	const [isArchiving, setIsArchiving] = useState(false);
	const { push, createHref } = useHistory();

	return (
		<TableRow hoverable={true} height={54}>
			<TeamName team={team} />
			<Environments team={team} reduced={reduced} />
			<Members members={owners} reduced={reduced} />
			<Members members={members} reduced={reduced} />

			<TableDataCell justifyContent={'flex-end'}>
				{allowEdit && (
					<ButtonIcon
						onClick={() =>
							push(
								createHref((location) => {
									location.pathname = '/settings/teams/' + team.id;
								}),
							)
						}
						tooltip="Edit Team"
					>
						<IconEdit />
					</ButtonIcon>
				)}

				{allowDelete && (
					<ButtonIcon
						disabled={isArchiving}
						onClick={() => {
							setIsArchiving(true);
							invokePromise(async () => {
								await handleDeleteTeamClick(team);
								setIsArchiving(false);
							});
						}}
						tooltip="Delete Team"
					>
						<IconTrash />
					</ButtonIcon>
				)}
			</TableDataCell>
		</TableRow>
	);
};
