/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { DeleteIcon } from 'pages/templates/FromTemplateModal/TemplateDetails/common';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { theme } from 'styles.v2/theme';
import { Pill, Text } from 'components';
import { ReactElement } from 'react';

interface FreeTextProps {
	children: string;
	onDelete: () => void;
}

export default function FreeText({ children, onDelete }: FreeTextProps): ReactElement {
	return (
		<Pill
			backgroundColor="neutral000"
			color="neutral600"
			sx={{
				width: 'fit-content',
				height: '28px',
				borderRadius: '4px',
				border: '1px solid ' + theme.colors.neutral300,
				...textEllipsis,
			}}
		>
			<Text variant="mediumStrong">”{children}”</Text>
			<DeleteIcon color="slate" onClick={onDelete} />
		</Pill>
	);
}
