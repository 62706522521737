/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { useField } from 'formik';
import React from 'react';

import { FormCheckbox, FormCheckContainerProps } from '../Checkbox';

type FormikCheckboxOwnProps = {
	name: string;
	value?: string;
};

export type FormikCheckContainerProps = FormikCheckboxOwnProps &
	Omit<FormCheckContainerProps, 'error' | 'touched' | 'ref' | keyof FormikCheckboxOwnProps>;

export const FormikCheckbox = React.forwardRef<HTMLInputElement, FormikCheckContainerProps>((props, ref) => {
	const [field, meta] = useField({ type: 'checkbox', name: props.name, value: props.value });
	return <FormCheckbox {...field} {...meta} {...props} ref={ref} />;
});

FormikCheckbox.displayName = 'FormikCheckbox';
