/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { useEffect, useState } from 'react';
import { Subject } from 'rxjs';

const isAvailable$ = new Subject<boolean>();

export function usePlatformAvailability(): boolean {
	const [isAvailable, setIsAvailable] = useState(true);
	useEffect(() => {
		const subscription = isAvailable$.subscribe((_isAvailable) => {
			if (isAvailable !== _isAvailable) {
				setIsAvailable(_isAvailable);
			}
		});

		return () => {
			subscription.unsubscribe();
		};
	});

	return isAvailable;
}

export function setGlobalAvailability(_isAvailable: boolean): void {
	isAvailable$.next(_isAvailable);
}
