/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import QueryLanguageLexer from 'queryLanguage/parser/generated/QueryLanguageLexer';
import { tokenize } from 'queryLanguage/parser/tokenizer';
import * as monaco from 'monaco-editor-core';

class State implements monaco.languages.IState {
	clone(): monaco.languages.IState {
		return new State();
	}
	equals(): boolean {
		return true;
	}
}

export class TokensProvider implements monaco.languages.TokensProvider {
	getInitialState(): monaco.languages.IState {
		return new State();
	}

	tokenize(line: string): monaco.languages.ILineTokens {
		// So far we ignore the state, which is not great for performance reasons
		return tokensForLine(line);
	}
}

function tokensForLine(input: string): monaco.languages.ILineTokens {
	const tokens = tokenize(input);
	return new LineTokens(
		tokens.map(
			(token) => new Token(QueryLanguageLexer.symbolicNames[token.type] || 'UNKNOWN', token.start, token.stop),
		),
	);
}

class LineTokens implements monaco.languages.ILineTokens {
	endState: monaco.languages.IState;
	tokens: monaco.languages.IToken[];
	constructor(tokens: monaco.languages.IToken[]) {
		this.endState = new State();
		this.tokens = tokens;
	}
}

export class Token implements monaco.languages.IToken {
	scopes: string;
	startIndex: number;
	endIndex: number;
	constructor(ruleName: string, startIndex: number, endIndex: number) {
		this.scopes = ruleName;
		this.startIndex = startIndex;
		this.endIndex = endIndex;
	}
}
