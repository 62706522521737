/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import axios from 'axios';

export class SettingsApi {
	async isSupportAccessEnabled(): Promise<boolean> {
		return Boolean((await axios.get<string>('/ui/settings/support')).data);
	}

	async setSupportAccessEnabled(enabled: boolean): Promise<void> {
		await axios.post('/ui/settings/support', { enabled });
	}
}
