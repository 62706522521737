/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Heading, Link } from 'components';

export const TUTORIALS = {
	experiments: {
		gettingStarted: {
			id: 'experiments',
			title: <>Let&apos;s inject chaos into your system</>,
			children: (
				<>
					Create and run your first chaos experiment, so we can learn how your system reacts to turbulent conditions. To
					get you started, Steadybit has already discovered an overall picture of your system.
					<br />
					How about stressing host&apos;s memory or blackholing your network? Or just replay past incidents and see if
					you are safe now.
				</>
			),
			learnMore: `${process.env.REACT_APP_DOCS_URL}/`,
		},
		noEnvironmentSelected: {
			id: 'experiments-noEnvironmentSelected',
			children: (
				<>
					Select an environment to design
					<br /> an Experiment.
				</>
			),
		},
		wizardWellDone: {
			id: 'experiments-wizardWellDone',
			children: (
				<>
					Well done! All settings are made and <br />
					the experiment is ready to be executed.
				</>
			),
		},
	},
	settings: {
		agents: {
			id: 'settings-agents',
			title: 'What is an agent and how to install it?',
			children: (
				<>
					For analysing your system, steadybit uses an agent-based approach. Thereby, the agents run on your
					infrastructure, establish a connection to the Steadybit platform and send discovery data about your system to
					it. So, first step to start with experiments is to install the agents in your system.
				</>
			),
			learnMore: `${process.env.REACT_APP_DOCS_URL}/install-configure/30-install-agents`,
		},
		environments: {
			id: 'settings-environmentss',
			title: 'What belongs together comes together!',
			children: (
				<>
					Your system is discovered automatically by Steadybit, but slicing it right is a job for you.
					<br />
					Set up environments to assign a set of discovered targets to a team. This ensures that teams can only run
					experiments against their own infrastructure and don&apos;t interfere with other teams.
				</>
			),
			learnMore: `${process.env.REACT_APP_DOCS_URL}/install-configure/50-set-up-environments`,
		},
		environmentStartDefinition: {
			id: 'startEnvironmentDefinition-noClusterSelected',
			children: (
				<>
					First, select a cluster in which you want to <br />
					create an environment.
				</>
			),
		},
		apiTokens: {
			id: 'settings-api-tokens',
			title: <>Let&apos;s automate!</>,
			children: (
				<>
					You want to integrate Steadybit into your existing pipelines and automation processes? We provide you with the
					API for that.
					<br />
					<br />
					<Heading variant={'small'}>API</Heading>
					Learn more by reading our{' '}
					<Link href={`${window.location.origin}/api/spec`} target={'_blank'}>
						OpenAPI specification
					</Link>{' '}
					or simply try it out with our{' '}
					<Link href={`${window.location.origin}/api/swagger`} target={'_blank'}>
						interactive API
					</Link>
					. In case you need more help on how to get started, have a look at our
					<Link href={`${process.env.REACT_APP_DOCS_URL}/integrate-with-steadybit/api`} target={'_blank'}>
						{' '}
						documentation
					</Link>{' '}
					to learn more.
					<Heading variant={'small'}>CLI</Heading>
					If you want to use our CLI to link your repository with Steadybit, have a look at our{' '}
					<Link href={`${process.env.REACT_APP_DOCS_URL}/integrate/15-cli`} target={'_blank'}>
						{' '}
						CLI documentation{' '}
					</Link>
					or in the corresponding{' '}
					<Link href={'https://github.com/steadybit/cli'} target={'_blank'}>
						{' '}
						GitHub repository of the CLI
					</Link>
					.
				</>
			),
			learnMore: `${process.env.REACT_APP_DOCS_URL}/integrate/10-api`,
		},
		integrations: {
			id: 'settings-integrations',
			title: 'Receive notifications in your tool of choice',
			children: (
				<>
					You can integrate Steadybit with any tool capable of being triggered via HTTP. One example would be to receive
					a message in Slack whenever an experiment is executed, canceled or stopped as well as when the killswitch was
					engaged.
					<br />
					But that&apos;s just something we had in mind, we are sure you have plenty other great ideas of customization.
				</>
			),
			learnMore: `${process.env.REACT_APP_DOCS_URL}/integrate/50-webhooks`,
		},
		teams: {
			id: 'settings-teams',
			title: 'A home for users, environments, attacks and experiments',
			children: (
				<>
					Ever wanted to give access to specific environments, experiments and allowed attacks to a set of users?
					That&apos;s exactly where teams come in handy. With the help of teams you can specify who is allowed to modify
					experiments, execute an experiment for an environment as well as which attacks can be performed. All others
					have only read only access.
				</>
			),
			learnMore: `${process.env.REACT_APP_DOCS_URL}/install-configure/60-teams-and-users`,
		},
	},
};
