/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps, Stack, StackProps } from 'components';
import React from 'react';

export interface SubnavProps extends ContainerProps {
	stackProps?: StackProps;
}

export const Subnav: React.FC<SubnavProps> = ({ children, maxWidth = 1024, sx = {}, stackProps, ...props }) => {
	return (
		<Container
			as="nav"
			bg="neutral000"
			height="48px"
			width="100%"
			sx={{
				borderBottom: '1px solid',
				borderColor: 'neutral200',
				...sx,
			}}
			{...props}
		>
			<Stack
				maxWidth={maxWidth}
				mx={'auto'}
				direction="horizontal"
				alignItems={'center'}
				height={'100%'}
				{...stackProps}
			>
				{children}
			</Stack>
		</Container>
	);
};
