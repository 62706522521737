/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { OptionTypeBase, Select, SelectProps } from 'components';
import React from 'react';

interface StateSelectProps extends Omit<SelectProps<OptionTypeBase>, 'value' | 'onChange' | 'options'> {
	value?: string | null;
	onChange?: (v: string | null) => void;
	name?: string;
	placeholder?: string;
}

const STATE_OPTION_ALL = { label: 'All', value: null };
const STATE_OPTION_COMPLETED = { label: 'Completed', value: 'COMPLETED' };
const STATE_OPTION_FAILED = { label: 'Failed', value: 'FAILED' };
const STATE_OPTION_ERRORED = { label: 'Errored', value: 'ERRORED' };
const STATE_OPTION_CANCELED = { label: 'Canceled', value: 'CANCELED' };
const STATE_OPTIONS = [
	STATE_OPTION_ALL,
	STATE_OPTION_COMPLETED,
	STATE_OPTION_FAILED,
	STATE_OPTION_ERRORED,
	STATE_OPTION_CANCELED,
];

export const StateSelect: React.VFC<StateSelectProps> = ({ value, onChange, ...props }) => {
	return (
		<Select
			label={'State'}
			inlineLabel
			options={STATE_OPTIONS}
			value={STATE_OPTIONS.find((a) => value === a.value) ?? null}
			onChange={(option) => onChange?.(option && 'value' in option ? option.value : null)}
			{...props}
		/>
	);
};
