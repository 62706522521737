/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	Container,
	ContextualMenu,
	Divider,
	Heading,
	RouterButton,
	RouterLink,
	SettingsGroup,
	SettingsGroupItem,
	SettingsGroupProps,
	ShortenedText,
	Text,
} from 'components';
import { IconEnvironment, IconEnvironmentGlobal, IconCheck, IconSettings } from 'components/icons';
import DropDownButton from 'components/Select/Dropdown/DropdownButton';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import { EnvironmentSummaryVO } from 'ui-api';
import React, { useEffect } from 'react';

type EnvironmentDropDownProps = SettingsGroupProps & {
	onChangeEnvironment: (environmentId: string) => void;
	selectedEnvironmentId: string | undefined;
};
const EnvironmentDropDown: React.VFC<EnvironmentDropDownProps> = ({
	selectedEnvironmentId,
	onChangeEnvironment,
	disabled,
	...props
}) => {
	const {
		globalEnvironment,
		isGlobalSelected,
		onlyGlobalEnvironmentAvailableForCurrentTeam,
		selectedEnvironment,
		defaultSelectedEnvironment,
		environmentsOfCurrentTeam,
		isAdmin,
		environmentsIsLoading,
	} = useEnvironments(selectedEnvironmentId);

	useEffect(() => {
		if (!selectedEnvironmentId && defaultSelectedEnvironment) {
			onChangeEnvironment(defaultSelectedEnvironment.id);
		}
	}, [defaultSelectedEnvironment, selectedEnvironmentId, onChangeEnvironment]);

	return (
		<DropDownButton
			variant="secondary"
			customContent={
				<>
					<Text as={'span'} flex={'1 0 auto'} variant={'smallStrong'} mr={'xxSmall'} data-select-inline-label>
						Environment:
					</Text>
					{isGlobalSelected ? (
						<IconEnvironmentGlobal variant={'small'} flex={'1 0 auto'} />
					) : (
						<IconEnvironment variant={'small'} flex={'1 0 auto'} />
					)}
					<ShortenedText variant={'small'} sx={{ whiteSpace: 'nowrap' }} mr={'xSmall'}>
						{selectedEnvironment?.name}
					</ShortenedText>
				</>
			}
		>
			{({ selectItem }) => (
				<ContextualMenu py={0} minWidth={256}>
					{environmentsOfCurrentTeam.length > 0 ? (
						<>
							<SettingsGroup {...props} sx={{ border: 'none', maxWidth: 320, maxHeight: 600, overflowY: 'auto' }}>
								{environmentsOfCurrentTeam[0].id === globalEnvironment?.id && (
									<>
										<SettingsGroupItem
											px={12}
											py={'medium'}
											key={globalEnvironment.id}
											sx={{
												':hover': {
													bg: 'neutral100',
												},
											}}
											onClick={
												!disabled
													? () => {
															selectItem(globalEnvironment.id);
															onChangeEnvironment(globalEnvironment.id);
													  }
													: undefined
											}
										>
											<Container
												display={'flex'}
												alignItems={'center'}
												justifyContent={'space-between'}
												sx={{
													cursor: disabled ? 'initial' : 'pointer',
												}}
											>
												<Container
													display={'flex'}
													alignItems={'flex-start'}
													sx={{
														cursor: disabled ? 'initial' : 'pointer',
													}}
												>
													<IconEnvironmentGlobal mt={3} flex={'0 0 auto'} variant={'small'} />
													<Text
														ml={5}
														as={'label'}
														variant={isGlobalSelected ? 'mediumStrong' : 'medium'}
														sx={{
															cursor: disabled ? 'initial' : 'pointer',
														}}
													>
														{globalEnvironment.name}
													</Text>
												</Container>
												{selectedEnvironmentId === globalEnvironment.id && <IconCheck flex={'0 0 auto'} />}
											</Container>
										</SettingsGroupItem>
										<Divider />
									</>
								)}
								{!onlyGlobalEnvironmentAvailableForCurrentTeam || isAdmin ? (
									<Container
										display={'flex'}
										px={12}
										py={'xSmall'}
										flexDirection={'row'}
										justifyContent={'space-between'}
										alignItems={'center'}
										sx={{
											gap: '16px',
										}}
									>
										<Heading variant={'small'}>Environments</Heading>
										{isAdmin && (
											<RouterButton to={'/settings/environments'} variant={'secondarySmall'}>
												<IconSettings variant="small" ml="-xxSmall" mr="xxSmall" /> Environments
											</RouterButton>
										)}
									</Container>
								) : null}
								{environmentsOfCurrentTeam
									.filter((environment) => environment.id !== globalEnvironment?.id)
									.map((environment) =>
										renderEnvironment(selectedEnvironmentId, environment, disabled, (id) => {
											selectItem(id);
											onChangeEnvironment(id);
										}),
									)}
								{onlyGlobalEnvironmentAvailableForCurrentTeam && (
									<>
										<Text px={12} pt={'small'} variant={'small'} color={'neutral600'}>
											Overwhelmed by the size of your system?
										</Text>
										<Text px={12} pb={'xSmall'} variant={'small'} color={'neutral600'}>
											<RouterLink to={'/settings/environments'}>Create an environment </RouterLink>to divide it into
											smaller pieces
										</Text>
									</>
								)}
							</SettingsGroup>
						</>
					) : environmentsIsLoading ? null : (
						<Text variant={'small'} color={'coralDark'} my={'xSmall'} px={'small'}>
							No Environments allowed.
						</Text>
					)}
				</ContextualMenu>
			)}
		</DropDownButton>
	);
};

const renderEnvironment = (
	selectedEnvironmentId: string | undefined,
	environment: EnvironmentSummaryVO,
	disabled: boolean | undefined,
	onChangeEnvironment: (environmentId: string) => void,
): JSX.Element => {
	const checked = selectedEnvironmentId === environment.id;
	return (
		<SettingsGroupItem
			sx={{
				cursor: disabled ? 'initial' : 'pointer',
				':hover': {
					bg: 'neutral100',
				},
			}}
			py={6}
			px={12}
			key={environment.id}
			onClick={
				!disabled
					? () => {
							onChangeEnvironment(environment.id);
					  }
					: undefined
			}
		>
			<Container
				display={'flex'}
				alignItems={'center'}
				justifyContent={'space-between'}
				sx={{
					cursor: disabled ? 'initial' : 'pointer',
				}}
			>
				<Container display={'flex'} alignItems={'flex-start'}>
					<IconEnvironment mt={3} flex={'0 0 auto'} variant={'small'} />
					<ShortenedText
						ml={5}
						as={'label'}
						variant={checked ? 'mediumStrong' : 'medium'}
						sx={{ cursor: disabled ? 'initial' : 'pointer', whiteSpace: 'nowrap' }}
					>
						{environment.name}
					</ShortenedText>
				</Container>
				{checked && <IconCheck flex={'0 0 auto'} />}
			</Container>
		</SettingsGroupItem>
	);
};

export default EnvironmentDropDown;
