/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Image, ImageProps } from 'components';
import { IconUser } from 'components/icons';
import React from 'react';

export type UserIconProps = ImageProps;

export const UserIcon = React.forwardRef<HTMLImageElement, UserIconProps>(
	({ variant = 'medium', src, children, sx = {}, ...props }, ref) => {
		if (!src) {
			return (
				<div ref={ref}>
					<IconUser
						variant={variant}
						tx="userIcon"
						p="xxSmall"
						backgroundColor="#2a81a6"
						color="neutral100"
						sx={{
							minWidth: 28,
							minHeight: 28,
							maxWidth: 28,
							maxHeight: 28,
							borderRadius: '50%',
							...sx,
						}}
						{...props}
					/>
				</div>
			);
		}

		return (
			<Image
				ref={ref}
				tx="userIcon"
				variant={variant}
				src={src}
				sx={{
					boxSizing: 'content-box',
					borderRadius: 9999,
					...sx,
				}}
				{...props}
			>
				{children}
			</Image>
		);
	},
);
UserIcon.displayName = 'UserIcon';
