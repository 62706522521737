/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { IconComponent } from 'components/icons';
import { Pill, Stack, Text } from 'components';
import { ReactElement } from 'react';

interface TagProps {
	Icon: IconComponent;
	label: string;
	number: number;
}

export default function Tag({ Icon, label, number }: TagProps): ReactElement {
	return (
		<Stack
			size="xxSmall"
			direction="horizontal"
			sx={{
				alignItems: 'center',
			}}
		>
			<Icon variant="xSmall" color="neutral800" />
			<Text variant="smallStrong" color="neutral800" pr="xxSmall">
				{label}
			</Text>
			<Pill backgroundColor="cyan200" backgroundColorOnHover="cyan800" color="cyan800">
				{number}
			</Pill>
		</Stack>
	);
}
