/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import { useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import Dropdown from 'components/Select/Dropdown/Dropdown';
import { ReactElement, Ref } from 'react';
import { TextField } from 'components';

import VariablesAndPlaceholders from './VariablesAndPlaceholders';

interface TextAreaProps {
	hasErrors?: boolean;
	disabled: boolean;
	value: string;
	setValue: (value: string) => void;
}

export default function TextArea({ value, hasErrors, disabled, setValue }: TextAreaProps): ReactElement {
	const { mode } = useEditorSettings();

	if (mode === 'experiment') {
		return (
			<TextField
				as="textarea"
				value={value || ''}
				onChange={(e) => {
					setValue(e.target.value);
				}}
				hasError={hasErrors}
				disabled={disabled}
			/>
		);
	}

	return (
		<Dropdown<string>
			value={value || ''}
			onValueChanged={(v) => {
				setValue(v);
			}}
			renderComponent={({ setShowMenu, value, ref, onValueChanged }) => {
				return (
					<div
						ref={ref as Ref<HTMLDivElement>}
						onFocus={() => setShowMenu(true)}
						onBlur={() => setShowMenu(false)}
						style={{ width: '100%' }}
					>
						<TextField
							as="textarea"
							value={value}
							onChange={(e) => {
								onValueChanged?.(e.target.value);
							}}
							hasError={hasErrors}
							disabled={disabled}
						/>
					</div>
				);
			}}
		>
			{({ selectItem, width }) => {
				return (
					<DropdownContentFrame>
						<VariablesAndPlaceholders width={width} selectItem={selectItem} />
					</DropdownContentFrame>
				);
			}}
		</Dropdown>
	);
}
