/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import FieldValueHandler from 'pages/experimentsV2/StepConfigurationSidebar/Fields/Field';
import { listPlaceholderKeyValuePairs } from 'templates/utils';
import { listVariableKeyValuePairs } from 'utils/envVars';
import { ReactElement, useState } from 'react';
import { ExperimentStepWaitVO } from 'ui-api';
import { Stack } from 'components';

interface WaitParametersProsp {
	waitStep: ExperimentStepWaitVO;
	environmentVariable?: string;
	placeholder?: string;
	stepPath: string;
}

export default function WaitParameters({
	environmentVariable,
	placeholder,
	stepPath,
	waitStep,
}: WaitParametersProsp): ReactElement {
	const { customLabel, parameters } = waitStep;

	const [fromCustomLabel] = useState(() =>
		environmentVariable ? [] : listPlaceholderKeyValuePairs({ customLabel }, placeholder),
	);

	const [fromParameters] = useState(() =>
		environmentVariable
			? listVariableKeyValuePairs(parameters, environmentVariable)
			: listPlaceholderKeyValuePairs(parameters, placeholder),
	);

	return (
		<Stack>
			{fromCustomLabel.length > 0 && (
				<FieldValueHandler
					field={{
						name: 'customLabel',
						label: 'Label',
						type: 'string',
						required: true,
						readonly: true,
					}}
					path={`${stepPath}.customLabel`}
					disabled
				/>
			)}
			{fromParameters.length > 0 && (
				<FieldValueHandler
					field={{
						name: 'duration',
						label: 'Duration',
						type: 'duration',
						required: true,
					}}
					path={`${stepPath}.parameters.duration`}
					disabled
				/>
			)}
		</Stack>
	);
}
