/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { LinkButton, Stack, Text } from 'components';
import { ReactElement } from 'react';

export default function ExtensionCallout(): ReactElement {
	return (
		<Stack
			size="xSmall"
			alignItems="flex-start"
			m="small"
			p="small"
			backgroundColor="neutral100"
			sx={{ borderRadius: 8 }}
		>
			<Text variant="smallStrong">Extend Steadybit!</Text>
			<Text muted variant="small">
				Can&apos;t find what you&apos;re looking for? You can extend Steadybit&apos;s ability to attack, check and load
				test targets through extensions. Learn how to install an extension or author your own!
			</Text>
			<LinkButton
				href="https://docs.steadybit.com/integrate-with-steadybit/extensions"
				variant="secondarySmall"
				external
				target="_blank"
				data-track="get-extensions-from-experiment-designer-sidebar"
			>
				Extend Steadybit
			</LinkButton>
		</Stack>
	);
}
