/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Code, CopyToClipboard, Heading, Message, Stack } from 'components';
import { ReactElement } from 'react';

interface AccessTokenFormProps {
	accessToken?: string;
}

export function AccessTokenDisplay({ accessToken }: AccessTokenFormProps): ReactElement | null {
	if (!accessToken) {
		return null;
	}

	const command = `curl \\\n -H"Authorization: accessToken ${accessToken}"\\\n -H"Accept: application/json"\\\n ${window.location.origin}/api/teams`;
	return (
		<Stack size={'large'}>
			<Stack size={'xSmall'}>
				<Heading variant="small">Token</Heading>
				<Message variant={'warning'}>
					Make sure to copy your new access token now. You won’t be able to see it again!
				</Message>
				<Code data-private data-hj-suppress>
					{accessToken}
				</Code>
				<CopyToClipboard text={accessToken} />
			</Stack>

			<Stack size={'xSmall'}>
				<Heading variant="xSmall">Test your access token using curl</Heading>
				<Code data-private data-hj-suppress>
					{command}
				</Code>
				<CopyToClipboard text={command} />
			</Stack>
		</Stack>
	);
}
