/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { AsyncState, useAsyncState } from 'utils/hooks/useAsyncState';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import { Services } from 'services/services';
import { TargetPredicateVO } from 'ui-api';
import debounce from 'debounce-promise';
import { useMemo } from 'react';

export const useCount = (
	environmentId?: string,
	predicate?: TargetPredicateVO,
): AsyncState<Record<string, number> | undefined> => {
	const targetDefinitions = useTargetDefinitions();

	const debounced = useMemo(
		() =>
			debounce(
				async (environmentId, predicate): Promise<Record<string, number>> => {
					return environmentId ? await Services.targets.countTargets(environmentId, predicate) : {};
				},
				350,
				{ leading: true },
			),
		[],
	);

	return useAsyncState(async () => {
		if (!targetDefinitions.value) {
			return undefined;
		}

		const count: Record<string, number> = await debounced(environmentId, predicate);

		// We have several places in the UI which require there to be a value for specific target types.
		// This is why we have the extra dependency on the target definitions.
		return targetDefinitions.value.reduce(
			(agg, t) => {
				agg[t.id] = count[t.id] ?? 0;
				return agg;
			},
			{} as Record<string, number>,
		);
	}, [environmentId, predicate, targetDefinitions])[0];
};
