/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { digestMessage } from 'tracking/hashUtil';
import { CurrentUserVO, TenantVO } from 'ui-api';
import { useTenant } from 'tenancy/useTenant';
import { useUser } from 'services/usersApi';
import { useEffect } from 'react';

import { isSteadybitOrPartnerUser } from './ignoredEmailAddresses';
import invokePromise from '../utils/ignorePromise';

declare global {
	interface Window {
		INJECTED_LOGROCKET_ID?: string;
	}
}

async function initLogRocket(user: CurrentUserVO, tenant: TenantVO): Promise<void> {
	if (!window.INJECTED_LOGROCKET_ID) {
		return;
	}
	const LogRocket = (await import('logrocket')).default;
	LogRocket.init(window.INJECTED_LOGROCKET_ID, {
		console: {
			isEnabled: false,
		},
		network: {
			isEnabled: false,
		},
		browser: {
			// no browser URL tracking
			urlSanitizer: () => {
				return '';
			},
		},
	});
	LogRocket.identify(`${user.username}@${tenant.key}`, {
		tenant: tenant.key,
		username: user.username,
		amplitudeUserId: await digestMessage(user.email),
	});
}

export default function useLogRocket(): void {
	const tenant = useTenant();
	const user = useUser();

	const isLogRocketDisabled = (): boolean => {
		return tenant.enabledFeatures.includes('disable_log_rocket');
	};

	useEffect(() => {
		if (
			tenant.key &&
			tenant.key !== 'demo' &&
			tenant.key !== 'demo-2' &&
			tenant.key !== 'steadybit' &&
			!isSteadybitOrPartnerUser(user) &&
			!isLogRocketDisabled()
		) {
			invokePromise(() => initLogRocket(user, tenant));
		}
	}, [user, tenant]);
}
