/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { without } from 'lodash';

import { FormCheckbox } from './FormCheckbox';

export type OptionTypeBase = {
	value: string | null;
	label: string;
	disabled?: boolean;
	hasError?: boolean;
};
export type CheckboxOption<OptionType extends OptionTypeBase> = OptionType;

export type FormCheckboxGroupProps<OptionType extends OptionTypeBase> = {
	value?: CheckboxOption<OptionType>[];
	options: CheckboxOption<OptionType>[];
	onChange?: (value: CheckboxOption<OptionType>[]) => void;
	disabled?: boolean;
};

export function FormCheckboxGroup<OptionType extends OptionTypeBase>({
	value = [],
	options,
	onChange,
	disabled,
}: FormCheckboxGroupProps<OptionType>): JSX.Element {
	return (
		<>
			{options.map((option) => (
				<FormCheckbox
					key={option.value}
					label={option.label}
					hasError={option.hasError}
					checked={value.includes(option)}
					disabled={disabled || option.disabled}
					onChange={(e) => onChange?.(e.target.checked ? [...value, option] : without(value, option))}
				/>
			))}
		</>
	);
}
