/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { userConfirmV2 } from 'components';

export async function templateImportConflictConfirm(numberOfTemplates: number): Promise<boolean> {
	const entityName = numberOfTemplates === 1 ? 'template' : 'templates';
	const userResponse = await userConfirmV2({
		title: `Conflict importing ${entityName}`,
		message:
			numberOfTemplates === 1
				? 'This template already exists. Do you want to overwrite it?'
				: 'Some templates already exist. Do you want to overwrite them?',
		actions: [
			{
				value: 'confirm',
				label: `Overwrite template${numberOfTemplates === 1 ? '' : 's'}`,
				variant: 'primary',
			},
		],
		secondaryActions: [{ value: 'cancel', label: 'No' }],
		width: '500px',
	});

	return userResponse === 'confirm';
}
