/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Target } from 'pages/components/Target';
import { TargetTypeDescriptionVO } from 'ui-api';
import { ReactElement } from 'react';
import { Text } from 'components';

interface TargetsProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	targets: string[];
}

export default function Targets({ targets, targetDefinitions }: TargetsProps): ReactElement | null {
	if (!targets || targets.length === 0) {
		return null;
	}

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateRows: '24px 1fr',
				gap: '6px',
			}}
		>
			<Text variant="small" color="neutral800">
				Targets:
			</Text>

			<div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
				{targets.map((target, i) => {
					return <Target key={i} targetDefinitions={targetDefinitions} targetId={target} />;
				})}
			</div>
		</div>
	);
}
