/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, Container, Divider, Stack, Text, Tooltip } from 'components';
import { GetUrlWithState, UrlParam, useUrlState } from 'url/useUrlState';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import Collapsible from 'components/Collapsible.v2/Collapsible.v2';
import ReactCopyToClipboard from 'react-copy-to-clipboard';
import Markdown from 'components/Markdown/Markdown';
import { AdviceStatusVO, AdviceVO } from 'ui-api';
import { IconShare } from 'components/icons';
import { theme } from 'styles.v2/theme';
import { TargetId } from 'targets/util';

import TargetAdviceTable from './TargetAdviceTable';
import { ampli } from '../../ampli';

interface TargetAdviceDetailsProps {
	environmentId: string | null;
	targetId: TargetId;
	advice: AdviceVO;
	context: 'explorer_target' | 'explorer_advice';
}

interface AdviceIdUrlState {
	sharedAdviceId: string | null;
}

const targetIdParam: UrlParam<TargetId | null> = {
	pathSegment: '/advice',
	name: 'selectedTargetId',
	defaultValue: null,
};

const sharedAdviceIdParam: UrlParam<string | null> = {
	pathSegment: '/advice',
	name: 'selectedAdviceId',
	defaultValue: null,
};

export default function TargetAdviceDetails({
	environmentId,
	targetId,
	advice,
	context,
}: TargetAdviceDetailsProps): ReactElement {
	const [, getUrlWithState] = useUrlState<AdviceIdUrlState>([targetIdParam, sharedAdviceIdParam]);
	const [linkCopied, setLinkCopied] = useState(false);

	useEffect(() => {
		if (linkCopied) {
			const timeout = setTimeout(() => {
				setLinkCopied(false);
			}, 2000);
			return () => {
				clearTimeout(timeout);
			};
		}
	}, [linkCopied]);

	useEffect(() => {
		ampli.explorerAdviceViewed({
			advice_id: advice.adviceDefinitionId,
			advice_context: context,
			target: advice.targetReference,
			advice_status:
				advice.status === 'ACTION_NEEDED'
					? 'action_needed'
					: advice.status === 'VALIDATION_NEEDED'
					? 'validation_needed'
					: 'implemented',
		});
	}, [advice, context]);

	return (
		<Stack size="medium" px="medium" py="small">
			<Stack size="small">
				<Stack direction="horizontal" alignItems="center" justifyContent="space-between">
					<Text variant="xLargeStrong">{advice.name}</Text>

					{environmentId && (
						<Tooltip content={linkCopied ? 'Link copied!' : 'Click to copy the link'}>
							<Container>
								<ReactCopyToClipboard
									text={getShareLink(environmentId, targetId, advice, getUrlWithState)}
									onCopy={() => setLinkCopied(true)}
								>
									<Button variant="chromeless" color="neutral700">
										<IconShare mr="xxSmall" />
										Share
									</Button>
								</ReactCopyToClipboard>
							</Container>
						</Tooltip>
					)}
				</Stack>

				<Tags advice={advice} />
			</Stack>

			{advice.summary && <Summary status={advice.status} summary={advice.summary} />}

			{advice.motivation && (
				<>
					<Divider />
					<Motivation motivation={advice.motivation} />
				</>
			)}

			{advice.instruction && (
				<>
					<Divider />
					<Instruction instruction={advice.instruction} />
				</>
			)}

			<TargetAdviceTable
				adviceDefinitionId={advice.adviceDefinitionId}
				targetReference={advice.targetReference}
				environmentId={environmentId}
				type={advice.type}
			/>

			{(advice.actionSummary || advice.actionMotivation || advice.actionInstruction) && (
				<Collapsible title="Action Implemented">
					<Stack>
						{advice.actionMotivation && (
							<>
								<Divider color="neutral300" />
								<Motivation motivation={advice.actionMotivation} />
							</>
						)}

						{advice.actionInstruction && (
							<>
								<Divider color="neutral300" />
								<Instruction instruction={advice.actionInstruction} />
							</>
						)}
					</Stack>
				</Collapsible>
			)}
		</Stack>
	);
}

interface SummaryProps {
	summary: string;
	status: AdviceStatusVO;
}

function Summary({ summary, status }: SummaryProps): ReactElement {
	return (
		<AdviceFindingFrame state={status}>
			<Markdown content={summary} />
		</AdviceFindingFrame>
	);
}

interface MotivationProps {
	motivation: string;
}

function Motivation({ motivation }: MotivationProps): ReactElement {
	return (
		<Stack size="xxSmall">
			<Text variant="largeStrong">Motivation</Text>
			<Markdown content={motivation} />
		</Stack>
	);
}

interface InstructionProps {
	instruction: string;
}

function Instruction({ instruction }: InstructionProps): ReactElement {
	return (
		<Stack size="xxSmall">
			<Text variant="largeStrong">Instructions</Text>
			<Markdown content={instruction} />
		</Stack>
	);
}

interface AdviceFindingFrameProps {
	state: AdviceStatusVO;
	children: ReactNode;
}

function AdviceFindingFrame({ state, children }: AdviceFindingFrameProps): ReactElement {
	const color =
		state === 'ACTION_NEEDED'
			? theme.colors.coral
			: state === 'VALIDATION_NEEDED'
			? theme.colors.experimentWarning
			: theme.colors.cyanDark;

	return (
		<Stack
			size="xxSmall"
			sx={{
				p: 'small',
				borderLeft: '4px solid ' + color,
				bg:
					state === 'ACTION_NEEDED'
						? theme.colors.coralLight
						: state === 'VALIDATION_NEEDED'
						? theme.colors.feedbackWarningLight
						: theme.colors.cyanBackground,
			}}
		>
			<Text variant="smallStrong" color={color}>
				{state === 'ACTION_NEEDED'
					? 'ACTION NEEDED'
					: state === 'VALIDATION_NEEDED'
					? 'VALIDATION NEEDED'
					: 'IMPLEMENTED'}
			</Text>
			{children}
		</Stack>
	);
}

interface TagsProps {
	advice: AdviceVO;
}

function Tags({ advice }: TagsProps): ReactElement {
	return (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',
				flexWrap: 'wrap',
				gap: '8px',
			}}
		>
			{advice.tags.map((tag) => (
				<Container
					key={tag}
					sx={{
						backgroundColor: theme.colors.neutral200,
						borderRadius: 2,
						px: 'xSmall',
					}}
				>
					<Text variant="small">{tag}</Text>
				</Container>
			))}
		</Container>
	);
}

function getShareLink(
	environmentId: string,
	targetId: TargetId,
	advice: AdviceVO,
	getUrlWithState: GetUrlWithState,
): string {
	const url = getUrlWithState(
		{
			selectedAdviceId: advice.adviceDefinitionId,
			selectedTargetId: targetId,
		},
		(location) => {
			location.pathname = `/landscape/${environmentId}/advice`;
		},
	);
	return `${window.location.protocol}//${window.location.host}${url}`;
}
