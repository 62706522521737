/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { DeleteIcon } from 'pages/templates/FromTemplateModal/TemplateDetails/common';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { IconEnvironment } from 'components/icons';
import { ReactElement } from 'react';
import { Pill } from 'components';

interface EnvironmentProps {
	children: string;
	onClick?: () => void;
	onDelete?: () => void;
}

export default function Environment({ children, onClick, onDelete }: EnvironmentProps): ReactElement {
	return (
		<Pill
			backgroundColorOnHover="neutral300"
			backgroundColor="neutral200"
			color="neutral800"
			onClick={onClick}
			sx={{
				width: 'fit-content',
				height: '28px',
				fontSize: '12px',
				borderRadius: '14px',
				...textEllipsis,
			}}
		>
			<IconEnvironment variant="small" mr="xxSmall" />
			{children}
			{onDelete ? <DeleteIcon color="neutral800" onClick={onDelete} /> : <></>}
		</Pill>
	);
}
