/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { range } from 'lodash';

type PaginationItem = number | 'ellipsis';

const boundaryCount = 1; //visible elements at start and end;
const siblingCount = 1; // visible elements around selectedPage
export function usePagination(totalPages: number, activePage: number): PaginationItem[] {
	if (totalPages <= 1) {
		return [];
	}

	const startPages = range(0, Math.min(boundaryCount, totalPages));
	const endPages = range(Math.max(totalPages - boundaryCount, boundaryCount), totalPages);
	const siblingsStart = Math.max(
		Math.min(
			// Natural start
			activePage - siblingCount,
			// Lower boundary when page is high
			totalPages - boundaryCount - siblingCount * 2 - 2,
		),
		// Greater than startPages
		boundaryCount + 1,
	);

	const siblingsEnd = Math.min(
		Math.max(
			// Natural end
			activePage + siblingCount + 1,
			// Upper boundary when page is low
			boundaryCount + siblingCount * 2 + 2,
		),
		// Less than endPages
		endPages[0] - 1,
	);

	return [
		...startPages,
		// Start ellipsis
		...(siblingsStart > boundaryCount + 1
			? ['ellipsis' as PaginationItem]
			: boundaryCount + 1 < totalPages - boundaryCount
			? [boundaryCount]
			: []),

		// Sibling pages
		...(siblingsStart < siblingsEnd ? range(siblingsStart, siblingsEnd) : []),
		// End ellipsis
		...(siblingsEnd < totalPages - boundaryCount - 1
			? ['ellipsis' as PaginationItem]
			: totalPages - boundaryCount > boundaryCount
			? [totalPages - boundaryCount - 1]
			: []),
		...endPages,
	];
}
