/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { FieldVO } from 'ui-api';
import React from 'react';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const FieldVOOutput: React.VFC<{ field: FieldVO; value: any }> = ({ field, value }) => {
	if (field.type === 'boolean') {
		return value ? 'true' : 'false';
	}
	if (field.type === 'string[]' && field.options?.length) {
		const selectedOptions = field.options
			.filter((option) => value.includes(option.value))
			.map((option) => option.label)
			.join(', ');
		return selectedOptions || value;
	}
	if (field.type === 'string' && field.options?.length) {
		return field.options.find((option) => value === option.value)?.label || value;
	}

	return value || null;
};
