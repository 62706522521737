/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { UnstyledLink, UnstyledLinkProps } from 'components';
import React from 'react';

export type SubnavLinkProps = UnstyledLinkProps;

export const SubnavLink = React.forwardRef<HTMLLinkElement, SubnavLinkProps>(({ children, sx = {}, ...props }, ref) => (
	<UnstyledLink
		{...props}
		ref={ref}
		sx={{
			variant: 'text.mediumStrong',
			display: 'inline-flex',
			alignItems: 'center',
			justifyContent: 'center',
			borderBottom: '4px solid',
			borderTop: '4px solid',
			borderColor: 'transparent',
			height: '48px',
			minWidth: '48px',
			color: 'neutral600',
			textDecoration: 'none',

			':hover:not([disabled])': {
				textDecoration: 'none',
				color: 'neutral800',
				borderBottomColor: 'slate',
				...(props.onClick || props.href ? { cursor: 'pointer' } : {}),
			},
			'&[disabled]': {
				opacity: '.35',
				pointerEvents: 'none',
			},
			'&[aria-current=page]': {
				textDecoration: 'none',
				color: 'neutral800',
				borderBottomColor: 'slate',
			},
			...sx,
		}}
	>
		{children}
	</UnstyledLink>
));
SubnavLink.displayName = 'SubnavLink';
