/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Banner, Link, Snackbar } from 'components';
import { Services } from 'services/services';
import { ReactElement } from 'react';

export default function KillswitchWarning(): ReactElement {
	const handleDisengageClick = async (): Promise<void> => {
		try {
			await Services.killswitch.disengage();
		} catch (error) {
			Snackbar.error('Killswitch not disengaged: ' + error.toString(), { toastId: 'killswitch-disengaged' });
		}
	};

	return (
		<Banner
			id="killswitch-banner"
			variant="danger"
			title="Emergency Stop Engaged"
			description={
				<span>
					Running experiments is temporarily deactivated for all users.{' '}
					<Link onClick={handleDisengageClick}>Disengage emergency stop.</Link>
				</span>
			}
		/>
	);
}
