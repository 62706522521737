/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Checkbox, Label, Snackbar, Stack, Text, TextField, userConfirmV2 } from 'components';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { ReactElement, useEffect, useState } from 'react';
import { IconCheck, IconTrash } from 'components/icons';
import { Services } from 'services/services';
import { TeamVO } from 'ui-api';

export const handleDeleteTeamClick = async (teamVO: TeamVO): Promise<void> => {
	await userConfirmV2({
		title: 'Delete this Team',
		message: ({ setDisabled }) => <DeleteMessage teamVO={teamVO} setDisabled={setDisabled} />,
		actions: [
			{
				value: 'confirm',
				label: 'Delete Team and all Experiments',
				variant: 'primary',
				icon: <IconTrash mr="xSmall" />,
				actionIcon: <LoadingIndicator sx={{ mr: 'xSmall' }} />,
				action: async () => {
					try {
						await Services.teams.deleteTeam(teamVO.id);
						Snackbar.dark('Team deleted.', { toastId: 'team-deleted' });
					} catch (err) {
						if (err.response?.status === 403) {
							Snackbar.error('Team not deleted: There is an experiment running for the team', {
								toastId: 'team-deleted',
							});
						} else {
							Snackbar.error('Team not deleted: ' + err.toString(), { toastId: 'team-deleted' });
						}
					}
				},
			},
		],
		secondaryActions: [{ value: 'cancel', label: 'Cancel' }],
		width: '700px',
	});
};

interface DeleteMessageProps {
	setDisabled: (disabled: boolean) => void;
	teamVO: TeamVO;
}

function DeleteMessage({ teamVO, setDisabled }: DeleteMessageProps): ReactElement {
	const [value, setValue] = useState('');
	const [checked, setChecked] = useState(false);
	useEffect(() => {
		setDisabled(value !== teamVO.name || !checked);
	}, [checked, value, teamVO.name]);

	return (
		<Stack size="large">
			<Stack size="medium">
				<Text as="span">{`Do you really want to delete the Team "${teamVO.name} (${teamVO.key})"?`}</Text>
				<Text as="span">
					Once you delete it, you will lose{' '}
					<Text as="span" variant="mediumStrong" color="neutral800">
						all
					</Text>{' '}
					the Experiments and the executions associated to this Team.
				</Text>
			</Stack>

			<Stack size="small">
				<Stack size="xxSmall">
					<Text
						as="span"
						variant="mediumStrong"
						color="neutral800"
					>{`Type the Team’s name ("${teamVO.name}") to confirm deletion`}</Text>
					<TextField
						value={value}
						onChange={(e) => setValue(e.target.value)}
						placeholder="Team name"
						iconRight={value !== teamVO.name ? undefined : IconCheck}
					/>
				</Stack>

				<Stack direction="horizontal" size="xSmall" alignItems="flex-start">
					<Checkbox
						data-cy="delete-team-checkbox"
						onChange={(e) => setChecked(e.target.checked)}
						checked={checked}
						minWidth={18}
						minHeight={18}
						mt={2}
						id={'delete-team-checkbox'}
					/>
					<Label variant="medium" color="neutral800" htmlFor={'delete-team-checkbox'}>
						I acknowledge that I will lose all Experiments and executions of the Team {`"${teamVO.name}"`}
					</Label>
				</Stack>
			</Stack>
		</Stack>
	);
}
