/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { BufferGeometry, Float32BufferAttribute, Mesh, ShaderMaterial } from 'three';

import fragmentShader from '../shader/adviceIconsFragmentShader';
import { addQuadUVs, addQuadVertices } from '../attributeUtils';
import vertexShader from '../shader/adviceIconsVertexShader';
import { overlayLayer } from './layer';

export default class AdviceController {
	uvs: number[] = [];
	sizes: number[] = [];
	adviceModes: number[] = [];
	positions: number[] = [];
	toPositions: number[] = [];
	material: ShaderMaterial;
	geometry: BufferGeometry;
	mesh: Mesh<BufferGeometry, ShaderMaterial>;
	lastSetWorldUnitInScreenSpace = 0;

	constructor(initialActive: boolean) {
		this.material = new ShaderMaterial({
			uniforms: {
				progress: { value: 0.0 },
				worldUnitInScreenSpace: { value: 0.0 },
				withBorder: { value: 0.0 },
			},
			vertexShader,
			fragmentShader,
			transparent: true,
			depthTest: true,
			depthWrite: true,
		});

		this.geometry = new BufferGeometry();
		this.mesh = new Mesh(this.geometry, this.material);
		this.mesh.frustumCulled = false;
		this.mesh.renderOrder = overlayLayer;
		this.mesh.name = 'adviceIcons';
		this.mesh.visible = initialActive;
	}

	reset(): void {
		this.lastSetWorldUnitInScreenSpace = 0;
		this.toPositions.length = 0;
		this.adviceModes.length = 0;
		this.positions.length = 0;
		this.sizes.length = 0;
		this.uvs.length = 0;
	}

	add(
		currentX: number,
		currentY: number,
		x: number,
		y: number,
		targetRadius: number,
		adviceRequireAction: number,
		adviceRequireValidation: number,
		adviceDone: number,
		withBorder: boolean,
	): void {
		const r = withBorder ? 0.85 * targetRadius : targetRadius + 0.02;
		addQuadVertices(this.positions, currentX, currentY, overlayLayer, r);
		addQuadVertices(this.toPositions, x, y, overlayLayer, r);

		addQuadUVs(this.uvs);
		this.sizes.push(r, r, r, r, r, r);

		const adviceMode = adviceRequireAction > 0 ? 3 : adviceRequireValidation > 0 ? 2 : adviceDone > 0 ? 1 : 0;
		this.adviceModes.push(adviceMode, adviceMode, adviceMode, adviceMode, adviceMode, adviceMode);

		this.material.uniforms.withBorder.value = withBorder ? 1.0 : 0.0;
	}

	flush(): void {
		const { positions, toPositions, uvs, sizes, geometry, adviceModes } = this;
		geometry.setAttribute('uv', new Float32BufferAttribute(uvs, 2));
		geometry.setAttribute('targetSize', new Float32BufferAttribute(sizes, 1));
		geometry.setAttribute('position', new Float32BufferAttribute(positions, 3));
		geometry.setAttribute('adviceMode', new Float32BufferAttribute(adviceModes, 1));
		geometry.setAttribute('toPosition', new Float32BufferAttribute(toPositions, 3));
	}

	getMesh(): Mesh<BufferGeometry, ShaderMaterial> {
		return this.mesh;
	}

	getMaterial(): ShaderMaterial {
		return this.material;
	}

	updateScreenSpaceSize(worldUnitInScreenSpace: number): void {
		if (this.lastSetWorldUnitInScreenSpace === worldUnitInScreenSpace) {
			return;
		}
		this.lastSetWorldUnitInScreenSpace = worldUnitInScreenSpace;
		this.material.uniforms.worldUnitInScreenSpace.value = worldUnitInScreenSpace;
	}

	dispose(): void {
		this.geometry.dispose();
		this.material.dispose();
	}
}
