/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { DependencyList, useEffect, useState } from 'react';
import { Observable, distinctUntilChanged } from 'rxjs';

export function useStream<T>(stream: Observable<T>, dependencies: DependencyList = []): T | null {
	const [state, setState] = useState<T | null>(null);

	useEffect(() => {
		const subscription = stream.pipe(distinctUntilChanged()).subscribe({
			next: (value) => {
				setState(value);
			},
			error: () => {
				// ignore
			},
		});

		return () => subscription.unsubscribe();
	}, dependencies);

	return state;
}
