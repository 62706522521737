/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { listPlaceholders } from 'templates/utils';
import { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';

import { extractVariables } from '../UseTemplateModal/utils';
import { TemplateFormValues } from './types';

export default function PlaceholderExtractionAndCleanupJob(): null {
	const { values, setFieldValue } = useFormikContext<TemplateFormValues>();

	const debouncedValidate = useCallback(
		debounce(
			async (v: TemplateFormValues) => {
				const [currentUsedEnvVars, currentUsedPlaceholderKeys] = extractUsedPlaceholderKeys(v);

				const keysToAdd = currentUsedPlaceholderKeys.filter((used) =>
					v.placeholders.every((placeholder) => placeholder.key !== used),
				);
				const keysToRemove = v.placeholders.filter((p) => !p.protected && !currentUsedPlaceholderKeys.includes(p.key));
				if (keysToAdd.length > 0 || keysToRemove.length > 0) {
					const newPlaceholders = v.placeholders
						.filter((p) => !keysToRemove.includes(p))
						.concat(keysToAdd.map((key) => ({ key, name: '', description: '', protected: false })));

					setFieldValue('placeholders', newPlaceholders);
				}

				const envVarsToAdd = currentUsedEnvVars
					.map((key) => ({ key, value: '' }))
					.filter((used) => !v.variables.find((_v) => _v.key === used.key));
				const envVarsToRemove = v.variables.filter((v) => !currentUsedEnvVars.includes(v.key));
				if (envVarsToAdd.length > 0 || envVarsToRemove.length > 0) {
					const newVariables = v.variables
						.filter((v) => !envVarsToRemove.find((v2) => v2.key === v.key))
						.concat(envVarsToAdd);

					setFieldValue('variables', newVariables);
				}
			},
			500,
			{ leading: false },
		),
		[],
	);

	useEffect(() => {
		debouncedValidate(values);
	}, [values, debouncedValidate]);

	return null;
}

export function extractUsedPlaceholderKeys(values: TemplateFormValues): [string[], string[]] {
	const [usedEnvironmentVariables, usedPlaceholders] = extractVariables(values.lanes, values.placeholders);
	const fromExperimentName = values.experimentName ? listPlaceholders(values.experimentName) : [];
	const fromHypothesis = values.hypothesis ? listPlaceholders(values.hypothesis) : [];

	return [
		Array.from(usedEnvironmentVariables.keys()),
		[...Array.from(usedPlaceholders.keys()), ...fromExperimentName, ...fromHypothesis],
	];
}
