/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { UrlParam } from 'url/useUrlState';
import { TargetId } from 'targets/util';

import { Filtering, Grouping, Sizing, Coloring, Environment, GroupConfig, ColorConfig, ColorOverrides } from './types';

export const pathSegment = '/explore';

export interface UrlState {
	colorOverrides: ColorOverrides | null;
	colorConfig: ColorConfig | null;
	groupConfigs: GroupConfig[];
	description: string | null;
	environment: Environment;
	name: string | null;
	showAdvice: boolean;
	coloring: Coloring;
	grouping: Grouping;
	query: Filtering;
	sizing: Sizing;
}

export const nameParam: UrlParam<string | null> = {
	pathSegment,
	name: 'name',
	defaultValue: null,
};

export const descriptionParam: UrlParam<string | null> = {
	pathSegment,
	name: 'description',
	defaultValue: null,
};

export const environmentParam: UrlParam<Environment | null> = {
	pathSegment,
	name: 'environment',
	defaultValue: null,
};

export const filterQueryParam: UrlParam<Filtering | null> = {
	pathSegment,
	name: 'query',
	defaultValue: null,
};

export const groupingParam: UrlParam<Grouping | null> = {
	pathSegment,
	name: 'grouping',
	defaultValue: null,
};

export const sizingParam: UrlParam<Sizing | null> = {
	pathSegment,
	name: 'sizing',
	defaultValue: null,
};

export const coloringParam: UrlParam<Coloring | null> = {
	pathSegment,
	name: 'coloring',
	defaultValue: null,
};

export const groupingConfigsParam: UrlParam<Array<GroupConfig> | null> = {
	pathSegment,
	name: 'groupConfigs',
	defaultValue: null,
};

export const colorConfigParam: UrlParam<ColorConfig | null> = {
	pathSegment,
	name: 'colorConfig',
	defaultValue: null,
};

export const colorOverridesParam: UrlParam<ColorOverrides | null> = {
	pathSegment,
	name: 'colorOverrides',
	defaultValue: null,
};

export const adviceParam: UrlParam<boolean | null> = {
	pathSegment,
	name: 'showAdvice',
	defaultValue: null,
};

export const targetIdParam: UrlParam<TargetId | null> = {
	pathSegment,
	name: 'targetId',
	defaultValue: null,
};

export const sharedAdviceIdParam: UrlParam<string | null> = {
	pathSegment,
	name: 'sharedAdviceId',
	defaultValue: null,
};
