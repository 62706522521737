/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { DependencyList, useEffect, useState } from 'react';
import { Observable } from 'rxjs';

import {
	createResult,
	DataStreamResult,
	ErrorResult,
	loadingResult,
	createErrorResult,
	isDataStreamResultLike,
} from './stream/result';

export type Creator<T> = () => Observable<T>;

export function useObservable<T>(fn: Creator<DataStreamResult<T>>, dependencies: DependencyList): DataStreamResult<T>;
export function useObservable<T>(fn: Creator<T>, dependencies: DependencyList): DataStreamResult<T>;
export function useObservable<T>(fn: Creator<T>, dependencies: DependencyList): DataStreamResult<T> {
	const [state, setState] = useState<DataStreamResult<T>>(loadingResult);

	useEffect(() => {
		setState(loadingResult);

		const subscription = fn().subscribe({
			next: (value) => {
				if (isDataStreamResultLike(value)) {
					setState(value);
				} else {
					setState(createResult(value));
				}
			},
			error: (err) => {
				if (isDataStreamResultLike(err)) {
					setState(err);
				} else {
					setState(getError(err));
				}
			},
		});

		return () => subscription.unsubscribe();
	}, dependencies);

	return state;
}

function getError(err: Error): ErrorResult {
	// Ignoring this check because reponsonse is not part of the general Error
	// but works in the case we want to check.
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	return createErrorResult(err.message, err.response?.status);
}
