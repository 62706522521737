/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { FormikIdSelect, FormikTextField, Stack } from 'components';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { useAutoFocus } from 'utils/hooks/useAutoFocus';
import { useTenant } from 'tenancy/useTenant';
import { Services } from 'services/services';
import { ReactElement } from 'react';

interface AccessTokenFormProps {
	accessToken?: string;
	isAdminToken: boolean;
}

const typeOptions = [
	{
		id: 'ADMIN',
		name: 'Admin – allows usage of the management APIs, e.g., environments and teams',
	},
	{
		id: 'TEAM',
		name: 'Team – allows usage of the team specific APIs, e.g., experiments',
	},
];

export default function AccessTokenForm({ accessToken, isAdminToken }: AccessTokenFormProps): ReactElement {
	const autoFocusRef = useAutoFocus();
	const hasToken = Boolean(accessToken);
	const tenant = useTenant();
	const isAdmin = tenant.user?.role === 'ADMIN';

	const [teams] = useAsyncState(() => Services.teams.findTeams('', !isAdmin), [isAdmin], []);

	return (
		<>
			{!hasToken && (
				<Stack size={'medium'}>
					<FormikTextField
						label={'Token name'}
						name={'name'}
						maxLength={255}
						ref={autoFocusRef}
						required
						autoComplete={'off'}
					/>
					{isAdmin && <FormikIdSelect label={'Type'} name={'type'} options={typeOptions} />}
					{!isAdminToken && <FormikIdSelect label={'Team'} name={'teamId'} options={teams.value} />}
				</Stack>
			)}
		</>
	);
}
