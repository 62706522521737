/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { CreateAccessTokenRequest, CreateAccessTokenResponse, GetAccessTokensPageResponse } from 'ui-api';
import { Criteria, PageParams } from 'utils/hooks/usePage';
import axios from 'axios';

export class AccessTokensApi {
	async findAccessTokens(criteria: Criteria, page: PageParams): Promise<GetAccessTokensPageResponse> {
		const params = page.appendTo(new URLSearchParams(criteria));
		return (await axios.get<GetAccessTokensPageResponse>('/ui/accesstokens', { params })).data;
	}

	async deleteAccessToken(id: string): Promise<void> {
		await axios.delete(`/ui/accesstokens/${id}`);
	}

	async createAccessToken(body: CreateAccessTokenRequest): Promise<CreateAccessTokenResponse> {
		return (await axios.post<CreateAccessTokenResponse>('/ui/accesstokens', body)).data;
	}
}
