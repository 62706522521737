/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { lazy, ReactElement, Suspense } from 'react';

const OnboardingLoaded = lazy(() => import('./OnboardingScreen'));

interface OnboardingLazyLoaderProps {
	onComplete: () => void;
}

export default function OnboardingLazyLoader(props: OnboardingLazyLoaderProps): ReactElement | null {
	return (
		<Suspense fallback={<></>}>
			<OnboardingLoaded {...props} />
		</Suspense>
	);
}
