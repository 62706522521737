/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { exhaustMap, map, mergeMap, scan, takeWhile, tap } from 'rxjs/operators';
import { ExecutionLogEventVO, GetExecutionLogEventsResponse } from 'ui-api';
import { from, interval, Observable, of } from 'rxjs';
import { orderBy } from 'lodash';
import AnsiUp from 'ansi_up';
import axios from 'axios';

import { DateReviver, withReviver } from './common';

export class ExecutionLogType {
	static ACTION = 'ACTION';
	static AGENT = 'AGENT';
	static KUBERNETES = 'KUBERNETES';
	static KUBERNETES_EVENTS = 'KUBERNETES_EVENTS';
	static NEWRELIC = 'NEWRELIC';
	static INSTANA = 'INSTANA';
}

const ansiUp = new AnsiUp();

export class ExecutionLogsApi {
	streamLogs(
		executionId: number,
		type: ExecutionLogType | string,
		ids: string | string[] | undefined,
	): [observable: Observable<ExecutionLogEventVO[]>, stop: () => void] {
		const idArray = Array.isArray(ids) ? ids : [ids];
		if (idArray.length === 0) {
			return [of([]), () => {}];
		}

		const aggregateFn =
			idArray.length === 1
				? (acc: ExecutionLogEventVO[], current: ExecutionLogEventVO[]) => [...acc, ...current]
				: (acc: ExecutionLogEventVO[], current: ExecutionLogEventVO[]) =>
						orderBy([...acc, ...current], ['timestamp'], ['asc']);

		let live = true;
		const observable = from(idArray).pipe(
			mergeMap((id) =>
				this.createLogObservable(executionId, type, id).pipe(
					takeWhile(() => live, true),
					map((events) => {
						events.forEach((e) => ((e as ExecutionLogEventVO).id = e.id));
						return events as ExecutionLogEventVO[];
					}),
				),
			),
			scan(aggregateFn, []),
		);
		return [
			observable,
			() => {
				live = false;
			},
		];
	}

	getLogUri(executionId: number, type: ExecutionLogType | string, id: string, tenantKey?: string): string {
		return `/ui/executions/${executionId}/log/${type}/${id}${tenantKey ? `?tenantKey=${tenantKey}` : ''}`;
	}

	getLogUriWithoutId(executionId: number, type: ExecutionLogType | string, tenantKey?: string): string {
		return `/ui/executions/${executionId}/log/${type}/${tenantKey ? `?tenantKey=${tenantKey}` : ''}`;
	}

	async fetchLog(
		executionId: number,
		type: ExecutionLogType | string,
		id: string | undefined,
		next = -1,
	): Promise<GetExecutionLogEventsResponse> {
		return (
			await axios.get<GetExecutionLogEventsResponse>(
				id ? this.getLogUri(executionId, type, id) : this.getLogUriWithoutId(executionId, type, id),
				{
					params: { next },
					transformResponse: withReviver(DateReviver(['timestamp'])),
				},
			)
		).data;
	}

	private createLogObservable(
		executionId: number,
		type: ExecutionLogType | string,
		id: string | undefined,
	): Observable<ExecutionLogEventVO[]> {
		let next = -1;
		return interval(1000).pipe(
			exhaustMap(async () => {
				const response = await this.fetchLog(executionId, type, id, next);
				next = response.next;
				return response.content;
			}),
			tap((events) => {
				events.forEach((event) => (event.message = event.message ? ansiUp.ansi_to_html(event.message) : ''));
			}),
		);
	}
}
