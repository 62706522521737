/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { FileUpload } from 'components/FileUpload/FileUpload';
import { ReactElement } from 'react';

interface FileProps {
	disabled: boolean;
	accept: string;
	value: string | undefined;
	setValue: (value: string | undefined) => void;
}

export default function File({ value, disabled, accept, setValue }: FileProps): ReactElement {
	return (
		<FileUpload
			value={value}
			onChange={(_value: string | undefined) => {
				setValue(_value);
			}}
			accept={accept}
			disabled={disabled}
		/>
	);
}
