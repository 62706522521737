/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react';
import { Banner } from 'components';

import useShouldBeCareful from './useShouldBeCareful';

export default function SystemStatus(): ReactElement | null {
	const shouldBeCareful = useShouldBeCareful();
	if (!shouldBeCareful) {
		return null;
	}

	return (
		<Banner
			id="steadybit-user-on-prod"
			variant="danger"
			title="🚨 ATTENTION 🚨"
			description={<span>You are currently operating on a customer tenant. Be careful what you do!</span>}
			sx={{
				background: 'repeating-linear-gradient(45deg, #FFD3D2, #FFD3D2 5%, #FFF4F4 5%, #FFF4F4 10%)',
				backgroundSize: '100px 100px',
				animation: 'move-it 2s linear infinite',

				'@keyframes move-it': {
					'0%': {
						backgroundPosition: 'initial',
					},
					'100%': {
						backgroundPosition: '100px 0px',
					},
				},
			}}
		/>
	);
}
