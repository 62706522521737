/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Container, ModalFooterV2, ModalHeaderV2, ModalV2, Stack } from 'components';
import React, { ReactElement, ReactNode, useState } from 'react';
import { useObservable } from 'react-use';
import { ensure } from 'utils/ensure';
import { ReplaySubject } from 'rxjs';

import { ModalOverlay } from '../Modal';
import { Button } from '../Button';

type ConfirmAction = {
	value: string;
	label: ReactNode;
	variant?: 'primary' | 'secondary' | 'primaryAttention' | 'chromeless';
	dataCy?: string;
	disabled?: boolean;
	autoFocus?: boolean;
	icon?: ReactNode;
	action?: () => Promise<void>;
	actionIcon?: ReactNode;
};

interface MessageOpions {
	setDisabled: (disabled: boolean) => void;
}

type ConfirmOptions = {
	title?: string;
	message: ReactNode | string | ((props: MessageOpions) => ReactElement);
	actions: ConfirmAction[];
	secondaryActions?: ConfirmAction[];
	dataCy?: string;
	width?: string;
};

type OutstandingConfirmation = ConfirmOptions & {
	resolve: (result: string) => void;
};

const outstandingConfirmation$ = new ReplaySubject<OutstandingConfirmation | null>(1);

export const UserConfirmContainerV2: React.VFC = () => {
	const confirmation = useObservable(outstandingConfirmation$);
	const [disabled, setDisabled] = useState(false);

	return (
		<ModalOverlay
			open={Boolean(confirmation)}
			onClose={() => {
				confirmation?.resolve?.('cancel');
			}}
			zIndex={40}
		>
			{({ close }) => {
				const { title, message, actions, secondaryActions, dataCy, width } = {
					...ensure(confirmation),
				};

				return (
					<ModalV2
						{...(dataCy ? { 'data-cy': dataCy } : {})}
						{...(width ? { width } : {})}
						centered
						sx={{
							minHeight: 1,
						}}
						slick
						withFooter
					>
						<ModalHeaderV2
							title={title}
							onClose={close}
							subTitle={typeof message === 'function' ? message({ setDisabled }) : message}
						/>
						<ModalFooterV2>
							<Container display="flex" justifyContent="space-between" width="100%">
								<Stack direction="horizontal" size="medium">
									{(secondaryActions || []).map((action, i) => (
										<Action key={i} action={action} confirmation={confirmation} />
									))}
								</Stack>
								<Stack direction="horizontal" size="medium" pl="xxLarge">
									{actions.map((action, i) => (
										<Action key={i} action={action} confirmation={confirmation} disabled={disabled} />
									))}
								</Stack>
							</Container>
						</ModalFooterV2>
					</ModalV2>
				);
			}}
		</ModalOverlay>
	);
};

interface ActionProps {
	action: ConfirmAction;
	disabled?: boolean;
	confirmation: OutstandingConfirmation | null | undefined;
}

function Action({ action, confirmation, disabled }: ActionProps): ReactElement {
	const [isProcessingAction, setIsProcessingAction] = useState(false);
	return (
		<Button
			key={action.value}
			variant={action.variant ?? 'secondary'}
			disabled={action.disabled || disabled || isProcessingAction}
			onClick={async (e) => {
				e.stopPropagation();
				if (action.action) {
					setIsProcessingAction(true);
					try {
						await action.action?.();
					} finally {
						setIsProcessingAction(false);
					}
				}
				confirmation?.resolve(action.value);
			}}
			autoFocus={action.autoFocus}
			data-cy={action.dataCy}
		>
			{isProcessingAction && action.actionIcon ? action.actionIcon : action.icon}
			{action.label}
		</Button>
	);
}

export function userConfirmV2(options: ConfirmOptions): Promise<string> {
	return new Promise<string>((resolve) => {
		outstandingConfirmation$.next({
			resolve: (result: string) => {
				outstandingConfirmation$.next(null);
				resolve(result);
			},
			...options,
		});
	});
}
