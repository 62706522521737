/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Container, LoadingIndicator, Text, Tooltip } from 'components';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { formatTime } from 'utils/dateFns';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import { IconCheck, IconWarning, IconWarningCircle } from '../../../components/icons';
import { ExperimentExecutionLogPreflightsLine } from './experimentExecutionLog';
import ExperimentExecutionLogLine from './ExperimentExecutionLogLine';
import { isErrored, isFailed } from './utils';

interface ExperimentExecutionLogPreflightsProps extends ExperimentExecutionLogPreflightsLine {
	onClick: () => void;
}

export default function ExperimentExecutionLogPreflights({
	time,
	preflights,
	onClick,
}: ExperimentExecutionLogPreflightsProps): ReactElement {
	const anyErrored = preflights.some((check) => check.state === 'ERRORED');
	const anyFailed = preflights.some((check) => check.state === 'FAILED');
	const anyRunning = preflights.some((check) => check.state === 'UNCHECKED');
	const allSuccessful = preflights.every((check) => check.state === 'SUCCESSFUL');
	const unsuccessfulPreflights = preflights.filter((t) => isErrored(t.state) || isFailed(t.state));

	const isBold = !allSuccessful;

	const color = anyErrored
		? theme.colors.coral700
		: anyFailed
		? theme.colors.experimentWarning
		: allSuccessful
		? 'neutral700'
		: theme.colors.purple700;
	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const getIconForPreflightStates = () => {
		if (anyErrored) {
			return IconWarningCircle;
		} else if (anyFailed) {
			return IconWarning;
		} else if (allSuccessful) {
			return IconCheck;
		}
		return LoadingIndicator;
	};
	const Icon = getIconForPreflightStates();

	const label = 'Performing preflight checks';
	return (
		<ExperimentExecutionLogLine
			StateComponent={
				anyErrored || anyFailed ? (
					<Container
						sx={{
							display: 'flex',
							alignItems: 'center',
							width: 'fit-content',
							maxWidth: '100%',
							p: '4px 6px',
							borderRadius: '4px',
							background: color,
							color: 'neutral000',
						}}
					>
						<Icon variant="small" mr="xxSmall" />
						<Text variant="smallStrong" sx={{ ...textEllipsis }}>
							{unsuccessfulPreflights?.length}
						</Text>
					</Container>
				) : (
					<Icon variant="small" ml="xSmall" color={color} />
				)
			}
			TimeComponent={
				<Text variant={isBold ? 'smallStrong' : 'small'} color={color} sx={{ fontVariantNumeric: 'tabular-nums' }}>
					{formatTime(new Date(time))}
				</Text>
			}
			onClick={onClick}
			sx={{
				border: anyErrored
					? '2px solid coral'
					: anyFailed
					? '2px solid ' + theme.colors.experimentWarning
					: anyRunning
					? '2px dashed ' + theme.colors.purple700
					: '2px solid transparent',
				backgroundColor: anyErrored ? 'coral100' : anyFailed ? '#FEF4E6' : anyRunning ? 'purple100' : 'neutral100',
				cursor: 'pointer',
				':hover': {
					backgroundColor: anyErrored ? 'coral300' : anyFailed ? '#FDDEB5' : anyRunning ? 'purple300' : 'neutral300',
				},
			}}
		>
			<Container display="flex" alignItems="center" justifyContent="space-between" width="100%">
				<Tooltip content={label}>
					<Text variant={isBold ? 'smallStrong' : 'small'} color={color} sx={{ ...textEllipsis }}>
						{label}
					</Text>
				</Tooltip>
			</Container>
		</ExperimentExecutionLogLine>
	);
}
