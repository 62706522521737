/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Box, Colors, Flex, Text } from '@steadybit/ui-components-lib';
import Calendar from 'components/Calendar/Calendar';
import { formatDate } from 'utils/dateFns';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';
import { days } from 'utils/time';

import DropDownItemWrapper from './DropDownItemWrapper';

interface TimesProps {
	selectedTime: string | [number, number] | undefined;
	width: string | number | undefined;
	selectItem: (time: string | [number, number] | undefined) => void;
}

export default function Times({ selectedTime, width, selectItem }: TimesProps): ReactElement {
	return (
		<Flex
			style={{
				minWidth: width,
				px: 'small',
				py: 'xSmall',
			}}
		>
			<TimeItem selected={selectedTime === 'lastDay'} time="lastDay" onSelect={() => selectItem('lastDay')} />
			<TimeItem selected={selectedTime === 'lastWeek'} time="lastWeek" onSelect={() => selectItem('lastWeek')} />
			<TimeItem selected={selectedTime === 'lastMonth'} time="lastMonth" onSelect={() => selectItem('lastMonth')} />
			<TimeItem selected={selectedTime === undefined} time={undefined} onSelect={() => selectItem(undefined)} />
			<CustomTimeItem time={Array.isArray(selectedTime) ? selectedTime : undefined} onSelect={selectItem} />
		</Flex>
	);
}

interface TimeItemProps {
	selected: boolean;
	time?: string;
	onSelect: () => void;
}
function TimeItem({ selected, time, onSelect }: TimeItemProps): ReactElement {
	return (
		<DropDownItemWrapper onClick={onSelect}>
			<Text
				type={selected ? 'smallStrong' : 'smallMedium'}
				style={{ color: selected ? Colors.slate : Colors.neutral600 }}
			>
				{getTimeLabel(time)}
			</Text>
		</DropDownItemWrapper>
	);
}

interface CustomTimeItemProps {
	time: [number, number] | undefined;
	onSelect: (timeRange: [number, number]) => void;
}
function CustomTimeItem({ time, onSelect }: CustomTimeItemProps): ReactElement {
	const expanded = !!time;

	return (
		<DropDownItemWrapper
			vertical
			highlighted={expanded}
			onClick={() => {
				if (!expanded) {
					const now = new Date().getTime();
					onSelect([now - days(1).getMillis(), now]);
				}
			}}
		>
			<Text
				type={expanded ? 'smallStrong' : 'smallMedium'}
				style={{ color: expanded ? Colors.slate : Colors.neutral600 }}
			>
				Custom
			</Text>

			{expanded && (
				<Box
					style={{
						py: 'small',
						px: 'medium',
						borderRadius: 'xSmall',
						border: '1px solid ' + theme.colors.neutral300,
						backgroundColor: theme.colors.neutral000,
					}}
				>
					<Calendar
						range
						value={time ? [new Date(time[0]), new Date(time[1])] : undefined}
						onChange={(e) => {
							if (Array.isArray(e) && e.length === 2) {
								const from = e[0] as Date;
								const to = e[1] as Date;
								onSelect([from.getTime(), to.getTime()]);
							}
						}}
					/>
				</Box>
			)}
		</DropDownItemWrapper>
	);
}

export function getTimeLabel(time: string | [number, number] | undefined): string {
	if (!time) {
		return 'All Time';
	}
	if (Array.isArray(time)) {
		return `${formatDate(new Date(time[0]))} - ${formatDate(new Date(time[1]))}`;
	}

	if (time === 'lastMonth') {
		return 'Past 1 Month';
	}
	if (time === 'lastWeek') {
		return 'Past 1 Week';
	}
	if (time === 'lastDay') {
		return 'Past 1 Day';
	}
	return 'Custom';
}
