/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { filter, mergeMap, shareReplay } from 'rxjs/operators';
import { concat, defer, from, Observable } from 'rxjs';
import { KillswitchVO } from 'ui-api';
import axios from 'axios';

import { EventsApi } from './eventsApi';

export class KillswitchApi {
	killswitchActive$: Observable<boolean>;

	constructor(events: EventsApi) {
		this.killswitchActive$ = concat(
			defer(() => from(KillswitchApi.fetchKillswitchActive())),
			events.events$.pipe(
				filter((event) => ['killswitch.engaged', 'killswitch.disengaged'].includes(event.type)),
				mergeMap(() => KillswitchApi.fetchKillswitchActive()),
			),
		).pipe(shareReplay(1));
	}

	private static async fetchKillswitchActive(): Promise<boolean> {
		return Boolean((await axios.get<KillswitchVO>('/ui/killswitch')).data.engaged);
	}

	async engage(): Promise<void> {
		await axios.post('/ui/killswitch');
	}

	async disengage(): Promise<void> {
		await axios.delete('/ui/killswitch');
	}
}
