/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { Pill, Text } from 'components';
import { ReactElement } from 'react';

interface PlaceholderPillProps {
	placeholder: string;
}

export default function PlaceholderPill({ placeholder }: PlaceholderPillProps): ReactElement {
	return (
		<Pill
			backgroundColor="aqua300"
			color="aqua800"
			sx={{
				width: 'fit-content',
				height: '32px',
				borderRadius: '16px',
				px: '16px',
			}}
		>
			<Text
				variant="mediumMedium"
				lineHeight="16px"
				sx={{
					...textEllipsis,
				}}
			>
				{placeholder}
			</Text>
		</Pill>
	);
}
