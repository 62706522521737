/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react';

import { Input } from './Text';

interface TextArrayProps {
	hasErrors?: boolean;
	disabled: boolean;
	value: string[];
	setValue: (value: string[]) => void;
}

export default function TextArray({ value, disabled, hasErrors, setValue }: TextArrayProps): ReactElement {
	return (
		<Input
			value={parse(value)}
			onChange={(v) => {
				setValue(serialize(v));
			}}
			disabled={disabled}
			hasErrors={hasErrors}
		/>
	);
}

function parse(value: string[]): string {
	return value ? value.join(',') : '';
}

function serialize(value: string): string[] {
	const v = value.split(',').map((v) => v.trim());
	if (v.join('') === '') {
		return [];
	}
	return v;
}
