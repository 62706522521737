/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

export function unescapeNewLines(s: string): string {
	if (!s) {
		return s;
	}

	let result = '';
	let i = 0;
	while (i < s.length) {
		const index = s.indexOf('\\', i);
		if (index >= 0) {
			result += s.substr(i, index - i);
			const next = s[index + 1];
			if (next === '\\') {
				result += '\\';
				i = index + 2;
			} else if (next === 'n') {
				result += '\n';
				i = index + 2;
			} else {
				result += '\\';
				i = index + 1;
			}
		} else {
			result += s.substr(i);
			return result;
		}
	}

	return result;
}
