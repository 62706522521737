/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { IconEnvironment, IconEnvironmentGlobal } from 'components/icons';
import DropdownPill from 'components/Select/Dropdown/DropdownPill';
import Labels from 'components/Select/Dropdown/presets/Labels';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import { ReactElement } from 'react';

interface EnvironmentSelectorProps {
	selectEnvironmentId: (id: string) => void;
	selectedEnvironmentId: string;
	readonly: boolean;
}

export default function EnvironmentSelector({
	selectedEnvironmentId,
	selectEnvironmentId,
	readonly,
}: EnvironmentSelectorProps): ReactElement {
	const {
		isGlobalSelected: globalChecked,
		selectedEnvironment,
		environmentsOfCurrentTeam,
	} = useEnvironments(selectedEnvironmentId);

	return (
		<DropdownPill
			Icon={globalChecked ? IconEnvironmentGlobal : IconEnvironment}
			value={selectedEnvironment?.name || ''}
			sx={{ width: 'fit-content' }}
			disabled={readonly}
		>
			{({ selectItem }) => (
				<Labels<string>
					type="loose"
					maxHeight={400}
					onSelect={({ id }) => {
						selectEnvironmentId(id);
						selectItem(id);
					}}
					labels={environmentsOfCurrentTeam.map((environment) => ({
						id: environment.id,
						label: environment.name,
					}))}
					width={300}
					selectedId={selectedEnvironmentId}
				/>
			)}
		</DropdownPill>
	);
}
