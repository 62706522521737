/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React, { ReactNode } from 'react';

export interface WizardSidebarProps extends ContainerProps {
	header?: ReactNode;
	activeIndex: number;
	onStepSelect: (selectedIndex: number, activeIndex: number, event: React.MouseEvent) => void;
	onIsChecked?: (index: number, activeIndex: number) => boolean;
}

const defaultOnIsChecked = (index: number, activeIndex: number): boolean => index < activeIndex;
export const WizardSidebar: React.FC<WizardSidebarProps> = ({
	children,
	activeIndex,
	onStepSelect,
	onIsChecked = defaultOnIsChecked,
	width = 380,
	header,
	sx = {},
	...props
}) => (
	<Container
		display={'flex'}
		flexDirection="column"
		alignItems="flex-start"
		bg="neutral100"
		maxWidth={width}
		width={width}
		py={'xLarge'}
		pl={'large'}
		pr="medium"
		{...props}
		sx={{ borderRight: '1px solid', borderColor: 'neutral200', gridArea: 'sidebar', overflowX: 'auto', ...sx }}
	>
		{header}
		{React.Children.map(
			children,
			(child, index) =>
				child &&
				React.cloneElement(child as React.ReactElement, {
					key: `wizard-${index}`,
					checked: onIsChecked(index, activeIndex),
					active: index === activeIndex,
					onStepClick: (e: React.MouseEvent) => onStepSelect(index, activeIndex, e),
					number: index + 1,
				}),
		)}
	</Container>
);
