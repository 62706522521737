/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { LooseValue } from 'react-calendar/dist/cjs/shared/types';
import ReactCalendar from 'react-calendar';
import { ReactElement } from 'react';

import './Calendar.css';

interface CalendarProps {
	value: LooseValue | undefined;
	defaultActiveStartDate?: Date;
	defaultValue?: Date;
	range?: boolean;
	onChange: (e: Date | Date[]) => void;
	tileDisabled?: () => boolean;
}

export default function Calendar({
	defaultActiveStartDate,
	range = false,
	defaultValue,
	value,
	tileDisabled = () => false,
	onChange,
}: CalendarProps): ReactElement {
	return (
		<ReactCalendar
			defaultActiveStartDate={defaultActiveStartDate}
			defaultValue={defaultValue}
			selectRange={range}
			value={value}
			tileDisabled={tileDisabled}
			onChange={(e) => {
				if (Array.isArray(e) && e.length === 2) {
					const from = e[0] as Date;
					const to = e[1] as Date;
					onChange([from, to]);
				} else {
					onChange(e as Date);
				}
			}}
		/>
	);
}
