/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { StyleProp, Container, ContainerProps, Pill } from 'components';
import { theme } from 'styles.v2/theme';
import React from 'react';

import { IconComponent } from '../icons';

export type SegmentedControlType = 'dark' | 'light' | null;

type SegmentedControlOwnProps<OptionType extends OptionTypeBase> = {
	onChange?: (value: OptionType) => void;
	options: OptionType[];
	value?: OptionType;
	name: string;
	type?: SegmentedControlType;
	children?: ((opts: { checked?: boolean }) => React.ReactNode) | React.ReactNode;
};
export type SegmentedControlProps<OptionType extends OptionTypeBase> = SegmentedControlOwnProps<OptionType> &
	Omit<ContainerProps, 'css' | keyof SegmentedControlOwnProps<OptionType>>;

interface OptionTypeBase {
	label: string;
	value: string;
	icon?: IconComponent;
	count?: number;
	disabled?: boolean;
	beta?: boolean;
}

export function SegmentedControl<OptionType extends OptionTypeBase>({
	options,
	disabled,
	onChange,
	name,
	value,
	type,
	sx,
	width = '100%',
	variant = 'medium',
	...props
}: SegmentedControlProps<OptionType>): JSX.Element {
	const defaultSx = (checked: boolean, index: number, disabled: boolean): StyleProp => ({
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		px: 'medium',
		color: checked ? 'neutral000' : 'neutral600',
		bg: checked ? 'slate' : 'neutral100',
		backgroundImage: checked || disabled ? 'none' : 'linear-gradient(180deg, #FFF 0%, #F4F7FB 100%)',

		border: '0 solid',
		borderLeftWidth: index > 0 ? 1 : 0,
		borderColor: 'neutral200',
		textAlign: 'center',
		cursor: checked || disabled ? 'default' : 'pointer',
		opacity: disabled ? '.3' : 'unset',
		':focus-within': {
			boxShadow: `inset 0 0 0 2px ${theme.colors.slate}`,
		},

		input: {
			opacity: 0,
			clip: 'rect(0,0,0,0)',
			position: 'absolute',
		},
		...sx,
	});
	const lightSx = (checked: boolean, disabled: boolean): StyleProp => ({
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		px: 'medium',
		color: checked ? 'neutral800' : disabled ? 'neutral400' : 'neutral600',

		borderLeft: checked ? '1px solid' : '0 solid',
		borderRight: checked ? '1px solid' : '0 solid',
		borderTop: checked ? '1px solid' : '0 solid',
		borderBottom: checked ? '0 solid' : '1px solid',

		// borderLeftWidth: index > 0 ? 1 : 0,
		borderColor: 'neutral300',
		textAlign: 'center',
		cursor: checked || disabled ? 'default' : 'pointer',

		':hover': {
			color: disabled ? 'neutral160' : 'purple700',
		},

		input: {
			opacity: 0,
			clip: 'rect(0,0,0,0)',
			position: 'absolute',
		},

		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		...sx,
	});
	const darkSx = (checked: boolean, disabled: boolean): StyleProp => ({
		fontFamily: 'body',
		lineHeight: 'bodySmall',
		fontSize: 1,
		fontWeight: 'strong',
		display: 'flex',
		maxHeight: 32,
		minWidth: 10,
		alignItems: 'center',
		justifyContent: 'center',
		px: 'medium',
		color: checked ? 'neutral000' : 'neutral200',
		bg: checked ? 'neutral600' : 'transparent',

		border: '0 solid',
		borderColor: 'neutral200',
		textAlign: 'center',
		cursor: checked || disabled ? 'default' : 'pointer',
		opacity: disabled ? '.3' : 'unset',

		':focus-within': {
			boxShadow: `inset 0 0 0 2px ${theme.colors.neutral600}`,
		},

		input: {
			opacity: 0,
			clip: 'rect(0,0,0,0)',
			position: 'absolute',
		},
		...sx,
	});
	const usesCount = options.some((o) => o.count !== undefined && o.count >= 0);
	return (
		<Container
			display={'flex'}
			flexDirection="row"
			width={width}
			sx={((): StyleProp => {
				let sxToUse: StyleProp = {
					borderRadius: 4,
					border: '1px solid',
					borderColor: 'neutral300',
					overflow: 'hidden',
				};
				switch (type) {
					case 'dark':
						sxToUse = { borderRadius: 3, border: '1px solid', borderColor: 'neutral600', overflow: 'hidden' };
						break;
					case 'light':
						sxToUse = { overflow: 'hidden' };
						break;
				}
				return sxToUse;
			})()}
		>
			{options.map((option, index) => {
				const checked = option.value === value?.value;
				const Icon = option.icon;
				return (
					<Container
						as="label"
						flex={type === 'light' ? '0 1 auto' : '1 1 50%'}
						width={type === 'light' ? 'unset' : '100%'}
						tx="segmentedControl"
						key={option.value}
						variant={variant}
						sx={((): StyleProp => {
							let sxToUse = defaultSx(checked, index, !!option.disabled || !!disabled);
							switch (type) {
								case 'dark':
									sxToUse = darkSx(checked, !!option.disabled || !!disabled);
									break;
								case 'light':
									sxToUse = lightSx(checked, !!option.disabled || !!disabled);
									break;
							}
							return sxToUse;
						})()}
						{...props}
					>
						<input
							type="radio"
							name={name}
							value={option.value}
							tabIndex={0}
							checked={checked}
							disabled={!!disabled || !!option.disabled}
							onChange={() => onChange && onChange(option)}
						/>
						{Icon && <Icon sx={{ minWidth: 20 }} mr={'xxSmall'} />}
						{option.label}
						{option.beta && (
							<Pill backgroundColor="purple200" color="slate" sx={{ ml: 'xxSmall' }} onClick={() => {}}>
								beta
							</Pill>
						)}
						{usesCount && (option.count && option.count > 0 ? ` (${option.count})` : ' (0)')}
					</Container>
				);
			})}
		</Container>
	);
}

SegmentedControl.displayName = 'SegmentedControl';
