/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { useEffect, useState } from 'react';

type UseKeyboardNavigationReturnType = [
	hoveredIndex: number | undefined,
	setHoveredIndex: (index: number | undefined) => void,
];

export default function useKeyboardNavigation(
	numberItems: number,
	onSelect: (index: number) => void,
	initialIndex?: number,
): UseKeyboardNavigationReturnType {
	const [hoveredIndex, setHoveredIndex] = useState<number | undefined>(initialIndex);

	const keyDownListener = (event: KeyboardEvent): void => {
		if (event.key === 'ArrowDown') {
			setHoveredIndex(((hoveredIndex ?? -1) + 1) % numberItems);
		} else if (event.key === 'ArrowUp') {
			const newIndex = ((hoveredIndex ?? numberItems) - 1) % numberItems;
			setHoveredIndex(newIndex < 0 ? numberItems - 1 : newIndex);
		} else if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
			onSelect(hoveredIndex ?? 0);
		} else {
			setHoveredIndex(undefined);
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', keyDownListener, true);
		return () => window.removeEventListener('keydown', keyDownListener, true);
	}, [keyDownListener]);

	useEffect(() => setHoveredIndex(initialIndex), [numberItems]);

	return [hoveredIndex, setHoveredIndex];
}
