/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

export interface DownloadArgs {
	mediaType: string;
	fileName: string;
	fileContent: string;
}

export function download(args: DownloadArgs): void {
	const element = document.createElement('a');
	element.setAttribute('href', `data:${args.mediaType};charset=utf-8,${encodeURIComponent(args.fileContent)}`);
	element.setAttribute('download', args.fileName);
	element.style.display = 'none';
	document.body.appendChild(element);

	element.click();

	document.body.removeChild(element);
}
