/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import React, { ForwardedRef, forwardRef, useMemo, useRef, useState } from 'react';
import { VisuallyHidden } from '@reach/visually-hidden';
import { AnimatePresence, motion } from 'framer-motion';
import { usePopper } from 'react-popper';
import mergeRefs from 'react-merge-refs';
import { useCombobox } from 'downshift';
import { theme } from 'styles.v2/theme';
import ReactDOM from 'react-dom';

import { filterOptionsRecursive, flattenOptions, isGroup } from './utils';
import { IconArrowDropDown, IconComponent, IconRemove } from '../icons';
import { ButtonIcon, Container, StyleProp, TextProps } from '..';
import { SelectOptionGroup } from './SelectOptionGroup';
import { SelectOptions } from './SelectOptions';
import { SelectOption } from './SelectOption';
import * as Types from './SelectOptionTypes';
import { TextField } from '../TextField';
import { Spinner } from '../Spinner';
import { Text } from '../Text';

export type ComboboxOptionComponent<OptionType extends Types.OptionTypeBase> = React.FunctionComponent<{
	option: Types.SelectOption<OptionType>;
	inputValue: string;
}>;

export interface ComboContainerProps<OptionType extends Types.OptionTypeBase>
	extends Omit<TextProps, 'css' | 'value' | 'onChange'> {
	disabled?: boolean;
	options: Types.SelectOptions<OptionType>[];
	placeholder?: string;
	value?: Types.SelectOption<OptionType> | null | undefined;
	onChange: (value: Types.SelectOption<OptionType> | null | undefined) => void;
	hasError?: boolean;
	noResultContent?: React.ReactNode;
	label?: string;
	menuWidth?: number | string;
	verticalGroups?: boolean;
	optionComponent?: ComboboxOptionComponent<OptionType>;
	loading?: boolean;
	clearable?: boolean;
	creatable?: boolean | ((inputValue: string) => string);
	highlightedSelectionStyle?: boolean;
	onCreate?: (inputValue: string) => void;
	iconLeft?: IconComponent;
	iconLeftColor?: string;
	hideIconArrowDown?: boolean;
	inputSx?: StyleProp;
}

export interface ComboboxPlainProps<T extends Types.OptionTypeBase> extends ComboContainerProps<T> {
	onInputValueChange: (inputValue?: string) => void;
}

const hasSelectionStyle: StyleProp = {
	fontWeight: 'strong',
	borderColor: 'cyanDark',
	boxShadow: `inset 0 0 0 1px ${theme.colors.cyanDark}`,

	':focus': {
		outline: 'none',
		border: '1px solid',
		borderColor: 'cyanDark',
		boxShadow: `inset 0 0 0 1px ${theme.colors.cyanDark}`,
	},
};

const defaultNoResultContent = (
	<Text variant="medium" color="neutral600">
		No Results
	</Text>
);

function selectRangeAll(input: HTMLInputElement | null): void {
	try {
		if (input) {
			input.setSelectionRange(0, input.value.length);
		}
	} catch (err) {
		// Silently ignoring this
	}
}

export const CREATABLE_OPTION_KEY = '__CREATEABLE_OPTION_KEY__';
export const CREATABLE_OPTION = {
	label: CREATABLE_OPTION_KEY,
	value: CREATABLE_OPTION_KEY,
};

const ComboboxInternal = function Combobox<OptionType extends Types.OptionTypeBase>(
	{ options, creatable, hideIconArrowDown, ...props }: ComboContainerProps<OptionType>,
	ref: ForwardedRef<HTMLInputElement>,
): JSX.Element {
	const [inputItems, setInputItems] = useState<typeof options>([]);
	const onInputValueChange = React.useCallback(
		(inputValue?: string): void => {
			const items = inputValue
				? filterOptionsRecursive(options, (option) => option.label.toLowerCase().includes(inputValue.toLowerCase()))
				: options;
			setInputItems(items);
		},
		[options],
	);

	return (
		<ComboboxPlain
			options={inputItems}
			onInputValueChange={onInputValueChange}
			creatable={creatable}
			hideIconArrowDown={hideIconArrowDown}
			{...props}
			ref={ref}
		/>
	);
};

/**
 *
 * 🚨 ATTENTION 🚨
 *
 * There is an open ADT for this component: https://www.notion.so/steadybit/Multiple-Inputs-for-similar-Usecases-DropDown-ComboBox-6fa13829ba3b438db6598e902ce62d65
 * It triggers as soon as there are changes to this component.
 * So if you need to make changes, please refactor the stuff first and resolve the ADT.
 *
 * all affected components have this comment.
 *
 * 	-Johannes
 */
export const Combobox = forwardRef(ComboboxInternal) as (<OptionType extends Types.OptionTypeBase>(
	props: ComboContainerProps<OptionType> & { ref?: React.ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof ComboboxInternal>) & { displayName: string };

function ComboboxPlainInternal<OptionType extends Types.OptionTypeBase>(
	{
		options,
		value,
		onChange,
		hasError,
		label,
		placeholder,
		noResultContent = defaultNoResultContent,
		menuWidth,
		verticalGroups,
		optionComponent,
		loading,
		creatable,
		clearable,
		highlightedSelectionStyle = false,
		onInputValueChange,
		onCreate = () => {},
		disabled,
		name,
		variant = 'medium',
		iconLeft,
		iconLeftColor,
		hideIconArrowDown,
		inputSx,
		...props
	}: ComboboxPlainProps<OptionType>,
	ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
	const OptionComponent = optionComponent;
	const inputRef = useRef<HTMLInputElement>(null);
	const [inputWasChanged, setInputWasChanged] = useState(false);
	const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
	const {
		styles,
		attributes,
		update: updatePopper,
	} = usePopper(inputRef.current, popperElement, {
		placement: 'bottom-start',
	});

	const flattenedInputItems = useMemo(() => {
		if (creatable) {
			return [...flattenOptions(options), CREATABLE_OPTION as Types.SelectOptions<OptionType>];
		}
		return flattenOptions(options);
	}, [options, creatable]);

	const {
		isOpen,
		selectedItem,
		getLabelProps,
		getMenuProps,
		getInputProps,
		openMenu,
		inputValue,
		setInputValue,
		highlightedIndex,
		getComboboxProps,
		getItemProps,
		selectItem,
	} = useCombobox<Types.SelectOptions<OptionType> | null>({
		items: flattenedInputItems,
		selectedItem: value,
		defaultHighlightedIndex: flattenedInputItems.length > 0 ? 0 : undefined,
		initialHighlightedIndex: 0,
		initialSelectedItem: value,
		itemToString: (item) => (item ? item.label : ''),
		onSelectedItemChange: (item) => {
			if (isGroup(item.selectedItem)) {
				return;
			}

			if (item.selectedItem === CREATABLE_OPTION) {
				onCreate(inputValue);
			} else {
				onChange(item.selectedItem);
				inputRef?.current?.blur();
			}
		},
		onIsOpenChange: (changes) => {
			if (changes.isOpen) {
				if (changes.selectedItem) {
					onInputValueChange();
					selectRangeAll(inputRef.current);
				} else {
					onInputValueChange(changes.inputValue);
				}
			} else {
				if (changes.selectedItem && changes.selectedItem !== CREATABLE_OPTION) {
					setInputValue(changes.selectedItem.label);
					setInputWasChanged(false);
				} else if (!changes.selectedItem && !selectedItem) {
					setInputValue('');
				}
				setVerticalNavigation([]);
			}
		},
		onInputValueChange: ({ inputValue }) => {
			setInputWasChanged(true);
			onInputValueChange(inputValue);
			setVerticalNavigation([]);
		},
	});

	React.useEffect(() => {
		if (isOpen) {
			updatePopper?.();
		}
	}, [isOpen, updatePopper]);

	const verticalNavigationActive = useMemo(
		() => Boolean(verticalGroups && (inputValue === '' || (selectedItem?.label === inputValue && !inputWasChanged))),
		[inputValue, selectedItem, inputWasChanged, verticalGroups],
	);
	const [verticalNavigation, setVerticalNavigation] = useState<number[]>([]);

	const inputValueMatchesExistingOptionEntry = useMemo(
		() => Boolean(creatable && options.find((option) => option.label === inputValue)),
		[inputValue, options, creatable],
	);

	let optionIndex = -1;
	const renderOptions = (options: Types.SelectOptions<OptionType>[], level = 0): React.ReactNode => {
		return options.map((item, index) => {
			if (isGroup(item)) {
				return (
					<SelectOptionGroup
						active={verticalNavigation[level] === index}
						inActivePath={level + 1 < verticalNavigation.length}
						vertical={verticalNavigationActive}
						onLabelClick={() => setVerticalNavigation([...verticalNavigation, index])}
						onBackClick={() => setVerticalNavigation(verticalNavigation.slice(0, verticalNavigation.length - 1))}
						key={item.label}
						label={item.label}
					>
						{renderOptions(item.options, level + 1)}
					</SelectOptionGroup>
				);
			}

			optionIndex++;
			return (
				<SelectOption
					key={`${item.value}${optionIndex}`}
					focused={highlightedIndex === optionIndex}
					{...getItemProps({
						index: optionIndex,
						item,
						selected: selectedItem === item,
						disabled: item.disabled,
					})}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					data-private={props['data-private']}
				>
					{creatable && item === CREATABLE_OPTION ? (
						<Text color="slate" variant={'mediumStrong'} py="small">
							{creatable ? (typeof creatable === 'function' ? creatable(inputValue) : `Create "${inputValue}"`) : null}
						</Text>
					) : OptionComponent ? (
						<OptionComponent inputValue={inputValue} option={item} />
					) : (
						item.label
					)}
				</SelectOption>
			);
		});
	};

	return (
		<Container tx={'combobox'} variant={variant} {...props} sx={{ position: 'relative', ...props.sx }} data-combobox>
			<VisuallyHidden>
				<label {...getLabelProps()}>{label}</label>
			</VisuallyHidden>
			<div {...getComboboxProps()}>
				<TextField
					name={name}
					hasError={hasError}
					placeholder={placeholder}
					iconLeft={iconLeft}
					iconLeftColor={iconLeftColor}
					iconRight={hideIconArrowDown ? undefined : IconArrowDropDown}
					onIconRightClick={() => {
						openMenu();
						if (inputRef.current) {
							inputRef.current.focus();
						}
					}}
					sx={{
						...(highlightedSelectionStyle &&
						!hasError &&
						selectedItem &&
						selectedItem.label &&
						selectedItem.label === inputValue
							? hasSelectionStyle
							: {}),
						...inputSx,
					}}
					{...getInputProps({
						ref: mergeRefs([inputRef, ref]),
						onBlur: () => setInputWasChanged(false),
						onFocus: () => {
							if (!isOpen) {
								openMenu();
							}
						},
					})}
					disabled={disabled}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					data-private={props['data-private']}
				/>
				{!disabled && clearable && !loading && value ? (
					<ButtonIcon
						tabIndex={-1}
						aria-label="Clear selection"
						onClick={() => selectItem(null)}
						sx={{ position: 'absolute', right: 24, top: 4 }}
						data-combobox-remove-icon
					>
						<IconRemove />
					</ButtonIcon>
				) : null}
				<AnimatePresence>
					{loading ? (
						<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
							<Spinner variant="small" color="neutral600" sx={{ position: 'absolute', right: 34, top: 12 }} />
						</motion.div>
					) : null}
				</AnimatePresence>
			</div>
			{ReactDOM.createPortal(
				<Container
					sx={{ zIndex: 42, position: 'absolute', top: '100%', left: 0, right: 0 }}
					width={menuWidth || inputRef?.current?.clientWidth || 1}
					style={styles.popper}
					{...attributes.popper}
					{...getMenuProps({
						ref: (element) => {
							if (element && !popperElement) {
								setPopperElement(element);
							}
						},
					})}
				>
					<AnimatePresence>
						{isOpen && (
							<SelectOptions vertical={verticalNavigationActive} level={verticalNavigation.length} variant={variant}>
								{flattenedInputItems.length ? (
									renderOptions(
										creatable && inputValue && !inputValueMatchesExistingOptionEntry
											? [...options, CREATABLE_OPTION as Types.SelectOptions<OptionType>]
											: options,
									)
								) : (
									<Container px="small" py="xSmall">
										{noResultContent}
									</Container>
								)}
							</SelectOptions>
						)}
					</AnimatePresence>
				</Container>,
				document.body,
			)}
		</Container>
	);
}

export const ComboboxPlain = forwardRef(ComboboxPlainInternal) as <OptionType extends Types.OptionTypeBase>(
	props: ComboboxPlainProps<OptionType> & { ref?: React.ForwardedRef<HTMLInputElement> },
) => ReturnType<typeof ComboboxPlainInternal>;
