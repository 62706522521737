/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

export function getGroupLayer(depth: number): number {
	return depth * 2;
}

export function getGroupInnerLayer(depth: number): number {
	return depth * 2 + 1;
}

export function getGroupHoverLayer(depth: number): number {
	return depth * 2 + 1.5; // between group n and n+1
}

export const targetLayer = 100;
export const targetIconsLayer = 101;
export const overlayLayer = 102;
export const overlayIconLayer = 103;
export const targetHoverLayer = 104;
export const targetHoverIconsLayer = 105;
