/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import {
	History,
	LocationDescriptorObject,
	Location,
	Action,
	Path,
	TransitionPromptHook,
	UnregisterCallback,
	LocationListener,
	Href,
} from 'history';
import {
	CommonOpts,
	LocationDescriptorObjectWithUrlParams,
	LocationDescriptorWithUrlParams,
	LocationWithUrlParams,
} from 'url/type';
import { encodeQueryParameters, stringify } from 'url/stringifier';
import { parse, parseSearch } from 'url/parser';

import { clone } from './clone';

export interface HistoryWithUrlParams<T> extends History<T> {
	config: CommonOpts;
	action: Action;
	location: LocationWithUrlParams<T>;
	push(location: Path | LocationDescriptorWithUrlParams<T>, state?: T): void;
	replace(location: Path | LocationDescriptorWithUrlParams<T>, state?: T): void;
	block(prompt?: boolean | string | TransitionPromptHook<T>): UnregisterCallback;
	listen(listener: LocationListener<T>): UnregisterCallback;

	createHref(location: LocationDescriptorObjectWithUrlParams<T>): Href;
	createHref(mutatorFunction: (location: LocationWithUrlParams<T>) => void): string;
}

export function wrap<T>(originalHistory: History<T>, options: CommonOpts): HistoryWithUrlParams<T> {
	const wrappedHistory: HistoryWithUrlParams<T> = {
		get config() {
			return options;
		},

		get action() {
			return originalHistory.action;
		},

		get length() {
			return originalHistory.length;
		},

		get location(): LocationWithUrlParams<T> {
			return fromHistoryFormat(originalHistory.location, options);
		},

		listen(listener) {
			return originalHistory.listen((location, action) => listener(fromHistoryFormat(location, options), action));
		},

		push(to, state) {
			if (typeof to !== 'string') {
				return originalHistory.push(toHistoryFormat(to, options));
			}
			return originalHistory.push(to, state);
		},

		replace(to, state) {
			if (typeof to !== 'string') {
				return originalHistory.replace(toHistoryFormat(to, options));
			}
			return originalHistory.replace(to, state);
		},

		go(...args) {
			return originalHistory.go(...args);
		},

		goBack(...args) {
			return originalHistory.goBack(...args);
		},

		goForward(...args) {
			return originalHistory.goForward(...args);
		},

		block(blocker) {
			if (typeof blocker !== 'function') {
				return originalHistory.block(blocker);
			}

			return originalHistory.block((location, action) => blocker(fromHistoryFormat(location, options), action));
		},

		createHref,
	};

	function createHref(mutatorFunction: (location: LocationWithUrlParams<T>) => void): string;
	function createHref(to: LocationDescriptorObjectWithUrlParams<T>): string;
	function createHref(
		toOrMutator: LocationDescriptorObjectWithUrlParams<T> | ((location: LocationWithUrlParams<T>) => void),
	): string {
		if (typeof toOrMutator === 'function') {
			const mutator = toOrMutator as (location: LocationWithUrlParams<T>) => void;
			const location = clone(wrappedHistory.location);
			mutator(location);
			return wrappedHistory.createHref(location);
		}
		if (typeof toOrMutator !== 'string') {
			toOrMutator = toHistoryFormat(toOrMutator, options);
		}
		return originalHistory.createHref(toOrMutator);
	}

	return wrappedHistory;
}

function fromHistoryFormat<T>(location: Location<T>, options: CommonOpts): LocationWithUrlParams<T> {
	return {
		...location,
		...parse(location.pathname, location.search, options),
	};
}

function toHistoryFormat<T>(
	location: LocationDescriptorObjectWithUrlParams<T>,
	options: CommonOpts,
): LocationDescriptorObject<T> {
	const query = {
		...parseSearch(location.search),
		...(location.query || {}),
	};
	return {
		pathname: stringify(
			{
				pathname: location.pathname,
				matrix: location.matrix,
			},
			options,
		),
		search: encodeQueryParameters(query),
		state: location.state,
		hash: location.hash,
		key: location.key,
	};
}
