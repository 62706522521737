/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import qs from 'qs';

import { CommonOpts, MatrixParameters, Parameters, UrlParams } from './type';

const qsOptions = { delimiter: ';' };

export function parse(
	pathname: string | undefined,
	searchStr?: string,
	{ useAbsolutePaths }: CommonOpts = {},
): UrlParams {
	const query = parseSearch(searchStr);
	if (!pathname || !pathname.startsWith('/')) {
		return {
			pathname: '',
			query,
			matrix: {},
		};
	}

	const matrix: MatrixParameters = {};

	let pathnameWithoutMatrixParameters = '';
	pathname
		.split('/')
		.slice(1)
		.forEach((segment) => {
			const segmentResult = parseSegment(segment);
			pathnameWithoutMatrixParameters = `${pathnameWithoutMatrixParameters}/${segmentResult.pathname}`;

			if (useAbsolutePaths) {
				matrix[pathnameWithoutMatrixParameters] = segmentResult.matrix;
			} else {
				const segmentPathname = `/${segmentResult.pathname}`;
				if (matrix[segmentPathname]) {
					matrix[segmentPathname] = {
						...matrix[segmentPathname],
						...segmentResult.matrix,
					};
				} else {
					matrix[segmentPathname] = segmentResult.matrix;
				}
			}
		});

	return {
		pathname: pathnameWithoutMatrixParameters,
		query,
		matrix,
	};
}

export function parseSearch(str?: string): Parameters {
	if (str == null) {
		return {};
	}

	if (str.startsWith('?')) {
		str = str.substring(1);
	}

	return qs.parse(str);
}

interface Segment {
	pathname: string;
	matrix: Parameters;
}

function parseSegment(segment: string): Segment {
	const index = segment.indexOf(';');
	if (index < 0) {
		return {
			pathname: segment,
			matrix: {},
		};
	}

	return {
		pathname: segment.substring(0, index),
		matrix: qs.parse(segment.substring(index + 1), qsOptions),
	};
}
