/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { BasicStyleProperties, InteractionTypes, resolveTxAndVariant } from 'components/types';
import React, { ReactNode, Ref, forwardRef } from 'react';
import { Button } from 'theme-ui';

export interface BasicButtonProps extends BasicStyleProperties, InteractionTypes<HTMLButtonElement> {
	alignItems?: 'center' | 'flex-start' | 'flex-end';
	display?: string;
	flex?: string;
	flexShrink?: number;
	justifyContent?: string;
	title?: string;
	type?: 'button' | 'submit' | 'reset';
	children?: ReactNode;
	disabled?: boolean;

	ref?: Ref<HTMLButtonElement>;
	variant?: string;
	as?: React.ElementType;
	tx?: string;
	autoFocus?: boolean;
}

export const BasicButton = forwardRef<HTMLButtonElement, BasicButtonProps>(
	(
		{
			children,
			backgroundColor,
			bg,
			color,
			height,
			minHeight,
			maxHeight,
			width,
			minWidth,
			maxWidth,
			m,
			mb,
			ml,
			mr,
			mt,
			mx,
			my,
			p,
			pb,
			pl,
			pr,
			pt,
			px,
			py,
			overflow,
			overflowX,
			overflowY,
			opacity,
			tabIndex,
			display,
			flex,
			flexShrink,
			alignItems,
			justifyContent,
			sx = {},
			variant,
			tx,
			...props
		},
		ref,
	) => {
		return (
			<Button
				ref={ref}
				{...props}
				sx={{
					backgroundColor,
					bg,
					color,
					height,
					minHeight,
					maxHeight,
					width,
					minWidth,
					maxWidth,
					m,
					mb,
					ml,
					mr,
					mt,
					mx,
					my,
					p,
					pb,
					pl,
					pr,
					pt,
					px,
					py,
					overflow,
					overflowX,
					overflowY,
					opacity,
					tabIndex,
					display,
					flex,
					flexShrink,
					alignItems,
					justifyContent,
					...sx,
				}}
				variant={resolveTxAndVariant(tx, variant)}
			>
				{children}
			</Button>
		);
	},
);
BasicButton.displayName = 'BasicButton';
