/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	Container,
	Pagination,
	ShortenedText,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	TableSort,
	Tooltip,
} from 'components';
import { AdviceStatusVO, TargetPredicateVO, TargetTypeDescriptionVO, TargetVO } from 'ui-api';
import { localeCompareIgnoreCase, toTitleCase } from 'utils/string';
import TableLoadingRow from 'components/Table/TableLoadingRow';
import AdviceIconWrapper from 'components/advice/AdviceIcon';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { useDimensions } from 'utils/hooks/useDimensions';
import { usePromise } from 'utils/hooks/usePromise';
import { PageParams } from 'utils/hooks/usePage';
import { ReactElement, useState } from 'react';
import { useUrlState } from 'url/useUrlState';
import { Services } from 'services/services';
import { toTargetId } from 'targets/util';

import { UrlState, selectedTargetIdParam } from '../urlParams';

interface TargetTableWrapperProps {
	targetDefinition: TargetTypeDescriptionVO;
	predicate: TargetPredicateVO;
	filterStatus: string[];
	searchQuery: string;
	adviceType: string;
	targetType: string;
	numItems: number;
}

type SortAttribute = {
	attribute: string | null;
	direction: 'asc' | 'desc';
};

export default function TargetTableWrapper(props: TargetTableWrapperProps): ReactElement | null {
	const [divRef, [tableWidth]] = useDimensions<HTMLDivElement>();
	return (
		<Container ref={divRef} minWidth="100%">
			<TargetTable tableWidth={tableWidth} {...props} />
		</Container>
	);
}

interface TargetTableProps extends TargetTableWrapperProps {
	tableWidth: number;
}

function TargetTable({
	targetDefinition,
	filterStatus,
	searchQuery,
	adviceType,
	tableWidth,
	targetType,
	predicate,
	numItems,
}: TargetTableProps): ReactElement {
	const columns = targetDefinition.table.columns;

	const [, , updateUrlState] = useUrlState<UrlState>([selectedTargetIdParam]);

	const [hoveredTarget, setHoveredTarget] = useState<number | null>(null);

	const [page, setPage] = useState(0);
	const [sortAttribute, setSortAttribute] = useState<SortAttribute>({
		attribute: columns[0].attribute,
		direction: 'asc',
	});

	const requiredAttributes = targetDefinition.table.columns.reduce((agg, column) => {
		agg.push(column.attribute, ...(column.fallbackAttributes ?? []));
		return agg;
	}, [] as string[]);

	requiredAttributes.push(
		'advice.status.action-needed',
		'advice.status.validation-needed',
		'advice.status.implemented',
	);
	requiredAttributes.sort(localeCompareIgnoreCase);

	const [updateSignal, setUpdateSignal] = useState(0);
	const targetsResult = usePromise(
		() =>
			Services.targets.fetchAdviceTargets(
				predicate,
				adviceType,
				targetType,
				requiredAttributes,
				searchQuery,
				new PageParams(page, 20, [[`attributes[${sortAttribute.attribute}]`, sortAttribute.direction]]),
				filterStatus,
			),
		[
			predicate,
			adviceType,
			targetType,
			requiredAttributes.join(','),
			searchQuery,
			page,
			sortAttribute.attribute,
			sortAttribute.direction,
			filterStatus.join(','),
			updateSignal,
		],
	);
	useEventEffect(
		(e) => {
			const allShownReferences = targetsResult.value?.content.map((t) => t.name) ?? [];
			if (e.targetReference && allShownReferences.includes(e.targetReference)) {
				setUpdateSignal((signal) => signal + 1);
			}
		},
		['advice-updated'],
		() => {},
		[targetsResult.value?.content],
	);

	const numColumns = columns.length;
	const attributeToSort: string = sortAttribute.attribute || columns[0].attribute;

	return (
		<>
			<Table>
				<TableHead>
					<TableRow>
						{columns.map((column, i) => {
							const header = column.attribute;
							return (
								<TableHeadCell key={i} width={tableWidth / numColumns} sx={{ minHeight: '26px !important' }}>
									<TableSort
										sort={attributeToSort === header ? sortAttribute.direction : undefined}
										onClick={() => {
											setSortAttribute({
												attribute: header,
												direction:
													attributeToSort === header ? (sortAttribute.direction === 'asc' ? 'desc' : 'asc') : 'asc',
											});
										}}
									>
										{toTitleCase(header)}
									</TableSort>
								</TableHeadCell>
							);
						})}
						<TableHeadCell width="60px" sx={{ minHeight: '26px !important' }}>
							Status
						</TableHeadCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{!targetsResult.value ? (
						<LoadingRows numRows={Math.min(20, numItems)} numColumns={numColumns + 1} />
					) : (
						targetsResult.value?.content.map((t, i) => {
							const isHovered = hoveredTarget === i;

							return (
								<TableRow
									key={i}
									hoverable
									sx={{
										bg: i % 2 === 0 ? 'neutral000' : 'neutral100',
										cursor: 'pointer',
										'&:hover': {
											bg: 'neutral200',
										},
									}}
									onClick={() => updateUrlState({ selectedTargetId: toTargetId(t) }, false)}
									onMouseEnter={() => setHoveredTarget(i)}
									onMouseLeave={() => setHoveredTarget(null)}
								>
									{columns.map((column, i) => {
										const value = t.attributes.find((a) => a.key === column.attribute)?.value;

										let content = null;
										if (!value && i === 0) {
											content = (
												<ShortenedText noWrap variant="medium" sx={{ fontStyle: 'italic' }} ml="xSmall">
													{t.name ? t.name : `Unnamed target ${t.type} - ${t.agentId}`}
												</ShortenedText>
											);
										} else {
											content = (
												<ShortenedText
													noWrap
													fontFamily="monospace"
													variant={isHovered || isHovered ? 'smallStrong' : 'small'}
													color={isHovered ? 'slate' : 'neutral700'}
													ml="xSmall"
												>
													{value}
												</ShortenedText>
											);
										}

										if (i === 0) {
											content = (
												<Tooltip content={t.name || t.type}>
													<Stack direction="horizontal" size="xSmall" alignItems="center">
														{content}
													</Stack>
												</Tooltip>
											);
										}

										return (
											<TableDataCell
												key={i}
												maxWidth={tableWidth / numColumns}
												sx={{
													minHeight: '26px !important',
												}}
												style={{
													paddingLeft: 0,
													paddingRight: '8px',
												}}
											>
												{content}
											</TableDataCell>
										);
									})}

									<TableDataCell width="60px" justifyContent="center" sx={{ minHeight: '26px !important' }}>
										<AdviceStatus adviceType={adviceType} target={t} />
									</TableDataCell>
								</TableRow>
							);
						})
					)}

					{}
				</TableBody>
			</Table>
			{targetsResult.value && targetsResult.value.totalPages > 1 && (
				<Container sx={{ px: 'medium', pt: 'xSmall' }}>
					<Pagination size="small" activePage={page} totalPages={targetsResult.value.totalPages} onClick={setPage} />
				</Container>
			)}
		</>
	);
}

interface AdviceStatusProps {
	adviceType: string;
	target: TargetVO;
}

function AdviceStatus({ adviceType, target }: AdviceStatusProps): ReactElement | null {
	const status: AdviceStatusVO | undefined = getStatus(target, adviceType);
	if (!status) {
		return null;
	}
	return <AdviceIconWrapper variant="xSmall" status={status} />;
}

function getStatus(target: TargetVO, adviceType: string): AdviceStatusVO | undefined {
	for (let i = 0; i < target.attributes.length; i++) {
		const { key, value } = target.attributes[i];
		if (key === 'advice.status.action-needed' && value === adviceType) {
			return 'ACTION_NEEDED';
		}
		if (key === 'advice.status.validation-needed' && value === adviceType) {
			return 'VALIDATION_NEEDED';
		}
		if (key === 'advice.status.implemented' && value === adviceType) {
			return 'IMPLEMENTED';
		}
	}
}

function LoadingRows({ numRows, numColumns }: { numRows: number; numColumns: number }): ReactElement {
	const rows = [];
	for (let i = 0; i < numRows; i++) {
		rows.push(<TableLoadingRow key={i} variant="small" numColumns={numColumns} />);
	}

	return <>{rows}</>;
}
