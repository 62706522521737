/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { AdviceValidationStateVO, AdviceListItemVO, AdviceVO, TargetPredicateVO, TargetTypeCategoryVO } from 'ui-api';
import axios from 'axios';

export class AdviceApi {
	async getAdviceListItems(predicate: TargetPredicateVO): Promise<AdviceListItemVO[]> {
		return (await axios.post<AdviceListItemVO[]>('/ui/advice', predicate)).data;
	}

	async getTargetTypes(
		predicate: TargetPredicateVO,
		adviceType: string,
		query: string,
		filteredStatus: string[],
	): Promise<TargetTypeCategoryVO[]> {
		return (
			await axios.post<TargetTypeCategoryVO[]>('/ui/advice/target_types', {
				predicate,
				adviceType,
				query,
				filteredStatus,
			})
		).data;
	}

	async count(predicate: TargetPredicateVO, adviceType: string, query: string): Promise<{ [index: string]: number }> {
		return (await axios.post<{ [index: string]: number }>('/ui/advice/count', { predicate, adviceType, query })).data;
	}

	async getAdvice(type: string, targetReference: string): Promise<AdviceVO[]> {
		if (!!type && !!targetReference) {
			return (
				await axios.get<AdviceVO[]>('/ui/advice/list', {
					params: {
						type: type,
						targetReference: targetReference,
					},
				})
			).data;
		} else {
			return [];
		}
	}

	async getAdviceValidations(
		type: string,
		targetReference: string,
		adviceDefinitionId: string,
	): Promise<AdviceValidationStateVO[]> {
		if (!!type && !!targetReference && !!adviceDefinitionId) {
			return (
				await axios.get<AdviceValidationStateVO[]>('/ui/advice/validations', {
					params: {
						type: type,
						targetReference: targetReference,
						adviceDefinitionId: adviceDefinitionId,
					},
				})
			).data;
		} else {
			return [];
		}
	}

	async getTargetsWithAdvice(predicate: TargetPredicateVO): Promise<string[]> {
		return (
			await axios.post<string[]>('/ui/advice/targetsWithAdvice', {
				predicate,
			})
		).data;
	}

	async updateAdviceExperimentState(
		type: string,
		targetReference: string,
		adviceDefinitionId: string,
		AdviceExperimentState: AdviceValidationStateVO,
	): Promise<void> {
		return (
			await axios.post<void>('/ui/advice/validation', AdviceExperimentState, {
				params: {
					type: type,
					targetReference: targetReference,
					adviceDefinitionId: adviceDefinitionId,
				},
			})
		).data;
	}

	async createExperiment(
		type: string,
		targetReference: string,
		adviceDefinitionId: string,
		experimentId: string,
		teamId: string,
		environmentId?: string,
	): Promise<void> {
		return (
			await axios.post<void>('/ui/advice/validation/create/experiment', null, {
				params: {
					type: type,
					targetReference: targetReference,
					adviceDefinitionId: adviceDefinitionId,
					experimentId: experimentId,
					environmentId: environmentId,
					teamId: teamId,
				},
			})
		).data;
	}
}
