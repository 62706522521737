/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import ExperimentActionHint from 'pages/experiments/components/experimentActionHint';
import { Text, Container, Stack } from 'components';
import { HintVO } from 'ui-api';
import React from 'react';

import { SettingsGroupItem, SettingsGroupItemProps } from './SettingsGroupItem';
import { IconInformationCircle, IconWarning } from '../icons';
import { Tooltip } from '../Tooltip';

type SettingsGroupItemGridProps = SettingsGroupItemProps & {
	direction?: 'horizontal' | 'vertical';
	deprecationMessage?: string;
	hint?: HintVO | undefined;
	deprecated?: boolean;
	description?: string;
	optional?: boolean;
	label: string;
};

const SettingsGroupItemGrid: React.FC<SettingsGroupItemGridProps> = ({
	label,
	description,
	optional,
	deprecated,
	deprecationMessage,
	hint,
	direction = 'vertical',
	children,
	...props
}) => {
	const labelAndIcons = (
		<Container display="flex" alignItems="center" justifyContent="space-between" pr="xxSmall">
			<Container
				sx={{
					display: 'flex',
					alignItems: 'flex-start',
					justifyContent: 'space-between',
					gap: '8px',
				}}
			>
				<Text variant="smallMedium" sx={{ minWidth: 'min-content' }}>
					{label}
				</Text>

				{optional && (
					<Text variant="smallMedium" sx={{ fontStyle: 'italic', color: 'neutral500' }}>
						(Optional)
					</Text>
				)}

				{description && (
					<Tooltip
						color={'light'}
						placement={'bottom'}
						content={
							<Text variant={'small'} maxWidth={'25em'}>
								{description}
							</Text>
						}
					>
						<div style={{ lineHeight: '16px' }}>
							<IconInformationCircle variant={'small'} ml={'xSmall'} color={'neutral400'} mt={2} />
						</div>
					</Tooltip>
				)}

				{deprecated && (
					<Tooltip
						color={'light'}
						placement={'bottom'}
						content={
							<Text variant={'small'} maxWidth={'25em'}>
								deprecated - {deprecationMessage}
							</Text>
						}
					>
						<IconWarning variant={'small'} ml={'xSmall'} color={'experimentWarning'} mt={2} />
					</Tooltip>
				)}
			</Container>
			{direction === 'horizontal' && children}
		</Container>
	);

	return (
		<SettingsGroupItem {...props}>
			<Stack size="xxSmall">
				{labelAndIcons}

				{direction === 'vertical' && children}

				{hint && (
					<Container pt="xxSmall">
						<ExperimentActionHint hint={hint} />
					</Container>
				)}
			</Stack>
		</SettingsGroupItem>
	);
};

export default SettingsGroupItemGrid;
