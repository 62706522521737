/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Box, Colors, Spacings, Text } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

interface NotFoundResultProps {
	title: string;
	width: string | number | undefined;
}

export default function NotFoundResult({ title, width }: NotFoundResultProps): ReactElement {
	return (
		<Text type="small" style={{ marginLeft: Spacings.small, marginRight: Spacings.small, color: Colors.neutral600 }}>
			{title}
		</Text>
	);
}
