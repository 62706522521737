/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

//polyfills needed for Safari & Edge (only english locale)
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/polyfill';

export interface Timezone {
	timeZone: string;
	offset: string;
}

export const timeZones: Timezone[] = [
	{ timeZone: 'Etc/GMT+12', offset: '-12' },
	{ timeZone: 'Etc/GMT+11', offset: '-11' },
	{ timeZone: 'Pacific/Midway', offset: '-11' },
	{ timeZone: 'Pacific/Niue', offset: '-11' },
	{ timeZone: 'Pacific/Pago_Pago', offset: '-11' },
	{ timeZone: 'Pacific/Samoa', offset: '-11' },
	{ timeZone: 'US/Samoa', offset: '-11' },
	{ timeZone: 'America/Adak', offset: '-10' },
	{ timeZone: 'America/Atka', offset: '-10' },
	{ timeZone: 'Etc/GMT+10', offset: '-10' },
	{ timeZone: 'HST', offset: '-10' },
	{ timeZone: 'Pacific/Fakaofo', offset: '-10' },
	{ timeZone: 'Pacific/Honolulu', offset: '-10' },
	{ timeZone: 'Pacific/Johnston', offset: '-10' },
	{ timeZone: 'Pacific/Rarotonga', offset: '-10' },
	{ timeZone: 'Pacific/Tahiti', offset: '-10' },
	{ timeZone: 'SystemV/HST10', offset: '-10' },
	{ timeZone: 'US/Aleutian', offset: '-10' },
	{ timeZone: 'US/Hawaii', offset: '-10' },
	{ timeZone: 'Pacific/Marquesas', offset: '-9.5' },
	{ timeZone: 'AST', offset: '-9' },
	{ timeZone: 'America/Anchorage', offset: '-9' },
	{ timeZone: 'America/Juneau', offset: '-9' },
	{ timeZone: 'America/Nome', offset: '-9' },
	{ timeZone: 'America/Sitka', offset: '-9' },
	{ timeZone: 'America/Yakutat', offset: '-9' },
	{ timeZone: 'Etc/GMT+9', offset: '-9' },
	{ timeZone: 'Pacific/Gambier', offset: '-9' },
	{ timeZone: 'SystemV/YST9', offset: '-9' },
	{ timeZone: 'SystemV/YST9YDT', offset: '-9' },
	{ timeZone: 'US/Alaska', offset: '-9' },
	{ timeZone: 'America/Dawson', offset: '-8' },
	{ timeZone: 'America/Ensenada', offset: '-8' },
	{ timeZone: 'America/Los_Angeles', offset: '-8' },
	{ timeZone: 'America/Metlakatla', offset: '-8' },
	{ timeZone: 'America/Santa_Isabel', offset: '-8' },
	{ timeZone: 'America/Tijuana', offset: '-8' },
	{ timeZone: 'America/Vancouver', offset: '-8' },
	{ timeZone: 'America/Whitehorse', offset: '-8' },
	{ timeZone: 'Canada/Pacific', offset: '-8' },
	{ timeZone: 'Canada/Yukon', offset: '-8' },
	{ timeZone: 'Etc/GMT+8', offset: '-8' },
	{ timeZone: 'Mexico/BajaNorte', offset: '-8' },
	{ timeZone: 'PST', offset: '-8' },
	{ timeZone: 'PST8PDT', offset: '-8' },
	{ timeZone: 'Pacific/Pitcairn', offset: '-8' },
	{ timeZone: 'SystemV/PST8', offset: '-8' },
	{ timeZone: 'SystemV/PST8PDT', offset: '-8' },
	{ timeZone: 'US/Pacific', offset: '-8' },
	{ timeZone: 'America/Boise', offset: '-7' },
	{ timeZone: 'America/Cambridge_Bay', offset: '-7' },
	{ timeZone: 'America/Chihuahua', offset: '-7' },
	{ timeZone: 'America/Dawson_Creek', offset: '-7' },
	{ timeZone: 'America/Denver', offset: '-7' },
	{ timeZone: 'America/Edmonton', offset: '-7' },
	{ timeZone: 'America/Hermosillo', offset: '-7' },
	{ timeZone: 'America/Inuvik', offset: '-7' },
	{ timeZone: 'America/Mazatlan', offset: '-7' },
	{ timeZone: 'America/Ojinaga', offset: '-7' },
	{ timeZone: 'America/Phoenix', offset: '-7' },
	{ timeZone: 'America/Shiprock', offset: '-7' },
	{ timeZone: 'America/Yellowknife', offset: '-7' },
	{ timeZone: 'Canada/Mountain', offset: '-7' },
	{ timeZone: 'Etc/GMT+7', offset: '-7' },
	{ timeZone: 'MST', offset: '-7' },
	{ timeZone: 'MST7MDT', offset: '-7' },
	{ timeZone: 'Mexico/BajaSur', offset: '-7' },
	{ timeZone: 'Navajo', offset: '-7' },
	{ timeZone: 'PNT', offset: '-7' },
	{ timeZone: 'SystemV/MST7', offset: '-7' },
	{ timeZone: 'SystemV/MST7MDT', offset: '-7' },
	{ timeZone: 'US/Arizona', offset: '-7' },
	{ timeZone: 'US/Mountain', offset: '-7' },
	{ timeZone: 'America/Bahia_Banderas', offset: '-6' },
	{ timeZone: 'America/Belize', offset: '-6' },
	{ timeZone: 'America/Cancun', offset: '-6' },
	{ timeZone: 'America/Chicago', offset: '-6' },
	{ timeZone: 'America/Costa_Rica', offset: '-6' },
	{ timeZone: 'America/El_Salvador', offset: '-6' },
	{ timeZone: 'America/Guatemala', offset: '-6' },
	{ timeZone: 'America/Indiana/Knox', offset: '-6' },
	{ timeZone: 'America/Indiana/Tell_City', offset: '-6' },
	{ timeZone: 'America/Knox_IN', offset: '-6' },
	{ timeZone: 'America/Managua', offset: '-6' },
	{ timeZone: 'America/Matamoros', offset: '-6' },
	{ timeZone: 'America/Menominee', offset: '-6' },
	{ timeZone: 'America/Merida', offset: '-6' },
	{ timeZone: 'America/Mexico_City', offset: '-6' },
	{ timeZone: 'America/Monterrey', offset: '-6' },
	{ timeZone: 'America/North_Dakota/Beulah', offset: '-6' },
	{ timeZone: 'America/North_Dakota/Center', offset: '-6' },
	{ timeZone: 'America/North_Dakota/New_Salem', offset: '-6' },
	{ timeZone: 'America/Rainy_River', offset: '-6' },
	{ timeZone: 'America/Rankin_Inlet', offset: '-6' },
	{ timeZone: 'America/Regina', offset: '-6' },
	{ timeZone: 'America/Resolute', offset: '-6' },
	{ timeZone: 'America/Swift_Current', offset: '-6' },
	{ timeZone: 'America/Tegucigalpa', offset: '-6' },
	{ timeZone: 'America/Winnipeg', offset: '-6' },
	{ timeZone: 'CST', offset: '-6' },
	{ timeZone: 'CST6CDT', offset: '-6' },
	{ timeZone: 'Canada/Central', offset: '-6' },
	{ timeZone: 'Canada/Saskatchewan', offset: '-6' },
	{ timeZone: 'Chile/EasterIsland', offset: '-6' },
	{ timeZone: 'Etc/GMT+6', offset: '-6' },
	{ timeZone: 'Mexico/General', offset: '-6' },
	{ timeZone: 'Pacific/Easter', offset: '-6' },
	{ timeZone: 'Pacific/Galapagos', offset: '-6' },
	{ timeZone: 'SystemV/CST6', offset: '-6' },
	{ timeZone: 'SystemV/CST6CDT', offset: '-6' },
	{ timeZone: 'US/Central', offset: '-6' },
	{ timeZone: 'US/Indiana-Starke', offset: '-6' },
	{ timeZone: 'America/Atikokan', offset: '-5' },
	{ timeZone: 'America/Bogota', offset: '-5' },
	{ timeZone: 'America/Cayman', offset: '-5' },
	{ timeZone: 'America/Coral_Harbour', offset: '-5' },
	{ timeZone: 'America/Detroit', offset: '-5' },
	{ timeZone: 'America/Fort_Wayne', offset: '-5' },
	{ timeZone: 'America/Grand_Turk', offset: '-5' },
	{ timeZone: 'America/Guayaquil', offset: '-5' },
	{ timeZone: 'America/Havana', offset: '-5' },
	{ timeZone: 'America/Indiana/Indianapolis', offset: '-5' },
	{ timeZone: 'America/Indiana/Marengo', offset: '-5' },
	{ timeZone: 'America/Indiana/Petersburg', offset: '-5' },
	{ timeZone: 'America/Indiana/Vevay', offset: '-5' },
	{ timeZone: 'America/Indiana/Vincennes', offset: '-5' },
	{ timeZone: 'America/Indiana/Winamac', offset: '-5' },
	{ timeZone: 'America/Indianapolis', offset: '-5' },
	{ timeZone: 'America/Iqaluit', offset: '-5' },
	{ timeZone: 'America/Jamaica', offset: '-5' },
	{ timeZone: 'America/Kentucky/Louisville', offset: '-5' },
	{ timeZone: 'America/Kentucky/Monticello', offset: '-5' },
	{ timeZone: 'America/Lima', offset: '-5' },
	{ timeZone: 'America/Louisville', offset: '-5' },
	{ timeZone: 'America/Montreal', offset: '-5' },
	{ timeZone: 'America/Nassau', offset: '-5' },
	{ timeZone: 'America/New_York', offset: '-5' },
	{ timeZone: 'America/Nipigon', offset: '-5' },
	{ timeZone: 'America/Panama', offset: '-5' },
	{ timeZone: 'America/Pangnirtung', offset: '-5' },
	{ timeZone: 'America/Port-au-Prince', offset: '-5' },
	{ timeZone: 'America/Thunder_Bay', offset: '-5' },
	{ timeZone: 'America/Toronto', offset: '-5' },
	{ timeZone: 'Canada/Eastern', offset: '-5' },
	{ timeZone: 'Cuba', offset: '-5' },
	{ timeZone: 'EST', offset: '-5' },
	{ timeZone: 'EST5EDT', offset: '-5' },
	{ timeZone: 'Etc/GMT+5', offset: '-5' },
	{ timeZone: 'IET', offset: '-5' },
	{ timeZone: 'Jamaica', offset: '-5' },
	{ timeZone: 'SystemV/EST5', offset: '-5' },
	{ timeZone: 'SystemV/EST5EDT', offset: '-5' },
	{ timeZone: 'US/East-Indiana', offset: '-5' },
	{ timeZone: 'US/Eastern', offset: '-5' },
	{ timeZone: 'US/Michigan', offset: '-5' },
	{ timeZone: 'America/Caracas', offset: '-4.5' },
	{ timeZone: 'America/Anguilla', offset: '-4' },
	{ timeZone: 'America/Antigua', offset: '-4' },
	{ timeZone: 'America/Argentina/San_Luis', offset: '-4' },
	{ timeZone: 'America/Aruba', offset: '-4' },
	{ timeZone: 'America/Asuncion', offset: '-4' },
	{ timeZone: 'America/Barbados', offset: '-4' },
	{ timeZone: 'America/Blanc-Sablon', offset: '-4' },
	{ timeZone: 'America/Boa_Vista', offset: '-4' },
	{ timeZone: 'America/Campo_Grande', offset: '-4' },
	{ timeZone: 'America/Cuiaba', offset: '-4' },
	{ timeZone: 'America/Curacao', offset: '-4' },
	{ timeZone: 'America/Dominica', offset: '-4' },
	{ timeZone: 'America/Eirunepe', offset: '-4' },
	{ timeZone: 'America/Glace_Bay', offset: '-4' },
	{ timeZone: 'America/Goose_Bay', offset: '-4' },
	{ timeZone: 'America/Grenada', offset: '-4' },
	{ timeZone: 'America/Guadeloupe', offset: '-4' },
	{ timeZone: 'America/Guyana', offset: '-4' },
	{ timeZone: 'America/Halifax', offset: '-4' },
	{ timeZone: 'America/Kralendijk', offset: '-4' },
	{ timeZone: 'America/La_Paz', offset: '-4' },
	{ timeZone: 'America/Lower_Princes', offset: '-4' },
	{ timeZone: 'America/Manaus', offset: '-4' },
	{ timeZone: 'America/Marigot', offset: '-4' },
	{ timeZone: 'America/Martinique', offset: '-4' },
	{ timeZone: 'America/Moncton', offset: '-4' },
	{ timeZone: 'America/Montserrat', offset: '-4' },
	{ timeZone: 'America/Port_of_Spain', offset: '-4' },
	{ timeZone: 'America/Porto_Acre', offset: '-4' },
	{ timeZone: 'America/Porto_Velho', offset: '-4' },
	{ timeZone: 'America/Puerto_Rico', offset: '-4' },
	{ timeZone: 'America/Rio_Branco', offset: '-4' },
	{ timeZone: 'America/Santiago', offset: '-4' },
	{ timeZone: 'America/Santo_Domingo', offset: '-4' },
	{ timeZone: 'America/St_Barthelemy', offset: '-4' },
	{ timeZone: 'America/St_Kitts', offset: '-4' },
	{ timeZone: 'America/St_Lucia', offset: '-4' },
	{ timeZone: 'America/St_Thomas', offset: '-4' },
	{ timeZone: 'America/St_Vincent', offset: '-4' },
	{ timeZone: 'America/Thule', offset: '-4' },
	{ timeZone: 'America/Tortola', offset: '-4' },
	{ timeZone: 'America/Virgin', offset: '-4' },
	{ timeZone: 'Antarctica/Palmer', offset: '-4' },
	{ timeZone: 'Atlantic/Bermuda', offset: '-4' },
	{ timeZone: 'Atlantic/Stanley', offset: '-4' },
	{ timeZone: 'Brazil/Acre', offset: '-4' },
	{ timeZone: 'Brazil/West', offset: '-4' },
	{ timeZone: 'Canada/Atlantic', offset: '-4' },
	{ timeZone: 'Chile/Continental', offset: '-4' },
	{ timeZone: 'Etc/GMT+4', offset: '-4' },
	{ timeZone: 'PRT', offset: '-4' },
	{ timeZone: 'SystemV/AST4', offset: '-4' },
	{ timeZone: 'SystemV/AST4ADT', offset: '-4' },
	{ timeZone: 'America/St_Johns', offset: '-3.5' },
	{ timeZone: 'CNT', offset: '-3.5' },
	{ timeZone: 'Canada/Newfoundland', offset: '-3.5' },
	{ timeZone: 'AGT', offset: '-3' },
	{ timeZone: 'America/Araguaina', offset: '-3' },
	{ timeZone: 'America/Argentina/Buenos_Aires', offset: '-3' },
	{ timeZone: 'America/Argentina/Catamarca', offset: '-3' },
	{ timeZone: 'America/Argentina/ComodRivadavia', offset: '-3' },
	{ timeZone: 'America/Argentina/Cordoba', offset: '-3' },
	{ timeZone: 'America/Argentina/Jujuy', offset: '-3' },
	{ timeZone: 'America/Argentina/La_Rioja', offset: '-3' },
	{ timeZone: 'America/Argentina/Mendoza', offset: '-3' },
	{ timeZone: 'America/Argentina/Rio_Gallegos', offset: '-3' },
	{ timeZone: 'America/Argentina/Salta', offset: '-3' },
	{ timeZone: 'America/Argentina/San_Juan', offset: '-3' },
	{ timeZone: 'America/Argentina/Tucuman', offset: '-3' },
	{ timeZone: 'America/Argentina/Ushuaia', offset: '-3' },
	{ timeZone: 'America/Bahia', offset: '-3' },
	{ timeZone: 'America/Belem', offset: '-3' },
	{ timeZone: 'America/Buenos_Aires', offset: '-3' },
	{ timeZone: 'America/Catamarca', offset: '-3' },
	{ timeZone: 'America/Cayenne', offset: '-3' },
	{ timeZone: 'America/Cordoba', offset: '-3' },
	{ timeZone: 'America/Fortaleza', offset: '-3' },
	{ timeZone: 'America/Godthab', offset: '-3' },
	{ timeZone: 'America/Jujuy', offset: '-3' },
	{ timeZone: 'America/Maceio', offset: '-3' },
	{ timeZone: 'America/Mendoza', offset: '-3' },
	{ timeZone: 'America/Miquelon', offset: '-3' },
	{ timeZone: 'America/Montevideo', offset: '-3' },
	{ timeZone: 'America/Paramaribo', offset: '-3' },
	{ timeZone: 'America/Recife', offset: '-3' },
	{ timeZone: 'America/Rosario', offset: '-3' },
	{ timeZone: 'America/Santarem', offset: '-3' },
	{ timeZone: 'America/Sao_Paulo', offset: '-3' },
	{ timeZone: 'Antarctica/Rothera', offset: '-3' },
	{ timeZone: 'BET', offset: '-3' },
	{ timeZone: 'Brazil/East', offset: '-3' },
	{ timeZone: 'Etc/GMT+3', offset: '-3' },
	{ timeZone: 'America/Noronha', offset: '-2' },
	{ timeZone: 'Atlantic/South_Georgia', offset: '-2' },
	{ timeZone: 'Brazil/DeNoronha', offset: '-2' },
	{ timeZone: 'Etc/GMT+2', offset: '-2' },
	{ timeZone: 'America/Scoresbysund', offset: '-1' },
	{ timeZone: 'Atlantic/Azores', offset: '-1' },
	{ timeZone: 'Atlantic/Cape_Verde', offset: '-1' },
	{ timeZone: 'Etc/GMT+1', offset: '-1' },
	{ timeZone: 'Africa/Abidjan', offset: '0' },
	{ timeZone: 'Africa/Accra', offset: '0' },
	{ timeZone: 'Africa/Bamako', offset: '0' },
	{ timeZone: 'Africa/Banjul', offset: '0' },
	{ timeZone: 'Africa/Bissau', offset: '0' },
	{ timeZone: 'Africa/Casablanca', offset: '0' },
	{ timeZone: 'Africa/Conakry', offset: '0' },
	{ timeZone: 'Africa/Dakar', offset: '0' },
	{ timeZone: 'Africa/El_Aaiun', offset: '0' },
	{ timeZone: 'Africa/Freetown', offset: '0' },
	{ timeZone: 'Africa/Lome', offset: '0' },
	{ timeZone: 'Africa/Monrovia', offset: '0' },
	{ timeZone: 'Africa/Nouakchott', offset: '0' },
	{ timeZone: 'Africa/Ouagadougou', offset: '0' },
	{ timeZone: 'Africa/Sao_Tome', offset: '0' },
	{ timeZone: 'Africa/Timbuktu', offset: '0' },
	{ timeZone: 'America/Danmarkshavn', offset: '0' },
	{ timeZone: 'Atlantic/Canary', offset: '0' },
	{ timeZone: 'Atlantic/Faeroe', offset: '0' },
	{ timeZone: 'Atlantic/Faroe', offset: '0' },
	{ timeZone: 'Atlantic/Madeira', offset: '0' },
	{ timeZone: 'Atlantic/Reykjavik', offset: '0' },
	{ timeZone: 'Atlantic/St_Helena', offset: '0' },
	{ timeZone: 'Eire', offset: '0' },
	{ timeZone: 'Etc/GMT', offset: '0' },
	{ timeZone: 'Etc/GMT+0', offset: '0' },
	{ timeZone: 'Etc/GMT-0', offset: '0' },
	{ timeZone: 'Etc/GMT0', offset: '0' },
	{ timeZone: 'Etc/Greenwich', offset: '0' },
	{ timeZone: 'Etc/UCT', offset: '0' },
	{ timeZone: 'Etc/UTC', offset: '0' },
	{ timeZone: 'Etc/Universal', offset: '0' },
	{ timeZone: 'Etc/Zulu', offset: '0' },
	{ timeZone: 'Europe/Belfast', offset: '0' },
	{ timeZone: 'Europe/Dublin', offset: '0' },
	{ timeZone: 'Europe/Guernsey', offset: '0' },
	{ timeZone: 'Europe/Isle_of_Man', offset: '0' },
	{ timeZone: 'Europe/Jersey', offset: '0' },
	{ timeZone: 'Europe/Lisbon', offset: '0' },
	{ timeZone: 'Europe/London', offset: '0' },
	{ timeZone: 'GB', offset: '0' },
	{ timeZone: 'GB-Eire', offset: '0' },
	{ timeZone: 'GMT', offset: '0' },
	{ timeZone: 'GMT0', offset: '0' },
	{ timeZone: 'Greenwich', offset: '0' },
	{ timeZone: 'Iceland', offset: '0' },
	{ timeZone: 'Portugal', offset: '0' },
	{ timeZone: 'UCT', offset: '0' },
	{ timeZone: 'UTC', offset: '0' },
	{ timeZone: 'Universal', offset: '0' },
	{ timeZone: 'WET', offset: '0' },
	{ timeZone: 'Zulu', offset: '0' },
	{ timeZone: 'Africa/Algiers', offset: '1' },
	{ timeZone: 'Africa/Bangui', offset: '1' },
	{ timeZone: 'Africa/Brazzaville', offset: '1' },
	{ timeZone: 'Africa/Ceuta', offset: '1' },
	{ timeZone: 'Africa/Douala', offset: '1' },
	{ timeZone: 'Africa/Kinshasa', offset: '1' },
	{ timeZone: 'Africa/Lagos', offset: '1' },
	{ timeZone: 'Africa/Libreville', offset: '1' },
	{ timeZone: 'Africa/Luanda', offset: '1' },
	{ timeZone: 'Africa/Malabo', offset: '1' },
	{ timeZone: 'Africa/Ndjamena', offset: '1' },
	{ timeZone: 'Africa/Niamey', offset: '1' },
	{ timeZone: 'Africa/Porto-Novo', offset: '1' },
	{ timeZone: 'Africa/Tunis', offset: '1' },
	{ timeZone: 'Africa/Windhoek', offset: '1' },
	{ timeZone: 'Arctic/Longyearbyen', offset: '1' },
	{ timeZone: 'Atlantic/Jan_Mayen', offset: '1' },
	{ timeZone: 'CET', offset: '1' },
	{ timeZone: 'ECT', offset: '1' },
	{ timeZone: 'Etc/GMT-1', offset: '1' },
	{ timeZone: 'Europe/Amsterdam', offset: '1' },
	{ timeZone: 'Europe/Andorra', offset: '1' },
	{ timeZone: 'Europe/Belgrade', offset: '1' },
	{ timeZone: 'Europe/Berlin', offset: '1' },
	{ timeZone: 'Europe/Bratislava', offset: '1' },
	{ timeZone: 'Europe/Brussels', offset: '1' },
	{ timeZone: 'Europe/Budapest', offset: '1' },
	{ timeZone: 'Europe/Copenhagen', offset: '1' },
	{ timeZone: 'Europe/Gibraltar', offset: '1' },
	{ timeZone: 'Europe/Ljubljana', offset: '1' },
	{ timeZone: 'Europe/Luxembourg', offset: '1' },
	{ timeZone: 'Europe/Madrid', offset: '1' },
	{ timeZone: 'Europe/Malta', offset: '1' },
	{ timeZone: 'Europe/Monaco', offset: '1' },
	{ timeZone: 'Europe/Oslo', offset: '1' },
	{ timeZone: 'Europe/Paris', offset: '1' },
	{ timeZone: 'Europe/Podgorica', offset: '1' },
	{ timeZone: 'Europe/Prague', offset: '1' },
	{ timeZone: 'Europe/Rome', offset: '1' },
	{ timeZone: 'Europe/San_Marino', offset: '1' },
	{ timeZone: 'Europe/Sarajevo', offset: '1' },
	{ timeZone: 'Europe/Skopje', offset: '1' },
	{ timeZone: 'Europe/Stockholm', offset: '1' },
	{ timeZone: 'Europe/Tirane', offset: '1' },
	{ timeZone: 'Europe/Vaduz', offset: '1' },
	{ timeZone: 'Europe/Vatican', offset: '1' },
	{ timeZone: 'Europe/Vienna', offset: '1' },
	{ timeZone: 'Europe/Warsaw', offset: '1' },
	{ timeZone: 'Europe/Zagreb', offset: '1' },
	{ timeZone: 'Europe/Zurich', offset: '1' },
	{ timeZone: 'MET', offset: '1' },
	{ timeZone: 'Poland', offset: '1' },
	{ timeZone: 'ART', offset: '2' },
	{ timeZone: 'Africa/Blantyre', offset: '2' },
	{ timeZone: 'Africa/Bujumbura', offset: '2' },
	{ timeZone: 'Africa/Cairo', offset: '2' },
	{ timeZone: 'Africa/Gaborone', offset: '2' },
	{ timeZone: 'Africa/Harare', offset: '2' },
	{ timeZone: 'Africa/Johannesburg', offset: '2' },
	{ timeZone: 'Africa/Kigali', offset: '2' },
	{ timeZone: 'Africa/Lubumbashi', offset: '2' },
	{ timeZone: 'Africa/Lusaka', offset: '2' },
	{ timeZone: 'Africa/Maputo', offset: '2' },
	{ timeZone: 'Africa/Maseru', offset: '2' },
	{ timeZone: 'Africa/Mbabane', offset: '2' },
	{ timeZone: 'Africa/Tripoli', offset: '2' },
	{ timeZone: 'Asia/Amman', offset: '2' },
	{ timeZone: 'Asia/Beirut', offset: '2' },
	{ timeZone: 'Asia/Damascus', offset: '2' },
	{ timeZone: 'Asia/Gaza', offset: '2' },
	{ timeZone: 'Asia/Hebron', offset: '2' },
	{ timeZone: 'Asia/Istanbul', offset: '2' },
	{ timeZone: 'Asia/Jerusalem', offset: '2' },
	{ timeZone: 'Asia/Nicosia', offset: '2' },
	{ timeZone: 'Asia/Tel_Aviv', offset: '2' },
	{ timeZone: 'CAT', offset: '2' },
	{ timeZone: 'EET', offset: '2' },
	{ timeZone: 'Egypt', offset: '2' },
	{ timeZone: 'Etc/GMT-2', offset: '2' },
	{ timeZone: 'Europe/Athens', offset: '2' },
	{ timeZone: 'Europe/Bucharest', offset: '2' },
	{ timeZone: 'Europe/Chisinau', offset: '2' },
	{ timeZone: 'Europe/Helsinki', offset: '2' },
	{ timeZone: 'Europe/Istanbul', offset: '2' },
	{ timeZone: 'Europe/Kiev', offset: '2' },
	{ timeZone: 'Europe/Mariehamn', offset: '2' },
	{ timeZone: 'Europe/Nicosia', offset: '2' },
	{ timeZone: 'Europe/Riga', offset: '2' },
	{ timeZone: 'Europe/Simferopol', offset: '2' },
	{ timeZone: 'Europe/Sofia', offset: '2' },
	{ timeZone: 'Europe/Tallinn', offset: '2' },
	{ timeZone: 'Europe/Tiraspol', offset: '2' },
	{ timeZone: 'Europe/Uzhgorod', offset: '2' },
	{ timeZone: 'Europe/Vilnius', offset: '2' },
	{ timeZone: 'Europe/Zaporozhye', offset: '2' },
	{ timeZone: 'Israel', offset: '2' },
	{ timeZone: 'Libya', offset: '2' },
	{ timeZone: 'Turkey', offset: '2' },
	{ timeZone: 'Africa/Addis_Ababa', offset: '3' },
	{ timeZone: 'Africa/Asmara', offset: '3' },
	{ timeZone: 'Africa/Asmera', offset: '3' },
	{ timeZone: 'Africa/Dar_es_Salaam', offset: '3' },
	{ timeZone: 'Africa/Djibouti', offset: '3' },
	{ timeZone: 'Africa/Juba', offset: '3' },
	{ timeZone: 'Africa/Kampala', offset: '3' },
	{ timeZone: 'Africa/Khartoum', offset: '3' },
	{ timeZone: 'Africa/Mogadishu', offset: '3' },
	{ timeZone: 'Africa/Nairobi', offset: '3' },
	{ timeZone: 'Antarctica/Syowa', offset: '3' },
	{ timeZone: 'Asia/Aden', offset: '3' },
	{ timeZone: 'Asia/Baghdad', offset: '3' },
	{ timeZone: 'Asia/Bahrain', offset: '3' },
	{ timeZone: 'Asia/Kuwait', offset: '3' },
	{ timeZone: 'Asia/Qatar', offset: '3' },
	{ timeZone: 'Asia/Riyadh', offset: '3' },
	{ timeZone: 'EAT', offset: '3' },
	{ timeZone: 'Etc/GMT-3', offset: '3' },
	{ timeZone: 'Europe/Kaliningrad', offset: '3' },
	{ timeZone: 'Europe/Minsk', offset: '3' },
	{ timeZone: 'Indian/Antananarivo', offset: '3' },
	{ timeZone: 'Indian/Comoro', offset: '3' },
	{ timeZone: 'Indian/Mayotte', offset: '3' },
	{ timeZone: 'Asia/Tehran', offset: '3.5' },
	{ timeZone: 'Iran', offset: '3.5' },
	{ timeZone: 'Asia/Baku', offset: '4' },
	{ timeZone: 'Asia/Dubai', offset: '4' },
	{ timeZone: 'Asia/Muscat', offset: '4' },
	{ timeZone: 'Asia/Tbilisi', offset: '4' },
	{ timeZone: 'Asia/Yerevan', offset: '4' },
	{ timeZone: 'Etc/GMT-4', offset: '4' },
	{ timeZone: 'Europe/Moscow', offset: '4' },
	{ timeZone: 'Europe/Samara', offset: '4' },
	{ timeZone: 'Europe/Volgograd', offset: '4' },
	{ timeZone: 'Indian/Mahe', offset: '4' },
	{ timeZone: 'Indian/Mauritius', offset: '4' },
	{ timeZone: 'Indian/Reunion', offset: '4' },
	{ timeZone: 'NET', offset: '4' },
	{ timeZone: 'W-SU', offset: '4' },
	{ timeZone: 'Asia/Kabul', offset: '4.5' },
	{ timeZone: 'Antarctica/Mawson', offset: '5' },
	{ timeZone: 'Asia/Aqtau', offset: '5' },
	{ timeZone: 'Asia/Aqtobe', offset: '5' },
	{ timeZone: 'Asia/Ashgabat', offset: '5' },
	{ timeZone: 'Asia/Ashkhabad', offset: '5' },
	{ timeZone: 'Asia/Dushanbe', offset: '5' },
	{ timeZone: 'Asia/Karachi', offset: '5' },
	{ timeZone: 'Asia/Oral', offset: '5' },
	{ timeZone: 'Asia/Samarkand', offset: '5' },
	{ timeZone: 'Asia/Tashkent', offset: '5' },
	{ timeZone: 'Etc/GMT-5', offset: '5' },
	{ timeZone: 'Indian/Kerguelen', offset: '5' },
	{ timeZone: 'Indian/Maldives', offset: '5' },
	{ timeZone: 'PLT', offset: '5' },
	{ timeZone: 'Asia/Calcutta', offset: '5.5' },
	{ timeZone: 'Asia/Colombo', offset: '5.5' },
	{ timeZone: 'Asia/Kolkata', offset: '5.5' },
	{ timeZone: 'IST', offset: '5.5' },
	{ timeZone: 'Asia/Kathmandu', offset: '5.75' },
	{ timeZone: 'Asia/Katmandu', offset: '5.75' },
	{ timeZone: 'Antarctica/Vostok', offset: '6' },
	{ timeZone: 'Asia/Almaty', offset: '6' },
	{ timeZone: 'Asia/Bishkek', offset: '6' },
	{ timeZone: 'Asia/Dacca', offset: '6' },
	{ timeZone: 'Asia/Dhaka', offset: '6' },
	{ timeZone: 'Asia/Qyzylorda', offset: '6' },
	{ timeZone: 'Asia/Thimbu', offset: '6' },
	{ timeZone: 'Asia/Thimphu', offset: '6' },
	{ timeZone: 'Asia/Yekaterinburg', offset: '6' },
	{ timeZone: 'BST', offset: '6' },
	{ timeZone: 'Etc/GMT-6', offset: '6' },
	{ timeZone: 'Indian/Chagos', offset: '6' },
	{ timeZone: 'Asia/Rangoon', offset: '6.5' },
	{ timeZone: 'Indian/Cocos', offset: '6.5' },
	{ timeZone: 'Antarctica/Davis', offset: '7' },
	{ timeZone: 'Asia/Bangkok', offset: '7' },
	{ timeZone: 'Asia/Ho_Chi_Minh', offset: '7' },
	{ timeZone: 'Asia/Hovd', offset: '7' },
	{ timeZone: 'Asia/Jakarta', offset: '7' },
	{ timeZone: 'Asia/Novokuznetsk', offset: '7' },
	{ timeZone: 'Asia/Novosibirsk', offset: '7' },
	{ timeZone: 'Asia/Omsk', offset: '7' },
	{ timeZone: 'Asia/Phnom_Penh', offset: '7' },
	{ timeZone: 'Asia/Pontianak', offset: '7' },
	{ timeZone: 'Asia/Saigon', offset: '7' },
	{ timeZone: 'Asia/Vientiane', offset: '7' },
	{ timeZone: 'Etc/GMT-7', offset: '7' },
	{ timeZone: 'Indian/Christmas', offset: '7' },
	{ timeZone: 'VST', offset: '7' },
	{ timeZone: 'Antarctica/Casey', offset: '8' },
	{ timeZone: 'Asia/Brunei', offset: '8' },
	{ timeZone: 'Asia/Choibalsan', offset: '8' },
	{ timeZone: 'Asia/Chongqing', offset: '8' },
	{ timeZone: 'Asia/Chungking', offset: '8' },
	{ timeZone: 'Asia/Harbin', offset: '8' },
	{ timeZone: 'Asia/Hong_Kong', offset: '8' },
	{ timeZone: 'Asia/Kashgar', offset: '8' },
	{ timeZone: 'Asia/Krasnoyarsk', offset: '8' },
	{ timeZone: 'Asia/Kuala_Lumpur', offset: '8' },
	{ timeZone: 'Asia/Kuching', offset: '8' },
	{ timeZone: 'Asia/Macao', offset: '8' },
	{ timeZone: 'Asia/Macau', offset: '8' },
	{ timeZone: 'Asia/Makassar', offset: '8' },
	{ timeZone: 'Asia/Manila', offset: '8' },
	{ timeZone: 'Asia/Shanghai', offset: '8' },
	{ timeZone: 'Asia/Singapore', offset: '8' },
	{ timeZone: 'Asia/Taipei', offset: '8' },
	{ timeZone: 'Asia/Ujung_Pandang', offset: '8' },
	{ timeZone: 'Asia/Ulaanbaatar', offset: '8' },
	{ timeZone: 'Asia/Ulan_Bator', offset: '8' },
	{ timeZone: 'Asia/Urumqi', offset: '8' },
	{ timeZone: 'Australia/Perth', offset: '8' },
	{ timeZone: 'Australia/West', offset: '8' },
	{ timeZone: 'CTT', offset: '8' },
	{ timeZone: 'Etc/GMT-8', offset: '8' },
	{ timeZone: 'Hongkong', offset: '8' },
	{ timeZone: 'PRC', offset: '8' },
	{ timeZone: 'Singapore', offset: '8' },
	{ timeZone: 'Australia/Eucla', offset: '8.75' },
	{ timeZone: 'Asia/Dili', offset: '9' },
	{ timeZone: 'Asia/Irkutsk', offset: '9' },
	{ timeZone: 'Asia/Jayapura', offset: '9' },
	{ timeZone: 'Asia/Pyongyang', offset: '9' },
	{ timeZone: 'Asia/Seoul', offset: '9' },
	{ timeZone: 'Asia/Tokyo', offset: '9' },
	{ timeZone: 'Etc/GMT-9', offset: '9' },
	{ timeZone: 'JST', offset: '9' },
	{ timeZone: 'Japan', offset: '9' },
	{ timeZone: 'Pacific/Palau', offset: '9' },
	{ timeZone: 'ROK', offset: '9' },
	{ timeZone: 'ACT', offset: '9.5' },
	{ timeZone: 'Australia/Adelaide', offset: '9.5' },
	{ timeZone: 'Australia/Broken_Hill', offset: '9.5' },
	{ timeZone: 'Australia/Darwin', offset: '9.5' },
	{ timeZone: 'Australia/North', offset: '9.5' },
	{ timeZone: 'Australia/South', offset: '9.5' },
	{ timeZone: 'Australia/Yancowinna', offset: '9.5' },
	{ timeZone: 'AET', offset: '10' },
	{ timeZone: 'Antarctica/DumontDUrville', offset: '10' },
	{ timeZone: 'Asia/Yakutsk', offset: '10' },
	{ timeZone: 'Australia/ACT', offset: '10' },
	{ timeZone: 'Australia/Brisbane', offset: '10' },
	{ timeZone: 'Australia/Canberra', offset: '10' },
	{ timeZone: 'Australia/Currie', offset: '10' },
	{ timeZone: 'Australia/Hobart', offset: '10' },
	{ timeZone: 'Australia/Lindeman', offset: '10' },
	{ timeZone: 'Australia/Melbourne', offset: '10' },
	{ timeZone: 'Australia/NSW', offset: '10' },
	{ timeZone: 'Australia/Queensland', offset: '10' },
	{ timeZone: 'Australia/Sydney', offset: '10' },
	{ timeZone: 'Australia/Tasmania', offset: '10' },
	{ timeZone: 'Australia/Victoria', offset: '10' },
	{ timeZone: 'Etc/GMT-10', offset: '10' },
	{ timeZone: 'Pacific/Chuuk', offset: '10' },
	{ timeZone: 'Pacific/Guam', offset: '10' },
	{ timeZone: 'Pacific/Port_Moresby', offset: '10' },
	{ timeZone: 'Pacific/Saipan', offset: '10' },
	{ timeZone: 'Pacific/Truk', offset: '10' },
	{ timeZone: 'Pacific/Yap', offset: '10' },
	{ timeZone: 'Australia/LHI', offset: '10.5' },
	{ timeZone: 'Australia/Lord_Howe', offset: '10.5' },
	{ timeZone: 'Antarctica/Macquarie', offset: '11' },
	{ timeZone: 'Asia/Sakhalin', offset: '11' },
	{ timeZone: 'Asia/Vladivostok', offset: '11' },
	{ timeZone: 'Etc/GMT-11', offset: '11' },
	{ timeZone: 'Pacific/Efate', offset: '11' },
	{ timeZone: 'Pacific/Guadalcanal', offset: '11' },
	{ timeZone: 'Pacific/Kosrae', offset: '11' },
	{ timeZone: 'Pacific/Noumea', offset: '11' },
	{ timeZone: 'Pacific/Pohnpei', offset: '11' },
	{ timeZone: 'Pacific/Ponape', offset: '11' },
	{ timeZone: 'SST', offset: '11' },
	{ timeZone: 'Pacific/Norfolk', offset: '11.5' },
	{ timeZone: 'Antarctica/McMurdo', offset: '12' },
	{ timeZone: 'Antarctica/South_Pole', offset: '12' },
	{ timeZone: 'Asia/Anadyr', offset: '12' },
	{ timeZone: 'Asia/Kamchatka', offset: '12' },
	{ timeZone: 'Asia/Magadan', offset: '12' },
	{ timeZone: 'Etc/GMT-12', offset: '12' },
	{ timeZone: 'Kwajalein', offset: '12' },
	{ timeZone: 'NST', offset: '12' },
	{ timeZone: 'NZ', offset: '12' },
	{ timeZone: 'Pacific/Auckland', offset: '12' },
	{ timeZone: 'Pacific/Fiji', offset: '12' },
	{ timeZone: 'Pacific/Funafuti', offset: '12' },
	{ timeZone: 'Pacific/Kwajalein', offset: '12' },
	{ timeZone: 'Pacific/Majuro', offset: '12' },
	{ timeZone: 'Pacific/Nauru', offset: '12' },
	{ timeZone: 'Pacific/Tarawa', offset: '12' },
	{ timeZone: 'Pacific/Wake', offset: '12' },
	{ timeZone: 'Pacific/Wallis', offset: '12' },
	{ timeZone: 'NZ-CHAT', offset: '12.75' },
	{ timeZone: 'Pacific/Chatham', offset: '12.75' },
	{ timeZone: 'Etc/GMT-13', offset: '13' },
	{ timeZone: 'MIT', offset: '13' },
	{ timeZone: 'Pacific/Apia', offset: '13' },
	{ timeZone: 'Pacific/Enderbury', offset: '13' },
	{ timeZone: 'Pacific/Tongatapu', offset: '13' },
	{ timeZone: 'Etc/GMT-14', offset: '14' },
	{ timeZone: 'Pacific/Kiritimati', offset: '14' },
];

let timeFormat: Intl.DateTimeFormat;
let dateFormat: Intl.DateTimeFormat;
let dateTimeFormat: Intl.DateTimeFormat;
let dateTimeMillisFormat: Intl.DateTimeFormat;

export function setTimeZone(timeZone: string): void {
	if (!isValidTimezone(timeZone)) {
		return;
	}

	timeFormat = new Intl.DateTimeFormat('default', {
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		timeZone,
	});
	dateFormat = new Intl.DateTimeFormat('default', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		timeZone,
	});
	dateTimeFormat = new Intl.DateTimeFormat('default', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		timeZone,
	});
	dateTimeMillisFormat = new Intl.DateTimeFormat('default', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		fractionalSecondDigits: 3,
		timeZone,
	});
}

// initially set to browser configured timeZone
setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);

export const daysAgo = (n: number): Date => {
	const d = new Date();
	d.setDate(d.getDate() - n);
	d.setHours(0, 0, 0, 0);
	return d;
};

export const twoDigits = (n: number): string => String(n).padStart(2, '0');

export const formatDifference = (a: Date, b: Date): string => {
	const diff = b.valueOf() - a.valueOf();
	const isNegative = diff < 0;
	const absDiffInSecs = Math.abs(Math.floor(diff / 1000));

	const h = Math.floor(absDiffInSecs / 3600);
	const m = Math.floor((absDiffInSecs % 3600) / 60);
	const s = absDiffInSecs % 60;

	return isNegative ? '00:00:00' : `${twoDigits(h)}:${twoDigits(m)}:${twoDigits(s)}`;
};

export const formatTime = (date: Date): string => (date ? `${timeFormat.format(date)}` : '');

export const formatDateWithTime = (date?: Date): string => (date ? dateTimeFormat.format(date) : '');
export const formatDateWithTimeInTimeZone = (date: Date, timeZone: string): string => {
	return getDateTimeFormat(timeZone).format(date);
};

export const formatDate = (date?: Date): string => (date ? dateFormat.format(date) : '');

interface DateTimeFormatPart {
	type: string;
	value: string;
}
const formatPartsDateWithTime = (date: Date): DateTimeFormatPart[] => dateTimeFormat.formatToParts(date);
const formatPartsDateWithTimeInTimeZone = (date: Date, timeZone: string): DateTimeFormatPart[] => {
	return getDateTimeFormat(timeZone).formatToParts(date);
};
const getDateTimeFormat = (timeZone: string) =>
	new Intl.DateTimeFormat('default', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		timeZone,
	});
export const formatTimestamp = (date?: Date): string => (date ? dateTimeMillisFormat.format(date) : '');

//returns 2021-04-15T08:17:24 but in UTC
export const toDatetimeUTCValue = (d: Date): string => {
	return `${d.getUTCFullYear()}-${twoDigits(d.getUTCMonth() + 1)}-${twoDigits(d.getUTCDate())}T${twoDigits(
		d.getUTCHours(),
	)}:${twoDigits(d.getUTCMinutes())}:${twoDigits(d.getUTCSeconds())}`;
};

function isValidTimezone(timeZone: string): boolean {
	try {
		Intl.DateTimeFormat(undefined, { timeZone });
		return true;
	} catch (ex) {
		return false;
	}
}

export function dateAsUserTimezone(_date: Date): Date {
	const date = new Date(_date);
	const parts: DateTimeFormatPart[] = formatPartsDateWithTime(date);
	return getFromParts(date, parts);
}

export function dateInTimeZone(_date: Date, timeZone: string): Date {
	const date = new Date(_date);
	const parts: DateTimeFormatPart[] = formatPartsDateWithTimeInTimeZone(date, timeZone);
	return getFromParts(date, parts);
}

export function getDateAsLocale(_date: Date): Date {
	const date = new Date(_date);
	const parts: DateTimeFormatPart[] = new Intl.DateTimeFormat('default', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
	}).formatToParts(date);
	return getFromParts(date, parts);
}

function getFromParts(_date: Date, parts: DateTimeFormatPart[]): Date {
	const date = new Date(_date);

	const day = parts.find((part) => part.type === 'day')?.value;
	const month = parts.find((part) => part.type === 'month')?.value;
	const year = parts.find((part) => part.type === 'year')?.value;
	const hour = parts.find((part) => part.type === 'hour')?.value;
	const minute = parts.find((part) => part.type === 'minute')?.value;
	const second = parts.find((part) => part.type === 'second')?.value;
	const dayPeriod = parts.find((part) => part.type === 'dayPeriod')?.value;

	date.setFullYear(Number(year));
	date.setMonth(Number(month) - 1);
	date.setDate(Number(day));
	date.setHours(Number(hour) + (dayPeriod === 'PM' ? 12 : 0));
	date.setMinutes(Number(minute));
	date.setSeconds(Number(second));

	return date;
}

export function getDayPeriod(date: Date): 'AM' | 'PM' {
	const dateString = new Date(date)
		.toLocaleTimeString([], {
			hour: '2-digit',
			minute: '2-digit',
			hour12: true,
		})
		.toLowerCase();
	const lastTwoChars = dateString.slice(-2);
	return lastTwoChars === 'am' ? 'AM' : 'PM';
}
