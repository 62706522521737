/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { CurrentUserVO, TenantVO } from 'ui-api';

export const ineum: typeof window.ineum = function ineumWrapper() {
	if (typeof window !== 'undefined') {
		// @ts-expect-error We avoid the rest/spread operators for performance reasons.
		// eslint-disable-next-line prefer-spread, prefer-rest-params
		window.ineum?.apply(window, arguments);
	}
} as typeof window.ineum;

export function onTenant(tenant?: TenantVO): void {
	if (tenant) {
		ineum('meta', 'tenant', tenant.key);
	}
}

export function onUser(user?: CurrentUserVO): void {
	if (user) {
		ineum('user', user.username, user.name, user.email);
	}
}

export function onTitleChange(documentTitle: string): void {
	ineum('page', documentTitle);
}
