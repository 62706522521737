/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import antlr4 from 'antlr4';

import QueryLanguageLexer from './generated/QueryLanguageLexer';

export function tokenize(input: string): antlr4.Token[] {
	const chars = new antlr4.InputStream(input);
	const lexer = new QueryLanguageLexer(chars);
	return lexer.getAllTokens();
}
