/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

export default `
precision highp float;
precision highp int;

uniform float progress;

attribute vec3 toPosition;
attribute vec3 color;
attribute float opacity;

varying vec2 vUv;
varying vec3 vColor;
varying float fOpacity;

void main()	{
	vUv = uv;
	vColor = color;
	fOpacity = opacity;

	vec3 currentPosition = vec3(
		position.x + (toPosition.x - position.x) * progress,
		position.y + (toPosition.y - position.y) * progress,
		position.z
	);

	gl_Position = projectionMatrix * modelViewMatrix * vec4( currentPosition, 1.0 );
}
`;
