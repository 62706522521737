/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { DataStreamResult } from 'utils/hooks/stream/result';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { TargetPredicateVO } from 'ui-api';
import { useState } from 'react';

export function useTargetTypesWithAdvice(predicate: TargetPredicateVO): DataStreamResult<string[]> {
	const [updateSignal, setUpdateSignal] = useState(0);

	useEventEffect(() => {
		setUpdateSignal((signal) => signal + 1);
	}, ['targetType.updated', 'targetType.created', 'targetType.deleted']);

	return usePromise(() => Services.adviceApi.getTargetsWithAdvice(predicate), [predicate, updateSignal]);
}
