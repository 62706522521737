/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	Heading,
	ModalContentV2,
	ModalFooterV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	Stack,
} from 'components';
import { LandscapeViewMappedGroupingVO } from 'ui-api';
import { ReactElement, useState } from 'react';
import { useFormikContext } from 'formik';

import ModalMappingsSection, { addUntitledTitles, newMapping, removeEmptyMappings } from './ModalMappingsSection';
import { ColorConfig, LandscapeConfig } from '../types';
import { getGroupingLabelFromUrl } from '../utils';

interface ConfigureColorModalProps {
	attributeKey: string;
	readOnly?: boolean;
	onSave: (colorConfig: ColorConfig | null) => void;
	onClose: () => void;
}

export default function ConfigureColorModal({
	attributeKey,
	readOnly,
	onClose,
	onSave,
}: ConfigureColorModalProps): ReactElement {
	const formik = useFormikContext<LandscapeConfig>();
	const { colorConfig } = formik.values;
	const [mappings, setMappings] = useState<LandscapeViewMappedGroupingVO[]>(
		colorConfig?.mappings ? [...colorConfig.mappings] : [newMapping()],
	);

	const attributeLabel = getGroupingLabelFromUrl(attributeKey);

	return (
		<ModalOverlay open centerContent onClose={onClose}>
			{({ close }) => (
				<ModalV2 slick withFooter width="800px">
					<ModalHeaderV2
						title={
							<Stack direction="horizontal" size="xSmall" alignItems="center">
								<Heading variant="large" color="neutral800">
									Configure
								</Heading>
								<Heading variant="large" color="slate">
									{attributeLabel}
								</Heading>
							</Stack>
						}
						subTitle="You can configure your view even more by creating custom buckets and assign ad hoc values for each bucket."
						onClose={close}
					/>
					<ModalContentV2>
						<ModalMappingsSection
							attributeKey={attributeKey}
							mappings={mappings}
							readOnly={readOnly}
							setMappings={setMappings}
						/>
					</ModalContentV2>
					<ModalFooterV2>
						<Stack direction="horizontal" alignItems="center">
							<Button variant="secondary" onClick={close}>
								Cancel
							</Button>
							<Button
								onClick={() => {
									const cleanedMappings = addUntitledTitles(removeEmptyMappings(mappings));
									onSave(cleanedMappings.length > 0 ? { mappings: cleanedMappings } : null);
									close();
								}}
								disabled={readOnly}
							>
								Save configuration
							</Button>
						</Stack>
					</ModalFooterV2>
				</ModalV2>
			)}
		</ModalOverlay>
	);
}
