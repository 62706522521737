/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { FieldConfig, FieldHookConfig, useField } from 'formik';
import { Text, ContainerProps, Container } from 'components';
import React from 'react';

import { KeyValueListInput, KeyValueListInputProps, KeyValuePair } from '../KeyValueListInput/KeyValueListInput';
import { hasError } from './utils';

type FormikKeyValueListInputProps = FieldConfig & ContainerProps & Omit<KeyValueListInputProps, keyof FieldConfig>;

export const FormikKeyValueListInput: React.VFC<FormikKeyValueListInputProps> = ({ width, ...props }) => {
	const [field, meta, { setValue, setTouched }] = useField({ ...props, type: 'key-value' } as FieldHookConfig<
		KeyValuePair[]
	>);

	return (
		<Container display={'flex'} flexDirection={'column'} width={width}>
			<KeyValueListInput
				{...field}
				onChange={(value: KeyValuePair[]) => {
					setValue(value);
					setTouched(true, false);
				}}
				{...props}
			/>
			{hasError(meta) ? (
				<Text mt={'xxSmall'} variant={'smallStrong'} color={'coral'} data-formik-error>
					{meta.error}
				</Text>
			) : null}
		</Container>
	);
};
FormikKeyValueListInput.displayName = 'FormikKeyValueListInput';
