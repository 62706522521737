/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ModalContentV2, ModalHeaderV2, ModalOverlay, ModalV2 } from 'components';
import { EditorSettingsContext } from 'pages/experimentsV2/useEditorSettings';
import { TemplateVO, TrackingCreationMethodVO } from 'ui-api';
import { ReactElement } from 'react';

import NavigationWithContent from './pages/NavigationWithContent';
import ExperimentNameField from './ExperimentNameField';
import UseTemplateForm from './UseTemplateForm';

interface UseTemplateModalProps {
	experimentCreationMethod: TrackingCreationMethodVO;
	newExperimentTags?: string[];
	templatePreviewId: string;
	template?: TemplateVO;
	onClose: () => void;
}

export default function UseTemplateModal({
	experimentCreationMethod,
	templatePreviewId,
	newExperimentTags,
	template,
	onClose,
}: UseTemplateModalProps): ReactElement {
	return (
		<ModalOverlay open onClose={onClose}>
			<UseTemplateForm
				experimentCreationMethod={experimentCreationMethod}
				newExperimentTags={newExperimentTags}
				templateId={templatePreviewId}
				template={template}
			>
				<ModalV2 slick withFooter width="90vw" maxWidth="1650px">
					<ModalHeaderV2
						title="Create New Experiment From Template"
						subTitle={<ExperimentNameField />}
						onClose={onClose}
					/>
					<ModalContentV2>
						<EditorSettingsContext.Provider value={{ mode: 'templateUsage' }}>
							<NavigationWithContent />
						</EditorSettingsContext.Provider>
					</ModalContentV2>
				</ModalV2>
			</UseTemplateForm>
		</ModalOverlay>
	);
}
