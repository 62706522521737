/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import DropdownPill from 'components/Select/Dropdown/DropdownPill';
import { IconDescription } from 'components/icons';
import { TextField } from 'components';
import { ReactElement } from 'react';

interface DescriptionProps {
	description: string;
	setDescription: (description: string) => void;
	readonly: boolean;
}
export default function Description({ description, readonly, setDescription }: DescriptionProps): ReactElement {
	return (
		<DropdownPill Icon={IconDescription} value={description || 'No description'} sx={{ width: 'fit-content' }}>
			{({ selectItem }) => (
				<TextField
					disabled={readonly}
					autoFocus
					sx={{ minHeight: 150, minWidth: 400 }}
					color="neutral700"
					as="textarea"
					value={description}
					placeholder="No description"
					variant="small"
					pl="xSmall"
					onChange={(e) => setDescription(e.target.value)}
					maxLength={20_000}
					onKeyDown={(e) => {
						if (e.key === 'Escape' || e.key === 'Enter' || e.key === 'Esc') {
							selectItem(description);
							e.target.dispatchEvent(new Event('dropdown_close', { bubbles: true }));
							e.stopPropagation();
							e.preventDefault();
						}
					}}
				/>
			)}
		</DropdownPill>
	);
}
