/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useContext, createContext } from 'react';
import { ActionVO } from 'ui-api';

type ActionsContextType = {
	actions: ActionVO[];
};

export const ActionsContext = createContext<ActionsContextType>({ actions: [] });

const useActions = (): ActionVO[] => {
	const { actions } = useContext(ActionsContext);
	return actions;
};
export default useActions;
