/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Container, ContainerProps, StyleProp } from 'components';
import useObservable from 'react-use/lib/useObservable';
import { Services } from 'services/services';
import * as PopperJS from '@popperjs/core';
import { usePopper } from 'react-popper';
import { useClickAway } from 'react-use';
import { theme } from 'styles.v2/theme';
import { ReplaySubject } from 'rxjs';
import { debounce } from 'lodash';

import { IconClose, IconHelp } from '../icons';
import { ButtonIcon } from '../ButtonIcon';
import { Heading } from '../Heading';
import { Button } from '../Button';
import { Stack } from '../Stack';
import { Text } from '../Text';

export type TutorialTooltipProps = {
	id: string;
	title?: React.ReactNode;
	learnMore?: string;
	placement?: PopperJS.Placement;
	light?: boolean;
	hideIcon?: boolean;
	hideSkip?: boolean;
	hideCloseIcon?: boolean;
	showAlways?: boolean;
	children: ReactNode | (() => ReactNode);
};

const isGlobalVisible$ = new ReplaySubject(1);
const setIsGlobalVisible = debounce((b) => isGlobalVisible$.next(b), 400);

export const TutorialTooltip: React.FC<TutorialTooltipProps> = ({
	children,
	id,
	showAlways,
	hideIcon,
	hideSkip,
	hideCloseIcon,
	title,
	learnMore,
	light = false,
	placement = 'right',
}) => {
	const [referenceEl, setReferenceEl] = useState<HTMLElement | null>(null);
	const [popperEl, setPopperEl] = useState<HTMLElement | null>(null);
	const [arrowEl, setArrowEl] = useState<HTMLElement | null>(null);
	const isGlobalVisible = useObservable(isGlobalVisible$);
	const [isTriggered, setIsTriggered] = useState(false);
	const openTip = useMemo(
		() => () => {
			setIsTriggered(true);
			setIsGlobalVisible(true);
		},
		[],
	);
	const closeTip = useMemo(
		() => () => {
			setIsTriggered(false);
			setIsGlobalVisible(false);
		},
		[],
	);
	useEffect(() => {
		if ((!Services.users.isTutorialCompleted(id) && !Services.users.isTutorialSkipped()) || showAlways) {
			openTip();
		}
		if (!showAlways) {
			Services.users.addTutorialCompleted(id);
		}
	}, [id, openTip, showAlways]);
	// as usePopper works only properly with useState (instead of References)
	// we need to keep a real reference around synced to the state for the useClickAway.
	const popperRef = React.useRef(popperEl);
	React.useEffect(() => {
		popperRef.current = popperEl;
	}, [popperEl]);
	useClickAway(popperRef, closeTip);

	const {
		styles,
		attributes,
		update: updatePopper,
		state,
	} = usePopper(referenceEl, popperEl, {
		placement,
		strategy: 'fixed',
		modifiers: [
			{
				name: 'preventOverflow',
				options: {
					rootBoundary: 'viewport',
				},
			},
			{
				name: 'offset',
				options: {
					offset: [-20, placement === 'bottom' || placement === 'top' ? 25 : 5],
				},
			},
			{
				name: 'arrow',
				options: {
					element: arrowEl,
				},
			},
		],
	});
	React.useEffect(() => {
		if (isTriggered && isGlobalVisible) {
			updatePopper?.();
		}
	}, [isTriggered, isGlobalVisible, updatePopper]);

	const tooltipContent = React.useMemo(
		() => (isTriggered && isGlobalVisible ? (typeof children === 'function' ? children() : children) : null),
		[isTriggered, isGlobalVisible, children],
	);

	return (
		<>
			{hideIcon ? (
				<Container width={1} height={1} ref={(r) => r && setReferenceEl(r)}></Container>
			) : (
				<ButtonIcon ref={setReferenceEl} ml={'xSmall'} color={'neutral700'}>
					<IconHelp variant={'small'} onClick={isTriggered && isGlobalVisible ? closeTip : openTip} />
				</ButtonIcon>
			)}
			{isTriggered && isGlobalVisible && tooltipContent && (
				<Container
					ref={setPopperEl}
					style={styles.popper}
					{...attributes.popper}
					sx={{
						display: 'flex',
						alignItems: 'flex-start',
						justifyContent: 'space-between',
						width: '400px',

						backgroundColor: light ? 'purple500' : 'slateLight',
						color: light ? 'neutral000' : 'neutral800',
						variant: light ? 'text.mediumMedium' : 'text.smallStrong',
						px: light ? 'small' : 'large',
						pt: light ? 'xSmall' : 'medium',
						pb: light ? 'xSmall' : 'small',
						zIndex: 200,
						borderRadius: light ? '4px' : 0,
					}}
				>
					<Arrow light={light} ref={setArrowEl} sx={{ ...styles.arrow }} placement={state?.placement ?? placement} />

					<Stack size="xxSmall">
						{title && (
							<Heading variant={'small'} color={'neutral800'} mb={'xSmall'}>
								{title}
							</Heading>
						)}

						<Text>{typeof children === 'function' ? children() : children}</Text>
						{!hideSkip && (
							<Stack direction={'horizontal'} alignItems={'center'} mt={'small'}>
								{learnMore ? (
									<Button onClick={() => window.open(learnMore)} variant={'secondary'} mt={'xxSmall'}>
										Learn more
									</Button>
								) : null}
								{Services.users.isTutorialSkipped() ? null : (
									<Text
										variant="smallStrong"
										color="slate"
										sx={{
											cursor: 'pointer',
											':hover': {
												textDecoration: 'underline',
											},
										}}
										onClick={() => {
											Services.users.skipTutorial();
											closeTip();
										}}
									>
										Skip tutorials
									</Text>
								)}
							</Stack>
						)}
					</Stack>

					{!hideCloseIcon && (
						<Container
							color={light ? 'neutral000' : 'neutral600'}
							ml="xSmall"
							sx={{
								':hover': {
									cursor: 'pointer',
									textDecoration: 'none',
									color: 'neutral700',
									bg:
										'linear-gradient(180deg, ' + theme.colors.neutral000 + ' 0%, ' + theme.colors.neutral100 + ' 100%)',
								},
							}}
							variant={'small'}
							onClick={(e) => {
								e.stopPropagation();
								closeTip();
							}}
						>
							<IconClose />
						</Container>
					)}
				</Container>
			)}
		</>
	);
};
TutorialTooltip.displayName = 'TutorialTooltip';

export interface ArrowProps extends ContainerProps {
	light: boolean;
	placement: string;
}

const Arrow = React.forwardRef<HTMLDivElement, ArrowProps>(({ placement, light, sx, ...props }, ref) => (
	<Container
		ref={ref}
		sx={{
			position: 'absolute',
			...sx,
			...getArrowStyles(placement, light),
		}}
		{...props}
	/>
));
Arrow.displayName = 'Arrow';

function getArrowStyles(placement: string, light: boolean): StyleProp {
	if (placement === 'top') {
		return {
			top: '100%',
			left: '-14px',
			'&::before': {
				content: '""',
				display: 'block',
				margin: 'auto',
				width: '0',
				height: '0',
				borderStyle: 'solid',
				borderWidth: '5px 7px 0 7px',
				borderColor: `${light ? theme.colors.purple500 : theme.colors.slateLight} transparent transparent transparent`,
			},
		};
	}
	if (placement === 'bottom') {
		return {
			left: '-47%',
			bottom: '100%',
			'&::before': {
				content: '""',
				display: 'block',
				margin: 'auto',
				width: '0',
				height: '0',
				borderStyle: 'solid',
				borderWidth: '0 7px 5px 7px',
				borderColor: `transparent transparent ${light ? theme.colors.purple500 : theme.colors.slateLight} transparent`,
			},
		};
	}
	if (placement === 'left') {
		return {
			top: '-4px',
			left: '100%',
			'&::before': {
				content: '""',
				display: 'block',
				margin: 'auto',
				width: '0',
				height: '0',
				borderStyle: 'solid',
				borderWidth: '7px 0 7px 5px',
				borderColor: `transparent transparent transparent${light ? theme.colors.purple500 : theme.colors.slateLight}`,
			},
		};
	}
	if (placement === 'right') {
		return {
			top: '-4px',
			right: '100%',
			'&::before': {
				content: '""',
				display: 'block',
				margin: 'auto',
				width: '0',
				height: '0',
				borderStyle: 'solid',
				borderWidth: '7px 5px 7px 0',
				borderColor: `transparent ${light ? theme.colors.purple500 : theme.colors.slateLight} transparent transparent`,
			},
		};
	}

	return {};
}
