/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { BasicStyleProperties } from 'components/types';
import { Heading } from 'components';
import React from 'react';

interface HeaderProps extends BasicStyleProperties {
	label: string;
	variant?: string;
}

export const Header: React.VFC<HeaderProps> = ({ label, variant = 'small', ...props }) => {
	return (
		<Heading variant={variant} mb={'small'} {...props}>
			{label}
		</Heading>
	);
};
