/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { TargetPredicateVO } from 'ui-api';

export interface ParsingError {
	message: string;
	line: number;
	column: number;
}

export function isParsingError(parseResult: unknown): parseResult is ParsingError {
	return (
		typeof (parseResult as ParsingError).message === 'string' &&
		typeof (parseResult as ParsingError).line === 'number' &&
		typeof (parseResult as ParsingError).column === 'number'
	);
}

export type ParsingResult = TargetPredicateVO | ParsingError;
