/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ReactElement, ReactNode } from 'react';
import { Stack, Text } from 'components';
import { theme } from 'styles.v2/theme';

interface EmptyListContent {
	title: string;
	description?: string | ReactNode;
	icon?: ReactElement;
	withoutBorder?: boolean;
}

export default function EmptyListContent({
	title,
	icon,
	description,
	withoutBorder = false,
}: EmptyListContent): ReactElement {
	return (
		<Stack
			sx={{
				size: 'large',
				position: 'relative',
				py: 'xLarge',
				alignItems: 'center',
				border: withoutBorder ? '1px solid ' + theme.colors.neutral300 : 'none',
			}}
		>
			{icon}
			<Stack direction="vertical" size="none" alignItems="center" width={700}>
				<Text variant="xLargeStrong">{title}</Text>
				{description &&
					(typeof description === 'string' ? (
						<Text variant="medium" textAlign="center" color="neutral600">
							{description}
						</Text>
					) : (
						description
					))}
			</Stack>
		</Stack>
	);
}
