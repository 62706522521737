/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React from 'react';

export interface WizardContentProps extends ContainerProps {
	innerProps?: ContainerProps;
}

export const WizardContent: React.FC<WizardContentProps> = ({ children, maxWidth = 1280, innerProps, ...props }) => {
	return (
		<Container
			sx={{ gridArea: 'content', flexGrow: 1, py: 'medium', px: 'xxxxLarge', minWidth: 720, overflow: 'auto' }}
			{...props}
		>
			<Container mx="auto" maxWidth={maxWidth} {...innerProps}>
				{children}
			</Container>
		</Container>
	);
};
