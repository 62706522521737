/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	Container,
	hasError,
	Label,
	ModalContentV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	SettingsGroup,
	SettingsGroupItem,
	SettingsGroupProps,
	Text,
} from 'components';
import ReadOnlyEnvironmentDetails from 'pages/settings/environments/config/ReadOnlyEnvironmentDetails';
import { IconEnvironment, IconEnvironmentGlobal } from 'components/icons';
import { FormikRadio } from 'components/Formik/FormikRadio';
import { EnvironmentSummaryVO, TeamVO } from 'ui-api';
import React, { useEffect } from 'react';
import { useField } from 'formik';

type EnvironmentSelectorProps = SettingsGroupProps & {
	environments: EnvironmentSummaryVO[];
	fieldName: string;
	onlyPermittedByTeam?: boolean;
	team?: TeamVO;
	loading?: boolean;
};

export const EnvironmentLabel: React.VFC<{ environment: EnvironmentSummaryVO }> = ({ environment }) => {
	return (
		<>
			{environment.global ? <IconEnvironmentGlobal /> : <IconEnvironment />}
			<Label ml={'xxSmall'} variant={'mediumMedium'}>
				{environment.name}
			</Label>
		</>
	);
};

const EnvironmentSelector: React.VFC<EnvironmentSelectorProps> = ({
	environments,
	fieldName,
	onlyPermittedByTeam = false,
	team,
	disabled,
	loading = false,
	...props
}) => {
	const [showDetails, setShowDetails] = React.useState<EnvironmentSummaryVO>();
	const [field, meta, fieldHelpers] = useField(fieldName);
	const selectedEnvironmentId: string = field.value;

	const isEnvironmentSelected = (environmentId: string): boolean => selectedEnvironmentId === environmentId;
	const isEnvironmentPermitted = (environmentId: string): boolean =>
		!!team && team.allowedEnvironments.includes(environmentId);
	const showPermissionWarning = (environmentId: string): boolean =>
		onlyPermittedByTeam && !isEnvironmentPermitted(environmentId) && !loading;

	const handleChangeCheck = (environmentId: string): void => {
		fieldHelpers.setValue(isEnvironmentSelected(environmentId) ? undefined : environmentId);
	};
	const selectableEnvironments = environments.filter(
		(environment) =>
			!onlyPermittedByTeam || isEnvironmentSelected(environment.id) || isEnvironmentPermitted(environment.id),
	);
	useEffect(() => {
		if (selectableEnvironments.length === 1) {
			if (!isEnvironmentSelected(selectableEnvironments[0].id)) {
				handleChangeCheck(selectableEnvironments[0].id);
			}
		}
	}, [selectableEnvironments]);

	return selectableEnvironments.length > 0 ? (
		<>
			<SettingsGroup {...props} hasError={hasError(meta)}>
				{selectableEnvironments.map((environment) => (
					<SettingsGroupItem
						key={environment.id}
						sx={{
							'& + [data-settings-group-item], [data-settings-group-item] + &, [data-settings-group-header] + &': {
								borderTop: '1px solid',
								borderColor: 'neutral200',
							},
						}}
						onClick={
							!disabled && selectableEnvironments.length !== 1
								? (e) => {
										e.preventDefault();
										e.stopPropagation();
										handleChangeCheck(environment.id);
								  }
								: undefined
						}
					>
						<Container display={'flex'} alignItems={'center'}>
							<FormikRadio
								name={fieldName}
								value={environment.id}
								checked={isEnvironmentSelected(environment.id)}
								disabled={disabled || selectableEnvironments.length === 1}
								onChange={() => fieldHelpers.setValue(environment.id)}
							/>
							<Container mx={'small'} bg={'neutral200'} height={40} width={'1px'} />
							<EnvironmentLabel environment={environment} />
							{showPermissionWarning(environment.id) ? (
								<Text ml={'small'} variant={'mediumStrong'} color={'coral'}>
									No Permissions for Environment!
								</Text>
							) : null}
							<Button
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setShowDetails(environment);
								}}
								color={'purple700'}
								variant={'chromeless'}
								ml={'auto'}
							>
								<Text variant={'smallStrong'}>Details</Text>
							</Button>
						</Container>
					</SettingsGroupItem>
				))}
			</SettingsGroup>
			{showDetails && (
				<ModalOverlay open centerContent onClose={() => setShowDetails(undefined)}>
					{({ close }) => (
						<ModalV2 slick width="90vw">
							<ModalHeaderV2 title={showDetails.name} onClose={close} />
							<ModalContentV2>
								<ReadOnlyEnvironmentDetails environment={showDetails} />
							</ModalContentV2>
						</ModalV2>
					)}
				</ModalOverlay>
			)}
		</>
	) : loading ? null : (
		<Text variant={'mediumStrong'} color={'coral'} mt={'xSmall'}>
			No Environments allowed for this Team.
		</Text>
	);
};

export default EnvironmentSelector;
