/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import useActionHierarchy, { ActionCategory as ActionCategoryA } from 'experiment/actions/useActionHierarchy';
import ExtensionCallout from 'pages/experiments/components/ExtensionCallout';
import { useApplicationHeaderHeight } from 'App/ApplicationHeaderHeight';
import { Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { ReactElement } from 'react';
import { Stack } from 'components';
import { useField } from 'formik';

import { SidebarLaneMaker } from '../DragAndDropHandler';
import { useEditorSettings } from '../useEditorSettings';
import { ActionCategory } from './types';
import ActionGroup from './ActionGroup';

export default function ActionsSidebar(): ReactElement {
	const [{ value: environmentId }] = useField<string>('environmentId');
	const [{ value: teamId }] = useField<string>('teamId');

	const { mode } = useEditorSettings();

	const applicationHeaderHeight = useApplicationHeaderHeight();
	// experiment header and subheader
	const height =
		mode === 'experiment'
			? `calc(100vh - ${applicationHeaderHeight + 48 + 80}px)`
			: `calc(100vh - ${applicationHeaderHeight + 65 + 56 + 60}px)`;

	if (!environmentId) {
		return <SidebarWithoutEnvironment height={height} />;
	}
	return <SidebarWithEnvironment teamId={teamId} environmentId={environmentId} height={height} />;
}

function SidebarWithoutEnvironment({ height }: { height: string }): ReactElement {
	return (
		<Stack maxWidth={270} minWidth={270} height={height} overflowY="auto">
			<Stack size="none">
				<Droppable droppableId={SidebarLaneMaker} direction="vertical" isDropDisabled>
					{(dropProvided: DroppableProvided) => (
						<div ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
							<ActionGroup
								key="basic"
								group={{
									label: 'Basic',
									actions: [
										{
											id: 'wait',
											label: 'Wait',
										},
									],
								}}
								initalExpanded
							/>
						</div>
					)}
				</Droppable>
			</Stack>
			<ExtensionCallout />
		</Stack>
	);
}

function SidebarWithEnvironment({
	teamId,
	environmentId,
	height,
}: {
	teamId: string;
	environmentId: string;
	height: string;
}): ReactElement {
	const actionCategories = useActionHierarchy(teamId, environmentId);

	return (
		<Stack maxWidth={270} minWidth={270} height={height} overflowY="auto">
			<Stack size="none">
				<Droppable droppableId={SidebarLaneMaker} direction="vertical" isDropDisabled>
					{(dropProvided: DroppableProvided) => (
						<div ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
							<ActionGroup
								key="basic"
								group={{
									label: 'Basic',
									actions: [
										{
											id: 'wait',
											label: 'Wait',
										},
									],
								}}
								initalExpanded
							/>
							{actionCategories.map((category) => (
								<ActionGroup key={category.label} group={mapCategory(category)} />
							))}
						</div>
					)}
				</Droppable>
			</Stack>
			<ExtensionCallout />
		</Stack>
	);
}

function mapCategory(category: ActionCategoryA): ActionCategory {
	return {
		...category,
		actions: category.actions
			? category.actions.map((a) => ({
					id: a.action.id,
					label: a.label,
			  }))
			: undefined,
		subCategories: category.subCategories?.map((subCategory) => ({
			...subCategory,
			actions: subCategory.actions.map((a) => ({
				id: a.action.id,
				label: a.label,
			})),
		})),
	};
}
