/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import DeleteViewModal from 'pages/customViews/modals/DeleteViewModal';
import SaveViewModal from 'pages/customViews/modals/SaveViewModal';
import { useFormikContext } from 'formik';
import { useHistory } from 'url/hooks';
import { ReactElement } from 'react';

import { LandscapeConfig } from './types';

export default function ActionModalHandler(): ReactElement | null {
	const { values, setFieldValue, submitForm } = useFormikContext<LandscapeConfig>();
	const { createHref, push } = useHistory();

	if (values.action === 'delete') {
		return (
			<DeleteViewModal
				viewId={values.id}
				viewName={values.name}
				onClose={() => setFieldValue('action', null)}
				onDeleteSuccess={() =>
					push(
						createHref((location) => {
							location.pathname = '/landscape/views';
						}),
					)
				}
			/>
		);
	}

	if (values.action === 'create') {
		return (
			<SaveViewModal
				onClose={() => setFieldValue('action', null)}
				onSave={async (name, description) => {
					setFieldValue('name', name);
					setFieldValue('description', description);

					// Hack to wait for new value to be applied
					// Pending https://github.com/jaredpalmer/formik/issues/529
					await Promise.resolve();

					submitForm();
				}}
			/>
		);
	}

	return null;
}
