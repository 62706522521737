/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import axios, { AxiosProgressEvent } from 'axios';
import { UploadFileResponse } from 'ui-api';

export class FilesApi {
	async upload(file: File, onUploadProgress: (progressEvent: AxiosProgressEvent) => void): Promise<UploadFileResponse> {
		const formData = new FormData();
		formData.append('file', file);
		return (
			await axios.post<UploadFileResponse>('ui/files', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				onUploadProgress,
			})
		).data;
	}

	async fetchFilename(id: string): Promise<string> {
		return (await axios.get<string>(`/ui/files/${id}/filename`)).data;
	}
}
