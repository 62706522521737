/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	ButtonIcon,
	Label,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	Text,
	TextField,
	Tooltip,
} from 'components';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { useAutoFocus } from 'utils/hooks/useAutoFocus';
import { useField, useFormikContext } from 'formik';
import React, { ReactNode, useState } from 'react';
import { IconDelete } from 'components/icons';
import { InvitationVO } from 'ui-api';

import { RoleSelect } from './roleSelect';
import { TeamSelect } from './teamSelect';

export type InviteUserFormValues = {
	invitations: InvitationVO[];
};

export const InviteUserForm: React.VFC = () => {
	const formik = useFormikContext<InviteUserFormValues>();
	const autoFocusRef = useAutoFocus();

	function handleAddInvitation(email?: string): void {
		if (email && !formik.values.invitations.find((i) => i.email === email)) {
			formik.setFieldValue('invitations', [...formik.values.invitations, { email: email, role: 'USER' }]);
			setEmail('');
		}
	}

	const handleTeamChange = (index: number) => (team: string) => {
		formik.setFieldValue(`invitations[${index}].team`, team);
	};

	const handleRoleChange = (index: number) => (role: string) => {
		formik.setFieldValue(`invitations[${index}].role`, role);
	};

	const handleRemoveInvitation = (invitation: InvitationVO) => () => {
		const newInvitations = formik.values.invitations.filter((i) => i.email !== invitation.email);
		formik.setFieldValue('invitations', newInvitations);
	};

	const [email, setEmail] = useState<string | undefined>('');

	return (
		<Stack>
			<Stack>
				<Text>Invited users will get an e-mail that gives them access to steadybit.</Text>
				<Stack direction="horizontal" variant="large" alignItems={'flex-end'}>
					<Label width="100%">
						E-mail address
						<TextField
							mt={'xSmall'}
							name={'email'}
							value={email}
							maxLength={1000}
							autoComplete={'off'}
							ref={autoFocusRef}
							onChange={(e) => setEmail(e.target.value)}
							data-private
						/>
					</Label>
					<Button id={'add-invitation'} mb={'xxxSmall'} onClick={() => handleAddInvitation(email)}>
						Add
					</Button>
				</Stack>
				<InvitationTable>
					{formik.values.invitations.map((i, index) => (
						<InvitationRow
							key={i.email}
							invitation={i}
							disabled={false}
							onTeamChange={handleTeamChange(index)}
							onRoleChange={handleRoleChange(index)}
							onRemove={handleRemoveInvitation(i)}
							index={index}
						/>
					))}
				</InvitationTable>
			</Stack>
		</Stack>
	);
};

function InvitationTable({ children }: { children: ReactNode }): ReactElement {
	return (
		<Table width={'100%'} data-cy="team-table">
			<TableHead>
				<TableRow>
					<TableHeadCell>E-Mail</TableHeadCell>
					<TableHeadCell width={'200px'}>Team</TableHeadCell>
					<TableHeadCell width={'200px'}>Role</TableHeadCell>
					<TableHeadCell width={'40px'} />
				</TableRow>
			</TableHead>
			<TableBody>{children}</TableBody>
		</Table>
	);
}

interface InvitationRowProps {
	invitation: InvitationVO;
	disabled?: boolean;
	onTeamChange: (r: string) => void;
	onRoleChange: (r: string) => void;
	onRemove: () => void;
	index: number;
}

const InvitationRow: React.VFC<InvitationRowProps> = ({
	invitation,
	disabled,
	onTeamChange,
	onRoleChange,
	onRemove,
	index,
}) => {
	const [, meta] = useField(`invitations[${index}].email`);
	const error = meta.error;

	return (
		<TableRow hoverable={true}>
			<TableDataCell overflow={'hidden'}>
				<Stack size="none">
					<Tooltip onlyShowOnEllipsis content={<span data-private>{invitation.email}</span>}>
						<Text
							variant={'mediumStrong'}
							maxWidth={'300px'}
							overflow={'hidden'}
							sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
							data-private
						>
							{invitation.email}
						</Text>
					</Tooltip>
					{error && <Text color="coral">{error}</Text>}
				</Stack>
			</TableDataCell>
			<TableDataCell>
				{!disabled ? <TeamSelect value={invitation.team} onChange={onTeamChange} /> : invitation.team}
			</TableDataCell>
			<TableDataCell>
				{!disabled ? <RoleSelect width={'200px'} value={invitation.role} onChange={onRoleChange} /> : invitation.role}
			</TableDataCell>
			<TableDataCell justifyContent={'flex-end'}>
				{!disabled ? (
					<ButtonIcon muted onClick={onRemove}>
						<IconDelete />
					</ButtonIcon>
				) : null}
			</TableDataCell>
		</TableRow>
	);
};
