/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { AdviceStatusVO, TargetPredicateVO, TargetTypeCategoryVO } from 'ui-api';
import ListHeaderSearch from 'components/List/presets/ListHeaderSearch';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import { CollapsibleV2, Container, Stack, Text } from 'components';
import EmptyListContent from 'components/List/EmptyListContent';
import { IconGroup, IconTargetType } from 'components/icons';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import AdviceBadges from 'components/advice/AdviceBadges';
import { ReactElement, useEffect, useState } from 'react';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { theme } from 'styles.v2/theme';
import { debounce } from 'lodash';
import { ampli } from 'ampli';

import TargetTable from './TargetTable';

interface TargetCategoriesProps {
	predicate: TargetPredicateVO;
	adviceType: string;
}

export default function TargetCategories({ adviceType, predicate }: TargetCategoriesProps): ReactElement {
	const [activeBadges, setActiveBadges] = useState<Array<AdviceStatusVO>>([]);
	const [internalSearchQuery, setInternalSearchQuery] = useState<string>('');
	const [searchQuery, setSearchQuery] = useState<string>('');
	const debouncedSearchQuery = debounce(setSearchQuery, 1000);

	const filterStatus = activeBadges.map((badge) => {
		if (badge === 'ACTION_NEEDED') {
			return 'advice.status.action-needed';
		}
		if (badge === 'VALIDATION_NEEDED') {
			return 'advice.status.validation-needed';
		}
		return 'advice.status.implemented';
	});

	useEffect(() => {
		setActiveBadges([]);
		setInternalSearchQuery('');
		setSearchQuery('');
	}, [adviceType]);

	useEffect(() => {
		if (!!searchQuery && searchQuery.length > 0) {
			ampli.adviceTargetListFiltered({ filter_text: searchQuery });
		}
	}, [searchQuery]);

	const targetTypesResult = usePromise(
		() => Services.adviceApi.getTargetTypes(predicate, adviceType, searchQuery, filterStatus),
		[adviceType, searchQuery, filterStatus.join(',')],
	);

	const [updateSignal, setUpdateSignal] = useState(0);
	const countResult = usePromise(
		() => Services.adviceApi.count(predicate, adviceType, searchQuery),
		[adviceType, searchQuery, updateSignal],
	);
	useEventEffect(
		() => {
			setUpdateSignal((signal) => signal + 1);
		},
		['advice-updated'],
		() => {},
		[],
	);

	const targetDefinitionsResult = useTargetDefinitions();
	const targetDefinitions = targetDefinitionsResult.value || [];

	const [targetTypes, setTargetTypes] = useState<TargetTypeCategoryVO[] | undefined>(undefined);
	useEffect(() => {
		if (targetTypesResult.value && targetDefinitionsResult.value) {
			setTargetTypes(
				targetTypesResult.value.sort((a, b) => {
					const targetDefinitionA = targetDefinitions.find((definition) => definition.id === a.type);
					const targetDefinitionB = targetDefinitions.find((definition) => definition.id === b.type);
					return targetDefinitionA && targetDefinitionB
						? targetDefinitionA?.label.other.localeCompare(targetDefinitionB?.label.other)
						: 0;
				}),
			);
		} else {
			setTargetTypes(undefined);
		}
	}, [targetTypesResult, targetDefinitionsResult]);

	return (
		<Stack overflowY="auto" maxHeight="100%" px="xLarge" pt="xLarge" pb="small">
			<Container display="flex" alignItems="center" justifyContent="space-between" flexDirection="row-reverse">
				<ListHeaderSearch
					title="Search targets"
					value={internalSearchQuery}
					setValue={(v) => {
						setInternalSearchQuery(v);
						debouncedSearchQuery(v);
					}}
				/>

				{countResult.value && (
					<Stack direction="horizontal" alignItems="center">
						<Text variant="smallStrong">Filter by:</Text>
						<AdviceBadges
							adviceRequireAction={countResult.value['advice.status.action-needed'] || 0}
							adviceRequireValidation={countResult.value['advice.status.validation-needed'] || 0}
							adviceDone={countResult.value['advice.status.implemented'] || 0}
							onBadgeClick={(type) => {
								if (activeBadges.indexOf(type) === -1) {
									setActiveBadges([...activeBadges, type]);
								} else {
									setActiveBadges(activeBadges.filter((t) => t !== type));
								}
							}}
							activeBadges={activeBadges}
						/>
					</Stack>
				)}
			</Container>
			{targetTypes && (
				<Stack>
					{targetTypes.length === 0 && (
						<EmptyListContent
							icon={<IconGroup variant="xxLarge" color="slate" />}
							title="No targets available"
							description="There are no targets matching your query."
						/>
					)}

					{targetTypes.map(({ type, numberOfTargets }) => {
						const targetDefinition = targetDefinitions.find((t) => t.id === type);
						if (!targetDefinition) {
							return null;
						}

						return (
							<CollapsibleV2
								key={type}
								title={
									<Stack direction="horizontal" size="xSmall" alignItems="center" color="neutral700">
										<IconTargetType targetType={targetDefinition.id} minWidth={16} />
										<Text variant="mediumStrong" sx={{ ...textEllipsis }}>
											{targetDefinition.label.other} ({numberOfTargets})
										</Text>
									</Stack>
								}
								backgroundColor={theme.colors.neutral000}
								initialExpanded={targetTypes.length === 1}
							>
								<TargetTable
									targetDefinition={targetDefinition}
									filterStatus={filterStatus}
									numItems={numberOfTargets}
									searchQuery={searchQuery}
									adviceType={adviceType}
									predicate={predicate}
									targetType={type}
								/>
							</CollapsibleV2>
						);
					})}
				</Stack>
			)}
		</Stack>
	);
}
