/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ReactElement, ReactNode } from 'react';
import { Spinner, Text } from 'components';

import EmptyContent from './EmptyContent';

interface LoadingContentProps {
	children: ReactNode;
	loading: boolean;
}

export default function LoadingContent({ children, loading }: LoadingContentProps): ReactElement {
	if (!loading) {
		return (
			<EmptyContent>
				<Spinner variant="large" color="neutral500" />
				<Text mt="xSmall" muted>
					{children}
				</Text>
			</EmptyContent>
		);
	}

	return (
		<EmptyContent>
			<Spinner variant="large" color="neutral500" />
		</EmptyContent>
	);
}
