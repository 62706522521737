/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { useContext, createContext } from 'react';
import { TenantVO } from 'ui-api';

type TenantContextType = {
	tenant: TenantVO;
};

// Due to the initial implementation, the tenant information is stored with a context.
// We never write invalid tenant objects to this context, so we can safely ignore the TS error
// about a missing default value.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const TenantContext = createContext<TenantContextType>({ tenant: null });

export const useTenant = (): TenantVO => {
	const { tenant } = useContext(TenantContext);
	return tenant;
};
