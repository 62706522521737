/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, CollapsibleV2, Container, LoadingIndicator, Stack, Text } from 'components';
import EditableLabel from 'components/EditableLabel/EditableLabel';
import { LandscapeViewMappedGroupingVO } from 'ui-api';
import { IconAdd, IconTrash } from 'components/icons';
import { usePromise } from 'utils/hooks/usePromise';
import { Flex } from '@steadybit/ui-components-lib';
import { Services } from 'services/services';
import { useFormikContext } from 'formik';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { LandscapeConfig } from '../types';
import DropDown from './DropDown';

interface ModalMappingsSectionProps {
	setMappings: (mappings: LandscapeViewMappedGroupingVO[]) => void;
	mappings: LandscapeViewMappedGroupingVO[];
	attributeKey: string;
	readOnly?: boolean;
}

export default function ModalMappingsSection({
	attributeKey,
	mappings,
	readOnly,
	setMappings,
}: ModalMappingsSectionProps): ReactElement {
	const formik = useFormikContext<LandscapeConfig>();
	const { filterQuery, environmentId } = formik.values;

	const attributeValuesResult = usePromise(
		() =>
			Services.editorApi.fetchTargetAttributeValues({
				key: attributeKey,
				environmentId,
				predicate: { query: filterQuery },
			}),
		[attributeKey, environmentId, filterQuery],
	);
	const attributeValues = attributeValuesResult?.value || [];

	const allUsedValues = mappings.flatMap((mapping) => mapping.attributeValues);
	const remainingValues = attributeValues
		.filter((value) => allUsedValues.indexOf(value) === -1)
		.sort((a, b) => a.localeCompare(b));

	const addValueToMapping = (mappingIndex: number, value: string): void => {
		const newMappings = [...mappings];
		newMappings[mappingIndex].attributeValues = [...newMappings[mappingIndex].attributeValues, value];
		setMappings(newMappings);
	};

	const removeValueFromMapping = (mappingIndex: number, value: string): void => {
		const newMappings = [...mappings];
		newMappings[mappingIndex].attributeValues = newMappings[mappingIndex].attributeValues.filter((v) => v !== value);
		setMappings(newMappings);
	};

	return (
		<Stack size="medium" mt="medium">
			{mappings.map((mapping, i) => {
				return (
					<CollapsibleV2
						key={i}
						title={mapping.groupName}
						borderless
						initialExpanded
						actions={
							!readOnly && (
								<IconTrash
									sx={{
										cursor: 'pointer',
										color: 'neutral600',
										'&:hover': {
											color: 'neutral800',
										},
									}}
									onClick={(e) => {
										e.stopPropagation();
										e.preventDefault();
										setMappings(mappings.filter((_, index) => index !== i));
									}}
								/>
							)
						}
					>
						<Container
							sx={{
								display: 'grid',
								gridTemplateColumns: '1fr 1fr',
								gap: '36px',
							}}
						>
							<Stack size="xSmall">
								<Text variant="smallStrong" color="neutral600">
									Bucket Name
								</Text>
								<Container sx={{ borderBottom: '2px solid ' + theme.colors.neutral300 }}>
									<EditableLabel
										placeholder="untitled"
										value={mapping.groupName}
										onChange={(label) => {
											const newMappings = [...mappings];
											newMappings[i].groupName = label;
											setMappings(newMappings);
										}}
										disabled={readOnly}
									/>
								</Container>
							</Stack>
							<Stack size="xSmall">
								<Text variant="smallStrong" color="neutral600">
									Attribute Values
								</Text>
								{mapping.attributeValues.map((attributeValue) => {
									return (
										<DropDown
											key={attributeValue}
											label={attributeValue}
											placeholder="Type to search..."
											items={remainingValues}
											onItemClick={(value) =>
												value ? addValueToMapping(i, value) : removeValueFromMapping(i, attributeValue)
											}
											readonly={readOnly}
										/>
									);
								})}
								{remainingValues.length > 0 && (
									<DropDown
										label=""
										placeholder="Type to search..."
										items={remainingValues}
										onItemClick={(value) => value && addValueToMapping(i, value)}
										readonly={readOnly}
									/>
								)}
							</Stack>
						</Container>
					</CollapsibleV2>
				);
			})}

			{remainingValues.length > 0 && (
				<Button
					variant="chromeless"
					width="fit-content"
					mb="small"
					ml="small"
					onClick={() => setMappings([...mappings, newMapping()])}
					disabled={readOnly}
				>
					<IconAdd mr="xSmall" /> Add new bucket
				</Button>
			)}

			{attributeValuesResult.loading && (
				<Flex direction="horizontal" align="center" spacing="xSmall">
					<LoadingIndicator variant="small" />
					<Text color="neutral600">Loading attribute values...</Text>
				</Flex>
			)}
		</Stack>
	);
}

export function newMapping(): LandscapeViewMappedGroupingVO {
	return {
		id: uuidv4(),
		groupName: '',
		attributeValues: [],
	};
}

export function removeEmptyMappings(mappings: LandscapeViewMappedGroupingVO[]): LandscapeViewMappedGroupingVO[] {
	return mappings.filter((mapping) => mapping.attributeValues.length > 0);
}

export function addUntitledTitles(mappings: LandscapeViewMappedGroupingVO[]): LandscapeViewMappedGroupingVO[] {
	mappings.forEach((mapping) => {
		if (!mapping.groupName) {
			mapping.groupName = 'untitled';
		}
	});
	return mappings;
}
