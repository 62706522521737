/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

export default `
precision highp float;
precision highp int;

varying vec2 vUv;
varying vec3 vColor;

void main()	{
  vec3 color = vColor;

  float dist = length(vUv - vec2(0.5, 0.5)) * 1.2;
  float delta = fwidth(dist);
  float alpha = smoothstep(0.5-delta, 0.5, dist);
  vec4 colorCircle = vec4(color, 1.0 - alpha);
  
  gl_FragColor = colorCircle;
}
`;
