/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Container,
	Pagination,
	ShortenedText,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	TableSort,
	Tooltip,
} from 'components';
import { getAttributeHeader, getAttributeKeyValue, TargetId } from 'targets/util';
import { localeCompareIgnoreCase, toTitleCase } from 'utils/string';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { emit, event$ } from 'targets/Explore/ServiceLocator';
import { targetIdParam } from 'targets/Explore/urlParams';
import { useDimensions } from 'utils/hooks/useDimensions';
import { useStream } from 'utils/hooks/useStream';
import { TargetTypeDescriptionVO } from 'ui-api';
import { useUrlState } from 'url/useUrlState';
import { theme } from 'styles.v2/theme';
import { filter, map } from 'rxjs';

import { HoverEntity, LayoutedTarget, isColorLegendHoveredEvent, isHoverEntityEvent, isLayoutedTarget } from '../types';
import { useAttributeDefinitions } from '../../../../attributes/useAttributeDefinitions';
import AdviceBadges from '../../../../components/advice/AdviceBadges';

interface TargetIdUrlState {
	targetId: TargetId | null;
}

interface TargetTableProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	targets: LayoutedTarget[];
	selectedTargetId?: string;
	showAdvices: boolean;
	targetType: string;
	expanded: boolean;
}

type SortAttribute = {
	attribute: string | null;
	direction: 'asc' | 'desc';
};

export default function TargetTable({
	targetDefinitions,
	selectedTargetId,
	showAdvices,
	targetType,
	expanded,
	targets,
}: TargetTableProps): ReactElement | null {
	const pageSize = 20;
	const [page, setPage] = useState(0);
	const [divRef, [wrapperWidth]] = useDimensions<HTMLDivElement>();
	const [, , updateUrlState] = useUrlState<TargetIdUrlState>([targetIdParam]);
	const [sortAttribute, setSortAttribute] = useState<SortAttribute>({
		attribute: null,
		direction: 'asc',
	});

	const [highlightByAttributeKey, setHighlightByAttributeKey] = useState<string | null>(null);
	const [highlightByAttributeValues, setHighlightByAttributeValues] = useState<string[] | null>(null);
	useEffect(() => {
		const hoveredColorGroupSubscription = event$()
			.pipe(filter(isColorLegendHoveredEvent))
			.subscribe((e) => {
				if (e.colorAttributeKey && e.colorAttributeValues) {
					setHighlightByAttributeKey(e.colorAttributeKey);
					setHighlightByAttributeValues(e.colorAttributeValues);
				} else {
					setHighlightByAttributeKey(null);
					setHighlightByAttributeValues(null);
				}
			});

		return () => {
			hoveredColorGroupSubscription.unsubscribe();
		};
	}, []);

	const flattenedTargets = useMemo(() => {
		const flattenedTargets: LayoutedTarget[] = [];
		const touchedIds = new Set<string>();
		for (let i = 0; i < targets.length; i++) {
			const target = targets[i];
			const { id } = target.target;
			if (!touchedIds.has(id)) {
				flattenedTargets.push(target);
				touchedIds.add(id);
			}
		}
		return flattenedTargets;
	}, [targets]);

	// reassigning targets to make sure, that the non flattened targets are not used below
	targets = flattenedTargets;

	const hoveredTargetId = useStream<string | null>(
		event$()
			.pipe(filter(isHoverEntityEvent))
			.pipe(
				map((e: HoverEntity) => {
					return isLayoutedTarget(e.entity) ? e.entity?.target.id : null;
				}),
			),
		[targets],
	);

	useEffect(() => {
		if (selectedTargetId) {
			const indexOfSelectedTarget = targets.findIndex((t) => t.target.id === selectedTargetId);
			setPage(Math.max(0, Math.floor(indexOfSelectedTarget / pageSize)));
		} else {
			setPage(0);
		}
	}, [targets, selectedTargetId]);

	const targetDefinition = targetDefinitions.find((t) => t.id === targetType);
	if (!targetDefinition) {
		return null;
	}

	const columns = targetDefinition.table.columns.slice(0, expanded ? 10 : 1);
	const numColumns = columns.length;
	const tableWidth = showAdvices ? wrapperWidth - 150 : wrapperWidth;

	const attributeToSort: string = sortAttribute.attribute || columns[0].attribute;
	if (attributeToSort === 'advice') {
		targets = targets.slice().sort((t1, t2) => {
			return sortAttribute.direction === 'asc' ? getScore(t1) - getScore(t2) : getScore(t2) - getScore(t1);
		});
	} else {
		targets = targets.slice().sort((t1, t2) => {
			const attrT1: string = t1.target.attributes.find((a) => a.key === attributeToSort)?.value || '';
			const attrT2: string = t2.target.attributes.find((a) => a.key === attributeToSort)?.value || '';
			return sortAttribute.direction === 'asc'
				? localeCompareIgnoreCase(attrT1, attrT2)
				: localeCompareIgnoreCase(attrT2, attrT1);
		});
	}

	// paginate
	const pagedTargets = targets.slice(page * pageSize, (page + 1) * pageSize);
	const totalPages = Math.ceil(targets.length / pageSize);
	const attributeDefinitions = useAttributeDefinitions();
	return (
		<Container
			ref={divRef}
			sx={{
				mx: '-small',
				mb: '-xSmall',
				width: 'calc(100% + 32px)',
			}}
		>
			<Table>
				<TableHead>
					<TableRow>
						{columns.map((column, i) => {
							const header = getAttributeHeader(column, attributeDefinitions?.value || []);
							return (
								<TableHeadCell
									key={i}
									width={expanded ? tableWidth / numColumns : tableWidth}
									sx={{ minHeight: '26px !important' }}
								>
									<TableSort
										sort={attributeToSort === header ? sortAttribute.direction : undefined}
										onClick={() => {
											setSortAttribute({
												attribute: header,
												direction:
													attributeToSort === header ? (sortAttribute.direction === 'asc' ? 'desc' : 'asc') : 'asc',
											});
										}}
									>
										{toTitleCase(header)}
									</TableSort>
								</TableHeadCell>
							);
						})}
						{showAdvices && (
							<TableHeadCell width="150px" sx={{ minHeight: '26px !important' }}>
								<TableSort
									sort={attributeToSort === 'advice' ? sortAttribute.direction : undefined}
									onClick={() => {
										setSortAttribute({
											attribute: 'advice',
											direction:
												attributeToSort === 'advice' ? (sortAttribute.direction === 'asc' ? 'desc' : 'asc') : 'asc',
										});
									}}
								>
									Advice
								</TableSort>
							</TableHeadCell>
						)}
					</TableRow>
				</TableHead>
				<TableBody>
					{pagedTargets.map((t, iT) => {
						const isSelected = t.target.id === selectedTargetId;
						let isHovered = t.target.id === hoveredTargetId;
						const targetAttributes = t.target.attributes.map((attr) => ({ key: attr.key, value: attr.value }));
						if (highlightByAttributeKey && highlightByAttributeValues) {
							isHovered = !!t.target.attributes.find(
								(a) =>
									a.key === highlightByAttributeKey &&
									highlightByAttributeValues &&
									highlightByAttributeValues.includes(a.value),
							);
						}

						return (
							<TableRow
								key={t.target.id + '-' + iT}
								hoverable
								sx={{
									bg: iT % 2 === 0 ? 'neutral000' : 'neutral100',
									cursor: 'pointer',
									'&:hover': {
										bg: 'neutral200',
									},
								}}
								onClick={() =>
									updateUrlState({
										targetId: {
											type: t.target.type,
											name: t.target.name,
										},
									})
								}
								onMouseEnter={() => emit({ type: 'hoverEntity', entity: t })}
								onMouseLeave={() => emit({ type: 'hoverEntity', entity: null })}
							>
								{columns.map((column, i) => {
									const value = getAttributeKeyValue(column, targetAttributes)?.value;

									let content = null;
									if (!value && i === 0) {
										content = (
											<ShortenedText noWrap variant="medium" sx={{ fontStyle: 'italic' }}>
												not defined
											</ShortenedText>
										);
									} else {
										content = (
											<ShortenedText
												noWrap
												variant={isSelected || isHovered ? 'smallStrong' : 'small'}
												fontFamily="monospace"
												color={isSelected || isHovered ? 'slate' : 'neutral700'}
											>
												{value}
											</ShortenedText>
										);
									}

									if (i === 0) {
										content = (
											<Tooltip content={t.target.resolvedTypeLabel || t.target.type}>
												<Stack direction="horizontal" size="xSmall" alignItems="center" ml="xSmall">
													{content}
												</Stack>
											</Tooltip>
										);
									}

									return (
										<TableDataCell
											key={i}
											onClick={() => emit({ type: 'selectEntity', entity: t })}
											maxWidth={expanded ? tableWidth / numColumns : tableWidth}
											sx={{
												minHeight: '26px !important',
											}}
											style={{
												borderTop: isSelected ? '1px solid ' + theme.colors.slate : 'none',
												borderBottom: isSelected ? '1px solid ' + theme.colors.slate : 'none',
												borderLeft: isSelected && i === 0 ? '1px solid ' + theme.colors.slate : 'none',
												borderRight:
													isSelected && !showAdvices && i === numColumns - 1
														? '1px solid ' + theme.colors.slate
														: 'none',
												paddingLeft: i === 0 ? '0' : '8px',
												paddingRight: isSelected ? '0' : '8px',
											}}
										>
											{content}
										</TableDataCell>
									);
								})}

								{showAdvices && (
									<TableDataCell
										width="150px"
										sx={{
											minHeight: '26px !important',
										}}
										style={{
											borderTop: isSelected ? '1px solid ' + theme.colors.slate : 'none',
											borderBottom: isSelected ? '1px solid ' + theme.colors.slate : 'none',
											borderRight: isSelected ? '1px solid ' + theme.colors.slate : 'none',
											paddingRight: isSelected ? '0' : '8px',
										}}
									>
										{<AdviceBadges {...t.target} variant="smallest" floating={false} />}
									</TableDataCell>
								)}
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
			{totalPages > 1 && (
				<Container sx={{ px: 'medium', pt: 'xSmall' }}>
					<Pagination size="small" activePage={page} totalPages={totalPages} onClick={setPage} />
				</Container>
			)}
		</Container>
	);
}

function getScore(t: LayoutedTarget): number {
	let score = 0;
	if (t.target.adviceRequireAction > 0) {
		score += 100_000 * t.target.adviceRequireAction;
	}
	if (t.target.adviceRequireValidation > 0) {
		score += 50 * t.target.adviceRequireValidation;
	}
	if (t.target.adviceDone > 0) {
		score += t.target.adviceDone;
	}
	return score;
}
