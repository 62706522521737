/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { BasicButton, BasicButtonProps, Container } from 'components';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import React, { ReactNode, useCallback } from 'react';

import { Spinner } from '../Spinner';

export interface ButtonProps extends BasicButtonProps {
	muted?: boolean;
	loading?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ children, loading = false, tx, variant = 'primary', sx = {}, ...props }, ref) => {
		const wrapIfLoading = useCallback(
			(children: ReactNode) => {
				if (loading) {
					return (
						<Container as="span" sx={{ visibility: 'hidden' }}>
							{children}
						</Container>
					);
				}
				return children;
			},
			[loading],
		);
		return (
			<BasicButton
				ref={ref}
				tx={tx || 'buttons'}
				variant={variant}
				sx={{
					position: 'relative',
					display: 'inline-flex',
					alignItems: 'center',
					justifyContent: 'center',
					cursor: loading ? 'initial' : 'pointer',
					textDecoration: 'none',

					':hover': {
						textDecoration: 'none',
					},

					':disabled': {
						opacity: '.35',
						pointerEvents: 'none',
					},

					pointerEvents: loading ? 'none' : 'initial',

					'[data-spinner]': {
						margin: 'auto',
						width: '1.25em',
						height: '1.25em',
					},

					...sx,
				}}
				type={'button'}
				{...props}
			>
				{wrapIfLoading(children)}
				{loading && (
					<Spinner
						white={variant.startsWith('primary')}
						data-spinner
						sx={{
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					/>
				)}
			</BasicButton>
		);
	},
);
Button.displayName = 'Button';

export interface ProgressButtonProps extends Omit<ButtonProps, 'loading'> {
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
}

export const ProgressButton = React.forwardRef<HTMLButtonElement, ProgressButtonProps>(
	({ onClick, children, ...props }, ref) => {
		const [promise, setPromise] = React.useState<Promise<void>>(Promise.resolve());
		const [state] = useAsyncState(() => promise, [promise]);

		return (
			<Button ref={ref} {...props} onClick={(e) => setPromise(onClick(e))} loading={state.loading}>
				{children}
			</Button>
		);
	},
);
ProgressButton.displayName = 'ProgressButton';
