/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { StyleProp, Button, ButtonProps } from 'components';
import React, { ReactNode } from 'react';

import { Stack } from '../Stack';

type RadioButtonOwnProps = {
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	children?: ((opts: { checked?: boolean }) => React.ReactNode) | React.ReactNode;
	hasError?: boolean;
};
export type RadioButtonProps = RadioButtonOwnProps &
	Omit<ButtonProps, 'css' | keyof RadioButtonOwnProps> & {
		checked?: boolean;
		value?: string;
	};

const wrapIfString = (maybeString: ReactNode): ReactNode => {
	if (typeof maybeString === 'string') {
		return <span>{maybeString}</span>;
	} else {
		return maybeString;
	}
};

function getColors(checked?: boolean, disabled?: boolean, hasError?: boolean): StyleProp {
	return !disabled
		? {
				color: checked ? 'neutral000' : 'neutral800',
				bg: checked ? 'slate' : 'neutral200',
				borderColor: hasError ? 'coral' : 'transparent',

				':focus-within': {
					borderColor: 'neutral800',
				},

				':hover': {
					bg: checked ? 'slate' : 'neutral000',
					borderColor: hasError ? 'coral' : 'slate',
				},
		  }
		: {
				color: checked ? 'neutral000' : 'neutral400',
				bg: checked ? 'neutral400' : 'neutral100',
				borderColor: hasError ? 'coral' : 'transparent',
		  };
}

function getSmallColors(checked?: boolean, disabled?: boolean, hasError?: boolean): StyleProp {
	return !disabled
		? {
				color: checked ? 'neutral000' : 'neutral600',
				bg: checked ? 'slate' : 'neutral050',
				borderColor: hasError ? 'coral' : checked ? 'transparent' : 'neutral300',

				':focus-within': {
					borderColor: 'neutral800',
				},

				':hover': {
					bg: checked ? 'slate' : 'neutral000',
					borderColor: hasError ? 'coral' : 'slate',
				},
		  }
		: {
				color: checked ? 'neutral000' : 'neutral400',
				bg: checked ? 'neutral400' : 'neutral100',
				borderColor: hasError ? 'coral' : 'transparent',
				pointerEvents: 'none',
		  };
}

export const RadioButton = React.forwardRef<HTMLButtonElement, RadioButtonProps>(
	({ children, variant = 'medium', checked, name, disabled, onChange, value, hasError, ...props }, ref) => {
		return (
			<Button
				tx="radioButton"
				variant={variant}
				as="label"
				ref={ref}
				sx={{
					textAlign: 'left',
					borderRadius: '4px',

					...(variant !== 'small'
						? getColors(checked, disabled, hasError)
						: getSmallColors(checked, disabled, hasError)),

					input: {
						opacity: 0,
						clip: 'rect(0,0,0,0)',
						position: 'absolute',
					},
				}}
				{...props}
			>
				<input type="radio" name={name} value={value} checked={checked} disabled={disabled} onChange={onChange} />

				<Stack
					direction="horizontal"
					size="small"
					alignItems="center"
					justifyContent={variant === 'large' ? 'flex-start' : 'center'}
					height={'100%'}
					width={'100%'}
				>
					{wrapIfString(
						typeof children === 'function'
							? //the forwardRef messes up the children prop - we need this stupid workaround
							  (children as (opts: { checked?: boolean }) => React.ReactNode)({ checked })
							: children,
					)}
				</Stack>
			</Button>
		);
	},
);
RadioButton.displayName = 'RadioButton';
