/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { BufferGeometry, Float32BufferAttribute, Mesh, ShaderMaterial } from 'three';
import { event$ } from 'targets/Explore/ServiceLocator';
import { Subscription } from 'rxjs';

import { getGroupHoverLayer, targetHoverIconsLayer, targetHoverLayer } from './layer';
import { addQuadUVs, addQuadVertices } from '../attributeUtils';
import innerGroupFS from '../shader/innerGroupFragmentShader';
import innerGroupVS from '../shader/innerGroupVertexShader';
import { isLayoutedTarget, isSelectEntity } from '../types';
import iconFS from '../shader/hoverIconsFragmentShader';
import iconVS from '../shader/hoverIconsVertexShader';
import ExploreTextures from '../ExploreTextures';

export default class SelectionController {
	subscription: Subscription;

	iconMesh: Mesh<BufferGeometry, ShaderMaterial>;
	mesh: Mesh<BufferGeometry, ShaderMaterial>;

	constructor(textures: ExploreTextures, render: () => void) {
		const uvs: number[] = [];
		addQuadUVs(uvs);
		const selectionGeometry = new BufferGeometry();
		selectionGeometry.setAttribute('position', new Float32BufferAttribute([], 3));
		selectionGeometry.setAttribute('uv', new Float32BufferAttribute(uvs, 2));
		const material = new ShaderMaterial({
			uniforms: {
				color: { value: [91.0 / 255.0, 72.0 / 255.0, 202.0 / 255.0] },
			},
			vertexShader: innerGroupVS,
			fragmentShader: innerGroupFS,
			transparent: true,
			depthTest: true,
			depthWrite: true,
		});

		this.mesh = new Mesh(selectionGeometry, material);
		this.mesh.frustumCulled = false;
		this.mesh.name = 'selection';

		const iconMaterial = new ShaderMaterial({
			uniforms: {
				progress: { value: 1.0 },
				textureMap: { value: textures.get('container') },
				worldUnitInScreenSpace: { value: 0.0 },
			},
			vertexShader: iconVS,
			fragmentShader: iconFS,
			transparent: true,
			depthTest: true,
			depthWrite: true,
		});
		const iconGeometry = new BufferGeometry();
		iconGeometry.setAttribute('position', new Float32BufferAttribute([], 3));
		iconGeometry.setAttribute('uv', new Float32BufferAttribute(uvs, 2));
		iconGeometry.setAttribute('targetSize', new Float32BufferAttribute([], 1));

		this.iconMesh = new Mesh(iconGeometry, iconMaterial);
		this.iconMesh.frustumCulled = false;
		this.iconMesh.name = 'selection-icon';

		this.subscription = event$().subscribe((e) => {
			if (isSelectEntity(e)) {
				if (e.entity) {
					const { x, y } = e.entity;
					this.show();
					const vertices: number[] = [];
					const iconVertices: number[] = [];
					const radiuses: number[] = [];

					if (isLayoutedTarget(e.entity)) {
						this.iconMesh.material.uniforms.textureMap.value = textures.get(e.entity.target.type);
					} else {
						this.iconMesh.material.uniforms.textureMap.value = null;
					}

					if (isLayoutedTarget(e.entity)) {
						const r = e.entity.target.size * 1.205 + 0.02;
						radiuses.push(r, r, r, r, r, r);

						addQuadVertices(vertices, x, y, targetHoverLayer, r);
						addQuadVertices(iconVertices, x, y, targetHoverIconsLayer, Math.min(3, r - 0.2) * 0.5);

						this.mesh.renderOrder = targetHoverLayer;
						this.iconMesh.renderOrder = targetHoverIconsLayer;
					} else {
						const r = e.entity.r * 1.2 - 0.2;
						radiuses.push(r, r, r, r, r, r);

						const d = getGroupHoverLayer(e.entity.group.depth);
						addQuadVertices(vertices, x, y, d, r);
						addQuadVertices(iconVertices, x, y, d, Math.min(3, r - 0.2) * 0.5);

						this.mesh.renderOrder = d;
						this.iconMesh.renderOrder = d;
					}

					selectionGeometry.setAttribute('position', new Float32BufferAttribute(vertices, 3));
					iconGeometry.setAttribute('position', new Float32BufferAttribute(iconVertices, 3));
					iconGeometry.setAttribute('targetSize', new Float32BufferAttribute(radiuses, 1));

					render();
				} else {
					this.hide();
				}
			}
		});
	}

	updateScreenSpaceSize(worldUnitInScreenSpace: number): void {
		this.iconMesh.material.uniforms.worldUnitInScreenSpace.value = worldUnitInScreenSpace;
	}

	getMeshes(): Mesh<BufferGeometry, ShaderMaterial>[] {
		return [this.mesh, this.iconMesh];
	}

	show(): void {
		this.mesh.visible = true;
		this.iconMesh.visible = true;
	}

	hide(): void {
		this.mesh.visible = false;
		this.iconMesh.visible = false;
	}

	dispose(): void {
		this.subscription.unsubscribe();
		this.mesh.geometry.dispose();
		this.mesh.material.dispose();
		this.iconMesh.geometry.dispose();
		this.iconMesh.material.dispose();
	}
}
