/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ReactElement, ReactNode } from 'react';

export interface BannerAccentProps {
	children: ReactNode;
}

export const customPropertyName = '--banner-accent-color';

export function BannerAccent({ children }: BannerAccentProps): ReactElement {
	return <span style={{ color: `var(${customPropertyName})`, fontWeight: 500 }}>{children}</span>;
}
