/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { smellsLikeTemplatePlaceholder } from 'components/Formik';
import * as monaco from 'monaco-editor-core';
import { Services } from 'services/services';
import { useEffect } from 'react';

import { CustomKeywordFunction, CustomSuggestionFunction } from './Editor';
import { getSuggestionsForLinePosition } from './suggestions';
import { quoteForVariables } from '../../utils/envVars';
import { LANGUAGE } from './config';

interface UseSuggestionsProps {
	editor: monaco.editor.IStandaloneCodeEditor | null;
	targetType?: string;
	environmentId?: string;
	getAdditionalKeywords?: CustomKeywordFunction;
	getAdditionalSuggestions?: CustomSuggestionFunction;
}

const preferredKeywords = ['COUNT', 'AND', 'OR', 'IS', 'PRESENT', 'NOT', '=', '~'];

export default function useSuggestions(params: UseSuggestionsProps): void {
	const { editor, targetType, environmentId, getAdditionalKeywords, getAdditionalSuggestions } = params;
	useEffect(() => {
		if (!editor) {
			return;
		}

		const disposable = monaco.languages.registerCompletionItemProvider(LANGUAGE, {
			triggerCharacters: ['!', '~', '.', '"', '=', '('],
			provideCompletionItems: (model, position) => {
				const { column, lineNumber } = position;
				const line = model.getValueInRange({
					startLineNumber: lineNumber,
					endLineNumber: lineNumber,
					startColumn: 0,
					endColumn: Number.MAX_VALUE,
				});

				// -1 for transforming monaco space (starts at 1) to word space (starts at 0)
				const cursorPosition = column - 1;

				return getSuggestionsForLinePosition({
					line,
					cursorPosition,
					getAdditionalSuggestions,
					getAdditionalKeywords,
					getKeywords: () => Services.editorApi.fetchTargetAttributeKeys({ targetType, environmentId }),
					getKeywordValues: async (key) =>
						(await Services.editorApi.fetchTargetAttributeValues({ key, targetType, environmentId })).map((v) =>
							quoteForVariables(v),
						),
				}).then((suggestionResult) => {
					const range = {
						startLineNumber: lineNumber,
						endLineNumber: lineNumber,
						startColumn: suggestionResult.startIndex + 1,
						endColumn: suggestionResult.endIndex + 1,
					};
					return {
						suggestions: suggestionResult.suggestions.map(({ label, text }) => {
							return {
								label,
								insertText: insertTextIfNeeded(text, line),
								filterText: text,
								kind: monaco.languages.CompletionItemKind.Keyword,
								range,
								sortText: preferredKeywords.includes(label) ? '0' : '1',
							};
						}),
					};
				});
			},
		});
		return () => {
			disposable.dispose();
		};
	}, [editor, targetType, getAdditionalKeywords, getAdditionalSuggestions]);
}

function shouldWrapInQuotes(value: string, line: string): boolean {
	if (!line && smellsLikeTemplatePlaceholder(value)) {
		return false;
	}

	return (
		[':', '/', '$', '_', '{', '}', '[', ']'].some((char) => value.includes(char)) && value[value.length - 1] !== '"'
	);
}

function insertTextIfNeeded(text: string, line: string): string {
	if (shouldWrapInQuotes(text, line)) {
		return `"${text}"`;
	} else if (text.toLowerCase().endsWith('count')) {
		return text + '(';
	} else if (line.toLowerCase().lastIndexOf('count(') > line.toLowerCase().lastIndexOf(')') && text != ')') {
		if (text.endsWith(' ')) {
			return text.substring(0, text.length - 1) + ') ';
		}
		return text + ') ';
	} else {
		return text;
	}
}
