/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { EditorSettingsContext } from 'pages/experimentsV2/useEditorSettings';
import { ActionsContext } from 'pages/experimentsV2/useActions';
import { usePromise } from 'utils/hooks/usePromise';
import { useUrlState } from 'url/useUrlState';
import { Services } from 'services/services';
import { ReactElement } from 'react';
import { Stack } from 'components';

import WelcomeToTemplatesModal from '../WelcomeToTemplatesModal/WelcomeToTemplatesModal';
import TemplatePlaceholdersContent from './TemplatePlaceholdersContent';
import TemplateDescriptionContent from './TemplateDescriptionContent';
import TemplateExperimentContent from './TemplateExperimentContent';
import { UrlState, selectedViewParam } from './urlParams';
import UnsavedChangesPromt from './UnsavedChangesPromt';
import TemplateSubHeader from './TemplateSubHeader';
import TemplateHeader from './TemplateHeader';

export default function TemplateEditor(): ReactElement {
	const actions = usePromise(() => Services.actions.fetchActions(), []);
	const [{ selectedView }] = useUrlState<UrlState>([selectedViewParam]);

	return (
		<ActionsContext.Provider value={{ actions: actions.value || [] }}>
			<UnsavedChangesPromt />
			<Stack size="none">
				<TemplateHeader />
				<TemplateSubHeader />
				<WelcomeToTemplatesModal />
				<EditorSettingsContext.Provider value={{ mode: 'templateEditor' }}>
					{selectedView === 'editor' && <TemplateExperimentContent />}
					{selectedView === 'placeholders' && <TemplatePlaceholdersContent />}
					{selectedView === 'description' && <TemplateDescriptionContent />}
				</EditorSettingsContext.Provider>
			</Stack>
		</ActionsContext.Provider>
	);
}
