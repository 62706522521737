/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

export default `
precision highp float;
precision highp int;

varying vec2 vUv;
varying vec3 vColor;

uniform vec3 color;

void main()	{
	vUv = uv;
	vColor = color;
	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
`;
