/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import {
	CurrentUserVO,
	GetUsersPageResponse,
	InviteUsersRequest,
	UpdateCurrentUserRequest,
	UpdatedMembershipRoleRequest,
	UserVO,
} from 'ui-api';
import { getGlobal, setGlobal } from 'utils/localStorage';
import { PageParams } from 'utils/hooks/usePage';
import { uniq } from 'lodash';
import axios from 'axios';
import React from 'react';

import { EventsApi } from './eventsApi';

type UserContextType = {
	user: CurrentUserVO;
};

// Due to the initial implementation, the user information is stored with a context.
// We never write invalid tenant objects to this context, so we can safely ignore the TS error
// about a missing default value.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const UserContext = React.createContext<UserContextType>({ user: null });

export const useUser = (): CurrentUserVO => {
	const { user } = React.useContext(UserContext);
	return user;
};

export class UsersApi {
	private cachedUsers = new Map<string, UserVO>();

	constructor(events: EventsApi) {
		//remove user from cache when it changes
		events.events$.subscribe((event) => event.username && this.cachedUsers.delete(event.username));
	}

	async fetchUsers(page: PageParams): Promise<GetUsersPageResponse> {
		return this.findUsers('', page);
	}

	async fetchUsersWithQuery(query: string, page: PageParams): Promise<GetUsersPageResponse> {
		return this.findUsers(query, page);
	}

	async getCurrentUser(): Promise<CurrentUserVO> {
		return (await axios.get<CurrentUserVO>('/ui/users/me')).data;
	}

	async updateCurrentUser(body: UpdateCurrentUserRequest): Promise<void> {
		await axios.post('/ui/users/me', body);
	}

	async updateUserAmplitudeSessionId(amplitudeSessionId: number): Promise<void> {
		await axios.post(`/ui/users/me/session/${amplitudeSessionId}`);
	}

	async updateUserAmplitudeDeviceId(amplitudeDeviceId: String): Promise<void> {
		await axios.post(`/ui/users/me/device/${amplitudeDeviceId}`);
	}

	async findUsers(query: string, page: PageParams): Promise<GetUsersPageResponse> {
		const params = page.toUrlSearchParams({ query });
		const users = (await axios.get<GetUsersPageResponse>('/ui/users', { params })).data;
		users.content.forEach((user) => this.cachedUsers.set(user.username, user));
		return users;
	}

	async findUsersByUsername(usernames: string[]): Promise<Map<string, UserVO>> {
		const uniqueUsernames = uniq(usernames.filter(Boolean));
		const unknownUsernames = uniqueUsernames.filter((username) => !this.cachedUsers.has(username));

		if (unknownUsernames.length > 0) {
			const params = new URLSearchParams();
			unknownUsernames.forEach((username) => username && params.append('username', username));
			const users = (await axios.get<GetUsersPageResponse>('/ui/users', { params })).data.content;
			users.forEach((user) => this.cachedUsers.set(user.username, user));
		}

		const result = new Map<string, UserVO>();
		uniqueUsernames.forEach((username) => {
			const user = this.cachedUsers.get(username);
			if (user) {
				result.set(user.username, user);
			}
		});
		return result;
	}

	async updateUserRole(username: string, body: UpdatedMembershipRoleRequest): Promise<void> {
		await axios.post(`/ui/users/${encodeURI(username)}/role`, body);
	}

	async removeUserRole(username: string): Promise<void> {
		await axios.delete(`/ui/users/${encodeURI(username)}/role`);
	}

	async inviteUsers(body: InviteUsersRequest): Promise<void> {
		await axios.post('/ui/users/invite', body);
	}

	addTutorialCompleted(tutorialId: string): void {
		const tutorialsCompleted = getGlobal('tutorialsCompleted');
		if (tutorialsCompleted) {
			const tutorialsCompletedArr: string[] = JSON.parse(tutorialsCompleted);
			if (!tutorialsCompletedArr.includes(tutorialId)) {
				setGlobal('tutorialsCompleted', JSON.stringify([...tutorialsCompletedArr, tutorialId]));
			}
		} else {
			setGlobal('tutorialsCompleted', JSON.stringify([tutorialId]));
		}
	}

	isTutorialCompleted(tutorialId: string): boolean {
		const tutorialsCompleted = getGlobal('tutorialsCompleted');
		if (tutorialsCompleted) {
			const tutorialCompletedArr: string[] = JSON.parse(tutorialsCompleted);
			return tutorialCompletedArr.includes(tutorialId);
		}
		return false;
	}

	skipTutorial(): void {
		if (!this.isTutorialSkipped()) {
			setGlobal('tutorialsSkipped', 'true');
		}
	}

	isTutorialSkipped(): boolean {
		const tutorialsSkipped = getGlobal('tutorialsSkipped') ?? 'false';
		return JSON.parse(tutorialsSkipped);
	}
}
