/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Subscription, filter } from 'rxjs';
import { Component } from 'react';

import { LayoutedGroup, LayoutedTarget, SelectEntity, isSelectEntity } from './types';
import { emit, event$ } from '../ServiceLocator';
import { LandscapeGroup } from '../types';

interface EntitySelectionReEmitterProps {
	groups: LandscapeGroup[];
}

export default class EntitySelectionReEmitter extends Component<EntitySelectionReEmitterProps> {
	selectedEntity: LayoutedGroup | LayoutedTarget | null = null;
	subscription: Subscription;

	constructor(props: EntitySelectionReEmitterProps) {
		super(props);

		this.subscription = event$()
			.pipe(filter(isSelectEntity))
			.subscribe((event: SelectEntity) => {
				this.selectedEntity = event.entity;
			});
	}

	shouldComponentUpdate(nextProps: Readonly<EntitySelectionReEmitterProps>): boolean {
		return nextProps.groups !== this.props.groups;
	}

	componentDidUpdate(): void {
		if (this.selectedEntity) {
			emit({
				type: 'selectEntity',
				entity: null,
			});
		}
	}

	componentWillUnmount(): void {
		this.subscription.unsubscribe();
	}

	render(): null {
		return null;
	}
}
