/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { TeamSummaryVO } from 'ui-api';
import React from 'react';

// We always set a valid team to this context in Init.tsx. No other place, consuming this context
// will receive this initial null value.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TeamContext = React.createContext<TeamContextType>({ team: null as any });

export const useTeam = (): TeamSummaryVO => {
	return React.useContext(TeamContext).team;
};

type TeamContextType = {
	team: TeamSummaryVO;
};
