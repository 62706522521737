/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { Snackbar } from 'components';
import { ampli } from 'ampli';

import { ColorMapping, LandscapeConfig, LandscapeGroup } from '../types';
import { getAllLandscapeGroups, getTargets } from '../utils';

export default function useTargetDuplicationHint(groups: LandscapeGroup[], colorMapping: ColorMapping | null): void {
	const formik = useFormikContext<LandscapeConfig>();
	const { filterQuery, groupBy, colorBy, sizeBy, environmentId } = formik.values;

	const [groupKeyCausingDuplicates, mapContainsColorDuplicates] = useMemo(() => {
		const _groupKeyCausingDuplicates = new Set<string>();
		let _mapContainsColorDuplicates = false;
		const targets = getTargets(groups);
		for (let i = 0; i < targets.length; i++) {
			const target = targets[i];
			if (target.duplicatedByGroupings && target.duplicatedByGroupings.length > 1) {
				target.duplicatedByGroupings.forEach((grouping) => _groupKeyCausingDuplicates.add(grouping.label));
			}
			if (target.duplicatedByColor) {
				_mapContainsColorDuplicates = true;
			}
		}
		return [Array.from(_groupKeyCausingDuplicates), _mapContainsColorDuplicates];
	}, [groups, colorBy]);
	const mapContainsGroupDuplicates = groupKeyCausingDuplicates.length > 0;
	const mapContainsDuplicates = mapContainsGroupDuplicates || mapContainsColorDuplicates;

	useEffect(() => {
		if (mapContainsDuplicates) {
			Snackbar.darkForever(
				'Some targets have multiple values for the property you are grouping by and will be included in all relevant groups. Hovering above a target will highlight all occurrences of that target in the landscape.',
				{
					toastId: 'explore-contains-duplicates',
				},
			);
		} else {
			Snackbar.dismiss('explore-contains-duplicates');
		}
	}, [mapContainsDuplicates]);
	useEffect(() => {
		return () => {
			Snackbar.dismiss('explore-contains-duplicates');
		};
	}, []);

	useEffect(() => {
		if (colorBy && mapContainsColorDuplicates && colorMapping) {
			ampli.landscapeExplorerColorBySetAttribute({
				url: window.location.href,
				environment_id: environmentId,
				landscape_explorer_colored_by: colorBy,
				landscape_explorer_filter_query: filterQuery,
				landscape_explorer_grouped_by: groupBy,
				landscape_explorer_set_attribute: colorBy,
				landscape_explorer_set_attribute_values_length: '' + colorMapping.colorList.length,
				landscape_explorer_sized_by: sizeBy,
			});
		}
	}, [environmentId, colorBy, mapContainsColorDuplicates]);

	useEffect(() => {
		if (groupKeyCausingDuplicates.length === 0) {
			return;
		}

		const flattenedGroups = getAllLandscapeGroups(groups);
		for (let i = 0; i < groupBy.length; i++) {
			const groupKey = groupBy[i];
			if (!groupKeyCausingDuplicates.includes(groupKey)) {
				continue;
			}
			const c = countGroupsAtDepth(flattenedGroups, i);
			ampli.landscapeExplorerGroupBySetAttribute({
				url: window.location.href,
				environment_id: environmentId,
				landscape_explorer_colored_by: colorBy,
				landscape_explorer_filter_query: filterQuery,
				landscape_explorer_grouped_by: groupBy,
				landscape_explorer_set_attribute: groupKey,
				landscape_explorer_set_attribute_values_length: c + '',
				landscape_explorer_sized_by: sizeBy,
			});
		}
	}, [environmentId, groupBy.join(', '), groupKeyCausingDuplicates.join(', ')]);
}

function countGroupsAtDepth(groups: LandscapeGroup[], depth: number): number {
	let count = 0;
	for (let i = 0; i < groups.length; i++) {
		const group = groups[i];
		if (group.depth === depth) {
			count++;
		}
	}
	return count;
}
