/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import SidebarPredicateModule from 'pages/settings/environments/config/SidebarPredicateModule';
import SidebarNameModule from 'pages/settings/environments/config/SidebarNameModule';
import { EnvironmentConfig } from 'pages/settings/environments/config/types';
import ResultView from 'pages/settings/environments/config/ResultView';
import { Button, Container, Snackbar, Stack, Text } from 'components';
import Sidebar from 'pages/settings/environments/config/Sidebar';
import { Formik, useField, useFormikContext } from 'formik';
import { userInput } from 'components/Dialog/UserInput';
import { IconSaveDisc } from 'components/icons';
import { Services } from 'services/services';
import { EnvironmentVO } from 'ui-api';
import { ReactElement } from 'react';

interface CreateNewEnvironmentInsideTeamProps {
	onCreated: (config: EnvironmentVO) => void;
}

export default function CreateNewEnvironmentInsideTeam({
	onCreated,
}: CreateNewEnvironmentInsideTeamProps): ReactElement {
	return (
		<Formik<EnvironmentConfig>
			initialValues={{
				name: '',
				predicate: {
					predicates: [],
					operator: 'AND',
				},
				teamIds: [],
			}}
			onSubmit={async (values) => {
				const savedConfig = await save(values);
				if (savedConfig) {
					onCreated(savedConfig);
				}
			}}
			validateOnBlur={false}
			validateOnChange={false}
		>
			<>
				<Header />
				<Container sx={{ display: 'flex', height: '100%', pb: 'large', gap: '24px' }}>
					<Sidebar size="small">
						<SidebarNameModule />
						<SidebarPredicateModule />
					</Sidebar>
					<ResultView />
				</Container>
			</>
		</Formik>
	);
}

function Header(): ReactElement {
	const { submitForm, isSubmitting } = useFormikContext();
	const [field] = useField<string>('name');

	return (
		<Stack direction="horizontal" alignItems="center" justifyContent="space-between" pb="medium">
			<Text variant="xLargeStrong">Create new Environment</Text>
			<Button onClick={submitForm} disabled={isSubmitting || !field.value} width="210px">
				<IconSaveDisc variant="small" mr="xSmall" /> {isSubmitting ? 'Saving...' : 'Create Environment'}
			</Button>
		</Stack>
	);
}

async function save(configToSave: EnvironmentConfig): Promise<EnvironmentVO | undefined> {
	if (!configToSave.name) {
		const inputResult = await userInput({
			title: 'Name your Environment',
			placeholder: 'Untitled Environment',
			buttonOkCaption: 'Create Environment',
		});
		if (inputResult.action === 'cancel') {
			return;
		}
		configToSave = { ...configToSave, name: inputResult.input || '' };
	}

	try {
		return await Services.environments.createEnvironment(configToSave);
	} catch (error) {
		console.error('error', error);
		Snackbar.error(error.title);
	}
}
