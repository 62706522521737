/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { DataStreamResult } from 'utils/hooks/stream/result';
import { usePromise } from 'utils/hooks/usePromise';
import { TargetTypeDescriptionVO } from 'ui-api';
import { Services } from 'services/services';

export function useTargetDefinitions(): DataStreamResult<TargetTypeDescriptionVO[]> {
	return usePromise(() => Services.targets.getTargetDefinitions(), []);
}
