/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react';
import { Toggle } from 'components';

interface BooleanProps {
	disabled: boolean;
	value: boolean;
	setValue: (value: boolean) => void;
}

export default function BooleanField({ value, disabled, setValue }: BooleanProps): ReactElement {
	return (
		<Toggle
			checked={value}
			onChange={() => {
				setValue(!value);
			}}
			disabled={disabled}
		/>
	);
}
