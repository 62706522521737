/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import { motion } from 'framer-motion';
import React from 'react';

import { TabContext } from './Tabs';

type TabPanelOwnProps = {
	value: string;
};

export type TabPanelProps = TabPanelOwnProps & Omit<ContainerProps, 'css' | keyof TabPanelOwnProps>;

const animate = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0 },
	transition: { duration: 0.3 },
};

export const TabPanel = React.forwardRef<HTMLDivElement, TabPanelProps>(({ value, children, ...props }, ref) => {
	const context = React.useContext(TabContext);
	const selected = context.value === value;

	if (!selected) {
		return null;
	}

	return (
		<motion.div {...animate} style={{ willChange: 'opacity, transform' }}>
			<Container ref={ref} {...props}>
				{children}
			</Container>
		</motion.div>
	);
});
TabPanel.displayName = 'TabPanel';
