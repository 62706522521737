/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { BehaviorSubject, distinctUntilChanged, from, interval, share, startWith, switchMap } from 'rxjs';
import { DateReviver, withReviver } from 'services/common';
import { useObservable } from 'utils/hooks/useObservable';
import { SystemStatusVO } from 'ui-api';
import { isEqual } from 'lodash';
import axios from 'axios';

const systemStatus$ = interval(1000 * 60)
	.pipe(startWith(-1))
	.pipe(switchMap(() => from(fetchSystemStatus())))
	.pipe(distinctUntilChanged(isEqual))
	.pipe(
		share({
			connector: () => new BehaviorSubject({ experimentExecutionTriggerBehavior: 'EXECUTE' } as SystemStatusVO),
		}),
	);

export function useSystemStatus(): SystemStatusVO {
	const result = useObservable(() => systemStatus$, []);

	return (
		result.value ?? {
			experimentExecutionTriggerBehavior: 'EXECUTE',
		}
	);
}

async function fetchSystemStatus(): Promise<SystemStatusVO> {
	return (
		await axios.get<SystemStatusVO>('/ui/system-status', {
			transformResponse: withReviver(DateReviver(['start', 'end'])),
		})
	).data;
}
