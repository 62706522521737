/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, Container, Text } from 'components';
import { Flex } from '@steadybit/ui-components-lib';
import { IconExplore } from 'components/icons';
import { ReactElement } from 'react';

interface ExploreTooManyTargetsSoftcapViewProps {
	numberOfTargets: number;
	onLoadClick: () => void;
}

export default function ExploreTooManyTargetsSoftcapView({
	numberOfTargets,
	onLoadClick,
}: ExploreTooManyTargetsSoftcapViewProps): ReactElement {
	return (
		<Container
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				gap: '16px',
				height: '100%',
				width: '100%',
				bg: 'neutral700',
				color: 'purple300',
			}}
		>
			<IconExplore variant="xxxLarge" color="purple300" />
			<Text variant="xLargeStrong">Targets loading temporarily paused</Text>

			<Flex spacing="none" align="center">
				<Text variant="medium" color="neutral000">
					Your current scope includes {numberOfTargets} targets.
				</Text>
				<Text
					variant="medium"
					color="neutral000"
					maxWidth={550}
					sx={{
						textAlign: 'center',
					}}
				>
					You could filter your view or select an environment with fewer targets to avoid long loading times and ease{' '}
					deriving insights.
				</Text>
			</Flex>
			<Button variant="secondarySmall" onClick={onLoadClick}>
				Load targets anyway
			</Button>
		</Container>
	);
}
