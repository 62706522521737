/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Pill,
	RouterLink,
	RouterPagination,
	Stack,
	StateBadge,
	Table,
	TableBody,
	TableDataCell,
	TableErrorRow,
	TableHead,
	TableHeadCell,
	TableRow,
	TableSortLink,
	Text,
} from 'components';
import {
	EnvironmentSummaryVO,
	ExperimentExecutionSummaryVO,
	GetExperimentExecutionsPageResponse,
	TeamVO,
} from 'ui-api';
import { IconEnvironment, IconEnvironmentGlobal, IconRuns } from 'components/icons';
import AstroidScreen from 'components/List/AstroidScreen/AstroidScreen';
import ListHeaderTitle from 'components/List/presets/ListHeaderTitle';
import TeamIcon from 'pages/settings/teams/components/TeamIcon';
import TableLoadingRow from 'components/Table/TableLoadingRow';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import { DataStreamResult } from 'utils/hooks/stream/result';
import ListHeader from 'components/List/presets/ListHeader';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import ViewWrapper from 'pages/components/ViewWrapper';
import { usePromise } from 'utils/hooks/usePromise';
import { formatDateWithTime } from 'utils/dateFns';
import { PageParams } from 'utils/hooks/usePage';
import { ReactElement, ReactNode } from 'react';
import { useUrlState } from 'url/useUrlState';
import { Services } from 'services/services';
import { useUser } from 'services/usersApi';

import { directionParam, pageParam, sortParam, UrlState } from './urlParams';
import FilterBar from './FilterBar';

interface ExperimentRunsProps {
	experimentExecutionsResult: DataStreamResult<GetExperimentExecutionsPageResponse>;
}

export default function ExperimentRuns({ experimentExecutionsResult }: ExperimentRunsProps): ReactElement {
	const [{ page }, getWithUrlState] = useUrlState<UrlState>([pageParam]);

	const teamsResult = usePromise(() => Services.teams.fetchTeams(new PageParams(0, 1_000)), []);
	const teams = teamsResult.value?.content || [];

	const environments = useEnvironments();

	const experimentExecutions = experimentExecutionsResult.value;
	const isEmptyResult =
		experimentExecutions && !experimentExecutionsResult.loading && experimentExecutions.content.length === 0;

	return (
		<ViewWrapper>
			<Stack px="xxLarge" py="small" size="medium">
				<ListHeader left={<ListHeaderTitle title="Runs" Icon={IconRuns} />} />

				<FilterBar
					teams={teams}
					environments={environments.environments}
					loading={!teamsResult.value || environments.environments.length === 0}
				/>

				<ExperimentTable>
					{experimentExecutionsResult.error && <TableErrorRow error={experimentExecutionsResult.error.message} />}

					{experimentExecutionsResult.loading && !isEmptyResult ? (
						<>
							<TableLoadingRow numColumns={4} />
							<TableLoadingRow numColumns={4} />
							<TableLoadingRow numColumns={4} />
							<TableLoadingRow numColumns={4} />
							<TableLoadingRow numColumns={4} />
							<TableLoadingRow numColumns={4} />
						</>
					) : (
						experimentExecutions &&
						experimentExecutions.content.map((execution) => {
							return (
								<ExperimentRunRow
									key={execution.id}
									execution={execution}
									environments={environments.environments}
									teams={teams}
								/>
							);
						})
					)}
				</ExperimentTable>
				<RouterPagination
					activePage={page}
					totalPages={experimentExecutions?.totalPages}
					to={(i) => getWithUrlState({ page: i })}
				/>

				{isEmptyResult && (
					<AstroidScreen
						title={
							<Text variant="xLargeStrong" color="slate">
								There are no experiment runs matching the filter criteria.
							</Text>
						}
						icon={<IconRuns variant="xxLarge" color="slate" />}
						description={
							<Text
								variant="smallMedium"
								color="neutral600"
								sx={{
									textAlign: 'center',
									maxWidth: '470px',
								}}
							>
								There aren’t any results for your current filter configuration. Please try adjusting your search
								criteria for better results.
							</Text>
						}
					/>
				)}
			</Stack>
		</ViewWrapper>
	);
}

function ExperimentTable({ children }: { children: ReactNode }): ReactElement {
	const [{ direction }, getWithUrlState] = useUrlState<UrlState>([sortParam, directionParam]);

	return (
		<Table data-cy="experiment-runs-table" width="100%">
			<TableHead>
				<TableRow>
					<TableHeadCell>Run ID</TableHeadCell>
					<TableHeadCell width="180px">
						<TableSortLink
							sort={direction === 'DESC' ? 'asc' : 'desc'}
							to={getWithUrlState({ sort: 'created', direction: direction === 'DESC' ? 'ASC' : 'DESC' })}
						>
							Run Start / End
						</TableSortLink>
					</TableHeadCell>
					<TableHeadCell>Environment</TableHeadCell>
					<TableHeadCell>State</TableHeadCell>
				</TableRow>
			</TableHead>
			<TableBody>{children}</TableBody>
		</Table>
	);
}

const ExperimentRunRow = ({
	environments,
	execution,
	teams,
}: {
	execution: ExperimentExecutionSummaryVO;
	environments: EnvironmentSummaryVO[];
	teams: TeamVO[];
}): ReactElement => {
	const environment = environments.find((env) => env.id === execution.environmentId);
	const Icon = environment?.global ? IconEnvironmentGlobal : IconEnvironment;
	const team = teams.find((team) => team.key === execution.teamKey);
	const user = useUser();
	const userIsTeamMember = !!team?.members.find((member) => member.username === user.username);

	return (
		<TableRow key={execution.id} hoverable={true}>
			<TableDataCell>
				<Stack direction="horizontal" size="xSmall" alignItems="center" py="xSmall">
					{team && (
						<TeamIcon
							tooltip={
								<>
									{team.name} ({team.key})
									{!userIsTeamMember && (
										<>
											<br />
											You aren&apos;t a member of this team, thus it is view only
										</>
									)}
								</>
							}
							userIsMember={userIsTeamMember}
							color={team.logoColor}
							logoId={team.logoId}
						/>
					)}
					<Stack size="xxxSmall">
						<Text variant="medium">#{execution.id}</Text>
						<RouterLink
							variant="secondary"
							to={`/experiments/edit/${execution.experimentKey}/executions/${execution.id}`}
						>
							<Stack direction="horizontal" size="xSmall">
								<Text variant="mediumStrong" as="span" sx={{ ...textEllipsis }}>
									{execution.experimentKey} {execution.name}
								</Text>
							</Stack>
						</RouterLink>
					</Stack>
				</Stack>
			</TableDataCell>
			<TableDataCell>
				<Stack size="xxSmall">
					<Text variant="small" sx={{ color: 'neutral600', fontVariantNumeric: 'tabular-nums', whiteSpace: 'nowrap' }}>
						{`${formatDateWithTime(execution.created)} ${execution.ended ? '-' : ''}`}
					</Text>
					{execution.ended && (
						<Text variant="small" sx={{ color: 'neutral600', fontVariantNumeric: 'tabular-nums' }}>
							{formatDateWithTime(execution.ended)}
						</Text>
					)}
				</Stack>
			</TableDataCell>
			<TableDataCell>
				{environment && (
					<Pill backgroundColor="neutral200" color="neutral700" sx={{ borderRadius: '20px' }}>
						<Icon variant="small" color="neutral700" mr="xSmall" ml="xxxSmall" />
						<Text variant="small" sx={{ ...textEllipsis }}>
							{environment.name}
						</Text>
					</Pill>
				)}
			</TableDataCell>
			<TableDataCell>
				<Stack size="none">
					<StateBadge as="state" value={execution.state} />
					<Stack direction="horizontal" alignItems="center" size="xxSmall">
						<Text variant="small" as="span" color="neutral600">
							by
						</Text>
						<Text
							variant="smallStrong"
							sx={{ color: 'neutral600', overflow: 'hidden', textOverflow: 'ellipsis' }}
							noWrap
							data-private
						>
							{execution.createdBy.name}
						</Text>
					</Stack>
				</Stack>
			</TableDataCell>
		</TableRow>
	);
};
