/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import { IconArrowDropDown, IconArrowDropUp, IconComponent } from 'components/icons';
import DropDown from 'components/Select/Dropdown/Dropdown';
import { Container, Stack, Text } from 'components';
import React, { RefObject } from 'react';

import { ContextualMenuButton } from '../ContextualMenu';
import { Button, ButtonProps } from '../Button';

export interface ButtonSplitAction {
	Icon?: IconComponent;
	label: string;
	value: string;
	disabled?: boolean;
	active?: boolean;
}

export interface ButtonSplitProps extends ButtonProps {
	muted?: boolean;
	actions: ButtonSplitAction[];
	onAction: (action: ButtonSplitAction) => void;
}

const ButtonSplitDropdownTrigger: React.FC<ButtonProps> = React.forwardRef(
	({ children, variant, sx = {}, ...props }, ref) => (
		<Button
			ref={ref}
			tx="buttonSplitRight"
			variant={variant}
			sx={{
				px: 0,
				borderRadius: '0 4px 4px 0',
				...sx,
			}}
			{...props}
		>
			{children}
		</Button>
	),
);
ButtonSplitDropdownTrigger.displayName = 'ButtonSplitDropdownTrigger';

export const ButtonSplit = React.forwardRef<HTMLButtonElement, ButtonSplitProps>(
	({ children, variant = 'primary', actions, onAction, sx = {}, ...props }, ref) => (
		<Container sx={{ display: 'inline-flex', flexDirection: 'row', flex: '0 0 auto' }}>
			<Button ref={ref} tx="buttonSplitLeft" variant={variant} sx={{ borderRadius: '4px 0 0 4px', ...sx }} {...props}>
				{children}
			</Button>

			{actions.length > 0 && (
				<DropDown
					placement="bottom-end"
					renderComponent={({ ref, showMenu, setShowMenu }) => (
						<ButtonSplitDropdownTrigger
							disabled={props.disabled}
							variant={variant}
							onClick={() => setShowMenu(!showMenu)}
							ref={ref as RefObject<HTMLButtonElement>}
							sx={sx}
						>
							{showMenu ? <IconArrowDropUp variant="small" /> : <IconArrowDropDown variant="small" />}
						</ButtonSplitDropdownTrigger>
					)}
				>
					{({ selectItem }) => (
						<DropdownContentFrame>
							<Stack size="none" p="xxSmall">
								{actions.map((action) => (
									<ContextualMenuButton
										key={action.value}
										onClick={() => {
											onAction(action);
											selectItem(action);
										}}
										disabled={action.disabled}
										sx={{
											bg: action.active ? 'purple100' : 'neutral000',
											color: action.active ? 'slate' : 'neutral800',
										}}
									>
										{action.Icon && <action.Icon variant="medium" minWidth={24} minHeight={24} />}
										<Text variant="smallStrong" ml="xxSmall">
											{action.label}
										</Text>
									</ContextualMenuButton>
								))}
							</Stack>
						</DropdownContentFrame>
					)}
				</DropDown>
			)}
		</Container>
	),
);
ButtonSplit.displayName = 'ButtonSplit';
