/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ExperimentExecutionVO, ExperimentStepActionVO } from 'ui-api';
import { useEffect } from 'react';

import { isCanceled, isCompleted, isErrored, isFailed, isRunning } from '../components/utils';
import { ampli } from '../../../ampli';

export default function useTrackingExperimentExecutionViewed(
	execution: ExperimentExecutionVO | undefined,
	executionView: 'attack_monitor' | 'agent_log' | 'tracing',
): void {
	useEffect(() => {
		if (execution) {
			const experimentSteps = execution.lanes.flatMap((lane) => lane.steps);
			const experimentStepIds = [
				...new Set(
					experimentSteps.map((step) =>
						step.type === 'action' ? (step as ExperimentStepActionVO).actionId : step.type,
					),
				),
			];
			const experimentExecutionResult = isFailed(execution.state)
				? 'failed'
				: isCanceled(execution.state)
					? 'aborted'
					: isCompleted(execution.state)
						? 'succeeded'
						: isErrored(execution.state)
							? 'errored'
							: undefined;
			ampli.experimentExecutionViewed({
				experiment_key: execution.experimentKey,
				experiment_execution_id: execution.id,
				environment_id: execution.environmentId,
				experiment_steps: experimentStepIds,
				experiment_number_of_steps: experimentSteps.length,
				experiment_execution_result: experimentExecutionResult,
				experiment_execution_running: isRunning(execution.state),
				experiment_execution_view: executionView,
			});
		}
	}, [execution?.experimentKey, execution?.id, executionView]);
}
