/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useApplicationHeaderHeight } from 'App/ApplicationHeaderHeight';
import { ReactElement, useState } from 'react';
import { useUrlState } from 'url/useUrlState';
import { theme } from 'styles.v2/theme';

import { UrlState, selectedStepIdParam } from '../urlParams';
import { WorkspaceSettings } from '../useWorkspaceSettings';
import { Mode } from '../useEditorSettings';
import ZoomControls from './ZoomControls';
import Timeline from './Timeline';
import Lanes from './Lanes';
import './Workspace.css';

interface WorkspaceProps {
	mode: Mode;
}

export default function Workspace({ mode }: WorkspaceProps): ReactElement {
	const [, , updateUrlState] = useUrlState<UrlState>([selectedStepIdParam]);
	const [pxPerSecond, setPxPerSecond] = useState(10);
	const [workspaceWidth, setWorkspaceWidth] = useState(0);

	const applicationHeaderHeight = useApplicationHeaderHeight();
	// experiment header and subheader
	const height =
		mode === 'experiment'
			? `calc(100vh - ${applicationHeaderHeight + 48 + 80}px)`
			: `calc(100vh - ${applicationHeaderHeight + 65 + 56 + 60}px)`;

	return (
		<div
			id="experiment.explorer.wrapper"
			className="experimentexplorerwrapper"
			style={{
				position: 'relative',

				display: 'flex',
				alignItems: 'center',

				height,
				width: '100%',

				backgroundColor: theme.colors.neutral200,
				overflowX: 'scroll',
			}}
			onClick={() => {
				updateUrlState({
					selectedStepId: undefined,
				});
			}}
		>
			<WorkspaceSettings.Provider value={{ workspaceWidth, pxPerSecond }}>
				<Timeline />
				<Lanes setWorkspaceWidth={setWorkspaceWidth} />
				<ZoomControls pxPerSecond={pxPerSecond} setPxPerSecond={setPxPerSecond} />
			</WorkspaceSettings.Provider>
		</div>
	);
}
