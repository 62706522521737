/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { BufferGeometry, Float32BufferAttribute, Mesh, ShaderMaterial } from 'three';

import { addQuadUVs, addQuadVertices } from '../attributeUtils';
import fragmentShader from '../shader/iconsFragmentShader';
import vertexShader from '../shader/iconsVertexShader';
import ExploreTextures from '../ExploreTextures';

interface TargetTypeObject {
	uvs: number[];
	sizes: number[];
	positions: number[];
	toPositions: number[];
	material: ShaderMaterial;
	geometry: BufferGeometry;
	mesh: Mesh<BufferGeometry, ShaderMaterial>;
}

export default class IconsController {
	objects: Map<string, TargetTypeObject> = new Map();
	textures: ExploreTextures;
	lastSetWorldUnitInScreenSpace = 0;
	layer: number;
	color: [number, number, number];

	constructor(textures: ExploreTextures, layer: number, color: [number, number, number]) {
		this.textures = textures;
		this.layer = layer;
		this.color = color;
	}

	reset(): void {
		this.objects.forEach((object) => {
			object.geometry.dispose();
			object.material.dispose();
		});
		this.objects.clear();
		this.lastSetWorldUnitInScreenSpace = 0;
	}

	add(targetType: string, currentX: number, currentY: number, x: number, y: number, r: number): void {
		if (!this.objects.has(targetType)) {
			const material = new ShaderMaterial({
				uniforms: {
					progress: { value: 0.0 },
					textureMap: { value: this.textures.get(targetType) },
					worldUnitInScreenSpace: { value: 0.0 },
					color: { value: this.color },
				},
				vertexShader,
				fragmentShader,
				transparent: true,
				depthTest: true,
				depthWrite: true,
			});
			const geometry = new BufferGeometry();
			const mesh = new Mesh(geometry, material);
			mesh.frustumCulled = false;
			mesh.renderOrder = this.layer;
			mesh.name = targetType;
			this.objects.set(targetType, {
				positions: [],
				toPositions: [],
				uvs: [],
				sizes: [],
				material,
				geometry,
				mesh,
			});
		}

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const { positions, toPositions, uvs, sizes } = this.objects.get(targetType);
		addQuadVertices(positions, currentX, currentY, this.layer, r * 0.5);
		addQuadVertices(toPositions, x, y, this.layer, r * 0.5);

		addQuadUVs(uvs);
		sizes.push(r, r, r, r, r, r);
	}

	flush(): void {
		this.objects.forEach((object) => {
			const { positions, toPositions, uvs, sizes, geometry } = object;
			geometry.setAttribute('position', new Float32BufferAttribute(positions, 3));
			geometry.setAttribute('toPosition', new Float32BufferAttribute(toPositions, 3));
			geometry.setAttribute('uv', new Float32BufferAttribute(uvs, 2));
			geometry.setAttribute('targetSize', new Float32BufferAttribute(sizes, 1));
		});
	}

	getMeshes(): Mesh<BufferGeometry, ShaderMaterial>[] {
		return Array.from(this.objects.values()).map((object) => object.mesh);
	}

	getMaterials(): ShaderMaterial[] {
		return Array.from(this.objects.values()).map((object) => object.material);
	}

	updateScreenSpaceSize(worldUnitInScreenSpace: number): void {
		if (this.lastSetWorldUnitInScreenSpace === worldUnitInScreenSpace) {
			return;
		}
		this.lastSetWorldUnitInScreenSpace = worldUnitInScreenSpace;
		this.objects.forEach((object) => {
			object.material.uniforms.worldUnitInScreenSpace.value = worldUnitInScreenSpace;
		});
	}

	dispose(): void {
		this.objects.forEach((object) => {
			object.geometry.dispose();
			object.material.dispose();
		});
		this.objects.clear();
	}
}
