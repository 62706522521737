/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Svg, Container, ContainerProps, TextProps } from 'components';
import React, { useMemo } from 'react';

export type CheckboxChecked = boolean | 'Indeterminate';

export interface CheckContainerProps extends Omit<TextProps, 'css' | 'checked'> {
	checked?: CheckboxChecked;
	hasError?: boolean;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	inputProps?: Partial<ContainerProps>;
	disabled?: boolean;
}

export const Checkbox: React.FC<CheckContainerProps> = ({
	checked,
	hasError = false,
	disabled = false,
	color = 'slate',
	onChange,
	inputProps,
	name,
	id,
	...props
}) => {
	const isIndeterminate = checked === 'Indeterminate';
	const borderColor = useMemo(() => {
		if (hasError) {
			return 'coral';
		}
		if (disabled) {
			return 'neutral400';
		}
		if (isIndeterminate || checked) {
			return color;
		}

		return 'neutral400';
	}, [hasError, checked, disabled, isIndeterminate, color]);
	const bg = useMemo(() => {
		if (isIndeterminate || checked) {
			if (disabled) {
				return 'neutral400';
			}

			return color;
		}

		return 'neutral000';
	}, [isIndeterminate, checked, disabled, color]);

	return (
		<Container
			sx={{
				display: 'inline-block',
				position: 'relative',
				borderRadius: 4,
				border: '2px solid',
				borderColor,
				bg,
				':hover': {
					borderColor: color,
				},
				':focus-within': {
					borderColor: 'neutral800',
				},
			}}
			width={18}
			height={18}
			{...props}
		>
			<Svg
				sx={{
					position: 'absolute',
					left: '50%',
					top: '50%',
					transform: `translate(-50%, -50%) scale(${checked === false ? 0 : 1})`,
					transition: '150ms transform',
					transitionDelay: checked === false ? '0ms' : '150ms',
					color: 'neutral000',
				}}
				width={isIndeterminate ? 10 : 12}
				height={isIndeterminate ? 2 : 9}
				viewBox={isIndeterminate ? '0 0 10 2' : '0 0 12 9'}
				xmlns="http://www.w3.org/2000/svg"
			>
				{isIndeterminate ? (
					<rect width="10" height="2" rx="1" fill="currentColor" />
				) : (
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						fill="currentColor"
						d="M11.1435 2.0371L4.64447 8.16476L0.599976 4.35139L2.1435 2.71431L4.64447 5.07236L9.59998 0.400024L11.1435 2.0371Z"
					/>
				)}
			</Svg>

			<Container
				as="input"
				type="checkbox"
				width="100%"
				height="100%"
				onChange={onChange}
				name={name}
				id={id}
				disabled={disabled}
				sx={{
					cursor: 'pointer',
					opacity: 0,
					display: 'block',
					position: 'absolute',
					left: 0,
					right: 0,
					bottom: 0,
					top: 0,
					pointerEvents: disabled ? 'none' : 'initial',
				}}
				checked={!!checked}
				{...inputProps}
			/>
		</Container>
	);
};
