/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	ButtonIcon,
	Container,
	RouterButton,
	RouterPagination,
	ShortenedText,
	Snackbar,
	Spinner,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	TableSortLink,
	Text,
	userConfirm,
} from 'components';
import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import ListHeaderSearch from 'components/List/presets/ListHeaderSearch';
import { Order, PageLocation, usePage } from 'utils/hooks/usePage';
import { WebhookScope, WebhookTypes } from 'services/webhooksApi';
import { IconAdd, IconDelete, IconEdit } from 'components/icons';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { TeamSummaryVO, WebhookSummaryVO } from 'ui-api';
import { TeamSelect } from 'hocs/TeamSelect';
import { Services } from 'services/services';
import { useDebounce } from 'react-use';
import { useHistory } from 'url/hooks';
import { debounce } from 'lodash';

import invokePromise from '../../../../utils/ignorePromise';
import { ampli } from '../../../../ampli';

interface WebhooksListProps {
	type?: string;
	pathSegment: string;
	onEdit: (id: string) => string;
	onCreate: () => string;
	labelCreateWebhook?: string;
	labelNoData?: string;
}

const WebhookList: React.VFC<WebhooksListProps> = ({
	type,
	pathSegment,
	onCreate,
	onEdit,
	labelCreateWebhook,
	labelNoData,
}) => {
	const page = usePage(pathSegment, { size: 15, sort: [['name', 'asc', 'ignoreCase']] });
	const [webhooks, fetch] = useAsyncState(
		() =>
			Services.webhooks.findWebhooks(type ? page.withCriterion('type', type).criteria : page.criteria, page.pageParams),
		[type, page],
	);
	const [teams] = useAsyncState(
		async () =>
			webhooks.value ? Services.teams.findTeamsById(webhooks.value.content.map((w) => w.teamId || '')) : new Map(),
		[webhooks.value?.content],
		new Map(),
	);
	const debouncedFetch = useMemo(() => debounce(fetch, 100), [fetch]);
	useEventEffect(
		useCallback(
			(event) => {
				if (
					'webhook.created' === event.type ||
					webhooks.value?.content.some((webhook) => webhook.id === event.webhookId)
				) {
					debouncedFetch();
				}
			},
			[debouncedFetch, webhooks],
		),
		[],
		debouncedFetch.cancel,
		[webhooks],
	);

	useEffect(() => {
		if (type) {
			ampli.webhookListViewed({
				webhook_type: mapTypeToTracking(type),
				url: window.location.href,
			});
		}
	}, [type]);

	return (
		<Stack minHeight={'100%'} size={'large'} m={'xLarge'}>
			<WebhookFilters
				page={page}
				allowCreate={webhooks.value?._actions.includes('create')}
				onCreate={onCreate}
				labelCreateWebhook={labelCreateWebhook}
				type={type}
			/>

			{webhooks.error && <Text>Error loading Webhooks: {webhooks.error.message}</Text>}
			{webhooks.loading && !webhooks.value ? <Spinner variant="large" color={'neutral500'} mr={'auto'} /> : null}
			{webhooks.value ? (
				<>
					<WebhookTable page={page}>
						{webhooks.value.content.map((webhook) => (
							<WebhookRow key={webhook.id} value={webhook} onClick={onEdit} team={teams.value.get(webhook.teamId)} />
						))}
						{!webhooks.value.content.length && (
							<TableRow>
								<TableDataCell colSpan={6}>
									<Text muted>{labelNoData ? labelNoData : 'No Webhooks found.'}</Text>
								</TableDataCell>
							</TableRow>
						)}
					</WebhookTable>
				</>
			) : null}
			<RouterPagination
				activePage={page.pageParams.page}
				totalPages={webhooks.value?.totalPages}
				to={(i) => page.withPage(i).toString()}
			/>
		</Stack>
	);
};

const WebhookFilters: React.VFC<{
	page: PageLocation;
	allowCreate?: boolean | false;
	onCreate: () => string;
	labelCreateWebhook?: string;
	type: string | undefined;
}> = ({ page, allowCreate, onCreate, labelCreateWebhook, type }) => {
	const history = useHistory();
	const [name, setName] = React.useState<string | null>(page.criteria.get('name'));
	useDebounce(
		() => {
			trackWebhookFilter(name, page.criteria.get('teamId'), type);
			history.replace(page.withCriterion('name', name).toString());
		},
		350,
		[name],
	);

	return (
		<Stack direction="horizontal" size="medium" justifyContent="flex-start">
			<ListHeaderSearch
				title="Search webhook"
				value={name ?? ''}
				setValue={(v) => {
					setName(v);
					// reset pagination information when filtering
					history.replace(page.withPage(0).toString());
				}}
			/>
			<TeamSelect
				value={page.criteria.get('teamId')}
				name={'team'}
				onChange={(teamId) => {
					trackWebhookFilter(name, page.criteria.get('teamId'), type);
					history.replace(page.withCriterion('teamId', teamId).toString());
				}}
			/>
			<Container flex="1 1 300px" sx={{ textAlign: 'right' }}>
				{allowCreate ? (
					<RouterButton color={'primaryLarge'} to={onCreate()}>
						<IconAdd mr="xSmall" ml="-xxSmall" />
						<Text variant="mediumStrong" sx={{ whiteSpace: 'nowrap' }}>
							{labelCreateWebhook ? labelCreateWebhook : 'Add Webhook'}
						</Text>
					</RouterButton>
				) : null}
			</Container>
		</Stack>
	);
};

const trackWebhookFilter = (name: string | null, teamId: string | null, type: string | undefined): void => {
	ampli.webhookListFiltered({
		filter_text: name || undefined,
		filter_team: teamId || undefined,
		webhook_type: mapTypeToTracking(type),
	});
};

const mapTypeToTracking = (type: string | undefined): 'slack' | 'custom' | 'preflight' | undefined => {
	if (type === 'slack') {
		return type;
	} else if (type === 'webhookPreflight') {
		return 'preflight';
	} else if (type === 'webhook') {
		return 'custom';
	}
	return undefined;
};

const SORT_TYPE_ASC: Order[] = [['type', 'asc']];
const SORT_TYPE_DESC: Order[] = [['type', 'desc']];
const SORT_SCOPE_ASC: Order[] = [['scope', 'asc']];
const SORT_SCOPE_DESC: Order[] = [['scope', 'desc']];
const SORT_URL_ASC: Order[] = [['url', 'asc']];
const SORT_URL_DESC: Order[] = [['url', 'desc']];
const SORT_NAME_ASC: Order[] = [['name', 'asc', 'ignoreCase']];
const SORT_NAME_DESC: Order[] = [['name', 'desc', 'ignoreCase']];

const WebhookTable: React.FC<{ page: PageLocation; children: ReactNode }> = ({ page, children }) => {
	return (
		<Table width={'100%'}>
			<TableHead>
				<TableRow>
					<TableHeadCell>
						<TableSortLink
							sort={page.getDirection(SORT_NAME_ASC, SORT_NAME_DESC)}
							to={page.toggleSort(SORT_NAME_ASC, SORT_NAME_DESC).toString()}
							onClick={() => {
								ampli.webhookListSorted({ sorted_by: 'Name' });
							}}
						>
							Name
						</TableSortLink>
					</TableHeadCell>
					<TableHeadCell>
						<TableSortLink
							sort={page.getDirection(SORT_TYPE_ASC, SORT_TYPE_DESC)}
							to={page.toggleSort(SORT_TYPE_ASC, SORT_TYPE_DESC).toString()}
							onClick={() => {
								ampli.webhookListSorted({ sorted_by: 'Type' });
							}}
						>
							Type
						</TableSortLink>
					</TableHeadCell>
					<TableHeadCell>
						<TableSortLink
							sort={page.getDirection(SORT_SCOPE_ASC, SORT_SCOPE_DESC)}
							to={page.toggleSort(SORT_SCOPE_ASC, SORT_SCOPE_DESC).toString()}
							onClick={() => {
								ampli.webhookListSorted({ sorted_by: 'Scope' });
							}}
						>
							Scope
						</TableSortLink>
					</TableHeadCell>
					<TableHeadCell>
						<TableSortLink
							sort={page.getDirection(SORT_URL_ASC, SORT_URL_DESC)}
							to={page.toggleSort(SORT_URL_ASC, SORT_URL_DESC).toString()}
							onClick={() => {
								ampli.webhookListSorted({ sorted_by: 'URL' });
							}}
						>
							URL
						</TableSortLink>
					</TableHeadCell>
					<TableHeadCell>Team</TableHeadCell>
					<TableHeadCell />
				</TableRow>
			</TableHead>
			<TableBody>{children}</TableBody>
		</Table>
	);
};

interface WebhookRowProps {
	value: WebhookSummaryVO;
	team?: TeamSummaryVO;
	onClick: (id: string) => string;
}

const handleDeleteWebhookClick = (webhookVO: WebhookSummaryVO) => () => {
	invokePromise(async () => {
		if (
			(await userConfirm({
				title: 'Delete Webhook',
				message: `Do you really want to delete ${webhookVO.name}?`,
				actions: [
					{ value: 'cancel', label: 'Cancel' },
					{ value: 'confirm', label: `Delete ${webhookVO.name}`, variant: 'primary' },
				],
			})) === 'confirm'
		) {
			try {
				await Services.webhooks.deleteWebhook(webhookVO.id);
				Snackbar.dark(`'${webhookVO.name}'  deleted.`, { toastId: 'webhook-deleted' });
			} catch (err) {
				Snackbar.error(`'${webhookVO.name}' not deleted: ${err.toString()}`, {
					toastId: 'webhook-deleted',
				});
			}
		}
	});
};

const WebhookRow: React.VFC<WebhookRowProps> = ({ value, team, onClick }) => {
	const { push, createHref } = useHistory();
	const webhookType = WebhookTypes.find((w) => w.type === value.type);
	const webhookScope = WebhookScope[value.scope];
	return (
		<TableRow hoverable>
			<TableDataCell width={'10%'} sx={{ whiteSpace: 'nowrap' }}>
				{value.name}
			</TableDataCell>
			<TableDataCell width={'10%'} sx={{ whiteSpace: 'nowrap' }}>
				{webhookType ? webhookType.label : value.type}
			</TableDataCell>
			<TableDataCell width={'10%'} sx={{ whiteSpace: 'nowrap' }}>
				{webhookScope?.label}
			</TableDataCell>
			<TableDataCell width={'100%'} maxWidth={1}>
				<ShortenedText variant={'small'}>{value.url}</ShortenedText>
			</TableDataCell>
			<TableDataCell width={'10%'} sx={{ whiteSpace: 'nowrap' }}>
				{team ? `${team.key} ${team.name}` : value.teamId || '-'}
			</TableDataCell>
			<TableDataCell width={'100'}>
				{value._actions.includes('edit') ? (
					<ButtonIcon
						tooltip={'Edit Webhook'}
						onClick={() => {
							push(
								createHref((location) => {
									location.pathname = onClick(value.id);
								}),
							);
						}}
					>
						<IconEdit />
					</ButtonIcon>
				) : null}
				{value._actions.includes('delete') ? (
					<ButtonIcon onClick={handleDeleteWebhookClick(value)} tooltip={'Delete Webhook'}>
						<IconDelete />
					</ButtonIcon>
				) : null}
			</TableDataCell>
		</TableRow>
	);
};

export default WebhookList;
