/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { DataStreamResult, loadingResult } from 'utils/hooks/stream/result';
import { Observable, interval, ReplaySubject, from, defer } from 'rxjs';
import { StateOverTimeMetricVO, StateOverTimeWidgetVO } from 'ui-api';
import { concatWith, exhaustMap, takeUntil } from 'rxjs/operators';
import { ReactElement, useEffect, useState } from 'react';
import { useObservable } from 'utils/hooks/useObservable';
import { Services } from 'services/services';

import StateOverTimeCardPresenter from './StateOverTimeCardPresenter';
import { WidgetProps } from '../types';

export const StateOverTimeCardType = 'com.steadybit.widget.state_over_time';

export default function StateOverTimeCard({
	experimentExecution,
	widget,
	position,
	start,
	duration,
}: WidgetProps): ReactElement {
	const ended = !!experimentExecution.ended;
	let metrics = useStateOverTimeMetrics(experimentExecution.id, ended, widget as StateOverTimeWidgetVO);

	// Simulate on-going loading when there are no data points and the experiment has not yet ended.
	if (metrics.value?.length === 0 && !ended) {
		metrics = loadingResult;
	}

	return (
		<StateOverTimeCardPresenter
			widget={widget as StateOverTimeWidgetVO}
			position={position}
			start={start}
			duration={duration}
			metrics={metrics}
			ended={ended}
		/>
	);
}

function useStateOverTimeMetrics(
	experimentExecutionId: number,
	ended: boolean,
	widget: StateOverTimeWidgetVO,
): DataStreamResult<StateOverTimeMetricVO[]> {
	const [endedSignal] = useState(new ReplaySubject<boolean>(1));

	useEffect(() => {
		if (ended) {
			endedSignal.next(true);
		}
	}, [ended]);

	return useObservable(
		() =>
			getMetrics().pipe(
				concatWith(
					interval(2000).pipe(
						takeUntil(endedSignal),
						// Do not cause too-frequent requests bogging down the backend
						exhaustMap(() => getMetrics()),
					),

					// Do one more metric retrieval after the interval call above completed. This helps to grab metrics
					// that have caused the experiment to terminate. Unless of course the experiment was already completed
					// when we started the metric retrieval.
					ended ? from([]) : defer(() => getMetrics()),
				),
			),
		[experimentExecutionId, widget],
	);

	function getMetrics(): Observable<StateOverTimeMetricVO[]> {
		return from(Services.executionMetrics.getStateOverTimeMetrics(experimentExecutionId, widget));
	}
}
