/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Container,
	ModalContentV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	RouterLink,
	Snackbar,
	Stack,
	Text,
	userConfirm,
} from 'components';
import { EndedExperimentVO, RunningExperimentVO } from 'ui-api';
import { IconArrowRight, IconSleep } from 'components/icons';
import { Colors, Flex } from '@steadybit/ui-components-lib';
import useObservable from 'react-use/lib/useObservable';
import { Services } from 'services/services';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import { RunningExperiments } from './runningExperiments';
import { KillSwitchUnblock } from './killSwitchUnblock';
import { EndedExecutions } from './endedExecutions';
import { KillSwitch } from './killSwitch';

interface ActivityModalProps {
	onClose: () => void;
}

export default function ActivityModal({ onClose }: ActivityModalProps): ReactElement {
	const runningExperiments: RunningExperimentVO[] = useObservable(Services.experiments.running$, []);
	const endedExperiments: EndedExperimentVO[] = useObservable(Services.experiments.ended$, []);
	const killswitchActive = useObservable(Services.killswitch.killswitchActive$);

	const handleEngageClick = async (): Promise<void> => {
		if (
			(await userConfirm({
				title: 'Attention attention!',
				message:
					'Performing an emergency stop stops all executions and temporarily disable Steadybit for all users. Scheduled executions will be skipped during the stop.',
				actions: [
					{
						value: 'confirm',
						label: 'Continue',
						variant: 'primaryAttention',
					},
					{ value: 'cancel', label: 'Cancel', variant: 'chromeless' },
				],
			})) === 'confirm'
		) {
			try {
				await Services.killswitch.engage();
			} catch (error) {
				Snackbar.error('Killswitch not engaged: ' + error.toString(), { toastId: 'killswitch-engaged' });
			}
		}
	};

	const handleDisengageClick = async (): Promise<void> => {
		try {
			await Services.killswitch.disengage();
		} catch (error) {
			Snackbar.error('Killswitch not disengaged: ' + error.toString(), { toastId: 'killswitch-disengaged' });
		}
	};

	return (
		<ModalOverlay open centerContent onClose={onClose}>
			<ModalV2 minHeight="64px" width="90vw" maxWidth="630px" frameless slick withFooter>
				<ModalHeaderV2 title="" onClose={onClose} />
				<ModalContentV2>
					<Stack size="none" px="small" justifyContent="space-between" minHeight={320}>
						<Stack size="none" pb="small">
							{killswitchActive ? (
								<KillSwitchUnblock handleDisengageClick={handleDisengageClick} />
							) : (
								<Stack size="none">
									{runningExperiments.length === 0 && endedExperiments.length === 0 ? (
										<Container
											display={'flex'}
											py={'xLarge'}
											flexDirection={'column'}
											justifyContent={'center'}
											alignItems={'center'}
											color={'neutral400'}
											mx={'xLarge'}
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
											}}
										>
											<IconSleep width={44} height={51} />
											<Text variant="large" textAlign="center" my="medium" color="neutral600">
												You don&apos;t have any experiments running right now.
											</Text>
										</Container>
									) : null}

									{runningExperiments.length > 0 && (
										<Container>
											<Container
												sx={{ borderBottom: '1px solid', borderBottomColor: 'neutral300', pt: '14px', pb: '8px' }}
												onClick={(e) => e.stopPropagation()}
											>
												<Text variant={'mediumStrong'} as={'span'} my={'auto'}>
													Now Running
												</Text>
											</Container>
											<RunningExperiments running={runningExperiments} />
										</Container>
									)}

									{endedExperiments.length > 0 && (
										<Container>
											<Flex
												direction="horizontal"
												align="center"
												justify="spread"
												style={{
													borderBottom: '1px solid',
													borderBottomColor: Colors.neutral300,
													paddingTop: '40px',
													paddingBottom: '8px',
													paddingRight: '16px',
												}}
											>
												<Text variant={'mediumStrong'} as={'span'} my={'auto'}>
													Latest
												</Text>

												<RouterLink to="/experimentruns" onClick={onClose}>
													<Text variant="mediumMedium">
														See all Experiment Runs <IconArrowRight variant="small" />
													</Text>
												</RouterLink>
											</Flex>
											<EndedExecutions ended={endedExperiments} />
										</Container>
									)}
								</Stack>
							)}
						</Stack>

						<Stack
							direction="horizontal"
							sx={{
								mx: '-small',
								borderTop: '1px solid ' + theme.colors.neutral300,
							}}
						>
							{!killswitchActive && <KillSwitch handleEngageClick={handleEngageClick} />}
						</Stack>
					</Stack>
				</ModalContentV2>
			</ModalV2>
		</ModalOverlay>
	);
}
