/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	Link,
	ModalContentV2,
	ModalFooterV2,
	ModalHeaderV2,
	ModalV2,
	Radio,
	RouterLink,
	Stack,
	Text,
} from 'components';
import { IconArrowRight, IconHub, IconWarningOpen } from 'components/icons';
import { ReactElement, useEffect, useState } from 'react';
import { usePromise } from 'utils/hooks/usePromise';
import { PageParams } from 'utils/hooks/usePage';
import { Services } from 'services/services';
import { theme } from 'styles.v2/theme';
import { HubVO } from 'ui-api';

interface ChooseHubProps {
	selectHub: (hub: HubVO) => void;
	onClose: () => void;
}

export default function ChooseHub({ onClose, selectHub }: ChooseHubProps): ReactElement {
	const [selectedHub, setSelectedHub] = useState<HubVO | null>(null);

	const pageParams = new PageParams(0, 100, [['hubName', 'asc']]);
	const hubsResult = usePromise(() => Services.hubApi.getHubs(pageParams), [pageParams.page]);

	useEffect(() => {
		if (hubsResult.value?.totalElements === 1 && !selectedHub) {
			setSelectedHub(hubsResult.value.content[0]);
		}
	}, [hubsResult.value]);

	return (
		<ModalV2 maxWidth="1050px" width="90vw" slick centered withFooter>
			<ModalHeaderV2
				title="1. Choose a Hub"
				subTitle={
					<>
						<Text variant="small" color="neutral600" mr="xxSmall">
							These are the currently connected Hub you can choose from to import new templates.
						</Text>
						<Text as="span" variant="small" color="neutral600" mr="xxSmall">
							If your Hub is not listed here, you can add it in the
						</Text>
						<RouterLink to="/settings/hubs" color="primary">
							<Text as="span" variant="small">
								Hubs section.
							</Text>
						</RouterLink>
					</>
				}
				onClose={onClose}
			/>
			<ModalContentV2>
				<Stack size="none" mt="small">
					{hubsResult.loading && 'Loading hubs...'}
					{hubsResult.value?.content.map((hub, index) => {
						const isSelected = selectedHub?.id === hub.id;
						return (
							<HubLine
								key={hub.id}
								hub={hub}
								first={index === 0}
								isSelected={isSelected}
								onClick={() => setSelectedHub(hub)}
							/>
						);
					})}
				</Stack>
			</ModalContentV2>
			<ModalFooterV2>
				<Stack direction="horizontal" justifyContent="flex-end" width="100%" px="small">
					<Button
						disabled={!selectedHub}
						onClick={() => {
							if (selectedHub) {
								selectHub(selectedHub);
							}
						}}
					>
						Select Templates to import
						<IconArrowRight ml="xSmall" />
					</Button>
				</Stack>
			</ModalFooterV2>
		</ModalV2>
	);
}

interface HubLineProps {
	isSelected: boolean;
	isSingle?: boolean;
	syncError?: string;
	first?: boolean;
	hub: HubVO;
	onClick?: () => void;
}

export function HubLine({ hub, first, isSelected, isSingle, syncError, onClick }: HubLineProps): ReactElement {
	const borderWidth = syncError ? '2px' : '1px';
	return (
		<Stack
			key={hub.id}
			direction="horizontal"
			sx={{
				alignItems: 'center',

				minHeight: '64px',
				padding: '12px 16px',
				borderTop: first ? `${borderWidth} solid` : 'none',
				borderBottom: `${borderWidth} solid`,
				borderLeft: `${borderWidth} solid`,
				borderRight: `${borderWidth} solid`,
				borderColor: syncError ? 'coral' : 'neutral300',

				cursor: onClick ? 'pointer' : 'default',
				':hover': {
					backgroundColor: onClick ? theme.colors.neutral100 : 'none',
				},
			}}
			onClick={onClick}
		>
			{!isSingle && (
				<>
					<Radio checked={isSelected} onChange={() => {}} />
					<div style={{ width: '1px', height: '38px', backgroundColor: theme.colors.neutral200 }} />
				</>
			)}

			<Stack size="xxSmall">
				<Stack direction="horizontal" size="small" alignItems="center">
					<Stack direction="horizontal" size="xSmall" alignItems="center">
						<IconHub />
						<Text variant="mediumStrong" as="span">
							{hub.hubName}
						</Text>
					</Stack>
					{hub.hubLink && (
						<Link
							href={hub.hubLink}
							external
							sx={{
								textDecoration: 'none',
								':hover': {
									textDecoration: 'underline',
								},
							}}
						>
							{hub.hubLink}
						</Link>
					)}
				</Stack>

				{syncError && (
					<Stack direction="horizontal" size="xxSmall" alignItems="center">
						<IconWarningOpen variant="small" color="coral" />
						<Text variant="small" color="coral">
							{syncError}
						</Text>
					</Stack>
				)}
			</Stack>
		</Stack>
	);
}
