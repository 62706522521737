/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Container, StyleProp } from 'components';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

interface DropdownContentFrameProps {
	maxHeight?: string | number;
	maxWidth?: string | number;
	children: ReactElement | ReactElement[];
	snapToComponent?: boolean;
	sx?: StyleProp;
}

export default function DropdownContentFrame({
	children,
	maxHeight,
	maxWidth,
	snapToComponent,
	sx = {},
}: DropdownContentFrameProps): ReactElement {
	return (
		<Container
			sx={{
				maxHeight,
				maxWidth,
				mt: snapToComponent ? -9 : 0,
				overflowY: 'auto',
				overflowX: 'hidden',
				border: '1px solid ' + theme.colors.neutral200,
				borderTop: snapToComponent ? '2px solid ' + theme.colors.neutral300 : '1px solid ' + theme.colors.neutral200,
				boxShadow: snapToComponent ? 'none' : '2px 2px 9px 1px #62686f36',
				backgroundColor: 'neutral000',
				...sx,
				width: 'fit-content',
			}}
		>
			{children}
		</Container>
	);
}
