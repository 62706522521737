/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	Container,
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	Divider,
	Form,
	FormikCheckbox,
	FormikSelect,
	FormikTextField,
	Label,
	LoadingIndicator,
	ModalOverlay,
	Stack,
} from 'components';
import { EnvironmentSummaryVO, ExperimentVO, TeamSummaryVO } from 'ui-api';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import React, { ReactElement, useEffect } from 'react';
import { suppressSubmitOnEnter } from 'utils/form';
import { Formik, useFormikContext } from 'formik';
import { Services } from 'services/services';
import { useObservable } from 'react-use';
import { theme } from 'styles.v2/theme';
import { ensure } from 'utils/ensure';
import { ReplaySubject } from 'rxjs';

type ConfirmOptions = {
	originalExperiment: ExperimentVO;
};

type DuplicateExperimentFormValues = {
	name?: string;
	hypothesis?: string;
	teamId?: string;
	environmentId?: string;
	clearTargets?: boolean;
};

export type DuplicateExperimentResult = DuplicateExperimentFormValues & {
	result: string;
};
type OutstandingConfirmation = ConfirmOptions & {
	resolve: (result: DuplicateExperimentResult) => void;
};

const outstandingConfirmation$ = new ReplaySubject<OutstandingConfirmation | null>(1);

export const DuplicateExperimentConfirm: React.VFC = () => {
	const confirmation = useObservable(outstandingConfirmation$);
	const { environments } = useEnvironments();

	const teamId = confirmation?.originalExperiment.teamId;
	const environmentId = confirmation?.originalExperiment.environmentId || environments[0]?.id || '';

	const [teams] = useAsyncState(() => Services.teams.findTeams('', true), [], []);
	const isLoading = !teamId || !environmentId || teams.loading || !confirmation || environments.length === 0;

	return (
		<ModalOverlay
			open={Boolean(confirmation)}
			onClose={() => confirmation?.resolve?.({ result: 'cancel' })}
			zIndex={40}
		>
			{({ close }) => {
				const { originalExperiment } = { ...ensure(confirmation) };
				const initialTeamId: string = teams.value.find((t) => t.id === teamId)?.id ?? teams.value[0]?.id ?? teamId;
				const environmentsOfSelectedTeam = environments.filter(
					(environment) => !!environment.teams.find((ts) => ts.id === teamId),
				);
				const initialEnvironmentId: string =
					environmentsOfSelectedTeam.find((environment) => environment.id === environmentId)?.id ??
					environmentsOfSelectedTeam[0]?.id ??
					environmentId;

				return (
					<Formik
						initialValues={
							{
								...originalExperiment,
								name: `Copy of ${originalExperiment.name}`,
								teamId: initialTeamId,
								environmentId: initialEnvironmentId,
							} as DuplicateExperimentFormValues
						}
						validateOnBlur={false}
						enableReinitialize
						onSubmit={(values) => {
							confirmation?.resolve?.({ result: 'duplicate', ...values });
						}}
					>
						{({ submitForm, values }) => {
							return (
								<Form noValidate sx={{ display: 'contents' }} onKeyDown={suppressSubmitOnEnter}>
									<Dialog>
										<DialogHeader title={'Duplicate Experiment'} onClose={close} />
										<Divider my={'small'} />
										<DialogContent>
											{isLoading ? (
												<LoadingContent />
											) : (
												<DuplicateExperimentForm
													originalEnvironmentId={originalExperiment.environmentId}
													teams={teams.value}
													environments={environments}
												/>
											)}
										</DialogContent>
										<Divider mt={'small'} />
										<DialogFooter my={'small'}>
											<Button
												variant="secondary"
												onClick={(e) => {
													e.stopPropagation();
													confirmation?.resolve({ result: 'cancel', ...values });
												}}
											>
												Cancel
											</Button>

											<Button
												variant="primary"
												onClick={submitForm}
												ml="auto"
												disabled={isLoading}
												data-cy="duplicateExperiment"
											>
												Duplicate
											</Button>
										</DialogFooter>
									</Dialog>
								</Form>
							);
						}}
					</Formik>
				);
			}}
		</ModalOverlay>
	);
};

function LoadingContent(): ReactElement {
	return (
		<Container
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				py: 128,
			}}
		>
			<LoadingIndicator variant="xxLarge" color="slate" />
		</Container>
	);
}

const DuplicateExperimentForm: React.VFC<{
	originalEnvironmentId: string;
	teams: TeamSummaryVO[];
	environments: EnvironmentSummaryVO[];
}> = ({ originalEnvironmentId, teams, environments }) => {
	const formik = useFormikContext<DuplicateExperimentFormValues>();
	const { clearTargets, teamId, environmentId } = formik.values;

	const showWarning = environmentId && environmentId !== originalEnvironmentId && !clearTargets;
	const teamEnvironments = environments.filter((environment) => !!environment.teams.find((ts) => ts.id === teamId));

	useEffect(() => {
		const selectedEnvironment = teamEnvironments.find((environment) => environment.id === environmentId);
		if (!selectedEnvironment) {
			// If the selected environment is not in the selected team, take the first environment of the team
			formik.setFieldValue('environmentId', teamEnvironments[0]?.id);
		}
	}, [teamId]);

	const teamOptions = teams.map(({ id, name }) => ({
		label: name,
		value: id,
	}));
	const environmentOptions = teamEnvironments.map((environment) => ({
		label: environment.name,
		value: environment.id,
	}));

	return (
		<Stack>
			<FormikTextField label={'Name'} name={'name'} maxLength={255} autoComplete={'off'} />
			<FormikTextField
				label={'Description (optional)'}
				name={'hypothesis'}
				maxLength={255}
				autoComplete={'off'}
				as="textarea"
			/>
			<Label>
				Team
				<FormikSelect name="teamId" options={teamOptions} />
			</Label>
			<Label>
				Environment
				<FormikSelect name="environmentId" options={environmentOptions} />
				{showWarning && (
					<Container bg={'coral100'} sx={{ borderRadius: '3px', borderLeft: '4px solid', borderLeftColor: 'coral700' }}>
						<Container mx={'small'} mt="xSmall">
							<Label variant={'medium'} color={'neutral700'}>
								Changing the environment may prevent the experiment from running, because inconsistent targets would not
								be reachable.
							</Label>
						</Container>
					</Container>
				)}
			</Label>

			<Label>
				Clear Targets
				<FormikCheckbox
					mt={'xSmall'}
					name={'clearTargets'}
					label={'Remove all target information when duplicating'}
					variant={'small'}
					sx={{
						'&& > label': {
							...theme.text.medium,
						},
					}}
				/>
			</Label>
		</Stack>
	);
};

export function duplicateExperimentConfirm(originalExperiment: ExperimentVO): Promise<DuplicateExperimentResult> {
	return new Promise<DuplicateExperimentResult>((resolve) => {
		outstandingConfirmation$.next({
			resolve: (result: DuplicateExperimentResult) => {
				outstandingConfirmation$.next(null);
				resolve(result);
			},
			originalExperiment,
		});
	});
}
