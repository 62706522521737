/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { Container } from 'components';
import { ReactElement } from 'react';

export function ExploreLoadingView(): ReactElement {
	return (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				width: '100%',
				bg: 'neutral700',
			}}
		>
			<LoadingIndicator variant="xxLarge" color="neutral000" />
		</Container>
	);
}
