/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import React, { CSSProperties, Fragment, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ExperimentExecutionTimerange from 'pages/experiments/components/ExperimentExecutionTimerange';
import { IconExperimentError, IconExperimentFailed } from 'components/icons';
import { Modifier, usePopper } from 'react-popper';
import { Container, Stack } from 'components';
import { formatTime } from 'utils/dateFns';
import { clamp, throttle } from 'lodash';
import { createPortal } from 'react-dom';
import { theme } from 'styles.v2/theme';

import {
	PlayerBackground,
	PlayerCursor,
	PlayerCursorAnimated,
	PlayerCursorWrapper,
	PlayerLane,
	PlayerLaneWrapper,
	PlayerProgress,
	PlayerStep,
	PlayerTimeScale,
} from './components/player';
import {
	ExperimentPlayerLanes,
	ExperimentPlayerStep,
	ExperimentPlayerStepId,
	ExperimentPlayerTimeStamp,
} from './types';

export interface ExperimentPlayerProps extends React.ComponentProps<'div'> {
	lanes: ExperimentPlayerLanes;
	progress?: ExperimentPlayerTimeStamp;
	start: ExperimentPlayerTimeStamp;
	duration: ExperimentPlayerTimeStamp;
	renderStepContent: (id: ExperimentPlayerStepId) => React.ReactNode;
	stopped?: ExperimentPlayerTimeStamp;
	onHoverUpdate: (position: ExperimentPlayerTimeStamp | null) => void;
	cursorPosition: ExperimentPlayerTimeStamp | null;
	onPositionSelect: (position: ExperimentPlayerTimeStamp | null) => void;
	completedWithFailure: boolean;
	completedWithError: boolean;
	setSelectedStepId: (selectedStepId: ExperimentPlayerStepId | null) => void;
	selectedStepId: ExperimentPlayerStepId | null;
	hoveredStepId: string | null;
	setHoveredStepId: (id: string | null) => void;
}

export function timelinePercentage(pos: number, start: number, duration: number): string {
	return `${(100 / duration) * (pos - start)}%`;
}

const sameWidth: Modifier<'sameWidth'> = {
	name: 'sameWidth',
	enabled: true,
	phase: 'beforeWrite',
	requires: ['computeStyles'],
	fn: ({ state }) => {
		state.styles.popper.width = `${state.rects.reference.width}px`;
	},
	effect: ({ state }) => {
		state.elements.popper.style.width = `${(state.elements.reference as HTMLElement).offsetWidth}px`;
	},
};

const preventOverflow: Modifier<'preventOverflow'> = {
	name: 'preventOverflow',
	options: {
		rootBoundary: 'viewport',
	},
};

const offsetFunction = ({ placement }: { placement: string }): [number, number] => {
	switch (placement) {
		case 'bottom':
			return [0, -4];
		default:
			return [0, 0];
	}
};

const offset: Modifier<'offset'> = {
	name: 'offset',
	options: {
		offset: offsetFunction,
	},
};

export function ExperimentPlayer({
	onHoverUpdate,
	onPositionSelect,
	cursorPosition,
	progress,
	start,
	duration,
	stopped,
	lanes,
	renderStepContent,
	completedWithFailure,
	completedWithError,
	setSelectedStepId,
	hoveredStepId,
	setHoveredStepId,
}: ExperimentPlayerProps): React.ReactElement {
	const [localHoveredStepId, setLocalHoveredStepId] = useState<string | null>(hoveredStepId);
	const backgroundRef = useRef<HTMLDivElement>(null);
	const [hoveredStepElement, setHoveredStepElement] = useState<HTMLElement | null>(null);
	const [hoverPositionPercentage, setHoverPositionPercentage] = useState<ExperimentPlayerTimeStamp | null>(null);
	const popperEl = useRef<HTMLElement | null>(null);

	const { styles, attributes } = usePopper(hoveredStepElement, popperEl.current, {
		placement: 'bottom',
		modifiers: [sameWidth, preventOverflow, offset],
	});

	const updateHoverHandler = useMemo(
		() =>
			throttle((e: React.MouseEvent<HTMLDivElement>): void => {
				if (backgroundRef.current) {
					const rect = backgroundRef.current.getBoundingClientRect();
					const x = e.clientX - rect.left - 16;
					if (x > 0) {
						setHoverPositionPercentage((100 / (backgroundRef.current.clientWidth - 16)) * x);
					}
				}
			}, 0),
		[backgroundRef],
	);

	const handleBackgroundClick = useCallback(
		(e: React.MouseEvent<HTMLDivElement>) => {
			if (backgroundRef.current) {
				const rect = backgroundRef.current.getBoundingClientRect();
				const x = e.clientX - rect.left - 16;
				if (x > 0) {
					onPositionSelect(start + duration * (((100 / (backgroundRef.current.clientWidth - 16)) * x) / 100));
				}
			}
		},
		[duration, onPositionSelect, start],
	);

	const progressPosition = progress ? clamp(progress, start, start + duration) : undefined;
	const stoppedPosition = stopped ? clamp(stopped, start, start + duration) : undefined;
	const animatedCursorPosition = cursorPosition || progressPosition;

	useEffect(() => {
		onHoverUpdate(hoverPositionPercentage);
	}, [hoverPositionPercentage, onHoverUpdate]);

	let tooltipIsLeftAligned = true;
	const hoveredStep: ExperimentPlayerStep | undefined = localHoveredStepId
		? lanes
				.map((lane) => lane.flatMap((step) => step))
				.flat()
				.find((step) => step.id === localHoveredStepId)
		: undefined;
	if (hoveredStep) {
		const passesHalfOfTheTimeline = hoveredStep.start - start > duration / 2;
		tooltipIsLeftAligned = !passesHalfOfTheTimeline;
	}

	return (
		<PlayerBackground
			ref={backgroundRef}
			onMouseEnter={updateHoverHandler}
			onMouseMove={updateHoverHandler}
			onMouseLeave={() => setHoverPositionPercentage(null)}
			onClick={handleBackgroundClick}
		>
			{progressPosition !== undefined && (
				<PlayerProgress position={timelinePercentage(progressPosition, start, duration)} />
			)}
			{stoppedPosition !== undefined && (
				<PlayerProgress position={timelinePercentage(stoppedPosition, start, duration)} />
			)}
			<PlayerLaneWrapper>
				{lanes.map((lane, laneIndex) => (
					<PlayerLane key={laneIndex} prefixContent={laneIndex + 1}>
						{lane.map((step, stepIndex) => {
							if (step.effectiveSx === null) {
								return null;
							}

							const stepEnd =
								step.state === 'RUNNING' && progressPosition && step.end < progressPosition
									? progressPosition
									: step.end;

							const stepDuration = step.effectiveStart
								? stepEnd - step.effectiveStart + Math.abs(step.effectiveStart - step.start)
								: stepEnd - step.start;

							const width = `${(stepDuration / duration) * 100}%`;
							const left = timelinePercentage(step.start, start, duration);

							const initWidth = step.effectiveStart
								? timelinePercentage(step.effectiveStart, step.start, stepEnd - step.start)
								: 0;

							return (
								<Fragment key={`${laneIndex}-${stepIndex}`}>
									<PlayerStep
										width={width}
										left={left}
										zIndex={lane.length - stepIndex}
										initSx={step.initSx}
										initWidth={initWidth}
										effectiveSx={step.effectiveSx}
										textSx={step.textSx}
										state={step.state}
										experimentExecutionEnd={stoppedPosition}
										start={step.start}
										end={step.effectiveStart || stepEnd}
										ref={hoveredStep?.id === step.id ? setHoveredStepElement : null}
										onClick={(e) => {
											e.stopPropagation();
											setSelectedStepId(step.id);
										}}
										onMouseMove={(e) => e.stopPropagation()}
										onMouseEnter={(e) => {
											e.stopPropagation();
											setHoverPositionPercentage(null);
											setHoveredStepId(step.id);
											setLocalHoveredStepId(step.id);
										}}
										onMouseLeave={() => {
											setHoveredStepId(null);
											setLocalHoveredStepId(null);
										}}
										highlighted={hoveredStepId === step.id}
										muted={hoveredStepId ? hoveredStepId !== step.id : undefined}
									>
										{step.label ?? null}
									</PlayerStep>

									<ExperimentStepStateIcon
										state={step.state}
										left={`calc(${left} - 8px)`}
										onMouseEnter={(e) => {
											e.stopPropagation();
											setHoverPositionPercentage(null);
											setHoveredStepId(step.id);
											setLocalHoveredStepId(step.id);
										}}
										onMouseLeave={() => {
											setHoveredStepId(null);
											setLocalHoveredStepId(null);
										}}
										zIndex={lane.length + 1}
									/>
								</Fragment>
							);
						})}
					</PlayerLane>
				))}
			</PlayerLaneWrapper>
			<PlayerTimeScale duration={duration} />
			<PlayerCursorWrapper>
				{animatedCursorPosition && (
					<PlayerCursorAnimated
						totalWidth={backgroundRef.current?.clientWidth || 0}
						variant="slate"
						position={timelinePercentage(animatedCursorPosition, start, duration)}
					>
						{formatTime(new Date(animatedCursorPosition))}
					</PlayerCursorAnimated>
				)}
				{!!hoverPositionPercentage && (
					<PlayerCursor
						totalWidth={backgroundRef.current?.clientWidth || 0}
						variant="hover"
						position={`${hoverPositionPercentage}%`}
					>
						{formatTime(new Date(start + duration * (hoverPositionPercentage / 100)))}
					</PlayerCursor>
				)}
				{stopped && (
					<PlayerCursor
						totalWidth={backgroundRef.current?.clientWidth || 0}
						variant={completedWithFailure ? 'failed' : completedWithError ? 'errored' : 'success'}
						position={timelinePercentage(stopped, start, duration)}
					>
						{formatTime(new Date(stopped))}
					</PlayerCursor>
				)}
			</PlayerCursorWrapper>

			{hoveredStep
				? createPortal(
						<Container
							ref={popperEl}
							style={styles.popper}
							sx={{
								zIndex: 12,
								display: 'flex',
								justifyContent: tooltipIsLeftAligned ? 'flex-start' : 'flex-end',
								pointerEvents: 'none',
							}}
							{...attributes.popper}
						>
							<Container
								sx={{
									px: 'small',
									py: 'xSmall',
									bg: 'neutral050',
									border: getStateBorderColor(hoveredStep.originalExperimentStep?.state),
									boxShadow: 'applicationLarge',
									marginLeft:
										tooltipIsLeftAligned && hoveredStep.effectiveStart
											? timelinePercentage(
													Math.max(hoveredStep.effectiveStart, hoveredStep.start),
													hoveredStep.start,
													hoveredStep.end - hoveredStep.start,
											  )
											: 0,
									borderRadius: 4,
									minWidth: 320,
									width: 'fit-content',
								}}
							>
								<Stack size="xSmall">
									<ExperimentExecutionTimerange
										state={hoveredStep.state}
										start={Math.max(hoveredStep.effectiveStart || 0, hoveredStep.start)}
										end={hoveredStep.end}
										experimentExecutionEnd={stoppedPosition}
									/>
									<Container>{renderStepContent(hoveredStep.id)}</Container>
								</Stack>
							</Container>
						</Container>,
						document.body,
				  )
				: null}
		</PlayerBackground>
	);
}

interface ExperimentStepStateIconProps {
	state: string;
	left: string;
	zIndex: number;
	onMouseEnter: (e: React.MouseEvent) => void;
	onMouseLeave: (e: React.MouseEvent) => void;
}

function ExperimentStepStateIcon({
	state,
	left,
	zIndex,
	onMouseEnter,
	onMouseLeave,
}: ExperimentStepStateIconProps): ReactElement | null {
	const commonStyle: CSSProperties = {
		position: 'absolute',
		top: -8,
		left,
		width: 27,
		height: 27,

		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',

		zIndex,
	};

	if (state === 'ERRORED') {
		return <IconExperimentError sx={{ ...commonStyle }} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />;
	}
	if (state === 'FAILED') {
		return <IconExperimentFailed sx={{ ...commonStyle }} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />;
	}

	return null;
}

function getStateBorderColor(state: string | undefined): string {
	if (state === 'ERRORED') {
		return '2px solid ' + theme.colors.coral;
	}
	if (state === 'FAILED') {
		return '2px solid ' + theme.colors.experimentWarning;
	}
	return 'none';
}
