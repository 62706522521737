/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Stack, Text } from 'components';
import { ReactElement } from 'react';

import { ActionSubCategory } from './types';
import Actions from './Actions';

interface ActionSubGroupProps {
	subGroup: ActionSubCategory;
}

export default function ActionSubGroup({ subGroup }: ActionSubGroupProps): ReactElement {
	return (
		<Stack size="xxSmall">
			<Text variant="smallMedium" color="neutral600">
				{subGroup.label}
			</Text>
			<Actions actions={subGroup.actions} />
		</Stack>
	);
}
