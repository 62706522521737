/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from 'url/Link';
import React from 'react';

import { Button, ButtonProps } from './Button';

export type RouterButtonProps = ButtonProps & ReactRouterLinkProps<never>;

export const RouterButton = React.forwardRef<HTMLButtonElement, RouterButtonProps>(
	({ disabled, children, ...props }, ref) => {
		if (disabled) {
			return (
				<Button ref={ref} {...props} disabled={true}>
					{children}
				</Button>
			);
		}
		return (
			<Button ref={ref} as={ReactRouterLink} {...props}>
				{children}
			</Button>
		);
	},
);
RouterButton.displayName = 'RouterButton';
