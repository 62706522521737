/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Text, TextProps } from 'components';
import React from 'react';

export type HeadingProps = TextProps;

export const Heading: React.FC<HeadingProps> = ({ children, sx, ...props }) => {
	return (
		<Text tx="heading" as="div" sx={{ ...sx }} {...props}>
			{children}
		</Text>
	);
};
