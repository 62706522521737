/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import VerifyEmailError from 'pages/login-failure/VerifyEmailError';
import DefaultError from 'pages/login-failure/DefaultError';
import { useLocation } from 'url/hooks';
import React from 'react';

const LoginFailure: React.VFC = () => {
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const error = searchParams.get('error');

	if (error && error.toLowerCase().indexOf('steadybit-verify-email') >= 0) {
		return <VerifyEmailError />;
	}
	return <DefaultError error={error} />;
};

export default LoginFailure;
