/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Draggable, DraggableProvided, DraggableStateSnapshot, Droppable } from 'react-beautiful-dnd';
import { getColors } from 'pages/experiments/components/utils';
import { IconDelay, IconHandle } from 'components/icons';
import { ReactElement, useState } from 'react';
import { Container, Text } from 'components';
import { ActionIcon } from 'hocs/ActionIcon';
import { ActionVO } from 'ui-api';

import { SidebarActionMaker, WorkspaceLaneMaker, correctDropAnimation } from '../DragAndDropHandler';
import useIsExperimentDisabled from '../useIsExperimentDisabled';
import { ActionCategoryItem } from './types';
import useActions from '../useActions';

interface ActionsProps {
	actions?: ActionCategoryItem[];
}

export default function Actions({ actions }: ActionsProps): ReactElement | null {
	const [fixedId] = useState(Math.random());
	const disabled = useIsExperimentDisabled();
	const availableActions = useActions();

	if (!actions) {
		return null;
	}

	return (
		<Droppable droppableId={'droppable' + fixedId} isDropDisabled>
			{(droppableProvided) => (
				<div
					ref={droppableProvided.innerRef}
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '2px',
					}}
				>
					{actions.map(({ id, label }, i) => {
						const action = availableActions.find((a) => a.id === id);
						if (disabled) {
							return <Action key={id} id={id} label={label} action={action} disabled />;
						}

						return (
							<Draggable key={SidebarActionMaker + id} draggableId={SidebarActionMaker + id} index={i}>
								{(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => {
									const isDraggingOverLane: boolean =
										dragSnapshot.draggingOver && dragSnapshot.draggingOver.startsWith(WorkspaceLaneMaker)
											? true
											: false;

									return (
										<div
											ref={dragProvided.innerRef}
											{...dragProvided.draggableProps}
											{...dragProvided.dragHandleProps}
											style={correctDropAnimation(dragSnapshot, dragProvided.draggableProps.style)}
										>
											<Action
												key={id}
												id={id}
												label={label}
												action={action}
												isDragging={dragSnapshot.isDragging}
												isDraggingOverLane={isDraggingOverLane}
											/>
										</div>
									);
								}}
							</Draggable>
						);
					})}

					{droppableProvided.placeholder}
				</div>
			)}
		</Droppable>
	);
}

interface ActionProps {
	action: ActionVO | undefined;
	isDraggingOverLane?: boolean;
	isDragging?: boolean;
	disabled?: boolean;
	label: string;
	id: string;
}

function Action({ id, label, isDragging, isDraggingOverLane, action, disabled }: ActionProps): ReactElement {
	const { backgroundColor, color } = action?.kind
		? getColors(action.kind)
		: {
				backgroundColor: 'experimentWait',
				color: 'neutral000',
			};

	return (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: '8px',
				padding: '4px 8px',

				backgroundColor: isDraggingOverLane ? 'purple100' : 'neutral000',
				borderRadius: '4px',
				border: isDraggingOverLane ? '2px dashed' : '1px solid',
				borderColor: isDraggingOverLane ? 'slate' : isDragging ? 'neutral400' : 'neutral000',

				'&:hover': {
					borderColor: 'neutral400',
					backgroundColor: 'neutral100',
				},
			}}
		>
			<Container
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					minWidth: '32px',
					maxWidth: '32px',
					minHeight: '32px',
					maxHeight: '32px',
					borderRadius: '4px',
					backgroundColor,
				}}
			>
				{id === 'wait' ? <IconDelay color={color} /> : <ActionIcon id={id} color={color} />}
			</Container>
			<Text variant="smallMedium" color="neutral600">
				{label}
			</Text>
			{!disabled && <IconHandle ml="auto" color={isDragging ? 'neutral700' : 'neutral500'} />}
		</Container>
	);
}
