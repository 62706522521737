/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import {
	CSSProperties,
	FocusEventHandler,
	KeyboardEventHandler,
	MouseEventHandler,
	PointerEventHandler,
	TouchEventHandler,
} from 'react';
import { StyleProp } from 'components';

type OverflowType = 'auto' | 'hidden' | 'scroll' | 'visible';
export interface BasicStyleProperties {
	id?: string;
	className?: string;
	name?: string;
	backgroundColor?: string;
	bg?: string;
	color?: string;

	height?: string | number;
	minHeight?: string | number;
	maxHeight?: string | number;

	width?: string | number;
	minWidth?: string | number;
	maxWidth?: string | number;

	m?: string | number;
	mb?: string | number;
	ml?: string | number;
	mr?: string | number;
	mt?: string | number;
	mx?: string | number;
	my?: string | number;

	p?: string | number;
	pb?: string | number;
	pl?: string | number;
	pr?: string | number;
	pt?: string | number;
	px?: string | number;
	py?: string | number;

	overflow?: OverflowType;
	overflowX?: OverflowType;
	overflowY?: OverflowType;

	opacity?: string | number;
	sx?: StyleProp;
	style?: CSSProperties;
	tabIndex?: number;
}

export interface InteractionTypes<T extends HTMLElement> {
	onBlur?: FocusEventHandler<T>;
	onClick?: MouseEventHandler<T>;
	onFocus?: FocusEventHandler<T>;
	onKeyDown?: KeyboardEventHandler<T>;
	onMouseEnter?: MouseEventHandler<T>;
	onMouseMove?: MouseEventHandler<T>;
	onPointerDown?: PointerEventHandler<T>;
	onMouseLeave?: MouseEventHandler<T>;
	onMouseDown?: MouseEventHandler<T>;
	onMouseUp?: MouseEventHandler<T>;
	onTouchStart?: TouchEventHandler<T>;
	onTouchEnd?: TouchEventHandler<T>;
}

export function resolveStyleProp(prop: string | number | undefined): string | undefined {
	if (typeof prop === 'number') {
		return `${prop}px`;
	}
	return prop;
}

export function resolveSx(sx?: StyleProp): StyleProp {
	if (!sx) {
		return {};
	}

	addResolvedIfPresent(sx, 'height');
	addResolvedIfPresent(sx, 'minHeight');
	addResolvedIfPresent(sx, 'maxHeight');
	addResolvedIfPresent(sx, 'width');
	addResolvedIfPresent(sx, 'minWidth');
	addResolvedIfPresent(sx, 'maxWidth');
	addResolvedIfPresent(sx, 'm');
	addResolvedIfPresent(sx, 'mb');
	addResolvedIfPresent(sx, 'ml');
	addResolvedIfPresent(sx, 'mr');
	addResolvedIfPresent(sx, 'mt');
	addResolvedIfPresent(sx, 'mx');
	addResolvedIfPresent(sx, 'my');
	addResolvedIfPresent(sx, 'p');
	addResolvedIfPresent(sx, 'pb');
	addResolvedIfPresent(sx, 'pl');
	addResolvedIfPresent(sx, 'pr');
	addResolvedIfPresent(sx, 'pt');
	addResolvedIfPresent(sx, 'px');
	addResolvedIfPresent(sx, 'py');
	addResolvedIfPresent(sx, 'top');
	addResolvedIfPresent(sx, 'left');
	addResolvedIfPresent(sx, 'right');
	addResolvedIfPresent(sx, 'bottom');

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	if (sx.flex) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		sx = { ...sx, ...resolveFlex(sx.flex) };
	}

	return sx;
}

function addResolvedIfPresent(sx: StyleProp, key: string): void {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	if (sx[key]) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const value = sx[key];
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		sx[key] = resolveStyleProp(value);
	}
}

export function resolveTxAndVariant(tx?: string, variant?: string): string | undefined {
	if (!tx) {
		return variant;
	}
	if (!variant) {
		return tx;
	}
	return `${tx}.${variant}`;
}

export function getAriaAndDataProps(props: Record<string, string>): Record<string, string> {
	const ariaAndDataProps: Record<string, string> = {};

	Object.keys(props).forEach((key) => {
		if (key.startsWith('aria-') || key.startsWith('data-')) {
			ariaAndDataProps[key] = props[key];
		}
	});

	return ariaAndDataProps;
}

export function resolveFlex(flex?: string):
	| Record<string, string>
	| {
			flex: undefined;
			flexGrow: number;
			flexShrink: number;
			flexBasis: string;
	  } {
	if (!flex) {
		return {};
	}

	const parts = flex.split(' ');
	if (parts.length === 3) {
		return {
			flex: undefined,
			flexGrow: Number(parts[0]),
			flexShrink: Number(parts[1]),
			flexBasis: parts[2],
		};
	}
	if (parts.length === 1) {
		return {
			flex: undefined,
			flexGrow: 1,
			flexShrink: 1,
			flexBasis: parts[0],
		};
	}

	return { flex };
}
