/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	Container,
	FormikError,
	hasError,
	Heading,
	SettingsGroup,
	Spinner,
	Stack,
	Text,
	UnitField,
} from 'components';
import React, { ChangeEvent, ReactElement, RefObject, useCallback, useMemo, useState } from 'react';
import { IconChevronDoubleLeft, IconChevronDoubleRight, IconWarning } from 'components/icons';
import { ActionKindVO, ExperimentStepRadiusVO, QuantityRestrictionVO } from 'ui-api';
import { useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import AttackedTargetIndicator from 'targets/AttackedTargetIndicator';
import { useTargetDefinition } from 'targets/useTargetDefinition';
import DropDown from 'components/Select/Dropdown/Dropdown';
import { listPlaceholders } from 'templates/utils';
import { toInt, toString } from 'utils/numberFn';
import { useUpdateEffect } from 'react-use';
import { toTitleCase } from 'utils/string';
import { useFormikContext } from 'formik';
import { getLabel } from 'i18n/label';
import { ampli } from 'ampli';

import { ExperimentFormValues } from '../../experiment';
import { useBlastRadiusCount } from '../utils';

const BLAST_UNIT_PERCENTAGE = { label: '%', value: 'percentage' };
const BLAST_UNIT_MAXIMUM = { label: '#', value: 'maximum' };
const BLAST_UNITS = [BLAST_UNIT_PERCENTAGE, BLAST_UNIT_MAXIMUM];

export default function StepTargetsBlastRadius({
	targetType,
	blastRadius,
	environmentId,
	stepPath,
	expanded,
	setExpanded,
	actionKind,
	disabled,
	quantityRestriction,
}: {
	targetType: string;
	blastRadius: ExperimentStepRadiusVO;
	environmentId: string;
	stepPath: string | null;
	expanded: boolean;
	setExpanded: (expanded: boolean) => void;
	disabled?: boolean;
	actionKind?: ActionKindVO;
	quantityRestriction?: QuantityRestrictionVO;
}): ReactElement {
	const formik = useFormikContext<ExperimentFormValues>();
	const { setFieldValue } = formik;
	const mode = useMemo(
		() =>
			Number.isInteger(blastRadius.percentage)
				? 'percentage'
				: Number.isInteger(blastRadius.maximum)
					? 'maximum'
					: 'percentage',
		[blastRadius.percentage, blastRadius.maximum],
	);
	const targetDefinition = useTargetDefinition(targetType);
	const variables = useFormikContext<ExperimentFormValues>().values.variables;
	const blastRadiusCount = useBlastRadiusCount(blastRadius, environmentId, variables);
	const blastRadiusTargetLabel = useMemo((): string => {
		if (targetDefinition.value) {
			return toTitleCase(getLabel(targetDefinition.value.label));
		}

		return toTitleCase(targetType) + 's';
	}, [targetType, targetDefinition]);
	const blastRadiusError =
		mode === 'percentage'
			? hasError(formik.getFieldMeta(`${stepPath}.blastRadius.percentage`))
			: hasError(formik.getFieldMeta(`${stepPath}.blastRadius.maximum`));

	useUpdateEffect(
		//when maximum blastradius exceeds the selection reduce it
		() => {
			if (blastRadius?.maximum && blastRadiusCount.total && blastRadius.maximum > blastRadiusCount.total) {
				setFieldValue(`${stepPath}.blastRadius.maximum`, blastRadiusCount.total);
			}
		},
		[blastRadius?.maximum, blastRadiusCount.total, setFieldValue, stepPath],
	);

	const { mode: editorMode } = useEditorSettings();
	const cannotDeterminCount =
		editorMode === 'templateEditor' && blastRadius.predicate && listPlaceholders(blastRadius.predicate).length > 0;

	return (
		<Container
			sx={{
				display: 'flex',
				flex: '0 0 auto',
				height: '156px',
				bg: 'neutral100',
				py: 'small',
				px: 'medium',
				flexDirection: 'column',
				borderBottom: '2px solid ',
				borderColor: 'neutral300',
			}}
		>
			<Text variant={'small'} color={'neutral700'} mb={'xSmall'}>
				{actionKind === 'ATTACK' || actionKind == null ? 'Attacked ' : null} {blastRadiusTargetLabel}
			</Text>
			<Stack direction="horizontal" alignItems="flex-start" justifyContent="space-between">
				<Container display={'flex'} alignItems={'center'}>
					{targetType && <AttackedTargetIndicator targetType={targetType} mr={'xSmall'} color="coral" />}
					<Text variant={'xxLargeStrong'} color={'neutral800'} mr={'xSmall'}>
						{cannotDeterminCount || blastRadiusCount.impacted === undefined || isNaN(blastRadiusCount.impacted)
							? '--'
							: blastRadiusCount.impacted}
					</Text>
				</Container>
				<Stack direction="horizontal" alignItems="center">
					<Button
						variant="secondary"
						disabled={cannotDeterminCount}
						sx={{ justifySelf: 'end' }}
						onClick={() => {
							setExpanded(!expanded);
							ampli.experimentTargetsAttackedViewed({
								attack_query_defined: !!blastRadius.predicate,
								target_type: targetType,
							});
						}}
					>
						{expanded ? 'Hide' : 'Show'} Targets
						{expanded ? <IconChevronDoubleRight ml="xSmall" /> : <IconChevronDoubleLeft ml="xSmall" />}
					</Button>
				</Stack>
			</Stack>
			<Stack direction="horizontal" alignItems="flex-start" justifyContent="space-between" mt={'xSmall'}>
				{!blastRadius.predicate ? (
					<Text muted mt={'xSmall'}>
						Please add a valid target selection below.
					</Text>
				) : (
					<Stack direction={'horizontal'} alignItems="center">
						{(!cannotDeterminCount && (blastRadiusCount.total === 0 || blastRadiusCount.total === 1)) ||
						quantityRestriction == 'EXACTLY_ONE' ||
						quantityRestriction == 'ALL' ? null : (
							<Text variant={'xLarge'} color={'neutral600'}>
								<DropDown
									renderComponent={({ setShowMenu, showMenu, ref }) => {
										return (
											<Text
												as="span"
												variant="xLargeStrong"
												ref={ref as RefObject<HTMLDivElement>}
												onClick={() => {
													if (!disabled) {
														setShowMenu(!showMenu);
													}
												}}
												color={blastRadiusError ? 'coral' : undefined}
												sx={{ cursor: 'pointer', textDecoration: 'underline', textUnderlineOffset: '2px' }}
											>
												{mode === 'percentage' ? (blastRadius.percentage || 0) + '%' : blastRadius.maximum || 0}
											</Text>
										);
									}}
								>
									{() => (
										<EditBlastRadius
											blastRadius={blastRadius}
											stepPath={stepPath}
											totalTargetCount={blastRadiusCount.total}
										/>
									)}
								</DropDown>{' '}
								of {cannotDeterminCount ? '--' : blastRadiusCount.total} Targets
							</Text>
						)}
						{quantityRestriction == 'EXACTLY_ONE' && (blastRadiusCount.total || 0) > 1 ? (
							<Text color={'coral'}>
								<IconWarning color="coral" mr="xSmall" />
								Action requires exactly one target.
							</Text>
						) : null}
						{blastRadiusCount.loading ? <Spinner variant={'small'} color="neutral300" /> : null}
					</Stack>
				)}
			</Stack>
		</Container>
	);
}

const EditBlastRadius: React.VFC<{
	blastRadius: ExperimentStepRadiusVO;
	stepPath: string | null;
	totalTargetCount?: number;
}> = ({ blastRadius, stepPath, totalTargetCount }) => {
	const formik = useFormikContext<ExperimentFormValues>();
	const [mode, setMode] = useState<'percentage' | 'maximum'>(
		Number.isInteger(blastRadius.percentage)
			? 'percentage'
			: Number.isInteger(blastRadius.maximum)
				? 'maximum'
				: 'percentage',
	);
	const handleUnitChange = useCallback(
		(unit: { value: string }): void => {
			const newBlastRadius: ExperimentStepRadiusVO = {};
			newBlastRadius.targetType = blastRadius.targetType;
			newBlastRadius.predicate = blastRadius.predicate;
			if (unit.value === 'percentage') {
				newBlastRadius.percentage = 50;
				setMode('percentage');
			} else if (unit.value === 'maximum') {
				newBlastRadius.maximum = 1;
				setMode('maximum');
			}
			formik.setFieldValue(`${stepPath}.blastRadius`, newBlastRadius);
		},
		[formik, stepPath],
	);
	const handleValueChange = useCallback(
		(event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>): void => {
			if (mode === 'percentage') {
				formik.setFieldValue(`${stepPath}.blastRadius.percentage`, toInt(event.target.value));
				formik.setFieldTouched(`${stepPath}.blastRadius.percentage`, true, false);
			} else {
				formik.setFieldValue(`${stepPath}.blastRadius.maximum`, toInt(event.target.value));
				formik.setFieldTouched(`${stepPath}.blastRadius.maximum`, true, false);
			}
		},
		[formik, mode, stepPath],
	);
	return (
		<Container
			display={'flex'}
			flexDirection={'column'}
			sx={{
				boxShadow: 'applicationLarge',
				borderRadius: 3,
			}}
			backgroundColor={'neutral000'}
			width={'368px'}
			p={'medium'}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
			}}
		>
			<Container sx={{ borderBottom: '1px solid', borderBottomColor: 'neutral200' }} pb={'medium'}>
				<Heading variant={'xSmall'}>Limit Targets</Heading>
			</Container>
			<Text variant={'small'} mt={'medium'} color={'neutral800'}>
				When executing, the maximum number of randomly selected targets specified here will be attacked.
			</Text>
			<SettingsGroup mt={'medium'} width={'150px'} sx={{ border: '0px' }} variant={'small'}>
				<UnitField
					value={toString(mode === 'percentage' ? blastRadius.percentage : blastRadius.maximum)}
					unit={mode === 'percentage' ? BLAST_UNIT_PERCENTAGE : BLAST_UNIT_MAXIMUM}
					units={BLAST_UNITS}
					min={0}
					max={mode === 'percentage' ? 100 : totalTargetCount}
					onUnitChange={handleUnitChange}
					onChange={handleValueChange}
					type={'number'}
					hasError={
						mode === 'percentage'
							? hasError(formik.getFieldMeta(`${stepPath}.blastRadius.percentage`))
							: hasError(formik.getFieldMeta(`${stepPath}.blastRadius.maximum`))
					}
				/>
			</SettingsGroup>
			<FormikError name={`${stepPath}.blastRadius.percentage`} />
			<FormikError name={`${stepPath}.blastRadius.maximum`} />
		</Container>
	);
};
