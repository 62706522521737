/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Code,
	Combobox,
	Container,
	Label,
	Link,
	ModalContentV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	Stack,
} from 'components';
import { getBaseHref } from 'utils/getBaseHref';
import { ReactElement, useState } from 'react';
import { useTenant } from 'tenancy/useTenant';
import { useTeam } from 'services/useTeam';
import { Flex } from 'theme-ui';

type ExperimentBadgeModalProps = {
	experimentKey: string;
	onClose: () => void;
};

type CodeTypeOptionType = { value: 'HTML' | 'MARKDOWN' | 'IMAGE'; label: string };
const codeTypeOptions: CodeTypeOptionType[] = [
	{ value: 'HTML', label: 'HTML' },
	{ value: 'MARKDOWN', label: 'Markdown' },
	{ value: 'IMAGE', label: 'Image only' },
];

type ScaleOptionType = { value: string; label: string };
const scaleOptions: ScaleOptionType[] = [
	{ value: '1', label: '1x' },
	{ value: '2', label: '2x' },
	{ value: '3', label: '3x' },
];

export default function ExperimentBadgeModal({ experimentKey, onClose }: ExperimentBadgeModalProps): ReactElement {
	const tenant = useTenant();
	const team = useTeam();
	const [codeType, setCodeType] = useState<CodeTypeOptionType>(codeTypeOptions[0]);
	const [scale, setScale] = useState<ScaleOptionType>(scaleOptions[0]);

	const imageUrl = `${getBaseHref()}api/experiments/${experimentKey}/badge.svg?tenantKey=${tenant.key}&scale=${
		scale.value
	}`;
	const experimentUrl = `${getBaseHref()}experiments/edit/${experimentKey}?tenant=${tenant.key}&team=${team.key}`;
	const html = `<a href='${experimentUrl}' target='_blank'><img alt='${experimentKey}' src='${imageUrl}'></a>`;
	const markdown = `[![${experimentKey}](${imageUrl})](${experimentUrl})`;

	const content = codeType.value === 'HTML' ? html : codeType.value === 'MARKDOWN' ? markdown : imageUrl;

	return (
		<ModalOverlay open onClose={onClose} centerContent>
			{({ close }) => (
				<ModalV2>
					<ModalHeaderV2 title={`Create Experiment Badge for ${experimentKey}`} onClose={close} />
					<ModalContentV2>
						<Stack size={'medium'}>
							<Stack direction={'horizontal'} sx={{ gap: 'small' }}>
								<Label>
									Format
									<Combobox<CodeTypeOptionType>
										options={codeTypeOptions}
										value={codeType}
										onChange={(option) => {
											if (option) {
												setCodeType(option);
											}
										}}
										name={'codeType'}
										variant={'small'}
									/>
								</Label>
								<Label>
									Scale
									<Combobox<ScaleOptionType>
										width={'80px'}
										options={scaleOptions}
										value={scale}
										onChange={(option) => {
											if (option) {
												setScale(option);
											}
										}}
										name={'scale'}
										variant={'small'}
									/>
								</Label>
							</Stack>
							<Label>
								Result
								<Container>
									<Link href={experimentUrl} target={'_blank'}>
										<img src={imageUrl} />
									</Link>
								</Container>
							</Label>
							<Code
								lang={codeType.value === 'HTML' || codeType.value === 'MARKDOWN' ? 'markdown' : undefined}
								sx={{ whiteSpace: 'normal' }}
								withCopyToClipboard
							>
								{content}
							</Code>
						</Stack>
					</ModalContentV2>
				</ModalV2>
			)}
		</ModalOverlay>
	);
}
