/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { TextField } from 'components/TextField';
import { IconSearch } from 'components/icons';
import { ReactElement } from 'react';

interface ListHeaderSearchProps {
	title: string;
	value: string;
	setValue: (value: string) => void;
}
export default function ListHeaderSearch({ value, setValue, title, ...props }: ListHeaderSearchProps): ReactElement {
	return (
		<TextField
			// copy props to keep data- attributes
			{...props}
			iconLeft={IconSearch}
			placeholder={title}
			value={value}
			type="text"
			name="name"
			onChange={(e) => setValue(e.target.value)}
			wrapperSx={{ width: 300, maxWidth: 300 }}
		/>
	);
}
