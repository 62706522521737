/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ReactElement, Suspense } from 'react';

import image from './bg.webp';

interface BackgroundImageProps {
	alternativeVersion?: boolean;
}

export default function BackgroundImage({ alternativeVersion }: BackgroundImageProps): ReactElement | null {
	if (!alternativeVersion) {
		return <></>;
	}
	return (
		<Suspense fallback={<></>}>
			<img
				src={alternativeVersion ? image : image}
				loading="lazy"
				style={{
					position: 'absolute',
					bottom: 0,
					right: 0,
					width: '1752px',
					height: '1009px',
					zIndex: 1,
				}}
			/>
		</Suspense>
	);
}
