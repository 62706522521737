/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import axios from 'axios';

interface Result {
	status: string;
}

export class HealthApi {
	async isAvailable(): Promise<boolean> {
		const response = await axios.get<Result>('/ui/health/');
		return response.data.status === 'UP';
	}
}
