/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { emit } from 'targets/Explore/ServiceLocator';
import { OrthographicCamera } from 'three';

export default class MouseController {
	canvas: HTMLCanvasElement;
	camera: OrthographicCamera;
	render: () => void;
	maxZoomOut = 0.1;
	maxZoomIn = 100;

	constructor(canvas: HTMLCanvasElement, camera: OrthographicCamera, render: () => void) {
		this.canvas = canvas;
		this.camera = camera;
		this.render = render;

		this.canvas.addEventListener('mousewheel', this.mouseWheelListener, false);
		this.canvas.addEventListener('mousedown', this.mouseDownListener, false);
		this.canvas.addEventListener('mouseup', this.mouseUpListener, false);
		this.canvas.addEventListener('mouseleave', this.mouseLeaveListener, false);
		this.canvas.addEventListener('mousemove', this.mouseMoveListener, false);
	}

	isDragging = false;
	mousePosX = -1;
	mousePosY = -1;
	mouseDownPosX = -1;
	mouseDownPosY = -1;
	mouseWheelListener = (e: Event): void => this.onDocumentMouseWheel(e as WheelEvent);
	mouseDownListener = (e: MouseEvent): void => {
		this.mousePosX = e.offsetX;
		this.mousePosY = e.offsetY;
		this.mouseDownPosX = e.offsetX;
		this.mouseDownPosY = e.offsetY;
	};
	mouseUpListener = (): void => {
		this.mouseDownPosX = -1;
		this.mouseDownPosY = -1;
		if (this.isDragging) {
			this.isDragging = false;
			emit({ type: 'dragStop' });
		} else {
			emit({ type: 'mouseClick' });
		}
	};
	mouseLeaveListener = (): void => {
		this.mouseDownPosX = -1;
		this.mouseDownPosY = -1;
		emit({ type: 'mouseMove', x: -1, y: -1 });

		if (this.isDragging) {
			this.isDragging = false;
			emit({ type: 'dragStop' });
		}
	};
	mouseMoveListener = (e: MouseEvent): void => {
		emit({ type: 'mouseMove', x: e.offsetX, y: e.offsetY });

		if (!this.isDragging && this.mouseDownPosX !== -1 && this.mouseDownPosY !== -1) {
			const dX = e.offsetX - this.mouseDownPosX;
			const dY = e.offsetY - this.mouseDownPosY;
			if (Math.sqrt(dX * dX + dY * dY) > 5) {
				this.isDragging = true;
				emit({ type: 'dragStart' });
			}
		}

		if (this.isDragging) {
			const dX = e.offsetX - this.mousePosX;
			const dY = e.offsetY - this.mousePosY;
			this.mousePosX = e.offsetX;
			this.mousePosY = e.offsetY;
			this.camera.position.x -= dX * (1 / this.camera.zoom);
			this.camera.position.y += dY * (1 / this.camera.zoom);
			this.render();
		}
	};

	onDocumentMouseWheel(event: WheelEvent): void {
		const mouseX = event.offsetX;
		const mouseY = event.offsetY;
		const canvasWidth = this.canvas.clientWidth;
		const canvasHeight = this.canvas.clientHeight;
		const mouseNormalizedX = (mouseX / canvasWidth) * 2 - 1;
		const mouseNormalizedY = -(mouseY / canvasHeight) * 2 + 1;

		const worldWidthBefore = (this.camera.right - this.camera.left) / this.camera.zoom;
		const worldHeightBefore = (this.camera.top - this.camera.bottom) / this.camera.zoom;

		const maxAllowedZoomRange = this.maxZoomIn - this.maxZoomOut;
		const deltaMouse = -event.deltaY * maxAllowedZoomRange * 0.00125;
		this.camera.zoom += deltaMouse;
		this.camera.zoom = Math.min(this.maxZoomIn, Math.max(this.maxZoomOut, this.camera.zoom));

		const worldWidthAfter = (this.camera.right - this.camera.left) / this.camera.zoom;
		const worldHeightAfter = (this.camera.top - this.camera.bottom) / this.camera.zoom;
		const deltaWorldWidth = worldWidthAfter - worldWidthBefore;
		const deltaWorldHeight = worldHeightAfter - worldHeightBefore;

		const movedXInWorldSpace = -mouseNormalizedX * deltaWorldWidth;
		const movedYInWorldSpace = -mouseNormalizedY * deltaWorldHeight;

		const camShiftX = movedXInWorldSpace / 2;
		const camShiftY = movedYInWorldSpace / 2;

		this.camera.position.x += camShiftX;
		this.camera.position.y += camShiftY;

		this.camera.updateProjectionMatrix();
		this.render();
	}

	setMaxZoomOut(maxZoomOut: number): number {
		this.maxZoomOut = Math.min(maxZoomOut, this.maxZoomIn);
		return this.maxZoomOut;
	}

	dispose(): void {
		this.canvas.removeEventListener('mousewheel', this.mouseWheelListener, false);
		this.canvas.removeEventListener('mousedown', this.mouseDownListener, false);
		this.canvas.removeEventListener('mouseup', this.mouseUpListener, false);
		this.canvas.removeEventListener('mousemove', this.mouseMoveListener, false);
		this.canvas.removeEventListener('mouseleave', this.mouseLeaveListener, false);
	}
}
