/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { AsyncState, useAsyncState } from 'utils/hooks/useAsyncState';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { ExperimentScheduleVO } from 'ui-api';
import { Services } from 'services/services';
import { debounce } from 'lodash';
import { useMemo } from 'react';

export default function useRefreshingSchedule(experimentKey: string): AsyncState<ExperimentScheduleVO | undefined> {
	const [schedule, fetchSchedule] = useAsyncState(
		() => Services.experiments.fetchSchedule(experimentKey),
		[experimentKey],
	);
	const debouncedFetchSchedule = useMemo(
		() =>
			debounce(fetchSchedule, 1000, {
				leading: true,
			}),
		[fetchSchedule],
	);

	useEventEffect(
		debouncedFetchSchedule,
		['schedule.created', 'schedule.updated', 'schedule.deleted'],
		debouncedFetchSchedule.cancel,
	);

	return schedule;
}
