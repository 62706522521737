/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import antlr4 from 'antlr4';

import QueryLanguageParser from './generated/QueryLanguageParser';
import { QueryLanguageToTargetPredicateVisitor } from './visitor';
import QueryLanguageLexer from './generated/QueryLanguageLexer';
import { QueryLanguageErrorListener } from './errorListener';
import { isParsingError, ParsingResult } from './types';

const allPredicate = {
	operator: 'AND',
	predicates: [],
};
export function toTargetPredicate(input: string): ParsingResult {
	try {
		const chars = new antlr4.InputStream(input);
		const lexer = new QueryLanguageLexer(chars);
		lexer.addErrorListener(new QueryLanguageErrorListener());
		const tokens = new antlr4.CommonTokenStream(lexer);
		const parser = new QueryLanguageParser(tokens);
		parser.buildParseTrees = true;
		// remove the default error to console.error listener
		parser.removeErrorListeners();
		parser.addErrorListener(new QueryLanguageErrorListener());
		const tree = parser.topLevelQuery();
		return tree.accept(new QueryLanguageToTargetPredicateVisitor()) ?? allPredicate;
	} catch (e) {
		if (isParsingError(e)) {
			return e;
		} else {
			return {
				message: e.message,
				line: 1,
				column: 1,
			};
		}
	}
}
