/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	hasError,
	Heading,
	RouterButton,
	RouterLink,
	SettingsGroup,
	SettingsGroupItem,
	SettingsGroupProps,
	ShortenedText,
	Text,
	Divider,
	Container,
} from 'components';
import { IconEnvironment, IconEnvironmentGlobal, IconCheck } from 'components/icons';
import { EnvironmentSummaryVO, TeamVO } from 'ui-api';
import { useTenant } from 'tenancy/useTenant';
import { useField } from 'formik';
import React from 'react';

type EnvironmentDropDownProps = SettingsGroupProps & {
	environments: EnvironmentSummaryVO[];
	fieldName: string;
	addEnvironmentEnabled?: boolean;
	onlyPermittedByTeam?: boolean;
	team?: TeamVO;
	loading?: boolean;
	selectItem: (v: string) => void;
};

const ExperimentEnvironmentDropDown: React.VFC<EnvironmentDropDownProps> = ({
	environments,
	fieldName,
	onlyPermittedByTeam = false,
	team,
	disabled,
	selectItem,
	loading = false,
	...props
}) => {
	const tenant = useTenant();
	const [field, meta, fieldHelpers] = useField(fieldName);
	const selectedEnvironmentId: string = field.value;

	const isAdmin = tenant.user.role === 'ADMIN';
	const isEnvironmentSelected = (environmentId: string): boolean => selectedEnvironmentId === environmentId;
	const isEnvironmentPermitted = (environmentId: string): boolean =>
		!!team && team.allowedEnvironments.includes(environmentId);
	const showPermissionWarning = (environmentId: string): boolean =>
		onlyPermittedByTeam && !isEnvironmentPermitted(environmentId) && !loading;

	const handleChangeCheck = (environmentId: string): void => {
		fieldHelpers.setValue(isEnvironmentSelected(environmentId) ? undefined : environmentId);
		fieldHelpers.setTouched(true);
		selectItem(environmentId);
	};
	const selectableEnvironments = environments.filter(
		(environment) =>
			!onlyPermittedByTeam || isEnvironmentSelected(environment.id) || isEnvironmentPermitted(environment.id),
	);
	const selectableEnvironmentsWithoutGlobal = environments.filter((environment) => !environment.global);

	const globalEnvironment = selectableEnvironments.find((environment) => environment.global);
	const globalChecked = selectedEnvironmentId === globalEnvironment?.id;
	const toBeRenderedEnvironments = selectableEnvironmentsWithoutGlobal.filter(
		(environment) =>
			(selectedEnvironmentId === environment.id && showPermissionWarning(environment.id)) ||
			!showPermissionWarning(environment.id),
	);
	const onlyGlobalEnvironmentAvailable = globalEnvironment && toBeRenderedEnvironments.length === 0;
	return selectableEnvironments.length > 0 ? (
		<>
			<SettingsGroup {...props} hasError={hasError(meta)} sx={{ border: 'none', maxWidth: 320 }}>
				{globalEnvironment && (
					<>
						<SettingsGroupItem
							key={globalEnvironment.id}
							sx={{
								':hover': {
									bg: 'neutral100',
								},
							}}
							onClick={
								!disabled
									? () => {
											handleChangeCheck(globalEnvironment.id);
									  }
									: undefined
							}
						>
							<Container
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}
								sx={{
									cursor: disabled ? 'initial' : 'pointer',
								}}
							>
								<Container
									display={'flex'}
									alignItems={'flex-start'}
									sx={{
										cursor: disabled ? 'initial' : 'pointer',
										minWidth: 'fit-content',
									}}
								>
									<IconEnvironmentGlobal flex={'0 0 auto'} />
									<Text
										ml={5}
										as={'label'}
										variant={globalChecked ? 'mediumStrong' : 'medium'}
										sx={{
											cursor: disabled ? 'initial' : 'pointer',
										}}
									>
										{globalEnvironment.name}
									</Text>
								</Container>
								{selectedEnvironmentId === globalEnvironment.id && <IconCheck flex={'0 0 auto'} ml="xSmall" />}
								{showPermissionWarning(globalEnvironment.id) ? (
									<Text ml={'medium'} variant={'smallStrong'} color={'coral'}>
										No Permissions for Environment!
									</Text>
								) : null}
							</Container>
						</SettingsGroupItem>
						<Divider />
					</>
				)}
				{!onlyGlobalEnvironmentAvailable && (
					<Container
						display={'flex'}
						px={'medium'}
						pt={'xSmall'}
						flexDirection={'row'}
						justifyContent={'space-between'}
						alignItems={'center'}
					>
						<Heading variant={'small'}>Environments</Heading>
						{isAdmin && (
							<RouterButton to={'/settings/environments'} variant={'secondary'} sx={{ height: 'large' }}>
								Manage
							</RouterButton>
						)}
					</Container>
				)}
				{toBeRenderedEnvironments.map((environment) => {
					const checked = selectedEnvironmentId === environment.id;
					return (
						<SettingsGroupItem
							sx={{
								cursor: disabled ? 'initial' : 'pointer',
								':hover': {
									bg: 'neutral100',
								},
							}}
							key={environment.id}
							onClick={
								!disabled
									? () => {
											handleChangeCheck(environment.id);
									  }
									: undefined
							}
						>
							<Container
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}
								sx={{
									cursor: disabled ? 'initial' : 'pointer',
								}}
							>
								<Container display={'flex'} alignItems={'flex-start'}>
									<IconEnvironment flex={'0 0 auto'} />
									<ShortenedText
										ml={5}
										as={'label'}
										variant={checked ? 'mediumStrong' : 'medium'}
										sx={{ cursor: disabled ? 'initial' : 'pointer', whiteSpace: 'nowrap' }}
									>
										{environment.name}
									</ShortenedText>
								</Container>
								{checked && <IconCheck flex={'0 0 auto'} />}
							</Container>
							{showPermissionWarning(environment.id) ? (
								<Text ml={'medium'} variant={'smallStrong'} color={'coral'} sx={{}}>
									No Permissions for Environment!
								</Text>
							) : null}
						</SettingsGroupItem>
					);
				})}
				{!onlyGlobalEnvironmentAvailable && (
					<>
						<Divider />
						<Text px={'medium'} pt={'small'} variant={'small'} color={'neutral600'}>
							Unsure which environment is the right one?
						</Text>
						<Text px={'medium'} pb={'xSmall'} variant={'small'} color={'neutral600'}>
							Explore your environments in the <RouterLink to={'/landscape'}>landscape</RouterLink>.
						</Text>
					</>
				)}
				{onlyGlobalEnvironmentAvailable && (
					<>
						<Text px={'medium'} pt={'small'} variant={'small'} color={'neutral600'}>
							Overwhelmed by the size of your system?
						</Text>
						<Text px={'medium'} pb={'xSmall'} variant={'small'} color={'neutral600'}>
							{isAdmin ? (
								<>
									<RouterLink to={'/settings/environments'}>Create an environment</RouterLink> to divide it into smaller
									pieces
								</>
							) : (
								<>
									Ask your admin to <RouterLink to={'/settings/environments'}>create an environment</RouterLink>.
								</>
							)}
						</Text>
					</>
				)}
			</SettingsGroup>
		</>
	) : loading ? null : (
		<Text variant={'small'} color={'coralDark'} mt={'xSmall'} px={'small'}>
			No Environments allowed for this Team.
		</Text>
	);
};

export default ExperimentEnvironmentDropDown;
