/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import MDEditor, { commands } from '@uiw/react-md-editor';
import Markdown from 'components/Markdown/Markdown';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

interface DescriptionEditorProps {
	hasError?: boolean;
	value: string;
	onChange: (value: string) => void;
}

export default function DescriptionEditor({ value, hasError = false, onChange }: DescriptionEditorProps): ReactElement {
	return (
		<div key="md-editor-wrapper" data-color-mode="light">
			<MDEditor
				key="md-editor"
				value={value}
				onChange={(val) => onChange(val || '')}
				maxHeight={600}
				commands={[
					commands.group(
						[commands.title1, commands.title2, commands.title3, commands.title4, commands.title5, commands.title6],
						{
							name: 'title',
							groupName: 'title',
							buttonProps: { 'aria-label': 'Insert title' },
						},
					),
					commands.bold,
					commands.italic,
					commands.strikethrough,
					commands.divider,
					commands.link,
					commands.quote,
					commands.code,
					commands.codeBlock,
					commands.comment,
					commands.divider,
					commands.table,
					commands.unorderedListCommand,
					commands.orderedListCommand,
					commands.checkedListCommand,
				]}
				extraCommands={[commands.codeEdit, commands.codeLive, commands.codePreview]}
				textareaProps={{
					placeholder: 'Please enter a Markdown description',
					maxLength: 20_001,
				}}
				style={{
					border: hasError ? '1px solid ' + theme.colors.coral : '1px solid ' + theme.colors.neutral000,
				}}
				components={{
					preview: (source) => <Markdown content={source} />,
				}}
			/>
		</div>
	);
}
