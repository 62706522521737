/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { formatDateWithTime } from 'utils/dateFns';
import { useTenant } from 'tenancy/useTenant';
import { ReactElement } from 'react';
import { Text } from 'components';

export function LicenseInfo({ color }: { color?: string }): ReactElement | null {
	const tenant = useTenant();

	if (tenant.expires) {
		return (
			<Text variant="small" muted fontFamily="code" color={color}>
				License expiry:
				<br /> {formatDateWithTime(tenant.expires)}
			</Text>
		);
	}

	return null;
}
