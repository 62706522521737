/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { createFilterParams, UrlState } from 'pages/templates/FromTemplateModal/urlParams';
import { Tag as TemplateTag } from 'pages/templates/components/TemplateTags';
import { ActionVO, SearchObjectsVO, TargetTypeDescriptionVO } from 'ui-api';
import { Tag as ExperimentTag } from 'pages/experimentsV2/ExperimentTags';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { Container, Li, Stack, Text, Ul } from 'components';
import Environment from 'pages/components/Environment';
import { Target } from 'pages/components/Target';
import { useUrlState } from 'url/useUrlState';
import Action from 'pages/components/Action';
import { theme } from 'styles.v2/theme';
import { includes } from 'utils/string';

interface SearchObjectsProps {
	mode: 'experimentList' | 'templatePreviews' | 'templateList';
	searchObjectsResult: DataStreamResult<SearchObjectsVO>;
	targetDefinitions: TargetTypeDescriptionVO[];
	environemntIdToName: Map<string, string>;
	width: string | number | undefined;
	actionsData: ActionVO[];
	pathname: string;
	query: string;
	clearQuery: () => void;
}

export default function SearchObjects({
	searchObjectsResult,
	environemntIdToName,
	targetDefinitions,
	actionsData,
	pathname,
	query,
	width,
	mode,
	clearQuery,
}: SearchObjectsProps): ReactElement {
	const [{ environmentIdParam, tagsParam, actionsParam, targetTypesParam, freeTextPhrasesParam, pageParam }] = useState(
		() => createFilterParams(pathname),
	);
	const [
		{
			environmentIds: environmentIdsFromUrl,
			targetTypes: targetTypesFromUrl,
			actions: actionsFromUrl,
			tags: tagsFromUrl,
			freeTextPhrases,
		},
		,
		updateUrlState,
	] = useUrlState<UrlState>([
		freeTextPhrasesParam,
		environmentIdParam,
		targetTypesParam,
		actionsParam,
		pageParam,
		tagsParam,
	]);

	const tags = (searchObjectsResult.value?.tags || []).filter((v) => !tagsFromUrl.includes(v) && includes(v, query));

	const environments = (searchObjectsResult.value?.environmentIds || [])
		.filter((id) => !environmentIdsFromUrl.includes(id))
		.map((id) => ({ id, name: environemntIdToName.get(id) || '' }))
		.filter(({ name }) => name !== '' && includes(name, query));

	const targetTypes: TargetTypeDescriptionVO[] = (searchObjectsResult.value?.targetTypes || [])
		.filter((targetType) => !targetTypesFromUrl.includes(targetType))
		.map((targetType) => targetDefinitions.find((t) => t.id === targetType))
		.filter((tt) => tt !== undefined && includes(tt.label.one, query)) as TargetTypeDescriptionVO[];

	const actions: ActionVO[] = (searchObjectsResult.value?.actions || [])
		.filter((action) => !actionsFromUrl.includes(action))
		.map((action) => actionsData.find((actionData) => actionData.id === action))
		.filter((action) => action !== undefined && includes(action.name, query)) as ActionVO[];

	const MAX_ITEMS_PER_CATEGORY = 10;

	const renderedTags = tags.slice(0, MAX_ITEMS_PER_CATEGORY);
	const renderedEnvironments = environments.slice(0, MAX_ITEMS_PER_CATEGORY);
	const renderedTargetTypes = targetTypes.slice(0, MAX_ITEMS_PER_CATEGORY);
	const renderedActions = actions.slice(0, MAX_ITEMS_PER_CATEGORY);
	const numTags = renderedTags.length;
	const numEnvironments = renderedEnvironments.length;
	const numTargetTypes = renderedTargetTypes.length;
	const numActions = renderedActions.length;
	const hoveredIndex = useKeyboardNavigation(
		(i) => {
			if (i < 0 || i >= numTags + numEnvironments + numTargetTypes + numActions) {
				if (query) {
					updateUrlState({ page: 0, freeTextPhrases: [...freeTextPhrases, query] });
					clearQuery();
				}
				return;
			}

			if (i < numTags) {
				const selectedItem = renderedTags[i];
				updateUrlState({ page: 0, tags: [...tagsFromUrl, selectedItem] });
				return clearQuery();
			} else if (i < numTags + numEnvironments) {
				const selectedItem = renderedEnvironments[i - numTags];
				updateUrlState({ page: 0, environmentIds: [...environmentIdsFromUrl, selectedItem.id] });
				return clearQuery();
			}
			if (i < numTags + numEnvironments + numTargetTypes) {
				const selectedItem = renderedTargetTypes[i - numTags - numEnvironments];
				updateUrlState({ page: 0, targetTypes: [...targetTypesFromUrl, selectedItem.id] });
				return clearQuery();
			}
			if (i < numTags + numEnvironments + numTargetTypes + numActions) {
				const selectedItem = renderedActions[i - numTags - numEnvironments - numTargetTypes];
				updateUrlState({ page: 0, actions: [...actionsFromUrl, selectedItem.id] });
				return clearQuery();
			}
		},
		{ numTags, numEnvironments, numTargetTypes, numActions, hasQuery: Boolean(query) },
	);

	return (
		<Stack p="small" width="700px" maxWidth={width}>
			{tags.length > 0 && (
				<Stack size="xSmall">
					<Text variant="small" color="neutral600">
						TAGS
					</Text>
					<ListWrapper withMargin={false}>
						{renderedTags.map((tag, index) => {
							const TagComponent = mode === 'experimentList' ? ExperimentTag : TemplateTag;

							return (
								<ListItem key={tag} isHighlighted={hoveredIndex === index} borderRadius={4}>
									<TagComponent
										onClick={() => {
											updateUrlState({ page: 0, tags: [...tagsFromUrl, tag] });
											clearQuery();
										}}
									>
										{tag}
									</TagComponent>
								</ListItem>
							);
						})}
						{tags.length > MAX_ITEMS_PER_CATEGORY && <MoreItems numberMore={tags.length - MAX_ITEMS_PER_CATEGORY} />}
					</ListWrapper>
					<Divider />
				</Stack>
			)}
			{environments.length > 0 && (
				<Stack size="xSmall">
					<Text variant="small" color="neutral600">
						ENVIRONMENTS
					</Text>
					<ListWrapper>
						{renderedEnvironments.map(({ id, name }, index) => {
							const indexOffset = numTags;

							return (
								<ListItem key={id} isHighlighted={hoveredIndex === indexOffset + index} borderRadius={16}>
									<Environment
										onClick={() => {
											updateUrlState({ page: 0, environmentIds: [...environmentIdsFromUrl, id] });
											clearQuery();
										}}
									>
										{name}
									</Environment>
								</ListItem>
							);
						})}
						{environments.length > MAX_ITEMS_PER_CATEGORY && (
							<MoreItems numberMore={environments.length - MAX_ITEMS_PER_CATEGORY} />
						)}
					</ListWrapper>
					<Divider />
				</Stack>
			)}
			{targetTypes.length > 0 && (
				<Stack size="xSmall">
					<Text variant="small" color="neutral600">
						TARGET TYPES
					</Text>
					<ListWrapper>
						{renderedTargetTypes.map((targetType, index) => {
							const indexOffset = numTags + numEnvironments;

							return (
								<ListItem key={targetType.id} isHighlighted={hoveredIndex === indexOffset + index} borderRadius={16}>
									<Target
										targetId={targetType.id}
										targetDefinitions={targetDefinitions}
										onClick={() => {
											updateUrlState({
												page: 0,
												targetTypes: [...targetTypesFromUrl, targetType.id],
											});
											clearQuery();
										}}
									/>
								</ListItem>
							);
						})}
						{targetTypes.length > MAX_ITEMS_PER_CATEGORY && (
							<MoreItems numberMore={targetTypes.length - MAX_ITEMS_PER_CATEGORY} />
						)}
					</ListWrapper>
					<Divider />
				</Stack>
			)}
			{actions.length > 0 && (
				<Stack size="xSmall">
					<Text variant="small" color="neutral600">
						ACTIONS
					</Text>
					<ListWrapper>
						{renderedActions.map((action, index) => {
							const indexOffset = numTags + numEnvironments + numTargetTypes;

							return (
								<ListItem key={action.id} isHighlighted={hoveredIndex === indexOffset + index} borderRadius={6}>
									<Action
										actionsData={actionsData}
										action={action.id}
										onClick={() => {
											updateUrlState({ page: 0, actions: [...actionsFromUrl, action.id] });
											clearQuery();
										}}
									/>
								</ListItem>
							);
						})}
						{actions.length > MAX_ITEMS_PER_CATEGORY && (
							<MoreItems numberMore={actions.length - MAX_ITEMS_PER_CATEGORY} />
						)}
					</ListWrapper>
					{query && <Divider />}
				</Stack>
			)}
			{query && (
				<Stack size="xSmall">
					<Text variant="small" color="neutral600">
						Show everything that contains
					</Text>

					{query && (
						<ListWrapper>
							<ListItem
								key="add_as_free_text"
								isHighlighted={hoveredIndex === numTags + numEnvironments + numActions + numTargetTypes}
								borderRadius={6}
							>
								<Container
									onClick={() => {
										updateUrlState({ page: 0, freeTextPhrases: [...freeTextPhrases, query] });
										clearQuery();
									}}
									sx={{
										width: 'fit-content',
										cursor: 'pointer',
										px: 'xSmall',
										py: 'xxSmall',
										borderRadius: '4px',
										'&:hover': {
											backgroundColor: 'neutral200',
										},
									}}
									data-cy="create-free-text"
								>
									<Text variant="smallStrong" color="neutral600">
										“{query}“
									</Text>
								</Container>
							</ListItem>
						</ListWrapper>
					)}
				</Stack>
			)}
		</Stack>
	);
}

function Divider(): ReactElement {
	return <div style={{ height: '1px', backgroundColor: theme.colors.neutral300 }} />;
}

function MoreItems({ numberMore }: { numberMore: number }): ReactElement {
	return (
		<ListItem>
			<Text as="span" variant="small" sx={{ px: 'xSmall' }}>{`+${numberMore} more`}</Text>
		</ListItem>
	);
}

function ListWrapper({
	withMargin = true,
	children,
}: {
	children: ReactNode | ReactNode[];
	withMargin?: boolean;
}): ReactElement {
	return (
		<Ul
			sx={{
				display: 'flex',
				flexWrap: 'wrap',
				alignItems: 'center',
				gap: '4px',
				'& > li': {
					mb: withMargin ? 'xSmall' : undefined,
				},
			}}
		>
			{children}
		</Ul>
	);
}

interface ListItemProps {
	isHighlighted?: boolean;
	borderRadius?: number;
	children: ReactNode;
}

function ListItem({ children, isHighlighted = false, borderRadius = 0 }: ListItemProps): ReactElement {
	return (
		<Li
			sx={{
				borderRadius: borderRadius + 'px',
				border: '2px solid ' + (isHighlighted ? theme.colors.aqua700 : 'transparent'),
			}}
			onKeyDown={
				isHighlighted
					? (e) => {
							e.preventDefault();
						}
					: undefined
			}
		>
			{children}
		</Li>
	);
}

function useKeyboardNavigation(
	onSelect: (index: number) => void,
	config: {
		numEnvironments: number;
		numTargetTypes: number;
		numActions: number;
		hasQuery: boolean;
		numTags: number;
	},
): number | undefined {
	const { numTags, numEnvironments, numTargetTypes, numActions, hasQuery } = config;
	const numberItems = numTags + numEnvironments + numTargetTypes + numActions + (hasQuery ? 1 : 0);

	const [hoveredIndex, setHoveredIndex] = useState<number | undefined>(undefined);
	useEffect(() => setHoveredIndex(undefined), [numberItems]);

	const keyDownListener = (event: KeyboardEvent): void => {
		if (numberItems === 0) {
			return;
		}

		if (event.key === 'Tab' || event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
		}
		if (event.key === 'ArrowDown') {
			const newIndex = (hoveredIndex ?? -1) + 1;
			setHoveredIndex(Math.min(newIndex, numberItems - 1));
		} else if (event.key === 'ArrowUp') {
			const newIndex = (hoveredIndex ?? numberItems) - 1;
			setHoveredIndex(Math.max(0, newIndex));
		} else if (event.key === 'Enter') {
			onSelect(hoveredIndex ?? -1);
		} else if (event.key === 'Tab') {
			if (hoveredIndex == undefined) {
				setHoveredIndex(0);
			}

			const jumpSteps = Array.from(
				new Set([
					0,
					numTags,
					numTags + numEnvironments,
					numTags + numEnvironments + numTargetTypes,
					numTags + numEnvironments + numTargetTypes + numActions,
				]),
			);

			if (event.shiftKey) {
				const prevJumpStep = jumpSteps.reverse().find((step) => step < (hoveredIndex ?? -1));
				setHoveredIndex(prevJumpStep);
			} else {
				const nextJumpStep = jumpSteps.find((step) => step > (hoveredIndex ?? -1));
				setHoveredIndex(nextJumpStep ?? 0);
			}
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', keyDownListener, true);
		return () => window.removeEventListener('keydown', keyDownListener, true);
	}, [keyDownListener]);

	return hoveredIndex;
}
