/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { AnimatePresence, motion } from 'framer-motion';
import { Container, ContainerProps } from 'components';
import React from 'react';

import { IconArrowLeft, IconChevronRight } from '../icons';
import { ButtonLink } from '../ButtonLink';
import { Button } from '../Button';
import { Text } from '../Text';

interface SelectOptionGroupProps extends ContainerProps {
	label: string;
	inActivePath?: boolean;
	vertical?: boolean;
	active?: boolean;
	onLabelClick?: React.MouseEventHandler<HTMLButtonElement>;
	onBackClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const SelectOptionGroup: React.FunctionComponent<SelectOptionGroupProps> = React.forwardRef(
	({ children, label, onLabelClick, onBackClick, active, vertical = false }, ref) => {
		return (
			<Container
				data-select-option-group
				sx={{
					'[data-select-option-group-list] > [data-select-option-group]': {
						ml: 'small',
					},
					'& + &': {
						borderTop: '1px solid',
						borderColor: 'neutral200',
					},
					overflow: vertical ? 'hidden' : 'initial',
				}}
			>
				{vertical ? (
					<>
						<Button
							variant="chromeless"
							color="neutral800"
							width={1}
							px={12}
							py="xSmall"
							onClick={onLabelClick}
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								cursor: 'pointer',

								':hover': { bg: 'neutral100' },
								':focus': { bg: 'neutral100', outline: 'none' },
							}}
						>
							<Text color="neutral800" data-select-option-group-text>
								{label}
							</Text>
							<IconChevronRight color="neutral400" variant={'small'} />
						</Button>
						<AnimatePresence>
							{active && (
								<motion.div
									transition={{ bounce: 0.5 }}
									initial={{ opacity: 0, x: '50%' }}
									animate={{ opacity: 1, x: 0 }}
									exit={{ opacity: 0, x: '50%' }}
									style={{
										position: 'absolute',
										left: 0,
										top: -8,
										right: 0,
										bottom: -8,
										willChange: 'opacity, transform',
									}}
								>
									<Container
										as="ul"
										ref={ref}
										bg="neutral000"
										px="none"
										m="none"
										sx={{
											transform: 'translateX(100%)',
										}}
									>
										<ButtonLink
											icon={IconArrowLeft}
											variant="chromeless"
											onClick={onBackClick}
											py="xSmall"
											sx={{
												width: '100%',
												textAlign: 'left',
												justifyContent: 'flex-start',
												borderBottom: '1px solid',
												borderColor: 'neutral200',
											}}
										>
											<Text
												textAlign="center"
												color="neutral800"
												ml="50%"
												data-select-option-group-text
												sx={{
													transform: 'translateX(calc(-50% - 32px))',
												}}
											>
												{label}
											</Text>
										</ButtonLink>
										{children}
									</Container>
								</motion.div>
							)}
						</AnimatePresence>
					</>
				) : (
					<>
						<Text variant="xSmallStrong" color="neutral600" px={12} pt={12} pb={4}>
							{label}
						</Text>
						<Container as="ul" ref={ref} p="none" m="none" data-select-option-group-list>
							{children}
						</Container>
					</>
				)}
			</Container>
		);
	},
);
SelectOptionGroup.displayName = 'SelectOptionGroup';
