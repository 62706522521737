/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { useAsyncState } from 'utils/hooks/useAsyncState';
import { Services } from 'services/services';
import { Select } from 'components';
import React from 'react';

export const TeamSelect: React.VFC<{ value?: string; onChange: (value: string) => void }> = ({ value, onChange }) => {
	const [teams] = useAsyncState(
		async () =>
			(await Services.teams.findTeams('', false))
				.sort((a, b) => (a.name > b.name ? 1 : -1))
				.map((t) => ({
					label: `${t.name} (${t.key})`,
					value: t.id,
				})),
		[],
		[],
	);
	return (
		<Select
			data-cy="team-select"
			placeholder={'none'}
			variant={'chromeless'}
			width={200}
			value={teams.value.find((t) => value === t.value) ?? null}
			options={teams.value}
			onChange={(option) => {
				if (option && 'value' in option && option.value !== value) {
					onChange(option.value);
				}
			}}
		/>
	);
};
