/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	Container,
	RouterButtonIcon,
	RouterLink,
	Snackbar,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	Text,
	Toggle,
	Tooltip,
} from 'components';
import React, { ReactElement, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { usePromiseWithReExecution } from 'utils/hooks/usePromiseWithReExecution';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import useGlobalPermissions from 'services/useGlobalPermissions';
import { AdviceValidationStateVO, TeamSummaryVO } from 'ui-api';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import StateBadge from 'components/StateBadge/StateBadge';
import { useDimensions } from 'utils/hooks/useDimensions';
import { usePromise } from 'utils/hooks/usePromise';
import Markdown from 'components/Markdown/Markdown';
import { formatDateWithTime } from 'utils/dateFns';
import { IconExperiment } from 'components/icons';
import { PageParams } from 'utils/hooks/usePage';
import { Services } from 'services/services';
import { debounce } from 'lodash';

import { useEnvironments, UseEnvironmentsResult } from '../../utils/hooks/useEnvironments';
import Collapsible from '../../components/Collapsible.v2/Collapsible.v2';
import textEllipsis from '../../utils/styleSnippets/textEllipsis';
import { useTeam } from '../../services/useTeam';

interface TargetAdviceTableProps {
	environmentId: string | null;
	adviceDefinitionId: string;
	targetReference: string;
	type: string;
}

export default function TargetAdviceTable({
	adviceDefinitionId,
	targetReference,
	environmentId,
	type,
}: TargetAdviceTableProps): ReactElement | null {
	const [validationsPerformedCollapsed, setValidationsPerformedCollapsed] = useState(true);
	const [reloadSignal, setReloadSignal] = useState(0);
	const adviceValidationsResult = usePromise(
		() => Services.adviceApi.getAdviceValidations(type, targetReference, adviceDefinitionId),
		[adviceDefinitionId, reloadSignal],
	);
	const [lastStableValidations, setLastStableValidations] = useState<AdviceValidationStateVO[]>([]);

	useEventEffect(
		(e) => {
			if (lastStableValidations.map((e) => e.experimentKey).includes(e.experimentKey)) {
				setReloadSignal(reloadSignal + 1);
			}
		},
		['experiment.deleted'],
		() => {},
		[adviceDefinitionId, reloadSignal, lastStableValidations],
	);

	useEffect(() => {
		if (adviceValidationsResult.value) {
			setLastStableValidations(adviceValidationsResult.value);
		}
	}, [adviceValidationsResult.value]);

	useEffect(() => {
		setValidationsPerformedCollapsed(true);
	}, [adviceDefinitionId]);

	if (lastStableValidations.length === 0) {
		return null;
	}

	if (adviceValidationsResult.loading) {
		return <LoadingIndicator variant="small" />;
	} else if (adviceValidationsResult.value?.every((validationResult) => validationResult.validated)) {
		return (
			<Collapsible
				title="Validations Performed"
				onCollapseChange={(collapsed) => setValidationsPerformedCollapsed(collapsed)}
				initialExpanded={!validationsPerformedCollapsed}
			>
				<Stack size="small">
					<TargetAdviceValidationTable
						reload={() => setReloadSignal(reloadSignal + 1)}
						adviceDefinitionId={adviceDefinitionId}
						validations={lastStableValidations}
						targetReference={targetReference}
						environmentId={environmentId}
						type={type}
					/>
				</Stack>
			</Collapsible>
		);
	}
	return (
		<Stack size="small">
			<Text variant="mediumStrong">Recommended Validations</Text>
			<TargetAdviceValidationTable
				reload={() => setReloadSignal(reloadSignal + 1)}
				adviceDefinitionId={adviceDefinitionId}
				validations={lastStableValidations}
				targetReference={targetReference}
				environmentId={environmentId}
				type={type}
			/>
		</Stack>
	);
}

interface TargetAdviceValidationTableProps {
	validations: AdviceValidationStateVO[];
	environmentId: string | null;
	adviceDefinitionId: string;
	targetReference: string;
	reload: () => void;
	type: string;
}

function TargetAdviceValidationTable({
	adviceDefinitionId,
	targetReference,
	environmentId,
	validations,
	reload,
	type,
}: TargetAdviceValidationTableProps): ReactElement | null {
	const team = useTeam();
	const environments = useEnvironments(environmentId || undefined);

	const [divRef, [tableWidth]] = useDimensions<HTMLDivElement>();

	return (
		<Container ref={divRef}>
			<Table
				sx={{
					'& td, & th': {
						verticalAlign: 'top',
					},
				}}
			>
				<TableHead>
					<TableRow>
						<TableHeadCell width={64}>Validated</TableHeadCell>
						<TableHeadCell width={tableWidth - (240 + 64)}>Validation Name</TableHeadCell>
						<TableHeadCell width={240}></TableHeadCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{validations.map((validation) => {
						if (validation.type === 'EXPERIMENT') {
							return (
								<ExperimentRow
									key={validation.id}
									adviceDefinitionId={adviceDefinitionId}
									targetReference={targetReference}
									environments={environments}
									validation={validation}
									tableWidth={tableWidth}
									reload={reload}
									type={type}
									team={team}
								/>
							);
						} else if (validation.type === 'TEXT') {
							return (
								<ValidationTextRow
									key={validation.id}
									adviceDefinitionId={adviceDefinitionId}
									targetReference={targetReference}
									validation={validation}
									tableWidth={tableWidth}
									type={type}
								/>
							);
						}
					})}
				</TableBody>
			</Table>
		</Container>
	);
}

interface ExperimentRowProps {
	validation: AdviceValidationStateVO;
	environments: UseEnvironmentsResult;
	adviceDefinitionId: string;
	targetReference: string;
	tableWidth: number;
	team: TeamSummaryVO;
	reload: () => void;
	type: string;
}

interface ValidationRowProps {
	validation: AdviceValidationStateVO;
	adviceDefinitionId: string;
	targetReference: string;
	tableWidth: number;
	type: string;
}

function Description(props: { description: string; shortDescription: string }): ReactElement {
	const shortDescriptionRef = useRef<HTMLDivElement>(null);
	const [showReadMore, setShowReadMore] = React.useState<boolean>(false);
	const [renderFullDescription, setRenderFullDescription] = useState(false);

	useLayoutEffect(() => {
		const { current } = shortDescriptionRef;
		const shortDescriptionEllipsed = (current?.offsetWidth ?? 0) < (current?.scrollWidth ?? 0);
		setShowReadMore(shortDescriptionEllipsed || !!props.description);
	}, [props.description, shortDescriptionRef?.current?.scrollWidth, shortDescriptionRef?.current?.offsetWidth]);

	return (
		<>
			{renderFullDescription ? (
				<Stack size="xSmall">
					{props.description ? (
						<Text variant="small" as="span" color="neutral600">
							<Markdown content={props.description} />
						</Text>
					) : (
						<Text variant="small" as="span" color="neutral600">
							{props.shortDescription}
						</Text>
					)}
					<ReadButton onClick={() => setRenderFullDescription(false)}>Read less</ReadButton>
				</Stack>
			) : (
				<Stack direction="horizontal" size="xSmall">
					<Text as="span" variant="small" sx={{ ...textEllipsis, color: 'neutral600' }} ref={shortDescriptionRef}>
						{props.shortDescription}
					</Text>
					{showReadMore ? <ReadButton onClick={() => setRenderFullDescription(true)}>Read more</ReadButton> : null}
				</Stack>
			)}
		</>
	);
}

function ExperimentRow({
	adviceDefinitionId,
	targetReference,
	environments,
	tableWidth,
	validation,
	reload,
	type,
	team,
}: ExperimentRowProps): ReactElement {
	const permissions = useGlobalPermissions();

	const [experimentExecutions, refetch] = usePromiseWithReExecution(
		() =>
			validation.experimentKey
				? Services.experiments.fetchExperimentRuns(
						new URLSearchParams(),
						new PageParams(0, 1),
						validation.experimentKey,
					)
				: Promise.resolve(null),
		[validation.experimentKey],
	);
	const debouncedFetch = useMemo(() => debounce(refetch, 100), [fetch]);
	useEventEffect(
		(e) => {
			if (e.experimentKey === validation.experimentKey) {
				debouncedFetch();
			}
		},
		[
			'experiment.execution.failed',
			'experiment.execution.errored',
			'experiment.execution.canceled',
			'experiment.execution.completed',
			'experiment.execution.deleted',
		],
		debouncedFetch.cancel,
	);
	const lastRun = experimentExecutions.value?.content[0];

	return (
		<TableRow key={validation.id} hoverable={true}>
			<TableDataCell justifyContent="center">
				<Toggle
					checked={validation.validated}
					onChange={async (e) => {
						await Services.adviceApi.updateAdviceExperimentState(type, targetReference, adviceDefinitionId, {
							...validation,
							validated: e.target.checked,
						});
					}}
				/>
			</TableDataCell>
			<TableDataCell maxWidth={tableWidth - 350}>
				<Stack size="none" py="xSmall">
					<ExperimentWrapper experimentKey={validation.experimentKey}>
						<>
							{validation.experimentKey && (
								<>
									<Text as="span" variant="mediumStrong">
										{validation.experimentKey}
									</Text>
									&nbsp;
								</>
							)}
							<Text as="span" variant="medium">
								{validation.name}
							</Text>
						</>
					</ExperimentWrapper>
					<Description description={validation.description} shortDescription={validation.shortDescription} />
				</Stack>
			</TableDataCell>
			<TableDataCell justifyContent="flex-end">
				{validation.experimentKey ? (
					<Stack direction="horizontal" size="small" alignItems="center">
						<RouterButtonIcon
							to={`/experiments/edit/${validation.experimentKey}${lastRun ? '/executions/' + lastRun.id : '/design'}`}
							tooltip={`Go to experiment ${validation.experimentKey}`}
							variant="secondary"
						>
							<IconExperiment />
						</RouterButtonIcon>
						{lastRun && (
							<Container display={'flex'} flexDirection={'column'}>
								<Container maxWidth={150}>
									<StateBadge as="state" value={lastRun.state} />
								</Container>
								<RouterLink mr={'xSmall'} to={`/experiments/edit/${lastRun.experimentKey}/executions`}>
									<Text variant={'small'} sx={{ color: 'neutral600', fontVariantNumeric: 'tabular-nums' }}>
										{`${formatDateWithTime(lastRun.created)} `}
									</Text>
								</RouterLink>
							</Container>
						)}
					</Stack>
				) : (
					<Tooltip
						content={
							!permissions.experiments.canCreate
								? 'You need to be a member of the Team to create new experiments.'
								: undefined
						}
					>
						<Button
							px="xSmall"
							variant="primarySmall"
							disabled={!permissions.experiments.canCreate}
							onClick={async (e) => {
								try {
									await Services.adviceApi.createExperiment(
										type,
										targetReference,
										adviceDefinitionId,
										validation.id,
										team.id,
										environments.selectedEnvironment?.id || environments.defaultSelectedEnvironment?.id,
									);
									reload();
									e.stopPropagation();
								} catch (e) {
									const statusCode = e?.response?.status;
									if (statusCode === 403) {
										Snackbar.error('You are not allowed to create this experiment', {
											toastId: 'create-advice-experiment-error',
										});
									} else {
										Snackbar.error('Experiment could not be created', {
											toastId: 'create-advice-experiment-error',
										});
									}
								}
							}}
						>
							<IconExperiment mr="xSmall" />
							Create Experiment
						</Button>
					</Tooltip>
				)}
			</TableDataCell>
		</TableRow>
	);
}

function ValidationTextRow({
	adviceDefinitionId,
	targetReference,
	tableWidth,
	validation,
	type,
}: ValidationRowProps): ReactElement {
	return (
		<TableRow key={validation.id} hoverable={true}>
			<TableDataCell maxWidth={64}>
				<Toggle
					checked={validation.validated}
					onChange={async (e) => {
						await Services.adviceApi.updateAdviceExperimentState(type, targetReference, adviceDefinitionId, {
							...validation,
							validated: e.target.checked,
						});
					}}
				/>
			</TableDataCell>
			<TableDataCell maxWidth={tableWidth - 66} colSpan={2}>
				<Stack size="none" py="xSmall">
					<Text as="span" variant="medium" muted={validation.validated}>
						{validation.name}
					</Text>
					<Description description={validation.description} shortDescription={validation.shortDescription} />
				</Stack>
			</TableDataCell>
		</TableRow>
	);
}

interface ExperimentWrapperProps {
	children: ReactElement;
	experimentKey: string | undefined;
}

function ExperimentWrapper({ children, experimentKey }: ExperimentWrapperProps): ReactElement | null {
	if (experimentKey) {
		return (
			<RouterLink
				to={`/experiments/edit/${experimentKey}`}
				sx={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
				}}
			>
				{children}
			</RouterLink>
		);
	}
	return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>{children}</div>;
}

function ReadButton({ children, onClick }: { children: string; onClick: () => void }): ReactElement {
	return (
		<Text
			as="span"
			variant="smallStrong"
			onClick={onClick}
			sx={{
				width: '120px',
				whiteSpace: 'nowrap',
				color: 'slate',
				cursor: 'pointer',
				'&:hover': {
					textDecoration: 'underline',
				},
			}}
		>
			{children}
		</Text>
	);
}
