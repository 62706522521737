/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { AnimatePresence, motion } from 'framer-motion';
import { LinkButton, Stack, Text } from 'components';
import { ReactElement } from 'react';

export default function ExtensionCallout(): ReactElement {
	const animate = {
		initial: { opacity: 0 },
		exit: { opacity: 1 },
		animate: { opacity: 1 },
		transition: { duration: 0.5, delay: 0.2 },
	};

	return (
		<AnimatePresence>
			<motion.div
				style={{
					willChange: 'opacity',
				}}
				{...animate}
			>
				<Stack alignItems="flex-start" backgroundColor="neutral000" p="small" sx={{ borderRadius: 8 }}>
					<Text variant="mediumStrong">Extend Steadybit!</Text>
					<Text muted>
						Can&apos;t find what you&apos;re looking for? You can extend Steadybit&apos;s ability to discover targets
						through extensions. Learn how to install an extension or author your own!
					</Text>
					<LinkButton
						href="https://docs.steadybit.com/integrate-with-steadybit/extensions"
						variant="secondarySmall"
						external
						target="_blank"
						data-track="get-extensions-from-target-table"
					>
						Extend Steadybit
					</LinkButton>
				</Stack>
			</motion.div>
		</AnimatePresence>
	);
}
