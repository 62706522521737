/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ReactElement, useEffect, useRef, useState } from 'react';
import { Container } from 'components';

interface DimensionWrapperProps {
	children: (dimensions: Dimensions) => ReactElement;
}

export default function DimensionWrapper({ children }: DimensionWrapperProps): ReactElement {
	const ref = useRef<HTMLDivElement>(null);
	const [htmlElement, setHtmlElement] = useState<HTMLDivElement | null>(null);

	useEffect(() => setHtmlElement(ref.current), [ref.current]);

	return (
		<Container ref={ref}>
			{htmlElement ? (
				<RefAwareDimensionWrapper htmlElement={htmlElement}>{children}</RefAwareDimensionWrapper>
			) : (
				children({ width: undefined, height: undefined })
			)}
		</Container>
	);
}

interface RefAwareDimensionWrapperProps {
	children: (dimensions: Dimensions) => ReactElement;
	htmlElement: HTMLDivElement;
}

function RefAwareDimensionWrapper({ htmlElement, children }: RefAwareDimensionWrapperProps): ReactElement {
	const dimensions = useContainerDimensions(htmlElement);
	return children(dimensions);
}

interface Dimensions {
	width: number | undefined;
	height: number | undefined;
}

function useContainerDimensions(divElement: HTMLDivElement): Dimensions {
	function getDimensions(): Dimensions {
		return {
			width: divElement.offsetWidth,
			height: divElement.offsetHeight,
		};
	}

	const [dimensions, setDimensions] = useState<Dimensions>({ width: undefined, height: undefined });
	const checkAndSetDimensions = (): void => {
		const newDimensions = getDimensions();
		if (newDimensions.width !== dimensions.width || newDimensions.height !== dimensions.height) {
			setDimensions(newDimensions);
		}
	};

	useEffect(() => {
		const handleResize = (): void => {
			checkAndSetDimensions();
		};

		if (divElement) {
			checkAndSetDimensions();
		}

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [divElement]);

	return dimensions;
}
