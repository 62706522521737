/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React, { useMemo } from 'react';

export interface RadioProps extends Omit<ContainerProps, 'css' | 'checked'> {
	checked?: boolean;
	hasError?: boolean;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	inputProps?: Partial<ContainerProps>;
}

export const Radio: React.FC<RadioProps> = ({
	checked,
	hasError = false,
	value,
	name,
	disabled = false,
	onChange,
	inputProps,
	...props
}) => {
	const borderColor = useMemo(() => {
		if (hasError) {
			return 'coral';
		}
		if (disabled) {
			return 'neutral400';
		}
		if (checked) {
			return 'slate';
		}

		return 'neutral400';
	}, [hasError, checked, disabled]);
	const bg = useMemo(() => {
		if (disabled && !checked) {
			return 'neutral100';
		}

		return 'neutral000';
	}, [checked, disabled]);

	return (
		<Container
			sx={{
				position: 'relative',
				borderRadius: '50%',
				border: '2px solid',
				borderColor,
				bg,
				':hover': {
					borderColor: 'slate',
				},
				':focus-within': {
					borderColor: 'neutral800',
				},
			}}
			width={18}
			height={18}
			p={3}
			{...props}
		>
			<Container
				width={8}
				height={8}
				bg={disabled ? 'neutral400' : 'slate'}
				sx={{ borderRadius: '50%', transform: `scale(${checked ? 1 : 0})` }}
			/>
			<Container
				as="input"
				type="radio"
				width="100%"
				height="100%"
				onChange={onChange}
				disabled={disabled}
				sx={{
					cursor: 'pointer',
					opacity: 0,
					display: 'block',
					position: 'absolute',
					left: 0,
					right: 0,
					bottom: 0,
					top: 0,
				}}
				name={name}
				value={value}
				checked={!!checked}
				{...inputProps}
			/>
		</Container>
	);
};
