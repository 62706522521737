/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ExperimentScheduleVO } from 'ui-api';
import { ReactElement } from 'react';

import ScheduleExperimentModal from './ScheduleExperimentModal';

interface EditExperimentScheduleModalProps {
	schedule: ExperimentScheduleVO;
	onClose: () => void;
	disabled: boolean;
}

export default function EditExperimentScheduleModal({
	schedule,
	onClose,
	disabled,
}: EditExperimentScheduleModalProps): ReactElement {
	return (
		<ScheduleExperimentModal
			schedule={schedule}
			title={`Edit Schedule for Experiment ${schedule.experimentKey}`}
			onClose={onClose}
			disabled={disabled}
		/>
	);
}
