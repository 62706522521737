/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { smellsLikeEnvVar, smellsLikeTemplatePlaceholder } from 'components';
import { parseDuration } from 'utils/duration';
import { ReactElement } from 'react';

import { Options } from './Common';
import { Input } from './Integer';

interface DurationProps {
	hasErrors?: boolean;
	disabled: boolean;
	value: string;
	setValue: (value: string) => void;
}

export default function Duration({ value, hasErrors, disabled, setValue }: DurationProps): ReactElement {
	const { value: duration, unit } = parseDuration(value);

	let v: string = duration.toString();
	if (smellsLikeEnvVar(value) || smellsLikeTemplatePlaceholder(value)) {
		v = value;
	}

	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Input
				value={v}
				onChange={(newDuration) => {
					if (smellsLikeEnvVar(newDuration)) {
						setValue(String(newDuration));
					} else if (smellsLikeTemplatePlaceholder(newDuration)) {
						setValue(String(newDuration));
					} else {
						setValue(newDuration + unit.value);
					}
				}}
				hasErrors={hasErrors}
				disabled={disabled}
				withUnit
			/>
			<Options
				value={unit.label}
				options={[
					{ value: 'ms', label: 'MilliSec.' },
					{ value: 's', label: 'Seconds' },
					{ value: 'm', label: 'Minutes' },
				]}
				onChange={(v) => {
					setValue(duration + v);
				}}
				disabled={disabled}
			/>
		</div>
	);
}
