/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, ModalOverlay } from 'components';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { useCustomPrompt } from 'utils/hooks/useCustomPrompt';
import { useFormikContext } from 'formik';
import { LandscapeViewVO } from 'ui-api';
import { useHistory } from 'url/hooks';

import { LandscapeConfig } from './types';
import { pathSegment } from './urlParams';

interface DidNotSavePromptViewProps {
	onSave: (view: LandscapeViewVO, saveAsNewView: boolean) => void;
	saveAsNewView: boolean;
}

export default function DidNotSavePromptView({ onSave, saveAsNewView }: DidNotSavePromptViewProps): ReactElement {
	const { location } = useHistory();

	const touchedParameters = Object.keys(location.matrix[pathSegment]);
	const formIsTouched = touchedParameters.length > 0 && !containsOnlyAllowedSkippingParameters(touchedParameters);

	const formik = useFormikContext<LandscapeConfig>();
	const { action, isNew } = formik.values;

	const shouldShowPrompt = !isNew && formIsTouched && action !== 'delete' && action !== 'create';
	const { showPrompt, resolve } = useCustomPrompt(shouldShowPrompt, ({ pathname }) => {
		if (pathname === `/landscape/explore/${formik.values.id}` || pathname.startsWith('/landscape/explore/template_')) {
			return false;
		}
		return true;
	});

	return (
		<ModalOverlay open={showPrompt} onClose={() => resolve(false)}>
			<Dialog width={750}>
				<DialogHeader variant="medium" title="You have unsaved changes" onClose={() => resolve(false)} />
				<DialogContent>You applied some changed to a saved view. What do you want to do?</DialogContent>
				<DialogFooter>
					<Button variant="chromeless" onClick={() => resolve(true)} ml="auto" mr="medium" color="neutral700">
						Discard Changes
					</Button>
					<Button
						variant="primary"
						onClick={async () => {
							onSave(formik.values, saveAsNewView);
							resolve(true);
						}}
					>
						{saveAsNewView ? 'Save as new view' : 'Save view'}
					</Button>
				</DialogFooter>
			</Dialog>
		</ModalOverlay>
	);
}

function containsOnlyAllowedSkippingParameters(keys: string[]): boolean {
	return keys.every((key) => ['targetId'].includes(key));
}
