/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Global } from '@emotion/react';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

export default function GlobalStyles(): ReactElement {
	return (
		<Global
			styles={() => ({
				body: {
					margin: 0,
					fontFamily: theme.fonts.body,
					fontWeight: theme.fontWeights.body,
					lineHeight: theme.lineHeights.body,
					color: theme.colors.neutral800,
				},
			})}
		/>
	);
}
