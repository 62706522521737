// Generated from QueryLanguageParser.g4 by ANTLR 4.13.0
// jshint ignore: start
import antlr4 from 'antlr4';
import QueryLanguageParserListener from './QueryLanguageParserListener.js';
import QueryLanguageParserVisitor from './QueryLanguageParserVisitor.js';

const serializedATN = [4,1,24,91,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,4,7,4,
2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,2,9,7,9,2,10,7,10,1,0,1,0,1,0,1,1,3,1,27,
8,1,1,2,1,2,1,2,5,2,32,8,2,10,2,12,2,35,9,2,1,3,1,3,1,3,5,3,40,8,3,10,3,
12,3,43,9,3,1,4,3,4,46,8,4,1,4,1,4,1,5,1,5,1,6,1,6,1,6,1,6,1,6,1,6,1,6,1,
6,1,6,1,6,1,6,1,6,1,6,1,6,3,6,66,8,6,1,6,1,6,1,6,1,6,1,6,1,6,1,6,3,6,75,
8,6,1,7,1,7,1,7,1,7,1,8,1,8,3,8,83,8,8,1,9,1,9,3,9,87,8,9,1,10,1,10,1,10,
0,0,11,0,2,4,6,8,10,12,14,16,18,20,0,2,1,0,9,16,3,0,9,9,11,11,17,20,89,0,
22,1,0,0,0,2,26,1,0,0,0,4,28,1,0,0,0,6,36,1,0,0,0,8,45,1,0,0,0,10,49,1,0,
0,0,12,74,1,0,0,0,14,76,1,0,0,0,16,82,1,0,0,0,18,86,1,0,0,0,20,88,1,0,0,
0,22,23,3,2,1,0,23,24,5,0,0,1,24,1,1,0,0,0,25,27,3,4,2,0,26,25,1,0,0,0,26,
27,1,0,0,0,27,3,1,0,0,0,28,33,3,6,3,0,29,30,5,2,0,0,30,32,3,6,3,0,31,29,
1,0,0,0,32,35,1,0,0,0,33,31,1,0,0,0,33,34,1,0,0,0,34,5,1,0,0,0,35,33,1,0,
0,0,36,41,3,8,4,0,37,38,5,1,0,0,38,40,3,8,4,0,39,37,1,0,0,0,40,43,1,0,0,
0,41,39,1,0,0,0,41,42,1,0,0,0,42,7,1,0,0,0,43,41,1,0,0,0,44,46,3,10,5,0,
45,44,1,0,0,0,45,46,1,0,0,0,46,47,1,0,0,0,47,48,3,12,6,0,48,9,1,0,0,0,49,
50,5,3,0,0,50,11,1,0,0,0,51,52,3,16,8,0,52,53,5,4,0,0,53,54,5,5,0,0,54,75,
1,0,0,0,55,56,3,16,8,0,56,57,5,4,0,0,57,58,5,3,0,0,58,59,5,5,0,0,59,75,1,
0,0,0,60,61,3,16,8,0,61,62,7,0,0,0,62,63,3,18,9,0,63,66,1,0,0,0,64,66,3,
14,7,0,65,60,1,0,0,0,65,64,1,0,0,0,66,75,1,0,0,0,67,68,5,6,0,0,68,69,5,7,
0,0,69,70,3,16,8,0,70,71,5,8,0,0,71,72,7,1,0,0,72,73,3,18,9,0,73,75,1,0,
0,0,74,51,1,0,0,0,74,55,1,0,0,0,74,65,1,0,0,0,74,67,1,0,0,0,75,13,1,0,0,
0,76,77,5,7,0,0,77,78,3,2,1,0,78,79,5,8,0,0,79,15,1,0,0,0,80,83,3,20,10,
0,81,83,5,22,0,0,82,80,1,0,0,0,82,81,1,0,0,0,83,17,1,0,0,0,84,87,3,20,10,
0,85,87,5,22,0,0,86,84,1,0,0,0,86,85,1,0,0,0,87,19,1,0,0,0,88,89,5,21,0,
0,89,21,1,0,0,0,8,26,33,41,45,65,74,82,86];


const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

const decisionsToDFA = atn.decisionToState.map( (ds, index) => new antlr4.dfa.DFA(ds, index) );

const sharedContextCache = new antlr4.atn.PredictionContextCache();

export default class QueryLanguageParser extends antlr4.Parser {

    static grammarFileName = "QueryLanguageParser.g4";
    static literalNames = [ null, null, null, null, null, null, null, "'('", 
                            "')'", "'!='", "'!=*'", "'='", "'=*'", "'~'", 
                            "'~*'", "'!~'", "'!~*'", "'>'", "'>='", "'<'", 
                            "'<='" ];
    static symbolicNames = [ null, "AND", "OR", "NOT", "IS", "PRESENT", 
                             "COUNT", "LPAREN", "RPAREN", "OP_NOT_EQUAL", 
                             "OP_NOT_EQUAL_IGNORE_CASE", "OP_EQUAL", "OP_EQUAL_IGNORE_CASE", 
                             "OP_TILDE", "OP_TILDE_IGNORE_CASE", "OP_NOT_TILDE", 
                             "OP_NOT_TILDE_IGNORE_CASE", "OP_GREATER_THAN", 
                             "OP_GREATER_THAN_EQUAL", "OP_LESS_THAN", "OP_LESS_THAN_EQUAL", 
                             "QUOTED", "TERM", "DEFAULT_SKIP", "UNKNOWN" ];
    static ruleNames = [ "topLevelQuery", "query", "disjQuery", "conjQuery", 
                         "modClause", "modifier", "clause", "groupingExpr", 
                         "fieldName", "term", "quotedTerm" ];

    constructor(input) {
        super(input);
        this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache);
        this.ruleNames = QueryLanguageParser.ruleNames;
        this.literalNames = QueryLanguageParser.literalNames;
        this.symbolicNames = QueryLanguageParser.symbolicNames;
    }



	topLevelQuery() {
	    let localctx = new TopLevelQueryContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 0, QueryLanguageParser.RULE_topLevelQuery);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 22;
	        this.query();
	        this.state = 23;
	        this.match(QueryLanguageParser.EOF);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	query() {
	    let localctx = new QueryContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 2, QueryLanguageParser.RULE_query);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 26;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if((((_la) & ~0x1f) === 0 && ((1 << _la) & 6291656) !== 0)) {
	            this.state = 25;
	            this.disjQuery();
	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	disjQuery() {
	    let localctx = new DisjQueryContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 4, QueryLanguageParser.RULE_disjQuery);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 28;
	        this.conjQuery();
	        this.state = 33;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===2) {
	            this.state = 29;
	            this.match(QueryLanguageParser.OR);
	            this.state = 30;
	            this.conjQuery();
	            this.state = 35;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	conjQuery() {
	    let localctx = new ConjQueryContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 6, QueryLanguageParser.RULE_conjQuery);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 36;
	        this.modClause();
	        this.state = 41;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===1) {
	            this.state = 37;
	            this.match(QueryLanguageParser.AND);
	            this.state = 38;
	            this.modClause();
	            this.state = 43;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	modClause() {
	    let localctx = new ModClauseContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 8, QueryLanguageParser.RULE_modClause);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 45;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===3) {
	            this.state = 44;
	            this.modifier();
	        }

	        this.state = 47;
	        this.clause();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	modifier() {
	    let localctx = new ModifierContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 10, QueryLanguageParser.RULE_modifier);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 49;
	        this.match(QueryLanguageParser.NOT);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	clause() {
	    let localctx = new ClauseContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 12, QueryLanguageParser.RULE_clause);
	    var _la = 0;
	    try {
	        this.state = 74;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,5,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 51;
	            this.fieldName();
	            this.state = 52;
	            this.match(QueryLanguageParser.IS);
	            this.state = 53;
	            this.match(QueryLanguageParser.PRESENT);
	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 55;
	            this.fieldName();
	            this.state = 56;
	            this.match(QueryLanguageParser.IS);
	            this.state = 57;
	            this.match(QueryLanguageParser.NOT);
	            this.state = 58;
	            this.match(QueryLanguageParser.PRESENT);
	            break;

	        case 3:
	            this.enterOuterAlt(localctx, 3);
	            this.state = 65;
	            this._errHandler.sync(this);
	            switch(this._input.LA(1)) {
	            case 21:
	            case 22:
	                this.state = 60;
	                this.fieldName();
	                this.state = 61;
	                _la = this._input.LA(1);
	                if(!((((_la) & ~0x1f) === 0 && ((1 << _la) & 130560) !== 0))) {
	                this._errHandler.recoverInline(this);
	                }
	                else {
	                	this._errHandler.reportMatch(this);
	                    this.consume();
	                }
	                this.state = 62;
	                this.term();
	                break;
	            case 7:
	                this.state = 64;
	                this.groupingExpr();
	                break;
	            default:
	                throw new antlr4.error.NoViableAltException(this);
	            }
	            break;

	        case 4:
	            this.enterOuterAlt(localctx, 4);
	            this.state = 67;
	            this.match(QueryLanguageParser.COUNT);
	            this.state = 68;
	            this.match(QueryLanguageParser.LPAREN);
	            this.state = 69;
	            this.fieldName();
	            this.state = 70;
	            this.match(QueryLanguageParser.RPAREN);
	            this.state = 71;
	            _la = this._input.LA(1);
	            if(!((((_la) & ~0x1f) === 0 && ((1 << _la) & 1968640) !== 0))) {
	            this._errHandler.recoverInline(this);
	            }
	            else {
	            	this._errHandler.reportMatch(this);
	                this.consume();
	            }
	            this.state = 72;
	            this.term();
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	groupingExpr() {
	    let localctx = new GroupingExprContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 14, QueryLanguageParser.RULE_groupingExpr);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 76;
	        this.match(QueryLanguageParser.LPAREN);
	        this.state = 77;
	        this.query();
	        this.state = 78;
	        this.match(QueryLanguageParser.RPAREN);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	fieldName() {
	    let localctx = new FieldNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 16, QueryLanguageParser.RULE_fieldName);
	    try {
	        this.state = 82;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 21:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 80;
	            this.quotedTerm();
	            break;
	        case 22:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 81;
	            this.match(QueryLanguageParser.TERM);
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	term() {
	    let localctx = new TermContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 18, QueryLanguageParser.RULE_term);
	    try {
	        this.state = 86;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 21:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 84;
	            this.quotedTerm();
	            break;
	        case 22:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 85;
	            this.match(QueryLanguageParser.TERM);
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	quotedTerm() {
	    let localctx = new QuotedTermContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 20, QueryLanguageParser.RULE_quotedTerm);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 88;
	        this.match(QueryLanguageParser.QUOTED);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}


}

QueryLanguageParser.EOF = antlr4.Token.EOF;
QueryLanguageParser.AND = 1;
QueryLanguageParser.OR = 2;
QueryLanguageParser.NOT = 3;
QueryLanguageParser.IS = 4;
QueryLanguageParser.PRESENT = 5;
QueryLanguageParser.COUNT = 6;
QueryLanguageParser.LPAREN = 7;
QueryLanguageParser.RPAREN = 8;
QueryLanguageParser.OP_NOT_EQUAL = 9;
QueryLanguageParser.OP_NOT_EQUAL_IGNORE_CASE = 10;
QueryLanguageParser.OP_EQUAL = 11;
QueryLanguageParser.OP_EQUAL_IGNORE_CASE = 12;
QueryLanguageParser.OP_TILDE = 13;
QueryLanguageParser.OP_TILDE_IGNORE_CASE = 14;
QueryLanguageParser.OP_NOT_TILDE = 15;
QueryLanguageParser.OP_NOT_TILDE_IGNORE_CASE = 16;
QueryLanguageParser.OP_GREATER_THAN = 17;
QueryLanguageParser.OP_GREATER_THAN_EQUAL = 18;
QueryLanguageParser.OP_LESS_THAN = 19;
QueryLanguageParser.OP_LESS_THAN_EQUAL = 20;
QueryLanguageParser.QUOTED = 21;
QueryLanguageParser.TERM = 22;
QueryLanguageParser.DEFAULT_SKIP = 23;
QueryLanguageParser.UNKNOWN = 24;

QueryLanguageParser.RULE_topLevelQuery = 0;
QueryLanguageParser.RULE_query = 1;
QueryLanguageParser.RULE_disjQuery = 2;
QueryLanguageParser.RULE_conjQuery = 3;
QueryLanguageParser.RULE_modClause = 4;
QueryLanguageParser.RULE_modifier = 5;
QueryLanguageParser.RULE_clause = 6;
QueryLanguageParser.RULE_groupingExpr = 7;
QueryLanguageParser.RULE_fieldName = 8;
QueryLanguageParser.RULE_term = 9;
QueryLanguageParser.RULE_quotedTerm = 10;

class TopLevelQueryContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = QueryLanguageParser.RULE_topLevelQuery;
    }

	query() {
	    return this.getTypedRuleContext(QueryContext,0);
	};

	EOF() {
	    return this.getToken(QueryLanguageParser.EOF, 0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryLanguageParserListener ) {
	        listener.enterTopLevelQuery(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryLanguageParserListener ) {
	        listener.exitTopLevelQuery(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryLanguageParserVisitor ) {
	        return visitor.visitTopLevelQuery(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class QueryContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = QueryLanguageParser.RULE_query;
    }

	disjQuery() {
	    return this.getTypedRuleContext(DisjQueryContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryLanguageParserListener ) {
	        listener.enterQuery(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryLanguageParserListener ) {
	        listener.exitQuery(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryLanguageParserVisitor ) {
	        return visitor.visitQuery(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class DisjQueryContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = QueryLanguageParser.RULE_disjQuery;
    }

	conjQuery = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ConjQueryContext);
	    } else {
	        return this.getTypedRuleContext(ConjQueryContext,i);
	    }
	};

	OR = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(QueryLanguageParser.OR);
	    } else {
	        return this.getToken(QueryLanguageParser.OR, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof QueryLanguageParserListener ) {
	        listener.enterDisjQuery(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryLanguageParserListener ) {
	        listener.exitDisjQuery(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryLanguageParserVisitor ) {
	        return visitor.visitDisjQuery(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ConjQueryContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = QueryLanguageParser.RULE_conjQuery;
    }

	modClause = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ModClauseContext);
	    } else {
	        return this.getTypedRuleContext(ModClauseContext,i);
	    }
	};

	AND = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(QueryLanguageParser.AND);
	    } else {
	        return this.getToken(QueryLanguageParser.AND, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof QueryLanguageParserListener ) {
	        listener.enterConjQuery(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryLanguageParserListener ) {
	        listener.exitConjQuery(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryLanguageParserVisitor ) {
	        return visitor.visitConjQuery(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ModClauseContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = QueryLanguageParser.RULE_modClause;
    }

	clause() {
	    return this.getTypedRuleContext(ClauseContext,0);
	};

	modifier() {
	    return this.getTypedRuleContext(ModifierContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryLanguageParserListener ) {
	        listener.enterModClause(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryLanguageParserListener ) {
	        listener.exitModClause(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryLanguageParserVisitor ) {
	        return visitor.visitModClause(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ModifierContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = QueryLanguageParser.RULE_modifier;
    }

	NOT() {
	    return this.getToken(QueryLanguageParser.NOT, 0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryLanguageParserListener ) {
	        listener.enterModifier(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryLanguageParserListener ) {
	        listener.exitModifier(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryLanguageParserVisitor ) {
	        return visitor.visitModifier(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ClauseContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = QueryLanguageParser.RULE_clause;
    }

	fieldName() {
	    return this.getTypedRuleContext(FieldNameContext,0);
	};

	IS() {
	    return this.getToken(QueryLanguageParser.IS, 0);
	};

	PRESENT() {
	    return this.getToken(QueryLanguageParser.PRESENT, 0);
	};

	NOT() {
	    return this.getToken(QueryLanguageParser.NOT, 0);
	};

	term() {
	    return this.getTypedRuleContext(TermContext,0);
	};

	groupingExpr() {
	    return this.getTypedRuleContext(GroupingExprContext,0);
	};

	OP_TILDE() {
	    return this.getToken(QueryLanguageParser.OP_TILDE, 0);
	};

	OP_NOT_TILDE() {
	    return this.getToken(QueryLanguageParser.OP_NOT_TILDE, 0);
	};

	OP_EQUAL() {
	    return this.getToken(QueryLanguageParser.OP_EQUAL, 0);
	};

	OP_NOT_EQUAL() {
	    return this.getToken(QueryLanguageParser.OP_NOT_EQUAL, 0);
	};

	OP_TILDE_IGNORE_CASE() {
	    return this.getToken(QueryLanguageParser.OP_TILDE_IGNORE_CASE, 0);
	};

	OP_NOT_TILDE_IGNORE_CASE() {
	    return this.getToken(QueryLanguageParser.OP_NOT_TILDE_IGNORE_CASE, 0);
	};

	OP_EQUAL_IGNORE_CASE() {
	    return this.getToken(QueryLanguageParser.OP_EQUAL_IGNORE_CASE, 0);
	};

	OP_NOT_EQUAL_IGNORE_CASE() {
	    return this.getToken(QueryLanguageParser.OP_NOT_EQUAL_IGNORE_CASE, 0);
	};

	COUNT() {
	    return this.getToken(QueryLanguageParser.COUNT, 0);
	};

	LPAREN() {
	    return this.getToken(QueryLanguageParser.LPAREN, 0);
	};

	RPAREN() {
	    return this.getToken(QueryLanguageParser.RPAREN, 0);
	};

	OP_GREATER_THAN() {
	    return this.getToken(QueryLanguageParser.OP_GREATER_THAN, 0);
	};

	OP_GREATER_THAN_EQUAL() {
	    return this.getToken(QueryLanguageParser.OP_GREATER_THAN_EQUAL, 0);
	};

	OP_LESS_THAN() {
	    return this.getToken(QueryLanguageParser.OP_LESS_THAN, 0);
	};

	OP_LESS_THAN_EQUAL() {
	    return this.getToken(QueryLanguageParser.OP_LESS_THAN_EQUAL, 0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryLanguageParserListener ) {
	        listener.enterClause(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryLanguageParserListener ) {
	        listener.exitClause(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryLanguageParserVisitor ) {
	        return visitor.visitClause(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class GroupingExprContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = QueryLanguageParser.RULE_groupingExpr;
    }

	LPAREN() {
	    return this.getToken(QueryLanguageParser.LPAREN, 0);
	};

	query() {
	    return this.getTypedRuleContext(QueryContext,0);
	};

	RPAREN() {
	    return this.getToken(QueryLanguageParser.RPAREN, 0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryLanguageParserListener ) {
	        listener.enterGroupingExpr(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryLanguageParserListener ) {
	        listener.exitGroupingExpr(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryLanguageParserVisitor ) {
	        return visitor.visitGroupingExpr(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class FieldNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = QueryLanguageParser.RULE_fieldName;
    }

	quotedTerm() {
	    return this.getTypedRuleContext(QuotedTermContext,0);
	};

	TERM() {
	    return this.getToken(QueryLanguageParser.TERM, 0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryLanguageParserListener ) {
	        listener.enterFieldName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryLanguageParserListener ) {
	        listener.exitFieldName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryLanguageParserVisitor ) {
	        return visitor.visitFieldName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TermContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = QueryLanguageParser.RULE_term;
    }

	quotedTerm() {
	    return this.getTypedRuleContext(QuotedTermContext,0);
	};

	TERM() {
	    return this.getToken(QueryLanguageParser.TERM, 0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryLanguageParserListener ) {
	        listener.enterTerm(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryLanguageParserListener ) {
	        listener.exitTerm(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryLanguageParserVisitor ) {
	        return visitor.visitTerm(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class QuotedTermContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = QueryLanguageParser.RULE_quotedTerm;
    }

	QUOTED() {
	    return this.getToken(QueryLanguageParser.QUOTED, 0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryLanguageParserListener ) {
	        listener.enterQuotedTerm(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryLanguageParserListener ) {
	        listener.exitQuotedTerm(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryLanguageParserVisitor ) {
	        return visitor.visitQuotedTerm(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}




QueryLanguageParser.TopLevelQueryContext = TopLevelQueryContext; 
QueryLanguageParser.QueryContext = QueryContext; 
QueryLanguageParser.DisjQueryContext = DisjQueryContext; 
QueryLanguageParser.ConjQueryContext = ConjQueryContext; 
QueryLanguageParser.ModClauseContext = ModClauseContext; 
QueryLanguageParser.ModifierContext = ModifierContext; 
QueryLanguageParser.ClauseContext = ClauseContext; 
QueryLanguageParser.GroupingExprContext = GroupingExprContext; 
QueryLanguageParser.FieldNameContext = FieldNameContext; 
QueryLanguageParser.TermContext = TermContext; 
QueryLanguageParser.QuotedTermContext = QuotedTermContext; 
