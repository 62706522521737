/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { useFetchTeamsIncluding } from 'services/teamsApi';
import { Combobox, TextProps } from 'components';
import React, { useMemo } from 'react';

interface TeamComboContainerProps extends Omit<TextProps, 'value' | 'onChange' | 'ref'> {
	value?: string | null;
	onChange?: (v: string | null) => void;
	onBlur?: (e: React.FocusEvent) => void;
	name?: string;
	disabled?: boolean;
	hasError?: boolean;
	required?: boolean;
	myTeamsOnly?: boolean;
	clearable?: boolean;
	placeholder?: string;
	variant?: string;
	teamId?: string;
}

type TeamComboboxOption = {
	value: string;
	label: string;
};

export const TeamCombobox: React.FC<TeamComboContainerProps> = ({
	disabled,
	variant,
	teamId,
	onChange,
	value,
	clearable = true,
	hasError = false,
	sx,
	...props
}) => {
	const [teams] = useFetchTeamsIncluding('', teamId, true, 0, 100);
	const teamsMap = useMemo(
		() =>
			teams.value
				.map((t) => ({
					label: `${t.name} (${t.key})`,
					value: t.id,
				}))
				.filter((t) => (disabled ? t.value === value : true)),
		[teams, disabled, value],
	);
	return (
		<Combobox<TeamComboboxOption>
			{...props}
			disabled={disabled}
			sx={{ ...sx }}
			variant={variant}
			value={teamsMap?.find((t) => value === t.value) ?? null}
			options={teamsMap ?? []}
			clearable={clearable}
			loading={teams.loading}
			hasError={hasError}
			onChange={(value) => onChange?.(value ? (value as TeamComboboxOption).value : null)}
		/>
	);
};
