/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { loadingResult, LoadingResult } from 'utils/hooks/stream/result';
import { useObservable } from 'utils/hooks/useObservable';

import { Permissions } from './permissionsApi';
import { Services } from './services';
import { useTeam } from './useTeam';

export default function useGlobalPermissions(): Permissions {
	const teamId = useTeam().id;
	const permissionsResult = useObservable(() => Services.permissionsApi.getGlobalPermissions$(teamId), [teamId]);
	return permissionsResult.value || Services.permissionsApi.defaultPermissions;
}

export function useInitGlobalPermissions(): Permissions | LoadingResult {
	const teamId = useTeam().id;
	const permissionsResult = useObservable(() => Services.permissionsApi.getGlobalPermissions$(teamId), [teamId]);

	if (permissionsResult.loading) {
		return loadingResult;
	}
	if (permissionsResult.error) {
		return Services.permissionsApi.defaultPermissions;
	}
	return permissionsResult.value;
}
