/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { ReactElement } from 'react';

import TemplatePlaceholder, { Placeholder } from './TemplatePlaceholder';
import EnvironmentVariables from './EnvironmentVariables';

export default function Pages({ page, placeholder }: { page: number; placeholder: Placeholder }): ReactElement | null {
	const actionsResult = usePromise(() => Services.actions.fetchActions(), []);

	if (page === 0) {
		return <EnvironmentVariables actionsResult={actionsResult} />;
	}

	if (!placeholder) {
		return null;
	}

	return <TemplatePlaceholder key={placeholder.key} placeholder={placeholder} actionsResult={actionsResult} />;
}
