/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

export function isOnpremise(): boolean {
	return (
		document.location?.hostname !== 'platform.dev.steadybit.com' &&
		document.location?.hostname !== 'platform.steadybit.com' &&
		process.env.NODE_ENV !== 'development'
	);
}
