/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { CopyToClipboard as ReactCopyToClipboard } from 'react-copy-to-clipboard';
import React from 'react';

import { Button, ButtonProps } from '../Button';
import { IconDuplicate } from '../icons';
import { Snackbar } from '../Snackbar';

interface CopyToClipboardProps extends ButtonProps {
	text: string;
	variant?: 'primary' | 'secondary';
}

export const CopyToClipboard: React.VFC<CopyToClipboardProps> = ({ text, variant = 'secondary', ...props }) => {
	return (
		<ReactCopyToClipboard text={text} onCopy={() => Snackbar.dark('Copied!')}>
			<Button width="96px" variant={variant} {...props}>
				<IconDuplicate mr="xSmall" />
				Copy
			</Button>
		</ReactCopyToClipboard>
	);
};
