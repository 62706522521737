/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { RouterLink, Stack, Text } from 'components';
import { IconComponent } from 'components/icons';
import { ReactElement, ReactNode } from 'react';
import { useLocation } from 'url/hooks';

interface NavItemProps {
	children: ReactNode;
	Icon?: IconComponent;
	active?: boolean;
	href: string;
	'data-cy'?: string;
}

export default function NavItem({ children, Icon, active, ...props }: NavItemProps): ReactElement {
	const location = useLocation();
	active = active ?? location.pathname.startsWith(props.href);
	const color = active ? 'neutral800' : 'neutral600';

	return (
		<li>
			<Stack
				size="xxSmall"
				direction="horizontal"
				alignItems="center"
				p="xSmall"
				mx="-xSmall"
				as={RouterLink}
				sx={{
					backgroundColor: active ? 'neutral200' : 'none',
					borderRadius: '6px',
					color,
					':hover': {
						color: 'neutral800',
						backgroundColor: 'neutral200',
						textDecoration: 'none',
					},
				}}
				aria-current={active ? 'page' : undefined}
				{...props}
			>
				{Icon && <Icon flexShrink={0} />}
				<Text variant="mediumStrong">{children}</Text>
			</Stack>
		</li>
	);
}
