/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Container, Link, LoadingIndicator, Pill, RouterLink, Stack, Text, Tooltip } from 'components';
import { EnvironmentVO, TargetAttributeDescriptionVO, TargetTypeDescriptionVO } from 'ui-api';
import { useAttributeDefinitions } from 'attributes/useAttributeDefinitions';
import { TargetId, emptyTargetDefinition, toTargetId } from 'targets/util';
import TargetDetailsModal from 'targets/TargetDetails/TargetDetailsModal';
import AstroidScreen from 'components/List/AstroidScreen/AstroidScreen';
import { IconTarget, createIconFromDataUri } from 'components/icons';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import { TargetViewTable } from 'hocs/targets/TargetViewTable';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ReactElement, useEffect, useState } from 'react';
import { UrlParam, useUrlState } from 'url/useUrlState';
import { localeCompareIgnoreCase } from 'utils/string';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { useFormikContext } from 'formik';
import { theme } from 'styles.v2/theme';

import useAgentReport from '../../../../utils/hooks/useAgentReport';
import { EnvironmentConfig } from './types';

interface UrlState {
	targetId: TargetId;
}

export default function ResultView(): ReactElement {
	const formik = useFormikContext<EnvironmentConfig>();
	const environment: EnvironmentConfig = formik.values;

	const [{ targetId }, , updateUrlWithState] = useUrlState<UrlState>([
		getTargetIdParam(isEnvironmentVO(environment) ? environment.id : undefined),
	]);

	const attributeDefinitionsResult = useAttributeDefinitions();
	const attributeDefinitions = attributeDefinitionsResult.value || [];
	const targetDefinitionsResult = useTargetDefinitions();
	const targetDefinitions = targetDefinitionsResult.value;

	const [targetCount, setTargetCount] = useState<Record<string, number> | null>(null);
	const targetCountsResult = usePromise(
		() => Services.environments.countTargets(environment.predicate),
		[environment.predicate],
	);
	useEffect(() => {
		if (targetCountsResult.value) {
			setTargetCount(targetCountsResult.value);
		}
	}, [targetCountsResult.value]);

	const isLoading = attributeDefinitionsResult.loading || targetDefinitionsResult.loading || targetCount === null;

	if (isLoading) {
		return <LoadingContent />;
	}

	const hasSomeTargets = targetCount.total !== undefined && targetCount.total > 0;

	return (
		<Container
			sx={{
				width: '100%',
				height: '100%',
				background: 'neutral100',
				p: 'medium',
				minHeight: '460px',
			}}
		>
			{isLoading ? (
				<LoadingContent />
			) : (
				<>
					{!hasSomeTargets && <EmptyTargetsContent />}

					{hasSomeTargets && targetId != null && (
						<TargetDetailsModal
							environmentId={isEnvironmentVO(environment) ? environment.id : null}
							onClose={() => updateUrlWithState({ targetId: undefined })}
							targetId={targetId}
							withAdvice={false}
						/>
					)}
				</>
			)}
			{targetDefinitions &&
				hasSomeTargets &&
				(!isLoading || targetCount.total === undefined) &&
				!attributeDefinitionsResult.loading &&
				!targetDefinitionsResult.loading && (
					<Table
						targetCount={targetCount}
						environment={environment}
						targetDefinitions={targetDefinitions.filter((target) => targetCount[target.id] > 0)}
						attributeDefinitions={attributeDefinitions}
					/>
				)}
		</Container>
	);
}

interface TableProps {
	targetCount: Record<string, number>;
	environment: EnvironmentConfig;
	targetDefinitions: TargetTypeDescriptionVO[];
	attributeDefinitions: TargetAttributeDescriptionVO[];
}

function Table({ environment, targetCount, targetDefinitions, attributeDefinitions }: TableProps): ReactElement {
	const [, getUrlWithState] = useUrlState<UrlState>([
		getTargetIdParam(isEnvironmentVO(environment) ? environment.id : undefined),
	]);

	const categories = Array.from(new Set(targetDefinitions.map((target) => target.category || ''))).sort((a, b) => {
		if (a === '') {
			return 1;
		}
		return localeCompareIgnoreCase(a, b);
	});
	const unknownTargetIds = getUnknownTargetIds(targetCount, targetDefinitions);
	if (unknownTargetIds.length > 0 && !categories.includes('Unknown')) {
		categories.push('Unknown');
	}
	const [selectedCategory, setSelectedCategory] = useState<string | undefined>(categories[0]);
	const [selectedTargetId, setSelectedTargetId] = useState<string>(
		targetDefinitions.find((target) => target.category === selectedCategory)?.id || targetDefinitions[0]?.id || '',
	);

	useEffect(() => {
		if (targetDefinitions.length > 0 && !selectedTargetId) {
			setSelectedTargetId(targetDefinitions[0]?.id || '');
		}
	}, [targetDefinitions]);

	// this effect controls if the current selected category and subcategory are still avaliable and reset them if not
	useEffect(() => {
		if (selectedCategory === 'Unknown') {
			if (!unknownTargetIds.includes(selectedTargetId)) {
				setSelectedTargetId(unknownTargetIds[0]);
			}
		} else if (categories.length > 0 && categories.find((c) => c === selectedCategory) === undefined) {
			setSelectedCategory(targetDefinitions[0].category);
		} else if (
			targetDefinitions.length > 0 &&
			selectedTargetId &&
			targetDefinitions.find((t) => t.id === selectedTargetId) === undefined
		) {
			setSelectedTargetId(
				targetDefinitions.find((target) => target.category === selectedCategory)?.id || targetDefinitions[0].id,
			);
		}
	}, [categories, selectedCategory]);

	const targetDefinition: TargetTypeDescriptionVO =
		targetDefinitions.find((target) => target.id === selectedTargetId) || emptyTargetDefinition(selectedTargetId);

	return (
		<Stack size="none" height="100%">
			<Stack direction="horizontal" size="xSmall" px="small" pt="xSmall" pb="small">
				<Text variant="mediumStrong">Included Targets</Text>
				<Pill backgroundColor="cyan200" color="cyan800">
					{targetCount.total}
				</Pill>
			</Stack>
			<Categories
				categories={categories}
				targetCount={targetCount}
				selectedCategory={selectedCategory}
				targetDefinitions={targetDefinitions}
				setSelectedCategory={(_category) => {
					setSelectedCategory(_category);
					setSelectedTargetId(
						targetDefinitions.find((target) => target.category === _category)?.id || targetDefinitions[0].id,
					);
				}}
			/>
			{selectedCategory === 'Unknown' ? (
				<UnknownTargetIdsSelection
					setSelectedTargetId={setSelectedTargetId}
					targetDefinitions={targetDefinitions}
					selectedTargetId={selectedTargetId}
					targetCount={targetCount}
				/>
			) : (
				<TargetTypeSelection
					setSelectedTargetId={setSelectedTargetId}
					selectedTargetId={selectedTargetId}
					targetDefinitions={targetDefinitions}
					selectedCategory={selectedCategory}
					targetCount={targetCount}
				/>
			)}

			{selectedTargetId && targetDefinition && (
				<Container overflowY="auto">
					<TargetViewTable
						framed
						query=""
						framePadding="medium"
						getTargetDetailHref={(target) => getUrlWithState({ targetId: toTargetId(target) })}
						attributeDefinitions={attributeDefinitions}
						targetDefinition={targetDefinition}
						predicate={environment.predicate}
						targetsPerPage={15}
					/>
				</Container>
			)}
		</Stack>
	);
}

interface CategoriesProps {
	setSelectedCategory: (category: string | undefined) => void;
	targetDefinitions: TargetTypeDescriptionVO[];
	categories: Array<string | undefined>;
	selectedCategory: string | undefined;
	targetCount: Record<string, number>;
}

function Categories({
	categories,
	selectedCategory,
	setSelectedCategory,
	targetDefinitions,
	targetCount,
}: CategoriesProps): ReactElement {
	return (
		<Stack
			direction="horizontal"
			size="none"
			sx={{
				background: 'neutral150',
				borderTop: '1px solid ' + theme.colors.neutral300,
				borderLeft: '1px solid ' + theme.colors.neutral300,
				borderRight: '1px solid ' + theme.colors.neutral300,
			}}
		>
			{categories.map((category, i) => {
				const aggregatedCount =
					category === 'Unknown'
						? getUnknownTargetIds(targetCount, targetDefinitions).reduce(
								(acc, targetId) => acc + (targetCount[targetId] || 0),
								0,
							)
						: targetDefinitions
								.filter((target) => (target.category || '') === category)
								.map((target) => target.id)
								.reduce((acc, targetId) => acc + (targetCount[targetId] || 0), 0);
				const isSelected = selectedCategory === category;
				return (
					<Stack
						key={category || 'undefined'}
						direction="horizontal"
						size="xSmall"
						sx={{
							textTransform: 'capitalize',
							borderRight: i < categories.length ? '1px solid ' + theme.colors.neutral300 : 'none',
							borderBottom: isSelected ? 'none' : '1px solid ' + theme.colors.neutral300,
							background: isSelected ? 'neutral000' : 'neutral150',
							p: 'small',
							cursor: 'pointer',
							':hover': {
								bg: 'neutral000',
							},
						}}
						onClick={() => setSelectedCategory(category)}
					>
						<Text variant="mediumStrong" color={isSelected ? 'neutral800' : 'slate'}>
							{category || 'Other'}{' '}
						</Text>
						<Pill backgroundColor="neutral200" color="neutral600">
							{aggregatedCount}
						</Pill>
					</Stack>
				);
			})}
		</Stack>
	);
}

function getUnknownTargetIds(
	targetCount: Record<string, number>,
	targetDefinitions: TargetTypeDescriptionVO[],
): string[] {
	return Object.keys(targetCount)
		.filter((id) => id != 'total')
		.filter((id) => targetDefinitions.find((t) => t.id === id) === undefined);
}

interface TargetTypeSelectionProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	setSelectedTargetId: (id: string) => void;
	selectedCategory: string | undefined;
	targetCount: Record<string, number>;
	selectedTargetId: string;
}

function TargetTypeSelection({
	setSelectedTargetId,
	targetDefinitions,
	selectedTargetId,
	selectedCategory,
	targetCount,
}: TargetTypeSelectionProps): ReactElement {
	return (
		<Stack
			direction="horizontal"
			size="small"
			sx={{
				py: 'small',
				px: 'medium',
				background: 'neutral000',
				borderLeft: '1px solid ' + theme.colors.neutral300,
				borderRight: '1px solid ' + theme.colors.neutral300,
			}}
		>
			{targetDefinitions
				.filter((target) => (target.category || '') === selectedCategory)
				.map((target) => {
					const count = targetCount[target.id] || 0;
					const isSelected = target.id === selectedTargetId;
					const Icon = createIconFromDataUri(target.icon);
					return (
						<Stack
							key={target.id}
							direction="horizontal"
							size="xSmall"
							sx={{
								alignItems: 'center',
								px: 'xxSmall',
								py: 'xSmall',
								borderBottom: '3px solid ' + (isSelected ? theme.colors.slate : 'transparent'),
								cursor: 'pointer',
								':hover': {
									borderBottom: '3px solid ' + (isSelected ? theme.colors.slate : theme.colors.purple300),
								},
							}}
							onClick={() => setSelectedTargetId(target.id)}
						>
							<Icon />
							<Text variant="smallStrong" color={isSelected ? 'neutral800' : 'neutral600'}>
								{target.label.other} ({count})
							</Text>
						</Stack>
					);
				})}
		</Stack>
	);
}

interface UnknownTargetIdsSelectionProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	setSelectedTargetId: (id: string) => void;
	targetCount: Record<string, number>;
	selectedTargetId: string;
}

function UnknownTargetIdsSelection({
	setSelectedTargetId,
	targetDefinitions,
	selectedTargetId,
	targetCount,
}: UnknownTargetIdsSelectionProps): ReactElement {
	return (
		<Stack
			direction="horizontal"
			size="small"
			sx={{
				py: 'small',
				px: 'medium',
				background: 'neutral000',
				borderLeft: '1px solid ' + theme.colors.neutral300,
				borderRight: '1px solid ' + theme.colors.neutral300,
			}}
		>
			{Object.keys(targetCount)
				.filter((id) => id != 'total')
				.filter((id) => targetDefinitions.find((t) => t.id === id) === undefined)
				.map((targetId) => {
					const count = targetCount[targetId] || 0;
					const isSelected = targetId === selectedTargetId;
					return (
						<Stack
							key={targetId}
							direction="horizontal"
							size="xSmall"
							sx={{
								alignItems: 'center',
								px: 'xxSmall',
								py: 'xSmall',
								borderBottom: '3px solid ' + (isSelected ? theme.colors.slate : 'transparent'),
								cursor: 'pointer',
								':hover': {
									borderBottom: '3px solid ' + (isSelected ? theme.colors.slate : theme.colors.purple300),
								},
							}}
							onClick={() => setSelectedTargetId(targetId)}
						>
							<Tooltip content={targetId}>
								<Text variant="smallStrong" color={isSelected ? 'neutral800' : 'neutral600'} sx={{ ...textEllipsis }}>
									{targetId} ({count})
								</Text>
							</Tooltip>
						</Stack>
					);
				})}
		</Stack>
	);
}

function LoadingContent(): ReactElement {
	return (
		<Container display="flex" alignItems="center" justifyContent="center" height="100%">
			<LoadingIndicator variant="xxLarge" color="slate" />
		</Container>
	);
}

function EmptyTargetsContent(): ReactElement {
	const { someAgentHasReportedInThePast } = useAgentReport();
	return (
		<Container display="flex" alignItems="center" justifyContent="center" height="calc(100% - 200px)">
			<Container width={800}>
				<AstroidScreen
					title={
						<Text variant="xLargeStrong" color="slate">
							{someAgentHasReportedInThePast
								? 'No targets discovered matching your scope'
								: 'No targets discovered to be assigned to this environment'}
						</Text>
					}
					icon={<IconTarget variant="xxxLarge" color="neutral600" />}
					description={
						<Stack direction="horizontal" size="xSmall">
							<Text variant="medium" color="neutral600" textAlign="center">
								{someAgentHasReportedInThePast ? (
									<>
										Please adjust your environment scope to assign discovered targets to this environment,
										<br />
										or <RouterLink to={'/settings/agents/setup'}>
											install additional agents and extensions
										</RouterLink>{' '}
										to discover more targets.
									</>
								) : (
									<>
										You must <RouterLink to={'/onboarding'}>continue with the agent setup</RouterLink>, to discover
										targets that can be assigned to this environment.
									</>
								)}
							</Text>
						</Stack>
					}
				/>
			</Container>
		</Container>
	);
}

function isEnvironmentVO(environment: EnvironmentConfig): environment is EnvironmentVO {
	return environment !== undefined && 'id' in environment;
}

function getTargetIdParam(envId?: string): UrlParam<string | undefined> {
	return {
		pathSegment: `/${envId ? envId : '<new>'}`,
		name: 'targetId',
		defaultValue: undefined,
	};
}
