/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components/Container';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

interface SkeletonsProps extends ContainerProps {
	height?: number;
	widths: Array<string | number>;
}
export default function Skeletons({ height = 12, widths, ...props }: SkeletonsProps): ReactElement {
	return (
		<Container {...props} sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 'xSmall', ...props.sx }}>
			{widths.map((width, i) => (
				<Skeleton key={i} width={width} height={height} />
			))}
		</Container>
	);
}

interface SkeletonProps {
	height: number;
	width: number | string;
}
function Skeleton({ height, width }: SkeletonProps): ReactElement {
	const key = `@keyframes shimmer-${width}`;

	return (
		<Container
			data-cy="skeleton"
			sx={{
				display: 'block',
				borderRadius: 2,
				height,
				width,
				background:
					'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5) 50%, transparent 80% ), ' +
					theme.colors.neutral300,
				backgroundRepeat: 'repeat-y',
				backgroundSsize: '50px 500px',
				backgroundPosition: '0 0',
				animation: `shimmer-${width} 2s infinite`,
				[key]: {
					'0%': { backgroundPosition: `left ${-width}px top` },
					'100%': { backgroundPosition: `left ${width}px top` },
				},
			}}
		/>
	);
}
