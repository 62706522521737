/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useHistory } from 'url/hooks';
import { debounce } from 'lodash';
import { ampli } from 'ampli';

import { LandscapeConfig } from '../types';
import { pathSegment } from '../urlParams';

export function useViewConfiguredTracking(): void {
	const formik = useFormikContext<LandscapeConfig>();
	const { filterQuery, groupBy, colorBy, sizeBy, environmentId, showAdvice } = formik.values;

	const { location } = useHistory();
	const formIsTouched = Object.keys(location.matrix[pathSegment]).length > 0;

	useEffect(() => {
		if (formIsTouched) {
			ampli.landscapeExplorerViewConfigured({
				url: window.location.href,
				environment_id: environmentId,
				landscape_explorer_filter_query: filterQuery,
				landscape_explorer_grouped_by: groupBy,
				landscape_explorer_colored_by: colorBy,
				landscape_explorer_sized_by: sizeBy,
				landscape_explorer_show_advice: showAdvice,
			});
		}
	}, [filterQuery, groupBy.join(','), colorBy, sizeBy, environmentId, showAdvice]);
}

export function useTargetSelectionForExperimentTracking(query: string, environmentId: string): void {
	useEffect(() => {
		ampli.landscapeExplorerTargetSelectedForExperiment({
			environment_id: environmentId,
			target_query: query,
		});
	}, [query, environmentId]);
}

export function useAttributeFilterTracking(
	attributeFilter: string,
	attributeType: 'target_attributes' | 'target_group_shared_attributes',
): void {
	const track = useMemo(
		() =>
			debounce((searchTerm: string) => {
				ampli.landscapeExplorerAttributesSearched({
					landscape_explorer_target_attribute_type: attributeType,
					search_term: searchTerm,
				});
			}, 500),
		[],
	);

	useEffect(() => {
		if (attributeFilter) {
			track(attributeFilter);
		}
	}, [attributeFilter]);
}
