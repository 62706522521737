/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { useEventEffect } from 'utils/hooks/useEventEffect';
import { usePromise } from 'utils/hooks/usePromise';
import { useTenant } from 'tenancy/useTenant';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';

export default function useDeletedTeamRedirect(): void {
	const team = useTeam();
	const tenant = useTenant();
	const teams = usePromise(() => Services.teams.findTeams('', false, 0, 100), []);

	useEventEffect(
		(event) => {
			if (event.teamId === team.id) {
				const newTeam = teams.value?.find((t) => t.userIsTeamMember);
				if (!newTeam) {
					return;
				}
				Services.teams.setTeam(tenant.key, newTeam.key);
				const pathSplit = window.location.pathname.split('/');
				const targetPath = pathSplit.length > 0 ? pathSplit[1] : '';
				window.location.assign('/' + targetPath);
			}
		},
		['team.deleted'],
		() => {},
		[team.id, teams, tenant.key],
	);
}
