/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

interface Time {
	getMillis: () => number;
}

interface Days extends Time {
	getDays: () => number;
	getMinutes: () => number;
}

interface Millis extends Time {
	getDuration: () => string;
}

export function days(days: number): Days {
	const time = Math.abs(days);
	return {
		getMillis: () => time * 24 * 60 * 60 * 1000,
		getMinutes: () => time * 24 * 60,
		getDays: () => time,
	};
}

export function hours(hours: number): Time {
	const time = Math.abs(hours);
	return {
		getMillis: () => time * 60 * 60 * 1000,
	};
}

export function minutes(minutes: number): Time {
	const time = Math.abs(minutes);
	return {
		getMillis: () => time * 60 * 1000,
	};
}

export function seconds(seconds: number): Time {
	const time = Math.abs(seconds);
	return {
		getMillis: () => time * 1000,
	};
}

export function millis(millis: number): Millis {
	const time = Math.abs(millis);
	return {
		getMillis: () => time,
		getDuration: () => {
			let duration = '';

			const oneDay = days(1).getMillis();
			if (Math.floor(time / oneDay) != 0) {
				duration += `${Math.floor(time / oneDay)}d`;
			}

			const oneHour = hours(1).getMillis();
			if (Math.floor((time % oneDay) / oneHour) != 0) {
				duration += ` ${Math.floor((time % oneDay) / oneHour)}h`;
			}

			const oneMinute = minutes(1).getMillis();
			if (Math.floor((time % oneHour) / oneMinute) != 0) {
				duration += ` ${Math.floor((time % oneHour) / oneMinute)}m`;
			}

			const oneSecond = seconds(1).getMillis();
			if (Math.floor((time % oneMinute) / oneSecond) != 0) {
				duration += ` ${Math.floor((time % oneMinute) / oneSecond)}s`;
			}

			if (Math.floor(time % 1000)) {
				duration += ` ${Math.floor(time % 1000)}ms`;
			}

			return duration;
		},
	};
}
