/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	CreateWebhookRequest,
	FieldVO,
	GetAvailableWebhookEventsResponse,
	GetWebhooksPageResponse,
	OptionVO,
	UpdateWebhookRequest,
	WebhookVO,
} from 'ui-api';
import { Criteria, PageParams } from 'utils/hooks/usePage';
import { groupBy, sortBy } from 'lodash';
import axios from 'axios';

type WebhookEvents = {
	category: string;
	events: string[];
}[];

const getCategory = (event: string): string => {
	const match = /([^.]+)/.exec(event);
	return match ? match[1] : 'other';
};

const categorizeEvents = (events: string[]): WebhookEvents => {
	events.sort();
	const categories = Object.entries(groupBy(events, getCategory)).map(([category, events]) => ({ category, events }));
	return sortBy(categories, ['category']);
};

export const WebhookScope: Record<string, OptionVO> = {
	GLOBAL: { value: 'GLOBAL', label: 'Any Experiment' },
	TEAM: { value: 'TEAM', label: 'Team' },
	EXPERIMENT: { value: 'EXPERIMENT', label: 'Experiment Opt-in' },
};

export interface WebhookInfoVO {
	type: string;
	label: string;
	parameters: FieldVO[];
}

export const WebhookTypes: WebhookInfoVO[] = [
	{
		type: 'slack',
		label: 'Slack Notification',
		parameters: [
			{
				name: 'channel',
				label: 'Channel',
				type: 'string',
			},
			{
				name: 'iconUrl',
				label: 'Icon Url',
				type: 'url',
			},
		],
	},
	{
		type: 'webhook',
		label: 'Webhook',
		parameters: [
			{
				name: 'secret',
				label: 'Secret (optional)',
				type: 'string',
				description:
					'If a secret is provided a signature of the body is computed using `HMAC SHA-256` and sent as `X-SB-Signature` http header. You can use this header to verify the message.',
			},
			{
				name: 'targetAttributeIncludes',
				label: 'Target Attribute Includes (optional)',
				type: 'string',
				defaultValue: '*',
				description:
					'The body size can get very large as we include all target attributes for each target of your experiments. When having experiments with many targets, it might be useful to filter the attributes to only include the ones you are interested in. You can use the wildcard character `*` to match all attributes or a comma-separated-list of attribute-names. If the field is empty, no attributes will be included.',
			},
		],
	},
	{
		type: 'webhookPreflight',
		label: 'Webhook',
		parameters: [
			{
				name: 'secret',
				label: 'Secret (optional)',
				type: 'string',
				description:
					'If a secret is provided a signature of the body is computed using `HMAC SHA-256` and sent as `X-SB-Signature` http header. You can use this header to verify the message.',
			},
			{
				name: 'targetAttributeIncludes',
				label: 'Target Attribute Includes (optional)',
				type: 'string',
				defaultValue: '*',
				description:
					'The body size can get very large as we include all target attributes for each target of your experiments. When having experiments with many targets, it might be useful to filter the attributes to only include the ones you are interested in. You can use the wildcard character `*` to match all attributes or a comma-separated-list of attribute-names. If the field is empty, no attributes will be included.',
			},
		],
	},
];

export class WebhooksApi {
	async findWebhooks(criteria: Criteria, page: PageParams): Promise<GetWebhooksPageResponse> {
		const params = page.appendTo(new URLSearchParams(criteria));
		return (await axios.get<GetWebhooksPageResponse>('/ui/webhooks', { params })).data;
	}

	async fetchAvailableEvents(type: string): Promise<WebhookEvents> {
		const params = type === 'webhookPreflight' ? new URLSearchParams({ type: 'preflight' }) : undefined;
		const events: string[] = (await axios.get<GetAvailableWebhookEventsResponse>('/ui/webhooks/events', { params }))
			.data.content;
		return categorizeEvents(events);
	}

	async deleteWebhook(id: string): Promise<void> {
		await axios.delete(`/ui/webhooks/${id}`);
	}

	async createWebhook(body: CreateWebhookRequest): Promise<void> {
		await axios.post('/ui/webhooks', body);
	}

	async updateWebhook(id: string, body: UpdateWebhookRequest): Promise<void> {
		await axios.post('/ui/webhooks/' + id, body);
	}

	async fetchWebhook(id: string): Promise<WebhookVO> {
		return (await axios.get<WebhookVO>(`/ui/webhooks/${id}`)).data;
	}
}
