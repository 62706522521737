/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { withBaseHref } from 'utils/getBaseHref';
import axios, { AxiosResponse } from 'axios';

export class UnauthorizedError extends Error {
	constructor(err: unknown) {
		super(err instanceof Error ? err.message : 'Unauthorized');
		Object.assign(this, err);
	}
}

export class AuthApi {
	constructor() {
		this.setupAxiosInterceptors();
	}

	async logout(): Promise<void> {
		//For logout we construct a form to do a post to /logout
		//REASONS: cognito / keycloak both don't provide a background logout url so the user has to visit the url
		//... also it happens that the keycloak didn't do a redirect so we handle it all in the background.
		const form = document.createElement('form');
		form.action = '/logout';
		form.method = 'post';

		const _csrfInput = document.createElement('input');
		_csrfInput.type = 'hidden';
		_csrfInput.name = '_csrf';
		_csrfInput.value = getCookieValue('XSRF-TOKEN');
		form.appendChild(_csrfInput);
		document.body.appendChild(form);

		return new Promise<void>((resolve) => {
			form.addEventListener(
				'load',
				() => {
					form.remove();
					resolve();
				},
				true,
			);

			form.submit();
		});
	}

	async login(username: string, password: string): Promise<void> {
		const data = new FormData();
		data.set('username', username);
		data.set('password', password);

		try {
			await axios.post('/login', data);
		} catch (error) {
			if (error.response?.status === 401) {
				throw new Error('Invalid username or password.');
			}
			throw error;
		}

		const params = new URLSearchParams(window.location.search);
		const redirectUrl = params.get('redirectTo') || '/';
		window.location.replace(redirectUrl);
	}

	setTenant(tenantKey?: string): void {
		if (tenantKey) {
			axios.defaults.headers.common['X-Tenant-Key'] = tenantKey;
		} else {
			delete axios.defaults.headers.common['X-Tenant-Key'];
		}
	}

	private setupAxiosInterceptors(): void {
		axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';

		axios.interceptors.response.use(
			(response) => response,
			async (error) => {
				if (error.response) {
					if (isLoginRequest(error.response) || isLogoutRequest(error.response)) {
						throw error;
					}
					if (error.response.status === 401) {
						redirectToLogin();
					}
				}
				throw error;
			},
		);
	}
}

const isLoginRequest = <T>(response: AxiosResponse<T>): boolean => {
	return response.config.method === 'post' && response.config.url === '/login';
};

const isLogoutRequest = <T>(response: AxiosResponse<T>): boolean => {
	return response.config.method === 'post' && response.config.url === '/logout';
};

const getCookieValue = (name: string): string => {
	const match = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
	return match ? match.pop() || '' : '';
};

export function redirectToLogin(): void {
	const redirectTo = window.location.pathname + window.location.search + window.location.hash;
	const search = redirectTo !== '/' ? '?' + new URLSearchParams({ redirectTo }).toString() : '';
	window.location.assign(withBaseHref(`/login${search}`));
}
