/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, TextProps } from 'components';
import React from 'react';

import { ButtonIcon } from '../ButtonIcon';
import { Heading } from '../Heading';
import { IconClose } from '../icons';

interface DialogHeaderProps extends TextProps {
	title?: string;
	variant?: string;
	onClose?: () => void;
}

export const DialogHeader: React.FC<DialogHeaderProps> = ({
	children,
	title,
	variant = 'large',
	onClose,
	sx = {},
	...props
}) => {
	return (
		<Container display={'flex'} flex="0 0 auto" alignItems={'center'} px={'medium'} mt={'medium'}>
			<Heading
				flex={'1 1 auto'}
				variant={variant}
				color="neutral800"
				sx={{ overflow: 'hidden', textOverflow: 'ellipsis', ...sx }}
				{...props}
			>
				{title}
			</Heading>
			{children}
			{onClose ? (
				<ButtonIcon ml="medium" flex="0 0 auto" variant="small" onClick={onClose} tooltip="Close">
					<IconClose />
				</ButtonIcon>
			) : null}
		</Container>
	);
};
