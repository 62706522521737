/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

export default `
precision highp float;
precision highp int;

uniform float progress;

varying vec2 vUv;
varying vec3 vColor;
varying float fOpacity;

void main()	{
  vec3 color = vColor;

  float dist = length(vUv - vec2(0.5, 0.5)) * 1.2;
  float delta = fwidth(dist);
  float alpha = smoothstep(0.5-delta, 0.5, dist);
  vec4 colorCircle = vec4(color, min(fOpacity, progress - alpha));
  
  if (alpha < 1.0) {
    gl_FragColor = colorCircle;
    return;
  }
  
  float distShadow = length(vUv - vec2(0.5, 0.5));
  float deltaShadow = fwidth(distShadow);
  float alphaShadow =  smoothstep(0.27 - deltaShadow, 0.45, distShadow);
  vec4 colorShadow = vec4(0.0, 0.0, 0.0, max(0.0, min(1.0, fOpacity * (progress - alphaShadow))));

  gl_FragColor = colorShadow;
}
`;
