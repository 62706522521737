/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Stack, RouterLink, Text } from 'components';
import { ReactElement } from 'react';

export function ExploreNoEnvironmentsView(): ReactElement {
	return (
		<Stack
			size="xSmall"
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				width: '100%',
				bg: 'neutral100',
				color: 'neutral600',
			}}
		>
			<Text as="span" variant="large">
				There are no environments available.
			</Text>
			<Text as="span" variant="large">
				You can create an environment
				<RouterLink to="/settings/environments" ml="xxSmall">
					here
				</RouterLink>
			</Text>
		</Stack>
	);
}
