/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Table, TableBody, TableHead, TableHeadCell, TableRow, TableSortLink } from 'components';
import { Order, PageLocation } from 'utils/hooks/usePage';
import { ReactNode } from 'react';

import { ampli } from '../../../../../ampli';

const SORT_NAME_ASC: Order[] = [['name', 'asc', 'ignoreCase']];
const SORT_NAME_DESC: Order[] = [['name', 'desc', 'ignoreCase']];
const TeamTable: React.FC<{ page: PageLocation; children: ReactNode }> = ({ page, children }) => {
	return (
		<Table width={'100%'}>
			<TableHead>
				<TableRow>
					<TableHeadCell>
						<TableSortLink
							sort={page.getDirection(SORT_NAME_ASC, SORT_NAME_DESC)}
							to={page.toggleSort(SORT_NAME_ASC, SORT_NAME_DESC).toString()}
							onClick={() => {
								ampli.teamListSorted({ sorted_by: 'Name' });
							}}
						>
							Name
						</TableSortLink>
					</TableHeadCell>
					<TableHeadCell>Environments</TableHeadCell>
					<TableHeadCell>Owner</TableHeadCell>
					<TableHeadCell>Members</TableHeadCell>
					<TableHeadCell />
				</TableRow>
			</TableHead>
			<TableBody>{children}</TableBody>
		</Table>
	);
};

export default TeamTable;
