/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { useEventEffect } from 'utils/hooks/useEventEffect';
import { IconAdviceGeneral } from 'components/icons';
import { Container, Stack, Text } from 'components';
import { usePromise } from 'utils/hooks/usePromise';
import { AdviceStatusVO, AdviceVO } from 'ui-api';
import { ReactElement, useState } from 'react';
import { Services } from 'services/services';
import { theme } from 'styles.v2/theme';

import { AdviceIcon } from './AdviceIcon';

interface AdviceBadgesBasicProps {
	onBadgeClick?: (badge: AdviceStatusVO) => void;
	activeBadges?: Array<AdviceStatusVO>;
	variant?: 'smallest' | 'small' | 'large';
	floating?: boolean;
}

interface AdviceBadgesPropsWithAdvices extends AdviceBadgesBasicProps {
	adviceRequireValidation: number;
	adviceRequireAction: number;
	adviceDone: number;
}
interface AdviceBadgesPropsWithResult extends AdviceBadgesBasicProps {
	result: AdviceVO[];
}

type AdviceBadgesProps = AdviceBadgesPropsWithAdvices | AdviceBadgesPropsWithResult;

function isAdviceBadgesPropsWithAdvices(
	props: AdviceBadgesPropsWithAdvices | AdviceBadgesPropsWithResult,
): props is AdviceBadgesPropsWithAdvices {
	return (props as AdviceBadgesPropsWithAdvices).adviceRequireValidation !== undefined;
}

export default function AdviceBadges(props: AdviceBadgesProps): ReactElement | null {
	const { activeBadges = [], onBadgeClick, variant = 'large', floating = true } = props;

	const { adviceRequireValidation, adviceRequireAction, adviceDone } = isAdviceBadgesPropsWithAdvices(props)
		? props
		: {
				adviceRequireAction: props.result.filter((a) => a.status === 'ACTION_NEEDED').length,
				adviceRequireValidation: props.result.filter((a) => a.status === 'VALIDATION_NEEDED').length,
				adviceDone: props.result.filter((a) => a.status === 'IMPLEMENTED').length,
		  };

	if (!adviceRequireValidation && !adviceRequireAction && !adviceDone) {
		return null;
	}

	return (
		<Container
			sx={{
				display: floating ? 'flex' : 'grid',
				gridTemplateColumns:
					variant === 'large' ? '86px 86px 86px' : variant === 'small' ? '60px 60px 60px' : '42px 42px 42px',
				gap: '4px',
			}}
		>
			{adviceRequireAction > 0 ? (
				<AdviceBadge
					state="ACTION_NEEDED"
					numAdvice={adviceRequireAction}
					variant={variant}
					onClick={onBadgeClick ? () => onBadgeClick('ACTION_NEEDED') : undefined}
					isActive={activeBadges.indexOf('ACTION_NEEDED') >= 0}
				/>
			) : (
				<div />
			)}
			{adviceRequireValidation > 0 ? (
				<AdviceBadge
					state="VALIDATION_NEEDED"
					numAdvice={adviceRequireValidation}
					variant={variant}
					onClick={onBadgeClick ? () => onBadgeClick('VALIDATION_NEEDED') : undefined}
					isActive={activeBadges.indexOf('VALIDATION_NEEDED') >= 0}
				/>
			) : (
				<div />
			)}
			{adviceDone > 0 && (
				<AdviceBadge
					state="IMPLEMENTED"
					numAdvice={adviceDone}
					variant={variant}
					onClick={onBadgeClick ? () => onBadgeClick('IMPLEMENTED') : undefined}
					isActive={activeBadges.indexOf('IMPLEMENTED') >= 0}
				/>
			)}
		</Container>
	);
}

interface AdviceBadgeProps {
	state: AdviceStatusVO;
	onClick?: () => void;
	isActive?: boolean;
	numAdvice: number;
	variant?: 'smallest' | 'small' | 'large';
}

function AdviceBadge({ state, numAdvice, variant, isActive, onClick }: AdviceBadgeProps): ReactElement {
	return (
		<Stack
			direction="horizontal"
			size="xxSmall"
			sx={{
				display: 'flex',
				alignItems: 'center',
				px: variant === 'smallest' ? '6px' : variant === 'small' ? '10px' : '16px',
				py: variant === 'smallest' ? '0px' : variant === 'small' ? '1px' : '4px',
				borderRadius: variant === 'small' || variant === 'smallest' ? '16px' : '4px',
				bg:
					state === 'ACTION_NEEDED' ? 'coral' : state === 'VALIDATION_NEEDED' ? 'experimentWarning' : 'feedbackSuccess',
				m: '2px',

				border: '2px solid transparent',
				outline: '2px solid transparent',

				...(isActive
					? {
							outline: '2px solid ' + theme.colors.slate,
							border: '2px solid white',
					  }
					: {}),

				...(onClick
					? {
							cursor: 'pointer',
							':hover': {
								outline: '2px solid ' + theme.colors.slate,
								border: '2px solid white',
							},
					  }
					: {}),
			}}
			onClick={onClick}
		>
			<AdviceIcon variant={variant === 'smallest' ? 'xSmall' : 'small'} state={state} />
			<Text variant={variant === 'smallest' ? 'xSmallStrong' : 'smallStrong'} color="neutral000">
				{numAdvice}
			</Text>
		</Stack>
	);
}

interface AdviceBadgesRetrieverProps {
	targetReference: string;
	floating?: boolean;
	type: string;
}

export function AdviceBadgesRetriever({
	type,
	targetReference,
	floating,
}: AdviceBadgesRetrieverProps): ReactElement | null {
	const [updateSignal, setUpdateSignal] = useState(0);
	const adviceResult = usePromise(
		() => Services.adviceApi.getAdvice(type, targetReference),
		[type, targetReference, updateSignal],
	);

	useEventEffect(
		(e) => {
			if (e.targetReference === targetReference) {
				setUpdateSignal((signal) => signal + 1);
			}
		},
		['advice-updated'],
		() => {},
		[type, targetReference],
	);

	if (!adviceResult.value) {
		return null;
	}

	return <AdviceBadges variant="small" result={adviceResult.value} floating={floating} />;
}

export function GeneralAdvice(): ReactElement {
	return (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',

				width: '20px',
				height: '20px',
				backgroundColor: 'slate',
				borderRadius: '50%',
			}}
		>
			<IconAdviceGeneral variant="xSmall" color="neutral000" />
		</Container>
	);
}
