/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import TargetDetailsModal from 'targets/TargetDetails/TargetDetailsModal';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { ReactElement, useEffect, useRef } from 'react';
import { useUrlState } from 'url/useUrlState';
import { useFormikContext } from 'formik';
import { TargetId } from 'targets/util';

import { targetIdParam } from './urlParams';
import { LandscapeConfig } from './types';

interface TargetIdUrlState {
	targetId: TargetId | null;
}

interface TargetModalProps {
	environmentId: string;
}

export default function TargetModal({ environmentId }: TargetModalProps): ReactElement | null {
	const [{ targetId }, , updateUrlState] = useUrlState<TargetIdUrlState>([targetIdParam]);
	const formik = useFormikContext<LandscapeConfig>();
	const { showAdvice } = formik.values;

	if (!targetId) {
		return null;
	}

	return (
		<DataReloadHandler>
			<TargetDetailsModal
				onClose={() => updateUrlState({ targetId: null })}
				defaultToAdvicePage={showAdvice}
				environmentId={environmentId}
				withAdvice={showAdvice}
				targetId={targetId}
			/>
		</DataReloadHandler>
	);
}

interface DataReloadHandlerProps {
	children: ReactElement;
}

function DataReloadHandler({ children }: DataReloadHandlerProps): ReactElement {
	const formik = useFormikContext<LandscapeConfig>();
	const shouldReload = useRef<boolean>(false);
	useEventEffect(
		() => {
			shouldReload.current = true;
		},
		['advice-updated'],
		() => {},
		[shouldReload.current],
	);

	useEffect(() => {
		return () => {
			if (shouldReload.current) {
				formik.setFieldValue('reloadSignal', formik.values.reloadSignal + 1);
			}
		};
	}, []);

	return children;
}
