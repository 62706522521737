/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ReactElement, ReactNode } from 'react';
import { Container } from 'components';

interface LoadingContentProps {
	children: ReactNode;
}

export default function EmptyContent({ children }: LoadingContentProps): ReactElement {
	return (
		<Container
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			p="xLarge"
			color="neutral500"
		>
			{children}
		</Container>
	);
}
