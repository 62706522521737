/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { useTenant } from '../tenancy/useTenant';
import { useUser } from './usersApi';

/**
 * Need to set a feature flag for development only? Update .env.development and restart the dev server!
 */
type FeatureFlag = 'disable_analytics' | 'webhookPreflight' | 'targetSelectonReworkEnabled' | 'customLabelingEnabled';

export default function useFeatureFlag(featureFlag: FeatureFlag): boolean {
	if (typeof process.env.REACT_APP_FEATURE_FLAGS === 'string') {
		if (process.env.REACT_APP_FEATURE_FLAGS.split(',').indexOf(featureFlag) >= 0) {
			return true;
		}
	}

	const user = useUser();
	const tenant = useTenant();

	const value = user?.features?.includes(featureFlag) || tenant?.enabledFeatures?.includes(featureFlag);

	// For this feature we agreed on having a special handling. The FF controls, weather it is allowed to show a custom logo, e.g. on the splash screen.
	// The splash screen is shown, while we are loading the user & tenant, so we cannot check the real value of the FF before the splash screen is shown.
	// Therefore, we said, the FF is true while loading the user & tenant.
	if (featureFlag === 'customLabelingEnabled' && value == undefined) {
		return true;
	}

	return value;
}
