/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React from 'react';

export type DialogFooterProps = ContainerProps;

export const DialogFooter: React.FC<DialogFooterProps> = ({ children, ...props }) => {
	return (
		<Container display={'flex'} flex="0 0 auto" px={'medium'} my={'large'} {...props}>
			{children}
		</Container>
	);
};
