/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react-markdown/lib/react-markdown';
import { Container } from 'components';

import Dashboard from './dashboard';

interface DashboardProps {
	renderAgentInstallBanner?: boolean;
}

export default function DashboardIndex({ renderAgentInstallBanner }: DashboardProps): ReactElement {
	return (
		<Container
			as={'main'}
			sx={{
				gridArea: 'main',
				overflow: 'auto',
				position: 'relative',
			}}
		>
			<Dashboard renderAgentInstallBanner={renderAgentInstallBanner} />
		</Container>
	);
}
