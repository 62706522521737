/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components/Container';
import { ReactElement } from 'react';

type UlProps = ContainerProps;

export default function Ul({ children, sx = {}, ...props }: UlProps): ReactElement {
	return (
		<Container as="ul" sx={{ listStyle: 'none', p: 0, m: 0, ...sx }} {...props}>
			{children}
		</Container>
	);
}
