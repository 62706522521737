/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { FieldHookConfig, useField, useFormikContext } from 'formik';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import Dropdown from 'components/Select/Dropdown/Dropdown';
import { Container, Text } from 'components';

import { NumberInput, NumberInputProps } from '../NumberInput';
import { hasError, smellsLikeEnvVar } from './utils';
import Variables from './Variables';

export type FormikNumberInputProps = Omit<NumberInputProps, 'css' | 'error' | 'touched'>;

export function FormikNumberInput({ flex, my, mx, mt, mb, ml, mr, ...props }: FormikNumberInputProps): ReactElement {
	const formik = useFormikContext();
	const [field, meta] = useField({ type: 'number', ...props } as FieldHookConfig<number>);

	return (
		<Dropdown<string | number>
			onValueChanged={(value) => {
				formik.setFieldTouched(field.name, true, false);
				formik.setFieldValue(field.name, value);
			}}
			renderComponent={({ setShowMenu, ref, onValueChanged }) => {
				return (
					<Container
						ref={ref}
						display={'flex'}
						flexDirection={'column'}
						my={my}
						mx={mx}
						mt={mt}
						mb={mb}
						ml={ml}
						mr={mr}
						flex={flex}
					>
						<NumberInput
							hasError={hasError(meta)}
							{...field}
							onChange={(v) => onValueChanged?.(v)}
							onFocus={() => setShowMenu(true)}
							{...meta}
							{...props}
							type={smellsLikeEnvVar(field.value) ? 'string' : 'number'}
						/>

						{hasError(meta) ? (
							<Text mt={'xxSmall'} variant={'smallStrong'} color={'coral'} data-formik-error>
								{meta.error}
							</Text>
						) : null}
					</Container>
				);
			}}
		>
			{({ width, selectItem }) => <Variables width={width} onSelect={(v) => selectItem(v)} />}
		</Dropdown>
	);
}
