/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { AdviceDefinitionOnAgentInfoVO, AdviceDefinitionSummaryVO, AdviceVO, ListResponse } from 'ui-api';
import axios from 'axios';

export class AdviceDefinitionsApi {
	async deleteAdvice(id: string): Promise<void> {
		await axios.delete(`/ui/adviceDefinition/${id}`);
	}

	async fetchAdviceOnAgentInfo(id: string): Promise<ListResponse<AdviceDefinitionOnAgentInfoVO>> {
		return (await axios.get<ListResponse<AdviceDefinitionOnAgentInfoVO>>(`/ui/adviceDefinition/${id}/agents`)).data;
	}

	async fetchAdvice(id: string): Promise<AdviceVO> {
		return (await axios.get<AdviceVO>(`/ui/adviceDefinition/${id}`)).data;
	}

	async fetchAdviceSummaries(): Promise<ListResponse<AdviceDefinitionSummaryVO>> {
		return (await axios.get<ListResponse<AdviceDefinitionSummaryVO>>('/ui/adviceDefinition/summaries')).data;
	}
}
