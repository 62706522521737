/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { useScrollPosition } from 'utils/hooks/useScrollPosition';
import { Container } from 'components/Container';
import { ReactElement } from 'react';

interface ScrollContainerProps {
	children: (fromChild: number, toChild: number) => ReactElement;

	// we give ourself this restiction to not overcomplicate things for now
	height: number;
	heightPerChild: number;
}

export default function ScrollContainer({ height, heightPerChild, children }: ScrollContainerProps): ReactElement {
	const { scrollTop, ref } = useScrollPosition();

	const fromChild = Math.floor(scrollTop / heightPerChild);
	const numChildrenrendered = Math.ceil(height / heightPerChild);
	const toChild = fromChild + numChildrenrendered;

	return (
		<Container
			ref={ref}
			sx={{
				height,
				p: 'xSmall',
				variant: 'text.small',
				whiteSpace: 'pre',
				fontFamily: 'code',
				overflow: 'auto',
				color: 'neutral700',
				'& > div > span': {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				},
			}}
		>
			{children(fromChild, toChild)}
		</Container>
	);
}
