/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Services } from 'services/services';
import { useCallback } from 'react';

import { useEventEffect } from './useEventEffect';
import { useAsyncState } from './useAsyncState';

interface Result {
	someAgentHasReportedInThePast: boolean;
	loading: boolean;
}

export default function useAgentReport(): Result {
	const [lastReportedAgent, fetchLastReportedAgent] = useAsyncState(() => Services.agents.fetchLastReported(), []);
	useEventEffect(
		useCallback(() => {
			if (!lastReportedAgent.value?.registered) {
				fetchLastReportedAgent();
			}
		}, [fetchLastReportedAgent, lastReportedAgent]),
		['agent.registered'],
	);

	let someAgentHasReportedInThePast = true;
	if (!lastReportedAgent.loading && lastReportedAgent.value && !lastReportedAgent.value?.registered) {
		someAgentHasReportedInThePast = false;
	}
	return {
		someAgentHasReportedInThePast,
		loading: lastReportedAgent.loading,
	};
}
