/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useStableInstance } from 'utils/hooks/useStableInstance';
import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';

import { UseTemplateFormData } from './UseTemplateForm';
import { replaceMarkers } from './utils';

export default function PlaceholderSyncJob(): null {
	const formik = useFormikContext<UseTemplateFormData>();
	const { placeholderValuesMap, variableValuesMap } = formik.values;

	const [debounceSync] = useState(() =>
		debounce(
			async (v) => {
				formik.setValues(
					replaceMarkers({ formData: v, replaceEmptyValues: true, replacePlaceholders: true, replaceVariables: true }),
				);
			},
			250,
			{ leading: true },
		),
	);

	const tpHash = useStableInstance(placeholderValuesMap);
	const evHash = useStableInstance(variableValuesMap);

	useEffect(() => {
		debounceSync(formik.values);
	}, [tpHash, evHash]);

	return null;
}
