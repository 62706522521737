/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React from 'react';

import { IconRoundSpinner, IconRoundSpinnerWhite } from '../icons';

export interface SpinnerProps extends ContainerProps {
	variant?: string;
	white?: boolean;
}

export const Spinner: React.FC<SpinnerProps> = ({ white = false, variant = 'small', sx = {}, ...props }) => {
	return (
		<Container sx={{ flex: '0 0 auto', alignSelf: 'center', ...sx }} {...props} data-cy={'spinner'}>
			{white ? (
				<IconRoundSpinnerWhite flex="0 0 auto" variant={variant} />
			) : (
				<IconRoundSpinner flex="0 0 auto" variant={variant} />
			)}
		</Container>
	);
};
