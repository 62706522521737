/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { LandscapeTargetDescriptionVO } from 'ui-api';

import {
	ColorConfig,
	ColorListEntry,
	ColorMapping,
	Group,
	LandscapeGroup,
	LandscapeGroupWithTargets,
	LandscapeTarget,
	LandscapeTargetAttribute,
	LandscapeTargetBase,
} from './types';
import { LayoutedGroup, LayoutedTarget } from './TargetBubbleMap/types';
import layout from './circleLayouter';

const collator = new Intl.Collator('en-US');
function localeCompare(a: string, b: string): number {
	return collator.compare(a, b);
}

export function getSharedAttributes(group: LandscapeGroup): LandscapeTargetAttribute[] {
	if (!group.targets || group.targets.length <= 1) {
		return [];
	}

	const attributeCount = new Map<string, number>();
	const attributes = new Map<string, LandscapeTargetAttribute>();

	for (const target of group.targets) {
		for (const attribute of target.attributes) {
			const k = attribute.key + '-' + attribute.value;
			attributeCount.set(k, (attributeCount.get(k) || 0) + 1);
			attributes.set(k, attribute);
		}
	}

	return Array.from(attributes.values()).filter((attribute) => {
		const k = attribute.key + '-' + attribute.value;
		return attributeCount.get(k) === group.targets.length;
	});
}

export function getIconMap(icons?: LandscapeTargetDescriptionVO[]): Map<string, string> {
	if (!icons) {
		return new Map();
	}

	const iconMap = new Map<string, string>();
	icons.forEach(({ type, icon }) => {
		iconMap.set(type, icon);
	});
	return iconMap;
}

export function getGroupingLabelFromUrl(key: Group): Group {
	if (key.startsWith('attribute=')) {
		return key.substring('attribute='.length);
	}
	return key;
}

export const colors: { [index: string]: [number, number, number] } = {
	red: [255, 135, 135],
	orangeDark: [255, 153, 113],
	orangeLight: [255, 175, 105],
	yellow: [255, 200, 104],
	green: [121, 224, 150],
	tiffany: [100, 223, 188],
	teal: [82, 200, 197],
	blueLight: [105, 205, 223],
	blue: [116, 193, 255],
	plum: [150, 169, 255],
	berries: [171, 158, 255],
	violet: [222, 147, 255],
	rosePink: [252, 159, 252],
	pink: [255, 142, 189],
	greyDefault: [204, 212, 221],
};

export function getColorMapping(
	colorConfig: ColorConfig | null,
	colorOverrides: { [index: string]: string },
	attributes: LandscapeTargetAttribute[],
): ColorMapping {
	const predefinedColors = [
		colors.blueLight,
		colors.orangeLight,
		colors.plum,
		colors.orangeDark,
		colors.pink,
		colors.teal,
		colors.yellow,
		colors.violet,
		colors.rosePink,
		colors.blue,
		colors.berries,
		colors.tiffany,
		colors.red,
		colors.green,
	];

	//  override values with buckets
	const attributeValueToBucketName: { [index: string]: string } = {};
	if (colorConfig) {
		colorConfig.mappings.forEach((mapping) => {
			mapping.attributeValues.forEach((attributeValue) => {
				attributeValueToBucketName[attributeValue] = mapping.groupName;
			});
		});
	}

	const colorsFromConfigs = Array.from(new Set(Object.values(attributeValueToBucketName)));
	const uniqueValues = Array.from(new Set(attributes.map((a) => a.value))).filter(
		(a) => !attributeValueToBucketName[a],
	);

	const colorList: Array<ColorListEntry> = [...colorsFromConfigs, ...uniqueValues]
		.map((label, i) => {
			let color: [number, number, number] = predefinedColors[i % predefinedColors.length];
			if (colorOverrides[label]) {
				color = decodeColor(colorOverrides[label]);
			}
			return { label, color };
		})
		.sort((a, b) => localeCompare(a.label, b.label));

	const colorAsMap: { [index: string]: [number, number, number] } = {};
	colorList.forEach((entry) => (colorAsMap[entry.label] = entry.color));

	return {
		colorList,
		colorAsMap,
		attributeValueToBucketName,
	};
}

export function resolveColorFromMapping(
	attributeValue: string,
	{ attributeValueToBucketName, colorAsMap }: ColorMapping,
): [number, number, number] {
	const valueToBucket = attributeValueToBucketName[attributeValue] || attributeValue;
	const bucketToColor = colorAsMap[valueToBucket];
	if (!bucketToColor) {
		return colors.greyDefault;
	}
	return bucketToColor;
}

export function getTargets(groups: LandscapeGroup[]): LandscapeTarget[] {
	function getTargetsInternal(targets: LandscapeTarget[], groups: LandscapeGroup[]): void {
		for (let i = 0; i < groups.length; i++) {
			const group = groups[i];
			if (group.groups) {
				getTargetsInternal(targets, group.groups);
			} else {
				for (let i2 = 0; i2 < group.targets.length; i2++) {
					targets.push(group.targets[i2]);
				}
			}
		}
	}

	const visibleTargets: LandscapeTarget[] = [];
	getTargetsInternal(visibleTargets, groups);
	return visibleTargets;
}

export function getTargetsLayouted(groups: LayoutedGroup[]): LayoutedTarget[] {
	function getTargetsLayoutedInternal(targets: LayoutedTarget[], groups: LayoutedGroup[]): void {
		for (let i = 0; i < groups.length; i++) {
			const group = groups[i];
			if (group.layoutedGroups) {
				getTargetsLayoutedInternal(targets, group.layoutedGroups);
			} else {
				for (let i2 = 0; i2 < group.layoutedTargets.length; i2++) {
					targets.push(group.layoutedTargets[i2]);
				}
			}
		}
	}

	const visibleTargets: LayoutedTarget[] = [];
	getTargetsLayoutedInternal(visibleTargets, groups);
	return visibleTargets;
}

export function countGroups(groups: LandscapeGroup[]): number {
	let count = 0;
	for (let i = 0; i < groups.length; i++) {
		const group = groups[i];
		if (group.groups) {
			count += countGroups(group.groups);
		} else {
			count++;
		}
	}
	return count;
}

export function getLayoutedTargets(groups: LayoutedGroup[]): LayoutedTarget[] {
	const visibleTargets: LayoutedTarget[] = [];
	getLayoutedTargetsInternal(visibleTargets, groups);
	return visibleTargets;
}

function getLayoutedTargetsInternal(targets: LayoutedTarget[], groups: LayoutedGroup[]): void {
	for (let i = 0; i < groups.length; i++) {
		const group = groups[i];
		if (group.layoutedGroups) {
			getLayoutedTargetsInternal(targets, group.layoutedGroups);
		} else {
			for (let i2 = 0; i2 < group.layoutedTargets.length; i2++) {
				targets.push(group.layoutedTargets[i2]);
			}
		}
	}
}

export function decodeColor(color: string): [number, number, number] {
	return color
		.slice('rgb('.length)
		.split(',')
		.map((c) => parseInt(c.trim(), 10)) as [number, number, number];
}

export function getSize(sizeBy: string, target: LandscapeTargetBase): number {
	if (!sizeBy) {
		return 1;
	}
	return target.attributes.filter((a) => a.key === sizeBy).length || 1;
}

export function applyTargetPropertiesToGroups(groups: LandscapeGroup[]): void {
	for (let i = 0; i < groups.length; i++) {
		const group = groups[i];
		if (group.groups) {
			applyTargetPropertiesToGroups(group.groups);
			if (allGroupsHaveTheSameColor(group.groups)) {
				group.color = group.groups[0].color;
			}
			group.adviceRequireValidation = group.groups.reduce((acc, g) => acc + (g.adviceRequireValidation || 0), 0);
			group.adviceRequireAction = group.groups.reduce((acc, g) => acc + (g.adviceRequireAction || 0), 0);
			group.adviceDone = group.groups.reduce((acc, g) => acc + (g.adviceDone || 0), 0);
		} else {
			if (allTargetsHaveTheSameColor(group.targets)) {
				group.color = group.targets[0].color;
			} else {
				group.color = colors.greyDefault;
			}
			group.adviceRequireValidation = group.targets.reduce((acc, t) => acc + t.adviceRequireValidation, 0);
			group.adviceRequireAction = group.targets.reduce((acc, t) => acc + t.adviceRequireAction, 0);
			group.adviceDone = group.targets.reduce((acc, t) => acc + t.adviceDone, 0);
			group.totalUniqueTargets = group.targets.reduce((acc, t) => {
				acc.add(t.id);
				return acc;
			}, new Set<string>()).size;
		}
	}
}

function allTargetsHaveTheSameColor(targets: LandscapeTarget[]): boolean {
	if (targets.length === 0) {
		return false;
	}
	const color = targets[0].color;
	return targets.every((t) => t.color[0] === color[0] && t.color[1] === color[1] && t.color[2] === color[2]);
}

function allGroupsHaveTheSameColor(groups: LandscapeGroup[]): boolean {
	if (groups.length === 0) {
		return false;
	}
	const color = groups[0].color;
	return groups.every((g) => g.color[0] === color[0] && g.color[1] === color[1] && g.color[2] === color[2]);
}

export function layoutGroups(groups: LandscapeGroup[], distance: number): [groups: LayoutedGroup[], radius: number] {
	const layoutedGroups: LayoutedGroup[] = groups.map((group) => {
		if (group.groups) {
			const [subGroups, radius] = layoutGroups(group.groups, 1);
			const g = {
				x: 0,
				y: 0,
				r: radius,
				group,
				layoutedGroups: subGroups,
				parent: null,
			};
			subGroups.forEach((subG) => (subG.parent = g));
			return g;
		}

		return layoutTargets(group);
	});

	const { nodes, radius } = layout(
		layoutedGroups.map((g) => g.r),
		distance,
	);
	for (let i = 0; i < nodes.length; i++) {
		const node = nodes[i];
		applyPositionToGroup(layoutedGroups[i], node.x, node.y);
	}

	return [layoutedGroups, radius];
}

function layoutTargets(group: LandscapeGroupWithTargets): LayoutedGroup {
	const { nodes, radius } = layout(
		group.targets.map((t) => t.size),
		0.05,
	);
	const g: LayoutedGroup = {
		x: 0,
		y: 0,
		r: radius + 0.5,
		group,
		layoutedTargets: [],
		parent: null,
	};
	g.layoutedTargets = nodes.map(
		(node, i): LayoutedTarget => ({
			...node,
			target: group.targets[i],
			parent: g,
		}),
	);
	return g;
}

function applyPositionToGroup(g: LayoutedGroup, x: number, y: number): void {
	g.x += x;
	g.y += y;
	if (g.layoutedTargets) {
		g.layoutedTargets.forEach((t) => {
			t.x += x;
			t.y += y;
		});
	}

	if (g.layoutedGroups) {
		g.layoutedGroups.forEach((subG) => {
			applyPositionToGroup(subG, x, y);
		});
	}
}

export function centerGroups(groups: LayoutedGroup[]): void {
	let left = 0;
	let right = 0;
	let top = 0;
	let bottom = 0;

	for (let i = 0; i < groups.length; i++) {
		const { x, y, r } = groups[i];
		left = Math.min(left, x - r);
		right = Math.max(right, x + r);
		top = Math.min(top, y - r);
		bottom = Math.max(bottom, y + r);
	}
	const dx = (right - left) / 2 - right;
	const dy = (bottom - top) / 2 - bottom;

	for (let i = 0; i < groups.length; i++) {
		shiftGroup(groups[i], dx, dy);
	}
}

function shiftGroup(group: LayoutedGroup, dx: number, dy: number): void {
	group.x += dx;
	group.y += dy;
	if (group.layoutedGroups) {
		group.layoutedGroups.forEach((g) => shiftGroup(g, dx, dy - 0.8));
	}
	if (group.layoutedTargets) {
		group.layoutedTargets.forEach((t) => {
			t.x += dx;
			t.y += dy;
		});
	}
}

export function getAttributes(targets: LandscapeTargetBase[]): LandscapeTargetAttribute[] {
	const attributes: LandscapeTargetAttribute[] = [];
	let c = 0;
	for (let i = 0; i < targets.length; i++) {
		const target = targets[i];
		for (let iA = 0; iA < target.attributes.length; iA++) {
			const attribute = target.attributes[iA];
			attributes[c++] = attribute;
		}
	}
	return attributes;
}

export function getAllGroups(groups: LayoutedGroup[]): LayoutedGroup[] {
	const all = [];
	for (let i = 0; i < groups.length; i++) {
		const group = groups[i];
		all.push(group);
		all.push(...getSubgroups(group));
	}
	return all;
}

function getSubgroups(group: LayoutedGroup): LayoutedGroup[] {
	if (!group.layoutedGroups) {
		return [];
	}
	const subgroups = [];
	for (let i = 0; i < group.layoutedGroups.length; i++) {
		const subGroup = group.layoutedGroups[i];
		subgroups.push(subGroup);
		subgroups.push(...getSubgroups(subGroup));
	}
	return subgroups;
}

export function getAllLandscapeGroups(groups: LandscapeGroup[]): LandscapeGroup[] {
	const all = [];
	for (let i = 0; i < groups.length; i++) {
		const group = groups[i];
		all.push(group);
		all.push(...getLandscapeSubgroups(group));
	}
	return all;
}

function getLandscapeSubgroups(group: LandscapeGroup): LandscapeGroup[] {
	if (!group.groups) {
		return [];
	}
	const subgroups = [];
	for (let i = 0; i < group.groups.length; i++) {
		const subGroup = group.groups[i];
		subgroups.push(subGroup);
		subgroups.push(...getLandscapeSubgroups(subGroup));
	}
	return subgroups;
}

export function hasAdvice(entity: LandscapeTarget | LandscapeGroup): boolean {
	return entity.adviceRequireAction > 0 || entity.adviceRequireValidation > 0 || entity.adviceDone > 0;
}
