/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Button, Container, Text } from 'components';
import React from 'react';

export const KillSwitch: React.VFC<{ handleEngageClick: () => void }> = ({ handleEngageClick }) => {
	return (
		<Container
			display={'flex'}
			flexDirection={'row'}
			alignItems={'center'}
			bg={'neutral100'}
			height={'80px'}
			width={'100%'}
			px={'medium'}
			sx={{
				borderBottomLeftRadius: 3,
				borderBottomRightRadius: 3,
			}}
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			<Text variant={'small'} color={'neutral600'} mr={'xSmall'}>
				You can stop all executions and temporarily disable Steadybit for all users.
			</Text>
			<Button
				width={142}
				height={42}
				sx={{
					px: 'xxSmall',
					alignItems: 'center',
					justifyContent: 'start',
					bg: 'coral',
					flexShrink: 0,
				}}
				onClick={handleEngageClick}
			>
				<Text variant={'mediumStrong'} pl={'xSmall'} noWrap={true}>
					Emergency Stop
				</Text>
			</Button>
		</Container>
	);
};
