/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { IconGroup, IconInformation, IconTags, IconTarget } from 'components/icons';
import { ReactElement, useEffect, useState } from 'react';
import { Container, Stack, Text } from 'components';
import { useFormikContext } from 'formik';
import { theme } from 'styles.v2/theme';

import {
	LayoutedGroup,
	LayoutedTarget,
	isDragStartEvent,
	isDragStopEvent,
	isHoverEntityEvent,
	isLayoutedTarget,
	isMouseMoveEvent,
} from './types';
import { getSteadybitLabelOrDefault } from './SelectedEntitySidebar/utils';
import { LandscapeConfig, isLandscapeGroupWithTargets } from '../types';
import TargetTypeBadge from './SelectedEntitySidebar/TargetTypeBadge';
import AdviceBadges from '../../../components/advice/AdviceBadges';
import EntityLabel from './SelectedEntitySidebar/EntityLabel';
import { event$ } from '../ServiceLocator';
import { GroupLabel } from './GroupLabels';
import Tag from './Tag';

interface HoveredEntityTooltipProps {
	icons: Map<string, string>;
}

export default function HoveredEntityTooltip({ icons }: HoveredEntityTooltipProps): ReactElement | null {
	const formik = useFormikContext<LandscapeConfig>();
	const { sizeBy, showAdvice } = formik.values;

	const [hoveredEntity, setHoveredEntity] = useState<LayoutedTarget | LayoutedGroup | null>(null);
	const [render, setRender] = useState(true);

	useEffect(() => {
		let mouseX = 0;
		let mouseY = 0;
		let h: LayoutedTarget | LayoutedGroup | null = null;
		const subscription = event$().subscribe((event) => {
			if (isDragStartEvent(event)) {
				setRender(false);
			} else if (isDragStopEvent(event)) {
				setRender(true);
			} else if (isHoverEntityEvent(event)) {
				setHoveredEntity(event.entity);
				h = event.entity;
				position(mouseX, mouseY, h);
			} else if (isMouseMoveEvent(event)) {
				mouseX = event.x;
				mouseY = event.y;
				position(mouseX, mouseY, h);
			}
		});
		return () => {
			subscription.unsubscribe();
		};
	}, [event$]);

	if (!hoveredEntity || !render) {
		return null;
	}

	const isTarget = isLayoutedTarget(hoveredEntity);

	return (
		<Stack
			id="tooltip"
			size="xSmall"
			sx={{
				position: 'fixed',

				px: 'medium',
				py: 'small',
				bg: 'neutral000',
				borderRadius: 4,
				width: sizeBy && isTarget ? 420 : 320,
				zIndex: 1,
				transform: 'translate3D(-10000px, -10000px, 0px)',
				boxShadow: theme.shadows.applicationMedium,

				'&::after': {
					content: '""',
					position: 'absolute',
					bottom: 0,
					left: 'calc(50% - 8px)',
					width: 0,
					height: 0,
					borderLeft: '8px solid transparent',
					borderRight: '8px solid transparent',
					borderTop: '8px solid #fff',
					transform: 'translate3D(0, 100%, 0)',
				},
			}}
		>
			{isTarget ? (
				<>
					<EntityLabel label={getSteadybitLabelOrDefault(hoveredEntity.target, hoveredEntity.target.label)} />
					{showAdvice && <AdviceBadges {...hoveredEntity.target} />}
					<TargetTypeBadge
						type={hoveredEntity.target.resolvedTypeLabel || hoveredEntity.target.type}
						icon={icons.get(hoveredEntity.target.type)}
					/>
					<Tag Icon={IconTags} label="Attributes" number={hoveredEntity.target.attributes.length} />
					{sizeBy && (
						<Tag Icon={IconTags} label={`# of different values for ${sizeBy}`} number={hoveredEntity.target.size} />
					)}
					{((hoveredEntity.target.duplicatedByGroupings?.length || 0) > 1 ||
						hoveredEntity.target.duplicatedByColor) && <DuplicationInfo />}
				</>
			) : (
				<>
					<Container display="flex">
						<GroupLabel group={hoveredEntity.group} />
					</Container>
					{showAdvice && <AdviceBadges {...hoveredEntity.group} />}
					{hoveredEntity.layoutedTargets && isLandscapeGroupWithTargets(hoveredEntity.group) && (
						<Tag Icon={IconTarget} label="Targets" number={hoveredEntity.group.totalUniqueTargets || 0} />
					)}
					{hoveredEntity.layoutedGroups && (
						<Tag Icon={IconGroup} label="Groups" number={hoveredEntity.layoutedGroups.length} />
					)}
				</>
			)}
		</Stack>
	);
}

function position(x: number, y: number, entity: LayoutedTarget | LayoutedGroup | null): void {
	if (!entity) {
		return;
	}
	const tooltip = document.getElementById('tooltip');
	if (tooltip) {
		if (x < 0 || y < 0) {
			tooltip.style.transform = `translate3D(${-10000}px, ${-10000}px, 0)`;
		} else {
			const rect = tooltip.getBoundingClientRect();
			tooltip.style.transform = `translate3D(${x - (rect.right - rect.left) / 2}px, ${
				y - tooltip.offsetHeight - 24
			}px, 0)`;
		}
	}
}

function DuplicationInfo(): ReactElement {
	return (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'flex-start',
				gap: '6px',

				backgroundColor: 'neutral100',
				px: 'xSmall',
				py: 'xSmall',
				borderRadius: 4,
			}}
		>
			<IconInformation minWidth={16} minHeight={16} />
			<Text variant="small" color="neutral800">
				This target appears multiple times due to your view configuration.
			</Text>
		</Container>
	);
}
