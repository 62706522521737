/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, Checkbox, Heading, ModalContentV2, ModalOverlay, ModalV2, Stack, Text } from 'components';
import { lazy, ReactElement, Suspense, useState } from 'react';
import { useTenant } from 'tenancy/useTenant';
import { get, set } from 'utils/localStorage';

const TutorialSteps = lazy(() => import('./TutorialSteps'));

export default function WelcomeToTemplatesModal(): ReactElement {
	const tenant = useTenant();
	const skipWelcomeToTemplatesScreen = get(tenant.key, 'showWelcomeToTemplatesScreen') === 'false';
	const [showWelcomeScreen, setShowWelcomeScreen] = useState(!skipWelcomeToTemplatesScreen);
	const [checked, setChecked] = useState(false);

	return (
		<ModalOverlay open={showWelcomeScreen} centerContent>
			<ModalV2 withFooter width="795px">
				<ModalContentV2>
					<Stack size="large" alignItems="center" mb="xLarge">
						<Stack size="xSmall" alignItems="center" mt="large">
							<Heading variant="xLarge" color="slateDark">
								Welcome to Experiment Templates!
							</Heading>

							<Text as="span" variant="medium" color="slateDark">
								Just follow three easy steps to create a new template:
							</Text>
						</Stack>

						<Suspense fallback={<></>}>
							<TutorialSteps />
						</Suspense>

						<Button
							onClick={() => {
								if (checked) {
									set(tenant.key, 'showWelcomeToTemplatesScreen', 'false');
								}
								setShowWelcomeScreen(false);
							}}
							data-cy="start-button"
						>
							Let’s start! 🚀
						</Button>

						<Stack direction="horizontal" size="xSmall" alignItems="center">
							<Checkbox onChange={(e) => setChecked(Boolean(e.target.checked))} checked={checked} />
							<Text variant="medium" color="neutral800">
								Don’t show these tips anymore
							</Text>
						</Stack>
					</Stack>
				</ModalContentV2>
			</ModalV2>
		</ModalOverlay>
	);
}
