/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import Markdown from 'components/Markdown/Markdown';
import { Message } from 'components';
import { ReactElement } from 'react';
import { HintVO } from 'ui-api';

interface ExperimentActionHintProps {
	hint?: HintVO;
}

export default function ExperimentActionHint({ hint }: ExperimentActionHintProps): ReactElement | null {
	if (!hint || !hint.content) {
		return null;
	}
	return (
		<Message variant={hint.type === 'WARNING' ? 'warning' : 'info'}>
			<Markdown content={hint.content} />
		</Message>
	);
}
