/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Stack } from 'components/Stack';
import React from 'react';

import { IconChevronDown, IconChevronUp } from '../icons';
import { Button, ButtonProps } from '../Button';

export interface TableSortProps extends Omit<ButtonProps, 'css'> {
	sort?: 'asc' | 'desc';
}

export const TableSort: React.FC<TableSortProps> = ({ sort, children, variant = 'chromeless', sx = {}, ...props }) => {
	return (
		<Button
			variant={'chromeless'}
			{...props}
			sx={{
				display: 'inline-flex',
				alignItems: 'center',
				color: sort ? (variant === 'chromeless' ? 'neutral800' : 'neutral200') : 'inherit',
				textTransform: 'inherit',
				':hover': {
					color: variant === 'chromeless' ? 'neutral800' : 'neutral200',
					textDecoration: 'none',
				},
				...sx,
			}}
		>
			{children}
			<Stack direction="vertical" size={0}>
				<IconChevronUp
					ml="xxSmall"
					mb={-3}
					variant="xSmall"
					width={10}
					height={10}
					color={sort === 'asc' ? 'neutral800' : 'neutral400'}
					sx={{
						stroke: sort === 'asc' ? 'neutral800' : undefined,
					}}
				/>
				<IconChevronDown
					ml="xxSmall"
					variant="xSmall"
					width={10}
					height={10}
					color={sort === 'desc' ? 'neutral800' : 'neutral400'}
					sx={{
						stroke: sort === 'desc' ? 'neutral800' : undefined,
					}}
				/>
			</Stack>
		</Button>
	);
};
