/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import React, { useCallback, useMemo, useState } from 'react';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { useDropzone } from 'react-dropzone';
import { Services } from 'services/services';
import { Container } from 'components';

import { ButtonIcon } from '../ButtonIcon';
import { Snackbar } from '../Snackbar';
import { FileLink } from './FileLink';
import { IconDelete } from '../icons';
import { Spinner } from '../Spinner';
import { Stack } from '../Stack';
import { Text } from '../Text';

export interface FileUploadProps {
	value?: string;
	onChange?: (value: string | undefined) => void;
	disabled?: boolean;
	accept?: string;
}

export const FileUpload: React.VFC<FileUploadProps> = ({ value, onChange, disabled, accept }) => {
	const [progress, setProgress] = useState<number | undefined>();

	const [filename] = useAsyncState(async () => (value ? Services.files.fetchFilename(value) : undefined), [value]);

	const onDrop = useCallback(
		async (acceptedFiles: File[]) => {
			try {
				const uploadResult = await Services.files.upload(
					acceptedFiles[0],
					(progressEvent) =>
						progressEvent.total && setProgress(Math.round((100 * progressEvent.loaded) / progressEvent.total)),
				);
				onChange?.(uploadResult.fileId);
			} catch (err) {
				onChange?.(undefined);
				Snackbar.error('File could not be uploaded: ' + err.toString(), {
					toastId: 'file-upload-error',
				});
			} finally {
				setProgress(undefined);
			}
		},
		[onChange],
	);
	const onDelete = useCallback(() => {
		//Do not physically delete file on server here. User could cancel experiment edit. File deletions are handled by experiment save.
		onChange?.(undefined);
	}, [onChange]);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept, maxFiles: 1 });

	const style = useMemo(
		() => ({
			borderRadius: 4,
			border: '1px dashed',
			borderColor: isDragActive ? 'slate' : 'neutral300',
			backgroundColor: 'neutral100',
		}),
		[isDragActive],
	);

	return (
		<>
			{value ? (
				<Container display={'flex'} flexDirection={'row'} alignItems={'center'}>
					<Container px={'small'} py={'xSmall'} sx={style} flexGrow={1}>
						<FileLink fileId={value}>
							<Text variant={'small'}>{!filename.loading && filename.value ? filename.value : '...'}</Text>
						</FileLink>
					</Container>
					<ButtonIcon
						variant={'small'}
						flexShrink={0}
						muted
						disabled={disabled}
						onClick={onDelete}
						tooltip={'Delete'}
						color={'neutral600'}
						ml={'auto'}
					>
						<IconDelete />
					</ButtonIcon>
				</Container>
			) : (
				<div {...getRootProps()} style={{ width: '100%' }}>
					<Container display={'flex'} px={'small'} py={'xSmall'} sx={style} flexDirection={'row'} alignItems={'center'}>
						<input {...getInputProps()} />

						{progress ? (
							<>
								<Text variant={'small'} color={'neutral600'}>
									Uploading... ({progress}%)
								</Text>
								<Stack justifyContent={'center'} alignItems={'center'} ml={'xSmall'} flexShrink={0}>
									<Spinner variant="small" color={'neutral600'} />
								</Stack>
							</>
						) : (
							<Text variant={'small'} color={'neutral600'}>
								Drag &apos;n&apos; drop or click here ({accept})
							</Text>
						)}
					</Container>
				</div>
			)}
		</>
	);
};
