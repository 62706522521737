/*
 * Copyright 2019 steadybit GmbH. All rights reserved.
 */

import useTimeoutFn from 'react-use/lib/useTimeoutFn';
import React from 'react';

export const useAutoFocus = <T extends HTMLInputElement>(): React.RefObject<T> => {
	const ref = React.useRef<T>(null);
	useTimeoutFn(() => {
		const el = ref.current;
		el?.focus();
	}, 0);
	return ref;
};
