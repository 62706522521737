/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { getGlobal } from 'utils/localStorage';
import { Container, Text } from 'components';
import { ReactElement } from 'react';

interface ArcadeHelpButtonProps {
	onClick: () => void;
}

export default function ArcadeHelpButton({ onClick }: ArcadeHelpButtonProps): ReactElement | null {
	const showHelpButton = getGlobal('showArcadeOverlay') !== 'never';
	if (!showHelpButton) {
		return null;
	}

	return (
		<Container onClick={onClick} ml="small" minWidth="fit-content">
			<Text
				as="span"
				variant="smallStrong"
				sx={{
					textDecoration: 'none',
					cursor: 'pointer',
					color: 'neutral700',
					whiteSpace: 'nowrap',
					':hover': {
						color: 'slate',
						textDecoration: 'underline',
					},
				}}
			>
				Need help?
			</Text>
		</Container>
	);
}
