/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

export default `
precision highp float;
precision highp int;

uniform float enableAdviceColors;
uniform float progress;
uniform float opacity;

attribute float hasAdvice;
attribute vec3 color;

varying float fOpacity;
varying vec3 vColor;
varying vec2 vUv;

void main()	{
	vUv = uv;
	vColor = color;
	fOpacity = opacity;

	if (enableAdviceColors == 1.0 && hasAdvice == 0.0) {
		vColor = vec3(103.0 / 255.0, 117.0 / 255.0, 134.0 / 255.0);
	}

	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
`;
