/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ReactElement, ReactNode } from 'react';

interface ChromaticIgnoreProps {
	children: ReactNode;
	display?: 'block' | 'inline' | 'contents';
}

/**
 * Marks its children as ignorable so that Chromatic does not try to create visual-diffs.
 */
export function ChromaticIgnore({ children, display = 'contents' }: ChromaticIgnoreProps): ReactElement {
	if (process.env.STORYBOOK === 'true') {
		return (
			<div className="chromatic-ignore" style={{ display }}>
				{children}
			</div>
		);
	}

	return <>{children}</>;
}
