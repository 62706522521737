/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { FieldHookConfig, useField, useFormikContext } from 'formik';
import { ExperimentFormValues } from 'pages/experiments/experiment';
import { DURATION_UNITS, parseDuration } from 'utils/duration';
import Dropdown from 'components/Select/Dropdown/Dropdown';
import { Container, Text } from 'components';
import { ReactElement } from 'react';

import { ParsingUnitField, ParsingUnitFieldProps, UnitFieldUnit } from '../UnitField';
import { hasError, smellsLikeEnvVar } from './utils';
import Variables from './Variables';

export type FormikDurationFieldProps = { units?: UnitFieldUnit[] } & Omit<
	ParsingUnitFieldProps,
	'unit' | 'units' | 'onUnitChange' | 'onChange' | 'parse' | 'ref'
>;

export function FormikDurationField({
	flex,
	my,
	mx,
	mt,
	mb,
	ml,
	mr,
	width,
	units = DURATION_UNITS,
	...props
}: FormikDurationFieldProps): ReactElement {
	const [field, meta, helper] = useField(props as FieldHookConfig<string | undefined>);
	const variables = useFormikContext<ExperimentFormValues>().values.variables;

	return (
		<Dropdown<string>
			onValueChanged={(value) => helper.setValue(value)}
			renderComponent={({ setShowMenu, ref, onValueChanged }) => {
				return (
					<Container
						display={'flex'}
						flexDirection={'column'}
						ref={ref}
						my={my}
						mx={mx}
						mt={mt}
						mb={mb}
						ml={ml}
						mr={mr}
						flex={flex}
						width={width}
					>
						<ParsingUnitField
							{...field}
							{...meta}
							units={units}
							unitSelectorDisabled={smellsLikeEnvVar(field.value)}
							onFocus={() => setShowMenu(true)}
							onChange={({ value = 0, unit }) => {
								value = Number(value);
								value = Math.max(value, 0);
								onValueChanged?.(`${value ? value : 0}${unit.value}`);
								helper.setTouched(true, false);
							}}
							parse={(s) => {
								if (typeof s === 'string' && smellsLikeEnvVar(s)) {
									const variableValue = variables.find((v) => v.key === s.slice(2, -2))?.value;
									if (variableValue) {
										const { unit } = parseDuration(variableValue);
										return { value: s, unit };
									}

									return {
										value: s,
										unit: { label: 'Millisec.', value: '' },
									};
								}
								const { value = '', unit } = parseDuration(s);
								return { value: String(value), unit };
							}}
							type={smellsLikeEnvVar(field.value) ? 'string' : 'number'}
							hasError={hasError(meta)}
							{...props}
						/>
						{hasError(meta) ? (
							<Text mt={'xxSmall'} variant={'smallStrong'} color={'coral'} data-formik-error>
								{meta.error}
							</Text>
						) : null}
					</Container>
				);
			}}
		>
			{({ width, selectItem }) => <Variables width={width} onSelect={(v) => selectItem(v)} />}
		</Dropdown>
	);
}
