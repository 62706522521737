/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Link, LinkProps } from 'url/Link';
import React from 'react';

import { ContextualMenuLink, ContextualMenuLinkProps } from './ContextualMenuLink';

export type ContextualMenuRouterLinkProps = ContextualMenuLinkProps & LinkProps<never>;

export const ContextualMenuRouterLink: React.FC<ContextualMenuRouterLinkProps> = ({ children, ...props }) => (
	<ContextualMenuLink as={Link} {...props}>
		{children}
	</ContextualMenuLink>
);
