/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { MetricIdentityVO, StateOverTimeMetricVO, StateOverTimeWidgetVO, TimeSeriesVO } from 'ui-api';
import { exhaustMap, scan, takeWhile } from 'rxjs/operators';
import { DateReviver, withReviver } from 'services/common';
import { AsyncState } from 'react-use/lib/useAsyncFn';
import { useEffect, useMemo, useRef } from 'react';
import { interval, Observable } from 'rxjs';
import { useObservable } from 'react-use';
import axios from 'axios';

import { Services } from './services';

type GroupedMetricVO = { ts: number; group: string[]; values: Record<string, number> };
export type MetricVO = { ts: number; value: number; labels: Record<string, string> };

export function useGroupedExecutionMetrics(
	executionId: number,
	names: string[],
	groupBy: string[],
	liveUpdate: boolean,
): AsyncState<GroupedMetricVO[]> {
	const live = useRef(liveUpdate);
	const observable = useMemo(() => {
		const observable = Services.executionMetrics.streamGroupedMetrics(executionId, names, groupBy);
		return observable.pipe(takeWhile(() => live.current, true));
	}, [executionId, names, groupBy]);
	const metrics = useObservable(observable, undefined);
	useEffect(() => {
		live.current = liveUpdate;
	}, [liveUpdate]);
	return useMemo(() => ({ value: metrics ?? [], loading: !metrics }), [metrics]);
}

export function useFilteredExecutionMetrics(
	executionId: number,
	name: string,
	label: string,
	labelValue: string,
	liveUpdate: boolean,
): AsyncState<MetricVO[]> {
	const live = useRef(liveUpdate);
	const observable = useMemo(() => {
		const observable = Services.executionMetrics.streamFilteredMetrics(executionId, name, label, labelValue);
		return observable.pipe(takeWhile(() => live.current, true));
	}, [executionId, name, label, labelValue]);
	const metrics = useObservable(observable, undefined);
	useEffect(() => {
		live.current = liveUpdate;
	}, [liveUpdate]);
	return useMemo(() => ({ value: metrics ?? [], loading: !metrics }), [metrics]);
}

function createConstantPool<T>(keyFn: (v: T) => string): (v: T) => T {
	const map = new Map<string, T>();
	return (v: T) => {
		const key = keyFn(v);
		const cached = map.get(key);
		if (cached) {
			return cached;
		} else {
			map.set(key, v);
			return v;
		}
	};
}

export class ExecutionMetricsApi {
	streamGroupedMetrics(executionId: number, names: string[], groupBy: string[]): Observable<GroupedMetricVO[]> {
		let offset = 0;
		const pool = createConstantPool((group: string[]) => group.join(','));
		return interval(1000).pipe(
			exhaustMap(async () => {
				const metrics = await this.fetchGroupedMetrics(executionId, names, groupBy, offset, pool);
				offset += metrics.length;
				return metrics;
			}),
			scan((acc: GroupedMetricVO[], current: GroupedMetricVO[]) => [...acc, ...current], []),
		);
	}

	private async fetchGroupedMetrics(
		executionId: number,
		names: string[],
		groupBy: string[],
		offset?: number,
		groupPool = createConstantPool((group: string[]) => group.join(',')),
	): Promise<GroupedMetricVO[]> {
		const params = new URLSearchParams({ names: names.join(','), groupBy: groupBy.join(',') });
		if (offset) {
			params.set('offset', String(offset));
		}
		const transformResponse = withReviver((name, value) => {
			if (name === 'group' && Array.isArray(value)) {
				return groupPool(value);
			}
			return value;
		});
		return (
			await axios.get<GroupedMetricVO[]>(`/ui/executions/${executionId}/metrics/grouped`, { params, transformResponse })
		).data;
	}

	streamFilteredMetrics(executionId: number, name: string, label: string, labelValue: string): Observable<MetricVO[]> {
		let offset = 0;
		return interval(1000).pipe(
			exhaustMap(async () => {
				const metrics = await this.fetchFilteredMetrics(executionId, name, label, labelValue, offset);
				offset += metrics.length;
				return metrics;
			}),
			scan((acc: MetricVO[], current: MetricVO[]) => [...acc, ...current], []),
		);
	}

	private async fetchFilteredMetrics(
		executionId: number,
		name: string,
		label: string,
		labelValue: string,
		offset?: number,
	): Promise<MetricVO[]> {
		const params = new URLSearchParams({ name, label, labelValue });
		if (offset) {
			params.set('offset', String(offset));
		}
		return (await axios.get<MetricVO[]>(`/ui/executions/${executionId}/metrics/filtered`, { params })).data;
	}

	async fetchExecutionMetrics(executionId: number): Promise<MetricIdentityVO[]> {
		return (await axios.get<MetricIdentityVO[]>(`/ui/executions/${executionId}/metrics`)).data;
	}

	async fetchMetric(executionId: number, metricIdentity: MetricIdentityVO): Promise<TimeSeriesVO[]> {
		return (await axios.post<TimeSeriesVO[]>(`/ui/executions/${executionId}/metrics/time-series`, metricIdentity)).data;
	}

	async getStateOverTimeMetrics(executionId: number, widget: StateOverTimeWidgetVO): Promise<StateOverTimeMetricVO[]> {
		return (
			await axios.post<StateOverTimeMetricVO[]>(`/ui/executions/${executionId}/metrics/state-over-time`, widget, {
				transformResponse: withReviver(DateReviver(['from', 'to'])),
			})
		).data;
	}
}
