/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React from 'react';

export interface NumberIndicatorProps extends ContainerProps {
	muted?: boolean;
}

export const NumberIndicator: React.FC<NumberIndicatorProps> = ({
	variant = 'medium',
	children,
	muted,
	sx = {},
	...props
}) => {
	return (
		<Container
			display={'flex'}
			tx="numberIndicator"
			bg={muted ? 'slateMid' : 'cyanLight'}
			color={muted ? 'neutral000' : 'neutral800'}
			justifyContent="center"
			alignItems="center"
			variant={variant}
			{...props}
			sx={{
				display: 'flex',
				textAlign: 'center',
				lineHeight: 1,
				borderRadius: 9999,
				whiteSpace: 'nowrap',
				...sx,
			}}
		>
			{children}
		</Container>
	);
};
