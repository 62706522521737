/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	Container,
	FormikCheckbox,
	FormikCombobox,
	FormikTextField,
	Link,
	ModalContentV2,
	ModalHeaderV2,
	ModalV2,
	Snackbar,
	Stack,
	Tag,
	Text,
} from 'components';
import { useAutoFocus } from 'utils/hooks/useAutoFocus';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { ReactElement, useMemo } from 'react';
import { UpdateCurrentUserRequest } from 'ui-api';
import { ValidationError } from 'utils/error';
import { Services } from 'services/services';
import { useUser } from 'services/usersApi';
import { timeZones } from 'utils/dateFns';
import { jobRoles } from 'utils/user';

import { localeCompare } from '../../../utils/string';

type AccountSettingsFormValues = UpdateCurrentUserRequest;

interface AccountSettingsProps {
	withName?: boolean;
	withEmail?: boolean;
	withJobRole?: boolean;
	onClose?: () => void;
}

export default function AccountSettings({
	onClose,
	withName = true,
	withEmail = true,
	withJobRole = true,
}: AccountSettingsProps): ReactElement {
	const autoFocusRef = useAutoFocus();
	const user = useUser();
	const [submitError, setSubmitError] = React.useState<Error | null>();

	const initial: AccountSettingsFormValues = user ?? {
		name: '',
		jobRole: '',
		termsAccepted: false,
	};

	const handleSubmit = async (
		{ ...values }: AccountSettingsFormValues,
		form: FormikHelpers<AccountSettingsFormValues>,
	): Promise<void> => {
		try {
			setSubmitError(null);
			await Services.users.updateCurrentUser({
				...values,
				termsAccepted: true,
			});
			Snackbar.dark('User updated.', { toastId: 'user-updated' });

			return new Promise((resolve) => {
				const prev = window.onload;
				window.onload = () => {
					resolve();
					window.onload = prev;
				};
				window.location.reload();
			});
		} catch (error) {
			if (error instanceof ValidationError) {
				error.violations.forEach((violation) => form.setFieldError(violation.field, violation.message));
			} else {
				setSubmitError(error);
			}
		}
	};

	const timezonesOption = useMemo(
		() =>
			timeZones
				.slice()
				.sort((t1, t2) => localeCompare(t1.timeZone, t2.timeZone))
				.map(({ timeZone, offset }) => ({
					label: `${timeZone.replace('_', ' ')} (UTC ${offset})`,
					value: timeZone,
				})),
		[timeZones],
	);

	return (
		<Formik initialValues={initial} enableReinitialize onSubmit={handleSubmit}>
			{({ isSubmitting, values, setFieldValue }) => (
				<Form noValidate>
					<ModalV2 slick width={550}>
						<ModalHeaderV2 title="Account Settings" onClose={onClose} />
						<ModalContentV2>
							<Stack size={'medium'}>
								{withName && (
									<Stack size={'xxSmall'}>
										<FormikTextField
											label={'Your Name'}
											name={'name'}
											maxLength={255}
											autoComplete={'off'}
											ref={autoFocusRef}
											data-private
										/>
										<Text variant={'small'} muted>
											Your name is visible to other users.
										</Text>
									</Stack>
								)}

								{withJobRole && (
									<Stack size={'xxSmall'}>
										<FormikCombobox
											label={'Your Job Role'}
											name={'jobRole'}
											maxLength={255}
											autoComplete={'off'}
											options={jobRoles.map((role) => ({ label: role.name, value: role.id }))}
										/>
									</Stack>
								)}

								{user.email && withEmail && (
									<Stack size={'xxSmall'}>
										<FormikTextField
											label={'Your E-Mail'}
											name={'email'}
											maxLength={255}
											autoComplete={'off'}
											disabled
											data-private
										/>
										<Text variant={'small'} muted>
											Managed by your administrator
										</Text>
									</Stack>
								)}

								<Stack size={'xxSmall'} alignItems="flex-end" width="100%">
									<FormikCombobox
										width="100%"
										label="Timezone"
										name="timeZone"
										maxLength={255}
										autoComplete="on"
										placeholder="Browser default"
										options={timezonesOption}
									/>
									{values.timeZone && (
										<Button variant="chromeless" onClick={() => setFieldValue('timeZone', undefined)}>
											Reset to browser default
										</Button>
									)}
								</Stack>

								{!user.termsAccepted && (
									<Stack size={'xxSmall'}>
										<FormikCheckbox
											mr={'small'}
											name="termsAccepted"
											label={
												<span>
													Accept Data Privacy Terms
													{user.previousTermsAccepted && (
														<Tag variant={'xSmall'} ml={'xSmall'}>
															new version
														</Tag>
													)}
												</span>
											}
										/>
										<Text ml={'medium'} variant={'small'} muted>
											{user.previousTermsAccepted ? (
												<>
													The{' '}
													<Link href="https://www.steadybit.com/imprint-and-legal" external>
														data privacy terms
													</Link>{' '}
													have changed. You need to accept them in order to continue to use Steadybit.
												</>
											) : (
												<>
													Agree to Steadybit&apos;s{' '}
													<Link href="https://www.steadybit.com/imprint-and-legal" external>
														data privacy terms
													</Link>
													.
												</>
											)}
										</Text>
									</Stack>
								)}

								<Container>
									<Button
										variant={'primaryLarge'}
										width={'100%'}
										mx={'auto'}
										type={'submit'}
										disabled={!values.termsAccepted}
										loading={isSubmitting}
									>
										Save
									</Button>
									{submitError ? <Text color={'coral'}> {submitError.toString()}</Text> : null}
								</Container>
							</Stack>
						</ModalContentV2>
					</ModalV2>
				</Form>
			)}
		</Formik>
	);
}
