/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { useTenant } from 'tenancy/useTenant';

export default function useShouldBeCareful(): boolean {
	const tenant = useTenant();
	return tenant.user.role === 'SUPPORT';
}
