/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import React, { ReactElement, useEffect, useState } from 'react';

import ButtonRound, { ButtonRoundProps, variants } from './ButtonRound';
import ProgressIndicator from './ProgressIndicator';

export interface ButtonRoundLongClickProps extends ButtonRoundProps {
	onClick: () => void;
}

const clickDelay = 1000;

const clickingVariants: Record<variants, variants> = {
	primary: 'primaryClicking',
	secondary: 'primaryClicking',
	danger: 'danger',
	primaryClicking: 'primaryClicking',
};

const ButtonRoundLongClick = React.forwardRef<HTMLButtonElement, ButtonRoundLongClickProps>(
	({ onClick, children, variant = 'primary', ...props }, ref) => {
		const [isClicking, setIsClicking] = useState(false);
		const start: () => void = () => setIsClicking(true);
		const abort: () => void = () => setIsClicking(false);

		return (
			<ButtonRound
				{...props}
				variant={isClicking ? clickingVariants[variant] : variant}
				ref={ref}
				onMouseDown={start}
				onTouchStart={start}
				onMouseUp={abort}
				onMouseLeave={abort}
				onTouchEnd={abort}
				onClick={noop}
			>
				{isClicking && (
					<ProgressHandler
						{...props}
						onTimeoutEnded={() => {
							setIsClicking(false);
							onClick?.();
						}}
					/>
				)}
				{children}
			</ButtonRound>
		);
	},
);
ButtonRoundLongClick.displayName = 'ButtonRoundLongClick';
export default ButtonRoundLongClick;

interface ProgressHandlerProps extends ButtonRoundProps {
	onTimeoutEnded: () => void;
}

function ProgressHandler({ onTimeoutEnded, ...props }: ProgressHandlerProps): ReactElement {
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		const timeout: number = window.setTimeout(() => setProgress(1), 1);
		return () => window.clearTimeout(timeout);
	}, []);

	useEffect(() => {
		const timeout: number = window.setTimeout(onTimeoutEnded, clickDelay);
		return () => window.clearTimeout(timeout);
	}, [onTimeoutEnded]);

	return <ProgressIndicator {...props} progress={progress} inversed />;
}

function noop(): void {}
