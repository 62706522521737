/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react';
import { Text } from 'components';

interface TitleProps {
	title: string;
}

export default function Title({ title }: TitleProps): ReactElement {
	return (
		<Text
			variant="mediumStrong"
			sx={{
				color: 'neutral800',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				wordBreak: 'normal',
				display: '-webkit-box !important',
				WebkitLineClamp: '2',
				WebkitBoxOrient: 'vertical',
				whiteSpace: 'break-spaces',
			}}
		>
			{title}
		</Text>
	);
}
