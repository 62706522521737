/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import FieldValueHandler from 'pages/experimentsV2/StepConfigurationSidebar/Fields/Field';
import { ActionVO, OccuranceTemplateFieldVO, OccuranceVO } from 'ui-api';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { findStep } from 'pages/experimentsV2/utils';
import { useFormikContext } from 'formik';
import { Stack, Text } from 'components';
import { ReactElement } from 'react';
import { get } from 'lodash';

import {
	isOccuranceActionPredicateVO,
	isOccuranceStepLabelVO,
	isOccuranceStepParameterVO,
} from '../UseTemplateModal/types';
import { UseTemplateFormData } from '../UseTemplateModal/UseTemplateForm';
import Steps from './Steps/Steps';

interface OccurancessCommon {
	actionsResult: DataStreamResult<ActionVO[]>;
	environmentVariable?: string;
	occurances: OccuranceVO[];
	placeholder?: string;
	entityName: string;
}

interface OccurancessWithPlaceholder extends OccurancessCommon {
	placeholder: string;
}

interface OccurancessWithEnvironmentVariable extends OccurancessCommon {
	environmentVariable: string;
}

export default function Occurances({
	environmentVariable,
	actionsResult,
	placeholder,
	occurances,
	entityName,
}: OccurancessWithPlaceholder | OccurancessWithEnvironmentVariable): ReactElement {
	const { values, errors } = useFormikContext<UseTemplateFormData>();

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const stepIds = new Set(occurances.filter((occurance) => occurance.stepId).map((occurance) => occurance.stepId));
	const stepIdToPath = new Map<string, string>();
	const steps = [];
	for (const stepId of stepIds) {
		const [step, path] = findStep(values.__originalLanes, stepId);
		if (step) {
			stepIdToPath.set(stepId, path);
			steps.push(step);
		}
	}

	const errorneousSteps: Set<string> = new Set();
	occurances.forEach((occurance) => {
		const stepId = getStepId(occurance);
		if (!stepId) {
			return;
		}

		const stepPath = stepIdToPath.get(stepId) || '';
		const pathToCheck = stepPath + getPathToCheck(occurance);
		if (!pathToCheck) {
			return;
		}

		const error = get(errors, pathToCheck);
		if (error) {
			errorneousSteps.add(stepId);
		}
	});

	return (
		<Stack>
			{occurances.filter(isOccuranceTemplateFieldVO).map((occurance, i) => {
				if (occurance.templateField === 'EXPERIMENT_NAME') {
					return <ExperimentNameField key={i} />;
				}
				if (occurance.templateField === 'HYPOTHESIS') {
					return <HypothesisField key={i} />;
				}
				return null;
			})}

			{steps.length > 0 && (
				<Stack size="xSmall">
					<Text variant="smallStrong" color="neutral600">
						This {entityName} is used in the following actions:
					</Text>
					{environmentVariable && (
						<Steps
							actionsResult={actionsResult}
							steps={steps}
							environmentVariable={environmentVariable}
							errorneousSteps={errorneousSteps}
						/>
					)}
					{placeholder && (
						<Steps
							actionsResult={actionsResult}
							steps={steps}
							placeholder={placeholder}
							errorneousSteps={errorneousSteps}
						/>
					)}
				</Stack>
			)}
		</Stack>
	);
}

function ExperimentNameField(): ReactElement {
	return (
		<FieldValueHandler
			field={{
				name: 'experimentName',
				label: 'Experiment name',
				type: 'string',
				required: true,
				readonly: true,
			}}
			path="experimentName"
			disabled
		/>
	);
}

function HypothesisField(): ReactElement {
	return (
		<FieldValueHandler
			field={{
				name: 'hypothesis',
				label: 'Hypothesis',
				type: 'textarea',
				required: true,
			}}
			path="hypothesis"
			disabled
		/>
	);
}

function isOccuranceTemplateFieldVO(occurance: OccuranceVO): occurance is OccuranceTemplateFieldVO {
	return 'templateField' in occurance;
}

export function getStepId(occurance: OccuranceVO): string | undefined {
	if (isOccuranceActionPredicateVO(occurance)) {
		return occurance.stepId;
	} else if (isOccuranceStepLabelVO(occurance)) {
		return occurance.stepId;
	} else if (isOccuranceStepParameterVO(occurance)) {
		return occurance.stepId;
	}
}

function getPathToCheck(occurance: OccuranceVO): string | undefined {
	if (isOccuranceActionPredicateVO(occurance)) {
		return '.blastRadius.predicate';
	} else if (isOccuranceStepLabelVO(occurance)) {
		return '.customLabel';
	} else if (isOccuranceStepParameterVO(occurance)) {
		return '.parameters.' + occurance.field.name;
	}
}
