/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Stack, Text, Tooltip, UnstyledLink } from 'components';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { IconNewTab } from 'components/icons';
import { ReactElement } from 'react';

interface StripLabelProps {
	label: string | ReactElement;
	descriptor?: string;
	url?: string;
}

export default function StripLabel({ label, descriptor, url }: StripLabelProps): ReactElement {
	const labelComponent = (
		<Tooltip onlyShowOnEllipsis content={label}>
			<Text textAlign="right" as="span" variant="smallStrong" lineHeight="24px" sx={{ ...textEllipsis }}>
				{label}
			</Text>
		</Tooltip>
	);

	return (
		<Stack
			direction="horizontal"
			display="flex"
			alignItems="center"
			justifyContent="flex-end"
			sx={{
				whiteSpace: 'nowrap',
			}}
			width="100%"
		>
			{url ? (
				<UnstyledLink
					href={url}
					external
					sx={{
						width: '100%',
						color: 'neutral800',
						textDecoration: 'none',
						'&:hover': {
							color: 'slate',
							textDecoration: 'underline',
						},
					}}
				>
					<Stack size="xxSmall" direction="horizontal" alignItems="center" justifyContent="space-between" pr="xSmall">
						<Tooltip content={labelComponent} onlyShowOnEllipsis>
							<Text sx={{ ...textEllipsis }}>{labelComponent}</Text>
						</Tooltip>
						<IconNewTab minWidth={16} minHeight={16} maxWidth={16} maxHeight={16} />
					</Stack>
				</UnstyledLink>
			) : (
				labelComponent
			)}

			{descriptor !== undefined && (
				<Tooltip onlyShowOnEllipsis content={descriptor}>
					<Text
						textAlign="right"
						as="span"
						variant="small"
						lineHeight="24px"
						color="neutral500"
						sx={{ ...textEllipsis }}
						height="24px"
						minWidth={25}
					>
						{descriptor}
					</Text>
				</Tooltip>
			)}
		</Stack>
	);
}
