/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { KeyValueListInput, KeyValuePair } from 'components/KeyValueListInput/KeyValueListInput';
import { ReactElement } from 'react';

interface KeyValueProps {
	disabled: boolean;
	value: KeyValuePair[];
	setValue: (value: KeyValuePair[]) => void;
}

export default function KeyValue({ value, disabled, setValue }: KeyValueProps): ReactElement {
	return (
		<KeyValueListInput
			value={value}
			disabled={disabled}
			onChange={(value: KeyValuePair[]) => {
				setValue(value);
			}}
		/>
	);
}
