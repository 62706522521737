/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import Tags from 'pages/templates/components/TemplateTags';
import { ReactElement } from 'react';
import { Text } from 'components';

interface TargetsProps {
	tags: string[];
}

export default function DetailsTags({ tags }: TargetsProps): ReactElement | null {
	if (!tags || tags.length === 0) {
		return null;
	}

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateRows: '24px 1fr',
				gap: '6px',
			}}
		>
			<Text variant="small" color="neutral800">
				Tags:
			</Text>

			<Tags tags={tags} />
		</div>
	);
}
