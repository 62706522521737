/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react';

import { useSystemStatus } from './useSystemStatus';
import MaintenanceBanner from './MaintenanceBanner';
import IncidentBanner from './IncidentBanner';

export default function SystemStatus(): ReactElement | null {
	const systemStatus = useSystemStatus();

	return (
		<>
			<IncidentBanner incident={systemStatus.incident} />
			<MaintenanceBanner maintenance={systemStatus.maintenance} />
		</>
	);
}
