/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import {
	CartesianGrid,
	Cell,
	Line,
	LineChart,
	Pie,
	PieChart,
	ReferenceDot,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip as TooltipRecharts,
	XAxis,
	YAxis,
} from 'recharts';
import { MetricVO, useFilteredExecutionMetrics } from 'services/executionMetricsApi';
import { Checkbox, Code, Container, Label, Stack, Text, Tooltip } from 'components';
import React, { ReactElement, useMemo, useState } from 'react';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ExperimentStepExecutionActionVO } from 'ui-api';
import { formatTime } from 'utils/dateFns';
import { countBy, range } from 'lodash';
import { theme } from 'styles.v2/theme';

import { ExperimentRunLogsAndMetricsProps } from '../experimentExecutionLogsAndMetrics';
import LoadingContent from './LoadingContent';
import EmptyContent from './EmptyContent';

type Stat = {
	ts: number;
	group: Group;
	responseTime: number;
	httpStatus?: string;
	message?: string;
	instanaTraceId?: string;
};

enum Group {
	Successful = 'Successful',
	UnexpectedStatus = 'Unexpected Status',
	BodyConstraintViolated = 'Body Constraint Violated',
	Failure = 'Failure',
}

const COLORS: Record<Group, string> = {
	[Group.Failure]: theme.colors.experimentWarning,
	[Group.UnexpectedStatus]: theme.colors.experimentWarning,
	[Group.BodyConstraintViolated]: theme.colors.experimentWarning,
	[Group.Successful]: theme.colors.cyanDark,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderHttpStatusTooltip = (props: { active?: boolean; payload?: Array<any> }): ReactElement | null => {
	if (props.active && props.payload && props.payload.length) {
		const stat = props.payload[0].payload as Stat;
		return (
			<Container
				sx={{
					py: 'xSmall',
					px: 'small',
					backgroundColor: 'neutral000',
					boxShadow: 'applicationMedium',
					border: '1px solid ' + theme.colors.neutral300,
					borderRadius: 4,
				}}
			>
				<Text color={COLORS[stat.group as Group] ?? theme.colors.neutral300} variant={'smallMedium'} mb={'xSmall'}>
					{stat.group}
					{stat.message && (
						<Text color={'neutral600'} variant={'small'}>
							{stat.message}
						</Text>
					)}
				</Text>

				<Stack size="xxSmall">
					{stat.httpStatus && <KeyValue k="HTTP-Status" v={stat.httpStatus} />}
					<KeyValue k="Timestamp" v={formatTime(new Date(stat.ts))} />
					<KeyValue k="Response-Time" v={stat.responseTime + ' ms'} />
				</Stack>

				{stat.instanaTraceId && (
					<>
						<Text color={'neutral600'} variant={'smallMedium'} mt={'xSmall'}>
							Instana Trace ID
						</Text>
						<Text color={'neutral600'} variant={'small'}>
							<Code inline>{stat.instanaTraceId}</Code>
						</Text>
					</>
				)}
			</Container>
		);
	}

	return null;
};

interface KeyValueProps {
	k: string;
	v: string;
}

function KeyValue({ k, v }: KeyValueProps): ReactElement {
	return (
		<Stack size="none">
			<Text variant="small" color="neutral600">
				{k}:
			</Text>
			<Text variant="smallStrong" color="neutral700" sx={{ ...textEllipsis }}>
				{v}
			</Text>
		</Stack>
	);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderPieChartTooltip = (props: { active?: boolean; payload?: Array<any> }): ReactElement | null => {
	if (props.active && props.payload && props.payload.length) {
		return (
			<Container
				backgroundColor={'neutral000'}
				p={'xSmall'}
				sx={{
					boxShadow: 'applicationMedium',
					border: '1px solid',
					borderColor: 'neutral300',
					borderRadius: 4,
				}}
			>
				<Text
					color={COLORS[props.payload[0].payload.group as Group] ?? theme.colors.neutral300}
					variant={'smallMedium'}
					mb={'Small'}
				>
					{props.payload[0].payload.group}
				</Text>
				<Text color={'neutral600'} variant={'smallMedium'} as={'span'}>
					{props.payload[0].payload.count}
				</Text>
			</Container>
		);
	}

	return null;
};

function metricToStat(metric: MetricVO, statusCodes: number[]): Stat {
	let message = '';
	let group = Group.Successful;

	let hasExpectedStatus;
	if ('expected_http_status' in metric.labels) {
		hasExpectedStatus = 'true' === metric.labels.expected_http_status;
	} else {
		//when not the agent is verifying the status code we must fall back to checking it here
		hasExpectedStatus = statusCodes.includes(Number(metric.labels.http_status));
	}

	if (metric.labels.error) {
		group = Group.Failure;
		message = metric.labels.error;
	} else if (!hasExpectedStatus) {
		group = Group.UnexpectedStatus;
	} else if (
		metric.labels.response_constraints_fulfilled &&
		!metric.labels.response_constraints_fulfilled.startsWith('true')
	) {
		group = Group.BodyConstraintViolated;
	}

	return {
		ts: metric.ts,
		responseTime: metric.value,
		httpStatus: metric.labels.http_status,
		group,
		message,
		instanaTraceId: metric.labels.instana_trace_id,
	};
}

function parseRange(range: string): number[] {
	if (!range) {
		return parseRange('200-299');
	}
	const numbers = [];
	for (const [, beginStr, endStr] of range.matchAll(/(\d+)(?:-(\d+))?/g)) {
		const [begin, end] = [beginStr, endStr].map(Number);
		numbers.push(begin);
		if (endStr !== undefined) {
			for (let num = begin + 1; num <= end; num++) {
				numbers.push(num);
			}
		}
	}
	return numbers;
}

export const HttpResponseTimes: React.VFC<
	Omit<ExperimentRunLogsAndMetricsProps, 'selectedStepId' | 'setSelectedStepId'> & {
		action: ExperimentStepExecutionActionVO;
	}
> = ({ experimentExecution, position, start, duration, action, onPositionSelect }) => {
	const ticks = useMemo(() => {
		const tickCount = Math.min(8, duration / 1000);
		return range(start, start + duration + 1, duration / tickCount);
	}, [start, duration]);
	const liveUpdate = !experimentExecution.ended;
	const metrics = useFilteredExecutionMetrics(
		experimentExecution.id,
		'response_time',
		'url',
		action.parameters.url,
		liveUpdate,
	);
	const data = useMemo(() => {
		const expectedStatusCodes = parseRange(action.parameters.statusCode);
		const bars: Stat[] = metrics.value?.map((m) => metricToStat(m, expectedStatusCodes)) || [];
		const results = Object.entries(countBy(bars, (metric) => metric.group)).map(([group, value]) => ({
			group: group as Group,
			count: value,
		}));
		return { bars, results };
	}, [metrics.value]);
	const [hidden, setHidden] = useState<Record<string, boolean>>({});
	const filteredData = useMemo(() => data.bars.filter((entry) => !hidden[entry.group]), [data.bars, hidden]);

	if (!metrics.value?.length) {
		return (
			<Stack size={0} sx={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
				{liveUpdate || metrics.loading ? (
					<LoadingContent loading={metrics.loading}>Waiting for metrics...</LoadingContent>
				) : (
					<EmptyContent>No metrics recorded.</EmptyContent>
				)}
			</Stack>
		);
	}
	return (
		<Container
			sx={{
				display: 'grid',
				gridTemplateColumns: '1fr 160px',
				height: '300px',
			}}
		>
			<Container sx={{ zIndex: 1 }}>
				<ResponsiveContainer key={'line-chart-container' + action.parameters.url}>
					<LineChart
						key={'line-chart' + action.parameters.url}
						data={filteredData}
						margin={{ left: 0, top: 0, right: 0, bottom: 0 }}
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						onClick={(event: any) => onPositionSelect(event?.activePayload?.[0]?.payload?.ts)}
					>
						<CartesianGrid {...theme.charts.grid} />
						<TooltipRecharts content={renderHttpStatusTooltip} />

						<XAxis
							{...theme.charts.axes}
							key={'line-chart-x-axis' + action.parameters.url + '_' + start + duration}
							dataKey="ts"
							type="number"
							domain={[start, start + duration]}
							ticks={ticks}
							scale={'time'}
							tickFormatter={(t) => formatTime(new Date(t))}
						/>
						<YAxis {...theme.charts.axes} type={'number'} unit={'ms'} />
						{filteredData.map((entry, i) => (
							<ReferenceDot
								key={'line-chart-' + action.parameters.url + '_' + i}
								x={entry.ts}
								y={entry.responseTime}
								fill={COLORS[entry.group] ?? theme.colors.neutral300}
								r={5}
							/>
						))}
						<Line
							type="monotone"
							dataKey="responseTime"
							stroke={theme.colors.neutral300}
							dot={false}
							isAnimationActive={false}
						/>
						{position && <ReferenceLine x={position} stroke={theme.colors.slate} strokeWidth={2} />}
					</LineChart>
				</ResponsiveContainer>
			</Container>

			<Stack size="xSmall" alignItems="center" sx={{ zIndex: 0 }}>
				<ResponsiveContainer height="50%">
					<PieChart>
						<Pie data={data.results} dataKey={'count'} fill="#82ca9d">
							{data.results.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={COLORS[entry.group]} />
							))}
						</Pie>
						<TooltipRecharts content={renderPieChartTooltip} />
					</PieChart>
				</ResponsiveContainer>
				<Stack size="xxSmall" overflowY="auto" height={100} width={150}>
					{data.results.map((result) => (
						<Container key={result.group} sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
							<Checkbox
								id={result.group}
								checked={!hidden[result.group]}
								color={COLORS[result.group]}
								onChange={(e) => setHidden((c) => ({ ...c, [result.group]: !e.target.checked }))}
							/>
							<Label htmlFor={result.group}>
								<Tooltip content={result.group} onlyShowOnEllipsis>
									<Container
										sx={{
											whiteSpace: 'nowrap',
											textOverflow: 'ellipsis',
											overflow: 'hidden',
										}}
									>
										<Text as="span" variant="small" color="neutral400">
											{result.count}x
										</Text>
										<Text as="span" variant="small" color="neutral600">
											{result.group}
										</Text>
									</Container>
								</Tooltip>
							</Label>
						</Container>
					))}
				</Stack>
			</Stack>
		</Container>
	);
};
