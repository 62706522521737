/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { saveTemplate } from 'templates/components/formUtils';
import { suppressSubmitOnEnter } from 'utils/form';
import { ReactElement, ReactNode } from 'react';
import { useHistory } from 'url/hooks';
import { Form, Formik } from 'formik';

import PlaceholderExtractionAndCleanupJob from './PlaceholderExtractionAndCleanupJob';
import { PreparedFormData } from './TemplateEditorLoader';
import ValidationHandler from './ValidationHandler';
import { TemplateFormValues } from './types';

interface TemplateEditorFormProps {
	formData: PreparedFormData;
	children: ReactNode;
}

export default function TemplateEditorForm({ formData, children }: TemplateEditorFormProps): ReactElement {
	const history = useHistory();

	return (
		<Formik<TemplateFormValues>
			initialValues={{ ...formData, ...formData.initialValues }}
			initialErrors={{}}
			// see <ValidationHandler /> for docs
			validateOnChange={false}
			validateOnBlur={false}
			enableReinitialize
			onSubmit={async (template: TemplateFormValues, { setValues, setTouched }) => {
				const savedTemplate = await saveTemplate(template);
				if (savedTemplate) {
					setValues(savedTemplate);
					setTouched({});
					history.replace('/settings/templates');
				}
			}}
		>
			{({ values }) => (
				<Form key={values.id} onKeyDown={suppressSubmitOnEnter} noValidate>
					<ValidationHandler />
					<PlaceholderExtractionAndCleanupJob />
					{children}
				</Form>
			)}
		</Formik>
	);
}
