/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { IconCancel } from 'components/icons';
import { Container, Text } from 'components';
import { ReactElement } from 'react';

interface CanceledBadgeProps {
	numberCanceled: number;
}

export default function CanceledBadge({ numberCanceled }: CanceledBadgeProps): ReactElement | null {
	if (numberCanceled === 0) {
		return null;
	}

	return (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',
				borderRadius: 4,
				backgroundColor: 'neutral400',
				color: 'neutral700',
				padding: '4px 8px',
			}}
		>
			<IconCancel variant="small" mr="xSmall" />
			<Text variant="smallStrong">Canceled ({numberCanceled})</Text>
		</Container>
	);
}
