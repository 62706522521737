/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import MultiSelectDropdown from 'components/Select/Dropdown/MultiSelectDropdown';
import MultiSelectList from 'components/Select/Dropdown/presets/MultiSelectList';
import { IconAdviceGeneral, createIconFromDataUri } from 'components/icons';
import EmptyListContent from 'components/List/EmptyListContent';
import { sharedAdviceIdParam } from 'targets/Explore/urlParams';
import AdviceIconWrapper from 'components/advice/AdviceIcon';
import { Container, Stack, Text, Tooltip } from 'components';
import AdviceBadges from 'components/advice/AdviceBadges';
import { ReactElement, useEffect, useState } from 'react';
import { localeCompareIgnoreCase } from 'utils/string';
import { AdviceStatusVO, AdviceVO } from 'ui-api';
import { useUrlState } from 'url/useUrlState';
import { theme } from 'styles.v2/theme';
import { TargetId } from 'targets/util';

import textEllipsis from '../../utils/styleSnippets/textEllipsis';
import TargetAdviceDetails from './TargetAdviceDetails';
import { ampli } from '../../ampli';

interface TargetAdviceProps {
	environmentId: string | null;
	advice: AdviceVO[];
	targetId: TargetId;
}

interface AdviceIdUrlState {
	sharedAdviceId: string | null;
}

export default function TargetAdvice({ environmentId, targetId, advice }: TargetAdviceProps): ReactElement {
	const [selectedAdviceId, setSelectedAdviceId] = useState<string | undefined>(undefined);

	const [{ sharedAdviceId }, , updateUrlState] = useUrlState<AdviceIdUrlState>([sharedAdviceIdParam]);
	useEffect(() => {
		if (sharedAdviceId) {
			setSelectedAdviceId(sharedAdviceId);
			updateUrlState({ sharedAdviceId: null });
		}
	}, [sharedAdviceId]);

	useEffect(() => {
		if (!sharedAdviceId && !selectedAdviceId && advice.length > 0) {
			setSelectedAdviceId(advice[0].targetReference + advice[0].adviceDefinitionId);
		}
	}, [sharedAdviceId, selectedAdviceId, advice]);

	const selectedAdvice = advice.find(
		(advice) => advice.targetReference + advice.adviceDefinitionId === selectedAdviceId,
	);
	const [activeBadges, setActiveBadges] = useState<Array<AdviceStatusVO>>([]);

	const [searchTermTags, setSearchTermTags] = useState<string>('');
	const [selectedTags, setSelectedTags] = useState<string[]>([]);
	const tags = advice
		.reduce((acc, advice) => {
			advice.tags.forEach((tag) => {
				if (acc.indexOf(tag) === -1) {
					acc.push(tag);
				}
			});
			return acc;
		}, [] as string[])
		.sort(localeCompareIgnoreCase)
		.filter((tag) => tag.toLowerCase().includes(searchTermTags.toLowerCase()))
		.map((tag) => ({ value: tag, label: tag }));

	if (selectedTags.length > 0) {
		advice = advice.filter((advice) => selectedTags.find((tag) => advice.tags.indexOf(tag) !== -1));
	}

	useEffect(() => {
		ampli.explorerAdviceListViewed({
			advice_ids_listed: advice.map((a) => a.type),
			advice_id_shown: selectedAdviceId ?? undefined,
			filter_tag: selectedTags,
			advice_context: 'explorer_target',
		});
	}, [advice, selectedAdviceId, selectedTags]);

	const adviceRequireAction = advice.filter((a) => a.status === 'ACTION_NEEDED').length;
	const adviceRequireValidation = advice.filter((a) => a.status === 'VALIDATION_NEEDED').length;
	const adviceDone = advice.filter((a) => a.status === 'IMPLEMENTED').length;
	const onlyOneStateExistsV = onlyOneStateExists(adviceRequireAction, adviceRequireValidation, adviceDone);

	if (activeBadges.length > 0) {
		advice = advice.filter((advice) => activeBadges.find((a) => a === advice.status));
	}
	advice.sort((a, b) => {
		if (a.status === b.status) {
			return a.name.localeCompare(b.name);
		}
		if (a.status === 'ACTION_NEEDED') {
			return -1;
		}
		if (b.status === 'ACTION_NEEDED') {
			return 1;
		}
		if (a.status === 'VALIDATION_NEEDED') {
			return -1;
		}
		if (b.status === 'VALIDATION_NEEDED') {
			return 1;
		}
		return a.name.localeCompare(b.name);
	});

	return (
		<Container
			sx={{
				display: 'grid',
				gridTemplateColumns: '420px 1fr',
				border: '1px solid ' + theme.colors.neutral300,
				borderTop: 'none',
				overflow: 'hidden',
			}}
		>
			<Stack size="none" sx={{ borderRight: '1px solid ' + theme.colors.neutral300, overflow: 'hidden' }}>
				<Stack bg="neutral100" p="small">
					{advice.length > 0 && (
						<Stack>
							<Text variant="smallStrong">Filter by tags:</Text>
							<MultiSelectDropdown
								variant="light"
								value={searchTermTags}
								selectedValues={selectedTags.map((tag) => ({ value: tag, label: tag }))}
								onRemoveSelectedValue={({ value }) => setSelectedTags(selectedTags.filter((t) => t !== value))}
								onValueChanged={setSearchTermTags}
								placeholder="Value"
								width="100%"
							>
								{({ width }) => (
									<DropdownContentFrame maxHeight={300} snapToComponent>
										<MultiSelectList
											width={width}
											selectedItemValues={selectedTags}
											items={tags}
											selectItem={({ value }) => setSelectedTags([...selectedTags, value])}
											removeSelectedItem={({ value }) => setSelectedTags(selectedTags.filter((t) => t !== value))}
										/>
									</DropdownContentFrame>
								)}
							</MultiSelectDropdown>
						</Stack>
					)}

					{!onlyOneStateExistsV && (
						<Container display="flex" alignItems="center" justifyContent="space-between">
							<Text variant="smallStrong">Filter by:</Text>
							<AdviceBadges
								adviceRequireAction={adviceRequireAction}
								adviceRequireValidation={adviceRequireValidation}
								adviceDone={adviceDone}
								onBadgeClick={(type) => {
									if (activeBadges.indexOf(type) === -1) {
										setActiveBadges([...activeBadges, type]);
									} else {
										setActiveBadges(activeBadges.filter((t) => t !== type));
									}
								}}
								activeBadges={activeBadges}
							/>
						</Container>
					)}
				</Stack>

				<Stack p="small" overflowY="auto">
					{advice.map((advice) => (
						<Advice
							key={advice.targetReference + advice.adviceDefinitionId}
							advice={advice}
							setSelectedAdviceId={setSelectedAdviceId}
							isSelected={selectedAdviceId === advice.targetReference + advice.adviceDefinitionId}
						/>
					))}
				</Stack>
			</Stack>

			{selectedAdvice ? (
				<Container overflowY="auto">
					<TargetAdviceDetails
						advice={selectedAdvice}
						targetId={targetId}
						environmentId={environmentId}
						context={'explorer_target'}
					/>
				</Container>
			) : (
				<EmptyAdviceContent />
			)}
		</Container>
	);
}

interface AdviceProps {
	setSelectedAdviceId: (adviceId: string) => void;
	isSelected: boolean;
	advice: AdviceVO;
}

function Advice({ advice, setSelectedAdviceId, isSelected }: AdviceProps): ReactElement {
	const Icon = createIconFromDataUri(advice.icon);

	return (
		<Container
			sx={{
				display: 'grid',
				gridTemplateColumns: '32px 1fr auto',
				alignItems: 'center',

				px: 'small',
				py: 'small',
				borderRadius: 4,
				border: '1px solid ' + (isSelected ? theme.colors.slate : theme.colors.neutral300),
				outline: '1px solid ' + (isSelected ? theme.colors.slate : 'transparent'),
				bg: isSelected ? 'neutral100' : 'white',

				color: isSelected ? theme.colors.slate : theme.colors.neutral800,

				cursor: 'pointer',
				':hover': {
					bg: 'neutral100',
					border: '1px solid ' + theme.colors.slate,
					outline: '1px solid ' + theme.colors.slate,
					color: theme.colors.slate,
				},
			}}
			onClick={() => {
				setSelectedAdviceId(advice.targetReference + advice.adviceDefinitionId);
				const modalWrapper = document.getElementsByClassName('modalContent');
				if (modalWrapper.length > 0) {
					modalWrapper[0].scrollTop = 0;
				}
			}}
		>
			{Icon ? <Icon variant="large" /> : <div />}
			<Tooltip content={advice.name} onlyShowOnEllipsis>
				<Text variant="medium" sx={{ ...textEllipsis }}>
					{advice.name}
				</Text>
			</Tooltip>
			<AdviceIconWrapper status={advice.status} />
		</Container>
	);
}

function EmptyAdviceContent(): ReactElement {
	return (
		<Container display="flex" alignItems="center" justifyContent="center" p="large">
			<EmptyListContent icon={<IconAdviceGeneral variant="xxLarge" color="slate" />} title="Please select an advice" />
		</Container>
	);
}

function onlyOneStateExists(adviceRequireAction: number, adviceRequireValidation: number, adviceDone: number): boolean {
	return (
		(adviceRequireAction > 0 && adviceRequireValidation === 0 && adviceDone === 0) ||
		(adviceRequireAction === 0 && adviceRequireValidation > 0 && adviceDone === 0) ||
		(adviceRequireAction === 0 && adviceRequireValidation === 0 && adviceDone > 0)
	);
}
