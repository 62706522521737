/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import {
	BasicStyleProperties,
	InteractionTypes,
	resolveStyleProp,
	resolveSx,
	resolveTxAndVariant,
} from 'components/types';
import { ElementType, ReactNode, Ref, forwardRef } from 'react';
import { Text as ThemeUiText } from 'theme-ui';

export interface TextProps extends BasicStyleProperties, InteractionTypes<HTMLInputElement> {
	autoComplete?: string;
	children?: ReactNode | string;
	flex?: string;
	flexBasis?: string;
	flexGrow?: number;
	flexShrink?: number;
	fontFamily?: string;
	fontSize?: string | number;
	fontWeight?: string | number;
	htmlFor?: string;
	lineHeight?: string | number;
	muted?: boolean;
	noWrap?: boolean;
	textAlign?: 'center' | 'right';

	ref?: Ref<HTMLDivElement>;
	as?: ElementType;

	tx?: string;
	variant?: string;
	size?: string;
}

export const Text = forwardRef<HTMLDivElement, TextProps>(
	(
		{
			children,
			muted,
			noWrap,
			sx = {},
			backgroundColor,
			bg,
			color,
			height,
			minHeight,
			maxHeight,
			width,
			minWidth,
			maxWidth,
			m,
			mb,
			ml,
			mr,
			mt,
			mx,
			my,
			p,
			pb,
			pl,
			pr,
			pt,
			px,
			py,
			overflow,
			overflowX,
			overflowY,
			opacity,
			tabIndex,
			flex,
			flexBasis,
			flexGrow,
			lineHeight,
			flexShrink,
			textAlign,
			fontSize,
			fontWeight,
			fontFamily,
			tx,
			variant = 'medium',
			as,
			...props
		},
		ref,
	) => {
		return (
			<ThemeUiText
				color={muted ? 'neutral500' : undefined}
				ref={ref}
				as={as || 'div'}
				sx={{
					whiteSpace: noWrap ? 'nowrap' : undefined,
					backgroundColor,
					bg,
					color,
					height: resolveStyleProp(height),
					minHeight: resolveStyleProp(minHeight),
					maxHeight: resolveStyleProp(maxHeight),
					width: resolveStyleProp(width),
					minWidth: resolveStyleProp(minWidth),
					maxWidth: resolveStyleProp(maxWidth),
					m: resolveStyleProp(m),
					mb: resolveStyleProp(mb),
					ml: resolveStyleProp(ml),
					mr: resolveStyleProp(mr),
					mt: resolveStyleProp(mt),
					mx: resolveStyleProp(mx),
					my: resolveStyleProp(my),
					p: resolveStyleProp(p),
					pb: resolveStyleProp(pb),
					pl: resolveStyleProp(pl),
					pr: resolveStyleProp(pr),
					pt: resolveStyleProp(pt),
					px: resolveStyleProp(px),
					py: resolveStyleProp(py),
					opacity: resolveStyleProp(opacity),
					lineHeight,
					fontSize,
					fontWeight,
					overflow,
					overflowX,
					overflowY,
					tabIndex,
					flex,
					flexBasis,
					flexGrow,
					flexShrink,
					textAlign,
					fontFamily,
					...resolveSx(sx),
				}}
				{...props}
				variant={resolveTxAndVariant(tx, variant)}
			>
				{children}
			</ThemeUiText>
		);
	},
);
Text.displayName = 'Text';
