/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { IconArrowDropDown, IconArrowDropUp, IconComponent } from 'components/icons';
import { Pill, ShortenedText, Stack } from 'components';
import { ContainerProps } from 'components/Container';
import { ReactElement, RefObject } from 'react';

import { GeneralDropdownProps, renderChildren } from './types';
import Dropdown from './Dropdown';

interface DropdownInputProps<T> extends Omit<ContainerProps, 'children'>, GeneralDropdownProps<T> {
	children: renderChildren<T>;
	placeholder?: string;
	Icon: IconComponent;
	value: string;
}

export default function DropdownPill(props: DropdownInputProps<string>): ReactElement {
	const { Icon, sx = {}, disabled, backgroundColor, color, ...rest } = props;

	return (
		<Dropdown<string>
			{...props}
			renderComponent={(_props) => {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const { setShowMenu, showMenu, value, ref, onValueChanged, ..._rest } = _props;

				return (
					<Pill
						{...rest}
						{..._rest}
						ref={ref as RefObject<HTMLDivElement>}
						backgroundColor={backgroundColor || 'neutral200'}
						backgroundColorOnHover="neutral300"
						color={color || 'neutral700'}
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							borderRadius: 20,
							maxWidth: '100%',
							overflow: 'hidden',
							cursor: disabled ? 'not-allowed' : 'pointer',
							pl: 0,
							...sx,
						}}
						onClick={() => {
							if (!disabled) {
								setShowMenu(!showMenu);
							}
						}}
					>
						<Stack size="xSmall" direction="horizontal" alignItems="center" mx="xSmall">
							<Icon variant="small" minWidth={16} minHeight={16} />
							<ShortenedText variant="small" sx={{ whiteSpace: 'nowrap' }}>
								{value || ''}
							</ShortenedText>
						</Stack>
						{disabled ? (
							''
						) : showMenu ? (
							<IconArrowDropUp width={16} minWidth={16} />
						) : (
							<IconArrowDropDown width={16} minWidth={16} />
						)}
					</Pill>
				);
			}}
		/>
	);
}
