/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { useState } from 'react';

export interface UseSelectedLogLevelResult {
	selectedLogLevel: string[];
	setSelectedLogLevel: (selectedLogLevel: string[]) => void;
	availableLogLevel: string[];
	setAvailableLogLevel: (availableLogLevel: string[]) => void;
}

export default function useSelectedLogLevel(defaultLogLevel: string[]): UseSelectedLogLevelResult {
	const [selectedLogLevel, setSelectedLogLevel] = useState<string[]>(defaultLogLevel);
	const [availableLogLevel, _setAvailableLogLevel] = useState<string[]>([]);

	const setAvailableLogLevel = (_availableLogLevel: string[]): void => {
		if (hasSameElements(availableLogLevel, _availableLogLevel)) {
			return;
		}

		for (let i = 0; i < _availableLogLevel.length; i++) {
			if (availableLogLevel.indexOf(_availableLogLevel[i]) === -1) {
				_setAvailableLogLevel(_availableLogLevel);
			}
		}

		if (selectedLogLevel.length === 0) {
			// TODO: if we plan to use this for more widget, we need to get a bit smarter here
			const warnLevels = _availableLogLevel.filter((_level) => _level === 'Warning');
			if (warnLevels.length > 0) {
				setSelectedLogLevel(warnLevels);
			}
			return;
		} else {
			const selectedButNotAvailable = selectedLogLevel.filter((_level) => _availableLogLevel.indexOf(_level) === -1);
			// if there are level selected which are not available anymore, filter the selection once and return
			if (selectedButNotAvailable.length > 1) {
				setSelectedLogLevel(selectedLogLevel.filter((_level) => _availableLogLevel.indexOf(_level) !== -1));
				return;
			}
		}
	};

	return { selectedLogLevel, setSelectedLogLevel, availableLogLevel, setAvailableLogLevel };
}

function hasSameElements(a: string[], b: string[]): boolean {
	return a.length === b.length && a.every((value) => b.includes(value));
}
