/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { MutableRefObject, ReactElement } from 'react';

import ExploreTextures from '../TargetBubbleMap/ExploreTextures';
import TargetBubbleMap from '../TargetBubbleMap/TargetBubbleMap';
import { ExploreLoadingView } from './ExploreLoadingView';
import { LandscapeGroup, NumberOfItems } from '../types';
import ExploreEmptyView from './ExploreEmptyView';

interface ExploreResultViewProps {
	canvasRef: MutableRefObject<HTMLCanvasElement | null>;
	numberOfItems: NumberOfItems | null;
	textures: ExploreTextures | null;
	groups: LandscapeGroup[] | null;
	icons: Map<string, string>;
	isCalculating: boolean;
	isLoading?: boolean;
}

export default function ExploreResultView({
	numberOfItems,
	isCalculating,
	canvasRef,
	isLoading,
	textures,
	groups,
	icons,
}: ExploreResultViewProps): ReactElement {
	if (!groups || !textures || isLoading || numberOfItems == undefined) {
		return <ExploreLoadingView />;
	}

	if (groups.length === 0) {
		return <ExploreEmptyView />;
	}

	return (
		<TargetBubbleMap
			numberOfItems={numberOfItems}
			isCalculating={isCalculating}
			canvasRef={canvasRef}
			textures={textures}
			groups={groups}
			icons={icons}
		/>
	);
}
